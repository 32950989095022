import React, { Component, Fragment } from 'react';
import Dialog from '_common/components/Dialog';
import { HeaderText } from '_common/components/Dialog/elements';
import { Row } from 'antd';
import { compose } from 'recompose';
import { chunk, debounce } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import {
  CompaniesGridWrapper,
  CompanyGridItem,
  SearchCompaniesWrapper,
  TopRowWrapper,
  CreateCompanyButton,
  CompaniesDialogRoot,
} from './elements';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { Input } from '_common/components';
import { RouterHistory, withRouter } from 'react-router';
import urls from '_common/routes/urls';
import { withTranslation } from 'react-i18next';
import CompanyHierarchyItem from './CompanyHierarchyItem';

type Props = {
  companies: Array<TCompany | TOption>,
  canCreateCompany: boolean,
  dialogProps: TDialogProps,
  selectedCompanyId: string,
  onSelectCompany: Function,
  history: RouterHistory,
  getCompanyName: string => string,
  t: TTranslateFunc,
};

type State = {
  windowWidth: number,
  windowHeight: number,
  filteredCompanies: Array<TCompany>,
  query: string,
};

class CompaniesDialog extends Component<Props, State> {

  static defaultProps = {
    companies: [],
  };

  state = {
    windowWidth: 0,
    windowHeight: 0,
    filteredCompanies: [],
    query: '',
  };

  componentDidMount() {
    this.setState({
      filteredCompanies: this.props.companies,
    });
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { query } = this.state;
    const { dialogProps, companies } = this.props;

    if (
      (dialogProps.isShown && !prevProps.dialogProps.isShown) ||
      companies.length !== prevProps.companies.length
    ) {
      this.setState({
        filteredCompanies: companies,
      });
    }
    if (query !== prevState.query) {
      this.search(query);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  search = debounce((query: string) => {
    let filteredCompanies = this.props.companies;
    if (query) {
      filteredCompanies = filteredCompanies.filter(company =>
        company.companyName.toLowerCase().includes(query.toLowerCase())
      );
    }
    this.setState({
      filteredCompanies,
    });
  }, 500);

  changeQuery = ({ target: { value: query } }: Object) =>
    this.setState({
      query,
    });

  onSelectCompany = (companyId: string) => () => {
    const {
      onSelectCompany,
      dialogProps: { handleCancelClick },
    } = this.props;
    onSelectCompany(companyId);
    if (handleCancelClick) {
      handleCancelClick();
    }
  };

  goToCreateCompany = () => {
    const { dialogProps, history } = this.props;
    dialogProps.handleCancelClick();
    history.push(urls.companies.subroutes.createCompany);
  };

  renderContent = () => {
    const {
      canCreateCompany,
      selectedCompanyId,
      t,
      getCompanyName,
    } = this.props;
    const { windowWidth, filteredCompanies } = this.state;

    return (
      <Fragment>
        <TopRowWrapper>
          <SearchCompaniesWrapper>
            <Input
              placeholder={t('searchCompany')}
              onChange={this.changeQuery}
            />
            <SearchOutlined />
          </SearchCompaniesWrapper>
          {canCreateCompany && (
            <CreateCompanyButton onClick={this.goToCreateCompany}>
              {t('createCompany')}
            </CreateCompanyButton>
          )}
        </TopRowWrapper>
        <CompaniesGridWrapper>
          {chunk(filteredCompanies, windowWidth > 1400 ? 5 : 4).map(
            companies => (
              <Row
                type="flex"
                key={
                  companies.length
                    ? companies.length + companies[0].companyId
                    : 0
                }
              >
                {companies.map((company: TCompany) => {
                  const isSelectedCompany =
                    selectedCompanyId === company.companyId;
                  return (
                    <CompanyGridItem key={company.companyId}>
                      <CompanyHierarchyItem
                        isParent={false}
                        companyName={company.companyName}
                        selected={isSelectedCompany}
                        onClick={
                          isSelectedCompany
                            ? null
                            : this.onSelectCompany(company.companyId)
                        }
                      />
                      {company.hierarchy
                        .reverse()
                        .filter(
                          parentId =>
                            ![
                              company.companyId,
                              MAIN_COMPANIES.DODDLE_ID,
                            ].includes(parentId)
                        )
                        .map(parentId => (
                          <CompanyHierarchyItem
                            key={parentId + company.companyId}
                            isParent
                            companyName={getCompanyName(parentId)}
                          />
                        ))}
                    </CompanyGridItem>
                  );
                })}
              </Row>
            )
          )}
        </CompaniesGridWrapper>
      </Fragment>
    );
  };

  render() {
    const { dialogProps, companies, t } = this.props;

    return (
      <Dialog
        {...dialogProps}
        disableScroll={false}
        dismissWrapperPosition="absolute"
        CustomDialogRoot={CompaniesDialogRoot}
        headerFontSize={19}
        helper={
          <HeaderText>
            {t('assignedCompanies', { count: companies.length })}
          </HeaderText>
        }
        headerText={t('companies')}
        width="95%"
        maxHeight="85%"
        renderContent={this.renderContent}
      />
    );
  }

}

export default compose(withTranslation('company'), withRouter)(CompaniesDialog);
