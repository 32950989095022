// @flow strict

import links from '_common/routes/urls';
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import type { RouterHistory, Location } from 'react-router';
import { Redirect } from 'react-router';
import { withRouter, Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import { Header, Notifications } from '_common/components';
import { StickyContainer, Sticky } from 'react-sticky';
import Dashboard from 'dashboard/routes';
import Search from 'search/routes';
import DoddleStoresView from 'doddleStores/routes';
import Users from 'users/routes';
import Promotions from 'promotions/routes';
import { get } from 'lodash';
import { ACLStore, CompaniesStore, UIStore } from 'stores';
import ParcelDetails from 'parcels/parcelDetails/routes';
import Companies from 'companies/routes';
import { ConfigProvider } from 'antd';
import { ANTD_LOCALE_MAPPING } from 'i18n';

type Props = {|
  location: Location,
  history: RouterHistory,
  aclStore: ACLStore,
  companiesStore: CompaniesStore,
  uiStore: UIStore,
|};

class Main extends React.Component<Props> {

  componentDidMount = async () => {
    const { aclStore, companiesStore } = this.props;
    await Promise.all([aclStore.fetch(), companiesStore.fetch()]);
  };

  render() {
    const routes = [
      ...Dashboard,
      ...Search,
      ...DoddleStoresView,
      ...ParcelDetails,
      ...Users,
      ...Companies,
      ...Promotions,
    ];

    return (
      <React.Fragment>
        <ConfigProvider
          locale={get(
            ANTD_LOCALE_MAPPING,
            this.props.uiStore.locale,
            ANTD_LOCALE_MAPPING.en
          )}
        >
          <Header />
          <StickyContainer>
            <Sticky>
              {({ style, rest }) => (
                <div {...rest}>
                  <Notifications style={style} />
                </div>
              )}
            </Sticky>
            <Switch>
              {routes.map(route => (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
              <Redirect to={links.notFound} />
            </Switch>
          </StickyContainer>
        </ConfigProvider>
      </React.Fragment>
    );
  }

}

export default compose(
  inject('aclStore', 'companiesStore', 'uiStore'),
  withRouter,
  observer
)(Main);
