// @flow strict

import links from '_common/routes/urls';
import Main from 'dashboard/pages/Main';

export default [
  {
    path: links.dashboard,
    component: Main,
  },
];
