// @flow

import React, { Fragment } from 'react';
import { toJS } from 'mobx';
import doddleStoresActions from 'doddleStores/actions';
import { bindTrailingArgs, computeOpeningHours } from '_common/utils/utils';
import { Roles } from '_common/constants/acl';
import commonActions from '_common/actions';
import { upperFirst, get } from 'lodash';
import { AVAILABILITY_CONFIG } from '_common/constants/stores';

const customView = (data: any) => {
  if (typeof data === 'string') {
    return data;
  }
  return (
    <Fragment>
      <div>{data.startTime}</div>
      <div>{data.endTime}</div>
    </Fragment>
  );
};

const getOpeningHoursData = (data, dayName, newDayData) => {
  let computedNewData = {};
  if (!newDayData.isOpen) {
    computedNewData.isOpen = false;
  } else {
    computedNewData = { ...newDayData };
  }

  return {
    openingHours: {
      ...toJS(get(data, 'singleStore.openingHours', {})),
      [dayName]: computedNewData,
    },
  };
};

const onUpdateClicked = (dayName: string) => (updateData: Object) => {
  doddleStoresActions.updateStoreOpeningHours(
    updateData.id,
    getOpeningHoursData(updateData.data, dayName, updateData.currentValue)
  );
};

export const storeAvailabilityBlock = (
  t: TTranslateFunc,
  customHeader: Object
): TInfoBlock => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
  });

  return {
    infoRows: AVAILABILITY_CONFIG.DAYS.map(day => ({
      aclAccess: hasPermissions,
      name: upperFirst(day),
      path: `singleStore.openingHours.${day}`,
      type: 'timePickerRange',
      compute: bindTrailingArgs(computeOpeningHours, t),
      customView: customView,
      onUpdateClicked: onUpdateClicked(day),
    })),
    translateNS: 'stores',
    idPropName: 'singleStore.storeId',
    CustomHeader: customHeader,
    header: '',
  };
};
