// @flow

import doddleStoresActions from 'doddleStores/actions';
import get from 'lodash/get';
import { toJS } from 'mobx';
import { Roles } from '_common/constants/acl';
import commonActions from '_common/actions';

export const updateSingleName = (
  arr: Array<TPrinter>,
  i: number,
  propName: string,
  value: string
) => {
  arr[i][propName] = value;
  return arr;
};

export const alternativeNamesDetailsBlock = (
  i: number,
  showError: (fieldName: string, value: boolean) => void,
  state: Object
) => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
  });
  return {
    infoRows: [
      {
        name: 'Alternative name ID',
        translateKey: 'alternativeNameId',
        path: `singleStore.alternativeStoreNames[${i}].id`,
        aclAccess: hasPermissions,
        type: 'input',
        errorData: state,
        errorMessage: 'requiredField',
        showError,
        onUpdateClicked: (updateObj: Object) => {
          if (!String(updateObj.currentValue).length) {
            showError(`singleStore.alternativeStoreNames[${i}].id`, true);
            return true;
          }
          doddleStoresActions.updateStoreById(updateObj.id, {
            alternativeStoreNames: updateSingleName(
              toJS(get(updateObj.data, `singleStore.alternativeStoreNames`)),
              i,
              'id',
              updateObj.currentValue
            ),
          });
        },
      },
      {
        name: 'Description',
        translateKey: 'description',
        path: `singleStore.alternativeStoreNames[${i}].language`,
        aclAccess: hasPermissions,
        type: 'input',
        errorData: state,
        errorMessage: 'requiredField',
        showError,
        onUpdateClicked: (updateObj: Object) => {
          if (!String(updateObj.currentValue).length) {
            showError(`singleStore.alternativeStoreNames[${i}].language`, true);
            return true;
          }
          doddleStoresActions.updateStoreById(updateObj.id, {
            alternativeStoreNames: updateSingleName(
              toJS(get(updateObj.data, `singleStore.alternativeStoreNames`)),
              i,
              'language',
              updateObj.currentValue
            ),
          });
        },
      },
      {
        name: 'Store name',
        path: `singleStore.alternativeStoreNames[${i}].storeName`,
        aclAccess: hasPermissions,
        type: 'input',
        errorData: state,
        errorMessage: 'requiredField',
        showError,
        onUpdateClicked: (updateObj: Object) => {
          if (!String(updateObj.currentValue).length) {
            showError(
              `singleStore.alternativeStoreNames[${i}].storeName`,
              true
            );
            return true;
          }
          doddleStoresActions.updateStoreById(updateObj.id, {
            alternativeStoreNames: updateSingleName(
              toJS(get(updateObj.data, `singleStore.alternativeStoreNames`)),
              i,
              'storeName',
              updateObj.currentValue
            ),
          });
        },
      },
    ],
    translateNS: 'stores',
    count: i + 1,
    idPropName: 'singleStore.storeId',
    header: 'alternative name',
  };
};
