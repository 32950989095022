import { useEffect, useState } from 'react';
import getHealthChecks from '_common/actions/getHealthChecks';
import { checkIsRequestFailed } from '_common/utils/utils';
import * as Sentry from '@sentry/react';

export default (isShown, storeId) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await getHealthChecks();
      Sentry.captureMessage('Connectivity testing results', {
        extra: {
          storeId,
          timestamp: new Date(),
          results: result.map(
            request =>
              `${request.testName}: ${
                checkIsRequestFailed(request) ? 'Fail' : 'Success'
              }`
          ),
        },
      });

      setData(result);
      setIsLoading(false);
    };
    if (isShown) {
      fetchData();
    } else {
      setData([]);
    }
  }, [isShown, storeId]);
  return [data, isLoading];
};
