// @flow

import stores from 'stores';

export default (
  setterMode: string,
  date: string,
  temporaryClosure?: boolean
) => {
  const { companyId, storeId } = stores.doddleStores.singleStore;
  return stores.doddleStores.setOpeningOrClosingDate({
    storeId,
    companyId,
    setterMode,
    date,
    temporary: temporaryClosure,
  });
};
