// @flow

import stores from 'stores';

export default (
  storeId: string,
  data: TStore,
  patch?: boolean,
  rev?: string,
  callback?: () => void
) => {
  const companyId = stores.doddleStores.singleStore.companyId;
  return stores.doddleStores.updateStoreById(
    storeId,
    data,
    companyId,
    patch,
    rev,
    callback
  );
};
