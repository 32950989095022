import styled from 'styled-components';
import { Checkbox } from 'antd';

export const ControlWrapper = styled.div`
  padding: 10px 4px 10px 10px;
  display: inline-block;
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.colors.primary : theme.colors.shade3};
  border-radius: 3px;
`;

export const Control = styled(Checkbox)`
  font-family: ${props => props.theme.fonts.basic};
  font-weight: 400;
  color: ${({ theme, checked }) =>
    checked ? theme.colors.primary : theme.colors.mediumGrey};
  .ant-checkbox-checked::after {
    border: none;
  }
  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${props => props.theme.colors.primary} !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primary};
  }
  .ant-checkbox-inner {
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }
`;
