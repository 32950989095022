// @flow

import React, { Component, Fragment } from 'react';
import { PromotionsStore } from 'stores';
import { getInfoBlock } from '_common/utils/utils';
import { generalBlock } from './config';
import { get } from 'lodash';
import { Col, Row } from 'antd';
import EnablePromotionDialog from 'promotions/components/EnablePromotioDialog';

type Props = {
  promotionsStore: PromotionsStore,
  t: TTranslateFunc,
};

type State = {
  errors: Object,
  isEnableDialogShown: boolean,
  enableDialogSuccessCallback?: Function,
};

class PromotionDetailsGeneral extends Component<Props, State> {

  state = {
    errors: {},
    isEnableDialogShown: false,
    enableDialogSuccessCallback: undefined,
  };

  setError = (fieldName: string, value: any): void => {
    this.setState({
      errors: {
        [fieldName]: value,
      },
    });
  };

  onEnableChange = (enabled: boolean, editableBlockCallback: Function) => {
    if (enabled) {
      this.setState({
        isEnableDialogShown: enabled,
        enableDialogSuccessCallback: editableBlockCallback,
      });
    } else {
      const {
        updateSinglePromotion,
        toggleEnablePromotion,
      } = this.props.promotionsStore;
      updateSinglePromotion(
        toggleEnablePromotion,
        [enabled],
        editableBlockCallback,
        false
      );
    }
  };

  closeEnableDialog = () => {
    this.setState({
      isEnableDialogShown: false,
    });
  };

  render() {
    const { promotionsStore, t } = this.props;
    const {
      errors,
      isEnableDialogShown,
      enableDialogSuccessCallback,
    } = this.state;

    const { singlePromotion, toggleEnablePromotion } = promotionsStore;

    return (
      <Fragment>
        <EnablePromotionDialog
          t={t}
          promotion={singlePromotion}
          toggleEnablePromotion={toggleEnablePromotion}
          successCallback={enableDialogSuccessCallback}
          dialogProps={{
            isShown: isEnableDialogShown,
            handleCancelClick: this.closeEnableDialog,
          }}
        />
        <Row type="flex">
          <Col span={16}>
            {getInfoBlock(
              generalBlock(
                errors,
                this.setError,
                get(promotionsStore, 'singlePromotion'),
                this.onEnableChange
              ),
              false,
              promotionsStore
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }

}

export default PromotionDetailsGeneral;
