// @flow

import { atsClient, lambdaApiClient } from '_common/api/clients/clients';

const getReturnParcelById = async (itemId: string): Promise<TParcelItem> => {
  const params = {
    params: JSON.stringify({ itemId }),
    method: 'items.getReturn',
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return response.data;
};

const getCollectionParcelById = async (
  itemId: string
): Promise<TParcelItem> => {
  const params = {
    params: JSON.stringify({ itemId }),
    method: 'items.getCollection',
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return response.data;
};

const getDlPackageById = async (id: string): Promise<TDlPackageType> => {
  const response = await lambdaApiClient.get(`/v1/dlpackages/${id}`);

  return response.data.resource;
};

const updateReturnParcelById = async (updateObj: Object, itemId: string) => {
  const params = {
    params: JSON.stringify({ itemId }),
    method: 'items.updateReturn',
  };
  const response = await atsClient.put(`/proxy-api`, updateObj, { params });

  return response.data;
};

const updateCollectionParcelById = async (
  updateObj: Object,
  itemId: string
) => {
  const params = {
    params: JSON.stringify({ itemId }),
    method: 'items.updateCollection',
  };
  const response = await atsClient.put(`/proxy-api`, updateObj, { params });

  return response.data;
};

const setParcelCollectionDeadline = async (updateObj: Object) => {
  const params = {
    params: JSON.stringify({ itemId: updateObj.id }),
    method: 'items.updateCollection',
  };
  const response = await atsClient.put(`/proxy-api`, {
    resource: {
      collectionDeadlineDateTime: updateObj.currentValue,
    },
    config: { params },
  });

  return response.data;
};

export default {
  getReturnParcelById,
  getCollectionParcelById,
  getDlPackageById,
  updateReturnParcelById,
  updateCollectionParcelById,
  setParcelCollectionDeadline,
};
