// @flow

export const TABLE_TYPES = {
  MAIN: 'MAIN',
  STORE: 'STORE',
};

export const TABLE_TYPE_USERS_FIELD_MAPPING = {
  [TABLE_TYPES.MAIN]: 'users',
  [TABLE_TYPES.STORE]: 'storeUsers',
};
