export const BARCODE_FORMAT_OPTIONS = [
  {
    label: 'QRCODE',
    value: 'QRCODE',
  },
  {
    label: 'CODE128',
    value: 'CODE128',
  },
];

export const COLLECTION_ID_CHECK_TYPE_OPTIONS = [
  {
    label: 'Name',
    value: 'NAME',
  },
  {
    label: 'Photo',
    value: 'PHOTO',
  },
];
