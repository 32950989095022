// @flow

import React from 'react';
import qs from 'qs';
import type { Location, RouterHistory } from 'react-router';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import stores, { ACLStore, CompaniesStore } from 'stores';
import CompanyDetailsSetup from '../Setup';
import Loader from '_common/components/Loader';
import TabsForm from '_common/components/TabsForm';
import CompanyDetailsCollections from '../Colletions';
import CompanyDetailsConfig from '../Config';
import CompanyDetailsHostMarketing from '../HostMarketing';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

type Props = {
  history: RouterHistory,
  location: Location,
  initialParentTab?: string,
  companiesStore: CompaniesStore,
  aclStore: ACLStore,
  t: TTranslateFunc,
};

type State = {
  isLoading: boolean,
  activeTab: string,
};

class CompanyDetails extends React.Component<Props, State> {

  _initialTab = 'setup';

  state = {
    isLoading: false,
    activeTab: this._initialTab,
  };

  componentDidMount(): void {
    const { location } = this.props;
    const tab = get(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
      'tab',
      this._initialTab
    );

    this.setActiveTab(tab);
    this.loadCompanyById(this.props.aclStore.getSelectedCompanyId);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { companiesStore, aclStore } = this.props;
    const singleCompanyId = companiesStore.getSingleCompanyId;
    const selectedCompanyId = aclStore.getSelectedCompanyId;
    const isCompanyIdChanged =
      (!singleCompanyId && selectedCompanyId) ||
      singleCompanyId !== selectedCompanyId;
    if (!this.state.isLoading && isCompanyIdChanged) {
      if (singleCompanyId) {
        this.setActiveTab(this._initialTab);
      }
      this.loadCompanyById(selectedCompanyId);
    }
  }

  componentWillUnmount() {
    this.props.companiesStore.resetSingleCompany();
  }

  getTabsConfig = (hostMarketingSettingsAccess: boolean): Array<TTabConfig> => {
    const { t } = this.props;
    const result = [
      {
        caption: t('setup'),
        url: 'setup',
        component: CompanyDetailsSetup,
        props: {
          companiesStore: this.props.companiesStore,
        },
        skipRef: true,
      },
      {
        caption: t('collections'),
        url: 'collections',
        component: CompanyDetailsCollections,
        skipRef: true,
      },
      {
        caption: t('config'),
        url: 'config',
        component: CompanyDetailsConfig,
        props: {
          companiesStore: this.props.companiesStore,
        },
        skipRef: true,
      },
    ];

    if (hostMarketingSettingsAccess) {
      result.push({
        caption: t('hostMarketing'),
        url: 'hostMarketing',
        component: CompanyDetailsHostMarketing,
        skipRef: true,
      });
    }

    return result;
  };

  setActiveTab = (activeTab: string) => {
    this.setState({ activeTab });
    this.onTabChange(activeTab);
  };

  loadCompanyById = (id: string) => {
    if (id) {
      const { companiesStore, history } = this.props;
      this.setState({
        isLoading: true,
      });
      companiesStore.loadSingleCompanyById(id, true).then((result: boolean) => {
        if (result) {
          this.setState({
            isLoading: false,
          });
        } else {
          history.push(stores.aclStore.getDefaultRedirectUrl());
        }
      });
    }
  };

  onTabChange = (key: string) => {
    this.props.history.push({
      search: `?tab=${key}`,
    });
  };

  render() {
    const {
      companiesStore: {
        singleCompany: { companyName },
      },
      aclStore: {
        getSelectedCompanyId: companyId,
        getHostMarketingPortalSettingsAccess,
      },
    } = this.props;
    const { isLoading, activeTab } = this.state;

    return isLoading ? (
      <Loader />
    ) : (
      <TabsForm
        onTabChange={this.onTabChange}
        tabsConfig={this.getTabsConfig(getHostMarketingPortalSettingsAccess)}
        title={`${companyName ? companyName : '-'} ${
          companyId ? `(${companyId})` : ''
        }`}
        activeTab={activeTab}
      />
    );
  }

}

export default compose(
  inject('companiesStore', 'aclStore'),
  withTranslation('company'),
  observer
)(CompanyDetails);
