import React, { Component, Fragment } from 'react';
import { OfferItem, OfferTemplate } from './elements';
import Button from '_common/components/Button/Button';
import { Switch, Col, Row, Tooltip } from 'antd';

type Props = {
  t: TTranslateFunc,
  offers: Array<TPromotionOffer>,
  deleteOffer: Function,
  toggleEnableOffer: Function,
};

type State = {};

class OffersTable extends Component<Props, State> {

  changeEnable = (offerId: string) => (enabled: boolean) => {
    this.props.toggleEnableOffer(offerId, enabled);
  };

  deleteOffer = (offerId: string) => () => {
    if (window.confirm(`Are you sure to delete ${offerId}?`)) {
      this.props.deleteOffer(offerId);
    }
  };

  render() {
    const { offers, t } = this.props;

    return (
      <Fragment>
        {offers.map((offer: TPromotionOffer) => (
          <OfferItem key={offer.offerId}>
            <Col xs={18}>
              <OfferTemplate>{offer.template}</OfferTemplate>
            </Col>
            <Col xs={6}>
              <Row justify="end" align="middle">
                <Tooltip
                  overlayStyle={{ fontSize: 13 }}
                  placement="top"
                  title={t(offer.enabled ? 'disableOffer' : 'enableOffer')}
                >
                  <Switch
                    style={{ marginRight: '10px' }}
                    defaultChecked={offer.enabled}
                    onChange={this.changeEnable(offer.offerId)}
                  />
                </Tooltip>
                <Button onClick={this.deleteOffer(offer.offerId)}>
                  {t('common:delete')}
                </Button>
              </Row>
            </Col>
          </OfferItem>
        ))}
      </Fragment>
    );
  }

}

export default OffersTable;
