// @flow strict

import links from '_common/routes/urls';
import CompanyCreate from 'companies/companiesCreate/Main';
import CompanyDetails from 'companies/companyDetails/Main';

export default [
  {
    path: links.companies.subroutes.createCompany,
    component: CompanyCreate,
  },
  {
    path: links.companies.subroutes.singleCompany,
    component: CompanyDetails,
  },
];
