import styled, { css } from 'styled-components';
import Button from '_common/components/Button/Button';
import { IconWrapper } from '_common/components/Icon/element';

export const DismissWrapper = styled.div`
  position: ${props => props.position};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 1100px;
  z-index: 10000;
  background-color: #5427850a;
`;

export const DialogRoot = styled.div`
  width: ${props => (props.width ? props.width : '640px')};
  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `};
  border: 1px solid #e6e7e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
  position: fixed;
  margin: 0 auto;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  z-index: 10000;
  display: block;
  @media only screen and (max-width: 576px) {
    transform: none;
    top: 30%;
    left: 0;
    right: 0;
    width: auto;
  }
`;

// Thanks IE
export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > ${IconWrapper} {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`;

// Thanks IE
export const DialogWrapperInner = styled.div`
  padding: 46px 46px 41px;
  ${props =>
    !props.height &&
    css`
      min-height: ${props => (props.minHeight ? props.minHeight : 320)}px;
    `};
  min-width: ${({ minWidth = 300 }) => minWidth}px;
  ${props =>
    props.skipMaxHeight || props.height
      ? css`
          height: ${props => (props.height ? `${props.height}px` : '100%')};
        `
      : css`
          max-height: 500px;
        `};
  display: flex;
  flex-direction: column;
  justify-content: ${({ innerWrapperFlexAlign = 'space-between' }) =>
    innerWrapperFlexAlign};
  @media only screen and (max-width: 576px) {
    padding: 30px;
  }
`;

export const BodyCustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-between;
`;

export const Header = styled.div`
  padding-bottom: 15px;
  margin-bottom: 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'space-between'};
  font-size: ${props => props.fontSize}px;
`;

export const HeaderText = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : 'inherit')};
  color: ${({ color = '#494949' }) => color};
`;

export const ConfirmButton = styled(Button)`
  && {
    font-family: ${props => props.theme.fonts.basic};
    font-size: 16px;
    border-radius: 4px;
    height: 48px;
    width: 260px;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const CancelButton = styled(ConfirmButton)`
  && {
    background: ${props => props.theme.colors.secondary};
    :hover {
      background: ${props => props.theme.colors.secondary};
    }
    :disabled {
      background: ${props => props.theme.colors.shade2};
    }
    :hover:disabled {
      background: ${props => props.theme.colors.shade2};
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    .ant-btn {
      height: 40px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
