// @flow

import React from 'react';
import { HeaderWrapper, LogoWrapper } from './elements';
import { Logo, Menu } from '_common/components';
import ActionsDropdown from './ActionsDropdown';
import links from '_common/routes/urls';
import CompaniesSelector from './CompaniesSelector';
import SettingsButton from './SettingsButton';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import stores, { CompaniesStore } from 'stores';

type Props = {
  companiesStore: CompaniesStore,
};

class Header extends React.Component<Props, any> {

  render() {
    return (
      <HeaderWrapper>
        <div style={{ display: 'flex' }}>
          <LogoWrapper>
            <Logo
              path={links.dashboard}
              url={this.props.companiesStore.companyLogo}
              alt={'Doddle'}
            />
          </LogoWrapper>
          <Menu />
          {!stores.aclStore.isSingleCompanyRole && <CompaniesSelector />}
          <SettingsButton />
        </div>
        <ActionsDropdown />
      </HeaderWrapper>
    );
  }

}

export default compose(inject('companiesStore'), observer)(Header);
