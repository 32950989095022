// @flow

import * as React from 'react';
import { StoreTabsWrapper } from '_common/components';
import { Tabs } from 'antd';
import qs from 'qs';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import type { Location, RouterHistory, Match } from 'react-router';
import { EditOutlined } from '@ant-design/icons';
import {
  CreateStoreButton,
  Header,
  SendOnboardingEmailButton,
  StoreBottomInfo,
  StoreName,
  StoreTopInfo,
  StoreTopInfoHolder,
  StoreZip,
  StyledPanel,
  StyledPanelWrapper,
} from './elements';
import DoddleStoreDetails from 'doddleStores/doddleStoresDetails/pages/DoddleStoreDetails/DoddleStoreDetails';
import UsersTable from 'users/pages/UsersTable';
import doddleStoreActions from 'doddleStores/actions';
import Loader from '_common/components/Loader';
import { EditLink } from 'doddleStores/doddleStoresList/components/elements';
import urls from '_common/routes/urls';
import { ACLStore, CompaniesStore, DoddleStores } from 'stores';
import { withTranslation } from 'react-i18next';
import {
  getStoreLastEvent,
  getStoreStatusElement,
  isStoreApproved,
} from '_common/utils/utils';
import { get } from 'lodash';
import { Roles } from '_common/constants/acl';
import { TABLE_TYPES } from '_common/constants/users';
import { STORE_EVENT_TYPES } from '_common/constants/stores';

type Props = {|
  location: Location,
  history: RouterHistory,
  match: Match,
  doddleStores: DoddleStores,
  companiesStore: CompaniesStore,
  aclStore: ACLStore,
  t: TTranslateFunc,
|};

type State = {|
  isLoading: boolean,
  activeTabName: string,
  hideDateFilter: boolean,
|};

class Main extends React.Component<Props, State> {

  componentWillUnmount() {
    doddleStoreActions.resetSingleStore();
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      doddleStores,
      aclStore,
    } = this.props;

    this.setState({
      isLoading: true,
    });

    if (id) {
      const allowedSensitiveData = aclStore.storeDetailsFullAccess;
      doddleStores
        .loadSingleStore(id, { sensitiveDataFlag: allowedSensitiveData })
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });

      this.setState({
        activeTabName: this.getActiveTab(),
      });
    }
  }

  TABS_KEYS = {
    details: 'STORE DETAILS',
    users: 'USERS',
  };

  state = {
    hideDateFilter: false,
    activeTabName: '',
    isLoading: false,
  };

  getActiveTab = () => {
    const { location } = this.props;

    let { tab } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (!tab) {
      tab = this.TABS_KEYS.details;
    }

    return tab.split(';')[0];
  };

  onTabClick = (activeTabName: string) => {
    this.setState({
      activeTabName,
    });
    this.props.history.push({
      search: `?tab=${activeTabName}`,
    });
  };

  getStatus = (status: string) => {
    const { t, doddleStores } = this.props;
    const { OPENING_DATE, CLOSING_DATE } = STORE_EVENT_TYPES;
    return getStoreStatusElement(status, t, {
      openingDate: get(
        getStoreLastEvent(doddleStores.singleStore, OPENING_DATE),
        'openingDate'
      ),
      closingDate: get(
        getStoreLastEvent(doddleStores.singleStore, CLOSING_DATE),
        'closingDate'
      ),
    });
  };

  onUserCreate = () => {
    this.props.history.push(
      urls.user.createStoreUser.replace(
        ':storeId',
        this.props.doddleStores.singleStore.storeId
      )
    );
  };

  sendOnboardingEmail = () => {
    const { singleStore, sendOnboardingEmail } = this.props.doddleStores;
    sendOnboardingEmail(singleStore.storeId);
  };

  render() {
    const {
      location,
      history,
      match,
      doddleStores,
      aclStore,
      t,
      companiesStore,
    } = this.props;
    const {
      singleStore: { storeName, status, storeId, place },
    } = doddleStores;
    const { selectedCompany } = companiesStore;
    const { isLoading, activeTabName } = this.state;
    const isStoreRole = aclStore.isStoreRole;
    const canCreateUsers = aclStore.isStrictAccessGranted({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
      scopes: [],
    });
    const canSendOnboardingEmail =
      isStoreApproved(status) && selectedCompany.storeOnboardingEmailEnabled;

    return isLoading ? (
      <Loader />
    ) : (
      <div>
        <Header>
          <StoreTopInfoHolder>
            <StoreTopInfo>
              <StoreName>{storeName || ''}</StoreName>
              <StoreZip>{`(${storeId})` || ''}</StoreZip>
              {this.getStatus(status)}
              {canSendOnboardingEmail && (
                <SendOnboardingEmailButton onClick={this.sendOnboardingEmail}>
                  {t('sendOnboardingEmail')}
                </SendOnboardingEmailButton>
              )}
            </StoreTopInfo>
            {activeTabName === this.TABS_KEYS.users && canCreateUsers && (
              <CreateStoreButton onClick={this.onUserCreate}>
                {t('createUser')}
              </CreateStoreButton>
            )}
          </StoreTopInfoHolder>
          <StoreBottomInfo>
            {['line1', 'line2', 'line3'].map((cur) => {
              const data = get(place, `address.${cur}`);
              return data && data.trim() ? `${data}, ` : '';
            })}
            {`${get(place, 'address.town', '')} `}
            {get(place, 'address.postcode', '')}
          </StoreBottomInfo>
        </Header>
        <StoreTabsWrapper>
          <Tabs
            defaultActiveKey={this.getActiveTab()}
            animated={false}
            onChange={this.onTabClick}
            type="card"
            items={[
              {
                key: this.TABS_KEYS.details,
                label: t('storeDetails'),
                children: (
                  <StyledPanelWrapper>
                    <StyledPanel>
                      <DoddleStoreDetails
                        location={location}
                        history={history}
                        initialParentTab={this.TABS_KEYS.details}
                        match={match}
                      />
                    </StyledPanel>
                  </StyledPanelWrapper>
                ),
              },
              {
                key: this.TABS_KEYS.users,
                label: t('users'),
                children: (
                  <StyledPanelWrapper>
                    <StyledPanel>
                      <UsersTable
                        canEditUsers={!isStoreRole}
                        fixedStore
                        ignorePanel
                        type={TABLE_TYPES.STORE}
                        storeId={storeId}
                        history={history}
                        EditLinkComponent={
                          <EditLink>
                            <EditOutlined />
                          </EditLink>
                        }
                      />
                    </StyledPanel>
                  </StyledPanelWrapper>
                ),
              },
            ]}
          />
        </StoreTabsWrapper>
      </div>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores', 'aclStore', 'companiesStore'),
  observer
)(Main);
