// @flow

import React, { useState, useEffect } from 'react';
import env from '@beam-australia/react-env';
import { Logo } from '_common/components';
import { useDidUpdateEffect } from '_common/hooks';
import defaultLogo from 'assets/images/logo.svg';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router';
import qs from 'qs';
import storage, { COMPANY_LOGO } from 'storage';

type Props = {
  savedLogo?: string,
  queryCompanyId?: string,
  history: RouterHistory,
};

const COMPANY_LOGO_BUCKET_URL = env('COMPANY_LOGO_BUCKET_URL');

const LOGO_VARIANTS = {
  SAVED_LOGO: 'SAVED_LOGO',
  QUERY_LOGO_PNG: 'QUERY_LOGO_PNG',
  QUERY_LOGO_JPEG: 'QUERY_LOGO_JPEG',
  DEFAULT_LOGO: 'DEFAULT_LOGO',
};

const CompanyLogo = ({ savedLogo, history }: Props) => {
  const [logoVariant, setLogoVariant] = useState(
    savedLogo ? LOGO_VARIANTS.SAVED_LOGO : LOGO_VARIANTS.DEFAULT_LOGO
  );

  const [queryCompanyId, setQueryCompanyId] = useState();

  useEffect(() => {
    const { companyId } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    setQueryCompanyId(companyId);
  }, [history]);

  useDidUpdateEffect(() => {
    if (queryCompanyId) {
      setLogoVariant(LOGO_VARIANTS.QUERY_LOGO_PNG);
    } else {
      setLogoVariant(LOGO_VARIANTS.SAVED_LOGO);
    }
  }, [queryCompanyId]);

  const getLogo = () => {
    const props = {
      maxHeight: 100,
      path: '/',
      alt: 'Doddle',
      url: '',
      onError: null,
    };

    const queryCompanyLogoTemplate = `${
      COMPANY_LOGO_BUCKET_URL ? COMPANY_LOGO_BUCKET_URL : ''
    }/${queryCompanyId ? queryCompanyId : ''}`;

    switch (logoVariant) {
      case LOGO_VARIANTS.QUERY_LOGO_PNG:
        props.url = `${queryCompanyLogoTemplate}.png`;
        props.onError = setLogoVariant.bind(
          null,
          LOGO_VARIANTS.QUERY_LOGO_JPEG
        );
        break;
      case LOGO_VARIANTS.QUERY_LOGO_JPEG:
        props.url = `${queryCompanyLogoTemplate}.jpeg`;
        props.onError = setLogoVariant.bind(null, LOGO_VARIANTS.SAVED_LOGO);
        break;
      case LOGO_VARIANTS.SAVED_LOGO:
        props.url = savedLogo ? savedLogo : Date.now().toString();
        props.onError = setLogoVariant.bind(null, LOGO_VARIANTS.DEFAULT_LOGO);
        break;
      default: {
        props.url = defaultLogo;
      }
    }

    return <Logo {...props} />;
  };

  return getLogo();
};

CompanyLogo.defaultProps = {
  savedLogo: storage.get(COMPANY_LOGO),
};

export default withRouter(CompanyLogo);
