// @flow

import React, { useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router';
import { isEmpty } from 'lodash';
import stores from 'stores';
import {
  LoginTitle,
  LoginInput,
  LoginInputLabel,
  LoginInputWrapper,
  LoginButton,
  LoginFormWrapper,
  LogoWrapper,
  NewPasswordInput,
} from './elements/Login';
import { withTranslation } from 'react-i18next';
import Notifications from '_common/components/Notifications';
import { passwordRegExp } from '_common/utils/utils';
import { Col, Row, Form } from 'antd';
import commonAction from '_common/actions';
import CompanyLogo from './components/CompanyLogo';
import qs from 'qs';
import { useComponentWillMount } from '_common/hooks';

type Props = {
  history: RouterHistory,
  t: TTranslateFunc,
};

const ChangePassword = ({ history, t }: Props) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [decodedOnboardingToken, setDecodedOnboardingToken] = useState({});

  const redirectToDefaultPage = () => {
    history.push(commonAction.getDefaultRedirectUrl());
  };

  const redirectToLoginPage = () => {
    history.push('/login');
  };

  useComponentWillMount(() => {
    // User is redirected from onboarding email
    // he is not signed in yet, but has password reset token from email
    const { onboardingToken } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    if (onboardingToken) {
      if (!isEmpty(stores.authStore.getLoggedUserField)) {
        stores.authStore.logout(false);
      }
      const expectedKeys = ['login', 'organisationId', 'resetToken'];
      try {
        const decodedToken = JSON.parse(atob(onboardingToken));
        if (
          expectedKeys.some(key => !Object.keys(decodedToken).includes(key))
        ) {
          throw new Error("Onboarding token doesn't have all expected keys");
        }
        const { login, organisationId } = decodedToken;
        if (stores.aclStore.userHasSelectedCompany(login, organisationId)) {
          throw new Error('User has already reset password');
        }
        setUserId(login);
        setDecodedOnboardingToken(decodedToken);
      } catch (e) {
        console.warn(e);
        redirectToLoginPage();
      }
    } else {
      // Otherwise - regular flow when user firstly signs in with temporary password
      // and then needs to reset it
      const {
        temporaryPassword,
        userId,
        organisationId,
      } = stores.authStore.tempUser;
      if (!temporaryPassword || !userId || !organisationId) {
        stores.authStore.getLoggedUserField
          ? redirectToDefaultPage()
          : redirectToLoginPage();
      } else {
        setUserId(userId);
      }
    }
  });

  const changePassword = ({ password, passwordRepeat }: Object) => {
    setIsLoading(true);

    if (password !== passwordRepeat) {
      form.setFields([
        {
          name: 'passwordRepeat',
          value: passwordRepeat,
          errors: [t('notifications:passwordsMismatch')],
        },
      ]);
      setIsLoading(false);
    } else {
      if (isEmpty(decodedOnboardingToken)) {
        const { organisationId, userId } = stores.authStore.tempUser;
        if (organisationId && userId) {
          stores.usersStore
            .resetPassword(organisationId, userId, password, {
              temporaryPassword: false,
              signIn: true,
            })
            .then((result: boolean) => {
              if (result) {
                redirectToDefaultPage();
              } else {
                setIsLoading(false);
              }
            });
        }
      } else {
        stores.usersStore
          .changePasswordForOnboardingUser(decodedOnboardingToken, password)
          .then((result: boolean) => {
            result ? redirectToLoginPage() : redirectToDefaultPage();
          });
      }
    }
  };

  return (
    <Fragment>
      <Notifications />
      <Row justify="center">
        <Col xs={24} md={16} lg={12} xl={10} xxl={9}>
          <LogoWrapper>
            <CompanyLogo />
          </LogoWrapper>
          <LoginFormWrapper>
            <LoginTitle>{t('changePassword')}</LoginTitle>
            <Form form={form} onFinish={changePassword}>
              <LoginInputWrapper>
                <LoginInputLabel htmlFor={'email'}>
                  {t('email')}
                </LoginInputLabel>
                <Form.Item>
                  <LoginInput
                    disabled
                    id={'email'}
                    placeholder={t('enterEmail')}
                    value={userId}
                  />
                </Form.Item>
              </LoginInputWrapper>
              <LoginInputWrapper>
                <LoginInputLabel htmlFor={'pwd'}>
                  {t('newPassword')}
                </LoginInputLabel>
                <Form.Item
                  name="password"
                  help={t('passwordHelp')}
                  rules={[
                    {
                      required: true,
                      pattern: passwordRegExp,
                      message: t('inputNewPassword'),
                    },
                  ]}
                >
                  <NewPasswordInput
                    autoComplete="new-password"
                    id={'pwd'}
                    type={'password'}
                    placeholder={t('enterNewPassword')}
                  />
                </Form.Item>
              </LoginInputWrapper>
              <LoginInputWrapper>
                <LoginInputLabel htmlFor={'pwdRepeat'}>
                  {t('passwordRepeat')}
                </LoginInputLabel>
                <Form.Item
                  name="passwordRepeat"
                  rules={[
                    {
                      required: true,
                      message: t('inputNewPassword'),
                    },
                  ]}
                >
                  <NewPasswordInput
                    id={'pwdRepeat'}
                    type={'password'}
                    placeholder={t('passwordRepeat')}
                  />
                </Form.Item>
              </LoginInputWrapper>
              <LoginButton disabled={isLoading} htmlType="submit">
                {t('common:confirm')}
              </LoginButton>
            </Form>
          </LoginFormWrapper>
        </Col>
      </Row>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withTranslation('login'),
  observer
)(ChangePassword);
