import React, { Fragment } from 'react';
import {
  StaticPromotionSection,
  StaticPromotionWrapper,
  StaticPromotionContent,
  StaticPromotionTitle,
  ImagePreview,
  ColorableText,
} from './elements';
import { Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PromotionsStore } from 'stores';
import { get, some, includes, merge, isEmpty } from 'lodash';
import UsersStore from 'users/stores/usersStore';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { barcodeImage } from 'assets';
import { convertFileDataToBase64URL, parseRGBA } from '_common/utils/utils';

type Props = {
  t: TTranslateFunc,
  formFields: any,
  promotionsStore: PromotionsStore,
  isNewMode: boolean,
  usersStore: UsersStore,
};

const DynamicPromotionRepresentation = ({
  t,
  isNewMode,
  formFields,
  promotionsStore: { singlePromotion, getOfferFromCache },
  usersStore,
}: Props) => {
  if (isNewMode && !formFields) {
    return null;
  }

  const sourceObject = isNewMode
    ? formFields
    : merge({ ...toJS(singlePromotion) }, formFields);
  const colorType = 'rgba';
  const colourScheme = [
    'bgColour',
    'borderColour',
    'offerTextColour',
    'tcLinkColour',
  ].reduce((acc, currentProp) => {
    const cur = get(sourceObject, `colourScheme.${currentProp}.${colorType}`);
    const rgba = get(cur, 'rgb', false);
    acc[currentProp] = rgba ? parseRGBA(rgba) : cur;
    return acc;
  }, {});

  const getNewOfferText = () => {
    const { offerId } = formFields;
    const variables = formFields[offerId];
    if (!variables) {
      return;
    }
    const variablesKeys = Object.keys(variables);
    if (some(variablesKeys, key => !Boolean(variables[key]))) {
      return;
    }

    const offer = getOfferFromCache(offerId);
    const formattedTemplate = variablesKeys.reduce(
      (fmtTemplate, variableKey) => {
        fmtTemplate = fmtTemplate.replace(
          `\${${variableKey}}`,
          variables[variableKey]
        );
        return fmtTemplate;
      },
      offer.template
    );
    return !includes(formattedTemplate, 'undefined') && formattedTemplate;
  };

  const getImageSource = () => {
    const { image } = sourceObject;
    let result = image;
    if (typeof image === 'object') {
      result = convertFileDataToBase64URL(formFields.image);
    }
    return result;
  };

  const config = {
    ...colourScheme,
    tcLink: get(sourceObject, 'tcLink'),
    offerText: isNewMode
      ? getNewOfferText()
      : getNewOfferText() || get(sourceObject, 'offer.offerText'),
  };

  const imageURL = formFields.image ? getImageSource() : singlePromotion.image;

  const renderAssetsSection = () => {
    const formHasPromoDisplayType = Object.keys(formFields).filter(x =>
      ['promotionCode', 'image', 'barcode'].includes(x)
    );
    const source =
      isEmpty(formHasPromoDisplayType) && !isNewMode
        ? singlePromotion
        : formFields;

    const { barcode, promotionCode } = source;
    let result = <ColorableText>{t('promoBcOrPi')}</ColorableText>;
    if (imageURL) {
      result = <ImagePreview src={imageURL} />;
    }

    if (barcode) {
      result = <ImagePreview src={barcodeImage} fixedSize />;
    }

    if (promotionCode) {
      result = (
        <ColorableText color={config.offerTextColour}>
          {promotionCode}
        </ColorableText>
      );
    }

    return result;
  };

  return (
    <Fragment>
      <StaticPromotionTitle>{t('promotionLayout')}</StaticPromotionTitle>
      <StaticPromotionWrapper bgColour={config.bgColour}>
        <StaticPromotionContent
          borderColour={config.borderColour}
          withMargin={imageURL}
        >
          {usersStore.currentCompany === MAIN_COMPANIES.AUSTRALIA_POST_ID && (
            <StaticPromotionSection>{t('companyLogo')}</StaticPromotionSection>
          )}
          <StaticPromotionSection
            color={config.offerTextColour}
            paddingTop={16}
            paddingBottom={16}
            paddingLeft={38}
            paddingRight={38}
          >
            {config.offerText || t('offerType')}
          </StaticPromotionSection>
          <Row type="flex" justify="center">
            <StaticPromotionSection
              withMargin={!imageURL}
              paddingTop={10}
              paddingBottom={10}
              paddingLeft={10}
              paddingRight={10}
            >
              {renderAssetsSection()}
            </StaticPromotionSection>
          </Row>
          <StaticPromotionSection
            paddingTop={10}
            paddingBottom={10}
            paddingLeft={50}
            paddingRight={50}
            color={config.tcLinkColour}
          >
            {config.tcLink ? (
              <a
                href={
                  config.tcLink.startsWith('http')
                    ? config.tcLink
                    : `https://${config.tcLink}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('tc')}
              </a>
            ) : (
              <ColorableText color={config.tcLinkColour}>
                {t('tc')}
              </ColorableText>
            )}
          </StaticPromotionSection>
        </StaticPromotionContent>
      </StaticPromotionWrapper>
    </Fragment>
  );
};

export default compose(
  withTranslation('promotions'),
  inject('promotionsStore', 'usersStore'),
  observer
)(DynamicPromotionRepresentation);
