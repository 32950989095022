// @flow

import React, { Fragment } from 'react';
import qs from 'qs';
import DoddleStoreDetailsGeneral from './pages/general/DoddleStoreDetailsGeneral';
import DoddleStoreDetailsServices from './pages/services/DoddleStoreDetailsServices';
import DoddleStoreDetailsAvailability from './pages/availability/DoddleStoreDetailsAvailability';
import DoddleStoreDetailsConfig from './pages/config/DoddleStoreDetailsConfig';
import DoddleStoreDetailsAlternativeNames from './pages/alternativeNames/DoddleStoreDetailsAlternativeNames';
import DoddleStoreDetailsPrinters from './pages/printers/DoddleStoreDetailsPrinters';
import DoddleStoreDetailsPhotos from './pages/photos/DoddleStoreDetailsPhotos';
import type { Location, RouterHistory } from 'react-router';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import DoddleStoresStore from '_common/stores/doddleStoresStore';
import RawData from '_common/components/RawData';
import { withTranslation } from 'react-i18next';
import stores from 'stores';
import TabsForm from '_common/components/TabsForm';
import { get } from 'lodash';

type Props = {
  history: RouterHistory,
  location: Location,
  initialParentTab?: string,
  doddleStores: DoddleStoresStore,
  t: TTranslateFunc,
};

type State = {
  isLoading: boolean,
};

class DoddleStoreDetails extends React.Component<Props, State> {

  state = {
    isLoading: false,
  };

  getTabKeys = (): Array<TTabConfig> => {
    const { doddleStores, t } = this.props;
    const fullAccess = stores.aclStore.storeDetailsFullAccess;

    return [
      {
        caption: t('general'),
        url: 'general',
        component: DoddleStoreDetailsGeneral,
        props: {
          data: doddleStores,
        },
        skipRef: true,
      },
      {
        caption: t('services'),
        url: 'services',
        component: DoddleStoreDetailsServices,
        props: {
          data: doddleStores,
        },
        access: fullAccess,
        skipRef: true,
      },
      {
        caption: t('openingHours'),
        url: 'openingHours',
        component: DoddleStoreDetailsAvailability,
        props: {
          data: doddleStores,
        },
        skipRef: true,
      },
      {
        caption: t('config'),
        url: 'config',
        component: DoddleStoreDetailsConfig,
        props: {
          data: doddleStores,
        },
        access: fullAccess,
        skipRef: true,
      },
      {
        caption: t('photos'),
        url: 'photos',
        component: DoddleStoreDetailsPhotos,
        props: {
          data: doddleStores,
        },
        access: fullAccess,
        skipRef: true,
      },
      {
        caption: t('printers'),
        url: 'printers',
        component: DoddleStoreDetailsPrinters,
        props: {
          data: doddleStores,
        },
        access: fullAccess,
        skipRef: true,
      },
      {
        caption: t('alternativeNames'),
        url: 'alternativeStoreNames',
        component: DoddleStoreDetailsAlternativeNames,
        props: {
          data: doddleStores,
        },
        access: fullAccess,
        skipRef: true,
      },
      {
        caption: t('eventHistory'),
        url: 'eventHistory',
        component: RawData,
        props: {
          header: t('eventHistory'),
          notFoundMessage: '',
          data: get(doddleStores, 'singleStore.eventHistory'),
        },
        access: fullAccess,
        skipRef: true,
      },
    ];
  };

  getActiveTab = () => {
    const { location } = this.props;

    let { tab } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const splittedAddress = tab ? tab.split(';') : '';

    if (!tab || !splittedAddress[1]) {
      tab = this.getTabKeys()[0].url;

      return tab;
    }

    return splittedAddress[1];
  };

  onTabClick = (key: string) => {
    const currentTab = this.props.location.search.split('=')[1];

    let addr = '';
    if (currentTab) {
      addr = currentTab.split(';')[0];
    } else {
      addr = this.props.initialParentTab;
    }

    this.props.history.push({
      search: `?tab=${addr || ''};${key}`,
    });
  };

  render() {
    return (
      <TabsForm
        PanelElement={Fragment}
        onTabChange={this.onTabClick}
        tabsConfig={this.getTabKeys()}
        activeTab={this.getActiveTab()}
      />
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  observer
)(DoddleStoreDetails);
