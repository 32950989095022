import styled from 'styled-components';
import { PanelSectionTitle } from '_common/components';

export const CustomerSectionTitle = styled(PanelSectionTitle)`
  margin-bottom: 30px;
`;

export const FormWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 13px;
  }
`;

export const ErrorMessage = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
  color: #ff0000;
`;
