// @flow strict

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'pollyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import env from '@beam-australia/react-env';
import { configure } from 'mobx';
import { Provider as MobxProvider } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import App from '_common/routes/App/App';
import themes from './themes';
import './themes/globals.css';
import './i18n';
import stores from './stores';
import bootstrap from 'bootstrap';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';

// $FlowIssue
configure({ enforceActions: 'observed' });

const root = document.getElementById('root');

if (!root) {
  throw new Error('Cannot find root element');
}

bootstrap();

const { origin, pathname, search } = window.location;

// Initialize Google Tag Manager.
const tagManagerArgs = {
  gtmId: env('GOOGLE_TAG_MANAGER_KEY') || '',
  dataLayerName: 'PageDataLayer',
};
TagManager.initialize(tagManagerArgs);

// Initialize Google Analytics.
ReactGA.initialize(env('GOOGLE_ANALYTICS_KEY') || '');
ReactGA.pageview(origin + pathname + search);

Sentry.init({ dsn: env('SENTRY_DSN') });

ReactDOM.render(
  <MobxProvider {...stores}>
    <ThemeProvider theme={themes['default']}>
      <App />
    </ThemeProvider>
  </MobxProvider>,
  root
);
