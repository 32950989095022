// @flow

import stores from 'stores';
import type { TFeaturePass } from '_common/stores/aclStore';

export default (
  featurePass: TFeaturePass,
  ...validatorVars: Array<any>
): boolean => {
  return stores.aclStore.canAccessFeature(featurePass, ...validatorVars);
};
