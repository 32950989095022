import React, { Fragment } from 'react';
import { Header } from '_common/components/InfoBlock/elements';
import { LastInfoBlockContainer } from '_common/utils/utilsElements';
import {
  Divider,
  PhotosHolder,
} from '_common/components/ImageUploadBlock/elements';
import ImageUploadBlock from '_common/components/ImageUploadBlock';
import commonActions from '_common/actions';
import { Roles } from '_common/constants/acl';
import { get } from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

type Props = {
  logos: TCompanyLogosType,
  updateCompanyPhotos: Function,
  deleteCompanyPhotos: Function,
  companyId: string,
  t: TTranslateFunc,
};

const BrandingBlock = ({
  logos,
  updateCompanyPhotos,
  deleteCompanyPhotos,
  companyId,
  t,
}: Props) => {
  const updateImage = (imageKey: string) => (imageObject: TFileDataType) => {
    updateCompanyPhotos(companyId, { [imageKey]: imageObject });
  };

  const deleteImage = (imageKey: string) => () => {
    deleteCompanyPhotos(companyId, [imageKey]);
  };

  const companyLogos = ['default', 'thumbnail'];

  return (
    <LastInfoBlockContainer>
      <Header>{t('branding')}</Header>
      <PhotosHolder>
        {companyLogos.map((logoKey, index) => (
          <Fragment key={logoKey}>
            <ImageUploadBlock
              hasPermissions={commonActions.canAccessFeature({
                roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
              })}
              src={get(logos, logoKey)}
              caption={t(`common:${logoKey}Image`)}
              onDelete={deleteImage(logoKey.toUpperCase())}
              onReplace={updateImage(logoKey)}
            />
            {index !== companyLogos.length - 1 && <Divider />}
          </Fragment>
        ))}
      </PhotosHolder>
    </LastInfoBlockContainer>
  );
};

export default compose(
  inject('companiesStore'),
  withTranslation('company'),
  observer
)(BrandingBlock);
