// @flow

import React, { Component } from 'react';
import { PromotionsStore, UsersStore } from 'stores';
import { Row, Col } from 'antd';
import PromoOfferBlock from './PromoOfferBlock';
import { getInfoBlock } from '_common/utils/utils';
import { otherBlock } from './config';
import StaticPromotionRepresentation from 'promotions/components/StaticPromotionRepresentation';
import DynamicPromotionRepresentation from 'promotions/components/DynamicPromotionRepresentation';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

import SendEmailDialog from 'promotions/components/SendEmailDialog';
import { withTranslation } from 'react-i18next';
import PromotionDisplayTypeBlock from './PromotionDisplayTypeBlock';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { set, omit, isEmpty } from 'lodash';
import { ActionButton } from '_common/components/PlainStyles';
import ReactDOM from 'react-dom';
import { PROMOTION_ACTION_BUTTONS_CONTAINER_ID } from '_common/constants/promotions';

type Props = {
  promotionsStore: PromotionsStore,
  usersStore: UsersStore,
  t: TTranslateFunc,
};

type State = {
  errors: Object,
  isSendEmailDialogOpened: boolean,
  fieldsInEdit: Array<string>,
  livePreviewData: Object,
  togglePromotionLayoutUpdates: boolean,
};

class PromotionDetailsMarketingMessage extends Component<Props, State> {

  state = {
    errors: {},
    isSendEmailDialogOpened: false,
    fieldsInEdit: [],
    livePreviewData: {},
    togglePromotionLayoutUpdates: false,
  };

  setError = (fieldName: string, value: any): void => {
    this.setState({
      errors: {
        [fieldName]: value,
      },
    });
  };

  addFieldInEdit = (fields: string | Array<string>) => {
    const fieldsToAdd = Array.isArray(fields) ? fields : [fields];
    this.setState(state => ({
      fieldsInEdit: [...state.fieldsInEdit, ...fieldsToAdd],
    }));
  };

  removeFieldInEdit = (fields: string | Array<string>) => {
    const fieldsToRemove = Array.isArray(fields) ? fields : [fields];
    const fieldsToRemoveFromLivePreviewData = [];
    const { livePreviewData } = this.state;
    if (fields === 'offerText') {
      fieldsToRemoveFromLivePreviewData.push(
        'offerId',
        livePreviewData.offerId
      );
    }
    if (fields.includes('promoDisplayType')) {
      fieldsToRemoveFromLivePreviewData.push(
        'barcode',
        'image',
        'promotionCode'
      );
    }
    if (!isEmpty(fieldsToRemoveFromLivePreviewData)) {
      this.setState(state => ({
        livePreviewData: omit(
          state.livePreviewData,
          fieldsToRemoveFromLivePreviewData
        ),
      }));
    }
    this.setState(state => ({
      fieldsInEdit: [
        ...state.fieldsInEdit.filter(item => !fieldsToRemove.includes(item)),
      ],
    }));
  };

  toggleSendEmailDialogOpened = () => {
    this.setState(state => ({
      isSendEmailDialogOpened: !state.isSendEmailDialogOpened,
    }));
  };

  /*updateCompanyLogo = (imageObject: TFileDataType) => {
    const {
      promotionsStore: { updateCompanyLogo, updateSinglePromotion },
    } = this.props;
    updateSinglePromotion(updateCompanyLogo, [{ default: imageObject }]);
  };*/

  renderPromotionLayout = () => {
    const {
      usersStore: { currentCompany },
    } = this.props;
    const { togglePromotionLayoutUpdates, livePreviewData } = this.state;
    return currentCompany === MAIN_COMPANIES.YAMATO_ID ? (
      <DynamicPromotionRepresentation
        key={togglePromotionLayoutUpdates ? 'true' : 'false'}
        formFields={livePreviewData}
      />
    ) : (
      <StaticPromotionRepresentation />
    );
  };

  onColorChange = (field: string) => (color: string) => {
    const newLivePreview = { ...this.state.livePreviewData };
    set(newLivePreview, `colourScheme.${field}.rgba`, color);
    this.setState(state => ({
      livePreviewData: newLivePreview,
      togglePromotionLayoutUpdates: !state.togglePromotionLayoutUpdates,
    }));
  };

  onTCLinkChange = (value: string) => {
    const newLivePreview = { ...this.state.livePreviewData };
    set(newLivePreview, 'tcLink', value);
    this.setState(state => ({
      livePreviewData: newLivePreview,
      togglePromotionLayoutUpdates: !state.togglePromotionLayoutUpdates,
    }));
  };

  onOfferChange = (offerId: string, variables: Object) => {
    const newLivePreview = {
      ...this.state.livePreviewData,
      offerId,
      ...variables,
    };
    this.setState(state => ({
      livePreviewData: newLivePreview,
      togglePromotionLayoutUpdates: !state.togglePromotionLayoutUpdates,
    }));
  };

  onPromoDisplayTypeChange = (fields: Object) => {
    const possibleKeys = ['barcode', 'image', 'promotionCode'];
    const actualKeys = Object.keys(fields);
    const omitKeys = possibleKeys.filter(x => !actualKeys.includes(x));
    if (!isEmpty(omitKeys) && !isEmpty(fields)) {
      this.setState(state => ({
        livePreviewData: {
          ...omit(state.livePreviewData, omitKeys),
          ...fields,
        },
        togglePromotionLayoutUpdates: !state.togglePromotionLayoutUpdates,
      }));
    }
  };

  onPromotionCodeChange = (promotionCode: string) => {
    this.setState(state => ({
      livePreviewData: { ...state.livePreviewData, promotionCode },
      togglePromotionLayoutUpdates: !state.togglePromotionLayoutUpdates,
    }));
  };

  render() {
    const { promotionsStore, t } = this.props;
    const { errors, isSendEmailDialogOpened, fieldsInEdit } = this.state;

    const sendTestEmailEnabled = !fieldsInEdit.length;
    const sendTestEmailContainer = document.getElementById(
      PROMOTION_ACTION_BUTTONS_CONTAINER_ID
    );

    return (
      <>
        {sendTestEmailContainer &&
          ReactDOM.createPortal(
            <ActionButton
              disabled={!sendTestEmailEnabled}
              onClick={this.toggleSendEmailDialogOpened}
            >
              {t('sendTestEmail')}
            </ActionButton>,
            sendTestEmailContainer
          )}
        <Row type="flex">
          <Col sm={{ span: 12 }} xxl={{ span: 10 }}>
            <PromoOfferBlock
              onOfferChange={this.onOfferChange}
              singlePromotion={promotionsStore.singlePromotion}
              singlePromotionOffer={promotionsStore.singlePromotionOffer}
              getOfferById={promotionsStore.getOfferById}
              updateSinglePromotion={promotionsStore.updateSinglePromotion}
              updatePromotionOffer={promotionsStore.updatePromotionOffer}
              addFieldInEdit={this.addFieldInEdit}
              removeFieldInEdit={this.removeFieldInEdit}
            />
            <PromotionDisplayTypeBlock
              onPromotionCodeChange={this.onPromotionCodeChange}
              onChange={this.onPromoDisplayTypeChange}
              imageLibrary={promotionsStore.imageLibrary}
              singlePromotion={promotionsStore.singlePromotion}
              updatePromotionDisplayType={
                promotionsStore.updatePromotionDisplayType
              }
              uploadProductImage={promotionsStore.uploadProductImage}
              updateSinglePromotion={promotionsStore.updateSinglePromotion}
              updatePromotion={promotionsStore.updatePromotion}
              errors={errors}
              setError={this.setError}
              addFieldInEdit={this.addFieldInEdit}
              removeFieldInEdit={this.removeFieldInEdit}
            />
            {getInfoBlock(
              otherBlock({
                errors,
                setError: this.setError,
                addFieldInEdit: this.addFieldInEdit,
                removeFieldInEdit: this.removeFieldInEdit,
                onColorChange: this.onColorChange,
                onTCLinkChange: this.onTCLinkChange,
              }),
              false,
              promotionsStore
            )}
            {/*<ImageUploadBlock
            hasPermissions={commonActions.canAccessFeature({
              roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
            })}
            src={get(promotionsStore, 'singlePromotion.companyLogo')}
            caption="Company logo"
            onReplace={this.updateCompanyLogo}
          />*/}
          </Col>
          <Col sm={{ offset: 2, span: 10 }} xxl={{ offset: 3, span: 8 }}>
            {this.renderPromotionLayout()}
          </Col>
        </Row>
        <SendEmailDialog
          t={t}
          dialogProps={{
            isShown: isSendEmailDialogOpened,
            handleCancelClick: this.toggleSendEmailDialogOpened,
          }}
          sendTestEmail={promotionsStore.sendTestEmailById}
          promotionId={promotionsStore.singlePromotion.promotionId}
        />
      </>
    );
  }

}

export default compose(
  withTranslation('promotions'),
  inject('promotionsStore', 'usersStore'),
  observer
)(PromotionDetailsMarketingMessage);
