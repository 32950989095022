import styled from 'styled-components';
import { ChromePicker } from 'react-color';

export const ColorPickerWrapper = styled.div`
  position: relative;
`;

const isWhiteOrTransparent = ({ r, g, b, a }) =>
  (r === 255 && g === 255 && b === 255) || a === 0;

export const Indicator = styled.div`
  position: absolute;
  right: ${({ right }) => (right ? right : 3)}%;
  top: ${({ top }) => (top ? top : 22)}%;
  height: 20px;
  width: 20px;
  border: ${({ color }) =>
    color && isWhiteOrTransparent(color) ? '1px solid #c9c8c7' : ''};
  border-radius: 2px;
  background-color: ${({ color }) =>
    color
      ? 'rgba(' +
        color.r +
        ', ' +
        color.g +
        ', ' +
        color.b +
        ', ' +
        color.a +
        ')'
      : ''};
`;

export const CustomColourPicker = styled(ChromePicker)`
  position: absolute;
  z-index: 200;
  div.flexbox-fix > div.flexbox-fix > div > div > input {
    line-height: normal;
  }
`;
