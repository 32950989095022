// @flow

import React from 'react';
import { CompaniesStore } from 'stores';
import {
  ColumnsHolder,
  LeftColumn,
} from '_common/components/DetailsPage/elements';
import { getInfoBlock } from '_common/utils/utils';
import { configBlock } from './config';
import commonActions from '_common/actions';
import { Roles } from '_common/constants/acl';

const CompanyDetailsConfig = ({
  companiesStore,
}: {
  companiesStore: CompaniesStore,
}) => (
  <ColumnsHolder>
    <LeftColumn>
      {getInfoBlock(
        configBlock(
          commonActions.canAccessFeature({
            roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
          })
        ),
        false,
        companiesStore
      )}
    </LeftColumn>
  </ColumnsHolder>
);

export default CompanyDetailsConfig;
