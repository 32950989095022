// @flow

import React, { Fragment, createRef } from 'react';
import Papa from 'papaparse';
import { Hidden } from '_common/components/PlainStyles';
import CSVDialog from '_common/components/CSVDialog';
import {
  DISPATCH_FLOW_OPTIONS,
  LOCATION_TYPE_OPTIONS,
  MOVE_TO_CONTAINER_FLOW_OPTIONS,
  RETURN_LABEL_FLOW_OPTIONS,
} from '_common/constants/stores';
import {
  allowEmptyValidator,
  getStoreAddressFromLoqateAddress,
  requiredValidator,
  validateEmail,
  validatePhoneNumber,
  wordToBool,
  optionsValidator,
  getSubCountriesOptions,
  validateDate,
  generateGoogleMapsLink,
  formatOpeningHours,
} from '_common/utils/utils';
import { set, get, isEmpty } from 'lodash';
import { BULK_ACTIONS_EXAMPLE_FILE_CONFIG } from '_common/constants/stores';
import CsvDownloader from 'react-csv-downloader';
import { ButtonLink } from '_common/components';
import { withTranslation } from 'react-i18next';
import DoddleStoresStore from '_common/stores/doddleStoresStore';

type Props = {
  isEditStores: boolean,
  doddleStores: DoddleStoresStore,
  userCompanies: Array<string>,
  resetUpdateStoresProgress: () => void,
  dialogProps: TDialogProps,
  t: TTranslateFunc,
};

type State = {
  headerText: string,
  triggerWarning: boolean,
  triggerError: boolean,
  warning: string,
  error: string,
  failedStores: Array<Object>,
  failedStoresHeaders: Array<{ id: string, displayName: string }>,
};

type TCSVToStoreMapping = {
  field: string,
  path: string,
  type: 'option' | 'string' | 'boolean' | 'number',
  options?: Array<string>,
  validator?: ?(value?: ?string, csvStore: Object) => boolean,
};

class BulkUploadDialog extends React.Component<Props, State> {

  failedStoresExportCSVLink = createRef();

  initialState = {
    error: '',
    warning: '',
    headerText: '',
    triggerError: false,
    triggerWarning: false,
    failedStores: [],
    failedStoresHeaders: [],
  };

  state = { ...this.initialState };

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      dialogProps: { isShown },
    } = this.props;
    if (prevProps.dialogProps.isShown !== isShown) {
      this.setState({
        ...this.initialState,
      });
    }
  }

  triggerWarning = (warning: string): void => {
    this.setState({
      warning,
      triggerWarning: !this.state.triggerWarning,
    });
  };

  triggerError = (error: string): void => {
    this.setState({
      error,
      triggerError: !this.state.triggerError,
    });
  };

  findAddressValidator = (_, csvStore): boolean => {
    const validateAddress = get(csvStore, 'Validate?', 'no');
    const line1 = get(csvStore, 'addressLine1');
    const postcode = get(csvStore, 'postcode');
    return !wordToBool(validateAddress) || postcode || line1;
  };

  countrySubdivisionValidator = (value: ?string, csvStore: Object): boolean => {
    if (!value) {
      return true;
    }
    let result = false;
    const country = get(csvStore, 'country');
    const locationType = get(csvStore, 'locationType');
    if (country) {
      const options = getSubCountriesOptions(
        this.props.doddleStores,
        locationType,
        country
      );
      result = options
        ? options.find((option: TOption) => option.value === value)
        : false;
    }
    return !!result;
  };

  openingHoursMappings: Array<Array<TCSVToStoreMapping>> = [
    [
      { field: 'MondayOpenHours', path: 'openingHours.monday', type: 'string' },
      {
        field: 'MondayClosingHours',
        path: 'openingHours.monday',
        type: 'string',
      },
    ],
    [
      {
        field: 'TuesdayOpenHours',
        path: 'openingHours.tuesday',
        type: 'string',
      },
      {
        field: 'TuesdayClosingHours',
        path: 'openingHours.tuesday',
        type: 'string',
      },
    ],
    [
      {
        field: 'WednesdayOpenHours',
        path: 'openingHours.wednesday',
        type: 'string',
      },
      {
        field: 'WednesdayClosingHours',
        path: 'openingHours.wednesday',
        type: 'string',
      },
    ],
    [
      {
        field: 'ThursdayOpenHours',
        path: 'openingHours.thursday',
        type: 'string',
      },
      {
        field: 'ThursdayClosingHours',
        path: 'openingHours.thursday',
        type: 'string',
      },
    ],
    [
      { field: 'FridayOpenHours', path: 'openingHours.friday', type: 'string' },
      {
        field: 'FridayClosingHours',
        path: 'openingHours.friday',
        type: 'string',
      },
    ],
    [
      {
        field: 'SaturdayOpenHours',
        path: 'openingHours.saturday',
        type: 'string',
      },
      {
        field: 'SaturdayClosingHours',
        path: 'openingHours.saturday',
        type: 'string',
      },
    ],
    [
      { field: 'SundayOpenHours', path: 'openingHours.sunday', type: 'string' },
      {
        field: 'SundayClosingHours',
        path: 'openingHours.sunday',
        type: 'string',
      },
    ],
  ];

  getCsvToStoreMappings = (): Array<TCSVToStoreMapping> => {
    const {
      isEditStores,
      userCompanies,
      doddleStores: { getCountriesOptions },
    } = this.props;
    let additionalFields = [];
    if (isEditStores) {
      additionalFields = [
        {
          field: 'storeId',
          path: 'storeId',
          type: 'string',
          validator: requiredValidator,
        },
        {
          field: 'openingDate',
          path: 'openingDate',
          type: 'string',
          validator: allowEmptyValidator(validateDate()),
        },
        {
          field: 'closingDate',
          path: 'closingDate',
          type: 'string',
          validator: allowEmptyValidator(validateDate()),
        },
      ];
    }
    return [
      ...additionalFields,
      {
        field: 'companyId',
        path: 'companyId',
        type: 'option',
        options: userCompanies,
        validator: requiredValidator,
      },
      { field: 'externalStoreId', path: 'externalStoreId', type: 'string' },
      {
        field: 'storeName',
        path: 'storeName',
        type: 'string',
        validator: !isEditStores ? requiredValidator : null,
      },
      { field: 'companyStoreId', path: 'companyStoreId', type: 'string' },
      { field: 'isDemo', path: 'isDemo', type: 'boolean' },
      { field: 'temporaryClosure', path: 'temporaryClosure', type: 'boolean' },
      {
        field: 'locationType',
        path: 'locationType',
        type: 'option',
        options: LOCATION_TYPE_OPTIONS.map((o) => o.value),
        validator: !isEditStores ? requiredValidator : null,
      },
      {
        field: 'Validate?',
        path: 'validateAddress',
        type: 'boolean',
      },
      {
        field: 'country',
        path: 'place.address.country',
        type: 'option',
        options: getCountriesOptions.map((o) => o.value),
        validator: !isEditStores ? requiredValidator : null,
      },
      {
        field: 'postcode',
        path: 'place.address.postcode',
        type: 'string',
        validator: this.findAddressValidator,
      },
      {
        field: 'addressLine1',
        path: 'place.address.line1',
        type: 'string',
        validator: this.findAddressValidator,
      },
      { field: 'addressLine2', path: 'place.address.line2', type: 'string' },
      { field: 'town', path: 'place.address.town', type: 'string' },
      { field: 'area', path: 'place.address.area', type: 'string' },
      { field: 'geo.lat', path: 'geo.lat', type: 'number' },
      { field: 'geo.lon', path: 'geo.lon', type: 'number' },
      {
        field: 'countrySubdivision',
        path: 'place.countrySubdivision',
        type: 'string',
        validator: this.countrySubdivisionValidator,
      },
      {
        field: 'emailAddress',
        path: 'place.emailAddress',
        type: 'string',
        validator: allowEmptyValidator(validateEmail),
      },
      {
        field: 'phoneNumber',
        path: 'place.phoneNumber',
        type: 'string',
        validator: allowEmptyValidator(validatePhoneNumber),
      },
      {
        field: 'howToFindUs',
        path: 'place.howToFindUs',
        type: 'string',
        validator: !isEditStores ? requiredValidator : null,
      },
      { field: 'mapUrl', path: 'place.map', type: 'string' },
      {
        field: 'restrictedAccess',
        path: 'place.restrictedAccess',
        type: 'boolean',
      },
      {
        field: 'collections.enabled',
        path: 'services.COLLECTIONS.enabled',
        type: 'boolean',
      },
      {
        field: 'collections.testing',
        path: 'services.COLLECTIONS.testing',
        type: 'boolean',
      },
      {
        field: 'collections.integratedLockers',
        path: 'services.COLLECTIONS.integratedLockers',
        type: 'boolean',
      },
      {
        field: 'collections.integratedLockersSite',
        path: 'services.COLLECTIONS.integratedLockersConfig.siteId',
        type: 'string',
      },
      {
        field: 'returns.enabled',
        path: 'services.RETURNS.enabled',
        type: 'boolean',
      },
      {
        field: 'returns.testing',
        path: 'services.RETURNS.testing',
        type: 'boolean',
      },
      {
        field: 'returns.integratedLockers',
        path: 'services.RETURNS.integratedLockers',
        type: 'boolean',
      },
      {
        field: 'returns.integratedLockersSite',
        path: 'services.RETURNS.integratedLockersConfig.siteId',
        type: 'string',
      },
      {
        field: 'returns.acceptsPaperless',
        path: 'services.RETURNS.acceptsPaperless',
        type: 'boolean',
      },
      {
        field: 'requestPowerWhitelisting',
        path: 'storeConfiguration.requestPowerWhitelisting',
        type: 'boolean',
      },
      {
        field: 'preadviceEndpoint',
        path: 'storeConfiguration.preadviceEndpoint',
        type: 'string',
      },
      {
        field: 'simplifiedCollectionFlow',
        path: 'storeConfiguration.storeSystemUIConfig.simplifiedCollectionFlow',
        type: 'boolean',
      },
      {
        field: 'returnLabelFlow',
        path: 'storeConfiguration.storeSystemUIConfig.returnLabelFlow',
        type: 'option',
        options: RETURN_LABEL_FLOW_OPTIONS.map((o) => o.value),
      },
      {
        field: 'returnsDisablePreBooked',
        path: 'storeConfiguration.storeSystemUIConfig.returnsDisablePreBooked',
        type: 'boolean',
      },
      {
        field: 'returnsFixedRetailerId',
        path: 'storeConfiguration.storeSystemUIConfig.returnsFixedRetailerId',
        type: 'string',
      },
      {
        field: 'returnsFixedCarrierId',
        path: 'storeConfiguration.storeSystemUIConfig.returnsFixedCarrierId',
        type: 'string',
      },
      {
        field: 'dispatchFlow',
        path: 'storeConfiguration.storeSystemUIConfig.dispatchFlow',
        type: 'option',
        options: DISPATCH_FLOW_OPTIONS.map((o) => o.value),
      },
      {
        field: 'moveToContainerFlow',
        path: 'storeConfiguration.storeSystemUIConfig.moveToContainerFlow',
        type: 'option',
        options: MOVE_TO_CONTAINER_FLOW_OPTIONS.map((o) => o.value),
      },
      {
        field: 'fixedCarrierId',
        path: 'storeConfiguration.storeSystemUIConfig.fixedCarrierId',
        type: 'string',
      },
      {
        field: 'collectionDeadlineDays',
        path: 'storeConfiguration.collectionDeadlineDays',
        type: 'number',
      },
      {
        field: 'networkForbidsLongPolling',
        path: 'storeConfiguration.technicalConfiguration.networkForbidsLongPolling',
        type: 'boolean',
      },
      {
        field: 'printers.description',
        path: 'storeConfiguration.technicalConfiguration.printers[0].description',
        type: 'string',
      },
      {
        field: 'printers.host',
        path: 'storeConfiguration.technicalConfiguration.printers[0].host',
        type: 'string',
      },
      {
        field: 'printers.type',
        path: 'storeConfiguration.technicalConfiguration.printers[0].type',
        type: 'string',
      },
      {
        field: 'extendedHours',
        path: 'extendedHours',
        type: 'boolean',
      },
    ];
  };

  formatOpeningHours = (csvStore: Object) => {
    const openingHours = {};
    for (const day of this.openingHoursMappings) {
      const result = { isOpen: false };
      let openTime = get(csvStore, day[0].field);
      let closeTime = get(csvStore, day[1].field);
      if (
        this.props.isEditStores &&
        (openTime === undefined || closeTime === undefined)
      ) {
        continue;
      }
      openTime = openTime.trim();
      closeTime = closeTime.trim();
      if (openTime && closeTime) {
        const { timeStartFormatted, timeEndFormatted } = formatOpeningHours(
          openTime,
          closeTime
        );
        set(result, 'hours', [[timeStartFormatted, timeEndFormatted]]);
        result.isOpen = true;
      }
      set(openingHours, day[0].path, result);
    }
    return openingHours;
  };

  loadPlace = async (store: TStore): Promise<void> => {
    const { line1, postcode, country } = get(store, 'place.address', {});
    if (country && (postcode || line1)) {
      const addresses = await this.props.doddleStores.findAddresses({
        postcode,
        address: line1,
        country,
      });
      if (addresses.length) {
        const addressResult = addresses[0];
        const address = await this.props.doddleStores.retrieveAddress(
          addressResult.id
        );
        if (address) {
          const {
            town,
            countrySubdivision,
            country,
            postcode,
            area,
            line1,
            line2,
          } = getStoreAddressFromLoqateAddress(
            address,
            this.props.doddleStores.commonSubCountries
          );
          set(store, 'place.address.town', town);
          set(store, 'place.address.country', country);
          set(store, 'place.address.postcode', postcode);
          set(store, 'place.address.area', area);
          set(store, 'place.countrySubdivision', countrySubdivision);
          set(store, 'place.address.line1', line1);
          set(store, 'place.address.line2', line2);
          const geoData = await this.props.doddleStores.getGeoByLocation(
            addressResult.country,
            addressResult.label
          );
          if (geoData) {
            set(store, 'geo.lat', geoData.Latitude);
            set(store, 'geo.lon', geoData.Longitude);
            set(
              store,
              'place.map',
              generateGoogleMapsLink({
                lat: geoData.Latitude,
                lon: geoData.Longitude,
              })
            );
          }
        }
      }
    }
  };

  /**
   * Transform parsed csv to storeV3Objects using mappings
   *
   * @param parsedStores
   * @param mappings
   * @returns {[]}
   */
  mapCSVToStores = async (
    parsedStores: Array<Object>,
    mappings: Array<TCSVToStoreMapping>
  ): Promise<Array<TStore>> => {
    const storesToAffect = [];
    const failedStores = [];
    let index = 0;
    for (const csvStore of parsedStores) {
      delete csvStore['Fields causing errors'];
      const storeV3Object = {
        index: index++,
        services: {},
        storeConfiguration: {},
        geo: {},
        openingHours: this.formatOpeningHours(csvStore),
        validateAddress: false,
      };
      let hasError = false;
      let errorFields = [];
      for (const config of mappings) {
        const { field, validator, path, options, type } = config;
        let csvField =
          typeof csvStore[field] === 'string'
            ? csvStore[field].trim()
            : undefined;
        if (
          (typeof validator === 'function' && !validator(csvField, csvStore)) ||
          (type === 'option' && options && !optionsValidator(csvField, options))
        ) {
          errorFields.push(field);
          hasError = true;
        }
        if (!hasError && csvField !== undefined) {
          csvField =
            type === 'number'
              ? Number(csvField)
              : type === 'boolean'
              ? wordToBool(csvField)
              : csvField;
          if (
            path &&
            ((type === 'number' && !Number.isNaN(csvField)) ||
              csvField !== undefined)
          ) {
            set(storeV3Object, path, csvField);
          }
        }
      }
      if (!hasError) {
        // Load address from Loqate if requested
        if (storeV3Object.validateAddress) {
          await this.loadPlace(storeV3Object);
        } else if (
          !isEmpty(storeV3Object.geo) &&
          !get(storeV3Object, 'place.map')
        ) {
          set(
            storeV3Object,
            'place.map',
            generateGoogleMapsLink(storeV3Object.geo)
          );
        }
        delete storeV3Object.validateAddress;

        if (this.props.isEditStores) {
          Object.entries(storeV3Object).forEach(([key, value]) => {
            if (typeof value === 'object' && isEmpty(value)) {
              delete storeV3Object[key];
            }
          });
        }
        storesToAffect.push(storeV3Object);
      } else if (errorFields.length) {
        if (errorFields.includes('country')) {
          errorFields = errorFields.filter(
            (field) => field !== 'countrySubdivision'
          );
        }
        failedStores.push({
          'Fields causing errors': errorFields.join(', '),
          ...csvStore,
        });
      }
      hasError = false;
    }
    this.setState({
      failedStores,
    });
    return storesToAffect;
  };

  downloadFailedStores = () => {
    const { failedStores } = this.state;
    if (failedStores.length > 0) {
      this.setState(
        {
          failedStoresHeaders: Object.keys(failedStores[0]).map((key) => ({
            displayName: key,
            id: key,
          })),
        },
        () => {
          if (
            this.failedStoresExportCSVLink.current &&
            this.failedStoresExportCSVLink.current.handleClick
          ) {
            this.failedStoresExportCSVLink.current.handleClick();
          }
        }
      );
    }
  };

  parseCSV = async (content: string) => {
    const {
      t,
      isEditStores,
      doddleStores: {
        storesBulkCreate,
        storesBulkUpdate,
        reloadStoresByFilters,
      },
    } = this.props;
    const bulkAction = isEditStores ? storesBulkUpdate : storesBulkCreate;
    this.setState({
      failedStores: [],
    });
    const parseOptions = {
      header: true,
      skipEmptyLines: true,
    };
    content = content.replace(/sep.+(\n|\r\n|\r)/, '');
    const { data: parsedStores } = Papa.parse(content, parseOptions);
    if (parsedStores.length) {
      const stores = await this.mapCSVToStores(
        parsedStores,
        this.getCsvToStoreMappings()
      );
      try {
        if (bulkAction) {
          const { amountAffected, failedIndexes } = await bulkAction(stores);
          let failedStores = [...this.state.failedStores];
          if (failedIndexes.length > 0) {
            failedStores = [
              ...failedStores,
              ...failedIndexes.map(({ storeIndex, message }) => ({
                'Fields causing errors': message,
                ...parsedStores[storeIndex],
              })),
            ];
            this.setState({
              failedStores: [...failedStores],
            });
          }
          if (amountAffected > 0) {
            let headerText = t(!isEditStores ? 'bulkCreated' : 'bulkUpdated', {
              count: amountAffected,
            });
            if (failedStores.length > 0) {
              headerText += t('bulkSkipped', { count: failedStores.length });
            }
            this.setState({
              headerText,
            });
          } else {
            this.triggerError(
              t(!isEditStores ? 'nothingToCreate' : 'nothingToUpdate')
            );
          }
        }
      } catch (e) {
        console.error(e);
        this.triggerError(t('common:unexpectedError'));
      } finally {
        reloadStoresByFilters();
        this.downloadFailedStores();
      }
    } else {
      this.triggerError(t('uploadFailed'));
    }
  };

  uploadFile = async (formData: Object) => {
    const {
      t,
      doddleStores: { uploadStoresFile, reloadStoresByFilters },
    } = this.props;
    this.setState({
      failedStores: [],
    });
    try {
      this.setState({
        headerText: t('bulkWait'),
      });
      const { failedStores, storesCreated, usersPending } =
        await uploadStoresFile(formData);
      if (!isEmpty(failedStores)) {
        this.setState({ failedStores: [...failedStores] });
      }

      if (storesCreated > 0) {
        let headerText = t('bulkCreated', { count: storesCreated });
        if (usersPending) {
          headerText += ' ' + t('usersPending');
        }
        if (!isEmpty(failedStores)) {
          headerText += ' ' + t('bulkSkipped', { count: failedStores.length });
        }
        this.setState({ headerText });
      } else {
        this.triggerError(t('nothingToCreate'));
      }
    } catch (e) {
      const status = get(e, 'response.data.httpStatus');
      const message = get(e, 'response.data.errors[0].message');
      if (status === 400 && message) {
        this.triggerError(message);
      } else if (e.message.includes('Network Error')) {
        this.triggerWarning(t('bulkUploadTimeoutError'));
      } else {
        this.triggerError(t('common:unexpectedError'));
      }
    } finally {
      reloadStoresByFilters();
      this.downloadFailedStores();
    }
  };

  render() {
    const {
      dialogProps,
      doddleStores: { getUpdateStoresProgressField: uploadProgress },
      resetUpdateStoresProgress,
      t,
      isEditStores,
    } = this.props;
    const {
      error,
      warning,
      triggerWarning,
      triggerError,
      headerText,
      failedStores,
      failedStoresHeaders,
    } = this.state;

    const actionProps = {
      parseCSVAction: isEditStores ? this.parseCSV : null,
      uploadAction: isEditStores ? null : this.uploadFile,
    };

    return (
      <Fragment>
        <CSVDialog
          {...actionProps}
          headerText={headerText}
          dialogProps={dialogProps}
          progress={uploadProgress}
          triggerError={triggerError}
          error={error}
          warning={warning}
          triggerWarning={triggerWarning}
          resetProgress={resetUpdateStoresProgress}
          exampleFileConfig={
            isEditStores
              ? BULK_ACTIONS_EXAMPLE_FILE_CONFIG.EDIT_STORES
              : BULK_ACTIONS_EXAMPLE_FILE_CONFIG.UPLOAD_STORES
          }
          Footer={
            failedStores.length ? (
              <ButtonLink onClick={this.downloadFailedStores}>
                {t('downloadFailed')}
              </ButtonLink>
            ) : undefined
          }
        />
        <Hidden>
          <CsvDownloader
            separator=";"
            columns={failedStoresHeaders}
            datas={failedStores}
            filename="failedStores.csv"
            ref={this.failedStoresExportCSVLink}
          />
        </Hidden>
      </Fragment>
    );
  }

}

export default withTranslation('stores')(BulkUploadDialog);
