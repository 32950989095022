import React, { Component, Fragment } from 'react';
import { Form } from 'antd';
import { Input, InputLabel } from '_common/components';
import {
  FixedWidthRow,
  RowWrapper,
} from '_common/components/TabsForm/elements';
import {
  CaptionHolder,
  EditableDataHolder,
  EditStateBlock,
} from '_common/components/InfoBlock/elements';
import { OFFER_VARIABLE_TYPES } from '_common/constants/promotions';
import { get } from 'lodash';

type Props = {
  offerId: string,
  offerVariables: Array<TPromotionOfferVariable>,
  form: Form,
  isEditLayout: boolean,
  initialOffer?: {
    offerId: string,
    variables: Array<{ id: string, value: string | number }>,
  },
  t: TTranslateFunc,
  blurHandler?: Function,
};

type State = {};

class OfferVariables extends Component<Props, State> {

  static defaultProps = {
    isEditLayout: false,
  };

  buildValidationRules = (label: string, type: string, validations: Object) => {
    const rules = [];
    const { t } = this.props;
    if (type === OFFER_VARIABLE_TYPES.NUMBER) {
      rules.push({
        pattern: /[0-9]+/,
        message: t('mustBeInteger'),
      });
    }
    const lowerCaseType = type.toLowerCase();
    const minRestriction = validations.minValue || validations.minLength;
    const maxRestriction = validations.maxValue || validations.maxLength;
    if (minRestriction) {
      const minMessage =
        type === OFFER_VARIABLE_TYPES.NUMBER
          ? `${label} ${t('numMin', { min: minRestriction })}`
          : `${label} ${t('stringMin', { min: minRestriction })}`;
      rules.push({
        type: lowerCaseType,
        min: minRestriction,
        message: minMessage,
      });
    }
    if (maxRestriction) {
      const maxMessage =
        type === OFFER_VARIABLE_TYPES.NUMBER
          ? `${label} ${t('numMax', { max: maxRestriction })}`
          : `${label} ${t('stringMax', { max: maxRestriction })}`;
      rules.push({
        type: lowerCaseType,
        max: maxRestriction,
        message: maxMessage,
      });
    }
    return rules;
  };

  handleBlur = () => {
    const { blurHandler } = this.props;
    blurHandler && blurHandler();
  };

  getConfigs = () => {
    const { offerVariables, offerId, initialOffer, t } = this.props;
    const configs = [];
    if (offerVariables) {
      offerVariables.forEach(
        ({ id, type, label, validations, options }: TPromotionOffer) => {
          let initialValue = undefined;
          if (initialOffer && initialOffer.offerId === offerId) {
            initialValue = get(
              initialOffer.variables.find(item => item.id === id),
              'value'
            );
          }
          const config = {
            label: t(label),
            field: `${offerId}.${id}`,
            type,
            rules: this.buildValidationRules(label, type, validations),
            initialValue,
          };
          config.controlElem = (
            <Input
              key={config.field}
              onBlur={this.handleBlur}
              placeholder={`${t('common:enter')} ${t(label.toLowerCase())}`}
            />
          );
          configs.push(config);
        }
      );
    }
    return configs;
  };

  normalizeValue = (type: string) => (value: string) => {
    let newValue = value;
    if (value && type === OFFER_VARIABLE_TYPES.NUMBER) {
      newValue = parseInt(value, 10);
      if (Number.isNaN(newValue)) {
        newValue = '';
      }
    }
    return newValue;
  };

  render() {
    const { offerId, isEditLayout, t } = this.props;
    const configs = this.getConfigs();

    const LabelWrapper = isEditLayout ? CaptionHolder : FixedWidthRow;
    const ControlWrapper = isEditLayout ? EditStateBlock : FixedWidthRow;
    const AdditionalWrapper = isEditLayout ? EditableDataHolder : Fragment;

    return (
      configs &&
      configs.map(config => (
        <RowWrapper key={`${offerId}${config.field}`}>
          <LabelWrapper noPadding width={190}>
            <InputLabel>{`${config.label}*`}</InputLabel>
          </LabelWrapper>
          <ControlWrapper paddingVertical={0} width={isEditLayout ? 57.5 : 380}>
            <AdditionalWrapper>
              <Form.Item
                initialValue={config.initialValue}
                name={config.field.split('.')}
                normalize={this.normalizeValue(config.type)}
                rules={[
                  {
                    required: true,
                    message: `${t(
                      'pleaseEnter'
                    )} ${config.label.toLowerCase()}`,
                  },
                  ...config.rules,
                ]}
              >
                {config.controlElem}
              </Form.Item>
            </AdditionalWrapper>
          </ControlWrapper>
        </RowWrapper>
      ))
    );
  }

}

export default OfferVariables;
