// @flow

import React, { Fragment } from 'react';
import { BlockHeader, InfoPart } from './elements';
import { observer } from 'mobx-react';

const RawData = ({
  header,
  notFoundMessage,
  data = [],
}: {
  header: string,
  notFoundMessage: string,
  data: Array<Object>,
}) => {
  return (
    <Fragment>
      <BlockHeader>{header}</BlockHeader>
      {data && data.length > 0 ? (
        data.map((cur, i) => (
          <InfoPart key={`${cur.dateTime}${cur.eventType}` || i}>
            {JSON.stringify(cur, null, 2)}
          </InfoPart>
        ))
      ) : (
        <div>{notFoundMessage}</div>
      )}
    </Fragment>
  );
};

export default observer(RawData);
