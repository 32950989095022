// @flow

import { authClient } from '_common/api/clients/clients';
import qs from 'qs';

const applicationSignIn = async () => {
  const body = qs.stringify({
    grant_type: 'client_credentials', //eslint-disable-line
    scope: 'admin_tool_service:password_reset',
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

const authorizeStaff = async (
  username: string,
  password: string,
  organisation: string
): Promise<TTokenResponse> => {
  const body = qs.stringify({
    grant_type: 'staff_password', //eslint-disable-line
    username,
    password,
    organisationId: organisation,
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

const refreshToken = async (refreshToken: ?string): Promise<any> => {
  const body = qs.stringify({
    grant_type: 'refresh_token', //eslint-disable-line
    refresh_token: refreshToken, //eslint-disable-line
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

export default {
  authorizeStaff,
  refreshToken,
  applicationSignIn,
};
