import styled from 'styled-components';
import { Col, Row } from 'antd';
import Button from '_common/components/Button/Button';
import { DialogWrapperInner } from '_common/components/Dialog/elements';

export { Header, StyledPanelWrapper } from '../Main/elements';

export const BlockTitle = styled.div`
  font-weight: 600;
  font-size: 26px;
  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

export const BlockCol = styled(Col)`
  padding: 30px;
  @media only screen and (max-width: 576px) {
    padding: 25px;
  }
`;

export const DashboardWrapper = styled(Row)`
  background: #fff;
  border: 1px solid #e6e7e8;
`;

export const InstructionsList = styled.ol`
  padding-inline-start: 15px;
  line-height: 3;
  font-size: 18px;
  margin-top: 1em;
  font-weight: 400;
  font-family: ${props => props.theme.fonts.book};
  @media only screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

export const QRCodeWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    width: 100%;
    @media only screen and (max-width: 576px) {
      width: 75%;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      width: 60%;
    }
  }
`;

export const EmailQrCodeButton = styled(Button)`
  background-color: ${props => props.theme.colors.secondary} !important;
  padding: 10px;
  height: auto;
`;

export const OpenHoursItem = styled(Row)`
  margin-top: 10px;
`;

export const StoreName = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.fonts.bold};
  font-size: 30px;
  color: #000;
`;

export const StoreId = styled.div`
  color: ${props => props.theme.colors.shade3};
  font-size: 20px;
  font-family: ${props => props.theme.fonts.book};
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const StoreAddress = styled.div`
  color: ${props => props.theme.colors.shade2};
  font-size: 16px;
  min-width: 180px;
  font-family: ${props => props.theme.fonts.book};
`;

export const InfoLabel = styled.span`
  color: #6a6b6e;
  width: ${props => (props.width ? props.width : 60)}px;
  font-family: ${props => props.theme.fonts.basic};
`;

export const ContactInfoRow = styled(Row)`
  margin-top: 10px;
  align-items: center;
`;

export const RightInfoRow = styled(Row)`
  justify-content: flex-end;
  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export const ContactInfo = styled.div`
  color: ${props => props.theme.colors.shade2};
  font-size: 16px;
  min-width: 180px;
  font-family: ${props => props.theme.fonts.book};
  @media only screen and (max-width: 576px) {
    min-width: 0;
  }
`;

export const ViewOnMapButton = styled(Button)`
  height: auto;
  padding: 10px 30px;
  @media only screen and (max-width: 576px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const TestConnectivityButton = styled(Button)`
  height: auto;
  padding: 10px 30px;
  width: 100%;
  margin-top: 10px;
  background-color: ${props => props.theme.colors.secondary} !important;
`;

export const CopyResultsButtons = styled(Button)`
  height: auto;
  padding: 10px 30px;
  width: 90%;
  align-self: center;
  margin-top: 30px;
  opacity: ${({ greyed }) => (greyed ? 0.5 : 1)};
  @media only screen and (max-width: 576px) {
    margin-bottom: 35px;
  }
`;

export const RightInfo = styled.div`
  display: block;
  @media only screen and (max-width: 767px) {
    text-align: left;
    margin-top: 10px;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

export const RightInfoMobile = styled.div`
  display: none;
  @media only screen and (max-width: 576px) {
    display: block;
    margin-top: 15px;
  }
`;

export const ConnectivityResultsWrapper = styled.div`
  ${DialogWrapperInner} {
    padding: 35px 0;
    max-height: initial;
    height: auto;
    @media only screen and (max-width: 576px) {
      max-height: 500px;
    }
  }
  .ant-collapse-header div.ant-row {
    flex-wrap: nowrap;
  }
`;

export const ConnectivityHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px 30px;
`;

export const ResultText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;
  color: #000;
  margin-left: 15px;
`;

export const SubHeaderTest = styled.div`
  font-family: ${props => props.theme.fonts.book};
  color: #000;
  font-size: 20px;
  margin-top: 16px;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  display: flex;
  width: 120px;
  height: 120px;
  align-self: center;
`;
