import styled from 'styled-components';
import Button from '_common/components/Button/Button';

export const Header = styled.div`
  background: #fff;
  padding: 40px 30px 35px;
  @media only screen and (max-width: 576px) {
    padding: 30px 25px;
    margin-bottom: 15px;
  }
`;

export const SendOnboardingEmailButton = styled(Button)`
  margin-left: 10px;
`;

export const StoreTopInfo = styled.div`
  margin-bottom: 11px;
  display: flex;
  align-items: center;
`;

export const StoreTopInfoHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StoreBottomInfo = styled.p`
  margin-bottom: 0;
  color: ${props => props.theme.colors.shade2};
  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;
  line-height: 16px;
`;

export const CreateStoreButton = styled(Button)`
  && {
    width: 200px;
    height: 46px;
  }
`;

export const StoreName = styled.span`
  display: inline-block;
  font-family: ${props => props.theme.fonts.bold};
  color: #494949;
  font-size: 26px;
  line-height: 26px;
  margin-right: 11px;
`;

export const StoreZip = styled.span`
  display: inline-block;
  margin-right: 14px;
  color: #a5a6a8;
  font-size: 23px;
  line-height: 26px;
  font-family: ${props => props.theme.fonts.light};
`;

export const StoreStatus = styled.span`
  display: inline-block;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.bold};
  font-size: 14px;
  line-height: 26px;
  margin-left: 20px;
  color: #fff;
  padding: 0 14px;
  @media only screen and (max-width: 576px) {
    width: 100%;
    padding: 3px 14px;
    text-align: center;
    margin-bottom: 10px;
    margin-left: 0;
  }
`;

export const StoreStatusDeactivated = styled(StoreStatus)`
  background-color: #ff0000;
`;

export const StoreStatusPlanned = styled(StoreStatus)`
  background-color: #5ad03b;
`;

export const StyledPanel = styled.div`
  width: 100%;
  padding: 30px;
  border: solid 1px #e6e7e8;
  background: #fff;
`;

export const StyledPanelWrapper = styled.div`
  padding: 30px;
  background: ${props => props.theme.colors.shade1};
  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;
