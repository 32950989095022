// @flow strict

import storage, {
  VERSION_KEY,
  USER_STORAGE_KEY,
  TOKEN_KEY,
  TOKEN_LIFETIME,
  REFRESH_TOKEN_KEY,
} from 'storage';
import packageJson from '../package.json';

export default () => {
  const version = storage.get(VERSION_KEY);
  const user = storage.get(USER_STORAGE_KEY);

  if (version !== packageJson.version) {
    const userID = user && user.userId;
    if (userID) {
      storage.remove(userID);
      storage.remove(TOKEN_KEY);
      storage.remove(TOKEN_LIFETIME);
      storage.remove(REFRESH_TOKEN_KEY);
    }
  }

  storage.set(VERSION_KEY, packageJson.version);
};
