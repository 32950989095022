// @flow

import { apiClient, atsClient, pureClient } from '_common/api/clients/clients';
import { AUTH_MANAGEMENT } from '_common/constants/timeout';
import { get } from 'lodash';

let wait = 0;

/**
 * Utility function to prevent breaching okta rate limits
 *
 * @param {Object} headers Response headers
 * @returns {Promise<*>}
 */
function checkRateLimit(headers) {
  const {
    RATE_LIMIT_DELAY_MS,
    RATE_LIMIT_THRESHOLD,
    RETRY_BACK_OFF,
  } = AUTH_MANAGEMENT;
  const limit = get(headers, 'x-rate-limit-limit');
  const remaining = get(headers, 'x-rate-limit-remaining');
  return new Promise(resolve => {
    if (
      !limit ||
      !remaining ||
      remaining === 'undefined' ||
      limit === 'undefined'
    ) {
      console.warn('No rate limit headers');
      return resolve();
    }
    const capacity = 1 - RATE_LIMIT_THRESHOLD / 100;
    console.warn(
      `Remaining capacity ${remaining / limit}. Delay threshold - ${capacity}`
    );
    if (remaining / limit < capacity) {
      wait++;
      const delay = Math.pow(RETRY_BACK_OFF, wait) * RATE_LIMIT_DELAY_MS;
      console.warn(
        `Waiting ${delay}ms, reached ${RATE_LIMIT_THRESHOLD}% of rate limit`
      );
      return setTimeout(resolve, delay);
    }
    // if not hitting rate limit, reset exponent
    wait = 0;
    return resolve();
  });
}

const createUser = async (
  userData: TUserData,
  organisationId: string
): Promise<Object> => {
  const response = await apiClient.post(
    `/v1/admin-tool-service/${organisationId}/create-user`,
    userData
  );

  return response.data.resource;
};

const getUsersByRole = async (
  roleName: string,
  organisationId: string
): Promise<Array<Object>> => {
  let users = [];
  let response;
  let mark = null;
  let hasNextPage = false;
  do {
    response = await atsClient.get(
      `/staff/users/${organisationId}/${roleName}`,
      { params: { mark } }
    );
    hasNextPage = get(response, 'data.hasNextPage');
    mark = get(response, 'data.mark');
    users = users.concat(response.data.resources);
    await checkRateLimit(response.headers);
  } while (hasNextPage);
  return users;
};

const getFilterExcludedUsers = async (
  organisationId: string = 'DODDLE',
  excludeRoles: Array<string> = []
): Promise<Function> => {
  let excludedUsers = [];
  for (const role of excludeRoles) {
    const usersToExclude = await getUsersByRole(role, organisationId);
    excludedUsers = [
      ...excludedUsers,
      ...usersToExclude.map(user => user.login),
    ];
  }
  return (users: Array<Object>) => {
    if (excludedUsers.length > 0) {
      return users.filter(user => !excludedUsers.includes(user.login));
    }
    return users;
  };
};

const getUsersByOrgId = async (
  organisationId: string = 'DODDLE',
  excludeRoles: Array<string> = []
): Promise<Array<Object>> => {
  const filterExcludedUsers = await getFilterExcludedUsers(
    organisationId,
    excludeRoles
  );
  let counter = 1;
  let response = null;
  let result = [];
  try {
    response = await apiClient.get(
      `/v1/auth-management/staff/organisationId/${organisationId}?limit=50&getStaffData=true`
    );
  } catch (e) {
    console.error(e);
  }
  if (response) {
    result = filterExcludedUsers(response.data.resources);
    while (response.data.hasNextPage) {
      try {
        response = await apiClient.get(
          `/v1/auth-management/staff/organisationId/${organisationId}?limit=50&mark=${counter *
            50}&getStaffData=true`
        );
        if (response) {
          result = [...result, ...filterExcludedUsers(response.data.resources)];
        }
      } catch (e) {
        console.error(e);
      }
      counter++;
    }
  }
  return result;
};

const getUsersByScope = async (scopeName: string): Promise<Array<Object>> => {
  const response = await apiClient.get(
    `/v1/auth-management/staff/scopeName/${scopeName}?limit=100000`
  );
  return response.data.resources;
};

const getUserByLogin = async (
  companyId: string = 'DODDLE',
  login: string
): Promise<Object> => {
  const response = await atsClient.get(`/${companyId}/${login}/get-user`);
  return response.data.resource;
};

const updateUser = async (
  data: TUserUpdateData,
  orgId: string,
  login: string
): Promise<Object> => {
  const response = await atsClient.patch(
    `/${orgId}/${login}/update-user/`,
    data
  );
  return response.data.resource;
};

const updatePassword = async (
  orgId: string,
  login: string,
  data: { password: string },
  temporaryPassword: boolean = false,
  token?: string
): Promise<string> => {
  const url = `/v1/admin-tool-service/${orgId}/${login}/update-staff-user-password?temporaryPassword=${temporaryPassword.toString()}`;
  let response = {};
  if (token) {
    response = await pureClient.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    response = await apiClient.patch(url, data);
  }
  return response.data.resource;
};

const suspendUser = async (orgId: string, login: string): Promise<string> => {
  const response = await apiClient.post(
    `/v1/auth-management/staff/organisationId/${orgId}/${login}/suspend`
  );
  return response.data;
};

const createScopes = async (scopes: Array<string>) => {
  const response = await apiClient.post('/v1/auth-management/staff/scopes', {
    scopes,
  });
  return response.data.resource;
};

const createUserForStores = async (
  companyId: string,
  storeIds: Array<string>
) => {
  const response = await apiClient.post(
    `/v1/admin-tool-service/${companyId}/create-store-users/`,
    {
      stores: storeIds,
    }
  );
  return response.data.resources;
};

const checkTemporaryPassword = async (companyId: string, login: string) => {
  const response = await apiClient.get(
    `/v1/admin-tool-service/${companyId}/${login}/check-temporary-password/`
  );
  return response.data.resource.temporaryPassword;
};

const changePasswordForOnboardingUser = async (
  { login, organisationId, resetToken: token }: TOnboardingToken,
  password: string,
  accessToken: string
) => {
  const response = await pureClient.post(
    `/v1/admin-tool-service/staff/${organisationId}/${login}/password/`,
    {
      password,
      token,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export default {
  createUser,
  getUsersByRole,
  getUsersByOrgId,
  getUsersByScope,
  getUserByLogin,
  updateUser,
  updatePassword,
  suspendUser,
  createScopes,
  createUserForStores,
  checkTemporaryPassword,
  changePasswordForOnboardingUser,
};
