// @flow

import React, { Component } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Modal } from 'antd';
import GridWithFilters from '../GridWithFilters';
import { SelectButton } from './elements';
import DoddleStores from '_common/stores/doddleStoresStore';
import { withTranslation } from 'react-i18next';

type Props = {|
  callback: (?TAddressModalData) => void,
  doddleStores: DoddleStores,
  t: TTranslateFunc,
|};

type State = {|
  resetFiltersTrigger: boolean,
|};

class AddressesModal extends Component<Props, State> {

  state = {
    resetFiltersTrigger: false,
  };

  filters = [
    {
      label: this.props.t('address'),
      placeholder: this.props.t('enterAddressModal'),
      type: 'input',
      key: 'address',
      path: 'address',
    },
  ];

  renderColumns = [
    {
      title: this.props.t('address'),
      dataIndex: 'address',
      key: 'address',
    },
  ];

  onCell = (row) => ({
    onClick: () => {
      this.props.callback({
        ...row,
        loadAddress: true,
      });
    },
  });

  getTableCollection = () => {
    const { getFilteredAddresses, getAddresses, isAddressFilterEmpty } =
      this.props.doddleStores;

    return getFilteredAddresses.length || !isAddressFilterEmpty
      ? getFilteredAddresses
      : getAddresses;
  };

  onCancel = () => {
    const { callback, doddleStores } = this.props;
    callback();
    doddleStores.resetFilteredAddresses();
    this.setState({
      resetFiltersTrigger: !this.state.resetFiltersTrigger,
    });
  };

  render() {
    const { doddleStores, t } = this.props;
    const { isAddressModalVisible, setFilteredAddresses, getAddresses } =
      doddleStores;

    return (
      <Modal
        open={isAddressModalVisible}
        title={t('chooseAddress')}
        footer={null}
        onOk={null}
        onCancel={this.onCancel}
        width={1048}
      >
        <GridWithFilters
          ignoreWrapper
          ignorePanel
          ignoreXScroll
          resetFilters={this.state.resetFiltersTrigger}
          uniqueDataId="id"
          filterData={getAddresses}
          onComplete={setFilteredAddresses}
          filters={this.filters}
          columns={this.renderColumns}
          gridData={this.getTableCollection()}
          rowKey="id"
          onCell={this.onCell}
          EditLinkComponent={<SelectButton>Select</SelectButton>}
        />
      </Modal>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  observer
)(AddressesModal);
