// @flow

import {
  allowEmptyValidator,
  boolToWord,
  generateGoogleMapsLink,
  getStoreLastEvent,
  requiredValidator,
  validateCoordinate,
  validateDate,
  validateEmail,
  validatePhoneNumber,
  validateUrl,
  getSubCountriesOptions,
} from '_common/utils/utils';
import doddleStoresActions from 'doddleStores/actions';
import moment from 'moment';
import { Roles } from '_common/constants/acl';
import commonActions from '_common/actions';
import { FLAG_OPTIONS } from '_common/constants/appConfig';
import { DoddleStores } from 'stores';
import { get } from 'lodash';
import {
  LOCATION_TYPE_OPTIONS,
  OPEN_CLOSE_SETTERS,
  STORE_EVENT_TYPES,
} from '_common/constants/stores';
import stores from 'stores';

export const storeDetailsBlock = (
  errors: Object,
  setError: Function,
  organisations: Array<TOption>,
  eventHistory: Array<Object>
): TInfoBlock => ({
  infoRows: [
    {
      name: 'Store ID',
      path: 'singleStore.storeId',
      aclAccess: false,
    },
    {
      name: 'Company Store ID',
      path: 'singleStore.companyStoreId',
      type: 'input',
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
      }),
      onUpdateClicked: (updateObj: Object) => {
        const previousValue = get(updateObj, 'data.singleStore.companyStoreId');
        doddleStoresActions.updateStoreById(
          updateObj.id,
          {
            companyStoreId: updateObj.currentValue,
          },
          undefined,
          undefined,
          () => {
            const companyId = get(updateObj, 'data.singleStore.companyId');
            if (!previousValue && companyId) {
              stores.usersStore.createUserForStores(companyId, [updateObj.id]);
            }
          }
        );
      },
    },
    {
      name: 'Company ID',
      path: 'singleStore.companyId',
      type: 'select',
      aclAccess: false,
      //$FlowFixMe
      options: organisations,
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          organisationId: updateObj.currentValue,
        });
      },
    },
    {
      name: 'Store name',
      path: 'singleStore.storeName',
      type: 'input',
      errorData: errors,
      showError: setError,
      validator: requiredValidator,
      errorMessage: 'inputStoreName',
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          storeName: updateObj.currentValue,
        });
      },
    },
    {
      name: 'Activation date',
      path: 'singleStore.openingDate',
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      errorData: errors,
      showError: setError,
      validator: validateDate(),
      errorMessage: 'Invalid date',
      type: 'datepicker',
      hideTime: true,
      customView: (data: string) => {
        const hasValidDate = moment(data).isValid();
        const openingDateEvent = getStoreLastEvent(
          { eventHistory },
          STORE_EVENT_TYPES.OPENING_DATE
        );
        return (
          (hasValidDate && openingDateEvent && openingDateEvent.openingDate) ||
          '-'
        );
      },
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.setOpeningOrClosingDate(
          OPEN_CLOSE_SETTERS.SET_OPENING_DATE,
          moment(updateObj.currentValue).format('YYYY-MM-DD')
        );
      },
    },
    {
      name: 'External store ID',
      path: 'singleStore.externalStoreId',
      type: 'input',
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
      }),
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          externalStoreId: updateObj.currentValue,
        });
      },
    },
    {
      name: 'Location type',
      path: 'singleStore.locationType',
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
      }),
      type: 'select',
      localizeOptions: true,
      localizeOptionsNS: 'stores',
      localizeValue: true,
      options: LOCATION_TYPE_OPTIONS,
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          locationType: updateObj.currentValue,
        });
      },
    },
  ],
  translateNS: 'stores',
  idPropName: 'singleStore.storeId',
  header: 'Store details',
});

export const developerBlock = () => ({
  infoRows: [
    {
      name: 'Is a demo?',
      path: 'singleStore.isDemo',
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
      }),
      localizeValue: true,
      compute: boolToWord,
      type: 'select',
      localizeOptions: true,
      options: FLAG_OPTIONS,
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          isDemo: updateObj.currentValue,
        });
      },
    },
    {
      name: 'Onboarding feed',
      path: 'singleStore.storeConfiguration.includeInOnboardingFeed',
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      localizeValue: true,
      compute: boolToWord,
      type: 'select',
      localizeOptions: true,
      options: FLAG_OPTIONS,
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          storeConfiguration: {
            ...get(updateObj.data, 'singleStore.storeConfiguration', {}),
            includeInOnboardingFeed: updateObj.currentValue,
          },
        });
      },
    },
  ],
  translateNS: 'stores',
  idPropName: 'singleStore.storeId',
  header: 'Developer',
});

export const locationBlock1 = (
  errors: Object,
  setError: Function,
  doddleStores: DoddleStores
): TInfoBlock => {
  let countryValue = get(doddleStores, 'singleStore.place.address.country');
  const locationType = get(doddleStores, 'singleStore.locationType');
  // Temporary workaround due to incorrect values in country field
  if (countryValue === 'Australia') {
    countryValue = 'AU';
  }
  const subCountryOptions = getSubCountriesOptions(
    doddleStores,
    locationType,
    countryValue
  );

  return {
    infoRows: [
      {
        aclAccess: commonActions.canAccessFeature({
          roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
        }),
        name: 'Country',
        path: 'singleStore.place.address.country',
        type: 'select',
        options: doddleStores.getCountriesOptions,
        localizePlaceholder: false,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            place: {
              countrySubdivision: '',
              address: {
                ...get(updateObj.data, 'singleStore.place.address', {}),
                country: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        aclAccess: commonActions.canAccessFeature({
          roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
        }),
        name: 'Country subdivision',
        path: 'singleStore.place.countrySubdivision',
        type: 'select',
        localizePlaceholder: false,
        options: subCountryOptions ? subCountryOptions : [],
        onUpdateClicked: (updateObj: Object) => {
          if (
            subCountryOptions &&
            subCountryOptions.find(
              (subCountry) => subCountry.value === updateObj.currentValue
            )
          ) {
            doddleStoresActions.updateStoreById(updateObj.id, {
              place: {
                countrySubdivision: updateObj.currentValue,
              },
            });
          }
        },
      },
      {
        aclAccess: commonActions.canAccessFeature({
          roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
        }),
        name: 'Address Line 1',
        path: 'singleStore.place.address.line1',
        type: 'input',
        errorData: errors,
        showError: setError,
        validator: requiredValidator,
        errorMessage: 'inputLine1',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            place: {
              address: {
                ...get(updateObj.data, 'singleStore.place.address', {}),
                line1: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        aclAccess: commonActions.canAccessFeature({
          roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
        }),
        name: 'Post code',
        path: 'singleStore.place.address.postcode',
        type: 'input',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            place: {
              address: {
                ...get(updateObj.data, 'singleStore.place.address', {}),
                postcode: updateObj.currentValue.trim()
                  ? updateObj.currentValue.trim()
                  : null,
              },
            },
          });
        },
      },
    ],
    translateNS: 'stores',
    idPropName: 'singleStore.storeId',
    header: 'Location',
  };
};

export const locationBlock2 = (
  errors: Object,
  setError: Function
): TInfoBlock => ({
  infoRows: [
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Address Line 2',
      path: 'singleStore.place.address.line2',
      type: 'input',
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          place: {
            address: {
              ...get(updateObj.data, 'singleStore.place.address', {}),
              line2: updateObj.currentValue,
            },
          },
        });
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Town',
      path: 'singleStore.place.address.town',
      type: 'input',
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          place: {
            address: {
              ...get(updateObj.data, 'singleStore.place.address', {}),
              town: updateObj.currentValue.trim()
                ? updateObj.currentValue.trim()
                : null,
            },
          },
        });
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Area',
      path: 'singleStore.place.address.area',
      type: 'input',
      errorData: errors,
      showError: setError,
      validator: requiredValidator,
      errorMessage: 'inputArea',
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          place: {
            address: {
              ...get(updateObj.data, 'singleStore.place.address', {}),
              area: updateObj.currentValue,
            },
          },
        });
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Directions to store',
      path: 'singleStore.place.howToFindUs',
      type: 'input',
      errorData: errors,
      showError: setError,
      validator: requiredValidator,
      errorMessage: 'inputDirections',
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          place: {
            howToFindUs: updateObj.currentValue,
          },
        });
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Latitude',
      path: 'singleStore.geo.lat',
      type: 'input',
      errorData: errors,
      showError: setError,
      validator: validateCoordinate,
      errorMessage: 'invalidCoordinates',
      onUpdateClicked: (updateObj: Object) => {
        const lon = get(updateObj.data, 'singleStore.geo.lon');
        const lat = updateObj.currentValue;
        let updateData = {
          geo: {
            ...get(updateObj.data, 'singleStore.geo', {}),
            lat: parseFloat(updateObj.currentValue) || 0,
          },
        };
        if (lat && lon) {
          updateData = {
            ...updateData,
            place: { map: generateGoogleMapsLink({ lat, lon }) },
          };
        }
        doddleStoresActions.updateStoreById(updateObj.id, updateData);
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Longitude',
      path: 'singleStore.geo.lon',
      type: 'input',
      errorData: errors,
      showError: setError,
      validator: validateCoordinate,
      errorMessage: 'invalidCoordinates',
      onUpdateClicked: (updateObj: Object) => {
        const lon = updateObj.currentValue;
        const lat = get(updateObj.data, 'singleStore.geo.lat');
        let updateData = {
          geo: {
            ...get(updateObj.data, 'singleStore.geo', {}),
            lon: parseFloat(updateObj.currentValue) || 0,
          },
        };
        if (lat && lon) {
          updateData = {
            ...updateData,
            place: { map: generateGoogleMapsLink({ lat, lon }) },
          };
        }
        doddleStoresActions.updateStoreById(updateObj.id, updateData);
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Google maps URL',
      path: 'singleStore.place.map',
      type: 'input',
      link: true,
      errorData: errors,
      showError: setError,
      validator: allowEmptyValidator(validateUrl),
      errorMessage: 'invalidUrl',
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          place: {
            map: updateObj.currentValue,
          },
        });
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
      }),
      name: 'Restricted access',
      path: 'singleStore.place.restrictedAccess',
      localizeValue: true,
      compute: boolToWord,
      type: 'select',
      localizeOptions: true,
      options: FLAG_OPTIONS,
      onUpdateClicked: (updateObj: Object) => {
        doddleStoresActions.updateStoreById(updateObj.id, {
          place: {
            restrictedAccess: updateObj.currentValue,
          },
        });
      },
    },
  ],
  header: '',
  translateNS: 'stores',
  idPropName: 'singleStore.storeId',
});

export const contactBlock = (
  errors: Object,
  setError: Function
): TInfoBlock => ({
  infoRows: [
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Email address',
      path: 'singleStore.place.emailAddress',
      type: 'input',
      errorData: errors,
      showError: setError,
      validator: allowEmptyValidator(validateEmail),
      errorMessage: 'invalidEmail',
      onUpdateClicked: ({ id, currentValue }: Object) => {
        doddleStoresActions.updateStoreById(id, {
          place: {
            emailAddress: currentValue,
          },
        });
      },
    },
    {
      aclAccess: commonActions.canAccessFeature({
        roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      }),
      name: 'Phone number',
      path: 'singleStore.place.phoneNumber',
      type: 'input',
      errorData: errors,
      showError: setError,
      validator: allowEmptyValidator(validatePhoneNumber),
      errorMessage: 'invalidPhone',
      onUpdateClicked: ({ id, currentValue }: Object) => {
        doddleStoresActions.updateStoreById(id, {
          place: {
            phoneNumber: currentValue,
          },
        });
      },
    },
  ],
  translateNS: 'stores',
  idPropName: 'singleStore.storeId',
  header: 'Contact',
});
