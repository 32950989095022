// @flow

import updateCollectionStatus from '../../actions/updateCollectionStatus';
import updateCollectionDeadline from '../../actions/updateCollectionDeadline';
import get from 'lodash/get';

export const parcelReciepientInfoBlock = {
  infoRows: [
    {
      name: 'Name',
      path: 'getSingleCollectionCustomerName',
    },
    {
      name: 'Doddle ID',
      path: 'singleCollection.customer.customerId',
    },
    {
      name: 'Email address',
      path: 'singleCollection.customer.email',
    },
    {
      name: 'Mobile number',
      path: 'singleCollection.customer.mobile',
      compute: (data: any) => {
        if (typeof data === 'string') {
          return data;
        }
        return get(data, 'number') || '';
      },
    },
  ],
  translateNS: 'parcels',
  idPropName: 'singleCollection.itemId',
  header: 'Recipient',
};

export const parcelDetailsInfoBlock = {
  infoRows: [
    {
      name: 'Company',
      path: 'singleCollection.organisationId',
    },
    {
      name: 'Retailer name',
      path: 'singleCollection.retailerNameOverride',
    },
    {
      name: 'Parcel UPI',
      path: 'singleCollection.labelValue',
    },
    {
      name: 'Order ID',
      path: 'singleCollection.retailerOrderId',
    },
    {
      name: 'Item ID',
      path: 'singleCollection.itemId',
    },
    {
      name: 'Parcel Reference',
      path: 'singleCollection.referenceId',
    },
  ],
  translateNS: 'parcels',
  idPropName: 'singleCollection.itemId',
  header: 'Parcel details',
};

export const parcelStatusInfoBlock = (storeName: string) => ({
  infoRows: [
    {
      name: 'Store',
      path: 'singleCollection.location.storeId',
      customValue: storeName,
      aclAccess: false,
    },
    {
      name: 'Parcel status',
      path: 'singleCollection.status',
      type: 'select',
      options: [
        {
          value: 'SENT_TO_RETAILER',
          label: 'SENT_TO_RETAILER',
        },
        {
          value: 'VOID',
          label: 'VOID',
        },
        {
          value: 'WITH_CUSTOMER',
          label: 'WITH_CUSTOMER',
        },
      ],
      onUpdateClicked: (updateObj: Object) => {
        updateCollectionStatus(updateObj);
      },
    },
    {
      name: 'Parcel storage location',
      path: 'singleCollection.location.shelfId',
    },
  ],
  translateNS: 'parcels',
  idPropName: 'singleCollection.itemId',
  header: 'Parcel status',
});

export const parcelLogisticsInfoBlock = {
  infoRows: [
    {
      name: 'Carrier',
      path: 'singleCollection.deliveryCarrierId',
    },
    {
      name: 'Arrived in store',
      path: 'getArrivedInStore',
    },
    {
      name: 'Collection deadline',
      path: 'getCollectionDeadlineString',
      type: 'datepicker',
      onUpdateClicked: (updateObj: Object) => {
        updateCollectionDeadline(updateObj);
      },
    },
    {
      name: 'Collected',
      path: 'getCollectionIsCollected',
    },
  ],
  translateNS: 'parcels',
  idPropName: 'singleCollection.itemId',
  header: 'Logistics',
};
