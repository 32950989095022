// @flow

import React, { Fragment } from 'react';
import {
  DialogRoot,
  Header,
  ButtonsWrapper,
  ConfirmButton,
  CancelButton,
  DismissWrapper,
  HeaderText,
  DialogWrapper,
  DialogWrapperInner,
  BodyCustomWrapper,
} from './elements';
import { set } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Icon } from '_common/components';
import { closeIcon } from 'assets';

class Dialog extends React.Component<TDialogProps> {

  static defaultProps = {
    innerWrapperFlexAlign: 'space-between',
    justifyHeaderContent: 'space-between',
    disableScroll: true,
    headerFontSize: 24,
    isDisabled: false,
    isConfirmDisabled: false,
    isError: false,
    minHeight: 280,
    dismissWrapperPosition: 'fixed',
  };

  componentDidUpdate(prevProps: TDialogProps): void {
    const { isShown, disableScroll } = this.props;
    if (prevProps.isShown !== isShown && disableScroll) {
      set(document, 'body.style.overflow', isShown ? 'hidden' : 'visible');
    }
  }

  allowClickInsideDialog = (e: Object) => e.stopPropagation();

  render() {
    const {
      id,
      isError,
      isDisabled,
      isShown,
      headerText,
      justifyHeaderContent,
      handleCancelClick,
      handleConfirmClick,
      displayCancelButton,
      renderContent,
      cancelLabel,
      confirmLabel,
      helper,
      isConfirmDisabled,
      headerFontSize,
      minHeight,
      height,
      width,
      maxHeight,
      customHeader,
      dismissWrapperPosition,
      t,
      CustomDialogRoot,
      withCloseIcon,
      innerWrapperFlexAlign,
      minWidth,
    } = this.props;
    const hasHeader = !!headerText;
    const hasHelper = !!helper;

    const DialogRootElem = CustomDialogRoot ? CustomDialogRoot : DialogRoot;
    const BodyWrapperElement = height ? BodyCustomWrapper : Fragment;

    return (
      isShown && (
        <DismissWrapper
          position={dismissWrapperPosition}
          onClick={handleCancelClick}
        >
          <DialogRootElem
            id={id}
            maxHeight={maxHeight}
            width={width}
            onClick={this.allowClickInsideDialog}
          >
            <DialogWrapper>
              {withCloseIcon && (
                <Icon
                  size="90%"
                  image={closeIcon}
                  onClick={handleCancelClick}
                />
              )}
              <DialogWrapperInner
                className="DialogWrapperInner"
                innerWrapperFlexAlign={innerWrapperFlexAlign}
                skipMaxHeight={!!maxHeight}
                minHeight={minHeight}
                height={height}
                minWidth={minWidth}
              >
                {customHeader ? (
                  customHeader
                ) : (
                  <Header
                    fontSize={headerFontSize}
                    justifyContent={justifyHeaderContent}
                  >
                    {hasHeader && <HeaderText>{headerText}</HeaderText>}
                    {hasHelper && helper}
                  </Header>
                )}
                <BodyWrapperElement>
                  {renderContent ? renderContent() : null}
                  <ButtonsWrapper>
                    {!isError && handleConfirmClick && (
                      <ConfirmButton
                        onClick={handleConfirmClick}
                        disabled={isDisabled || isConfirmDisabled}
                      >
                        {confirmLabel ? confirmLabel : t('confirm')}
                      </ConfirmButton>
                    )}
                    {handleCancelClick && displayCancelButton && (
                      <CancelButton
                        onClick={handleCancelClick}
                        disabled={isDisabled}
                      >
                        {cancelLabel ? cancelLabel : t('cancel')}
                      </CancelButton>
                    )}
                  </ButtonsWrapper>
                </BodyWrapperElement>
              </DialogWrapperInner>
            </DialogWrapper>
          </DialogRootElem>
        </DismissWrapper>
      )
    );
  }

}

export default withTranslation()(Dialog);
