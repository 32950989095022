// @flow

import React from 'react';
import { StyledButton } from './elements';

type Props = {
  [key: any]: any,
  children: any,
};

const Button = React.forwardRef<Props, any>(
  ({ greyed, ...props }: Props, ref) => {
    return <StyledButton {...props}>{props.children}</StyledButton>;
  }
);

export default Button;
