import { boolToWord } from '_common/utils/utils';
import { get } from 'lodash';
import stores from 'stores';
import { Roles } from '_common/constants/acl';
import { FEATURES } from '_common/stores/aclStore';

export const hostMarketingBlock = (
  hasPermissions: boolean,
  { storeUsersCanManagePromotions }: Object
): TInfoBlock => ({
  infoRows: [
    {
      name: 'Enabled',
      path: 'singleCompany.products.HOST_MARKETING_PORTAL.enabled',
      aclAccess: hasPermissions,
      type: 'switch',
      compute: boolToWord,
      localizeValue: true,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany, singleCompany },
      }: TDetailsUpdate) => {
        if (
          get(singleCompany, 'products.HOST_MARKETING_PORTAL.enabled') !==
          currentValue
        ) {
          updateCompany(id, {
            products: {
              HOST_MARKETING_PORTAL: {
                enabled: currentValue,
              },
            },
          }).then(result => {
            if (result && currentValue) {
              stores.promotionsStore.loadPromotions();
            }
          });
        }
      },
    },
    {
      name: 'Enable store users to create and edit their own promotions',
      translateKey: 'company:enableStoreUsersManagePromotions',
      path: '',
      customValue: storeUsersCanManagePromotions,
      customView: value => value,
      compute: boolToWord,
      aclAccess: hasPermissions,
      type: 'switch',
      localizeValue: true,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { singleCompany },
      }: TDetailsUpdate) => {
        if (singleCompany.companyId) {
          stores.companiesStore.toggleCustomRoleFeature(
            Roles.STORE,
            FEATURES.MANAGE_PROMOTIONS,
            currentValue
          );
        }
      },
    },
  ],
  idPropName: 'singleCompany.companyId',
  header: '',
});
