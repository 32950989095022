// @flow

import React from 'react';
import {
  SelectBlock,
  FormPartCaption,
  SelectedCompanyName,
  SelectedCompanyId,
  SelectCompaniesWrapper,
  UserLabel,
  SelectedCompany,
  RemoveCompanyButton,
  SelectedCompaniesWrapper,
  SelectSectionWrapper,
} from './elements';
import { Select } from '_common/components';
import { Form, Pagination, Tooltip } from 'antd';
import { chunk } from 'lodash';
import { PaginationWrapper } from '_common/components/PlainStyles';
import { withTranslation } from 'react-i18next';

type State = {
  page: number,
};

type Props = {
  options: Array<TOption>,
  parentCompanyId: string,
  onCompanyAdd: Function,
  onCompanyRemove: Function,
  selectedCompanies: Array<TOption>,
  isDisabled?: boolean,
  t: TTranslateFunc,
  parentCompanyOptions: Object,
};

class CompaniesSelect extends React.PureComponent<Props, State> {

  PAGE_SIZE = 5;

  state = {
    page: 1,
  };

  componentDidUpdate(prevProps: Props) {
    const { page } = this.state;
    const { selectedCompanies } = this.props;
    // Go to previous page when company is removed and there are no more companies on current page
    if (
      selectedCompanies.length !== 0 &&
      selectedCompanies.length < prevProps.selectedCompanies.length &&
      selectedCompanies.length % this.PAGE_SIZE === 0 &&
      this.state.page * this.PAGE_SIZE > selectedCompanies.length
    ) {
      this.changePage(page - 1);
    }
  }

  onSelect = (option: ?TOption) => {
    if (option) {
      this.props.onCompanyAdd(option.value);
    }
  };

  onRemove = (company: any) => () => {
    this.props.onCompanyRemove(company);
  };

  renderSelectedCompany = ({ label, value }: TOption) => {
    const { parentCompanyId, t } = this.props;
    const isParentCompany = value === parentCompanyId;

    const removeButton = (
      <RemoveCompanyButton
        disabled={isParentCompany}
        onClick={this.onRemove(value)}
      >
        {t('common:remove')}
      </RemoveCompanyButton>
    );

    return (
      <SelectedCompany key={value}>
        <SelectedCompanyName>{label}</SelectedCompanyName>
        <SelectedCompanyId>{value}</SelectedCompanyId>
        {isParentCompany ? (
          <Tooltip placement="top" title={t('cannotRemoveParent')}>
            {removeButton}
          </Tooltip>
        ) : (
          removeButton
        )}
      </SelectedCompany>
    );
  };

  changePage = (page: number) => {
    this.setState({
      page,
    });
  };

  showTotal = (total: string | number) =>
    this.props.t('totalCompanies', { count: total });

  render() {
    const {
      options,
      selectedCompanies,
      isDisabled,
      t,
      parentCompanyOptions,
    } = this.props;
    const { page } = this.state;

    const {
      showParentCompanySelect,
      isDisabled: isParentCompanyDisabled,
      disabled: isParentCompanyControlDisabled,
      initialValue: initialParentCompany,
      onParentCompanyChange,
    } = parentCompanyOptions;

    const selectedCompaniesChunks = chunk(selectedCompanies, this.PAGE_SIZE);
    const companiesChunk = selectedCompaniesChunks.length
      ? selectedCompaniesChunks[page - 1]
      : [];
    const totalCompanies = selectedCompanies.length;

    return (
      <SelectBlock>
        <FormPartCaption>{t('companies')}</FormPartCaption>
        {showParentCompanySelect && (
          <SelectSectionWrapper>
            <UserLabel>{t('organisation')}</UserLabel>
            <Form.Item name="organisationId">
              <Select
                checkInitial
                initialValue={initialParentCompany}
                isDisabled={isParentCompanyDisabled}
                forceDeepInitialized={false}
                onSelectOption={onParentCompanyChange}
                disabled={isParentCompanyControlDisabled}
                options={options}
                inputPlaceholder={t('selectOrganisation')}
                selectPlaceholder={t('organisationId')}
              />
            </Form.Item>
          </SelectSectionWrapper>
        )}
        <UserLabel>{t('selectCompany')}</UserLabel>
        <SelectCompaniesWrapper>
          <Select
            isDisabled={isDisabled}
            disableSelection
            onSelectOption={this.onSelect}
            inputPlaceholder={t('selectCompany')}
            selectPlaceholder={t('selectCompany')}
            options={options}
          />
        </SelectCompaniesWrapper>
        {companiesChunk && (
          <SelectedCompaniesWrapper>
            {companiesChunk.map(this.renderSelectedCompany)}
          </SelectedCompaniesWrapper>
        )}
        <PaginationWrapper>
          <Pagination
            size="small"
            showTotal={this.showTotal}
            total={totalCompanies}
            current={page}
            hideOnSinglePage
            onChange={this.changePage}
            pageSize={this.PAGE_SIZE}
          />
        </PaginationWrapper>
      </SelectBlock>
    );
  }

}

export default withTranslation('users')(CompaniesSelect);
