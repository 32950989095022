// @flow

import React from 'react';
import {
  CompanyItem,
  IconContainer,
  ItemContainer,
  ParentCompanyItem,
  CompanyNameContainer,
} from './elements';
import { Tooltip } from 'antd';
import { CaretUpOutlined, InfoCircleOutlined } from '@ant-design/icons';

type Props = {
  companyName: string,
  isParent: boolean,
  selected?: boolean,
  onClick?: Function,
};

type State = {
  overflowActive: boolean,
};

class CompanyHierarchyItem extends React.Component<Props, State> {

  _companyNameRef = React.createRef();

  state = {
    overflowActive: false,
  };

  componentDidMount() {
    this.setState({
      overflowActive: this.isEllipsisActive(this._companyNameRef.current),
    });
  }

  isEllipsisActive(e: Object) {
    let state = false;
    if (e) {
      state = e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
    }
    return state;
  }

  render() {
    const { overflowActive } = this.state;
    const { selected, companyName, isParent, onClick } = this.props;
    const ItemElement = isParent ? ParentCompanyItem : CompanyItem;

    return (
      <ItemElement onClick={onClick} selected={selected}>
        <ItemContainer>
          <CompanyNameContainer ref={this._companyNameRef}>
            {companyName}
          </CompanyNameContainer>
          <IconContainer>
            {overflowActive && (
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById('companies-dialog')
                }
                overlayStyle={{
                  zIndex: 20000,
                  fontSize: '13px',
                  maxWidth: '300px',
                }}
                placement="top"
                title={companyName}
              >
                <InfoCircleOutlined />
              </Tooltip>
            )}
            {isParent && <CaretUpOutlined />}
          </IconContainer>
        </ItemContainer>
      </ItemElement>
    );
  }

}

export default CompanyHierarchyItem;
