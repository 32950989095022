// @flow

import React, { Fragment } from 'react';
import { ACLStore, CompaniesStore } from 'stores';
import { getInfoBlock } from '_common/utils/utils';
import { hostMarketingBlock } from './config';
import commonActions from '_common/actions';
import { Roles } from '_common/constants/acl';
import { Row, Col } from 'antd';
import OfferTemplatesBlock from './OfferTemplates';
import { OFFER_MANAGEMENT_COMPANY_IDS } from '_common/constants/promotions';
import { get } from 'lodash';
import { FEATURES } from '_common/stores/aclStore';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

const CompanyDetailsHostMarketing = ({
  companiesStore,
  aclStore,
}: {
  aclStore: ACLStore,
  companiesStore: CompaniesStore,
}) => {
  return (
    <Fragment>
      <Row type="flex">
        <Col xs={12}>
          {getInfoBlock(
            hostMarketingBlock(
              commonActions.canAccessFeature({
                roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
              }),
              {
                storeUsersCanManagePromotions: aclStore.canCustomRoleAccessFeature(
                  companiesStore.singleCompanyCustomRoles.get(Roles.STORE),
                  FEATURES.MANAGE_PROMOTIONS
                ),
              }
            ),
            false,
            companiesStore
          )}
        </Col>
      </Row>
      {OFFER_MANAGEMENT_COMPANY_IDS.includes(
        get(companiesStore.selectedCompany, 'companyId')
      ) && (
        <Row type="flex">
          <Col xs={24}>
            <OfferTemplatesBlock />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default compose(
  inject('companiesStore', 'aclStore'),
  observer
)(CompanyDetailsHostMarketing);
