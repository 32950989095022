// @flow strict

import links from '_common/routes/urls';
import DoddleStoresDetails from 'doddleStores/doddleStoresDetails/pages/Main/Main';
import DoddleStoresList from 'doddleStores/doddleStoresList/pages/Main/Main';
import DoddleStoresCreate from 'doddleStores/doddleStoresCreate/pages/Main/Main';
import RestrictedList from 'doddleStores/doddleStoresList/pages/RestrictedList';
import StoreDashboard from 'doddleStores/doddleStoresDetails/pages/StoreDashboard';

export default [
  {
    path: links.doddleStores.main,
    component: DoddleStoresList,
  },
  {
    path: links.doddleStores.restrictedList,
    component: RestrictedList,
  },
  {
    path: links.doddleStores.subroutes.createStore,
    component: DoddleStoresCreate,
  },
  {
    path: links.doddleStores.subroutes.dashboard,
    component: StoreDashboard,
  },
  {
    path: links.doddleStores.subroutes.singleStore,
    component: DoddleStoresDetails,
  },
];
