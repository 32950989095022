import {
  boolToWord,
  boolToWordReverse,
  maxLengthValidator,
  requiredValidator,
} from '_common/utils/utils';
import {
  FLAG_OPTIONS,
  FLAG_REVERSE_OPTIONS,
} from '_common/constants/appConfig';
import { COLLECTION_ID_CHECK_TYPE_OPTIONS } from '_common/constants/companies';

export const checksBlock = (hasPermissions: boolean): TInfoBlock => ({
  infoRows: [
    {
      name: 'Signature required for parcels?',
      path: 'singleCompany.collections.signatureRequired',
      aclAccess: hasPermissions,
      compute: boolToWord,
      localizeValue: true,
      localizeOptions: true,
      type: 'select',
      options: FLAG_OPTIONS,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { signatureRequired: currentValue },
          });
      },
    },
    {
      name: 'ID required for collection?',
      path: 'singleCompany.collections.requiredCollectionIdCheckType',
      aclAccess: hasPermissions,
      type: 'select',
      hasEmptyOption: true,
      localizeValue: true,
      localizeOptions: true,
      localizePlaceholder: true,
      emptyOptionLabel: 'none',
      compute: (value: any) => {
        let result = 'none';
        const option = COLLECTION_ID_CHECK_TYPE_OPTIONS.find(
          option => option.value === value
        );
        if (option) {
          result = option.label;
        }
        return result;
      },
      options: COLLECTION_ID_CHECK_TYPE_OPTIONS,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { requiredCollectionIdCheckType: currentValue },
          });
      },
    },
    {
      name: 'Fixed parcel price',
      path: 'singleCompany.collections.fixedParcelPrice',
      aclAccess: hasPermissions,
      type: 'input',
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { fixedParcelPrice: parseInt(currentValue, 10) },
          });
      },
    },
  ],
  translateNS: 'company',
  idPropName: 'singleCompany.companyId',
  header: 'Checks',
});

export const daysBlock = (hasPermissions: boolean): TInfoBlock => ({
  infoRows: [
    {
      name: 'Days to collect',
      path: 'singleCompany.collections.deadlineDays',
      aclAccess: hasPermissions,
      type: 'input',
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { deadlineDays: parseInt(currentValue, 10) },
          });
      },
    },
    {
      name: 'Days to collect includes weekends?',
      path: 'singleCompany.collections.deadlineDaysIncludeWeekends',
      aclAccess: hasPermissions,
      compute: boolToWord,
      localizeValue: true,
      localizeOptions: true,
      type: 'select',
      options: FLAG_OPTIONS,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { deadlineDaysIncludeWeekends: currentValue },
          });
      },
    },
    {
      name: 'Days to collect includes bank holidays?',
      path: 'singleCompany.collections.deadlineDaysIncludeHolidays',
      aclAccess: hasPermissions,
      compute: boolToWord,
      localizeValue: true,
      type: 'select',
      localizeOptions: true,
      options: FLAG_OPTIONS,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { deadlineDaysIncludeHolidays: currentValue },
          });
      },
    },
  ],
  translateNS: 'company',
  idPropName: 'singleCompany.companyId',
  header: 'Days',
});

export const emailBlock = (
  hasPermissions: boolean,
  isSendEmailViaCompanySet: boolean,
  companiesList: Array<TOption>
): TInfoBlock => {
  const emailSettingsEnabled = hasPermissions && !isSendEmailViaCompanySet;

  return {
    infoRows: [
      {
        name: 'Send collection notification',
        path: 'singleCompany.collections.emailConfig.sendEmail',
        compute: boolToWord,
        localizeValue: true,
        aclAccess: emailSettingsEnabled,
        type: 'select',
        localizeOptions: true,
        options: FLAG_OPTIONS,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, {
              collections: { emailConfig: { sendEmail: currentValue } },
            });
        },
      },
      {
        name: 'Send order confirmation',
        path: 'singleCompany.collections.emailConfig.suppressOrderConfirmation',
        aclAccess: emailSettingsEnabled,
        compute: boolToWordReverse,
        localizeValue: true,
        type: 'select',
        localizeOptions: true,
        options: FLAG_REVERSE_OPTIONS,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, {
              collections: {
                emailConfig: { suppressOrderConfirmation: currentValue },
              },
            });
        },
      },
      {
        name: 'Collection reminder frequency',
        path: 'singleCompany.collections.emailConfig.dwellEmailFrequencyDays',
        aclAccess: emailSettingsEnabled,
        type: 'input',
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, {
              collections: {
                emailConfig: {
                  dwellEmailFrequencyDays: parseInt(currentValue, 10),
                },
              },
            });
        },
      },
      {
        name: 'Send email via other company',
        path: 'singleCompany.sendEmailViaCompany',
        aclAccess: hasPermissions,
        type: 'select',
        options: companiesList,
        hasEmptyOption: true,
        emptyOptionLabel: 'none',
        compute: (value?: string) => (value ? value : 'none'),
        localizeValue: true,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, { sendEmailViaCompany: currentValue });
        },
      },
      {
        name: 'Send Communications via other company',
        path: 'singleCompany.sendCommsViaCompany',
        aclAccess: hasPermissions,
        type: 'select',
        options: companiesList,
        hasEmptyOption: true,
        emptyOptionLabel: 'none',
        compute: (value?: string) => (value ? value : 'none'),
        localizeValue: true,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, { sendCommsViaCompany: currentValue });
        },
      },
    ],
    translateNS: 'company',
    idPropName: 'singleCompany.companyId',
    header: 'Email',
  };
};

export const smsBlock = (
  hasPermissions: boolean,
  smsEnabled: boolean,
  errors: Object,
  setError: Function
) => ({
  infoRows: [
    {
      name: 'Send collection notification',
      path: 'singleCompany.collections.smsConfig.sendSMS',
      aclAccess: hasPermissions,
      type: 'select',
      compute: boolToWord,
      localizeOptions: true,
      localizeValue: true,
      options: FLAG_OPTIONS,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { smsConfig: { sendSMS: currentValue } },
          });
      },
    },
    {
      name: 'From name',
      path: 'singleCompany.collections.smsConfig.sendSMSFrom',
      aclAccess: hasPermissions && smsEnabled,
      errorData: errors,
      errorMessage: 'inputFromName',
      showError: setError,
      validator: requiredValidator,
      type: 'input',
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { smsConfig: { sendSMSFrom: currentValue } },
          });
      },
    },
    {
      name: 'Message',
      path: 'singleCompany.collections.smsConfig.SMSTemplate',
      aclAccess: hasPermissions && smsEnabled,
      errorData: errors,
      errorMessage: 'messageMaxLength',
      translateReplace: { count: 280 },
      showError: setError,
      validator: maxLengthValidator(280),
      type: 'input',
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, {
            collections: { smsConfig: { SMSTemplate: currentValue } },
          });
      },
    },
  ],
  translateNS: 'company',
  idPropName: 'singleCompany.companyId',
  header: 'SMS',
});
