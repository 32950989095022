// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { PanelSectionTitle, Input, GridWithFilters } from '_common/components';
import { Row, Col, Radio } from 'antd';
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';
import { ParcelsStore } from 'stores';
import searchActions from 'search/actions';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import {
  SubText,
  RowWrapper,
  Line,
  SearchButton,
  StyledRadio,
  RadioWrapper,
  TableWrapper,
  FilterWrapper,
} from './elements';
import UIStore from '_common/stores/uiStore';
import { withTranslation } from 'react-i18next';

type State = {|
  selectedValue: string,
|};

type Props = {|
  parcelsStore: ParcelsStore,
  onSearchComplete?: (searchType: string) => {},
  onComplete: (Array<Object>, boolean) => void,
  filters: Array<TFilter>,
  columns: TColumns,
  onCell: Object => { onClick: Object => void },
  uiStore: UIStore,
  EditLinkComponent?: Node,
  actionRedirectLink?: Object => string,
  t: TTranslateFunc,
|};

const RADIO_OPTIONS = {
  collection: 'collection',
  returns: 'return',
};

class Parcels extends React.Component<Props, State> {

  state = {
    selectedValue: RADIO_OPTIONS.collection,
  };

  setRadioOption = value => {
    this.setState({
      selectedValue: value,
    });
  };

  getSearchHistory = () => {
    const {
      getSearchHistory,
      getFilteredSearchHistory,
    } = this.props.parcelsStore;

    const method = !this.getSearchFiler()
      ? getFilteredSearchHistory
      : getSearchHistory;

    return get(method['parcels'], 'items', []);
  };

  getOriginalCollection = () => {
    const { getSearchHistory } = this.props.parcelsStore;

    return get(getSearchHistory['parcels'], 'items', []);
  };

  getSearchFiler = () => {
    const { getFilteredSearchHistory } = this.props.parcelsStore;

    return get(getFilteredSearchHistory['parcels'], 'isFiltersEmpty');
  };

  getIsSearchInit = () => {
    const { getSearchHistory } = this.props.parcelsStore;

    return get(getSearchHistory['parcels'], 'isSearchInit', false);
  };

  onSearch = () => {
    const { selectedValue } = this.state;
    const {
      onSearchComplete,
      uiStore: { parcelsSearchParams: parcelCode },
    } = this.props;

    if (!parcelCode.trim()) return;

    switch (selectedValue) {
      case RADIO_OPTIONS.collection:
        return searchActions.searchCollection(parcelCode.trim()).then(() => {
          if (onSearchComplete) {
            onSearchComplete(selectedValue);
          }
        });
      case RADIO_OPTIONS.returns:
        return searchActions.searchReturns(parcelCode.trim()).then(() => {
          if (onSearchComplete) {
            onSearchComplete(selectedValue);
          }
        });
      default:
        return;
    }
  };

  renderParcelDetailsForm = () => {
    const { uiStore, t } = this.props;

    return (
      <Fragment>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Radio.Group value={this.state.selectedValue}>
              <RadioWrapper
                selectedOption={
                  this.state.selectedValue === RADIO_OPTIONS.collection
                }
                onClick={() => this.setRadioOption(RADIO_OPTIONS.collection)}
              >
                <StyledRadio value={RADIO_OPTIONS.collection}>
                  {t('collection')}
                </StyledRadio>
              </RadioWrapper>
              <RadioWrapper
                selectedOption={
                  this.state.selectedValue === RADIO_OPTIONS.returns
                }
                onClick={() => this.setRadioOption(RADIO_OPTIONS.returns)}
              >
                <StyledRadio value={RADIO_OPTIONS.returns}>
                  {t('return')}
                </StyledRadio>
              </RadioWrapper>
            </Radio.Group>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={15}>
            <Input
              value={uiStore.parcelsSearchParams}
              id={'parcelCode'}
              placeholder={t('enterParcelCode')}
              onChange={e => {
                uiStore.setParcelsSearchParams(e.target.value);
              }}
            />
            <SubText>{t('exampleQuery')}</SubText>
          </Col>
        </Row>
      </Fragment>
    );
  };

  render() {
    const {
      parcelsStore,
      onComplete,
      filters,
      columns,
      onCell,
      actionRedirectLink,
      EditLinkComponent,
      t,
    } = this.props;

    return (
      <Fragment>
        <PanelSectionTitle>{t('parcelDetails')}</PanelSectionTitle>
        <RowWrapper>
          <Row>
            <Col span={12}>{this.renderParcelDetailsForm()}</Col>
          </Row>
        </RowWrapper>
        <Line />
        <SearchButton
          onClick={this.onSearch}
          loading={parcelsStore.isSearchInProgress}
        >
          {t('search')}
        </SearchButton>

        <TableWrapper>
          {(!!this.getSearchHistory().length || this.getIsSearchInit()) && (
            <GridWithFilters
              loading={parcelsStore.isSearchInProgress}
              resetFilters={false}
              uniqueDataId={'itemId'}
              filterData={this.getOriginalCollection()}
              onComplete={onComplete}
              filters={filters}
              columns={columns}
              gridData={this.getSearchHistory()}
              rowKey={'itemId'}
              onCell={onCell}
              FilterWrapperComponent={FilterWrapper}
              ignorePanel
              actionRedirectLink={actionRedirectLink}
              EditLinkComponent={EditLinkComponent}
            />
          )}
        </TableWrapper>
      </Fragment>
    );
  }

}

export default compose(
  inject('parcelsStore', 'uiStore'),
  withRouter,
  withTranslation('search'),
  observer
)(Parcels);
