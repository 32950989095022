// @flow

import React, { Fragment, Component } from 'react';
import { CompaniesMenuTitle } from './elements';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { ACLStore, CompaniesStore } from 'stores';
import { Roles } from '_common/constants/acl';
import CompaniesDialog from './CompaniesDialog';
import { withTranslation } from 'react-i18next';

type Props = {|
  aclStore: ACLStore,
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
|};

type State = {
  isDialogOpened: boolean,
};

class CompaniesSelector extends Component<Props, State> {

  state = {
    isDialogOpened: false,
  };

  toggleCompaniesDialogOpened = () => {
    this.setState(state => ({
      isDialogOpened: !state.isDialogOpened,
    }));
  };

  selectCompany = (companyId: string) => {
    this.props.aclStore.setSelectedCompanyId(companyId);
  };

  render() {
    const { aclStore, companiesStore, t } = this.props;
    const { isDialogOpened } = this.state;

    const canCreateCompany = aclStore.canAccessFeature({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
    });

    const userCompaniesList = companiesStore.getCompanyObjectsList(
      companiesStore.getUserCompanies
    );

    return (
      <Fragment>
        <CompaniesMenuTitle onClick={this.toggleCompaniesDialogOpened}>
          {t('companies')}
        </CompaniesMenuTitle>
        <CompaniesDialog
          getCompanyName={companiesStore.getCompanyName}
          dialogProps={{
            id: 'companies-dialog',
            isShown: isDialogOpened,
            displayCancelButton: false,
            handleCancelClick: this.toggleCompaniesDialogOpened,
          }}
          companies={userCompaniesList}
          canCreateCompany={canCreateCompany}
          selectedCompanyId={aclStore.getSelectedCompanyId}
          onSelectCompany={this.selectCompany}
        />
      </Fragment>
    );
  }

}

export default compose(
  inject('aclStore', 'companiesStore'),
  withTranslation('menu'),
  observer
)(CompaniesSelector);
