import React, { Component, Fragment } from 'react';
import { Dialog } from '_common/components';
import {
  convertFileDataToBase64URL,
  convertImgToFileData,
  getFileData,
  getImageNameAndFormatFromUrl,
  handleApiError,
  validateUrl,
  getPromoImageLibraryUrl,
} from '_common/utils/utils';
import Button from '../Button/Button';
import { isEmpty, debounce, get, chunk, omit } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Progress,
  Row,
  Tabs,
  Typography,
  Input as AntdInput,
  Pagination,
} from 'antd';
import {
  CustomTabs,
  FileName,
  ImageWrapper,
  MutedOverlay,
  LibraryImageWrapper,
  SelectFileButton,
  SelectFileInput,
  AnotherImageButton,
  RemoveButton,
  SizeInfo,
  UrlInput,
} from './elements';
import { SeparationLine } from '../TabsForm/elements';
import { PaginationWrapper } from '../PlainStyles';
import commonActions from '_common/actions';

const TAB_KEYS = {
  LIBRARY: 'LIBRARY',
  FILE: 'FILE',
  URL: 'URL',
};

const IMAGE_LIBRARY_PAGE_SIZE = 15;

type Props = {
  t: TTranslateFunc,
  onConfirm: Function,
  defaultActiveTab: string,
  controlled: boolean,
  imageLibrary: Array<string>,
  maxSize?: number,
};

type ImageDataType = {
  data: ?(TFileDataType | string),
  name?: string,
};

type State = {
  inputUrl: string,
  isProcessing: boolean,
  imageData: {
    [TAB_KEYS.FILE]: ImageDataType,
    [TAB_KEYS.URL]: ImageDataType,
    [TAB_KEYS.LIBRARY]: ImageDataType,
  },
  libraryPage: number,
  confirmedImage: ?string,
  headerText: string,
  forceClose: boolean,
  isDialogShown: boolean,
  errorLoadImage: string,
  activeTab: string,
};

class ImageUploadDialog extends Component<Props, State> {

  fileInputRef = React.createRef();

  static defaultProps = {
    defaultActiveTab: TAB_KEYS.LIBRARY,
    controlled: false,
  };

  initialState = {
    inputUrl: '',
    confirmedImage: null,
    isProcessing: false,
    forceClose: false,
    libraryPage: 1,
    imageData: {
      [TAB_KEYS.FILE]: {
        data: null,
        name: '',
        file: null,
      },
      [TAB_KEYS.URL]: {
        data: null,
        name: '',
      },
      [TAB_KEYS.LIBRARY]: {
        data: null,
        name: '',
        url: '',
      },
    },
    headerText: 'uploadOrSelectImage',
    isDialogShown: false,
    errorLoadImage: '',
    activeTab: this.props.defaultActiveTab,
  };

  state = { ...this.initialState };

  componentDidMount(): void {
    if (!this.props.imageLibrary || !this.props.imageLibrary.length) {
      this.onTabClick(TAB_KEYS.FILE);
    }
  }

  onTabClick = (key: string) => {
    this.setState({
      activeTab: key,
    });
  };

  toggleIsProcessing = () => {
    this.setState(state => ({
      isProcessing: !state.isProcessing,
    }));
  };

  toggleDialog = () => {
    this.setState(state => ({
      isDialogShown: !state.isDialogShown,
    }));
  };

  changeInputUrl = ({ target: { value } }: Object): void => {
    this.setState({ inputUrl: value });
    this.loadImageByUrlDebounced(value);
  };

  onSelectFileFromDevice = async e => {
    const file = e.target.files[0];
    if (file) {
      const { data, contentType, size } = await getFileData(file);
      if (this.validateSize(size)) {
        this.setImageData(TAB_KEYS.FILE, {
          data: { data, contentType },
          name: get(file, 'name'),
          file,
        });
      }
    }
  };

  onSelectFromLibrary = url => () => {
    const selectedImage = get(this.state.imageData, `${TAB_KEYS.LIBRARY}.url`);
    if (url === selectedImage) {
      this.setImageData(TAB_KEYS.LIBRARY, {
        data: null,
        name: '',
        url: '',
      });
    } else {
      convertImgToFileData(getPromoImageLibraryUrl(url), {
        callback: (imageData: ?TFileDataType) => {
          if (imageData) {
            this.setImageData(TAB_KEYS.LIBRARY, {
              data: omit(imageData, ['size']),
              url,
              name: getImageNameAndFormatFromUrl(url).name,
            });
          }
        },
        errorCallback: error => console.error(error),
      });
    }
  };

  setImageData = (key: string, value: ?ImageDataType) => {
    this.setState(state => ({
      imageData: { ...state.imageData, [key]: value },
    }));
  };

  validateSize = (size: number): boolean => {
    let result = true;
    if (this.props.maxSize && size > this.props.maxSize) {
      result = false;
      handleApiError({}, 'maxFileSize', { messageSubData: { count: 1 } });
    }
    return result;
  };

  loadImageByUrlDebounced = debounce(url => {
    if (validateUrl(url)) {
      this.toggleIsProcessing();
      this.setState({
        errorLoadImage: '',
      });
      convertImgToFileData(url, {
        callback: (imageData: ?TFileDataType) => {
          this.toggleIsProcessing();
          if (this.validateSize(imageData.size)) {
            this.setImageData(TAB_KEYS.URL, {
              data: omit(imageData, ['size']),
              name: getImageNameAndFormatFromUrl(url).name,
            });
          }
        },
        errorCallback: () => {
          this.toggleIsProcessing();
          this.setImageData(TAB_KEYS.URL, null);
          this.setState({
            errorLoadImage: 'errorLoadImage',
          });
        },
      });
    }
  }, 1000);

  renderPreview = (imageData: ?(string | TFileDataType)) =>
    !isEmpty(imageData) && (
      <Col span={24}>
        <ImageWrapper>
          <img
            src={
              typeof imageData === 'string'
                ? imageData
                : convertFileDataToBase64URL(imageData)
            }
            alt=""
          />
        </ImageWrapper>
      </Col>
    );

  handleConfirm = () => {
    const { onConfirm } = this.props;
    const { imageData, activeTab } = this.state;
    if (onConfirm) {
      this.setState({
        isDialogShown: false,
        confirmedImage: get(imageData, `${activeTab}.name`),
      });
      onConfirm(get(imageData, `${activeTab}.data`));
    }
  };

  openFileInput = () => {
    const { current: inputRef } = this.fileInputRef;
    if (inputRef) {
      inputRef.click();
    }
  };

  removeFile = () => {
    this.setImageData(TAB_KEYS.FILE, {
      data: null,
      name: '',
      file: null,
    });
  };

  changeImageLibraryPage = (page: number) => {
    this.setState({
      libraryPage: page,
    });
  };

  getConfirmedFileName = () => {
    let { confirmedImage } = this.state;
    if (confirmedImage && confirmedImage.length > 17) {
      confirmedImage =
        confirmedImage.slice(0, 7) + '...' + confirmedImage.slice(-7);
    }
    return confirmedImage;
  };

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
  };

  removeCorruptedImage = (imageUrl: string) => () =>
    commonActions.removeCorruptedImage(imageUrl);

  renderImageLibraryTab = () => {
    const { imageLibrary, t } = this.props;
    const { imageData, libraryPage } = this.state;
    const selectedImageFromLibrary = get(imageData, `${TAB_KEYS.LIBRARY}.url`);
    let result = null;

    if (imageLibrary && imageLibrary.length) {
      const imagesChunks = chunk(imageLibrary, IMAGE_LIBRARY_PAGE_SIZE);
      const imagesToRender = imagesChunks.length
        ? imagesChunks[libraryPage - 1]
        : [];
      result = (
        <Tabs.TabPane tab={t('selectFromLibrary')} key={TAB_KEYS.LIBRARY}>
          <Row>
            {imagesToRender.map((url, key) => (
              <LibraryImageWrapper
                key={url}
                selected={url === selectedImageFromLibrary}
                onClick={this.onSelectFromLibrary(url)}
              >
                {selectedImageFromLibrary &&
                  url !== selectedImageFromLibrary && <MutedOverlay />}
                <img
                  src={getPromoImageLibraryUrl(url)}
                  alt={`library ${key}`}
                  onError={this.removeCorruptedImage(url)}
                />
              </LibraryImageWrapper>
            ))}
          </Row>
          <Row>
            <PaginationWrapper>
              <Pagination
                size="small"
                showSizeChanger={false}
                total={imageLibrary.length}
                current={libraryPage}
                hideOnSinglePage
                onChange={this.changeImageLibraryPage}
                pageSize={IMAGE_LIBRARY_PAGE_SIZE}
              />
            </PaginationWrapper>
          </Row>
        </Tabs.TabPane>
      );
    }
    return result;
  };

  renderUploadFromDeviceTab = () => {
    const { imageData } = this.state;
    const { t } = this.props;
    const selectedImageFromDevice = get(imageData, `${TAB_KEYS.FILE}.data`);

    return (
      <Tabs.TabPane tab={t('uploadFromDevice')} key={TAB_KEYS.FILE}>
        <input
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={this.onSelectFileFromDevice}
          ref={this.fileInputRef}
        />
        <Row gutter={[16, 0]}>
          {selectedImageFromDevice ? (
            <>
              <Col xs={4}>
                {this.renderPreview(selectedImageFromDevice)}
                <RemoveButton onClick={this.removeFile}>
                  {t('remove')}
                </RemoveButton>
              </Col>
              <Col xs={20}>
                <div>
                  {t('successfullyUploadedFile', {
                    name: get(imageData, `${TAB_KEYS.FILE}.name`),
                  })}
                </div>
                <AnotherImageButton onClick={this.openFileInput}>
                  {t('uploadAnotherImage')}
                </AnotherImageButton>
              </Col>
            </>
          ) : (
            <Col xs={24}>
              <AntdInput.Group>
                <SelectFileInput
                  onClick={this.openFileInput}
                  readOnly="readonly"
                  placeholder={t('selectImageFromDevice')}
                />
                <SelectFileButton onClick={this.openFileInput}>
                  {t('select')}
                </SelectFileButton>
              </AntdInput.Group>
              <SizeInfo>{t('maxFileSize', { count: 1 })}</SizeInfo>
            </Col>
          )}
        </Row>
      </Tabs.TabPane>
    );
  };

  renderUploadFromUrlTab = () => {
    const { imageData, isProcessing, errorLoadImage, inputUrl } = this.state;
    const { t } = this.props;
    const selectedImageFromUrl = get(imageData, `${TAB_KEYS.URL}.data`);

    return (
      <Tabs.TabPane tab={t('uploadViaUrl')} key={TAB_KEYS.URL}>
        <Row justify="center" align="middle">
          {selectedImageFromUrl && (
            <Col xs={4}>{this.renderPreview(selectedImageFromUrl)}</Col>
          )}
          <Col span={selectedImageFromUrl ? 20 : 24}>
            <UrlInput
              value={inputUrl}
              placeholder={t('common:inputUrl')}
              onChange={this.changeInputUrl}
            />
          </Col>
        </Row>
        {errorLoadImage && (
          <Row>
            <Col xs={24}>
              <Typography.Text type="warning">
                {t(errorLoadImage, { ns: 'notifications' })}
              </Typography.Text>
            </Col>
          </Row>
        )}
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Tabs.TabPane>
    );
  };

  renderContent = () => {
    const { activeTab } = this.state;

    return (
      <div>
        <CustomTabs activeKey={activeTab} onTabClick={this.onTabClick}>
          {this.renderImageLibraryTab()}
          {this.renderUploadFromDeviceTab()}
          {this.renderUploadFromUrlTab()}
        </CustomTabs>
        <SeparationLine verticalMargin={30} height={1} />
      </div>
    );
  };

  render() {
    const { t, controlled } = this.props;
    const {
      headerText,
      isDialogShown,
      activeTab,
      imageData,
      confirmedImage,
    } = this.state;

    return (
      <Fragment>
        <Dialog
          isShown={isDialogShown}
          height={615}
          innerWrapperFlexAlign="flex-start"
          width="960px"
          maxHeight="690px"
          headerText={t(headerText, { ns: ['common', 'notifications'] })}
          confirmLabel={t('save')}
          displayCancelButton
          isConfirmDisabled={isEmpty(get(imageData, `${activeTab}.data`))}
          renderContent={this.renderContent}
          handleConfirmClick={this.handleConfirm}
          handleCancelClick={this.handleCancel}
        />
        {!controlled && (
          <Row justify="space-between" align="middle">
            {confirmedImage && (
              <FileName>{this.getConfirmedFileName()}</FileName>
            )}
            <Button onClick={this.toggleDialog}>
              {t(confirmedImage ? 'changeImage' : 'selectImage')}
            </Button>
          </Row>
        )}
      </Fragment>
    );
  }

}

export default withTranslation('common', { withRef: true })(ImageUploadDialog);
