// @flow strict

import links from '_common/routes/urls';
import Main from 'search/pages/Main';

export default [
  {
    path: links.search.main,
    component: Main,
  },
];
