// @flow

import getParcelsByUser from './getParcelsByUser';
import searchCollection from './searchCollection';
import searchReturns from './searchReturns';
import setFilteredCollection from './setFilteredCollection';
import getParcelsByEvent from './getParcelsByEvent';

export default {
  getParcelsByUser,
  searchCollection,
  searchReturns,
  setFilteredCollection,
  getParcelsByEvent,
};
