import styled from 'styled-components';
import { Button } from '_common/components';
import { Link } from 'react-router-dom';

export const Header = styled.div`
  padding: 32px 30px;
  background-color: #fff;
  border-bottom: 1px solid #d1d2d2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderLeftPartHolder = styled.div`
  flex-basis: 100%;
`;

export const HeaderCaption = styled.p`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 26px;
  line-height: 26px;
  color: #494949;
  margin-bottom: 0;
`;

export const TitleLink = styled(Link)`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 14px;
  font-weight: 500;
  color: #542785;
  cursor: pointer;
`;

export const HeaderInnerHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderUserCaptionHolder = styled(HeaderInnerHolder)`
  margin-bottom: 12px;
`;

export const CreateButton = styled(Button)`
  && {
    display: inline-block;
    background: #542785;
    padding: 17px 52px 15px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    height: auto;
    width: auto;
    font-family: ${props => props.theme.fonts.bold};
  }
`;

export const EditLink = styled.div`
  cursor: pointer;
  font-size: 17px;
  text-align: center;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
`;

export const StyledPanel = styled.div`
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  border: solid 1px #e6e7e8;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PermissionCaption = styled.p`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 22px;
  line-height: 22px;
  color: #494949;
  margin-bottom: 0;
`;

export const StyledPanelWrapper = styled.div`
  padding: 30px;
`;
