// @flow

import React, { createRef } from 'react';
import {
  SearchHeader,
  Text,
  ButtonsWrapper,
  CreateStoreButton,
  SubHeader,
  BulkActionButton,
  EmptyStoresWrapper,
  EmptyStoresTitle,
  BulkActionsItem,
  SpinnerWrapper,
} from '../../components/elements';
import { Hidden } from '_common/components/PlainStyles';
import { observer, inject } from 'mobx-react';
import { computeOpeningHours } from '_common/utils/utils';
import { compose } from 'recompose';
import type { RouterHistory, Location } from 'react-router';
import { ACLStore, CompaniesStore, DoddleStores } from 'stores';
import urls from '_common/routes/urls';
import doddleStoresActions from 'doddleStores/actions';
import { get, chunk } from 'lodash';
import ApproveDialog from './ApproveDialog';
import BulkApproveDialog from './BulkApproveDialog';
import BulkUploadDialog from './BulkUploadDialog';
import { Roles } from '_common/constants/acl';
import {
  EXPORT_STORES_LIMIT,
  STORE_PROPS_MAPPING,
} from '_common/constants/stores';
import StoresTable from './StoresTable';
import { Dropdown, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CsvDownloader from 'react-csv-downloader';
import { withTranslation } from 'react-i18next';

type Props = {
  location: Location,
  history: RouterHistory,
  doddleStores: DoddleStores,
  companiesStore: CompaniesStore,
  aclStore: ACLStore,
  t: TTranslateFunc,
};

type State = {
  proposedStoresToExport: {
    headers: Array<Object>,
    storesToExport: Array<Object>,
  },
  filteredStoresToExport: {
    headers: Array<Object>,
    storesToExport: Array<Object>,
  },
  selectedStoresToExport: {
    headers: Array<Object>,
    storesToExport: Array<Object>,
  },
  showSingleApproveDialog: boolean,
  showBulkApproveDialog: boolean,
  showBulkCreateDialog: boolean,
  showBulkUpdateDialog: boolean,
  approvingStore: Object | null,
  isApproving: boolean,
  isErrorWhileApproving: boolean,
  proposedStoresLoading: boolean,
  filteredStoresLoading: boolean,
  selectedStoresLoading: boolean,
  selectStoresActive: boolean,
};

class Main extends React.Component<Props, State> {

  filteredStoresExportCSVLink = createRef();
  proposedStoresExportCSVLink = createRef();
  selectedStoresExportCSVLink = createRef();

  state = {
    proposedStoresToExport: {},
    filteredStoresToExport: {},
    selectedStoresToExport: {},
    showSingleApproveDialog: false,
    showBulkApproveDialog: false,
    showBulkCreateDialog: false,
    showBulkUpdateDialog: false,
    approvingStore: null,
    isApproving: false,
    isErrorWhileApproving: false,
    proposedStoresLoading: false,
    filteredStoresLoading: false,
    selectedStoresLoading: false,
    selectStoresActive: false,
  };

  handleExportSelectedStores = async () => {
    const { selectedStoresLoading } = this.state;
    const { doddleStores } = this.props;
    if (doddleStores.isStoresLoading || selectedStoresLoading) {
      return;
    }
    this.setState({
      selectedStoresLoading: true,
    });
    const headers = STORE_PROPS_MAPPING.map((prop) => ({
      id: prop.path,
      displayName: prop.label,
    }));
    const propPaths = STORE_PROPS_MAPPING.map((prop) => prop.path);
    const storesToExport = doddleStores.getSelectedStoresToExport.map(
      (store) => {
        const row = {};
        for (const path of propPaths) {
          let value = get(store, path, '');
          // Format opening hours to be in display format e.g. 09:00
          if (value && path.includes('openingHours')) {
            value = chunk(value, 2)
              .map((chunk) => chunk.join(''))
              .join(':');
          }
          row[path] = value;
        }
        return row;
      }
    );
    this.setState(
      {
        selectedStoresToExport: { headers, storesToExport },
        selectedStoresLoading: false,
      },
      () => {
        if (
          this.selectedStoresExportCSVLink.current &&
          this.selectedStoresExportCSVLink.current.handleClick
        ) {
          this.selectedStoresExportCSVLink.current.handleClick();
        }
        this.toggleSelectStoresActive();
        doddleStores.setSelectedStoresToExport([], []);
      }
    );
  };

  handleExportFilteredStores = async () => {
    const { filteredStoresLoading } = this.state;
    const { doddleStores } = this.props;
    if (doddleStores.isStoresLoading || filteredStoresLoading) {
      return;
    }
    this.setState({
      filteredStoresLoading: true,
    });

    const headers = STORE_PROPS_MAPPING.map((prop) => ({
      id: prop.path,
      displayName: prop.label,
    }));
    const propPaths = STORE_PROPS_MAPPING.map((prop) => prop.path);
    const stores = await doddleStores.getAllStoresByFilters({
      ...doddleStores.filters,
      includeOpeningHours: true,
      sensitiveDataFlag: true,
    });
    const storesToExport = stores.map((store) => {
      const row = {};
      for (const path of propPaths) {
        let value = get(store, path, '');
        // Format opening hours to be in display format e.g. 09:00
        if (value && path.includes('openingHours')) {
          value = chunk(value, 2)
            .map((chunk) => chunk.join(''))
            .join(':');
        }
        row[path] = value;
      }
      return row;
    });
    this.setState(
      {
        filteredStoresToExport: { headers, storesToExport },
        filteredStoresLoading: false,
      },
      () => {
        if (
          this.filteredStoresExportCSVLink.current &&
          this.filteredStoresExportCSVLink.current.handleClick
        ) {
          this.filteredStoresExportCSVLink.current.handleClick();
        }
      }
    );
  };

  handleExportProposedStores = () => {
    if (this.state.proposedStoresLoading) return;

    this.setState({
      proposedStoresLoading: true,
    });
    const { doddleStores } = this.props;
    const getStorePropValue = (store: any, path: string) => {
      let value = '';
      if (path) {
        value = get(store, path, '');
        if (value) {
          if (path.includes('openingHours')) {
            const openingHours = computeOpeningHours(value);
            value =
              typeof openingHours === 'string'
                ? openingHours
                : `${openingHours.startTime} - ${openingHours.endTime}`;
          }
        } else {
          if (path.includes('openingHours')) {
            value = 'Closed';
          }
        }
      }
      return value;
    };
    const storeProps = [
      { label: 'approved?', path: 'approvedHeader' },
      { label: 'activationDate', path: 'activationDateHeader' },
      { label: 'companyId', path: 'companyId' },
      { label: 'storeName', path: 'storeName' },
      { label: 'storeId', path: 'storeId' },
      { label: 'externalStoreId', path: 'externalStoreId' },
      { label: 'line1', path: 'place.address.line1' },
      { label: 'line2', path: 'place.address.line2' },
      { label: 'town', path: 'place.address.town' },
      { label: 'area', path: 'place.address.area' },
      { label: 'country', path: 'place.address.country' },
      { label: 'postcode', path: 'place.address.postcode' },
    ];
    const headers = storeProps.map((prop) => ({
      id: prop.path,
      displayName: prop.label,
    }));
    const propPaths = storeProps.map((prop) => prop.path);

    doddleStores.getAllProposedStoresForCurrentCompany().then((stores) => {
      const storesToExport = stores.map((store) => {
        const row = {};
        for (const path of propPaths) {
          row[path] = getStorePropValue(store, path);
        }
        return row;
      });

      const result = { headers, storesToExport };
      this.setState(
        {
          proposedStoresToExport: result,
          proposedStoresLoading: false,
        },
        () => {
          if (
            this.proposedStoresExportCSVLink.current &&
            this.proposedStoresExportCSVLink.current.handleClick
          ) {
            this.proposedStoresExportCSVLink.current.handleClick();
          }
        }
      );
    });
  };

  handleSingleApproveDialogOpen = (store) => () => {
    this.setState({
      showSingleApproveDialog: true,
      approvingStore: store,
    });
  };

  handleBulkApproveDialogOpen = () => {
    this.setState({
      showBulkApproveDialog: true,
    });
  };

  handleBulkApproveDialogCancel = () => {
    this.setState({
      showBulkApproveDialog: false,
    });
  };

  handleBulkCreateDialogCancel = () => {
    this.setState({
      showBulkCreateDialog: false,
    });
  };

  handleBulkCreateDialogOpen = () => {
    this.setState({
      showBulkCreateDialog: true,
    });
  };

  handleBulkUpdateDialogCancel = () => {
    this.setState({
      showBulkUpdateDialog: false,
    });
  };

  handleBulkUpdateDialogOpen = () => {
    this.setState({
      showBulkUpdateDialog: true,
    });
  };

  handleSingleCancel = () => {
    this.setState({
      showSingleApproveDialog: false,
      approvingStore: null,
      isApproving: false,
      isErrorWhileApproving: false,
    });
  };

  toggleSelectStoresActive = () => {
    this.setState(({ selectStoresActive }) => ({
      selectStoresActive: !selectStoresActive,
    }));
  };

  handleSingleApprove = async () => {
    const { approvingStore } = this.state;
    this.setState({ isApproving: true });

    try {
      if (!approvingStore) throw new Error('Approving store is not found');
      const { storeId, companyId } = approvingStore;

      await doddleStoresActions.approveStore(storeId, companyId);

      this.setState({
        showSingleApproveDialog: false,
        approvingStore: null,
        isApproving: false,
      });
    } catch (e) {
      this.setState({
        isApproving: false,
        isErrorWhileApproving: true,
      });
    }
  };

  onStoreCreate = () => {
    this.props.history.push(urls.doddleStores.subroutes.createStore);
  };

  resetUpdateStoreProgress = () => {
    this.props.doddleStores.setUpdateStoresProgress(0);
  };

  renderUploadStoresButton = (canAccess) =>
    canAccess && (
      <BulkActionButton onClick={this.handleBulkCreateDialogOpen}>
        {this.props.t('uploadStores')}
      </BulkActionButton>
    );

  renderCreateStoreButton = (canAccess, title) =>
    canAccess && (
      <CreateStoreButton onClick={this.onStoreCreate}>
        {title}
      </CreateStoreButton>
    );

  getProposedLoadingSpinner = (display?: string) =>
    this.state.proposedStoresLoading && (
      <SpinnerWrapper display={display}>
        <Spin />
      </SpinnerWrapper>
    );

  getFilteredLoadingSpinner = (display?: string) =>
    this.state.filteredStoresLoading && (
      <SpinnerWrapper display={display}>
        <Spin />
      </SpinnerWrapper>
    );

  getSelectedLoadingSpinner = (display?: string) =>
    this.state.selectedStoresLoading && (
      <SpinnerWrapper display={display}>
        <Spin />
      </SpinnerWrapper>
    );

  getBulkActionsMenuItems = (
    proposedStoreExists,
    totalRecords,
    selectedRowKeys
  ) => {
    const { filteredStoresLoading, selectStoresActive, selectedStoresLoading } =
      this.state;
    const { t } = this.props;

    return [
      {
        key: 'uploadStores',
        label: (
          <BulkActionsItem onClick={this.handleBulkCreateDialogOpen}>
            {t('uploadStores')}
          </BulkActionsItem>
        ),
      },
      {
        key: 'editStores',
        label: (
          <BulkActionsItem onClick={this.handleBulkUpdateDialogOpen}>
            {t('editStores')}
          </BulkActionsItem>
        ),
      },
      {
        key: 'approveMultiple',
        disabled: !proposedStoreExists,
        label: (
          <BulkActionsItem
            onClick={this.handleBulkApproveDialogOpen}
            disabled={!proposedStoreExists}
          >
            {t('approveMultiple')}
          </BulkActionsItem>
        ),
      },
      {
        key: 'exportProposed',
        disabled: !proposedStoreExists,
        label: (
          <BulkActionsItem
            onClick={this.handleExportProposedStores}
            disabled={!proposedStoreExists}
          >
            {t('exportProposed')}
            {this.getProposedLoadingSpinner('block')}
          </BulkActionsItem>
        ),
      },
      {
        key: 'exportFiltered',
        disabled: filteredStoresLoading || totalRecords > EXPORT_STORES_LIMIT,
        label: (
          <BulkActionsItem
            disabled={
              filteredStoresLoading || totalRecords > EXPORT_STORES_LIMIT
            }
            onClick={this.handleExportFilteredStores}
          >
            {t('exportFiltered')}
            {this.getFilteredLoadingSpinner('block')}
          </BulkActionsItem>
        ),
      },
      {
        key: 'selectToExport',
        disabled:
          (selectStoresActive && selectedRowKeys.length < 1) ||
          selectedStoresLoading,
        label: (
          <BulkActionsItem
            disabled={
              (selectStoresActive && selectedRowKeys.length < 1) ||
              selectedStoresLoading
            }
            onClick={
              selectStoresActive
                ? this.handleExportSelectedStores
                : this.toggleSelectStoresActive
            }
          >
            {t(selectStoresActive ? 'exportSelected' : 'selectToExport')}
            {this.getSelectedLoadingSpinner('block')}
          </BulkActionsItem>
        ),
      },
      { type: 'divider' },
    ];
  };

  rowSelectionOnChange = (
    selectedRowKeys: Array<Object>,
    selectedRows: Array<Object>
  ) => {
    this.props.doddleStores.setSelectedStoresToExport(
      selectedRowKeys,
      selectedRows
    );
  };

  render() {
    const { doddleStores, aclStore, companiesStore, t } = this.props;
    const {
      approvingStore,
      isApproving,
      isErrorWhileApproving,
      showSingleApproveDialog,
      showBulkApproveDialog,
      showBulkCreateDialog,
      showBulkUpdateDialog,
      proposedStoresToExport,
      filteredStoresToExport,
      selectedStoresToExport,
      selectStoresActive,
    } = this.state;

    const proposedStoreExists = doddleStores.getCompanyHasProposedStoresField;
    const storeTotalRecords = doddleStores.getTotalRecords;
    const selectedRowKeys = doddleStores.getSelectedStoreKeys;

    const canAccessActions = aclStore.canAccessFeature({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
    });

    return (
      <div>
        <ApproveDialog
          store={approvingStore}
          dialogProps={{
            isShown: showSingleApproveDialog && !!approvingStore,
            isError: isErrorWhileApproving,
            isDisabled: isApproving,
            handleCancelClick: this.handleSingleCancel,
            handleConfirmClick: this.handleSingleApprove,
          }}
        />
        <BulkApproveDialog
          reloadStores={doddleStores.reloadStoresByFilters}
          resetUpdateStoresProgress={this.resetUpdateStoreProgress}
          approveProgress={doddleStores.getUpdateStoresProgressField}
          storesBulkApprove={doddleStores.storesBulkApprove}
          dialogProps={{
            isShown: showBulkApproveDialog,
            isError: isErrorWhileApproving,
            isDisabled: isApproving,
            handleCancelClick: this.handleBulkApproveDialogCancel,
          }}
        />
        <BulkUploadDialog
          isEditStores={false}
          doddleStores={doddleStores}
          userCompanies={companiesStore.getUserCompanies}
          resetUpdateStoresProgress={this.resetUpdateStoreProgress}
          dialogProps={{
            isShown: showBulkCreateDialog,
            isError: isErrorWhileApproving,
            isDisabled: isApproving,
            handleCancelClick: this.handleBulkCreateDialogCancel,
          }}
        />
        <BulkUploadDialog
          isEditStores
          doddleStores={doddleStores}
          userCompanies={companiesStore.getUserCompanies}
          resetUpdateStoresProgress={this.resetUpdateStoreProgress}
          dialogProps={{
            isShown: showBulkUpdateDialog,
            isError: isErrorWhileApproving,
            isDisabled: isApproving,
            handleCancelClick: this.handleBulkUpdateDialogCancel,
          }}
        />
        <SearchHeader>
          <SubHeader>
            <Text>{t('allStores')}</Text>
          </SubHeader>

          <ButtonsWrapper>
            {canAccessActions && (
              <Dropdown
                trigger={['click']}
                placement="bottom"
                menu={{
                  items: this.getBulkActionsMenuItems(
                    proposedStoreExists,
                    storeTotalRecords,
                    selectedRowKeys
                  ),
                  style: {
                    backgroundColor: '#f7f7f7',
                    padding: 0,
                    marginTop: '-3px',
                  },
                }}
              >
                <BulkActionButton>
                  {t('bulkActions')} &nbsp; <DownOutlined />
                  {this.getProposedLoadingSpinner()}
                </BulkActionButton>
              </Dropdown>
            )}
            {this.renderCreateStoreButton(canAccessActions, t('createStore'))}
            <Hidden>
              <CsvDownloader
                separator=";"
                columns={proposedStoresToExport.headers}
                datas={proposedStoresToExport.storesToExport}
                filename="proposedStores.csv"
                ref={this.proposedStoresExportCSVLink}
              />
            </Hidden>
            <Hidden>
              <CsvDownloader
                separator=";"
                columns={filteredStoresToExport.headers}
                datas={filteredStoresToExport.storesToExport}
                filename="filteredStores.csv"
                ref={this.filteredStoresExportCSVLink}
              />
            </Hidden>
            <Hidden>
              <CsvDownloader
                separator=";"
                columns={selectedStoresToExport.headers}
                datas={selectedStoresToExport.storesToExport}
                filename="selectedStores.csv"
                ref={this.selectedStoresExportCSVLink}
              />
            </Hidden>
          </ButtonsWrapper>
        </SearchHeader>
        <StoresTable
          rowSelection={
            selectStoresActive
              ? {
                  selectedRowKeys,
                  preserveSelectedRowKeys: true,
                  onChange: this.rowSelectionOnChange,
                }
              : null
          }
          emptyElement={
            doddleStores.isStoresEmpty ? (
              <EmptyStoresWrapper>
                <EmptyStoresTitle>{t('noStores')}</EmptyStoresTitle>
                <div>
                  {this.renderCreateStoreButton(
                    canAccessActions,
                    t('createFirst')
                  )}
                  {this.renderUploadStoresButton(canAccessActions)}
                </div>
              </EmptyStoresWrapper>
            ) : undefined
          }
          companyId={aclStore.getSelectedCompanyId}
          history={this.props.history}
          handleSingleApproveDialogOpen={this.handleSingleApproveDialogOpen}
        />
      </div>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores', 'aclStore', 'companiesStore'),
  observer
)(Main);
