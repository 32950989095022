// @flow strict

import refreshToken from './refreshToken';
import logout from './logout';
import canAccessResource from './canAccessResource';
import getLoginOrganisation from './getLoginOrganisation';
import canAccessFeature from './canAccessFeature';
import getUserRoles from './getUserRoles';
import getDefaultRedirectUrl from './getDefaultRedirectUrl';
import isDoddleAdminRole from './isDoddleAdminRole';
import showApiError from './showApiError';
import showSuccessMessage from './showSuccessMessage';
import showWarningMessage from './showWarningMessage';
import getUserSelectedCompany from './getUserSelectedCompany';
import getHealthChecks from './getHealthChecks';
import removeCorruptedImage from './removeCorruptedImage';

export default {
  showWarningMessage,
  showSuccessMessage,
  showApiError,
  getUserSelectedCompany,
  isDoddleAdminRole,
  getDefaultRedirectUrl,
  getUserRoles,
  refreshToken,
  logout,
  canAccessResource,
  getLoginOrganisation,
  canAccessFeature,
  getHealthChecks,
  removeCorruptedImage,
};
