import { RESOURCES } from '_common/constants/acl';
import {
  allowEmptyValidator,
  normalizeUrl,
  validateUrl,
} from '_common/utils/utils';
import stores from 'stores';

type OtherBlockConfig = {
  errors: Object,
  setError: Function,
  addFieldInEdit: (field: string) => () => void,
  removeFieldInEdit: (field: string) => () => void,
  onColorChange: (field: string) => (color: string) => void,
  onTCLinkChange: (value: string) => void,
};

export const otherBlock = ({
  errors,
  addFieldInEdit,
  removeFieldInEdit,
  setError,
  onTCLinkChange,
  onColorChange,
}: OtherBlockConfig): TInfoBlock => {
  const hasPermissions = stores.aclStore.isStrictAccessGranted(
    RESOURCES.PROMOTIONS
  );

  return {
    infoRows: [
      {
        name: 'T&C link*',
        path: 'singlePromotion.tcLink',
        aclAccess: hasPermissions,
        type: 'input',
        errorData: errors,
        errorMessage: 'invalidUrl',
        showError: setError,
        validator: allowEmptyValidator(validateUrl),
        skipOnBlurValidation: true,
        onBlur: onTCLinkChange,
        onEditStart: addFieldInEdit,
        onEditEnd: removeFieldInEdit,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateSinglePromotion, updatePromotion },
        }: TDetailsUpdate) => {
          updateSinglePromotion(updatePromotion, [
            {
              tcLink: normalizeUrl(currentValue) || null,
            },
          ]);
        },
      },
      {
        name: 'T&C link colour',
        path: 'singlePromotion.colourScheme.tcLinkColour.rgba',
        translateKey: 'tcLinkColour',
        aclAccess: hasPermissions,
        type: 'color',
        onColorChange: onColorChange('tcLinkColour'),
        onEditStart: addFieldInEdit,
        onEditEnd: removeFieldInEdit,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateSinglePromotion, updatePromotion },
        }: TDetailsUpdate) => {
          updateSinglePromotion(updatePromotion, [
            {
              colourScheme: {
                tcLinkColour: {
                  rgba: currentValue,
                },
              },
            },
          ]);
        },
      },
      {
        name: 'Offer text colour',
        path: 'singlePromotion.colourScheme.offerTextColour.rgba',
        translateKey: 'offerTextColour',
        onColorChange: onColorChange('offerTextColour'),
        aclAccess: hasPermissions,
        type: 'color',
        onEditStart: addFieldInEdit,
        onEditEnd: removeFieldInEdit,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateSinglePromotion, updatePromotion },
        }: TDetailsUpdate) => {
          updateSinglePromotion(updatePromotion, [
            {
              colourScheme: {
                offerTextColour: {
                  rgba: currentValue,
                },
              },
            },
          ]);
        },
      },
      {
        name: 'Background colour',
        path: 'singlePromotion.colourScheme.bgColour.rgba',
        translateKey: 'bgColour',
        onColorChange: onColorChange('bgColour'),
        aclAccess: hasPermissions,
        type: 'color',
        onEditStart: addFieldInEdit,
        onEditEnd: removeFieldInEdit,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateSinglePromotion, updatePromotion },
        }: TDetailsUpdate) => {
          updateSinglePromotion(updatePromotion, [
            {
              colourScheme: {
                bgColour: {
                  rgba: currentValue,
                },
              },
            },
          ]);
        },
      },
      {
        name: 'Border colour',
        path: 'singlePromotion.colourScheme.borderColour.rgba',
        translateKey: 'borderColour',
        onColorChange: onColorChange('borderColour'),
        aclAccess: hasPermissions,
        type: 'color',
        onEditStart: addFieldInEdit,
        onEditEnd: removeFieldInEdit,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateSinglePromotion, updatePromotion },
        }: TDetailsUpdate) => {
          updateSinglePromotion(updatePromotion, [
            {
              colourScheme: {
                borderColour: {
                  rgba: currentValue,
                },
              },
            },
          ]);
        },
      },
    ],
    translateNS: 'promotions',
    idPropName: 'singlePromotion.promotionId',
    header: '',
  };
};
