import React, { Component, Fragment } from 'react';
import { Col, Progress, Row } from 'antd';
import { Dialog, Input } from '_common/components';
import { validateEmail } from '_common/utils/utils';
import commonActions from '_common/actions';
import { isEmpty, get } from 'lodash';
import { convertRGBToHex } from '_common/utils/utils';

type Props = {
  dialogProps?: TDialogProps,
  sendTestEmail: (string, string) => Promise<any>,
  promotionId?: string,
  data?: Object,
  t: TTranslateFunc,
};

type State = {
  email: string,
  isProcessing: boolean,
};

class SendEmailDialog extends Component<Props, State> {

  static defaultProps = {
    dialogProps: {},
  };

  initialState = {
    email: '',
    isProcessing: false,
  };

  state = { ...this.initialState };

  formatColourScheme = colourScheme => {
    const resultScheme = { ...colourScheme };
    for (const [colourName, formats] of Object.entries(colourScheme)) {
      const rgba = get(formats, 'rgba');
      if (rgba && !get(formats, 'hex')) {
        resultScheme[colourName].hex = convertRGBToHex(rgba);
      }
    }
    return resultScheme;
  };

  handleConfirm = () => {
    const { email, isProcessing } = this.state;
    const { sendTestEmail, promotionId, data, t } = this.props;

    if (isProcessing) return;

    this.toggleIsProcessing();

    let testEmailParam = promotionId;
    if (!isEmpty(data)) {
      if (!isEmpty(data.colourScheme)) {
        data.colourScheme = this.formatColourScheme(data.colourScheme);
      }
      testEmailParam = data;
    }

    if (validateEmail(email) && sendTestEmail && !isEmpty(testEmailParam)) {
      // Send test email by data or by promoId
      sendTestEmail(testEmailParam, email)
        .then((result: boolean) => {
          if (result) {
            commonActions.showSuccessMessage(t('emailSent', { email }));
            this.handleCancel();
          } else {
            this.toggleIsProcessing();
          }
        })
        .catch(() => {
          this.toggleIsProcessing();
        });
    }
  };

  toggleIsProcessing = () => {
    this.setState(state => ({
      isProcessing: !state.isProcessing,
    }));
  };

  changeEmail = ({ target: { value } }: Object): void => {
    this.setState({ email: value });
  };

  renderContent = () => {
    const { isProcessing, email } = this.state;

    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <Input
              value={email}
              placeholder={this.props.t('enterEmail')}
              onChange={this.changeEmail}
            />
          </Col>
        </Row>
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Fragment>
    );
  };

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
    this.props.dialogProps.handleCancelClick();
  };

  render() {
    const { dialogProps, t } = this.props;
    const { email } = this.state;

    const isEmailValid = validateEmail(email);

    return (
      <Dialog
        {...dialogProps}
        minHeight={320}
        headerText={t('enterEmail')}
        confirmLabel={t('common:send')}
        displayCancelButton
        isConfirmDisabled={!isEmailValid}
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default SendEmailDialog;
