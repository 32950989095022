// @flow strict

import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import links from '../urls';
import PrivateRoute from '../PrivateRoute';
import NotFound from '_common/routes/NotFound/NotFound';
import RootRedirect from '_common/routes/RootRedirect';
import Main from '_common/routes/Main/Main';
import Login from 'login/pages/Login';
import ChangePassword from 'login/pages/ChangePassword';
import StoreQRCodeImage from '_common/components/StoreQRCodeImage';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import GlobalStyle from 'themes/globalStyle';

const App = () => {
  const history = createBrowserHistory();
  history.listen(location => {
    const { pathname, search } = location;
    ReactGA.pageview(window.location.origin + pathname + search);
  });

  return (
    <>
      <Router history={history}>
        <Switch>
          <RootRedirect exact path={links.root} />
          <Route exact path={links.login} component={Login} />
          <Route exact path={links.changePassword} component={ChangePassword} />
          <Route exact path={links.notFound} component={NotFound} />
          <Route
            exact
            path={links.storeQRCodeImage}
            component={StoreQRCodeImage}
          />
          <PrivateRoute component={Main} />
        </Switch>
      </Router>
      <GlobalStyle />
    </>
  );
};

export default App;
