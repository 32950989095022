// @flow

import React, { Component } from 'react';
import { TimePicker as TP } from 'antd';
import { TimePickerWrapper } from './elements';

type Props = {
  onChange?: (date: Object, dateString: string) => void,
  format?: string,
};

class TimePicker extends Component<Props> {

  render() {
    return (
      <TimePickerWrapper>
        <TP
          {...this.props}
          onChange={this.props.onChange}
          format={this.props.format || 'HH:mm'}
        />
      </TimePickerWrapper>
    );
  }

}

export default TimePicker;
