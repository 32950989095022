// @flow

import {
  observable,
  action,
  runInAction,
  computed,
  values,
  reaction,
} from 'mobx';

import usersService from 'users/services/usersService';
import stores from 'stores';
import {
  MESSAGE_TYPE,
  STORE_STAFF_SCOPE_TEMPLATE,
} from '_common/constants/appConfig';
import { isEmpty, get } from 'lodash';
import {
  getCompanyFromScope,
  handleApiError,
  mergeParams,
} from '_common/utils/utils';
import authService from '_common/services/authService';
import { PaginationConfig } from 'antd/es/pagination';
import {
  TABLE_TYPE_USERS_FIELD_MAPPING,
  TABLE_TYPES,
} from '_common/constants/users';

class UsersStore {

  constructor() {
    reaction(
      () => this.filters,
      () => {
        this.resetLocalFilters();
        this.loadUsersByFilters();
      }
    );

    reaction(
      () => this.localFilters,
      () => {
        if (!isEmpty(this.localFilters)) {
          this.filterUsersLocally();
        }
      }
    );
  }

  tableType: string = TABLE_TYPES.MAIN;

  @observable
  users: Array<Object> = [];

  @observable
  storeUsers: Array<Object> = [];

  @observable
  filters: TUsersFilterParams = {
    role: null,
    host: '',
  };

  @observable
  localFilters: TUsersLocalFilterParams = {
    username: '',
  };

  @observable
  paginationConfig: PaginationConfig = {
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    showSizeChanger: false,
    onChange: this.onPageChange.bind(this),
  };

  @observable
  roleLoaded: ?string = null;

  @observable
  singleUser: Object = {};

  @observable
  filteredUsers: ?Array<Object> = null;

  @observable
  isFiltersEmpty: boolean = true;

  @observable
  resetFiltersFlag: boolean = false;

  @observable
  resetLocalFiltersFlag: boolean = false;

  @observable
  isUsersLoading: boolean = false;

  @observable
  isUsersLoaded: boolean = false;

  @observable
  currentCompany: string = '';

  @computed
  get getUsersField(): Array<Object> {
    return values(this.users);
  }

  @computed
  get getFilteredUsersField(): ?Array<Object> {
    return this.filteredUsers;
  }

  @computed
  get getStoreUsersField(): ?Array<Object> {
    return values(this.storeUsers);
  }

  @computed
  get getFiltersField(): TUsersFilterParams {
    return this.filters;
  }

  @computed
  get getPaginationConfigField(): Object {
    return this.paginationConfig;
  }

  @action
  onPageChange = (page: number) => {
    this.paginationConfig.current = page;
  };

  setTableType = (tableType: string) => {
    this.tableType = tableType;
  };

  @action
  setFilteredCollection = (
    collection: Array<Object>,
    isFiltersEmpty: boolean
  ) => {
    this.filteredUsers = collection;
    this.isFiltersEmpty = isFiltersEmpty;
  };

  @action
  resetFilteredCollection = () => {
    this.filteredUsers = null;
    this.isFiltersEmpty = true;
    this.resetFiltersFlag = !this.resetFiltersFlag;
  };

  @action
  setCurrentCompany = (currentCompany: string) => {
    this.currentCompany = currentCompany;
  };

  @action
  synchroniseCompanies = (
    addedCompanies: Array<string>,
    removedCompanies: Array<string>
  ) => {
    const { companiesStore } = stores;
    const singleUser = this.singleUser;
    let userCompanies = companiesStore.getUserCompanies;
    if (addedCompanies.length) {
      this.singleUser.companies = [...singleUser.companies, ...addedCompanies];
      userCompanies.push(...addedCompanies);
    }
    if (removedCompanies.length) {
      this.singleUser.companies = [
        ...singleUser.companies.filter(
          companyId => !removedCompanies.includes(companyId)
        ),
      ];
      this.singleUser.scopes = [
        ...singleUser.scopes.filter(
          scope => !removedCompanies.includes(getCompanyFromScope(scope))
        ),
      ];
      userCompanies = userCompanies.filter(
        companyId => !removedCompanies.includes(companyId)
      );
    }
    companiesStore.setUserCompanies(userCompanies);
    companiesStore.setCachedUserCompaniesList(
      companiesStore.getCompaniesList(userCompanies)
    );
  };

  createUserForStores = async (
    companyId: string,
    storeIds: Array<string>
  ): Promise<void> => {
    runInAction(() => {
      this.isUsersLoaded = false;
    });
    try {
      if (companyId) {
        const responses = await usersService.createUserForStores(
          companyId,
          storeIds
        );
        const failedStoreIds = responses.reduce((accum, response) => {
          const tempAccum = [...accum];
          if (!response.status) {
            tempAccum.push(response.storeId);
          }
          return tempAccum;
        }, []);
        if (failedStoreIds.length) {
          stores.uiStore.showMessage('someUsersHaveNotBeenCreated', {
            duration: 7,
            action: this.createUserForStores.bind(
              this,
              companyId,
              failedStoreIds
            ),
            actionText: 'Try again',
            type: MESSAGE_TYPE.WARNING,
          });
        } else {
          stores.uiStore.showMessage('usersHaveBeenSuccessfullyCreated', {
            duration: 5,
            type: MESSAGE_TYPE.SUCCESS,
          });
        }
      }
    } catch (e) {
      handleApiError(e, 'Could not create store users for company', {
        messageSubData: { companyId },
      });
    }
  };

  @action
  createUser = async (
    userData: TUserData,
    organisationId: string,
    adminAdditionalData: TAdminAdditions
  ) => {
    try {
      const { orgID, storestaff } = adminAdditionalData;

      const organisation = orgID ? orgID : organisationId;

      //set storestaff_{storeID}
      const storeScope =
        userData.scopes && userData.scopes.find(scope => scope === storestaff);

      if (!storeScope && storestaff) {
        if (userData.scopes) {
          userData.scopes.push(storestaff);
        } else {
          userData.scopes = [storestaff];
        }
      }

      const createdUser = await usersService.createUser(userData, organisation);

      stores.uiStore.showMessage('User has been successfully created', {
        duration: 5,
        link: !isEmpty(createdUser)
          ? `/userDetails/${createdUser.login}/${createdUser.organisationId}`
          : '',
        linkText: 'View details',
        type: MESSAGE_TYPE.SUCCESS,
      });

      this.addCreatedUserToTheList(createdUser, organisation);
    } catch (e) {
      handleApiError(e, 'Could not create user');
      return Promise.reject(e.response);
    }
  };

  @action
  addCreatedUserToTheList = async (createdUser: Object, companyId: string) => {
    if (
      companyId === this.currentCompany &&
      this.filters.role &&
      createdUser.roles &&
      createdUser.roles.length &&
      createdUser.roles.find(role => role === this.filters.role)
    ) {
      const extendedUser = {
        ...createdUser,
        status: 'active',
        organisationId: companyId,
      };

      runInAction(() => {
        this.users.push(extendedUser);
      });
    }
  };

  @action
  filterUsersLocally = () => {
    const { username } = this.localFilters;
    const usersField = TABLE_TYPE_USERS_FIELD_MAPPING[this.tableType];
    if (!username) {
      this.filteredUsers = null;
    } else if (username.length > 1 && get(this, `${usersField}.length`)) {
      this.filteredUsers = get(this, usersField, []).filter(user =>
        user.login.toLowerCase().includes(username.toLowerCase())
      );
    }
  };

  @action
  loadUsersByFilters = async () => {
    if (this.isUsersLoading) return;

    const { host, role } = this.filters;
    if (!host || !role) return;
    this.setLoading(true);
    this.users = [];
    this.onPageChange(1);
    try {
      const users = await usersService.getUsersByRole(role, host);
      runInAction(() => {
        this.users = users;
        this.isUsersLoaded = true;
        this.roleLoaded = role;
      });
    } catch (e) {
      handleApiError(e, 'Could not load users');
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getUsersByOrgId = async (orgId: string, setLoading?: () => void) => {
    try {
      this.setLoading(true);
      this.users = [];
      const users = await usersService.getUsersByOrgId(
        orgId,
        stores.aclStore.getHigherRoles
      );
      runInAction(() => {
        this.users = users;
        this.isUsersLoaded = true;
        if (setLoading) {
          setLoading();
        }
      });
    } catch (e) {
      handleApiError(e, 'Could not get users list');
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getUserByLogin = async (
    orgId: string,
    login: string,
    callback: ?Function,
    saveSingleUser: boolean = true
  ): Promise<?TUser> => {
    let singleUser = null;
    try {
      singleUser = await usersService.getUserByLogin(orgId, login);
      if (saveSingleUser && singleUser) {
        runInAction(() => {
          // $FlowExpectedError
          this.singleUser = singleUser;
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (callback && typeof callback === 'function') {
        callback();
      }
    }
    return singleUser;
  };

  @action
  resetUser = () => {
    this.singleUser = {};
  };

  @action
  updateUser = async (data: TUserUpdateData, orgId: string, login: string) => {
    try {
      const updatedUser = await usersService.updateUser(data, orgId, login);
      stores.uiStore.showMessage('Success user updated', {
        duration: 5,
        type: MESSAGE_TYPE.SUCCESS,
      });
      if (updatedUser) {
        runInAction(() => {
          this.singleUser = updatedUser;
        });
      }
    } catch (e) {
      handleApiError(e, 'Could not update user', {
        showNativeErrors: true,
      });
    }
  };

  @action
  changePasswordForOnboardingUser = async (
    onboardingToken: TOnboardingToken | Object,
    password: string
  ): Promise<boolean> => {
    let result = false;
    try {
      const {
        access_token: accessToken,
      } = await authService.applicationSignIn();
      await usersService.changePasswordForOnboardingUser(
        onboardingToken,
        password,
        accessToken
      );
      result = true;
    } catch (e) {
      handleApiError(e, 'Could not change password');
    }

    try {
      await stores.authStore.authorizeStaff(
        onboardingToken.login,
        password,
        onboardingToken.organisationId
      );
    } catch (e) {
      handleApiError(e, 'Could not authorise user');
    }
    return result;
  };

  @action
  resetPassword = async (
    orgId: string,
    login: string,
    password: string,
    options: { temporaryPassword?: boolean, signIn?: boolean } = {}
  ): Promise<boolean> => {
    const { temporaryPassword = false, signIn = false } = options;
    let result = false;
    try {
      const {
        authStore: { setUser, setTokens, tempTokens },
      } = stores;
      await usersService.updatePassword(
        orgId,
        login,
        {
          password,
        },
        temporaryPassword,
        signIn ? tempTokens.accessToken : undefined
      );
      result = true;
      if (signIn) {
        setUser({
          ...stores.authStore.tempUser,
          temporaryPassword,
        });
        setTokens(tempTokens);
      }
    } catch (e) {
      handleApiError(e, 'Could not reset password', { showNativeErrors: true });
    }
    return result;
  };

  @action
  suspendUser = async (orgId: string, login: string) => {
    try {
      await usersService.suspendUser(orgId, login);

      runInAction(() => {
        this.singleUser.status = 'suspended';
      });
    } catch (e) {
      handleApiError(e, 'Could not suspend user');
    }
  };

  @action
  loadStoreUsers = async (storeId: string) => {
    const scopeName = `${STORE_STAFF_SCOPE_TEMPLATE}${storeId}`;
    this.setLoading(true);
    this.storeUsers = [];
    this.isUsersLoaded = false;
    const users = await this.getUsersByScope(scopeName);
    runInAction(() => {
      this.storeUsers = users;
      this.setLoading(false);
    });
  };

  @action
  getUsersByScope = async (scopeName: string) => {
    let users = [];
    try {
      users = await usersService.getUsersByScope(scopeName);
    } catch (e) {
      handleApiError(e, 'Could not get users by scope');
    }
    return users;
  };

  @action
  setLoading = (loading: boolean) => {
    runInAction(() => {
      this.isUsersLoading = loading;
    });
  };

  @action
  setFilters = (filters: TUsersFilterParams) => {
    this.filters = mergeParams(this.filters, filters);
  };

  @action
  setLocalFilters = (localFilters: TUsersLocalFilterParams) => {
    this.localFilters = mergeParams(this.localFilters, localFilters);
  };

  @action
  setResetFiltersFlag = (value: boolean): void => {
    this.resetFiltersFlag = value;
  };

  @action
  setResetLocalFiltersFlag = (value: boolean): void => {
    this.resetLocalFiltersFlag = value;
  };

  @action
  resetLocalFilters = () => {
    this.localFilters = {};
    this.filteredUsers = null;
    this.resetLocalFiltersFlag = !this.resetLocalFiltersFlag;
  };

  @action
  resetFilters = (additionalDefaultFilters?: TUsersFilterParams = {}) => {
    this.filters = { host: this.currentCompany, ...additionalDefaultFilters };
    this.resetFiltersFlag = !this.resetFiltersFlag;
  };

}

export default UsersStore;
