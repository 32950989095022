import styled, { css } from 'styled-components';
import { Input, Button } from '_common/components';
import { Tabs, Col } from 'antd';

export const CustomTabs = styled(Tabs)`
  > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-between;
  }
`;

export const ImageWrapper = styled.div`
  text-align: center;
  img {
    max-height: 89px;
    max-width: 100%;
  }
`;

export const FileName = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  color: #8c8c8c;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LibraryImageWrapper = styled(Col)`
  cursor: pointer;
  img {
    height: 89px;
    ${props =>
      props.selected &&
      css`
        border: 4px solid #5ad03b;
      `};
  }
`;

export const MutedOverlay = styled.div`
  position: absolute;
  background-color: rgba(230, 231, 232, 0.7);
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export const SelectFileInput = styled(Input)`
  width: 80% !important;
  border: solid 1px #e6e7e8 !important;
`;

export const UrlInput = styled(Input)`
  border: solid 1px #e6e7e8 !important;
`;

export const SelectFileButton = styled(Button)`
  width: 20%;
  background-color: ${props => props.theme.colors.secondary} !important;
  height: 40px;
`;

export const AnotherImageButton = styled(Button)`
  margin-top: 10px;
  background-color: ${props => props.theme.colors.secondary} !important;
  height: 40px;
`;

export const RemoveButton = styled.div`
  cursor: pointer;
  text-align: center;
`;

export const SizeInfo = styled.div`
  color: ${props => props.theme.colors.shade2};
  font-size: 12px;
`;
