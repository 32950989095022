import { get } from 'lodash';
import stores from 'stores';
import { RESOURCES } from '_common/constants/acl';
import {
  minLengthValidator,
  maxLengthValidator,
  validateDateRange,
  allowEmptyValidator,
  formatPromotionDates,
} from '_common/utils/utils';
import { PROMOTION_LENGTH_RESTRICTIONS } from '_common/constants/promotions';
import moment from 'moment';

export const generalBlock = (
  errors: Object,
  setError: Function,
  singlePromotion: TPromotion,
  toggleEnabled: Function
): TInfoBlock => {
  const hasPermissions = stores.aclStore.isStrictAccessGranted(
    RESOURCES.PROMOTIONS
  );

  const {
    TITLE: { min: titleMinLength, max: titleMaxLength },
    DESCRIPTION: { max: descriptionMaxLength },
  } = PROMOTION_LENGTH_RESTRICTIONS;

  return {
    infoRows: [
      {
        name: 'Title*',
        path: 'singlePromotion.title',
        aclAccess: hasPermissions,
        type: 'input',
        errorData: errors,
        errorMessage: 'titleLengthRule',
        translateReplace: { min: titleMinLength, max: titleMaxLength },
        showError: setError,
        validator: minLengthValidator(
          titleMinLength,
          maxLengthValidator(titleMaxLength)
        ),
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updatePromotion, updateSinglePromotion },
        }: TDetailsUpdate) => {
          updateSinglePromotion(updatePromotion, [
            {
              title: currentValue,
            },
          ]);
        },
      },
      {
        name: 'Description*',
        labelExtra: 'forInternalUse',
        path: 'singlePromotion.description',
        aclAccess: hasPermissions,
        type: 'textarea',
        errorData: errors,
        errorMessage: 'descriptionLengthRule',
        translateReplace: {
          max: descriptionMaxLength,
        },
        showError: setError,
        validator: allowEmptyValidator(
          maxLengthValidator(descriptionMaxLength)
        ),
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateSinglePromotion, updatePromotion },
        }: TDetailsUpdate) => {
          updateSinglePromotion(updatePromotion, [
            {
              description: currentValue || null,
            },
          ]);
        },
      },
      {
        name: 'Date range active*',
        translateKey: 'dateRangeActive',
        path: 'singlePromotion.startDate',
        aclAccess: hasPermissions,
        type: 'datePickerRange',
        secondPath: 'singlePromotion.endDate',
        customView: () => {
          let result = 'N/A';
          try {
            const startDate = moment(get(singlePromotion, 'startDate'));
            const endDate = moment(get(singlePromotion, 'endDate'));
            if (startDate.isValid() && endDate.isValid()) {
              result = `${startDate.format('YYYY-MM-DD')} - ${endDate.format(
                'YYYY-MM-DD'
              )}`;
            }
          } catch (e) {
            console.error('invalid date');
          }
          return result;
        },
        errorData: errors,
        errorMessage: 'inputDateRange',
        showError: setError,
        validator: validateDateRange,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateSinglePromotion, updatePromotion },
        }: TDetailsUpdate) => {
          const { startDate, endDate } = formatPromotionDates([
            moment(currentValue.from),
            moment(currentValue.to),
          ]);
          updateSinglePromotion(updatePromotion, [
            {
              startDate,
              endDate,
            },
          ]);
        },
      },
      {
        name: 'Promotion is',
        translateKey: 'promotionIs',
        path: 'singlePromotion.enabled',
        aclAccess: hasPermissions,
        type: 'switch',
        localizeValue: true,
        compute: (value: any) => {
          let result = 'disabled';
          if (value) {
            result = 'enabled';
          }
          return result;
        },
        customUpdate: toggleEnabled,
      },
    ],
    translateNS: 'promotions',
    idPropName: 'singlePromotion.promotionId',
    header: '',
  };
};
