// @flow

import React from 'react';
import { MenuWrapper } from './elements';
import { RESOURCES } from '_common/constants/acl';
import stores, { CompaniesStore } from 'stores';
import links from '_common/routes/urls';
import _map from 'lodash/map';
import doddleStoresActions from 'doddleStores/actions';
import MenuItem from './MenuItem';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

type Props = {
  companiesStore: CompaniesStore,
  t: Function,
};

class Menu extends React.Component<Props> {

  static Item: Function;

  getMenuLinks = (promotionsEnabled: boolean): Object => {
    const result = {
      Dashboard: {
        link: links.dashboard,
        acl: [RESOURCES.DASHBOARD],
      },
      Stores: {
        link: links.doddleStores.main,
        acl: [RESOURCES.STORES.MAIN],
      },
      Users: {
        link: links.user.userList,
        acl: [RESOURCES.USERS.read],
      },
      Search: {
        link: links.search.main,
        acl: [RESOURCES.SEARCH],
      },
    };

    if (promotionsEnabled) {
      // $FlowFixMe
      result.Promotions = {
        link: links.promotions.main,
        acl: [RESOURCES.PROMOTIONS],
      };
    }

    return result;
  };

  render() {
    const promotionsEnabled = this.props.companiesStore.promotionsEnabled;
    const isStoreRoleAndHasPromotionsAccess =
      stores.aclStore.storeRolePromotionsAccess;
    const showPromotionsMenuItem =
      isStoreRoleAndHasPromotionsAccess || promotionsEnabled;

    return (
      <MenuWrapper>
        {_map(this.getMenuLinks(showPromotionsMenuItem), (v, k) => {
          const ItemJSX = (
            <MenuItem to={v.link} key={k}>
              {this.props.t(k.toLowerCase())}
            </MenuItem>
          );

          if (
            v.acl.length &&
            v.acl.every(acl => {
              return !!doddleStoresActions.getCurrentRole()
                ? stores.aclStore.isStrictAccessGranted(acl)
                : stores.aclStore.canAccessResource(acl);
            })
          ) {
            return ItemJSX;
          } else if (!v.acl.length) {
            return ItemJSX;
          }

          return null;
        })}
      </MenuWrapper>
    );
  }

}

Menu.Item = MenuItem;

export default compose(
  inject('companiesStore'),
  withTranslation('menu'),
  observer
)(Menu);
