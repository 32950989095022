import styled from 'styled-components';

export const SearchHeader = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #e6e7e8;
  padding: 40px 30px;
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 26px;
  color: #494949;
`;

export const TabsWrapper = styled.div`
  .ant-tabs-tab {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-tabs-tab-active {
    div {
      color: #494949;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #542785;
  }

  .ant-tabs-bar {
    margin-bottom: 30px;
  }
`;

export const TabTitle = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #b0b0b0;
`;

export const ParcelHeader = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6b6e;
`;

export const Flex = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Parcel = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6b6e;
`;

export const CustomerName = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #542785;
  margin-left: 16px;
  margin-top: 7px;
`;

export const BackLink = styled.a`
  color: #542785;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 14px;
  transition: 0.3s opacity cubic-bezier(0.645, 0.045, 0.355, 1);
  font-family: ${props => props.theme.fonts.basic};
  font-weight: 500;

  &:hover {
    color: #542785;
    opacity: 0.7;
  }
`;
