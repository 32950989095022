import React, { Fragment, createRef } from 'react';
import { GridWithFilters } from '_common/components';
import {
  ActionButton,
  EditLink,
  SearchResultsWrapper,
  StoreNameLink,
} from '../../components/elements';
import { Row } from 'antd';
import { DoddleStores } from 'stores';
import urls from '_common/routes/urls';
import type { RouterHistory } from 'react-router';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import StoreFilters from '../../components/Filters';
import stores from 'stores';
import { Roles } from '_common/constants/acl';
import { ActionLink } from '_common/components/Table/elements';
import { Button } from '_common/components';
import QRCodeDialog from 'doddleStores/doddleStoresList/components/QRCodeDialog';
import { withTranslation } from 'react-i18next';
import {
  getStoreLastEvent,
  localizeStoreStatus,
  toCamelCase,
  getSubCountriesOptions,
} from '_common/utils/utils';
import { get } from 'lodash';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import {
  LOCATION_TYPE_OPTIONS,
  OPEN_CLOSE_SETTERS,
  STATUSES,
  STORE_EVENT_TYPES,
} from '_common/constants/stores';
import OpeningDateSetter from 'doddleStores/doddleStoresList/components/OpeningDateSetter';

type Props = {
  doddleStores: DoddleStores,
  handleSingleApproveDialogOpen: Function,
  history: RouterHistory,
  companyId: string,
  emptyElement: any,
  rowSelection: Object,
  t: TTranslateFunc,
};

const COMPLEX_KEYS_SORT = {
  countrySubdivision: 'place.countrySubdivision',
};

class StoresTable extends React.Component<Props> {

  qrCodeDialogRef: ?Object = createRef();

  setOpeningDateForStore =
    (storeId?: string, companyId?: string) => (date: string) => {
      return this.props.doddleStores.setOpeningOrClosingDate({
        storeId,
        setterMode: OPEN_CLOSE_SETTERS.SET_OPENING_DATE,
        companyId,
        date: moment(date).format('YYYY-MM-DD'),
      });
    };

  renderColumns = () => {
    const { t } = this.props;
    const canApproveStores = stores.aclStore.canAccessFeature({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
    });
    const canSetOpeningDate = stores.aclStore.canAccessFeature({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
    });
    const columns = [
      {
        title: t('companyStoreId'),
        dataIndex: 'companyStoreId',
        key: 'companyStoreId',
        fixed: 'left',
        width: 200,
        sorter: false,
      },
      {
        title: t('storeName'),
        key: 'storeName',
        render: (store: TStore) => {
          const link = this.getActionRedirectLink(store);
          const { storeName } = store;
          return (
            storeName && (
              <StoreNameLink to={link}>{store.storeName}</StoreNameLink>
            )
          );
        },
        sorter: true,
      },
      {
        title: t('host'),
        dataIndex: 'companyId',
        key: 'companyId',
        sorter: false,
        render: (companyId: string) => {
          const company = stores.companiesStore.getCompanyFromCache(companyId);
          return company ? company.companyName : companyId;
        },
      },
      {
        title: t('countrySubdivision'),
        dataIndex: ['place', 'countrySubdivision'],
        key: ['place', 'countrySubdivision'],
        render: (countrySubdivisionCode: string, store: TStore) => {
          let result = countrySubdivisionCode;
          const locationType = get(store, 'locationType');
          const country = get(store, 'place.address.country');
          if (country) {
            const allSubdivisions = getSubCountriesOptions(
              this.props.doddleStores,
              locationType,
              country
            );
            if (allSubdivisions) {
              const countrySubdivisionOption = allSubdivisions.find(
                (option) => option.value === countrySubdivisionCode
              );
              result = countrySubdivisionOption
                ? countrySubdivisionOption.label
                : result;
            }
          }
          return result;
        },
        sorter: false,
      },
      {
        title: t('storeType'),
        dataIndex: 'locationType',
        key: 'locationType',
        sorter: false,
        render: (locationType: string) => {
          const option = LOCATION_TYPE_OPTIONS.find(
            (option) => option.value === locationType
          );
          return t(toCamelCase(option ? option.label : locationType));
        },
      },
      {
        title: t('status'),
        dataIndex: 'status',
        key: 'status',
        sorter: false,
        render: (status: string, store: TStore) => {
          const { OPENING_DATE, CLOSING_DATE } = STORE_EVENT_TYPES;
          const openingDate = moment(
            get(getStoreLastEvent(store, OPENING_DATE), 'openingDate'),
            'YYYY-MM-DD'
          );
          const closingDate = moment(
            get(getStoreLastEvent(store, CLOSING_DATE), 'closingDate'),
            'YYYY-MM-DD'
          );
          let result = localizeStoreStatus(status, this.props.t, {
            openingDate,
            closingDate,
          });
          if (status === STATUSES.PROPOSED && canApproveStores) {
            result = (
              <ActionButton
                onClick={this.props.handleSingleApproveDialogOpen(store)}
              >
                {result}
              </ActionButton>
            );
          } else if (status === STATUSES.PLANNED && canSetOpeningDate) {
            result = (
              <OpeningDateSetter
                t={this.props.t}
                canSetDate={canSetOpeningDate}
                setOpeningDate={this.setOpeningDateForStore(
                  store.storeId,
                  store.companyId
                )}
              />
            );
          }
          return result;
        },
      },
    ];
    columns.push({
      title: '',
      key: 'actions',
      render: (store: TStore) => (
        <Row type="flex" justify="space-between">
          <Button
            // $FlowExpectedError
            onClick={() => this.qrCodeDialogRef?.current?.open(store)}
          >
            {t('viewQr')}
          </Button>
          <ActionLink to={this.getActionRedirectLink(store)}>
            <EditLink>
              <EditOutlined />
            </EditLink>
          </ActionLink>
        </Row>
      ),
    });
    return columns;
  };

  onCell = (row) => {
    return {
      onClick: () => {
        this.props.history.push(`${urls.doddleStores.rootPath}/${row.storeId}`);
      },
    };
  };

  getActionRedirectLink = (row) => {
    const { storeId = '' } = row;
    return `${urls.doddleStores.rootPath}/${storeId}`;
  };

  onTableChange = (
    pagination: Object,
    filters: Object,
    sorter: Object,
    { action }
  ): void => {
    const { doddleStores } = this.props;
    if (action === 'sort') {
      const { columnKey, order } = sorter;
      if (columnKey && typeof columnKey === 'string') {
        const sortBy = COMPLEX_KEYS_SORT[columnKey] || columnKey;
        doddleStores.setSorting({
          sortBy: order ? sortBy : null,
          sortDescending: order ? order === 'descend' : null,
        });
      }
    }
  };

  render() {
    const { doddleStores, emptyElement, rowSelection, t } = this.props;

    const { getStoresField, getPaginationConfigField, isStoresLoading } =
      doddleStores;

    return (
      <Fragment>
        <QRCodeDialog t={t} ref={this.qrCodeDialogRef} />
        <GridWithFilters
          rowSelection={rowSelection}
          onTableChange={this.onTableChange}
          customActionColumn
          emptyElement={emptyElement}
          loading={isStoresLoading}
          SearchResultsWrapperComponent={SearchResultsWrapper}
          uniqueDataId={'storeId'}
          CustomFilterComponent={<StoreFilters />}
          columns={this.renderColumns()}
          gridData={getStoresField}
          pagination={getPaginationConfigField}
          rowKey={'storeId'}
          onCell={this.onCell}
        />
      </Fragment>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  observer
)(StoresTable);
