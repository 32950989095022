import React, { useState } from 'react';
import { Dialog, Loader } from '_common/components';
import { Collapse, Row } from 'antd';
import { get } from 'lodash';
import { HeaderText } from '_common/components/Dialog/elements';
import {
  LoaderWrapper,
  ConnectivityResultsWrapper,
  ResultText,
  ConnectivityHeader,
  CopyResultsButtons,
  SubHeaderTest,
} from './elements';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Icon } from '_common/components';
import { tickIcon, crossIcon } from 'assets';
import { useHcFetch } from '_common/hooks';
import { checkIsRequestFailed, copyPublicLink } from '_common/utils/utils';

const { Panel } = Collapse;

type Props = TDialogProps & {
  t: TTranslateFunc,
  store: TStore,
};

function ConnectivityResultsDialog({
  isShown,
  t,
  store,
  handleCancelClick,
  ...dialogProps
}: Props) {
  const [isCopied, setIsCopied] = useState(false);
  const [results, isLoading] = useHcFetch(isShown, store.storeId);

  const failedRequestsAmount = results.filter(checkIsRequestFailed).length;

  const handleCancel = () => handleCancelClick();

  const showCopied = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const handleClipboardCopy = async () => {
    if (!failedRequestsAmount) {
      await copyPublicLink(
        t('testSucceededClipboard', {
          companyId: store.companyId,
          companyStoreId: store.storeName,
        })
      );
      return showCopied();
    }
    const errorMessages = results
      .reduce(
        (acc, cur, index) => {
          if (!checkIsRequestFailed(cur)) {
            return acc;
          }
          const errorCaption = `${t('common:error')} # ${index}.`;
          const storeName = `${t('storeName')}: ${store.storeName}`;
          const message = `${t('message')}: ${cur.message}`;
          const code = `${t('shortCode')}: ${get(
            cur,
            'response.data.errors[0].code',
            t('common:noDetails')
          )}`;
          const extraDetails = `${t('reason')}: ${get(
            cur,
            'response.data.errors[0].message',
            t('common:noDetails')
          )}`;
          acc = [
            ...acc,
            `[${errorCaption}${[storeName, message, code, extraDetails].join(
              ', '
            )}]`,
          ];
          return acc;
        },
        ['\n']
      )
      .join('\n');
    await copyPublicLink(
      t('testFailedClipboard', {
        companyId: store.companyId,
        companyStoreId: store.storeName,
        errorMessages,
      })
    );
    return showCopied();
  };

  const renderIcon = ({ isActive }) =>
    isActive ? <UpOutlined /> : <DownOutlined />;
  const renderContent = () =>
    isLoading ? (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    ) : (
      <>
        <Collapse expandIconPosition="right" expandIcon={renderIcon}>
          {results.map(result => {
            const isFailedRequest = checkIsRequestFailed(result);
            const header = (
              <Row>
                <Icon
                  image={isFailedRequest ? crossIcon : tickIcon}
                  radius="50%"
                  width={32}
                  height={32}
                />
                <ResultText>{result.displayName}</ResultText>
              </Row>
            );
            const details = isFailedRequest ? (
              <>
                <div>{result.message}</div>
                <div>{get(result, 'response.data.errors[0].code')}</div>
                <div>{get(result, 'response.data.errors[0].message')}</div>
              </>
            ) : (
              t('testSucceeded')
            );

            return (
              <Panel header={header} key={result.displayName}>
                <div>{details}</div>
              </Panel>
            );
          })}
        </Collapse>
        <CopyResultsButtons greyed={isCopied} onClick={handleClipboardCopy}>
          {t(isCopied ? 'common:resultsCopied' : 'common:copyResults')}
        </CopyResultsButtons>
      </>
    );

  const renderHeader = () => (
    <ConnectivityHeader>
      {isLoading ? (
        ''
      ) : (
        <>
          <HeaderText fontSize={28} color="#000">
            {t('testResultsHeader', {
              confirmations: results.length - failedRequestsAmount,
              failures: failedRequestsAmount,
            })}
          </HeaderText>
          <SubHeaderTest>{t('testResultsSubHeader')}</SubHeaderTest>
        </>
      )}
    </ConnectivityHeader>
  );

  return (
    <ConnectivityResultsWrapper>
      <Dialog
        {...dialogProps}
        customHeader={renderHeader()}
        isShown={isShown}
        minHeight={420}
        renderContent={renderContent}
        handleCancelClick={handleCancel}
        withCloseIcon
      />
    </ConnectivityResultsWrapper>
  );
}

export default ConnectivityResultsDialog;
