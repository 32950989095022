// @flow

import React from 'react';
import { Wrapper, Title, SubTitle } from './elements';

const Error = ({
  message,
  description,
}: {
  message: string,
  description?: string,
}) => (
  <Wrapper>
    <Title>{message}</Title>
    <SubTitle>{description}</SubTitle>
  </Wrapper>
);

export default Error;
