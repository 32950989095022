// @flow

import {
  action,
  computed,
  observable,
  reaction,
  runInAction,
  toJS,
  values,
} from 'mobx';
import storage, {
  USER_SELECTED_COMPANY,
  USER_ROLES,
  USER_SCOPES,
} from 'storage';
import { includes, find, get } from 'lodash';
import {
  CUSTOM_ROLES,
  RESOURCES,
  Roles,
  RolesList,
  SINGLE_COMPANY_ROLES,
  START_PAGE,
} from '_common/constants/acl';
import aclService from '_common/services/aclService';
import stores from 'stores';
import usersService from 'users/services/usersService';
import links from '_common/routes/urls';
import {
  composeCompanySpecificRole,
  handleApiError,
} from '_common/utils/utils';
import { STORE_STAFF_SCOPE_TEMPLATE } from '_common/constants/appConfig';

/**
 * Feature pass configuration
 * All properties are optional, but if they have a value a logged-in user must satisfy all of them
 * @param userRoles - a logged-in user must have at least one of mentioned role
 * @param exceptRoles - a logged-in user mustn't have any of mentioned role
 * @param userScopes - a logged-in user must have at least one of mentioned userScopes
 * @param validator - a function which checking userRoles/userScopes of a logged user with context variables
 */
export type TFeaturePass = {
  roles?: Array<string>,
  exceptRoles?: Array<string>,
  scopes?: Array<string>,
  validator?: (
    userRoles: Array<string>,
    userScopes: Array<string>,
    ...rest: Array<any>
  ) => boolean,
};

type TFeatures = { [key: string]: TFeaturePass };

export const FEATURES: TFeatures = {
  MANAGE_PROMOTIONS: {
    scopes: ['ats-promotions:read', 'ats-promotions:write'],
  },
  USER_CREATE: {
    exceptRoles: [Roles.CUSTOMER_SUPPORT],
  },
  USER_DELETE: {
    exceptRoles: [Roles.CUSTOMER_SUPPORT],
  },
  USER_EDIT: {
    validator: (
      userRoles: Array<string>,
      userScopes: Array<string>,
      ...rest: Array<any>
    ): boolean => {
      const [subjectRoles] = rest;

      const allowedSubjectRoles = [
        Roles.CUSTOMER_SUPPORT,
        Roles.REPORTER,
        Roles.STORE,
      ];

      if (!includes(userRoles, Roles.CUSTOMER_SUPPORT)) return true;

      if (!subjectRoles || !subjectRoles.length) return false;
      for (const subjectRole of subjectRoles) {
        if (!includes(allowedSubjectRoles, subjectRole)) return false;
      }
      return true;
    },
  },
  USER_EDIT_PROFILE: {
    validator: (
      userRoles: Array<string>,
      userScopes: Array<string>,
      ...rest: Array<any>
    ): boolean => {
      const [subjectField] = rest;

      const allowedSubjectFields = ['password'];

      if (!includes(userRoles, Roles.CUSTOMER_SUPPORT)) return true;

      return includes(allowedSubjectFields, subjectField);
    },
  },
  USER_EDIT_ROLE: {
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
  },
  USER_EDIT_PASSWORD: {
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
  },
  USER_SEE_ROLE: {
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
  },
};

class ACL {

  constructor() {
    reaction(
      () => this.selectedCompanyId,
      () => {
        stores.usersStore.setCurrentCompany(this.selectedCompanyId);
        stores.doddleStores.setCurrentCompany(this.selectedCompanyId);
        stores.parcelsStore.setCurrentOrganisation(this.selectedCompanyId);
        stores.companiesStore.setSelectedCompany(this.selectedCompanyId);

        if (!this.initialising) {
          this.rolesList = this.getRolesList(this.selectedCompanyId);
          this.triggerUsersStoreUpdate();
        }
      }
    );

    reaction(
      () => this.initialising,
      () => {
        this.triggerUsersStoreUpdate();
      }
    );
  }

  triggerUsersStoreUpdate = () => {
    const defaultRole = get(this.getRolesListField, '[0].value');
    if (defaultRole) {
      stores.usersStore.resetFilters({ role: defaultRole });
    }
  };

  /**
   * Initialising 'false' means that selectedCompanyId is set
   * and all roles and scopes are loaded
   *
   * @type {boolean}
   */
  @observable
  initialising: boolean = true;

  /**
   * All admin tool roles
   *
   * @type {Array<string>}
   */
  @observable
  roleIds: Array<string> = [];

  @observable
  selectedCompanyId: string = '';

  @observable
  rolesList: Array<TOption> = [];

  @observable
  scopesList: Array<TOption> = [];

  @observable
  userRoles: Array<string> = [];

  @observable
  userScopes: Array<string> = [];

  @computed
  get storeRolePromotionsAccess(): boolean {
    return (
      !!this.isStoreRole &&
      this.isStrictAccessGranted({
        scopes: ['ats-promotions:read', 'ats-promotions:write'],
      })
    );
  }

  @computed
  get isSingleCompanyRole(): boolean {
    return this.userRoles && this.userRoles.length
      ? SINGLE_COMPANY_ROLES.find(role => this.userRoles[0].startsWith(role))
      : false;
  }

  @computed
  get isDoddleAdminRole() {
    return includes(this.userRoles, Roles.DODDLE_ADMIN, false);
  }

  @computed
  get isHostRole() {
    return includes(this.userRoles, Roles.HOST_ADMIN, false);
  }

  @computed
  get isCustomerSupportRole() {
    return includes(this.userRoles, Roles.CUSTOMER_SUPPORT, false);
  }

  @computed
  get isNetworkRole() {
    return includes(this.userRoles, Roles.NETWORK, false);
  }

  @computed
  get isStoreRole() {
    return !!this.userRoles.find(role => role.startsWith(Roles.STORE));
  }

  @computed
  get storeUsersStoreId(): string | null {
    let result = null;
    const scopes = toJS(this.getUserScopes());
    if (this.isStoreRole && Array.isArray(scopes) && scopes.length) {
      const storeStaffScope = scopes.find(scope =>
        scope.startsWith(STORE_STAFF_SCOPE_TEMPLATE)
      );
      if (storeStaffScope) {
        result = storeStaffScope.split(STORE_STAFF_SCOPE_TEMPLATE)[1];
      }
    }
    return result;
  }

  @computed
  get getScopesListField(): Array<TOption> {
    return values(this.scopesList);
  }

  get storeDetailsFullAccess(): boolean {
    return this.isStrictAccessGranted({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
      scopes: ['stores:read', 'stores-sensitiveData:read'],
    });
  }

  @computed
  get getHostMarketingPortalSettingsAccess(): boolean {
    if (this.isDoddleAdminRole) {
      return true;
    }

    const company = stores.companiesStore.getCompanyFromCache(
      this.getSelectedCompanyId
    );
    let result = false;

    if (company && company.hierarchy) {
      const { hierarchy } = company;
      const userCompanies = stores.companiesStore.getUserCompanies;
      for (const company of userCompanies) {
        if (hierarchy.includes(company)) {
          result = true;
          break;
        }
      }
    }

    return result;
  }

  /**
   * Find higher userRoles in the hierarchy
   * @returns Array<string>
   */
  @computed
  get getHigherRoles(): Array<string> {
    const userRoles = this.getUserRoles();
    let higherRoles = [];
    const rolesValues = Object.values(Roles);
    for (const userRole of userRoles) {
      const roleIndex = rolesValues.indexOf(userRole);
      if (rolesValues.includes(userRole)) {
        higherRoles = rolesValues.slice(0, roleIndex);
      }
    }
    // $FlowFixMe
    return higherRoles;
  }

  @computed
  get getRolesListField(): Array<TOption> {
    return this.filterHigherRoles(values(this.rolesList));
  }

  filterHigherRoles = (rolesList: Array<TOption>): Array<TOption> => {
    return rolesList.filter(
      (role: TOption) => !this.getHigherRoles.includes(role.value)
    );
  };

  get getSelectedCompanyId() {
    return this.selectedCompanyId;
  }

  userHasSelectedCompany = (login: string, companyId: string): boolean => {
    if (!login.includes('@')) {
      login = `${login}@${companyId}.doddle.io`;
    }
    return !!storage.get(`${USER_SELECTED_COMPANY}${login}`);
  };

  @action
  setSelectedCompanyId = (organisationId: string) => {
    this.selectedCompanyId = organisationId;
    storage.set(
      `${USER_SELECTED_COMPANY}${stores.authStore.getLoggedUserField.userId}`,
      organisationId
    );
  };

  createScopes = async (scopes: Array<string>): Promise<void> => {
    try {
      await usersService.createScopes(scopes);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Method to return cached scopes if query is empty.
   * Otherwise loads userScopes by query.
   *
   * @param query
   * @returns {Promise<Array<{ label: string, value: string }>>}
   */
  @action
  getScopesByQuery = async (query: string): Promise<Array<TOption>> => {
    if (!query) {
      if (this.scopesList.length) {
        return values(this.scopesList);
      } else {
        const scopes = await aclService.getScopesByQuery(query);
        runInAction(() => {
          this.scopesList = scopes;
        });
        return scopes;
      }
    }
    return aclService.getScopesByQuery(query);
  };

  @action
  getCurrentRole = () => {
    /** For Searching only inside Roles */
    this.getUserRoles();
    const rolesValues = Object.values(Roles);

    return (
      this.userRoles &&
      this.userRoles.find(userRole =>
        // $FlowExpectedError
        rolesValues.some(role => userRole.startsWith(role))
      )
    );
  };

  @action
  getUserScopes = (): Array<string> => {
    if (this.userScopes.length) return this.userScopes;

    const scopes = storage.get(USER_SCOPES);
    this.userScopes = scopes.split(' ');
    return this.userScopes;
  };

  @action
  getUserRoles = (): Array<string> => {
    if (this.userRoles && this.userRoles.length) return this.userRoles;

    this.userRoles = storage.get(USER_ROLES);
    return this.userRoles;
  };

  @action
  getDefaultRedirectUrl = () => {
    this.getUserRoles();

    let startPage = links.dashboard;

    if (this.userRoles && this.userRoles.length) {
      const defaultStartPage = Object.entries(START_PAGE).find(([role]) =>
        this.userRoles[0].startsWith(role)
      );
      startPage = get(defaultStartPage, '[1]', startPage);
    }

    return startPage;
  };

  @action
  getCurrentPermissionsConfig = (
    resource: Object
  ): { roleGranted: boolean, scopeGranted: boolean } => {
    this.getUserRoles();
    this.getUserScopes();

    let roleGranted = true;
    if (resource.roles && resource.roles.length) {
      roleGranted =
        this.userRoles && this.userRoles.length && resource.roles.length
          ? resource.roles.some(role =>
              this.userRoles.find(userRole => userRole.startsWith(role))
            )
          : false;
    }
    let scopeGranted = false;

    if (this.userScopes.length) {
      if (resource.scopes && resource.scopes.length) {
        scopeGranted = resource.scopes.every(scope =>
          this.userScopes.includes(scope)
        );
      } else {
        scopeGranted = true;
      }
    }

    return { roleGranted, scopeGranted };
  };

  @action
  canAccessResource(resource: Object): boolean {
    const { roleGranted, scopeGranted } = this.getCurrentPermissionsConfig(
      resource
    );
    return roleGranted || scopeGranted;
  }

  @action
  isStrictAccessGranted = (resource: Object): boolean => {
    const { roleGranted, scopeGranted } = this.getCurrentPermissionsConfig(
      resource
    );
    return roleGranted && scopeGranted;
  };

  canCustomRoleAccessFeature(
    customRole?: TCustomRole,
    featurePass: TFeaturePass
  ): boolean {
    return (
      !!customRole &&
      this.canAccessFeatureHelper(
        [customRole.role],
        customRole.scopes,
        featurePass
      )
    );
  }

  canAccessFeature(
    featurePass: TFeaturePass,
    ...validatorVars: Array<any>
  ): boolean {
    return this.canAccessFeatureHelper(
      this.getUserRoles(),
      this.getUserScopes(),
      featurePass,
      validatorVars
    );
  }

  canAccessFeatureHelper(
    sourceRoles: Array<string>,
    sourceScopes: Array<string>,
    featurePass: TFeaturePass,
    validatorVars?: Array<any>
  ): boolean {
    const isAnyIn = (what: Array<string>, where: Array<string>): boolean => {
      return !!find(where, whereItem => includes(what, whereItem));
    };

    const { exceptRoles, validator, roles, scopes } = featurePass;

    if (exceptRoles && isAnyIn(exceptRoles, sourceRoles)) {
      return false;
    }
    if (
      validator &&
      validatorVars &&
      !validator(sourceRoles, sourceScopes, ...validatorVars)
    ) {
      return false;
    }
    if (roles && !isAnyIn(roles, sourceRoles)) {
      return false;
    }
    return !(scopes && !isAnyIn(scopes, sourceScopes));
  }

  /**
   * Make up roles list
   * All company specific roles i.e. CUSTOM_ROLES replace base roles
   *
   * @param companyId
   * @returns {*}
   */
  getRolesList = (companyId: string) => {
    const customRoleValues = Object.values(CUSTOM_ROLES);
    const expectedRoles = Object.values(Roles).map(expectedRole => {
      let result = expectedRole;
      if (customRoleValues.includes(expectedRole)) {
        // $FlowExpectedError
        result = `${expectedRole}_${companyId}`;
      }
      return result;
    });
    const expectedRoleIds = this.roleIds.filter(role =>
      expectedRoles.includes(role)
    );
    return RolesList.reduce((accum: Array<TOption>, role: TOption) => {
      const tempAccum = [...accum];
      const roleValue = customRoleValues.includes(role.value)
        ? composeCompanySpecificRole(role.value, companyId)
        : role.value;
      if (expectedRoleIds.includes(roleValue)) {
        tempAccum.push({
          label: role.label,
          value: roleValue,
        });
      }
      return tempAccum;
    }, []);
  };

  @action
  fetch = async () => {
    let selectedCompanyId;
    try {
      const lastSelectedCompany = storage.get(
        `${USER_SELECTED_COMPANY}${stores.authStore.getLoggedUserField.userId}`
      );
      const userCompanies = stores.companiesStore.getUserCompanies;
      selectedCompanyId =
        userCompanies &&
        userCompanies.includes &&
        userCompanies.includes(lastSelectedCompany)
          ? lastSelectedCompany
          : userCompanies[0];
      this.setSelectedCompanyId(selectedCompanyId);
    } catch (e) {
      handleApiError(e, 'Could not set selected company');
    }
    try {
      const canReadUsers = this.isStrictAccessGranted(RESOURCES.USERS.read);
      if (canReadUsers) {
        if (!selectedCompanyId) {
          throw new Error('Uninitialised company id');
        }
        const roleIds = await aclService.getAdminToolRoles();
        runInAction(() => {
          this.roleIds = roleIds;
        });
        const rolesList = this.getRolesList(selectedCompanyId);
        const scopes = await aclService.getScopesByQuery();
        runInAction(() => {
          this.rolesList = rolesList;
          this.scopesList = scopes;
          this.initialising = false;
        });
      }
    } catch (error) {
      handleApiError(error, 'Could not load roles and scopes');
    }
  };

}

export default ACL;
