// @flow

import { apiClient } from '_common/api/clients/clients';
import { APPLICATION_NAME, SPACES, DASHBOARDNAMES } from '_common/constants/dashboard';

export type TDashboardConfigResponse = {
  name: string,
  src: string,
};

const getDashboard = async (
  organisationId: string
): Promise<TDashboardConfigResponse> => {
  //if the organisation is not in the constant then the response will be an error
  const url = `/v1/periscope-dashboard/${SPACES[organisationId]}/${APPLICATION_NAME}/getUrl/dashboard/${DASHBOARDNAMES[organisationId]}/organisation/${organisationId}`;
  const response = await apiClient.get(url);
  return response.data.resource;
};

export default {
  getDashboard,
};
