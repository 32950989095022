// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { get } from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import userActions from 'users/actions';
import GridWithFilters from '_common/components/GridWithFilters';
import { TableLink } from 'search/pages/Main/elements';
import type { RouterHistory } from 'react-router';
import { ACLStore, UsersStore } from 'stores';
import { withTranslation } from 'react-i18next';
import { Loader } from '_common/components';
import UsersFilter from './UsersFilter';
import { Roles, RolesList } from '_common/constants/acl';
import { TABLE_TYPES } from '_common/constants/users';

type Props = {
  type: string,
  usersStore: UsersStore,
  aclStore: ACLStore,
  storeId: string,
  fixedStore: boolean,
  ignorePanel: boolean,
  EditLinkComponent: Node,
  history: RouterHistory,
  organisationId?: string,
  setLoading?: (loading: boolean) => void,
  t: TTranslateFunc,
  canEditUsers?: boolean,
};

type State = {
  roleOverride: string,
  hiddenFilters: Array<string>,
};

class UsersTable extends Component<Props, State> {

  static defaultProps = {
    canEditUsers: true,
  };

  state = {
    roleOverride: '',
    hiddenFilters: [],
  };

  componentDidMount() {
    const { type, storeId, usersStore } = this.props;

    usersStore.setTableType(type);

    if (type === TABLE_TYPES.STORE) {
      if (!storeId) return;
      const storeRoleOption = RolesList.find(option =>
        option.value.startsWith(Roles.STORE)
      );
      this.setState({
        roleOverride: storeRoleOption ? storeRoleOption.label : Roles.STORE,
        hiddenFilters: ['role'],
      });
      usersStore.loadStoreUsers(storeId);
    }
  }

  renderColumns = () => [
    {
      title: this.props.t('username'),
      key: 'login',
      width: 400,
      sorter: (a, b) => {
        const aName = get(a, 'login', '');
        const bName = get(b, 'login', '');

        return aName.localeCompare(bName);
      },
      render: (row: Object) => {
        const { login } = row;
        const { canEditUsers } = this.props;
        const link = this.getActionRedirectLink(row);
        return (
          login &&
          (canEditUsers ? <TableLink to={link}>{login}</TableLink> : login)
        );
      },
    },
    {
      title: this.props.t('role'),
      key: 'roles',
      width: 400,
      render: () => {
        const { usersStore, aclStore } = this.props;
        const roleId = this.state.roleOverride || usersStore.roleLoaded;
        const roleOption = aclStore.getRolesListField.find(
          option => option.value === roleId
        );

        return roleOption ? roleOption.label : roleId;
      },
    },
    {
      title: this.props.t('status'),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => {
        const aStatus = get(a, 'status', '');
        const bStatus = get(b, 'status', '');

        return aStatus.localeCompare(bStatus);
      },
      render: (status: string) => {
        return this.props.t(status);
      },
    },
  ];

  onCell = row => {
    const { storeId } = this.props;
    return {
      onClick: () => {
        const storeAddress = storeId ? `/${storeId}` : '';
        this.props.history.push(
          `/userDetails/${row.login}/${row.organisationId}${storeAddress}`
        );
      },
    };
  };

  getActionRedirectLink = row => {
    const { storeId } = row;
    const storeAddress = storeId ? `/${storeId}` : '';

    return `/userDetails/${row.login}/${row.organisationId}${storeAddress}`;
  };

  componentWillUnmount() {
    userActions.resetFilteredCollection();
  }

  render() {
    const {
      aclStore,
      usersStore,
      EditLinkComponent,
      canEditUsers,
      type,
    } = this.props;
    const { hiddenFilters } = this.state;

    const users =
      type === TABLE_TYPES.STORE
        ? usersStore.getFilteredUsersField || usersStore.getStoreUsersField
        : usersStore.getFilteredUsersField || usersStore.getUsersField;

    return aclStore.initialising ? (
      <Loader />
    ) : (
      <GridWithFilters
        pagination={usersStore.getPaginationConfigField}
        customActionColumn={!canEditUsers}
        loading={usersStore.isUsersLoading}
        uniqueDataId={'login'}
        CustomFilterComponent={<UsersFilter hiddenFilters={hiddenFilters} />}
        columns={this.renderColumns()}
        gridData={users}
        rowKey={'login'}
        ignoreWrapper
        actionRedirectLink={this.getActionRedirectLink}
        onCell={this.onCell}
        EditLinkComponent={EditLinkComponent}
      />
    );
  }

}

export default compose(
  withTranslation('users'),
  inject('usersStore', 'doddleStores', 'aclStore'),
  observer
)(UsersTable);
