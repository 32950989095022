// @flow

import React, { Fragment } from 'react';
import {
  EditLink,
  Header,
  HeaderCaption,
  HeaderUserCaptionHolder,
  CreateButton,
  HeaderLeftPartHolder,
} from '_common/components/ListView/elements';
import type { RouterHistory } from 'react-router';
import UsersTable from '../UsersTable';
import { EditOutlined } from '@ant-design/icons';
import urls from '_common/routes/urls';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import commonActions from '_common/actions';
import ACLStore, { FEATURES } from '_common/stores/aclStore';
import { withTranslation } from 'react-i18next';
import { TABLE_TYPES } from '_common/constants/users';

type Props = {|
  history: RouterHistory,
  aclStore: ACLStore,
  t: TTranslateFunc,
|};

class UserList extends React.Component<Props> {

  onUserCreate = () => {
    this.props.history.push(urls.user.create);
  };

  render() {
    const { history, aclStore, t } = this.props;

    const selectedCompanyId = aclStore.getSelectedCompanyId;
    const isAllowedToCreateUser = commonActions.canAccessFeature(
      FEATURES.USER_CREATE
    );

    return (
      <Fragment>
        <Header>
          <HeaderLeftPartHolder>
            <HeaderUserCaptionHolder>
              <HeaderCaption>{t('users')}</HeaderCaption>
            </HeaderUserCaptionHolder>
          </HeaderLeftPartHolder>
          {isAllowedToCreateUser && (
            <CreateButton onClick={this.onUserCreate}>
              {t('createUser')}
            </CreateButton>
          )}
        </Header>
        <UsersTable
          type={TABLE_TYPES.MAIN}
          history={history}
          organisationId={selectedCompanyId}
          EditLinkComponent={
            <EditLink>
              <EditOutlined />
            </EditLink>
          }
        />
      </Fragment>
    );
  }

}

export default compose(
  withTranslation('users'),
  inject('aclStore'),
  observer
)(UserList);
