import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const paginationItem = css`
  width: 48px;
  height: 36px;
  border-radius: initial;
  line-height: 36px;
`;

const colors = css`
  border-color: #542785;
  color: #542785;
`;

export const TableWrapper = styled.div`
  .ant-table-thead {
    span {
      font-family: ${props => props.theme.fonts.basic};
      font-size: 13px;
      color: #6a6b6e;
    }
  }

  td {
    font-family: ${props => props.theme.fonts.basic};
  }

  .ant-table-pagination.ant-pagination {
    float: left;
  }

  ${props =>
    props.hideLastPage &&
    css`
      .ant-pagination li:nth-last-of-type(2) {
        display: none;
      }
    `}

  .ant-pagination-item {
    ${paginationItem};

    &:hover {
      border-color: #542785;

      a {
        color: #542785;
      }
    }
  }

  .ant-pagination-disabled {
    display: none;
  }

  .ant-pagination-item-active {
    background-color: #542785;
    border: none;

    &:hover,
    &:focus {
      border-color: #fff;

      a {
        color: #fff;
      }
    }

    a {
      color: #fff;
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    ${paginationItem};

    a {
      ${paginationItem};
    }
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    ${colors};
  }
`;

export const ViewLink = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 14px;
  color: #542785;
  cursor: pointer;
`;

export const ActionLink = styled(Link)`
  color: inherit;
`;
