// @flow

import { observable, action } from 'mobx';

class UIStore {

  @observable
  locale: string = 'en';

  @observable
  notifications: Array<TNotification> = [];

  @observable
  promotionEditConsentDialogRef: ?Object = null;

  @observable
  eventSearchParams: TEventSearchParams = {};

  @observable
  customerSearchParams: TCustomerSearchParams = {};

  @observable
  parcelsSearchParams: string = '';

  @action
  setLocale = (locale: string) => {
    this.locale = locale;
  };

  @action
  setPromotionEditConsentDialogRef = (ref: ?Object) => {
    this.promotionEditConsentDialogRef = ref;
  };

  @action
  setParcelsSearchParams = (parcelsSearchParams: string) => {
    this.parcelsSearchParams = parcelsSearchParams;
  };

  @action
  setCustomerSearchParams = (customerSearchParams: TCustomerSearchParams) => {
    this.customerSearchParams = {
      ...this.customerSearchParams,
      ...customerSearchParams,
    };
  };

  @action
  setEventSearchParams = (eventSearchParams: TEventSearchParams) => {
    this.eventSearchParams = {
      ...this.eventSearchParams,
      ...eventSearchParams,
    };
  };

  @action
  showMessage = (
    message: string,
    { duration = 5, subData = {}, ...restOptions }: TNotificationOptions
  ) => {
    this.notifications = [
      ...this.notifications,
      {
        id: Symbol(),
        message,
        duration,
        subData,
        ...restOptions,
      },
    ];
  };

  @action
  removeNotification = (ref: any) => {
    this.notifications = this.notifications.filter(({ id }) => id !== ref);
  };

}

export default UIStore;
