// @flow

import React from 'react';
import { DatePicker } from 'antd';
import { forEach, debounce, uniqBy, get, findLast } from 'lodash';
import moment from 'moment';
import type Moment from 'moment';
import { Input, Select } from '_common/components';
import { Wrapper, FilterItem, FilterLabel } from './elements';

type Props = {
  resetFilters: boolean,
  uniqueDataId: string | Function,
  data: Array<Object>,
  filterData: Array<Object>,
  onComplete: (Array<Object>, boolean) => void,
  filters: Array<TFilter> | (() => Array<TFilter>),
};

type State = {
  activeFilters: Object,
};

const DEFINED_FILTERS = {
  byDate: 'filterByDate',
  byName: 'filterByName',
  byStoreActive: 'filterByStoreActive',
  byDateRange: 'filterByDateRange',
  byDateExtra: 'filterByExtraDate',
};

class GridFilter extends React.Component<Props, State> {

  state = {
    activeFilters: {},
  };

  selectRefs = {};

  shouldComponentUpdate(nextProps: Props) {
    if (this.props.resetFilters !== nextProps.resetFilters) {
      this.resetFilters();
    }

    return true;
  }

  resetFilters = () => {
    const _state = Object.assign({}, this.state);

    forEach(_state, (v, k) => {
      _state[k] = '';
    });

    _state['activeFilters'] = {};

    forEach(this.selectRefs, v => {
      if (v.resetSelectedValue) {
        v.resetSelectedValue();
      }
    });

    this.setState(_state);
  };

  filterByName = (data: Array<Object>) => {
    const input = this.state.activeFilters[DEFINED_FILTERS.byName];

    const collection = [];

    data.forEach(item => {
      const firstName =
        this.propValueLookup(item, 'customer.name.firstName') || '';
      const lastName =
        this.propValueLookup(item, 'customer.name.lastName') || '';

      const fullName = `${firstName} ${lastName}`;

      if (!fullName || !input.value) return;

      if (fullName.toLowerCase().indexOf(input.value.toLowerCase()) > -1) {
        collection.push(item);
      }
    });

    return collection;
  };

  transformDates = (start: Moment, end: Moment): Object => {
    return {
      start: start.startOf('date'),
      end: end.endOf('date'),
    };
  };

  filterByDateRange = (data: Array<Object>) => {
    const date = this.state.activeFilters[DEFINED_FILTERS.byDateRange];

    const collection = [];

    const start = date.value[0];
    const end = date.value[1];

    const result = this.transformDates(start, end);

    data.forEach(item => {
      if (
        moment(item.dateCreated, 'YYYY-MM-DDTHH:mm:ss').isBetween(
          result.start,
          result.end
        )
      ) {
        collection.push(item);
      }
    });

    return collection;
  };

  filterByDate = (data: Array<Object>) => {
    const date = this.state.activeFilters[DEFINED_FILTERS.byDate];

    const collection = [];

    const start = date.value[0];
    const end = date.value[1];

    const result = this.transformDates(start, end);

    data.forEach(item => {
      const lastPutOnShelfEvent = findLast(
        item.eventHistory,
        rec => rec.eventType === 'PUT_ON_SHELF'
      );
      if (
        lastPutOnShelfEvent &&
        moment(lastPutOnShelfEvent.dateTime).isBetween(result.start, result.end)
      ) {
        collection.push(item);
      }
    });

    return collection;
  };

  filterByExtraDate = (data: Array<Object>) => {
    const date = this.state.activeFilters[DEFINED_FILTERS.byDateExtra];

    const collection = [];

    const start = date.value[0];
    const end = date.value[1];

    const result = this.transformDates(start, end);

    data.forEach(item => {
      item.eventHistory.forEach(event => {
        if (
          event.eventType === 'DELIVERED_TO_STORE' &&
          moment(event.dateTime).isBetween(result.start, result.end)
        ) {
          collection.push(item);
        }
      });
    });

    return collection;
  };

  filterByStoreActive = (data: Array<Object>): Array<Object> => {
    const input = this.state.activeFilters[DEFINED_FILTERS.byStoreActive];

    const isDisabled = input.value === 'deactivationDate';

    if (isDisabled) {
      return data.filter(item => !!item.deactivationDate);
    }

    return data.filter(item => !!!item.deactivationDate);
  };

  setInputValue = (value: string, key: string) => {
    this.setState({
      [key]: value,
    });
  };

  setSelectRef = (ref: ?Object, key: string) => {
    this.selectRefs[key] = ref;
  };

  setDateRangeValue = (value: ?Array<moment>, key: string) => {
    this.setState({
      [key]: value,
    });
  };

  handleTextFilter = debounce((value: string, config: Object) => {
    const trimmedValue = value.trim();
    const activeFilters = Object.assign({}, this.state.activeFilters);

    if (!trimmedValue) {
      delete activeFilters[config.key];
    } else {
      activeFilters[config.key] = {
        path: config.path,
        value: trimmedValue,
      };
    }

    this.setState({ activeFilters }, () => {
      this.runFilter();
    });
  }, 500);

  handleSelectFilter = debounce((option: Object, config: Object) => {
    const activeFilters = Object.assign({}, this.state.activeFilters);

    if (!option) {
      delete activeFilters[config.key];
    } else {
      activeFilters[config.key] = {
        path: config.path,
        value: option.value,
      };
    }

    this.setState({ activeFilters }, () => {
      this.runFilter();
    });
  }, 500);

  handleDateRangeFilter = (date: ?Array<moment>, config: Object) => {
    const activeFilters = Object.assign({}, this.state.activeFilters);

    if (!date || !date.length) {
      delete activeFilters[config.key];
    } else {
      activeFilters[config.key] = {
        path: config.path,
        value: date,
      };
    }

    this.setState({ activeFilters }, () => {
      this.runFilter();
    });
  };

  runFilter = () => {
    const { data, onComplete } = this.props;
    const { activeFilters } = this.state;
    const filtersCopy = Object.assign({}, activeFilters);

    let targetCollection = data;

    if (Object.keys(filtersCopy).includes(DEFINED_FILTERS.byName)) {
      targetCollection = this.filterByName(targetCollection);
      delete filtersCopy[DEFINED_FILTERS.byName];
    }

    if (Object.keys(filtersCopy).includes(DEFINED_FILTERS.byDate)) {
      targetCollection = this.filterByDate(targetCollection);
      delete filtersCopy[DEFINED_FILTERS.byDate];
    }

    if (Object.keys(filtersCopy).includes(DEFINED_FILTERS.byDateRange)) {
      targetCollection = this.filterByDateRange(targetCollection);
      delete filtersCopy[DEFINED_FILTERS.byDateRange];
    }

    if (Object.keys(filtersCopy).includes(DEFINED_FILTERS.byDateExtra)) {
      targetCollection = this.filterByExtraDate(targetCollection);
      delete filtersCopy[DEFINED_FILTERS.byDateExtra];
    }

    if (Object.keys(filtersCopy).includes(DEFINED_FILTERS.byStoreActive)) {
      targetCollection = this.filterByStoreActive(targetCollection);
      delete filtersCopy[DEFINED_FILTERS.byStoreActive];
    }

    targetCollection = targetCollection.filter(item => {
      return Object.keys(filtersCopy).every(key => {
        const propValue = this.propValueLookup(item, filtersCopy[key].path);
        if (!propValue) return false;

        return (
          propValue
            .toString()
            .toLowerCase()
            .indexOf(filtersCopy[key].value.toLowerCase()) > -1
        );
      });
    });

    const uniqueCollection = uniqBy(targetCollection, item => {
      return item[this.props.uniqueDataId];
    });

    onComplete(uniqueCollection, !!!Object.keys(activeFilters).length);
  };

  getSelectOptions = (id: string, path: string): Array<Object> => {
    const { filterData } = this.props;

    const options = [];

    const uniqueObjects = uniqBy(filterData, item => {
      const value = this.propValueLookup(item, path);
      if (!value) return false;

      return value;
    });

    uniqueObjects.forEach(item => {
      const value = this.propValueLookup(item, path);
      options.push({ value, label: value });
    });

    return options;
  };

  propValueLookup = (item: Object, path: string): string | typeof undefined => {
    return get(item, path);
  };

  renderFilterInput = (config: TFilter) => {
    switch (config.type) {
      case 'input':
        return (
          <Input
            value={this.state[config.key]}
            placeholder={config.placeholder}
            onChange={e => {
              this.setInputValue(e.target.value, config.key);
              this.handleTextFilter(e.target.value, config);
            }}
          />
        );
      case 'select':
        return (
          <Select
            ref={ref => this.setSelectRef(ref, config.key)}
            value={this.state[config.key]}
            selectPlaceholder={config.placeholder}
            onSelectOption={option => {
              this.handleSelectFilter(option, config);
            }}
            options={
              config.options || this.getSelectOptions(config.key, config.path)
            }
            inputPlaceholder={config.placeholder}
          />
        );
      case 'date_range':
        return (
          <div>
            <DatePicker.RangePicker
              value={this.state[config.key]}
              onChange={date => {
                this.setDateRangeValue(date, config.key);
                this.handleDateRangeFilter(date, config);
              }}
              format="DD-MM-YY"
              placeholder={['start', 'end']}
              style={{ border: 'none' }}
              size={'large'}
              bordered
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderFilter = (config: TFilter) => {
    return (
      <FilterItem
        key={config.key}
        style={config.width ? { flex: `0 0 ${config.width}` } : {}}
      >
        <FilterLabel>{config.label}</FilterLabel>
        {this.renderFilterInput(config)}
      </FilterItem>
    );
  };

  render() {
    const { filters } = this.props;

    const computedFilters = typeof filters === 'function' ? filters() : filters;

    return (
      <Wrapper>
        {computedFilters.map(f => {
          return this.renderFilter(f);
        })}
      </Wrapper>
    );
  }

}

export default GridFilter;
