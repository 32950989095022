// @flow

export const IDLE_TIMEOUT = 4 * 60 * 60 * 1000;
export const REFRESH_TOKEN_INTERVAL = 10000;

export const AUTH_MANAGEMENT = {
  RATE_LIMIT_THRESHOLD: 5,
  RETRY_BACK_OFF: 3,
  RATE_LIMIT_DELAY_MS: 2000,
};
