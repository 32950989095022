import React, { Component } from 'react';
import { Dialog } from '_common/components';

type Props = {
  singleStore?: TStore,
  storesList?: Array<TStore>,
  dialogProps: TDialogProps,
};

class StoresConflictDialog extends Component<Props> {

  renderContent = () => {};

  render() {
    const { dialogProps } = this.props;

    return (
      <Dialog
        {...dialogProps}
        justifyHeaderContent="center"
        displayCancelButton={false}
        confirmLabel="OK"
        renderContent={this.renderContent}
      />
    );
  }

}

export default StoresConflictDialog;
