// @flow

import React, { Component } from 'react';
import { Column, ColumnsHolder, LeftColumn } from './elements';
import {
  customerInfoBlock,
  interactionInfoBlock,
  returnItemInfoBlock,
  returnRoutingInfoBlock,
  returnAddressInfoBlock,
  parcelDetailInfoBlock,
} from './config';
import ParcelsStore from 'parcels/stores/parcelsStore';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { getInfoBlock } from '_common/utils/utils';
import { CompaniesStore } from 'stores';
import { get } from 'lodash';

type Props = {
  parcelsStore: ParcelsStore,
  companiesStore: CompaniesStore,
};

class ParcelReturn extends Component<Props> {

  render() {
    const { parcelsStore, companiesStore } = this.props;
    const companyId = get(parcelsStore, 'singleReturn.retailerId');
    const companyName = get(
      companiesStore.getCompanyFromCache(companyId),
      'companyName',
      companyId
    );

    return (
      <ColumnsHolder>
        <LeftColumn>
          {getInfoBlock(
            parcelDetailInfoBlock(companyName || companyId),
            false,
            parcelsStore
          )}
          {getInfoBlock(customerInfoBlock, false, parcelsStore)}
          {getInfoBlock(interactionInfoBlock, true)}
        </LeftColumn>
        <Column>
          {getInfoBlock(returnItemInfoBlock, false, parcelsStore)}
          {getInfoBlock(returnRoutingInfoBlock, false, parcelsStore)}
          {getInfoBlock(returnAddressInfoBlock, true)}
        </Column>
      </ColumnsHolder>
    );
  }

}

export default compose(
  inject('parcelsStore', 'companiesStore'),
  observer
)(ParcelReturn);
