// @flow

import {
  action,
  computed,
  observable,
  reaction,
  runInAction,
  toJS,
  values,
} from 'mobx';
import companiesService from '_common/services/companiesService';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { handleApiError, validateUrl } from '_common/utils/utils';
import storage, {
  COMPANY_HAS_ACCESS_TO_PROMOTIONS,
  COMPANY_LOGO,
  USER_COMPANIES,
} from 'storage';
import stores from 'stores';
import { get, includes, isEmpty, omit, some, uniq } from 'lodash';
import defaultLogo from 'assets/images/logo.svg';
import {
  CONNECTIVITY_ACCESS_CONFIG,
  CUSTOM_ROLES,
} from '_common/constants/acl';
import aclService from '_common/services/aclService';
import type { TFeaturePass } from './aclStore';

class CompaniesStore {

  constructor() {
    reaction(
      () => this.selectedCompany,
      () => {
        stores.dashboardStore.setCurrentCompany(this.selectedCompany);
        stores.promotionsStore.setCurrentCompany(this.selectedCompany);

        const promotionsEnabled = this.getCompanyPromotionsEnabled(
          this.selectedCompany
        );
        storage.set(COMPANY_HAS_ACCESS_TO_PROMOTIONS, promotionsEnabled);
        if (this.promotionsEnabled !== promotionsEnabled) {
          this.promotionsEnabled = promotionsEnabled;
        }

        this.getAndSetSelectedCompanyLogo();
      }
    );

    reaction(
      () => this.companies.size,
      () => {
        this.getAndSetSelectedCompanyLogo();
      }
    );

    reaction(
      () => this.companyLogo,
      async () => {
        const { authStore, aclStore } = stores;
        const loggedInUserMainCompany = get(
          authStore,
          'getLoggedUserField.organisationId'
        );
        let userMainCompanyLogo = this.companyLogo;
        if (aclStore.selectedCompanyId !== loggedInUserMainCompany) {
          userMainCompanyLogo = await this.getCompanyLogo(
            loggedInUserMainCompany
          );
        }
        storage.set(COMPANY_LOGO, userMainCompanyLogo);
      }
    );
  }

  @observable
  companies: Map<string, TCompany> = new Map();

  @observable
  userCompanies: Array<string> = storage.get(USER_COMPANIES) || [];

  @observable
  cachedUserCompaniesList: Array<TOption> = [];

  @observable
  selectedCompany: Object | TCompany = {};

  @observable
  companyLogo: string = storage.get(COMPANY_LOGO) || defaultLogo;

  @observable
  singleCompany: Object | TCompany = {};

  /**
   * There are company specific roles i.e. CUSTOM_ROLES
   * These roles could have different scopes than the base role
   * This object store custom role -> scopes mapping to reflect functionality setting in company details page
   *
   * @type {Object}
   */
  @observable
  singleCompanyCustomRoles: Map<string, Object> = new Map();

  @observable
  promotionsEnabled: boolean = !!storage.get(COMPANY_HAS_ACCESS_TO_PROMOTIONS);

  @computed
  get isConnectivityCheckAvailable(): boolean {
    const { topHierarchyCompanyName, companyName, hierarchy } =
      this.selectedCompany;
    return (
      includes(
        CONNECTIVITY_ACCESS_CONFIG.NAMES,
        topHierarchyCompanyName || companyName
      ) ||
      some(CONNECTIVITY_ACCESS_CONFIG.IDS, (allowedCompany) =>
        some(hierarchy, (hierarchyCompany) =>
          includes(hierarchyCompany, allowedCompany)
        )
      )
    );
  }

  @computed
  get getSingleCompanyId(): string {
    return this.singleCompany.companyId;
  }

  @computed
  get getCompanies(): Array<TCompany> {
    return values(this.companies);
  }

  @computed
  get getUserCompanies(): Array<string> {
    return values(this.userCompanies);
  }

  @computed
  get getCachedUserCompaniesList(): Array<TOption> {
    return values(this.cachedUserCompaniesList);
  }

  @action
  setCachedUserCompaniesList = (options: Array<TOption>) => {
    this.cachedUserCompaniesList = options;
  };

  @action
  setPromotionsEnabled = async (value: boolean) => {
    this.promotionsEnabled = value;
  };

  /**
   * Get logo of given company if it exists, otherwise retrieve parent logo
   *
   * @param companyId
   * @returns {Promise<void>}
   */
  getCompanyLogo = async (companyId: string): Promise<string> => {
    const company = this.getCompanyFromCache(companyId);
    let companyLogo: string = get(company, 'logos.default');
    if (stores.aclStore.isStoreRole) {
      companyLogo = get(company, 'logoUrl', defaultLogo);
    } else if (!companyLogo) {
      companyLogo = defaultLogo;
      let hierarchy = get(company, 'hierarchy');
      if (hierarchy && hierarchy.length > 0) {
        hierarchy = hierarchy.reverse();
        for (const parentCompanyId of hierarchy) {
          const parent = this.getCompanyFromCache(parentCompanyId);
          const parentLogo = get(parent, 'logos.default');
          if (parentLogo) {
            companyLogo = parentLogo;
            break;
          }
        }
      }
    }
    if (companyLogo && validateUrl(companyLogo)) {
      const companyLogoUrl = new URL(`${companyLogo}?t=${Date.now()}`);
      const { searchParams } = companyLogoUrl;
      if (searchParams.has('t')) {
        searchParams.delete('t');
      }
      searchParams.append('t', Date.now().toString());
      companyLogo = companyLogoUrl.toString();
    }
    return companyLogo;
  };

  @action
  getAndSetSelectedCompanyLogo = async () => {
    const companyLogo = await this.getCompanyLogo(
      stores.aclStore.selectedCompanyId
    );
    runInAction(() => {
      this.companyLogo = companyLogo;
    });
  };

  @action
  setSelectedCompany = async (companyId: string) => {
    const company = await this.getCompany(companyId, true);
    if (company) {
      runInAction(() => {
        this.selectedCompany = { ...company };
      });
    }
  };

  getCompanyPromotionsEnabled = (company?: TCompany): boolean => {
    return (
      get(company, 'products.HOST_MARKETING_PORTAL.enabled', false) ||
      stores.aclStore.storeRolePromotionsAccess
    );
  };

  @action
  setUserCompanies(companies: Array<string>) {
    const uniqCompanies = uniq(companies);
    storage.set(USER_COMPANIES, uniqCompanies);
    this.userCompanies = uniqCompanies;
  }

  getCompanyObjectsList(
    companiesId: Array<string> | null = null
  ): Array<TCompany> {
    let companies = this.getCompanies;
    if (!companiesId && !stores.aclStore.isDoddleAdminRole) {
      companiesId = this.userCompanies;
    }
    if (companiesId !== null && companiesId !== undefined) {
      companies = companies.filter((company) =>
        // $FlowFixMe
        companiesId.includes(company?.companyId)
      );
    }
    return companies;
  }

  /**
   * Get list of companies as options
   * If logged user is DoddleAdmin then show ALL companies, otherwise include only companies assigned to the user
   * If companiesId is not null then include only those companies
   *
   * @param companiesId
   * @returns Array
   */
  getCompaniesList(companiesId: Array<string> | null = null): Array<TOption> {
    const companiesList = this.getCompanyObjectsList(companiesId);
    return companiesList.map((company) => ({
      label: company.companyName,
      value: company.companyId,
    }));
  }

  @action
  async toggleCustomRoleFeature(
    baseRole: string,
    feature: TFeaturePass,
    addFeatureFlag: boolean
  ): Promise<void> {
    const featureScopes = feature.scopes;
    const customRole = this.singleCompanyCustomRoles.get(baseRole);
    if (
      customRole &&
      featureScopes &&
      featureScopes.length &&
      customRole.role &&
      Array.isArray(toJS(customRole).scopes)
    ) {
      try {
        const scopes = {};
        if (addFeatureFlag) {
          scopes.add = featureScopes;
        } else {
          scopes.remove = featureScopes;
        }
        const res = await aclService.updateRole(customRole.role, { scopes });
        const updatedCustomRole = res.find(
          (crole) => crole.role === customRole.role
        );
        if (updatedCustomRole) {
          runInAction(() => {
            this.singleCompanyCustomRoles.set(baseRole, updatedCustomRole);
          });
        }
      } catch (e) {
        handleApiError(e, 'Could not update role');
      }
    }
  }

  @action
  async loadSingleCompanyCustomRoles(companyId: string): Promise<void> {
    for (const roleName of Object.values(CUSTOM_ROLES)) {
      if (typeof roleName === 'string') {
        aclService
          .getCustomRole(roleName, companyId)
          .then((customRole) => {
            runInAction(() => {
              this.singleCompanyCustomRoles.set(roleName, customRole);
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }

  @action
  async loadSingleCompanyById(
    companyId: string,
    includeCustomRolesScopes: boolean = false
  ): Promise<boolean> {
    const company = await this.getCompany(companyId, true);
    let result = false;
    if (company) {
      result = true;
      if (includeCustomRolesScopes) {
        this.loadSingleCompanyCustomRoles(companyId);
      }
      runInAction(() => {
        this.singleCompany = company;
      });
    }
    return result;
  }

  @action
  resetSingleCompany(): void {
    this.singleCompany = {};
  }

  getCompanyFromCache = (companyId: string): TCompany | void => {
    return this.companies.get(companyId);
  };

  getCompanyName = (companyId: string): string => {
    let result = companyId;
    const company = this.getCompanyFromCache(companyId);
    if (company && company.companyName) {
      result = company.companyName;
    }
    return result;
  };

  @action
  async getCompany(
    companyId: string,
    forceFetch: boolean = false
  ): Promise<?TCompany> {
    let company = this.getCompanyFromCache(companyId);
    if (!company || forceFetch) {
      try {
        company = await companiesService.getCompany(companyId);
        runInAction(() => {
          // $FlowFixMe
          this.companies.set(companyId, company);
        });
      } catch (e) {
        handleApiError(e, 'Could not get company', {
          messageSubData: { companyId },
        });
        company = null;
      }
    }
    return company;
  }

  async getSubCompanies(companyId: string): Promise<Array<TCompany>> {
    let companies = [];
    try {
      companies = await companiesService.getSubCompanies(companyId);
    } catch (e) {
      handleApiError(e, 'couldNotLoadSubCompanies', {
        messageSubData: { companyId },
      });
    }
    return companies;
  }

  getAllCompanies(): Promise<Array<TCompany>> {
    return companiesService.getSubCompanies(MAIN_COMPANIES.DODDLE_ID);
  }

  createHierarchyFromCompany = async (
    companyId: string
  ): Promise<Array<string> | false> => {
    let newHierarchy = false;
    const company = await this.getCompany(companyId);
    if (company && company.hierarchy) {
      newHierarchy = uniq([...company.hierarchy, company.companyId]);
    }
    return newHierarchy;
  };

  @action
  async createCompany({
    setup,
    config,
    collections,
  }: {
    setup: Object,
    collections: Object,
    config: Object,
  }) {
    const { sendEmailViaCompany } = collections;
    const { storesFeed, label, onboardingStoresFeed } = config;
    const collectionsObject = omit(collections, ['sendEmailViaCompany']);
    const companyData = {
      ...setup,
      onboardingStoresFeed,
      sendEmailViaCompany,
      label,
      storesFeed: [
        {
          ...storesFeed,
          companyId: setup.companyId,
        },
      ],
      returns: {},
    };
    if (!isEmpty(collectionsObject)) {
      companyData.collections = collectionsObject;
    }
    try {
      const hierarchy = await this.createHierarchyFromCompany(
        companyData.parent
      );
      if (!isEmpty(hierarchy)) {
        companyData.hierarchy = hierarchy;
        const company = await companiesService.createCompany(companyData);
        runInAction(() => {
          this.companies.set(company.companyId, company);
        });
      } else {
        console.error('Could not create hierarchy for a new company');
        return Promise.reject();
      }
    } catch (e) {
      handleApiError(e, 'Could not create company', {
        showNativeErrors: true,
      });
      return Promise.reject();
    }
    return Promise.resolve();
  }

  @action
  toggleStoreOnboardingEmail = async (companyId: string): Promise<void> => {
    try {
      const currentStatus = this.singleCompany.storeOnboardingEmailEnabled;
      await companiesService.toggleOnboardingEmail(companyId, !currentStatus);
      if (this.singleCompany && companyId === this.singleCompany.companyId) {
        runInAction(() => {
          this.singleCompany.storeOnboardingEmailEnabled = !currentStatus;
          this.selectedCompany.storeOnboardingEmailEnabled = !currentStatus;
        });
      }
    } catch (e) {
      handleApiError(e, 'Could not toggle store onboarding email');
    }
  };

  @action
  updateCompany = async (companyId: string, data: Object): Promise<boolean> => {
    let result = true;
    try {
      const singleCompany = await companiesService.updateCompany(
        companyId,
        data
      );
      this.updateCachedSingleCompany(singleCompany);
    } catch (e) {
      result = false;
      handleApiError(e, 'Could not update company', { showNativeErrors: true });
    }
    return result;
  };

  @action
  updateCompanyPhotos = async (companyId: string, data: TCompanyLogosType) => {
    try {
      const singleCompany = await companiesService.updateCompanyPhotos(
        companyId,
        data
      );
      this.updateCachedSingleCompany(singleCompany);
    } catch (e) {
      handleApiError(e, 'Could not update photos');
    }
  };

  @action
  deleteCompanyPhotos = async (
    companyId: string,
    photosToDelete: Array<string>
  ) => {
    try {
      await companiesService.deleteCompanyPhotos(companyId, { photosToDelete });
      const company = this.getCompanyFromCache(companyId);
      for (const logo of photosToDelete) {
        if (company && company.logos) {
          // $FlowExpectedError
          delete company.logos[logo.toLowerCase()];
          storage.remove(COMPANY_LOGO);
        }
      }
      this.updateCachedSingleCompany(company);
    } catch (e) {
      handleApiError(e, 'Could not delete photos');
    }
  };

  @action
  updateCachedSingleCompany = (singleCompany?: TCompany) => {
    if (singleCompany && singleCompany.companyId) {
      runInAction(() => {
        // Flow wants a redundant check for singleCompany
        // $FlowFixMe
        this.companies.set(singleCompany.companyId, singleCompany);
        // Flow wants a redundant check for singleCompany
        // $FlowFixMe
        this.singleCompany = {
          storeOnboardingEmailEnabled:
            this.singleCompany.storeOnboardingEmailEnabled,
          ...singleCompany,
        };
        if (
          this.selectedCompany &&
          // Flow wants a redundant check for singleCompany
          // $FlowFixMe
          this.selectedCompany.companyId === singleCompany.companyId
        ) {
          this.selectedCompany = { ...singleCompany };
        }
      });
    } else {
      console.error('updateCachedSingleCompany::received empty singleCompany');
    }
  };

  @action
  fetch = async () => {
    const { aclStore, authStore } = stores;
    if (!storage.get(USER_COMPANIES) && !stores.aclStore.isStoreRole) {
      authStore.logout();
    }
    if (!aclStore.isSingleCompanyRole) {
      try {
        const companies = await this.getAllCompanies();
        runInAction(() => {
          if (companies) {
            companies.forEach((company) => {
              this.companies.set(company.companyId, company);
            });
          }
          this.cachedUserCompaniesList = this.getCompaniesList();
        });
      } catch (e) {
        handleApiError(e, 'Could not load companies');
      }
    }
  };

}

export default CompaniesStore;
