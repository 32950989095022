// @flow

import React, { Fragment } from 'react';
import {
  SelectBlock,
  FormPartCaption,
  TextLink,
  AddSelectButton,
  SelectWithValue,
  SelectedValue,
  SelectWrapper,
  SelectedItemsWrapper,
  UserLabel,
  SelectRemoveLinkHolder,
  ValidationMessage,
} from './elements';
import { Select } from '_common/components';
import { isCompanyScope } from '_common/utils/utils';
import { Pagination } from 'antd';
import { PaginationWrapper } from '_common/components/PlainStyles';
import { chunk } from 'lodash';
import { withTranslation } from 'react-i18next';

type Props = {
  caption: string,
  addButtonLabel: string,
  editLabel: string,
  selectLabel: string,
  options: Array<TOption>,
  placeHolder: string,
  onChange: Function,
  selectedItems: Array<string>,
  isAsync: boolean,
  loadOptions?: string => Promise<Array<TOption>>,
  validationMessage?: string,
  editable: boolean,
  pagination?: boolean,
  t: TTranslateFunc,
};

type State = {
  edit: boolean,
  page: number,
};

class AuthoritySelect extends React.Component<Props, State> {

  PAGE_SIZE = 5;

  static defaultProps = {
    editable: true,
  };

  state = {
    edit: false,
    page: 1,
  };

  removeItem = (e: {
    currentTarget: {
      dataset: {
        name: string,
      },
    },
  }) => {
    const { onChange, selectedItems } = this.props;
    onChange(
      selectedItems.filter(item => item !== e.currentTarget.dataset.name)
    );
  };

  onSelect = (i?: number) => (option?: TOption) => {
    const { selectedItems, onChange } = this.props;
    if (option) {
      const { value } = option;
      if (i !== null && i !== undefined) {
        selectedItems[i] = value;
        onChange(selectedItems, isCompanyScope(value));
      } else {
        onChange([...selectedItems, value], isCompanyScope(value));
      }
    }
  };

  renderSelectedItem = (value: string, i: number) => {
    const isCompany = isCompanyScope(value);
    const { edit } = this.state;
    const {
      options,
      placeHolder,
      isAsync,
      loadOptions,
      editable,
      t,
    } = this.props;

    const removeLink = editable && !isCompany && (
      <TextLink data-name={value} onClick={this.removeItem}>
        {t('common:remove')}
      </TextLink>
    );

    const selectedOption = options.find(
      (option: TOption) => option.value === value
    );
    const nextOptions = selectedOption
      ? options
      : [{ value, label: value }, ...options];

    return edit ? (
      <SelectRemoveLinkHolder key={value || `${placeHolder}${i}`}>
        <SelectWrapper>
          <Select
            isDisabled={isCompany}
            isAsync={isAsync}
            loadOptions={loadOptions}
            onSelectOption={this.onSelect(i)}
            inputPlaceholder={value || placeHolder}
            selectPlaceholder={value || placeHolder}
            initialValue={selectedOption ? selectedOption.value : undefined}
            options={nextOptions}
          />
        </SelectWrapper>
        {removeLink}
      </SelectRemoveLinkHolder>
    ) : (
      <SelectWrapper key={value || `${placeHolder}${i}`}>
        <SelectWithValue>
          <SelectedValue>
            {selectedOption ? selectedOption.label : value}
          </SelectedValue>
          {removeLink}
        </SelectWithValue>
      </SelectWrapper>
    );
  };

  appendItem = () => {
    const { onChange, selectedItems } = this.props;

    this.setState({
      edit: true,
    });

    onChange(['', ...selectedItems]);
  };

  changePage = (page: number) => {
    this.setState({
      page,
    });
  };

  showTotal = (total: string | number) =>
    this.props.t('totalScopes', { count: total });

  render() {
    const {
      caption,
      addButtonLabel,
      editLabel,
      selectLabel,
      options,
      placeHolder,
      selectedItems,
      isAsync,
      loadOptions,
      validationMessage,
      editable,
      pagination,
    } = this.props;
    const { edit, page } = this.state;

    let itemsToRender = selectedItems;
    if (pagination) {
      const selectedItemsChunks = chunk(selectedItems, this.PAGE_SIZE);
      itemsToRender = selectedItemsChunks.length
        ? selectedItemsChunks[page - 1]
        : [];
    }
    const totalItems = selectedItems.length;

    return (
      <SelectBlock>
        <FormPartCaption>
          {caption}
          {editable && !edit && totalItems ? (
            <TextLink
              onClick={() =>
                this.setState({
                  edit: true,
                })
              }
            >
              {editLabel}
            </TextLink>
          ) : null}
        </FormPartCaption>

        {editable && totalItems && !selectedItems.includes('') ? (
          <AddSelectButton onClick={this.appendItem}>
            {addButtonLabel}
          </AddSelectButton>
        ) : null}
        {itemsToRender.length ? (
          <Fragment>
            <SelectedItemsWrapper>
              {itemsToRender.map(this.renderSelectedItem)}
            </SelectedItemsWrapper>
            {pagination && (
              <PaginationWrapper>
                <Pagination
                  size="small"
                  showTotal={this.showTotal}
                  total={totalItems}
                  current={page}
                  hideOnSinglePage
                  onChange={this.changePage}
                  pageSize={this.PAGE_SIZE}
                />
              </PaginationWrapper>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <UserLabel>{selectLabel}</UserLabel>
            <SelectWrapper>
              <Select
                isDisabled={!editable}
                isAsync={isAsync}
                loadOptions={loadOptions}
                onSelectOption={this.onSelect()}
                inputPlaceholder={placeHolder}
                selectPlaceholder={placeHolder}
                options={options}
              />
            </SelectWrapper>
          </Fragment>
        )}

        {!!validationMessage && (
          <ValidationMessage>{validationMessage}</ValidationMessage>
        )}
      </SelectBlock>
    );
  }

}

export default withTranslation('users')(AuthoritySelect);
