// @flow strict

import links from '_common/routes/urls';
import SingleUser from '../pages/SingleUser';
import UserList from '../pages/UserList';

export default [
  {
    path: links.user.createStoreUser,
    component: SingleUser,
  },
  {
    path: links.user.editUserStore,
    component: SingleUser,
  },
  {
    path: links.user.editUser,
    component: SingleUser,
  },
  {
    path: links.user.userList,
    component: UserList,
  },
  {
    path: links.user.create,
    component: SingleUser,
  },
];
