import styled from 'styled-components';

export const ColumnsHolder = styled.div`
  display: flex;
`;

export const Column = styled.div`
  width: 40.1%;
`;

export const LeftColumn = styled(Column)`
  margin-right: 10.7%;
`;
