import React from 'react';
import { Row, Col, DatePicker } from 'antd';
import { Input, Select } from '_common/components';

import { EditIcon, UpdateButton } from './elements';

class EditableField extends React.Component {

  state = {
    editMode: false,
    updatedValue: '',
  };

  setEditMode = () => {
    this.setState({ editMode: true });
  };

  setViewMode = () => {
    const { onUpdate } = this.props;

    this.setState({ editMode: false });
    if (onUpdate) {
      onUpdate(this.state.updatedValue);
    }
  };

  render() {
    const { editMode } = this.state;

    const renderEditControl = props => {
      switch (props.type) {
        case 'select':
          return (
            <Select
              {...props}
              onSelectOption={item =>
                this.setState({ updatedValue: item.value })
              }
            />
          );
        case 'datepicker':
          return (
            <DatePicker
              showTime
              onChange={val => this.setState({ updatedValue: val })}
            />
          );
        default:
          return <Input {...this.props} />;
      }
    };

    return editMode ? (
      <Row type="flex" align="middle" style={{ height: '50px' }} gutter={5}>
        <Col span={20}>{renderEditControl(this.props)}</Col>
        <Col span={4}>
          <UpdateButton onClick={this.setViewMode}>Update</UpdateButton>
        </Col>
      </Row>
    ) : (
      <Row type="flex" align="middle" style={{ height: '50px' }}>
        <Col span={this.props.readonly ? 24 : 23}>{this.props.value}</Col>
        {!this.props.readonly && (
          <Col span={1}>
            <EditIcon type="edit" onClick={this.setEditMode} />
          </Col>
        )}
      </Row>
    );
  }

}

export default EditableField;
