import styled from 'styled-components';
import { InputLabel } from '_common/components';

export const SeparationDateLine = styled.div`
  width: 10px;
  height: 2px;
  background-color: #8c8c8c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const DayLabel = styled(InputLabel)`
  && {
    text-transform: capitalize;
  }
`;

export const SubTitle = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: 500;
  color: #494949;
  margin-bottom: 30px;
`;
