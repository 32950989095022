import styled from 'styled-components';
import { Button } from '_common/components';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

export const SpinnerWrapper = styled.div`
  display: ${({ display }) => (display ? display : 'inline-block')};
  margin-left: 5px;
  .ant-spin-dot-item {
    background-color: #542785;
  }
`;

export const SearchHeader = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #e6e7e8;
  padding: 40px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 26px;
  color: #494949;
`;

export const StoreNameLink = styled(Link)`
  font-family: ${(props) => props.theme.fonts.basic};
  font-size: 14px;
  font-weight: 500;
  color: #542785;
  cursor: pointer;
`;

export const SearchResultsWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  .hidden {
    display: none;
  }
`;

export const EmptyStoresWrapper = styled.div`
  text-align: center;
`;

export const EmptyStoresTitle = styled.div`
  font-size: 22px;
  margin-bottom: 30px;
`;

export const CreateStoreButton = styled(Button)`
  && {
    width: 200px;
    height: 46px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const BulkActionsItem = styled.button`
  && {
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 15px 0 2px 3px;
    border-top: 1px solid #e6e7e8 !important;
    background-color: #f7f7f7;
    &.selected {
      background-color: #d3e7f1;
    }
  }
`;

export const BulkActionsMenu = styled(Menu)`
  && {
    background-color: #f7f7f7;
    padding: 0;
    margin-top: -3px;
  }
`;

export const BulkActionButton = styled(CreateStoreButton)`
  && {
    background: ${(props) => props.theme.colors.secondary};
    :hover {
      background: ${(props) => props.theme.colors.secondary};
    }
    :focus {
      background: ${(props) => props.theme.colors.secondary};
    }
    :disabled {
      background: ${(props) => props.theme.colors.shade2};
    }
    :hover:disabled {
      background: ${(props) => props.theme.colors.shade2};
    }
  }
`;

export const EditLink = styled.div`
  cursor: pointer;
  font-size: 17px;
  text-align: center;
`;

export const SubHeader = styled.div``;

export const ActionButton = styled(Button)`
  && {
    border-radius: 4px;
    height: 34px;
  }
`;
