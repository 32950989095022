// @flow

import React from 'react';
import { MenuWrapper } from '_common/components/Menu/elements';
import { ACLStore } from 'stores';
import links from '_common/routes/urls';
import { Roles } from '_common/constants/acl';
import MenuItem from '_common/components/Menu/MenuItem';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

type Props = {
  aclStore: ACLStore,
  t: TTranslateFunc,
};

class SettingsButton extends React.Component<Props> {

  static Item: Function;

  render() {
    const { aclStore, t } = this.props;
    const canEditCompany = aclStore.canAccessFeature({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
    });

    return (
      canEditCompany && (
        <MenuWrapper>
          <MenuItem
            to={links.companies.subroutes.singleCompany.replace(
              ':id',
              aclStore.getSelectedCompanyId
            )}
          >
            {t('settings')}
          </MenuItem>
        </MenuWrapper>
      )
    );
  }

}

SettingsButton.Item = MenuItem;

export default compose(
  inject('aclStore'),
  withTranslation('menu'),
  observer
)(SettingsButton);
