import React, { Component, Fragment } from 'react';
import { Col, Progress, Row } from 'antd';
import { Dialog } from '_common/components';
import {
  TextMedium,
  CustomLink,
  CheckboxBig,
} from '_common/components/PlainStyles';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { CompaniesStore } from 'stores';

type Props = {
  companiesStore: CompaniesStore,
  promotion: TPromotion,
  toggleEnablePromotion: Function,
  dialogProps: TDialogProps,
  successCallback?: Function,
  t: TTranslateFunc,
};

type State = {
  agreedWithAPRules: boolean,
  isProcessing: boolean,
};

class EnablePromotionDialog extends Component<Props, State> {

  initialState = {
    agreedWithAPRules: false,
    isProcessing: false,
  };

  state = { ...this.initialState };

  handleConfirm = () => {
    this.setState({
      isProcessing: true,
    });
    const { toggleEnablePromotion, promotion, successCallback } = this.props;
    toggleEnablePromotion(promotion.promotionId, true).then(() => {
      if (successCallback) {
        successCallback();
      }
      this.handleCancel();
    });
  };

  toggleAgreed = ({ target: { checked } }: Object) =>
    this.setState({ agreedWithAPRules: checked });

  renderContent = () => {
    const { isProcessing, agreedWithAPRules } = this.state;
    const {
      companiesStore: {
        selectedCompany: {
          companyName,
          topHierarchyCompanyName,
          termsAndConditionsUrl,
        },
      },
    } = this.props;
    const tsCompanyName = topHierarchyCompanyName || companyName;

    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col span={2}>
            <CheckboxBig
              onChange={this.toggleAgreed}
              checked={agreedWithAPRules}
            />
          </Col>
          <Col span={18}>
            <TextMedium>
              <Trans
                i18nKey="enableConsent"
                ns="promotions"
                values={{ companyName: tsCompanyName }}
              >
                I've read and understood {tsCompanyName}
                <CustomLink href={termsAndConditionsUrl} target="_blank">
                  terms&nbsp;and&nbsp;conditions
                </CustomLink>
                regarding promotions
              </Trans>
            </TextMedium>
          </Col>
        </Row>
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Fragment>
    );
  };

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
    this.props.dialogProps.handleCancelClick();
  };

  render() {
    const { promotion, dialogProps, t } = this.props;
    const { agreedWithAPRules } = this.state;

    return (
      <Dialog
        {...dialogProps}
        minHeight={320}
        justifyHeaderContent="center"
        headerText={`${t('enable')} ${promotion.title}`}
        confirmLabel={t('enable')}
        displayCancelButton
        isConfirmDisabled={!agreedWithAPRules}
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default compose(
  observer,
  inject('companiesStore')
)(EnablePromotionDialog);
