import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 90px);
`;

export const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
`;

export const Version = styled.span`
  font-size: 11px;
  margin-left: 10px;
  font-family: ${props => props.theme.fonts.book};
  font-style: italic;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export const DashboardNameBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const RestoreDashboard = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;
