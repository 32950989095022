import styled from 'styled-components';
import { Upload, Progress } from 'antd';
import { Link } from 'react-router-dom';

export const ProgressBar = styled(Progress)`
  && {
    margin: 30px 0 30px 0;
  }
`;

export const CustomUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`;

export const Label = styled.div`
  margin-top: 15px;
`;

export const UploadInnerText = styled.div`
  color: #8c8c8c;
  font-family: ${props => props.theme.fonts.light};
  padding: 10px;
  border: 1px solid #d8e1e8;
  width: 100%;
`;

export const HelperLink = styled(Link)`
  min-width: 96px;
  font-size: 15px;
`;
