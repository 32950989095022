// @flow

import dashboardService from 'dashboard/service';
import type { TDashboardConfigResponse } from '../service';
import { handleApiError } from '_common/utils/utils';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { observable, action, runInAction, reaction } from 'mobx';
import stores from '../../stores';
import { RESOURCES } from '_common/constants/acl';
import { STATUSES, TYPES } from '_common/constants/dashboard';

export type TPeriscopeFilter = {
  filter_name: string,
  column_value: string,
};

export type TDashboardConfig = TDashboardConfigResponse;

class DashboardStore {

  constructor() {
    window.addEventListener('message', this.onFrameMessage, false);
    reaction(
      () => this.currentCompany,
      () => {
        if (stores.aclStore.isStrictAccessGranted(RESOURCES.DASHBOARD)) {
          this.loadConfig(this.currentCompany);
        }
      }
    );
  }

  @observable
  currentCompany: TCompany | Object = {};

  @observable
  iframeConfig: ?TDashboardConfig = null;

  @observable
  status: string = STATUSES.LOADING;

  @observable
  dashboardLoadedForCompanyId: string;

  @action
  setCurrentCompany = (currentCompany: TCompany | Object) => {
    this.currentCompany = currentCompany;
  };

  getRootCompanyId = (company: TCompany | Object) => {
    let rootCompanyId = '';
    if (company && TYPES[company.companyId]) {
      rootCompanyId = company.companyId;
    } else if (company) {
      const companiesHierarchy = company.hierarchy;
      if (companiesHierarchy) {
        if (companiesHierarchy.length > 1) {
          rootCompanyId =
            companiesHierarchy[0] === MAIN_COMPANIES.DODDLE_ID
              ? companiesHierarchy[1]
              : companiesHierarchy[0];
        } else if (companiesHierarchy.length === 1) {
          throw new Error('Dashboard is not configured');
        }
      }
    }
    return rootCompanyId;
  };

  @action
  setStatus = (status: string) => {
    runInAction(() => {
      this.status = status;
    });
  };

  @action
  async loadConfig(company: TCompany | Object) {
    if (company.companyId === this.dashboardLoadedForCompanyId) {
      return;
    }
    try {
      let config = null;
      this.setStatus(STATUSES.LOADING);
      this.dashboardLoadedForCompanyId = company.companyId;
      let rootCompanyId;
      try {
        rootCompanyId = this.getRootCompanyId(company);
      } catch {
        this.setStatus(STATUSES.NOT_CONFIGURED);
        return;
      }

      try{
      const response = await dashboardService.getDashboard(rootCompanyId);
      config = {
        ...response,
      };
      } catch {
        this.setStatus(STATUSES.ERROR);
        handleApiError(
          `Unknown rootCompanyId - ${rootCompanyId}`,
          'Could not load dashboard'
        );
        return;
      }

      this.setStatus(STATUSES.LOADED);
      runInAction(() => {
        this.iframeConfig = config;
      });
    } catch (e) {
      this.setStatus(STATUSES.ERROR);
      handleApiError(e, 'Could not load dashboard');
    }
  }

  onFrameMessage = (event: MessageEvent) => {
    const messageData = event.data;
    if (messageData === null || typeof messageData !== 'object') return;
    if (messageData.event_type !== 'drilldown') return;
    if (!Array.isArray(messageData.filter_values)) return;
    const filters: Array<TPeriscopeFilter> = [];
    for (const item of messageData.filter_values) {
      if (item === null || typeof item !== 'object') continue;
      filters.push({
        // eslint-disable-next-line camelcase
        filter_name: String(item.filter_name),
        // eslint-disable-next-line camelcase
        column_value: String(item.column_value),
      });
    }
  };

}

export default DashboardStore;
