// @flow

import React, { Component } from 'react';
import {
  FileInputButton,
  FileInputBtnWrapper,
  FileInfoWrap,
  FileName,
  DeleteFile,
  FileButton,
  FileButtonsWrapper,
} from './elements';
import {
  getFileData as getFileDataUtil,
  getImageDimensions,
  validateImageDimensions,
} from '_common/utils/utils';
import commonActions from '_common/actions';
import { omit } from 'lodash';
import UrlDialog from './UrlDialog';

type State = {
  file: any,
  hideButton: boolean,
  hasError: boolean,
  urlDialogShown: boolean,
};

type Props = {
  onFile: (any, ?any) => void,
  getFileData?: (rawFileData: Object) => Promise<?TFileDataType>,
  maxSize?: number,
  imageDimensionRestriction?: TImageDimensionRestriction,
  canAddByUrl: boolean,
  t: TTranslateFunc,
  initialFile?: string,
};

class FileInput extends Component<Props, State> {

  static defaultProps = {
    t: (s: string, o?: Object): string => s,
    canAddByUrl: true,
  };

  state = {
    file: null,
    hideButton: false,
    hasError: false,
    urlDialogShown: false,
  };

  componentDidMount() {
    const { initialFile } = this.props;
    if (initialFile) {
      this.setState({
        file: initialFile,
        hideButton: true,
      });
    }
  }

  getHasError = () => this.state.hasError;

  validateFile = async (
    event: Object,
    fileData: ?TFileDataType
  ): Promise<boolean> => {
    const { imageDimensionRestriction, maxSize, t } = this.props;
    const isImage = fileData && fileData.contentType.includes('image');
    let result = true;
    if (isImage && imageDimensionRestriction) {
      const imageDimensions = await getImageDimensions(fileData);
      if (
        !validateImageDimensions(imageDimensions, imageDimensionRestriction)
      ) {
        result = false;
        this.triggerError(
          event,
          t('notifications:imageRestrictions', {
            restrictions: JSON.stringify(imageDimensionRestriction),
          })
        );
      }
    }
    if (maxSize && fileData && fileData.size && fileData.size > maxSize) {
      result = false;
      this.triggerError(
        event,
        t('notifications:bigFile', { maxSize: maxSize / 1000 })
      );
    }
    return result;
  };

  onChange = async (e: Object) => {
    this.setState({ hasError: false });
    let { getFileData } = this.props;
    if (!getFileData) {
      getFileData = getFileDataUtil;
    }
    const file = e.target.files[0];
    try {
      // $FlowFixMe
      const fullFileData = await getFileData(file);
      this.triggerFileChange(file, fullFileData, e);
    } catch (e) {
      console.error(e, 'Could not get file data');
    }
  };

  onChangeByUrl = (
    fileName: string,
    imageData: { size: number, data: string, contentType: string }
  ) => {
    this.triggerFileChange({ name: fileName }, imageData, {});
  };

  triggerFileChange = async (file: Object, fullFileData: Object, e: Object) => {
    const fileData = omit(fullFileData, ['size']);
    const isValid = await this.validateFile(e, fullFileData);
    if (isValid) {
      this.setState({ file, hideButton: true });
      this.props.onFile(fileData, file);
    }
  };

  triggerError = (e: Object, message: string) => {
    this.onRemove();
    this.setState({ hasError: true });
    commonActions.showApiError(message);
  };

  onRemove = () => {
    this.setState({ file: null, hideButton: false });
    this.props.onFile(null);
  };

  toggleUrlDialog = () => {
    this.setState(state => ({
      urlDialogShown: !state.urlDialogShown,
    }));
  };

  render() {
    const { file, hideButton, urlDialogShown } = this.state;
    const { t, canAddByUrl } = this.props;

    return (
      <div>
        <UrlDialog
          t={t}
          dialogProps={{
            isShown: urlDialogShown,
            handleCancelClick: this.toggleUrlDialog,
          }}
          onChange={this.onChangeByUrl}
        />
        {hideButton ? (
          <FileInfoWrap>
            <FileName>{file && file.name}</FileName>
            <DeleteFile onClick={this.onRemove}>
              {t('common:delete')}
            </DeleteFile>
          </FileInfoWrap>
        ) : (
          <FileButtonsWrapper>
            {canAddByUrl && (
              <FileButton onClick={this.toggleUrlDialog}>
                {t('common:enterUrl')}
              </FileButton>
            )}
            <FileInputBtnWrapper>
              <FileInputButton
                accept="image/png, image/jpeg"
                type="file"
                onChange={this.onChange}
              />
              {t('common:selectPhoto')}
            </FileInputBtnWrapper>
          </FileButtonsWrapper>
        )}
      </div>
    );
  }

}

export default FileInput;
