// @flow

import React, { Component } from 'react';
import { StyledPanel, StyledPanelWrapper } from './elements';

class Panel extends Component<any> {

  render() {
    return (
      <StyledPanelWrapper>
        <StyledPanel {...this.props}>{this.props.children}</StyledPanel>
      </StyledPanelWrapper>
    );
  }

}

export default Panel;
