import styled from 'styled-components';

export const Wrapper = styled.div`
  .vertical-centered-box {
    position: absolute;
    width: 100%;
    height: calc(100% - 90px);
    text-align: center;
  }
  svg {
    position: relative;
  }
  .vertical-centered-box:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
  .vertical-centered-box .content {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 0;
  }
  .loader-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px rgba(71, 45, 107, 0.3);
    margin-left: -60px;
    margin-top: -60px;
    background: #fff;
  }
  .loader-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    transform-origin: 60px 60px;
    animation: rotate 1.2s infinite linear;
  }
  .loader-line-mask .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #472d6b;
  }
  @-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes rotate {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-o-keyframes rotate {
    0% {
      -o-transform: rotate(0deg);
    }
    100% {
      -o-transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  [not-existing] {
    zoom: 1;
  }
  @-webkit-keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
  }
  @-moz-keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
  }
  @-o-keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
  }
  @keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
  }
  [not-existing] {
    zoom: 1;
  }
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  [not-existing] {
    zoom: 1;
  }
`;
