import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import QRCode from 'qrcode';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { CompaniesStore, DoddleStores } from 'stores';
import {
  BlockCol,
  BlockTitle,
  DashboardWrapper,
  EmailQrCodeButton,
  Header,
  InstructionsList,
  QRCodeWrapper,
  StyledPanelWrapper,
  OpenHoursItem,
  StoreName,
  ContactInfo,
  ViewOnMapButton,
  RightInfo,
  InfoLabel,
  RightInfoMobile,
  ContactInfoRow,
  RightInfoRow,
} from './elements';
import { Col, Row } from 'antd';
import stores from 'stores';
import Loader from '_common/components/Loader';
import { get, trim } from 'lodash';
import EmailQRCodeDialog from './EmailQRCodeDialog';
import { AVAILABILITY_CONFIG } from '_common/constants/stores';
import {
  computeOpeningHours,
  getStoreStatusElement,
} from '_common/utils/utils';
import { FullWidthLink } from '_common/components/PlainStyles';
import { TestConnectivityButton } from 'doddleStores/doddleStoresDetails/pages/StoreDashboard/elements';
import ConnectivityResultsDialog from 'doddleStores/doddleStoresDetails/pages/StoreDashboard/ConnectivityResultsDialog';
import { useDidUpdateEffect } from '_common/hooks';

type Props = {
  i18n: Object,
  t: TTranslateFunc,
  doddleStores: DoddleStores,
  companiesStore: CompaniesStore,
};

const StoreDashboard = ({
  i18n: { language },
  t,
  doddleStores: { loadSingleStore, dashboardStore },
  companiesStore: { isConnectivityCheckAvailable },
}: Props) => {
  const emailDialogRef = useRef(null);

  const [qrCodeUrl, setQRCodeUrl] = useState();
  const [isStoreLoading, setIsStoreLoading] = useState(true);
  const [isConnectivityDialogOpen, setConnectivityDialogOpen] = useState(false);

  useEffect(() => {
    setIsStoreLoading(true);
    const { storeStaff: storeId } = get(stores.authStore, 'getLoggedUserField');
    QRCode.toDataURL(storeId[0])
      .then((qrCodeUrl) => {
        setQRCodeUrl(qrCodeUrl);
      })
      .catch((error) => {
        console.warn(error);
      });

    loadSingleStore(storeId, {
      sensitiveDataFlag: false,
      saveForDashboard: true,
    });
  }, [loadSingleStore]);

  useDidUpdateEffect(() => {
    setIsStoreLoading(false);
  }, [dashboardStore]);

  const getAddress = (): Object => {
    if (dashboardStore && dashboardStore.place) {
      const {
        place: { address },
      } = dashboardStore;
      if (address) {
        const { line1, line2, postcode, town } = address;
        const addressLines = trim(
          `${line1 ? line1 : ''}, ${line2 ? line2 + ',' : ''} ${
            town ? town + ',' : ''
          }`.trim(),
          ','
        );
        const postcodeLine = trim(`${postcode ? postcode : ''}`.trim(), ',');
        return { addressLines, postcodeLine };
      }
    }
  };

  const storeAddress: {
    addressLines: string,
    postcodeLine: string,
    // eslint-disable-next-line
  } = useMemo(() => getAddress(), [dashboardStore]);

  const openDialog = () => {
    if (emailDialogRef.current) {
      emailDialogRef.current.open();
    }
  };

  const infoLabelWidth = language === 'ja' ? 110 : 90;

  const toggleConnectivityDialog = (visibility: boolean) => () =>
    setConnectivityDialogOpen(visibility);

  return isStoreLoading || !dashboardStore ? (
    <Loader />
  ) : (
    <Fragment>
      <EmailQRCodeDialog
        ref={emailDialogRef}
        store={dashboardStore}
        userLogin={get(stores.authStore, 'getLoggedUserField.userId')}
        t={t}
      />
      <ConnectivityResultsDialog
        isShown={isConnectivityDialogOpen}
        isDisabled={false}
        isError={false}
        t={t}
        store={dashboardStore}
        handleCancelClick={toggleConnectivityDialog(false)}
      />
      <Header>
        <Row justify="space-between">
          <Col md={17} sm={24} xs={24}>
            <Row align="middle">
              <StoreName>{dashboardStore.storeName}</StoreName>
              {getStoreStatusElement(dashboardStore.status, t, {
                openingDate: dashboardStore.openingDate,
                closingDate: dashboardStore.closingDate,
                includeStatusString: true,
              })}
            </Row>
            <Row>
              <div>
                <ContactInfoRow>
                  <InfoLabel width={infoLabelWidth}>{t('address')}</InfoLabel>
                  <ContactInfo>{storeAddress.addressLines}</ContactInfo>
                </ContactInfoRow>
                <ContactInfoRow>
                  <InfoLabel width={infoLabelWidth}>{t('postcode')}</InfoLabel>
                  <ContactInfo>{storeAddress.postcodeLine}</ContactInfo>
                </ContactInfoRow>
                {dashboardStore.place.phoneNumber && (
                  <ContactInfoRow>
                    <InfoLabel width={infoLabelWidth}>{t('phone')}</InfoLabel>
                    <ContactInfo>
                      {dashboardStore.place.phoneNumber}
                    </ContactInfo>
                  </ContactInfoRow>
                )}
                {dashboardStore.place.emailAddress && (
                  <ContactInfoRow>
                    <InfoLabel width={infoLabelWidth}>{t('email')}</InfoLabel>
                    <ContactInfo>
                      {dashboardStore.place.emailAddress}
                    </ContactInfo>
                  </ContactInfoRow>
                )}
              </div>
            </Row>
          </Col>
          <Col md={7} sm={24} xs={24}>
            <RightInfo>
              <RightInfoRow>
                <Col>
                  <a
                    href={dashboardStore.place.map}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ViewOnMapButton>{t('viewStoreOnMap')}</ViewOnMapButton>
                  </a>
                  <div>
                    {isConnectivityCheckAvailable && (
                      <TestConnectivityButton
                        onClick={toggleConnectivityDialog(true)}
                      >
                        {t('testConnectivity')}
                      </TestConnectivityButton>
                    )}
                  </div>
                </Col>
              </RightInfoRow>
            </RightInfo>
            <RightInfoMobile>
              <Row justify="space-between">
                <ContactInfo>{dashboardStore.place.emailAddress}</ContactInfo>
                <ContactInfo>{dashboardStore.place.phoneNumber}</ContactInfo>
              </Row>
              <Row>
                <FullWidthLink
                  href={dashboardStore.place.map}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ViewOnMapButton block>{t('viewStoreOnMap')}</ViewOnMapButton>
                </FullWidthLink>
                {isConnectivityCheckAvailable && (
                  <TestConnectivityButton
                    onClick={toggleConnectivityDialog(true)}
                  >
                    {t('testConnectivity')}
                  </TestConnectivityButton>
                )}
              </Row>
            </RightInfoMobile>
          </Col>
        </Row>
      </Header>
      <StyledPanelWrapper>
        <DashboardWrapper>
          <Col xs={24}>
            <Row justify="center">
              <BlockCol xs={24} xl={16}>
                <Row>
                  <Col xs={24} md={14} lg={16} xl={16} xxl={16}>
                    <BlockTitle>{t('loginDetails')}</BlockTitle>
                    <InstructionsList>
                      <li>{t('openStoreApp')}</li>
                      <li>{t('scanQrCode')}</li>
                      <li>{t('enterLoginDetails')}</li>
                      <li>{t('loginCompleted')}</li>
                    </InstructionsList>
                  </Col>
                  <Col xs={24} md={10} lg={8} xl={8} xxl={6}>
                    {qrCodeUrl && (
                      <>
                        <QRCodeWrapper>
                          <img src={qrCodeUrl} alt="qrCode" />
                        </QRCodeWrapper>
                        <EmailQrCodeButton block onClick={openDialog}>
                          {t('emailQrCode')}
                        </EmailQrCodeButton>
                      </>
                    )}
                  </Col>
                </Row>
              </BlockCol>
              <BlockCol xs={24} xl={8}>
                <BlockTitle>{t('openHours')}</BlockTitle>
                {AVAILABILITY_CONFIG.DAYS.map((day: string) => {
                  const hours = computeOpeningHours(
                    dashboardStore.openingHours?.[day],
                    t
                  );
                  return (
                    <OpenHoursItem key={day}>
                      <Col xs={9} md={8}>
                        {t(`common:${day}`)}
                      </Col>
                      <Col xs={12} md={12}>
                        {typeof hours === 'object'
                          ? `${hours.startTime} - ${hours.endTime}`
                          : hours}
                      </Col>
                    </OpenHoursItem>
                  );
                })}
              </BlockCol>
            </Row>
          </Col>
        </DashboardWrapper>
      </StyledPanelWrapper>
    </Fragment>
  );
};

export default compose(
  withTranslation('stores'),
  inject('doddleStores', 'companiesStore'),
  observer
)(StoreDashboard);
