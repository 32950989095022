// @flow

import React, { Component, Fragment } from 'react';
import { TimePickersHolder } from './elements';
import { Checkbox, TimePicker } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { formatOpeningHours } from '_common/utils/utils';

type Props = {
  computedData: {
    startTime: string,
    endTime: string,
  },
  updateParentState: Object => void,
  t: TTranslateFunc,
};

type State = {
  timeStart: moment,
  timeEnd: moment,
  isOpen: boolean,
  fullDay: boolean,
};

class RangeTimePicker extends Component<Props, State> {

  DISPLAY_FORMAT = 'HH:mm';
  SAVE_FORMAT = 'HHmm';
  DEFAULT_VALUE = '00:00';

  constructor(props: Props) {
    super(props);
    const { computedData } = this.props;
    const timeStart = computedData.startTime || this.DEFAULT_VALUE;
    const timeEnd = computedData.endTime || this.DEFAULT_VALUE;

    this.state = {
      fullDay:
        computedData.startTime === '00:00' && computedData.endTime === '23:59',
      isOpen: Boolean(computedData.startTime && computedData.endTime),
      timeStart: moment(timeStart, this.DISPLAY_FORMAT),
      timeEnd: moment(timeEnd, this.DISPLAY_FORMAT),
    };
  }

  updateParentState = () => {
    const { isOpen, timeStart, timeEnd, fullDay } = this.state;

    const {
      timeStartMoment,
      timeEndMoment,
      timeStartFormatted,
      timeEndFormatted,
    } = formatOpeningHours(timeStart, timeEnd, fullDay);

    this.setState({
      timeStart: timeStartMoment,
      timeEnd: timeEndMoment,
    });

    return this.props.updateParentState({
      value: {
        isOpen,
        hours: [[timeStartFormatted, timeEndFormatted]],
      },
    });
  };

  updateIsOpened = ({ target: { checked } }: Object) => {
    this.setState(
      {
        isOpen: checked,
      },
      this.updateParentState
    );
  };

  updateFullDay = ({ target: { checked } }: Object) => {
    this.setState(
      {
        fullDay: checked,
      },
      this.updateParentState
    );
  };

  updateTime = (timeField: string) => (time: Object) => {
    this.setState({ [timeField]: time }, this.updateParentState);
  };

  render() {
    const { timeStart, timeEnd, isOpen, fullDay } = this.state;

    return (
      <Fragment>
        <Checkbox checked={isOpen} onChange={this.updateIsOpened}>
          {this.props.t('open')}
        </Checkbox>
        <Checkbox checked={fullDay} onChange={this.updateFullDay}>
          {this.props.t('stores:hoursCount', { hours: 24 })}
        </Checkbox>
        {isOpen && (
          <TimePickersHolder>
            <TimePicker
              disabled={fullDay}
              value={timeStart}
              format={this.DISPLAY_FORMAT}
              onChange={this.updateTime('timeStart')}
            />{' '}
            :
            <TimePicker
              disabled={fullDay}
              value={timeEnd}
              format={this.DISPLAY_FORMAT}
              onChange={this.updateTime('timeEnd')}
            />
          </TimePickersHolder>
        )}
      </Fragment>
    );
  }

}

export default withTranslation()(RangeTimePicker);
