// @flow

import { Roles } from '_common/constants/acl';
import { STORE_STAFF_SCOPE_TEMPLATE } from '_common/constants/appConfig';

export const SCOPES = {
  ORGANISATION: 'organisation',
  STORE_STAFF: 'storeStaff',
};

type TGenerateScopesConfig = {
  name: $Values<typeof SCOPES>,
  vars: Array<string>,
};

type TScopesConfigMap = Map<$Values<typeof SCOPES>, TGenerateScopesConfig>;
export type TScopesConfig = Array<TGenerateScopesConfig>;

export const EMPTY_TEMPLATES = {
  [SCOPES.STORE_STAFF]: STORE_STAFF_SCOPE_TEMPLATE,
};

const SCOPE_TEMPLATES = new Map([
  [
    SCOPES.STORE_STAFF,
    (storeId: string): string => {
      if (!storeId) throw new Error(`Store info is missing`);
      return `${EMPTY_TEMPLATES[SCOPES.STORE_STAFF]}${storeId}`;
    },
  ],
]);

const ROLE_SCOPES = {
  [Roles.STORE]: [SCOPES.STORE_STAFF],
};

const getScopes = (config: TScopesConfig): Array<string> => {
  const result = [];
  for (const configItem of config) {
    const scopeTpl = SCOPE_TEMPLATES.get(configItem.name);
    if (!scopeTpl) {
      console.warn(`Scope "${configItem.name}" is not found`);
      continue;
    }
    try {
      const scope = scopeTpl(...configItem.vars);
      result.push(scope);
    } catch (e) {
      throw new Error(
        `Couldn't get a scope "${configItem.name}". ${e.message}`
      );
    }
  }

  return result;
};

const getRoleConfig = (
  config: TScopesConfigMap,
  scopeList: Array<string>
): TScopesConfig => {
  const result = [];
  for (const scope of scopeList) {
    const configItem = config.get(scope);
    if (!configItem)
      throw new Error(`Missing required config for the scope "${scope}"`);
    result.push(configItem);
  }
  return result;
};

export default (
  roles: Array<string>,
  config: TScopesConfig,
  existingScopes?: Array<string>
): Array<string> => {
  const configMap: TScopesConfigMap = new Map(
    config.map((item: TGenerateScopesConfig) => [item.name, item])
  );

  let _existingScopes = [];
  if (existingScopes) {
    _existingScopes = existingScopes.map(scope => [scope, scope]);
  }
  const _roles = [...roles];
  if (roles.length) {
    if (roles.includes(Roles.STORE)) {
      const storeStaffTemplate = EMPTY_TEMPLATES[SCOPES.STORE_STAFF];
      _existingScopes = _existingScopes.filter(scope =>
        scope.includes(storeStaffTemplate)
      );
    }
  }

  const result = new Map(_existingScopes);

  for (const role of _roles) {
    const roleScopeList = ROLE_SCOPES[role];
    if (!roleScopeList) continue;
    try {
      const roleConfig = getRoleConfig(configMap, roleScopeList);
      const roleScopes = getScopes(roleConfig);
      for (const scope of roleScopes) {
        result.set(scope, scope);
      }
    } catch (e) {
      throw new Error(
        `Couldn't get scopes for the role "${role}". ${e.message}`
      );
    }
  }

  return Array.from(result.values());
};
