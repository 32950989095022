import styled from 'styled-components';

export const IconWrapper = styled.div`
  width: ${({ width = 20 }) => `${width}px`};
  height: ${({ height = 20 }) => `${height}px`};
  background: url(${({ image }) => image}) center center no-repeat;
  background-size: ${({ size }) => size};
  border-radius: ${({ radius = 'initial' }) => radius};
  flex-shrink: 0;
`;
