import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const FilterItem = styled.div`
  display: inline-block;
  margin: 5px;
  flex: 1;
  min-width: 100px;
  max-width: 400px;
  .ant-calendar-picker-input > .ant-calendar-range-picker-input {
    font-family: sans-serif;
  }
  .ant-picker-large {
    padding: 11px 5px;
    .ant-picker-suffix {
      display: none;
    }
    .ant-picker-input > input {
      font-size: 12px;
    }
  }
`;

export const FilterLabel = styled.span`
  color: #8c8c8c;
  font-size: 12px;
  font-family: ${props => props.theme.fonts.basic};
  font-weight: bold;
  text-transform: uppercase;
  @media only screen and (max-width: 1199px) {
    font-size: 11px;
  }
`;
