// @flow

import React, { Component } from 'react';
import { CompaniesStore } from 'stores';
import {
  Column,
  ColumnsHolder,
  LeftColumn,
} from '_common/components/DetailsPage/elements';
import { getInfoBlock } from '_common/utils/utils';
import { contactBlock, detailsBlock } from './config';
import BrandingBlock from './BrandingBlock';
import { get } from 'lodash';

type Props = {
  companiesStore: CompaniesStore,
};

type State = {
  errors: Object,
};

class CompanyDetailsSetup extends Component<Props, State> {

  state = {
    errors: {},
  };

  setError = (fieldName: string, value: any): void => {
    this.setState({
      errors: {
        [fieldName]: value,
      },
    });
  };

  render() {
    const { companiesStore } = this.props;
    const { errors } = this.state;

    return (
      <ColumnsHolder>
        <LeftColumn>
          {getInfoBlock(
            detailsBlock(
              companiesStore.getCachedUserCompaniesList,
              errors,
              this.setError,
              {
                canEditOnbaordingEmailFlag:
                  !companiesStore.singleCompany.sendEmailViaCompany ||
                  companiesStore.singleCompany.sendEmailViaCompany ===
                    companiesStore.singleCompany.companyId,
              }
            ),
            false,
            companiesStore
          )}
          <BrandingBlock
            companyId={get(companiesStore, 'singleCompany.companyId')}
            updateCompanyPhotos={companiesStore.updateCompanyPhotos}
            deleteCompanyPhotos={companiesStore.deleteCompanyPhotos}
            logos={get(companiesStore, 'singleCompany.logos')}
          />
        </LeftColumn>
        <Column>
          {getInfoBlock(
            contactBlock(errors, this.setError),
            true,
            companiesStore
          )}
        </Column>
      </ColumnsHolder>
    );
  }

}

export default CompanyDetailsSetup;
