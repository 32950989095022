// @flow

import React, { Component } from 'react';
import { Col, Radio, Row } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import {
  ButtonNext,
  FixedWidthRow,
  RadioGroupWrapper,
  RadioWrapper,
  RowWrapper,
  SectionWrapper,
  SeparationLine,
  StyledRadio,
  TabTitle,
} from '_common/components/TabsForm/elements';
import { Input, InputLabel, Select } from '_common/components';
import { get, omit } from 'lodash';
import { COLLECTION_ID_CHECK_TYPE_OPTIONS } from '_common/constants/companies';
import {
  cleanObject,
  localizeOptions,
  normalizeNumber,
} from '_common/utils/utils';
import { CompaniesStore } from 'stores';
import { onSelectFromDropdown } from '_common/utils/utils';
import { withTranslation } from 'react-i18next';

type Props = {|
  form: Form,
  onNextTab: string => void,
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
|};

class Collections extends Component<Props> {

  onSubmit = e => {
    e.preventDefault();

    const { form, onNextTab } = this.props;

    form.validateFields(err => {
      if (!err) {
        onNextTab('config');
      }
    });
  };

  getFormattedData = () => {
    const { form } = this.props;
    let data = null;

    form.validateFields((err, values) => {
      if (err) return;

      const { collectionsEnabled } = values;
      if (collectionsEnabled) {
        data = omit(values, ['collectionsEnabled']);
        if (data.sendEmailViaCompany) {
          data.emailConfig = undefined;
        }
        if (!get(data, 'smsConfig.sendSMS')) {
          data.smsConfig = { sendSMS: false };
        }
      } else {
        data = {};
      }
    });

    cleanObject(data, ['', undefined, null]);

    return data;
  };

  render() {
    const { companiesStore, form, t } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const collectionsEnabled = getFieldValue('collectionsEnabled');
    const isSendEmailViaCompanySet = !!getFieldValue('sendEmailViaCompany');
    const isSendSMSEnabled =
      collectionsEnabled && getFieldValue('smsConfig.sendSMS');
    const isEmailSettingsEnabled =
      !isSendEmailViaCompanySet && collectionsEnabled;

    return (
      <Form onSubmit={this.onSubmit}>
        <Row>
          <SectionWrapper marginBottom={15}>
            <RowWrapper>
              <FixedWidthRow width={190}>
                <InputLabel>{t('collectionsEnabled')}</InputLabel>
              </FixedWidthRow>
              <FixedWidthRow width={380}>
                <Form.Item>
                  <RadioGroupWrapper>
                    {getFieldDecorator('collectionsEnabled', {
                      initialValue: false,
                    })(
                      <Radio.Group>
                        <RadioWrapper>
                          <StyledRadio value={false}>
                            {t('common:no')}
                          </StyledRadio>
                        </RadioWrapper>
                        <RadioWrapper>
                          <StyledRadio value>{t('common:yes')}</StyledRadio>
                        </RadioWrapper>
                      </Radio.Group>
                    )}
                  </RadioGroupWrapper>
                </Form.Item>
              </FixedWidthRow>
            </RowWrapper>
          </SectionWrapper>
        </Row>
        <Row gutter={[16, 24]}>
          <Col span={12}>
            <SectionWrapper>
              <TabTitle>{t('checks')}</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('signatureRequired')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator('signatureRequired', {
                        initialValue: false,
                      })(
                        <Radio.Group disabled={!collectionsEnabled}>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:yes')}</StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('requiredCollectionIdCheckType')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('requiredCollectionIdCheckType')(
                      <Select
                        isDisabled={!collectionsEnabled}
                        hasEmptyOption
                        defaultToEmpty
                        emptyOptionLabel={t('common:none')}
                        onSelectOption={onSelectFromDropdown(
                          form,
                          'requiredCollectionIdCheckType'
                        )}
                        options={localizeOptions(
                          COLLECTION_ID_CHECK_TYPE_OPTIONS,
                          t
                        )}
                        inputPlaceholder={t('common:select')}
                        selectPlaceholder=""
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('fixedParcelPrice')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('fixedParcelPrice', {
                      normalize: normalizeNumber,
                    })(
                      <Input
                        disabled={!collectionsEnabled}
                        type="number"
                        placeholder={t('enterFixedParcelPrice')}
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
          <Col span={12}>
            <SectionWrapper>
              <TabTitle>{t('days')}</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('deadlineDays')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('deadlineDays', {
                      initialValue: 7,
                      rules: [
                        {
                          required: collectionsEnabled,
                          message: t('inputDaysToCollect'),
                        },
                      ],
                      normalize: normalizeNumber,
                    })(
                      <Input
                        type="number"
                        disabled={!collectionsEnabled}
                        placeholder={t('enterDaysToCollect')}
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('deadlineDaysIncludeWeekends')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator('deadlineDaysIncludeWeekends', {
                        initialValue: false,
                      })(
                        <Radio.Group disabled={!collectionsEnabled}>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:yes')}</StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('deadlineDaysIncludeHolidays')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator('deadlineDaysIncludeHolidays', {
                        initialValue: false,
                      })(
                        <Radio.Group disabled={!collectionsEnabled}>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:yes')}</StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <SectionWrapper>
            <TabTitle>{t('communications')}</TabTitle>
          </SectionWrapper>
          <Col span={12}>
            <SectionWrapper>
              <TabTitle>{t('common:email')}</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('sendEmail')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator('emailConfig.sendEmail', {
                        initialValue: true,
                      })(
                        <Radio.Group disabled={!isEmailSettingsEnabled}>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:yes')}</StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('suppressOrderConfirmation')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator(
                        'emailConfig.suppressOrderConfirmation',
                        {
                          initialValue: true,
                        }
                      )(
                        <Radio.Group disabled={!isEmailSettingsEnabled}>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:no')}</StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:yes')}
                            </StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('dwellEmailFrequencyDays')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('emailConfig.dwellEmailFrequencyDays', {
                      normalize: normalizeNumber,
                    })(
                      <Input
                        disabled={!isEmailSettingsEnabled}
                        min={1}
                        placeholder={t('enterCollReminder')}
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('sendEmailViaCompany')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('sendEmailViaCompany')(
                      <Select
                        isDisabled={!collectionsEnabled}
                        onSelectOption={onSelectFromDropdown(
                          form,
                          'sendEmailViaCompany'
                        )}
                        options={companiesStore.getCachedUserCompaniesList}
                        inputPlaceholder={t('common:select')}
                        selectPlaceholder=""
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
          <Col span={12}>
            <SectionWrapper>
              <TabTitle>{t('sms')}</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('sendSMS')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator('smsConfig.sendSMS', {
                        initialValue: false,
                      })(
                        <Radio.Group disabled={!collectionsEnabled}>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:yes')}</StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('sendSMSFrom')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('smsConfig.sendSMSFrom')(
                      <Input
                        disabled={!isSendSMSEnabled}
                        min={1}
                        placeholder={t('enterFromName')}
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('SMSTemplate')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('smsConfig.SMSTemplate', {
                      rules: [
                        {
                          max: 280,
                          message: t('messageMaxLength', { count: 280 }),
                        },
                      ],
                    })(
                      <Input
                        disabled={!isSendSMSEnabled}
                        min={1}
                        placeholder={t('enterMessage')}
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
        </Row>
        <SeparationLine />
        <ButtonNext htmlType="submit">{t('common:next')}</ButtonNext>
      </Form>
    );
  }

}

export default compose(
  inject('companiesStore'),
  withTranslation('company', { withRef: true }),
  Form.create(),
  observer
)(Collections);
