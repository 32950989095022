// @flow strict

import links from '_common/routes/urls';
import ParcelDetails from '../pages/index';
import ParcelReturn from '../pages/ParcelReturn/index';
import ParcelCollection from '../pages/ParcelCollection';

export default [
  {
    path: links.parcelCollection,
    component: ParcelDetails(
      ParcelCollection,
      {
        headerDataPath: 'singleCollection.labelValue',
        rawDataPath: 'singleCollection.eventHistory',
        logisticDataPath: 'singleCollection.logisticsData.eventHistory',
        namePath: 'singleCollection.customer.name',
      },
      'collection'
    ),
  },
  {
    path: links.parcelReturn,
    component: ParcelDetails(
      ParcelReturn,
      {
        headerDataPath: 'singleReturn.labelValue',
        rawDataPath: 'singleReturn.eventHistory',
        logisticDataPath: 'singleReturn.logisticsData.eventHistory',
        namePath: 'singleReturn.customer.name',
      },
      'return'
    ),
  },
];
