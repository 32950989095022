import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border: 2px solid rgba(0, 0, 0, 0.12);
  padding: ${(props) => props.theme.padding.header.vertical}px
    ${(props) => props.theme.padding.header.horizontal}px;
  display: flex;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  margin-right: 24px;
  height: 90px;
  display: flex;
  align-items: center;
  padding: 15px 0 !important;
  padding: ${(props) => props.theme.padding.menuItem.vertical}px 0;
  @media only screen and (max-width: 1099px) {
    margin-right: 15px;
  }
`;

export const ActionItem = styled.div`
  padding: 5px 0;
  width: 100%;
`;

export const LogoutDropdownTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.book};
  font-size: 14px;
  text-align: center;
  color: #6a6b6e;
`;

export const SelectLanguageWrapper = styled.div`
  width: 230px;
  height: 280px;
  position: absolute;
  top: 0;
  right: 20px;
  border: 1px solid #c6c7c8;
  background-color: #fff;
  z-index: 100;
`;

export const LanguageItem = styled.div`
  height: auto !important;
  border-top: 1px solid rgba(151, 151, 151, 0.25);
  border-bottom: 1px solid rgba(151, 151, 151, 0.25);
  margin: 0 3px 0 3px !important;
  font-family: ${(props) => props.theme.fonts.basic};
  color: #6a6b6e;
  img {
    margin-right: 20px;
    height: 25px;
    width: 27px;
    border-radius: 50%;
    border: 1px solid #c6c7c8;
  }
  &:last-child {
    border-top: 0;
  }
`;

export const BackButton = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  padding: 8px 12px !important;
  height: auto !important;
`;

export const ActionsDropdownWrapper = styled.div`
  padding: ${(props) => props.theme.padding.menuItem.vertical}px 0;
  @media only screen and (max-width: 576px) {
    display: flex;
    align-items: center;
  }
`;

export const Block = styled.span`
  width: 44px;
  height: 36px;
  display: inline-block;
  border-radius: 2px;
  padding-top: 5px;
  margin-right: 15px;
  background-color: #75b7b6;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
`;
