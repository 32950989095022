import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 60%;
  }
`;
