// @flow

import React from 'react';
import Papa from 'papaparse';
import moment from 'moment';
import { STORE_ACTIVATION_DATE_FORMAT } from '_common/constants/appConfig';
import { BULK_ACTIONS_EXAMPLE_FILE_CONFIG } from '_common/constants/stores';
import CSVDialog from '_common/components/CSVDialog';
import { withTranslation } from 'react-i18next';

type Props = {
  resetUpdateStoresProgress: () => void,
  approveProgress: number,
  storesBulkApprove: (
    Array<{ storeId: string, activationDate?: Object }>
  ) => Promise<number>,
  dialogProps: TDialogProps,
  reloadStores: () => void,
  t: TTranslateFunc,
};

type State = {
  headerText: string,
  triggerError: boolean,
  error: string,
};

class BulkApproveDialog extends React.Component<Props, State> {

  initialState = {
    error: '',
    headerText: '',
    triggerError: false,
  };

  state = { ...this.initialState };

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      dialogProps: { isShown },
    } = this.props;
    if (prevProps.dialogProps.isShown !== isShown) {
      this.setState({
        ...this.initialState,
      });
    }
  }

  triggerError = (error: string) => {
    this.setState({
      error,
      triggerError: !this.state.triggerError,
    });
  };

  parseCSV = async (content: string) => {
    const { storesBulkApprove, reloadStores, t } = this.props;
    const parseOptions = {
      header: true,
      skipEmptyLines: true,
      // In CSV schema we have 'approved?' it's better to remove '?'
      transformHeader: header => header.replace(/\?/g, ''),
    };
    content = content.replace(/sep.+(\n|\r\n|\r)/, '');
    const { data: parsedStores } = Papa.parse(content, parseOptions);
    if (parsedStores.length) {
      const storesToApprove = [];
      for (const store of parsedStores) {
        const {
          storeId,
          companyId,
          approved,
          activationDate: rawActivationDate,
        } = store;
        if (storeId && companyId && approved.toLowerCase() === 'yes') {
          const formattedDate = moment(
            rawActivationDate.replace(/[="]+/g, ''),
            STORE_ACTIVATION_DATE_FORMAT,
            true
          );
          const activationDate = formattedDate.isValid()
            ? formattedDate
            : undefined;
          storesToApprove.push({ storeId, companyId, activationDate });
        }
      }
      if (storesToApprove.length) {
        try {
          const amountApproved = await storesBulkApprove(storesToApprove);
          if (amountApproved > 0) {
            this.setState({
              headerText: t('bulkApproveSuccess', { count: amountApproved }),
            });
          } else {
            this.triggerError(t('nothingToApprove'));
          }
        } catch (e) {
          console.error(e);
          this.triggerError(t('common:unexpectedError'));
        } finally {
          reloadStores();
        }
      } else {
        this.triggerError(t('nothingToApprove'));
      }
    } else {
      this.triggerError(t('uploadFailed'));
    }
  };

  render() {
    const {
      dialogProps,
      approveProgress,
      resetUpdateStoresProgress,
    } = this.props;
    const { error, triggerError, headerText } = this.state;

    return (
      <CSVDialog
        headerText={headerText}
        dialogProps={dialogProps}
        progress={approveProgress}
        parseCSVAction={this.parseCSV}
        triggerError={triggerError}
        error={error}
        resetProgress={resetUpdateStoresProgress}
        exampleFileConfig={BULK_ACTIONS_EXAMPLE_FILE_CONFIG.PROPOSED_STORES}
      />
    );
  }

}

export default withTranslation('stores')(BulkApproveDialog);
