// @flow

import { apiClient, atsClient } from '_common/api/clients/clients';

const createCompany = async (data: Object): Promise<TCompany> => {
  const response = await atsClient.post('/companies', data);

  return response.data.resource;
};

const updateCompanyPhotos = async (
  companyId: string,
  data: TCompanyLogosType
): Promise<TCompany> => {
  const params = {
    method: 'companies.uploadPhotos',
    params: JSON.stringify({ companyId }),
  };
  const response = await atsClient.post(`/proxy-api`, data, { params });
  return response.data.resource;
};

const deleteCompanyPhotos = async (
  companyId: string,
  data: { photosToDelete: Array<string> }
): Promise<TCompany> => {
  const params = {
    params: JSON.stringify({ companyId }),
    method: 'companies.deletePhotos',
  };
  const response = await atsClient.delete(`proxy-api`, {
    data,
    params,
  });
  return response.data.resource;
};

const updateCompany = async (
  companyId: string,
  data: Object
): Promise<TCompany> => {
  const params = {
    params: JSON.stringify({ companyId }),
    method: 'companies.update',
  };
  const response = await atsClient.patch(`/proxy-api`, data, { params });
  return response.data.resource;
};

const getSubCompanies = async (companyId: string): Promise<Array<TCompany>> => {
  const response = await apiClient.get(
    `/v1/companies/${companyId}/subcompanies`
  );

  return response.data.resources;
};

const getCompany = async (companyId: string): Promise<TCompany> => {
  const response = await atsClient.get(`companies/${companyId}/`);

  return response.data.resource;
};

const toggleOnboardingEmail = (
  companyId: string,
  enabled: boolean
): Promise<boolean> => {
  return atsClient.post(`companies/${companyId}/toggle-onboarding-email`, {
    enabled,
  });
};

export default {
  getCompany,
  getSubCompanies,
  createCompany,
  updateCompanyPhotos,
  deleteCompanyPhotos,
  updateCompany,
  toggleOnboardingEmail,
};
