// @flow

import React, { Fragment } from 'react';
import { compose } from 'recompose';
import {
  Divider,
  Header,
  PhotosHolder,
} from '_common/components/ImageUploadBlock/elements';
import ImageUploadBlock from '_common/components/ImageUploadBlock';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import doddleStoresActions from 'doddleStores/actions';
import DoddleStoresStore from '_common/stores/doddleStoresStore';
import commonActions from '_common/actions';
import { Roles } from '_common/constants/acl';
import { withTranslation } from 'react-i18next';

const DoddleStoreDetailsPhotos = ({
  doddleStores,
  t,
}: {
  doddleStores: DoddleStoresStore,
  t: TTranslateFunc,
}) => {
  const id = get(doddleStores, 'singleStore.storeId');
  return (
    <Fragment>
      <Header>{t('photos')}</Header>
      <PhotosHolder>
        <Divider />
        <ImageUploadBlock
          hasPermissions={commonActions.canAccessFeature({
            roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
          })}
          src={get(doddleStores, 'singleStore.place.photos.default')}
          caption={t('common:defaultImage')}
          onReplace={dataObj =>
            doddleStoresActions.updateStorePhotos(id, { default: dataObj })
          }
        />
        <Divider />
        <ImageUploadBlock
          hasPermissions={commonActions.canAccessFeature({
            roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
          })}
          caption={t('common:thumbnailImage')}
          isSized
          src={get(doddleStores, 'singleStore.place.photos.thumbnail')}
          onReplace={dataObj =>
            doddleStoresActions.updateStorePhotos(id, { thumbnail: dataObj })
          }
        />
        <Divider />
        <ImageUploadBlock
          hasPermissions={commonActions.canAccessFeature({
            roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
          })}
          caption={t('common:wideImage')}
          src={get(doddleStores, 'singleStore.place.photos.wide')}
          onReplace={dataObj =>
            doddleStoresActions.updateStorePhotos(id, { wide: dataObj })
          }
        />
        <Divider />
      </PhotosHolder>
    </Fragment>
  );
};

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  observer
)(DoddleStoreDetailsPhotos);
