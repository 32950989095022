import React, { Fragment } from 'react';
import {
  NotificationItem,
  NotificationsWrapper,
  NotificationLink,
  NotificationAction,
} from './elements';
import { compose } from 'recompose';
import { CloseOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { UIStore } from 'stores';
import { omit } from 'lodash';
import { toCamelCase } from '_common/utils/utils';
import { withTranslation } from 'react-i18next';

type Props = {
  style?: Object,
  uiStore: UIStore,
  t: TTranslateFunc,
};

const Notifications = React.forwardRef(
  ({ style = {}, uiStore, t }: Props, ref) => {
    let notificationTimers = {};

    const dismiss = (id: any) => () => {
      notificationTimers = omit(notificationTimers, id);
      uiStore.removeNotification(id);
    };

    const renderNotification = ({
      id,
      duration,
      message,
      type,
      link,
      linkText,
      action,
      actionText,
      subData,
    }: TNotification) => {
      if (!notificationTimers.hasOwnProperty(id)) {
        notificationTimers[id] = setTimeout(dismiss(id), duration * 1000);
      }

      const camelMessage = toCamelCase(message);

      const localizedMessage = t([camelMessage, message], subData);

      return (
        <NotificationItem key={camelMessage} type={type}>
          {localizedMessage}
          {link && linkText && (
            <Fragment>
              &nbsp;
              <NotificationLink to={link}>
                {t(toCamelCase(linkText))}
              </NotificationLink>
            </Fragment>
          )}
          {action && actionText && (
            <Fragment>
              &nbsp;
              <NotificationAction onClick={() => action()}>
                {t(toCamelCase(actionText))}
              </NotificationAction>
            </Fragment>
          )}
          <CloseOutlined onClick={dismiss(id)} />
        </NotificationItem>
      );
    };

    return (
      <NotificationsWrapper style={style}>
        {uiStore.notifications.map(renderNotification)}
      </NotificationsWrapper>
    );
  }
);

export default compose(
  withTranslation('notifications'),
  inject('uiStore'),
  observer
)(Notifications);
