// @flow strict

import AuthStore from '_common/stores/authStore';
import ParcelsStore from 'parcels/stores/parcelsStore';
import DoddleStores from '_common/stores/doddleStoresStore';
import UIStore from '_common/stores/uiStore';
import UsersStore from 'users/stores/usersStore';
import ACLStore from '_common/stores/aclStore';
import CompaniesStore from '_common/stores/companiesStore';
import DashboardStore from 'dashboard/stores/dashboardStore';
import PromotionsStore from '_common/stores/promotionsStore';

export {
  AuthStore,
  ParcelsStore,
  DoddleStores,
  UsersStore,
  UIStore,
  ACLStore,
  DashboardStore,
  CompaniesStore,
  PromotionsStore,
};

const authStore = new AuthStore();
const parcelsStore = new ParcelsStore();
const doddleStores = new DoddleStores();
const usersStore = new UsersStore();
const uiStore = new UIStore();
const aclStore = new ACLStore();
const dashboardStore = new DashboardStore();
const companiesStore = new CompaniesStore();
const promotionsStore = new PromotionsStore();

export default {
  authStore,
  companiesStore,
  promotionsStore,
  parcelsStore,
  doddleStores,
  usersStore,
  uiStore,
  aclStore,
  dashboardStore,
};
