// @flow

import React, { Component, createRef } from 'react';
import { Row, Radio, Col, Form } from 'antd';
import {
  TabTitle,
  RowWrapper,
  SeparationLine,
  ButtonNext,
  FixedWidthRow,
  RadioWrapper,
  StyledRadio,
  RadioGroupWrapper,
  SectionWrapper,
} from '_common/components/TabsForm/elements';
import { InputLabel, Input, Select, Button } from '_common/components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { get, omit, debounce, isEmpty } from 'lodash';
import AddressesModal from '_common/components/AddressesModal';
import {
  getStoreAddressFromLoqateAddress,
  localizeOptions,
  normalizeNumber,
  onSelectFromDropdown,
  ruleValidator,
  validatePhoneNumber,
  generateGoogleMapsLink,
} from '_common/utils/utils';
import { ACLStore, AuthStore, CompaniesStore } from 'stores';
import { LOCATION_TYPE_OPTIONS } from '_common/constants/stores';
import { FLAG_OPTIONS } from '_common/constants/appConfig';

type Props = {|
  onNextTab: (string) => void,
  doddleStores: any,
  authStore: AuthStore,
  aclStore: ACLStore,
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
|};

type State = {|
  checkingStoreName: boolean,
|};

class General extends Component<Props, State> {

  formRef = createRef();

  state = {
    checkingStoreName: false,
  };

  componentDidMount() {
    this.props.doddleStores.loadCountriesConfig();
  }

  onFinish = () => {
    this.props.onNextTab('services');
  };

  getFormattedData = () => {
    let data = null;
    const form = this.formRef.current;
    if (form) {
      const values = form.getFieldsValue();
      const { place } = values;
      const omittedValues = omit(values, [
        'isDemo',
        'geo',
        'includeInOnboardingFeed',
      ]);
      data = {
        ...omittedValues,
        isDemo: !!values.isDemo,
        includeInOnboardingFeed: !!values.includeInOnboardingFeed,
        geo: {
          lat: isNaN(Number(values.geo.latitude))
            ? 0
            : Number(values.geo.latitude),
          lon: isNaN(Number(values.geo.longitude))
            ? 0
            : Number(values.geo.longitude),
        },
        place: {
          ...place,
          address: {
            ...place.address,
            postcode: place.address.postcode
              ? place.address.postcode
              : undefined,
            town: place.address.town ? place.address.town : undefined,
          },
        },
      };
    }

    return data;
  };

  validateExistingStoreValue = (finFuncName, errMsg) =>
    debounce((rule, value, callback) => {
      if (this.props.doddleStores[finFuncName](value)) {
        callback(errMsg);
      }

      callback();
    }, 1000);

  validateLocation = (rule, value, callback) => {
    const isLatitude = rule.fullField === 'geo.latitude';
    const message = this.props.t('invalidCoordinates');
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      callback(message);
    }

    if (isLatitude && (numberValue > 90 || numberValue < -90)) {
      callback(message);
    }

    if (!isLatitude && (numberValue > 180 || numberValue < -180)) {
      callback(message);
    }

    callback();
  };

  addressModalCallback = async (data?: TAddressModalData) => {
    const { doddleStores } = this.props;
    const form = this.formRef.current;

    if (form && data && data.loadAddress) {
      const [address, geoData] = await Promise.all([
        doddleStores.retrieveAddress(data.id),
        doddleStores.getGeoByLocation(data.country, data.label),
      ]);
      const locationType = form.getFieldValue('locationType');
      const subcountriesList =
        locationType &&
        !isEmpty(doddleStores.overrideSubCountries[locationType])
          ? doddleStores.overrideSubCountries[locationType]
          : doddleStores.commonSubCountries;
      if (address) {
        const {
          town,
          countrySubdivision,
          country,
          postcode,
          area,
          line1,
          line2,
        } = getStoreAddressFromLoqateAddress(address, subcountriesList);
        const addressObj = {
          place: {
            address: {
              town,
              country,
              postcode,
              area,
              line1,
              line2,
            },
          },
        };
        form.setFieldsValue(addressObj);
        if (countrySubdivision) {
          form.setFieldsValue({
            place: {
              countrySubdivision,
            },
          });
        }
      }
      if (geoData) {
        const geoObj = {
          geo: {
            latitude: geoData.Latitude,
            longitude: geoData.Longitude,
          },
          place: {
            map: generateGoogleMapsLink({
              lat: geoData.Latitude,
              lon: geoData.Longitude,
            }),
          },
        };
        form.setFieldsValue(geoObj);
      }
    } else {
      const resetAddressObj = {
        geo: {
          latitude: '',
          longitude: '',
        },
        place: {
          map: '',
          countrySubdivision: null,
          address: {
            area: '',
            town: '',
            line1: '',
            line2: '',
          },
        },
      };
      if (form) {
        form.setFieldsValue(resetAddressObj);
      }
    }
    doddleStores.setAddressModalVisible(false);
  };

  findAddresses = (): void => {
    const { doddleStores } = this.props;
    const form = this.formRef.current;
    if (form) {
      const { postcode, line1, country } = get(
        form.getFieldValue('place'),
        'address',
        {}
      );
      const findAddressEnabled = country && (postcode || line1);
      if (!doddleStores.isAddressModalVisible && findAddressEnabled) {
        doddleStores.findAddresses({ postcode, country, address: line1 });
        doddleStores.setAddressModalVisible(true);
      }
    }
  };

  onFormValuesChange = (changedValues, allValues) => {
    const form = this.formRef.current;
    const lat = get(allValues, 'geo.latitude');
    const lon = get(allValues, 'geo.longitude');
    if (form && changedValues.hasOwnProperty('geo')) {
      // Clear google maps url if any coordinate has been cleared
      if (
        Object.values(changedValues.geo).some(
          // $FlowExpectedError
          (coordinate) => coordinate.trim() === ''
        )
      ) {
        form.setFieldsValue({
          place: {
            map: '',
          },
        });
      } else if (lat && lon && lat.trim() && lon.trim()) {
        form.setFieldsValue({
          place: {
            map: generateGoogleMapsLink({ lat: lat.trim(), lon: lon.trim() }),
          },
        });
      }
    }
  };

  renderCountrySubdivisionSelect = () => {
    const { t, doddleStores } = this.props;
    const form = this.formRef.current;
    let options;
    if (form) {
      const locationType = form.getFieldValue('locationType');
      // Some countries have overwritten values for RETURNS_KIOSK locationType
      if (locationType) {
        options = get(
          doddleStores.overrideSubCountries,
          `${locationType}.${form.getFieldValue([
            'place',
            'address',
            'country',
          ])}.subcountries`
        );
      }
      options =
        options ??
        get(
          doddleStores.commonSubCountries,
          `${form.getFieldValue(['place', 'address', 'country'])}.subcountries`,
          []
        );
    }

    return (
      <Form.Item
        name={['place', 'countrySubdivision']}
        rules={[
          {
            required: true,
            message: t('inputCountrySub'),
          },
        ]}
      >
        <Select
          isDisabled={!options || !options.length}
          onSelectOption={onSelectFromDropdown(this.formRef, [
            'place',
            'countrySubdivision',
          ])}
          initialValue={null}
          checkOptions
          options={options ? options : []}
          inputPlaceholder={t('common:select')}
          selectPlaceholder={t('inputCountrySub')}
        />
      </Form.Item>
    );
  };

  renderCountrySelect = () => {
    const { t, doddleStores } = this.props;

    return (
      <Form.Item
        name={['place', 'address', 'country']}
        rules={[
          {
            required: true,
            message: t('inputCountry'),
          },
        ]}
      >
        <Select
          onSelectOption={onSelectFromDropdown(this.formRef, [
            'place',
            'address',
            'country',
          ])}
          options={doddleStores.getCountriesOptions}
          inputPlaceholder={t('common:select')}
          selectPlaceholder={t('inputCountry')}
        />
      </Form.Item>
    );
  };

  renderFindAddressButton = () => {
    const form = this.formRef.current;
    let enabled = false;
    if (form) {
      const address = form.getFieldValue(['place', 'address']);
      if (address) {
        enabled = address.country && (address.postcode || address.line1);
      }
    }
    return (
      <Button disabled={!enabled} onClick={this.findAddresses}>
        {this.props.t('findAddress')}
      </Button>
    );
  };

  render() {
    const { companiesStore, aclStore, t } = this.props;
    const form = this.formRef.current;

    return (
      <Form
        ref={this.formRef}
        onValuesChange={this.onFormValuesChange}
        onFinish={this.onFinish}
      >
        <div>
          <Row>
            <Col span={12}>
              <SectionWrapper>
                <TabTitle>{t('storeDetails')}</TabTitle>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('companyStoreId')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name="companyStoreId">
                      <Input placeholder={t('enterCompanyStoreId')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('companyId')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="companyId"
                      rules={[
                        {
                          required: true,
                          message: t('selectCompanyId'),
                        },
                      ]}
                      initialValue={aclStore.getSelectedCompanyId}
                    >
                      <Select
                        checkOptions
                        checkInitial
                        initialValue={aclStore.getSelectedCompanyId}
                        onSelectOption={onSelectFromDropdown(
                          this.formRef,
                          'companyId'
                        )}
                        options={companiesStore.getCachedUserCompaniesList}
                        inputPlaceholder={t('selectCompany')}
                        selectPlaceholder={t('companyId')}
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('storeName')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="storeName"
                      rules={[
                        {
                          required: true,
                          message: t('inputStoreName'),
                        },
                        {
                          validator: this.validateExistingStoreValue(
                            'haveStoreWithName',
                            t('nameAlreadyTaken')
                          ),
                        },
                      ]}
                    >
                      <Input placeholder={t('enterStoreName')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('externalStoreId')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name="externalStoreId">
                      <Input placeholder={t('enterExternalStoreId')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('locationType')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="locationType"
                      rules={[
                        {
                          required: true,
                          message: t('inputLocationType'),
                        },
                      ]}
                    >
                      <Select
                        onSelectOption={onSelectFromDropdown(
                          this.formRef,
                          'locationType'
                        )}
                        options={localizeOptions(
                          LOCATION_TYPE_OPTIONS,
                          t,
                          'stores'
                        )}
                        inputPlaceholder={t('common:select')}
                        selectPlaceholder={''}
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
              </SectionWrapper>
              <SectionWrapper>
                <TabTitle>{t('contact')}</TabTitle>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('emailAddress')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['place', 'emailAddress']}
                      rules={[
                        {
                          type: 'email',
                          message: t('notifications:invalidEmail'),
                        },
                      ]}
                    >
                      <Input placeholder={t('enterEmail')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('phoneNumber')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['place', 'phoneNumber']}
                      rules={[
                        {
                          validator: ruleValidator(
                            validatePhoneNumber,
                            t('notifications:invalidPhone')
                          ),
                        },
                      ]}
                    >
                      <Input placeholder={t('enterPhone')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
              </SectionWrapper>
            </Col>
            <Col span={12}>
              <SectionWrapper>
                <TabTitle>{t('location')}</TabTitle>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('country')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      shouldUpdate={(prevValues, nextValues) =>
                        prevValues.companyId !== nextValues.companyId
                      }
                    >
                      {this.renderCountrySelect}
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('countrySubdivision')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      shouldUpdate={(prevValues, nextValues) =>
                        get(prevValues, 'place.address.country') !==
                          get(nextValues, 'place.address.country') ||
                        get(prevValues, 'locationType') !==
                          get(nextValues, 'locationType')
                      }
                    >
                      {this.renderCountrySubdivisionSelect}
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('line1')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['place', 'address', 'line1']}
                      rules={[
                        {
                          required: true,
                          message: t('inputAddress'),
                        },
                      ]}
                    >
                      <Input placeholder={t('enterAddress')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('postcode')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name={['place', 'address', 'postcode']}>
                      <Input placeholder={t('enterPostcode')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={570} justify="flex-end">
                    <Form.Item
                      shouldUpdate={(prevValues, nextValues) =>
                        get(prevValues, 'place.address.country') !==
                          get(nextValues, 'place.address.country') ||
                        get(prevValues, 'place.address.postcode') !==
                          get(nextValues, 'place.address.postcode') ||
                        get(prevValues, 'place.address.line1') !==
                          get(nextValues, 'place.address.line1')
                      }
                    >
                      {this.renderFindAddressButton}
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('line2')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name={['place', 'address', 'line2']}>
                      <Input placeholder={t('enterLine2')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('town')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name={['place', 'address', 'town']}>
                      <Input placeholder={t('enterTown')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('area')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['place', 'address', 'area']}
                      rules={[
                        {
                          required: true,
                          message: t('inputArea'),
                        },
                      ]}
                    >
                      <Input placeholder={t('enterArea')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('howToFindUs')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['place', 'howToFindUs']}
                      rules={[
                        {
                          required: true,
                          message: t('inputDirections'),
                        },
                      ]}
                    >
                      <Input placeholder={t('enterDirections')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('lat')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['geo', 'latitude']}
                      rules={[
                        {
                          validator: this.validateLocation,
                        },
                      ]}
                    >
                      <Input placeholder={t('enterLat')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('lon')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['geo', 'longitude']}
                      rules={[
                        {
                          validator: this.validateLocation,
                        },
                      ]}
                    >
                      <Input placeholder={t('enterLon')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('map')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['place', 'map']}
                      rules={[
                        {
                          type: 'url',
                          message: t('notifications:invalidUrl'),
                        },
                      ]}
                    >
                      <Input placeholder={t('enterMap')} />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('restrictedAccess')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name={['place', 'restrictedAccess']}>
                      <Select
                        onSelectOption={onSelectFromDropdown(form, [
                          'place',
                          'restrictedAccess',
                        ])}
                        options={localizeOptions(FLAG_OPTIONS, t)}
                        inputPlaceholder={t('common:select')}
                        selectPlaceholder={''}
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
              </SectionWrapper>
              <SectionWrapper>
                <TabTitle>{t('developer')}</TabTitle>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('isDemo')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="isDemo"
                      initialValue={0}
                      normalize={normalizeNumber}
                    >
                      <RadioGroupWrapper>
                        <Radio.Group>
                          <RadioWrapper>
                            <StyledRadio value={0}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value={1}>
                              {t('common:yes')}
                            </StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      </RadioGroupWrapper>
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('includeInOnboardingFeed')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="includeInOnboardingFeed"
                      initialValue={0}
                      normalize={normalizeNumber}
                    >
                      <RadioGroupWrapper>
                        <Radio.Group>
                          <RadioWrapper>
                            <StyledRadio value={0}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value={1}>
                              {t('common:yes')}
                            </StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      </RadioGroupWrapper>
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
              </SectionWrapper>
            </Col>
          </Row>
          <SeparationLine />
          <ButtonNext htmlType="submit">{t('common:next')}</ButtonNext>
        </div>
        <AddressesModal callback={this.addressModalCallback} />
      </Form>
    );
  }

}

export default compose(
  inject('doddleStores', 'authStore', 'aclStore', 'companiesStore'),
  observer
)(General);
