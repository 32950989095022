import React, { Component, Fragment } from 'react';
import QRCode from 'qrcode';
import { Progress, Row, Col } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Dialog, Button, Input } from '_common/components';
import { HeaderText } from '_common/components/Dialog/elements';
import { ButtonsWrapper, QRCodeWrapper } from './elements';
import { validateEmail } from '_common/utils/utils';
import links from '_common/routes/urls';
import stores from 'stores';
import commonActions from '_common/actions';

type State = {
  qrCodeUrl: ?string,
  isOpened: boolean,
  errorMessage: string,
  store: ?TStore,
  dialogState: string,
  isProcessing: boolean,
  email: string,
};

type Props = {
  t: TTranslateFunc,
};

class QRCodeDialog extends Component<Props, State> {

  initialState = {
    qrCodeUrl: '',
    isOpened: false,
    errorMessage: '',
    store: null,
    dialogState: 'initial',
    isProcessing: false,
    email: '',
  };

  state = { ...this.initialState };

  open = (store: TStore) => {
    const { t } = this.props;
    this.setState({
      isOpened: true,
      store,
    });
    QRCode.toDataURL(store.storeId)
      .then((qrCodeUrl) => {
        this.setState({
          qrCodeUrl,
        });
      })
      .catch((error) => {
        console.warn(error);
        this.setState({
          errorMessage: t('couldNotGetQRCode'),
        });
      });
  };

  renderContent = () => {
    const { qrCodeUrl, errorMessage, store, dialogState, isProcessing, email } =
      this.state;
    const { t } = this.props;
    let qrCodeImageLink = '';
    if (store) {
      const { storeId } = store;
      qrCodeImageLink = `${links.storeQRCodeImage}?storeId=${storeId}`;
    }

    const onEmailClick = () => {
      this.setState({
        dialogState: 'email',
      });
    };

    const changeEmail = ({ target: { value } }: Object): void => {
      this.setState({ email: value });
    };

    return dialogState === 'initial' ? (
      <Row type="flex" justify="center">
        <Fragment>
          {!qrCodeUrl && !errorMessage && (
            <Progress
              trailColor="#e6e7e8"
              strokeColor="#542785"
              percent={100}
              status="active"
              showInfo={false}
            />
          )}
          {qrCodeUrl && (
            <Fragment>
              <QRCodeWrapper>
                <img src={qrCodeUrl} alt="qr code" />
                <ButtonsWrapper>
                  <a
                    href={qrCodeImageLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>{t('viewQRCode')}</Button>
                  </a>
                  <Button onClick={onEmailClick}>{t('emailQRCode')}</Button>
                </ButtonsWrapper>
              </QRCodeWrapper>
            </Fragment>
          )}
        </Fragment>
      </Row>
    ) : (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <Input
              value={email}
              placeholder={t('enterEmail')}
              onChange={changeEmail}
            />
          </Col>
        </Row>
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Fragment>
    );
  };

  handleConfirmEmail = () => {
    const { email, isProcessing, store } = this.state;
    const { t } = this.props;

    if (isProcessing) return;

    if (validateEmail(email) && store.companyId) {
      this.toggleIsProcessing();
      stores.doddleStores
        .sendLoginQRCodeAttached(store.companyId, store.storeId, email)
        .then((result: boolean) => {
          if (result) {
            commonActions.showSuccessMessage(t('qrCodeSent', { email }));
            this.handleCancel();
          } else {
            this.toggleIsProcessing();
          }
        });
    }
  };

  toggleIsProcessing = () => {
    this.setState((state) => ({
      isProcessing: !state.isProcessing,
    }));
  };

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
  };

  renderCustomHeader = () => {
    const { errorMessage, dialogState } = this.state;

    return dialogState === 'initial' ? (
      <Row type="flex" justify="space-between">
        <HeaderText fontSize={30}>
          {errorMessage ? errorMessage : ' '}
        </HeaderText>
        <CloseCircleOutlined
          style={{ fontSize: 30 }}
          onClick={this.handleCancel}
        />
      </Row>
    ) : null;
  };

  renderHeader = () => {
    const { dialogState } = this.state;
    const { t } = this.props;

    return dialogState === 'initial' ? '&nbsp;' : t('enterEmail');
  };

  render() {
    const { isOpened, dialogState, email, isProcessing } = this.state;
    const { t } = this.props;

    const isEmailValid = validateEmail(email);

    return (
      <Dialog
        minHeight={100}
        isShown={isOpened}
        headerText={this.renderHeader()}
        customHeader={this.renderCustomHeader()}
        confirmLabel={t('common:send')}
        isConfirmDisabled={!isEmailValid || isProcessing}
        handleConfirmClick={
          dialogState === 'initial' ? undefined : this.handleConfirmEmail
        }
        displayCancelButton={dialogState !== 'initial'}
        renderContent={this.renderContent}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default QRCodeDialog;
