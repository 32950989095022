// @flow

import React, { Component, createRef } from 'react';
import { Row, Radio, Col, Form } from 'antd';
import {
  TabTitle,
  RowWrapper,
  SeparationLine,
  ButtonNext,
  FixedWidthRow,
  RadioWrapper,
  StyledRadio,
  RadioGroupWrapper,
} from '_common/components/TabsForm/elements';
import { InputLabel, Input, Select } from '_common/components';
import {
  cleanObject,
  emptyValidatorAsync,
  localizeOptions,
  normalizeNumber,
  onSelectFromDropdown,
} from '_common/utils/utils';
import { FLAG_OPTIONS } from '_common/constants/appConfig';

type Props = {
  onNextTab: string => void,
  t: TTranslateFunc,
};

type ServiceType = {
  key: string,
  label: string,
};

class Services extends Component<Props> {

  formRef: Object = createRef();

  SERVICE_TYPES: ServiceType[] = [
    {
      key: 'COLLECTIONS',
      label: 'Orders',
    },
    {
      key: 'RETURNS',
      label: 'Returns',
    },
  ];

  getFormattedData = () => {
    let data = null;
    const form = this.formRef.current;
    if (form) {
      const numberToBooleanFields = [
        'testing',
        'acceptsPaperless',
        'integratedLockers',
      ];
      data = form.getFieldsValue();

      // Convert numbers to booleans
      for (const serviceType: ServiceType of this.SERVICE_TYPES) {
        for (const field of numberToBooleanFields) {
          if (data[serviceType.key][field] !== undefined) {
            data[serviceType.key][field] = !!data[serviceType.key][field];
          }
        }
      }

      cleanObject(data);
    }

    return data;
  };

  onFinish = () => {
    this.props.onNextTab('availability');
  };

  render() {
    const { t } = this.props;
    const form = this.formRef;

    return (
      <Form ref={this.formRef} onFinish={this.onFinish}>
        <Row>
          {this.SERVICE_TYPES.map((serviceType: ServiceType) => (
            <Col span={12} key={serviceType.key}>
              <TabTitle>{t(serviceType.label.toLowerCase())}</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('common:enabled')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <Form.Item
                      name={[serviceType.key, 'enabled']}
                      rules={[
                        {
                          validator: emptyValidatorAsync,
                          message: t('inputEnabled'),
                        },
                      ]}
                    >
                      <Select
                        hasEmptyOption
                        onSelectOption={onSelectFromDropdown(
                          form,
                          [serviceType.key, 'enabled'],
                          true
                        )}
                        options={localizeOptions(FLAG_OPTIONS, t)}
                        inputPlaceholder={t('common:select')}
                        selectPlaceholder={''}
                      />
                    </Form.Item>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('testing')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item
                    name={[serviceType.key, 'testing']}
                    normalize={normalizeNumber}
                  >
                    <RadioGroupWrapper>
                      <Radio.Group>
                        <RadioWrapper>
                          <StyledRadio value={0}>{t('common:no')}</StyledRadio>
                        </RadioWrapper>
                        <RadioWrapper>
                          <StyledRadio value={1}>{t('common:yes')}</StyledRadio>
                        </RadioWrapper>
                      </Radio.Group>
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('integratedLockers')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item
                    name={[serviceType.key, 'integratedLockers']}
                    normalize={normalizeNumber}
                  >
                    <RadioGroupWrapper>
                      <Radio.Group>
                        <RadioWrapper>
                          <StyledRadio value={0}>{t('common:no')}</StyledRadio>
                        </RadioWrapper>
                        <RadioWrapper>
                          <StyledRadio value={1}>{t('common:yes')}</StyledRadio>
                        </RadioWrapper>
                      </Radio.Group>
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('siteId')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item
                    name={[
                      serviceType.key,
                      'integratedLockersConfig',
                      'siteId',
                    ]}
                  >
                    <Input placeholder={t('enterSite')} />
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              {serviceType.key === 'RETURNS' && (
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('acceptsPaperless')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={[serviceType.key, 'acceptsPaperless']}
                      normalize={normalizeNumber}
                    >
                      <RadioGroupWrapper>
                        <Radio.Group>
                          <RadioWrapper>
                            <StyledRadio value={0}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value={1}>
                              {t('common:yes')}
                            </StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      </RadioGroupWrapper>
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
              )}
            </Col>
          ))}
        </Row>
        <SeparationLine />
        <ButtonNext htmlType="submit">{this.props.t('common:next')}</ButtonNext>
      </Form>
    );
  }

}

export default Services;
