// @flow

import axios from 'axios';
import type AxiosInstance from 'axios';

type Config = {
  baseURL: string,
  headers?: {
    [key: any]: any,
  },
  params?: {
    [key: any]: any,
  },
};

export default ({ baseURL, headers, params }: Config): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: headers ? headers : {},
    params: params ? params : {},
  });
};
