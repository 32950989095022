// @flow

import React from 'react';
import {
  ColumnsHolder,
  LeftColumn,
} from '_common/components/DetailsPage/elements';
import { storeAvailabilityBlock } from './config';
import { getInfoBlock } from '_common/utils/utils';
import DoddleStoresStore from '_common/stores/doddleStoresStore';
import { withTranslation } from 'react-i18next';
import { Header } from '_common/components/InfoBlock/elements';
import { Col, Row } from 'antd';
import ExtendedHoursControl from 'doddleStores/components/ExtendedHoursControl';

const DoddleStoreDetailsAvailability = ({
  data,
  t,
}: {
  data: DoddleStoresStore,
  t: TTranslateFunc,
}) => {
  const renderCustomHeader = () => (
    <Header>
      <Row align="middle" justify="space-between">
        <Col sm={24} md={12}>
          {t('storeAvailability')}
        </Col>
        <Col sm={24} md={12} className="text-right">
          <ExtendedHoursControl editMode doddleStoresStore={data} />
        </Col>
      </Row>
    </Header>
  );

  return (
    <ColumnsHolder>
      <LeftColumn>
        {getInfoBlock(
          storeAvailabilityBlock(t, renderCustomHeader()),
          true,
          data
        )}
      </LeftColumn>
    </ColumnsHolder>
  );
};

export default withTranslation('stores')(DoddleStoreDetailsAvailability);
