import React, { Component, Fragment } from 'react';
import { Col, Progress, Row } from 'antd';
import { Dialog, Input } from '_common/components';
import links from '_common/routes/urls';
import { RouterHistory } from 'react-router';

type Props = {
  promotionId?: string,
  history?: RouterHistory,
  t: TTranslateFunc,
  cloneSinglePromotion: Function,
};

type State = {
  title: string,
  isProcessing: boolean,
  isOpened: false,
};

class ClonePromotionDialog extends Component<Props, State> {

  initialState = {
    title: '',
    isProcessing: false,
    isOpened: false,
  };

  state = { ...this.initialState };

  open = () => {
    this.setState({
      isOpened: true,
    });
  };

  validateTitle = () => !!this.state.title.trim();

  handleConfirm = () => {
    const { title, isProcessing } = this.state;
    const { history, cloneSinglePromotion } = this.props;

    if (isProcessing) return;

    this.toggleIsProcessing();

    if (this.validateTitle()) {
      cloneSinglePromotion(title.trim())
        .then((result: boolean) => {
          if (result) {
            this.handleCancel();
            history.push(links.promotions.main);
          } else {
            this.toggleIsProcessing();
          }
        })
        .catch(() => {
          this.toggleIsProcessing();
        });
    }
  };

  toggleIsProcessing = () => {
    this.setState(state => ({
      isProcessing: !state.isProcessing,
    }));
  };

  changeTitle = ({ target: { value } }: Object): void => {
    this.setState({ title: value });
  };

  renderContent = () => {
    const { isProcessing, title } = this.state;

    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <Input
              value={title}
              placeholder={this.props.t('enterTitle')}
              onChange={this.changeTitle}
            />
          </Col>
        </Row>
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Fragment>
    );
  };

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
  };

  render() {
    const { t } = this.props;
    const { isOpened } = this.state;

    return (
      <Dialog
        isShown={isOpened}
        minHeight={320}
        headerText={t('clonePromotionConfirm')}
        confirmLabel={t('common:submit')}
        displayCancelButton
        isConfirmDisabled={!this.validateTitle()}
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default ClonePromotionDialog;
