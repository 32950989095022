// @flow

import React, { Component } from 'react';
import {
  Column,
  ColumnsHolder,
  LeftColumn,
} from '_common/components/DetailsPage/elements';
import {
  boolToWord,
  generateGoogleMapsLink,
  getInfoBlock,
  getStoreAddressFromLoqateAddress,
  getStoreLastEvent,
  validateDate,
} from '_common/utils/utils';
import {
  storeDetailsBlock,
  developerBlock,
  locationBlock1,
  locationBlock2,
  contactBlock,
} from './config';
import DoddleStoresStore from '_common/stores/doddleStoresStore';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import AddressesModal from '_common/components/AddressesModal';
import { CompaniesStore } from 'stores';
import { get, upperFirst, isEmpty } from 'lodash';
import {
  CaptionHolder,
  CaptionPart,
  DataPart,
  Header,
  InfoColumn,
  InfoRow,
} from '_common/components/InfoBlock/elements';
import { withTranslation } from 'react-i18next';
import Button from '_common/components/Button/Button';
import { InfoBlockContainer } from '_common/utils/utilsElements';
import EditableBlock from '_common/components/InfoBlock/EditableBlock';
import moment from 'moment';
import {
  OPEN_CLOSE_SETTERS,
  STORE_EVENT_TYPES,
} from '_common/constants/stores';
import doddleStoresActions from 'doddleStores/actions';
import { TemporaryClosureControl } from './elements';

const addressModalCallback =
  (doddleStores: DoddleStoresStore) => async (data: TAddressModalData) => {
    doddleStores.setAddressModalVisible(false);

    if (data && data.loadAddress) {
      const [address: TLoqateAddress, geoData: TLoqateGeocode] =
        await Promise.all([
          doddleStores.retrieveAddress(data.id),
          doddleStores.getGeoByLocation(data.country, data.label),
        ]);
      const updateData = { place: {}, geo: {} };
      if (address) {
        const subcountriesList = !isEmpty(
          doddleStores.overrideSubCountries[
            doddleStores.singleStore.locationType
          ]
        )
          ? doddleStores.overrideSubCountries[
              doddleStores.singleStore.locationType
            ]
          : doddleStores.commonSubCountries;
        const {
          town,
          countrySubdivision,
          country,
          postcode,
          area,
          line1,
          line2,
        } = getStoreAddressFromLoqateAddress(address, subcountriesList);
        updateData.place = {
          countrySubdivision: countrySubdivision,
          address: {
            town,
            country,
            postcode,
            area,
            line1,
            line2,
          },
        };
      }
      if (geoData) {
        updateData.place.map = generateGoogleMapsLink({
          lat: geoData.Latitude,
          lon: geoData.Longitude,
        });
        updateData.geo = {
          lat: geoData.Latitude,
          lon: geoData.Longitude,
        };
      }
      const storeId = get(doddleStores, 'singleStore.storeId');
      const companyId = get(doddleStores, 'singleStore.companyId');
      if (storeId && companyId) {
        doddleStores.updateStoreById(storeId, updateData, companyId);
      } else {
        console.error('Could not get storeId');
      }
    }
  };

type Props = {
  doddleStores: DoddleStoresStore,
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
};

type State = {
  errors: Object,
  temporaryClosure: boolean,
  deactivationDateInEdit: boolean,
};

class DoddleStoreDetailsGeneral extends Component<Props, State> {

  state = {
    errors: {},
    deactivationDateInEdit: false,
    temporaryClosure: false,
  };

  componentDidMount() {
    this.props.doddleStores.loadCountriesConfig();
  }

  setError = (fieldName: string, value: any): void => {
    this.setState({
      errors: {
        [fieldName]: value,
      },
    });
  };

  findAddresses = () => {
    const { doddleStores } = this.props;
    doddleStores
      .findAddresses({
        postcode: get(doddleStores, 'singleStore.place.address.postcode'),
        address: get(doddleStores, 'singleStore.place.address.line1'),
        country: get(doddleStores, 'singleStore.place.address.country'),
      })
      .then(() => {
        doddleStores.setAddressModalVisible(true);
      });
  };

  onDeactivationDateEditStart = () => {
    this.setState({
      deactivationDateInEdit: true,
    });
  };

  onDeactivationDateEditEnd = () => {
    this.setState({
      deactivationDateInEdit: false,
      temporaryClosure: false,
    });
  };

  updateClosureReason = (updateObj) => {
    doddleStoresActions.updateStoreById(
      this.props.doddleStores.singleStore.storeId,
      {
        closureReason: updateObj.currentValue,
      }
    );
  };

  updateDeactivationDate = (updateObj) => {
    doddleStoresActions.setOpeningOrClosingDate(
      OPEN_CLOSE_SETTERS.SET_CLOSING_DATE,
      moment(updateObj.currentValue).format('YYYY-MM-DD'),
      this.state.temporaryClosure
    );
  };

  updateTemporaryClosure = ({ target: { checked: temporaryClosure } }) => {
    this.setState({
      temporaryClosure,
    });
  };

  renderDeactivationBlock = () => {
    const {
      t,
      doddleStores: { singleStore },
    } = this.props;
    const { temporaryClosure, deactivationDateInEdit } = this.state;

    const hasValidDate = moment(get(singleStore, 'closingDate')).isValid();
    const closingDateEvent = getStoreLastEvent(
      singleStore,
      STORE_EVENT_TYPES.CLOSING_DATE
    );
    const closingDateFormatted =
      (hasValidDate && closingDateEvent && closingDateEvent.closingDate) || '-';

    return (
      <InfoBlockContainer>
        <Header>{t('deactivation')}</Header>
        <InfoColumn>
          <InfoRow>
            <CaptionHolder>
              <CaptionPart>{t('temporaryClosure')}</CaptionPart>
            </CaptionHolder>
            <DataPart>
              {upperFirst(
                boolToWord(get(singleStore, 'temporaryClosure', false))
              )}
            </DataPart>
          </InfoRow>
        </InfoColumn>
        <InfoColumn>
          <InfoRow>
            <CaptionHolder>
              <CaptionPart>{t('closingDate')}</CaptionPart>
            </CaptionHolder>
            <EditableBlock
              type="datepicker"
              hideTime
              errorData={this.state.errors}
              showError={this.setError}
              validator={validateDate()}
              errorMessage="invalidDate"
              computedData={singleStore.closingDate || '-'}
              data={singleStore}
              dataView={closingDateFormatted}
              onEditStart={this.onDeactivationDateEditStart}
              onEditEnd={this.onDeactivationDateEditEnd}
              path="closingDate"
              name={t('closingDate')}
              onUpdateClicked={this.updateDeactivationDate}
            />
          </InfoRow>
          {deactivationDateInEdit && (
            <InfoRow>
              <TemporaryClosureControl
                checked={temporaryClosure}
                onChange={this.updateTemporaryClosure}
              >
                {t('temporaryClosure')}
              </TemporaryClosureControl>
            </InfoRow>
          )}
        </InfoColumn>
        <InfoColumn>
          <InfoRow>
            <CaptionHolder>
              <CaptionPart>{t('closureReason')}</CaptionPart>
            </CaptionHolder>
            <EditableBlock
              type="input"
              computedData={singleStore.closureReason || '-'}
              data={singleStore}
              dataView={singleStore.closureReason || '-'}
              path="closureReason"
              name={t('closureReason')}
              onUpdateClicked={this.updateClosureReason}
            />
          </InfoRow>
        </InfoColumn>
      </InfoBlockContainer>
    );
  };

  render() {
    const { companiesStore, t, doddleStores } = this.props;
    const { errors } = this.state;
    const { line1, country, postcode } = get(
      doddleStores,
      'singleStore.place.address',
      {}
    );
    const findAddressesDisabled = !country || (!postcode && !line1);

    return (
      <ColumnsHolder>
        <LeftColumn>
          {getInfoBlock(
            storeDetailsBlock(
              errors,
              this.setError,
              companiesStore.getCachedUserCompaniesList,
              get(doddleStores, 'singleStore.eventHistory')
            ),
            false,
            doddleStores
          )}
          {this.renderDeactivationBlock()}
          {getInfoBlock(
            contactBlock(errors, this.setError),
            true,
            doddleStores
          )}
        </LeftColumn>
        <Column>
          {getInfoBlock(
            locationBlock1(errors, this.setError, doddleStores),
            true,
            doddleStores
          )}
          <InfoRow justify="flex-end">
            <Button
              disabled={findAddressesDisabled}
              onClick={this.findAddresses}
            >
              {t('findAddress')}
            </Button>
          </InfoRow>
          {getInfoBlock(
            locationBlock2(errors, this.setError),
            true,
            doddleStores
          )}
          {getInfoBlock(developerBlock(), true, doddleStores)}
        </Column>
        <AddressesModal callback={addressModalCallback(doddleStores)} />
      </ColumnsHolder>
    );
  }

}

export default compose(
  withTranslation('stores', { withRef: true }),
  inject('companiesStore', 'doddleStores'),
  observer
)(DoddleStoreDetailsGeneral);
