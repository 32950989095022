// @flow

import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { RESOURCES } from '_common/constants/acl';
import stores from 'stores';
import links from '_common/routes/urls';
import { get } from 'lodash';
import doddleStoresActions from 'doddleStores/actions';

type RedirectConfig = {
  canActivate: boolean,
  redirectURL: null | string,
};

const urlToPermissions = {
  dashboard: RESOURCES.DASHBOARD,
  search: RESOURCES.SEARCH,
  stores: RESOURCES.STORES,
  users: RESOURCES.USERS.read,
  userDetails: RESOURCES.USERS.read,
  createStoreUser: RESOURCES.USERS.write,
  parcelDetails: RESOURCES.SEARCH,
  companies: RESOURCES.COMPANIES.write,
  promotions: RESOURCES.PROMOTIONS,
};

const getRedirectConfig = (props: any): RedirectConfig => {
  const { pathname } = props.location;
  const defaultRedirectURL = stores.aclStore.getDefaultRedirectUrl();

  if (
    !get(stores.authStore.getLoggedUserField, 'userId') &&
    pathname !== links.login
  ) {
    return {
      canActivate: false,
      redirectURL: links.login,
    };
  }

  if (!doddleStoresActions.getCurrentRole()) {
    return {
      canActivate: true,
      redirectURL: '/dashboard',
    };
  }

  const config: RedirectConfig = {
    canActivate: false,
    redirectURL: null,
  };
  const pathSplit = pathname.split('/');
  const requestedPath = pathSplit[1];
  const requestedPage = pathSplit.slice(-1)[0];
  let permissionsConfig = urlToPermissions[requestedPath];

  // Extra rules for stores
  if (requestedPath === 'stores') {
    if (requestedPage === 'restricted-list') {
      permissionsConfig = permissionsConfig.RESTRICTED_LIST;
    } else if (requestedPage === 'list') {
      permissionsConfig = permissionsConfig.MAIN;
    } else if (requestedPage === 'dashboard') {
      permissionsConfig = permissionsConfig.DASHBOARD;
    } else {
      permissionsConfig = permissionsConfig.DETAILS;
    }
  }

  if (permissionsConfig) {
    config.canActivate = stores.aclStore.isStrictAccessGranted(
      permissionsConfig
    );
    if (!config.canActivate) {
      if (`/${requestedPath}` === defaultRedirectURL) {
        console.error(
          `User cannot get access to ${defaultRedirectURL}. Check scopes and roles.`
        );
        config.redirectURL = '/login';
      } else {
        config.redirectURL = defaultRedirectURL;
      }
    }
  }

  /** Extra checks, covers non-general rules */
  if (
    pathname === links.doddleStores.subroutes.createStore &&
    stores.aclStore.isCustomerSupportRole
  ) {
    config.canActivate = false;
    config.redirectURL = links.doddleStores.main;
  }

  if (
    requestedPath.includes('promotions') &&
    !stores.companiesStore.promotionsEnabled
  ) {
    config.canActivate = false;
    config.redirectURL = defaultRedirectURL;
  }

  return config;
};

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const renderComponent = (props: any) => {
    const config = getRedirectConfig(props);
    return config.canActivate ? (
      <Component {...props} />
    ) : (
      <Redirect to={config.redirectURL || '/login'} />
    );
  };

  return <Route exact {...rest} render={renderComponent} />;
};

export default PrivateRoute;
