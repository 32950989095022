import styled, { css } from 'styled-components';

export const StaticPromotionTitle = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 18px;
  color: #494949;
  margin-bottom: 23px;
`;

const defaultBgColor = '#fff';

export const StaticPromotionWrapper = styled.div`
  padding: 15px 0;
  border: 1px solid #dbdbdb;
  background-color: ${({ bgColour = defaultBgColor }) => bgColour};
  width: 100%;
  height: auto;
  min-height: 400px;

  display: flex;
  justify-content: center;
  align-items: stretch;
`;

export const StaticPromotionContent = styled.div`
  border: ${({ borderColour = defaultBgColor }) => `1px solid ${borderColour}`};

  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 370px;
  height: 100%;
  max-width: 300px;

  border-radius: 10px;
  padding: 1px;

  & > .ant-row {
    flex-grow: 1;
    ${({ withMargin }) =>
      withMargin &&
      css`
        margin: 5px 0;
      `}
  }

  & > :first-child {
    border-radius: 10px 10px 0 0;
  }

  & > :last-child {
    border-radius: 0 0 10px 10px;
  }
`;

export const StaticPromotionSection = styled.div`
  padding: ${props => (props.paddingTop ? props.paddingTop : 18)}px
    ${props => (props.paddingRight ? props.paddingRight : 15)}px
    ${props => (props.paddingBottom ? props.paddingBottom : 18)}px
    ${props => (props.paddingLeft ? props.paddingLeft : 15)}px;
  border: 1px dashed #d8d8d8;
  font-family: ${props => props.theme.fonts.basic};
  color: ${({ color = '#696969' }) => color};
  font-size: 16px;
  width: 100%;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  word-break: break-word;

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 10px;
    `};

  & > a {
    color: ${({ color = '#696969' }) => color};
  }
`;

export const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 300px;
  ${({ fixedSize }) =>
    fixedSize &&
    css`
      width: 125px;
      height: 120px;
    `}
`;

export const ColorableText = styled.div`
  color: ${({ color = '#696969' }) => color};
`;
