import { upperFirst } from 'lodash';
import { MAIN_COMPANIES } from './appConfig';

export const OFFER_MANAGEMENT_COMPANY_IDS = [
  MAIN_COMPANIES.DODDLE_ID,
  MAIN_COMPANIES.YAMATO_ID,
  MAIN_COMPANIES.AUSTRALIA_POST_ID,
];

export const OFFER_VARIABLES_DEFAULT_VALIDATIONS = {
  STRING: {
    validations: {
      minLength: 1,
      maxLength: 30,
    },
  },
  NUMBER: {
    validations: {
      minValue: 1,
      maxValue: 30,
    },
  },
};

export const OFFER_VARIABLE_TYPES = {
  NUMBER: 'NUMBER',
  STRING: 'STRING',
};

export const OFFER_RESTRICTIONS = {
  TEMPLATE: { min: 1, max: 150 },
  LABEL: { min: 1, max: 30 },
  ID: { min: 1, max: 30 },
  VAR_LENGTH: { min: 1, max: 50 },
  VAR_VALUE: { min: 1, max: 90 },
};

export const PROMOTION_LENGTH_RESTRICTIONS = {
  TITLE: { min: 5, max: 35 },
  DESCRIPTION: { min: 10, max: 200 },
  PROMOTION_CODE: { max: 100 },
};

export const PROMO_DISPLAY_TYPES = {
  PRODUCT_IMAGE: 'PRODUCT_IMAGE',
  BARCODE: 'BARCODE',
  PROMOTION_CODE: 'PROMOTION_CODE',
};

export const PROMO_DISPLAY_TYPE_OPTIONS = Object.keys(PROMO_DISPLAY_TYPES).map(
  value => ({
    label: upperFirst(value.replace('_', ' ').toLowerCase()),
    value,
  })
);

export const OFFER_VARIABLE_TYPE_OPTIONS = Object.keys(
  OFFER_VARIABLE_TYPES
).map(value => ({
  label: value.toLowerCase(),
  value,
}));

export const COMPANY_LOGO_RESTRICTIONS: TImageRestrictions = {
  dimension: {
    maxWidth: 600,
    maxHeight: 400,
  },
  size: 75000,
};

export const PRODUCT_IMAGE_RESTRICTIONS: TImageRestrictions = {
  dimension: {
    maxWidth: 600,
    maxHeight: 400,
  },
  size: 1000000,
};

export const PROMOTION_ACTION_BUTTONS_CONTAINER_ID =
  'promotionActionButtonsContainer';
