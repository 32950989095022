// @flow

import React from 'react';
import DoddleStoreGeneral from '../General';
import DoddleStoreServices from '../Services';
import DoddleStoreAvailability from '../Availability';
import DoddleStorePrinters from '../Printers';
import DoddleStorePhotos from '../Photos';
import { isEmpty } from 'lodash';
import links from '_common/routes/urls';
import TabsForm, { COLLECT_DATA_SOURCES } from '_common/components/TabsForm';
import type { RouterHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import stores from 'stores';

type Props = {
  history: RouterHistory,
  t: TTranslateFunc,
};

const DoddleStoreCreate = ({ history, t }: Props) => {
  const createStore = async formData => {
    if (!isEmpty(formData)) {
      try {
        await stores.doddleStores.createStore(formData);
        history.push(links.doddleStores.main);
      } catch (e) {
        throw new Error('Could not create store');
      }
    }
  };

  const TABS_CONFIG = [
    {
      caption: t('general'),
      url: 'general',
      component: DoddleStoreGeneral,
      props: {
        t,
      },
    },
    {
      caption: t('services'),
      url: 'services',
      component: DoddleStoreServices,
      props: {
        t,
      },
    },
    {
      caption: t('openingHours'),
      url: 'availability',
      component: DoddleStoreAvailability,
      props: {
        t,
      },
    },
    {
      caption: t('photos'),
      url: 'photos',
      component: DoddleStorePhotos,
      props: {
        t,
      },
    },
    {
      caption: t('printers'),
      url: 'printers',
      component: DoddleStorePrinters,
      props: {
        t,
      },
    },
  ];

  return (
    <TabsForm
      title={t('createStore')}
      activeTab="general"
      disabledTabs={['availability', 'services', 'photos', 'printers']}
      tabsConfig={TABS_CONFIG}
      collectDataSource={COLLECT_DATA_SOURCES.TABS_REF}
      onSubmit={createStore}
    />
  );
};

export default withTranslation('stores')(DoddleStoreCreate);
