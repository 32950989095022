import getReturnById from './getReturnById';
import getCollectionById from './getCollectionById';
import updateReturnStatus from './updateReturnStatus';
import updateCollectionStatus from './updateCollectionStatus';

export default {
  getReturnById,
  getCollectionById,
  updateReturnStatus,
  updateCollectionStatus,
};
