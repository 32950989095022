// @flow

import React, { Component } from 'react';
import { StyledPanelSectionTitle } from './elements';

class PanelSectionTitle extends Component<any> {

  render() {
    return (
      <StyledPanelSectionTitle {...this.props}>
        {this.props.children}
      </StyledPanelSectionTitle>
    );
  }

}

export default PanelSectionTitle;
