import React, { Component } from 'react';
import { LastInfoBlockContainer } from '_common/utils/utilsElements';
import { compose } from 'recompose';
import EditableBlock from '_common/components/InfoBlock/EditableBlock';
import { Form } from 'antd';
import { ExceptionOutlined } from '@ant-design/icons';
import {
  CaptionHolder,
  CaptionPart,
  Header,
  InfoRow,
} from '_common/components/InfoBlock/elements';
import OfferVariables from 'promotions/components/OfferVariables';
import { formatOfferVariables } from '_common/utils/utils';
import { ObsoleteOfferNote } from './elements';
import { withTranslation } from 'react-i18next';
import OffersDialog from 'promotions/components/OffersDialog';
import { get, isEmpty } from 'lodash';

type State = {
  newOfferId: string,
  isOfferTypeInEdit: boolean,
  newOffer: ?TPromotionOffer,
};

type Props = {
  singlePromotion: TPromotion,
  singlePromotionOffer: TPromotionOffer,
  getOfferById: string => Promise<?TPromotionOffer>,
  updatePromotionOffer: (string, Object, Object) => Promise<any>,
  updateSinglePromotion: Function,
  addFieldInEdit: (field: string) => void,
  removeFieldInEdit: (field: string) => void,
  t: TTranslateFunc,
  onOfferChange?: Function,
};

class PromoOfferBlock extends Component<Props, State> {

  formRef = React.createRef();

  state = {
    isOfferTypeInEdit: false,
    newOfferId: '',
    newOffer: null,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { newOffer, newOfferId } = this.state;
    const { getOfferById } = this.props;
    if (newOfferId && (!newOffer || newOfferId !== prevState.newOfferId)) {
      getOfferById(newOfferId).then(offer => {
        this.setState({
          newOffer: offer,
        });
      });
    }
  }

  toggleOfferTypeInEdit = (field: string) => {
    const { addFieldInEdit, removeFieldInEdit } = this.props;
    const newValue = !this.state.isOfferTypeInEdit;
    this.setState({
      isOfferTypeInEdit: newValue,
    });
    newValue ? addFieldInEdit(field) : removeFieldInEdit(field);
  };

  changeOfferType = (value: string) => {
    this.setState({
      newOfferId: value,
    });
  };

  updateOffer = async () => {
    const {
      singlePromotionOffer: oldOffer,
      updateSinglePromotion,
      updatePromotionOffer,
    } = this.props;
    const { newOffer } = this.state;
    const offer = newOffer || oldOffer;
    let error = false;
    const form = this.formRef.current;
    if (form) {
      const variables = form.getFieldValue(offer.offerId);
      try {
        await form.validateFields();
        if (variables) {
          const offerVariables = formatOfferVariables(variables);
          updateSinglePromotion(updatePromotionOffer, [
            {
              offer: {
                offerId: offer.offerId,
                variables: offerVariables,
              },
            },
            offer,
          ]);
        } else {
          error = true;
        }
      } catch (e) {
        error = true;
      }
    }
    return error;
  };

  isOfferChanged = () => {
    // add more logic if we have initial values for vars
    return true;
  };

  onVariablesChange = () => {
    const { onOfferChange, singlePromotionOffer: oldOffer } = this.props;
    const form = this.formRef.current;
    if (onOfferChange && form) {
      const { newOffer } = this.state;
      const offer = newOffer || oldOffer;
      const offerId = get(offer, 'offerId');
      const allVariables = form.getFieldsValue();
      const offersVariables = allVariables[offerId];
      if (offerId && !isEmpty(offersVariables)) {
        onOfferChange(offerId, { [offerId]: offersVariables });
      }
    }
  };

  render() {
    const { isOfferTypeInEdit, newOffer } = this.state;
    const {
      singlePromotion,
      singlePromotionOffer: currentOffer,
      t,
    } = this.props;
    const offerText = get(singlePromotion, 'offer.offerText');

    return (
      <LastInfoBlockContainer>
        <Header>
          {t('offerDetails')}
          {currentOffer.enabled === false && (
            <ObsoleteOfferNote>
              <ExceptionOutlined /> {t('obsoleteOffer')}
            </ObsoleteOfferNote>
          )}
        </Header>
        <InfoRow>
          <CaptionHolder>
            <CaptionPart>{t('offerType')}*</CaptionPart>
          </CaptionHolder>
          <EditableBlock
            localizePlaceholder={false}
            placeholder={t('selectOfferType')}
            type="modal"
            modal={dialogProps => (
              <OffersDialog t={t} dialogProps={{ ...dialogProps }} />
            )}
            initialValue={currentOffer.template}
            computedData={offerText}
            data={singlePromotion}
            dataView={offerText}
            path="offer.offerText"
            name={t('promoOfferType')}
            onValueChange={this.changeOfferType}
            onEditStart={this.toggleOfferTypeInEdit}
            onEditEnd={this.toggleOfferTypeInEdit}
            onUpdateClicked={this.updateOffer}
            skipUpdateCheck={this.isOfferChanged}
          />
        </InfoRow>
        <Form ref={this.formRef}>
          {isOfferTypeInEdit && (
            <OfferVariables
              blurHandler={this.onVariablesChange}
              isEditLayout
              offerId={newOffer ? newOffer.offerId : currentOffer.offerId}
              offerVariables={
                newOffer ? newOffer.variables : currentOffer.variables
              }
              initialOffer={singlePromotion.offer}
              form={this.formRef.current}
              t={t}
            />
          )}
        </Form>
      </LastInfoBlockContainer>
    );
  }

}

export default compose(withTranslation('promotions'))(PromoOfferBlock);
