import styled from 'styled-components';
import { Radio } from 'antd';

export const StyledRadio = styled(Radio)`
  display: flex;
  width: 100%;

  justify-content: start;
  align-items: center;

  border: 1px solid #d8e1e8;

  margin: 10px 0;
  padding: 15px;

  font-family: ${props => props.theme.fonts.book};
`;

export const OffersList = styled(Radio.Group)`
  border-bottom: 1px solid #d8e1e8;
  margin-bottom: 25px;
  padding: 18px 0;
  overflow-y: auto;
  max-height: 500px;
`;
