import styled from 'styled-components';

export const TabsWrapper = styled.div`
  position: relative;

  .ant-tabs {
    overflow: visible;
  }

  .ant-tabs-tab {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-tabs-tab-active {
    div {
      color: #494949;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #542785;
  }

  .ant-tabs-bar {
    margin-bottom: 30px;
  }

  .ant-tabs-nav-wrap {
    margin-left: 0 !important;
  }

  .ant-tabs-tabpane {
    background-color: #fff !important;
  }
`;

export const StoreTabsWrapper = styled.div`
  > .ant-tabs-top > .ant-tabs-nav > .ant-tabs-nav-wrap,
  > .ant-tabs-top > .ant-tabs-bar .ant-tabs-nav-container {
    padding-left: 30px;
    height: 50px !important;
    background: #fff;
    border-bottom: solid 1px #e6e7e8;
    overflow: visible;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #3e3f42;
    font-weight: bold;
  }

  .ant-tabs.ant-tabs-card > .ant-tabs-nav,
  .ant-tabs.ant-tabs-card > .ant-tabs-bar {
    margin-bottom: 0;

    .ant-tabs-tab {
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase;
      color: ${props => props.theme.colors.shade2};
      background: ${props => props.theme.colors.shade1};
      font-family: ${props => props.theme.fonts.book};
      font-weight: bold;
      padding: 15px 43px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 0;
      border: 1px solid ${props => props.theme.colors.shade1};
      transition: 0s;

      &:hover {
        color: ${props => props.theme.colors.shade2};
      }

      &.ant-tabs-tab-active {
        font-family: ${props => props.theme.fonts.basic};
        color: #3e3f42;
        border: solid 1px #e6e7e8;
        border-bottom-width: 0;
        padding-bottom: 21px;
      }
    }
  }
  .ant-tabs-bar {
  }

  .ant-tabs-tabpane {
    background-color: #fff !important;
  }
`;

export const TabTitle = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 12px;
  text-align: center;
  color: #b0b0b0;
  text-transform: uppercase;
`;
