import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Col, DatePicker } from 'antd';
import { Button } from '_common/components';

export const Hidden = styled.div`
  display: none;
`;

export const CheckboxBig = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 27px;
    height: 27px;

    ::after {
      width: 10px;
      height: 15px;
    }
  }
`;

export const FormItemSelect = styled(Form.Item)`
  .ant-form-item-control,
  .ant-legacy-form-item-control {
    line-height: normal;
  }
`;

export const CustomLink = styled.a`
  color: inherit;
  text-decoration: underline;
`;

export const TextMedium = styled.div`
  font-size: 16px;
`;

export const DropdownCover = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 150;
`;

export const HiddenOnBreakpoint = styled.div`
  display: ${props => (props.display ? props.display : 'block')};
  @media only screen and (max-width: ${props => props.breakpoint}px) {
    display: none;
  }
`;

export const LabelExtra = styled.div`
  font-size: 12px;
`;

export const PaginationWrapper = styled.div`
  margin-top: 10px;
`;

export const RangePickerFullWidth = styled(DatePicker.RangePicker)`
  width: 100%;
  height: 40px;
`;

export const FullWidthLink = styled.a`
  width: 100%;
`;

export const ColWithPaddingLeft = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  border-left: 1px solid #e6e7e8;
`;

export const ActionButton = styled(Button)`
  padding: 10px !important;
  height: auto !important;
  font-size: 15px !important;
  min-width: 200px;
  margin-left: 7px;
  &:first-child {
    margin-left: 0;
  }
`;
