// @flow

import React, { Fragment } from 'react';
import qs from 'qs';
import type { Location, Match, RouterHistory } from 'react-router';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { ACLStore, PromotionsStore, UIStore } from 'stores';
import Loader from '_common/components/Loader';
import TabsForm from '_common/components/TabsForm';
import PromotionDetailsGeneral from './General';
import PromotionStoresAssignment from 'promotions/components/StoresAssignment';
import PromotionDetailsMarketingMessage from './MarketingMessage';
import ConsentDialog from 'promotions/components/ConsentDialog';
import links from '_common/routes/urls';
import { withTranslation } from 'react-i18next';
import stores from 'stores';
import CustomHeader from 'promotions/components/CustomHeader';

type Props = {
  history: RouterHistory,
  location: Location,
  match: Match,
  initialParentTab?: string,
  promotionsStore: PromotionsStore,
  uiStore: UIStore,
  aclStore: ACLStore,
  t: TTranslateFunc,
};

type State = {
  isLoading: boolean,
  activeTab: string,
};

class PromotionDetails extends React.Component<Props, State> {

  getTabsConfig = (): Array<TTabConfig> => [
    {
      caption: this.props.t('details'),
      url: 'general',
      component: PromotionDetailsGeneral,
      props: {
        promotionsStore: this.props.promotionsStore,
        uiStore: this.props.uiStore,
        t: this.props.t,
      },
      skipRef: true,
    },
    {
      caption: this.props.t('storesAssignment'),
      url: 'stores',
      component: PromotionStoresAssignment,
      props: {
        isEditMode: true,
        t: this.props.t,
      },
      access: !stores.aclStore.isStoreRole,
      skipRef: true,
    },
    {
      caption: this.props.t('marketingMessageDesign'),
      url: 'marketingMessage',
      component: PromotionDetailsMarketingMessage,
      skipRef: true,
    },
  ];

  _initialTab = 'general';
  initialCompanyId: ?string = null;

  state = {
    isLoading: false,
    activeTab: this._initialTab,
  };

  componentDidMount(): void {
    const {
      location,
      match: {
        params: { id },
      },
    } = this.props;
    const { tab } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.setActiveTab(tab ? tab : this._initialTab);

    this.loadPromotionById(id);

    this.initialCompanyId = this.props.aclStore.selectedCompanyId;
  }

  componentDidUpdate() {
    const { aclStore, history } = this.props;
    const selectedCompanyId = aclStore.selectedCompanyId;
    if (
      this.initialCompanyId &&
      selectedCompanyId &&
      selectedCompanyId !== this.initialCompanyId
    ) {
      history.push(links.promotions.main);
    }
  }

  componentWillUnmount() {
    this.props.promotionsStore.resetSinglePromotion();
  }

  saveConsentDialogRef = (ref: ?Object) => {
    this.props.uiStore.setPromotionEditConsentDialogRef(ref);
  };

  setActiveTab = (activeTab: string) => {
    this.setState({ activeTab });
    this.onTabChange(activeTab);
  };

  loadPromotionById = (id?: ?string) => {
    const { aclStore, promotionsStore } = this.props;
    if (id) {
      this.setState({
        isLoading: true,
      });
      promotionsStore
        .loadSinglePromotionById(id, !aclStore.isStoreRole)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  onTabChange = (key: string) => {
    this.props.history.push({
      search: `?tab=${key}`,
    });
  };

  render() {
    const {
      promotionsStore: {
        singlePromotion: { title, canEdit: canEditPromo },
      },
      aclStore,
      t,
    } = this.props;
    const { isLoading, activeTab } = this.state;
    const showCloneButton = !aclStore.isStoreRole || canEditPromo;

    return isLoading ? (
      <Loader />
    ) : (
      <Fragment>
        <TabsForm
          selectedCompanyId={aclStore.selectedCompanyId}
          onTabChange={this.onTabChange}
          tabsConfig={this.getTabsConfig()}
          customHeader={
            <CustomHeader
              showCloneButton={showCloneButton}
              title={`${title || '-'}`}
            />
          }
          activeTab={activeTab}
        />
        <ConsentDialog t={t} ref={this.saveConsentDialogRef} />
      </Fragment>
    );
  }

}

export default compose(
  withTranslation('promotions'),
  inject('promotionsStore', 'aclStore', 'uiStore'),
  observer
)(PromotionDetails);
