import React, { Component, Fragment } from 'react';
import {
  PROMO_DISPLAY_TYPES,
  PRODUCT_IMAGE_RESTRICTIONS,
  PROMOTION_LENGTH_RESTRICTIONS,
} from '_common/constants/promotions';
import { Input, InputLabel, ImageUploadDialog } from '_common/components';
import {
  FixedWidthRow,
  RowWrapper,
} from '_common/components/TabsForm/elements';
import { Form } from 'antd';
import {
  CaptionHolder,
  EditableDataHolder,
  EditStateBlock,
} from '_common/components/InfoBlock/elements';
import { withTranslation } from 'react-i18next';

type Props = {
  type?: string,
  form: Form,
  labelWidth?: number,
  getFieldName?: string => void,
  isEditLayout: boolean,
  t: TTranslateFunc,
  onBlurHandler?: Function,
  imageLibrary: Array<string>,
};

class PromotionDisplayField extends Component<Props> {

  static defaultProps = {
    labelWidth: 190,
  };

  onBlurHandler = () => {
    this.props.onBlurHandler && this.props.onBlurHandler();
  };

  handleFileUploaded = (fileData: any) => {
    this.props.form.setFieldsValue({
      image: fileData,
    });
    this.onBlurHandler();
  };

  getConfig = (type?: string): Object | null => {
    const { BARCODE, PRODUCT_IMAGE, PROMOTION_CODE } = PROMO_DISPLAY_TYPES;
    const { getFieldName, t, imageLibrary } = this.props;
    let config = {};
    let field = null;
    switch (type) {
      case BARCODE: {
        field = 'barcode';
        config = {
          label: t('barcode'),
          field,
          controlElem: (
            <Input
              key={field}
              placeholder={t('enterBarcodePlaceholder')}
              onBlur={this.onBlurHandler}
            />
          ),
        };
        break;
      }
      case PRODUCT_IMAGE: {
        field = 'image';
        config = {
          label: t('productImage'),
          field,
          controlElem: (
            <ImageUploadDialog
              imageLibrary={imageLibrary}
              key={field}
              onConfirm={this.handleFileUploaded}
              maxSize={PRODUCT_IMAGE_RESTRICTIONS.size}
            />
          ),
        };
        break;
      }
      case PROMOTION_CODE: {
        field = 'promotionCode';
        config = {
          customRules: [
            {
              max: PROMOTION_LENGTH_RESTRICTIONS.PROMOTION_CODE.max,
              message: t('promotionCodeLengthRule', {
                max: PROMOTION_LENGTH_RESTRICTIONS.PROMOTION_CODE.max,
              }),
            },
          ],
          label: t('promotionCode'),
          field,
          controlElem: (
            <Input
              key={field}
              placeholder={t('enterPromoPlaceholder')}
              onBlur={this.onBlurHandler}
            />
          ),
        };
        break;
      }
      default: {
        config = null;
      }
    }
    if (getFieldName && field) {
      getFieldName(field);
    }
    return config;
  };

  render() {
    const { type, labelWidth, isEditLayout, t } = this.props;
    const config = this.getConfig(type);

    if (!config) {
      return null;
    }

    const LabelWrapper = isEditLayout ? CaptionHolder : FixedWidthRow;
    const ControlWrapper = isEditLayout ? EditStateBlock : FixedWidthRow;
    const AdditionalWrapper = isEditLayout ? EditableDataHolder : Fragment;

    const rules = [
      {
        required: true,
        message: `${config.label} ${t('isRequired')}`,
      },
    ];

    if (config.customRules) {
      config.customRules.forEach(rule => rules.push(rule));
    }

    return (
      config && (
        <RowWrapper marginBottom={10}>
          <LabelWrapper noPadding width={labelWidth}>
            <InputLabel>{`${config.label}*`}</InputLabel>
          </LabelWrapper>
          <ControlWrapper width={isEditLayout ? 57.5 : 380}>
            <AdditionalWrapper>
              <Form.Item rules={rules} name={config.field}>
                {config.controlElem}
              </Form.Item>
            </AdditionalWrapper>
          </ControlWrapper>
        </RowWrapper>
      )
    );
  }

}

export default withTranslation('promotions')(PromotionDisplayField);
