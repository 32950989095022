// @flow

import stores from 'stores';
import { get } from 'lodash';

export default () => {
  const { usersStore, authStore, companiesStore } = stores;
  const selectedCompanies = get(usersStore, 'singleUser.companies', []);
  const scopes = get(usersStore, 'singleUser.scopes', []);
  return companiesStore.getCompaniesList([
    ...new Set([
      ...authStore.createCompaniesListFromScopes(scopes),
      ...selectedCompanies,
    ]),
  ]);
};
