import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #000;
  }
`;

export const Title = styled.div`
  margin-top: 40px;
  font-size: 20px;
  font-family: ${props => props.theme.fonts.bold};
`;

export const SubTitle = styled.div`
  font-size: 15px;
`;
