// @flow

import React, { Fragment } from 'react';
import {
  Header,
  HeaderCaption,
  StyledPanelWrapper,
  FormContainer,
  FormPartCaption,
  Divider,
  SubmitButton,
  ButtonsHolder,
  TextLink,
  StoreName,
  StoreId,
  StoreAddress,
  HeaderInnerHolder,
  CaptionWrapper,
  HeaderBackButton,
  SelectBlock,
  UserLabel,
  SelectWrapper,
  UserInputWrapper,
  UserInput,
  GeneratePassword,
  SelectSectionWrapper,
} from './elements';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Col, Row, Form } from 'antd';
import AuthoritySelect from './AuthoritySelect';
import CompaniesSelect from './CompaniesSelect';
import userActions from 'users/actions';
import { get, compact, uniq, isEmpty, startsWith, includes } from 'lodash';
import DoddleStores from '_common/stores/doddleStoresStore';
import type { RouterHistory, Location } from 'react-router';
import { AuthStore, ACLStore, UsersStore, CompaniesStore } from 'stores';
import Loader from '_common/components/Loader';
import { withRouter } from 'react-router-dom';
import { ForbiddenMessage } from '_common/components';
import {
  CUSTOM_ROLES,
  RESOURCES,
  Roles,
  SINGLE_COMPANY_ROLES,
} from '_common/constants/acl';
import commonActions from '_common/actions';
import { FEATURES } from '_common/stores/aclStore';
import generateScopes, {
  EMPTY_TEMPLATES,
  SCOPES,
} from '_common/utils/generateScopes';
import type { TScopesConfig } from '_common/utils/generateScopes';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import {
  composeCompanySpecificRole,
  getPassword,
  localizeOptions,
  passwordRegExp,
  removeOptionIfExist,
  getScopeFromCompany,
  isCompanyScope,
} from '_common/utils/utils';
import Select from '_common/components/Select/Select';
import { withTranslation } from 'react-i18next';

type Props = {
  usersStore: UsersStore,
  match: {
    params: {
      storeId: string,
      login: string,
      orgId: string,
    },
  },
  doddleStores: DoddleStores,
  history: RouterHistory,
  location: Location,
  authStore: AuthStore,
  aclStore: ACLStore,
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
};

type State = {
  role: Array<string>,
  scope: Array<string>,
  noScope: boolean,
  noRole: boolean,
  formSubmitted: boolean,
  pickedRolesScopesFromProps: boolean,
  pickedCompaniesFromScopes: boolean,
  isDataLoading: boolean,
  isLoading: boolean,
  isStoreRequired: boolean,
  selectedCompanies: Array<TOption>,
  forceParentCompany?: string,
  resetStoresSelect: boolean,
  isStoresLoading: boolean,
  storeOptions: Array<TOption>,
  parentCompanyChangeInProgress: boolean,
  roleOptions: Array<TOption>,
};

class SingleUser extends React.Component<Props, State> {

  _isEditPage: boolean;
  _isCreatePage: boolean;
  _isCreateStoreUserPage: boolean;
  _isMounted: boolean;
  _formRef = React.createRef();

  _initialState = {
    role: [],
    scope: [],
    noRole: true,
    noScope: true,
    selectedCompanies: [],
    pickedRolesScopesFromProps: false,
    pickedCompaniesFromScopes: false,
    isDataLoading: false,
    formSubmitted: this._isEditPage,
    isLoading: false,
    isStoreRequired: false,
    forceParentCompany: undefined,
    resetStoresSelect: false,
    isStoresLoading: false,
    storeOptions: [],
    parentCompanyChangeInProgress: false,
    roleOptions: [],
  };

  constructor(props) {
    super(props);

    const { role, scope, noScope, noRole } = this.getInitialRolesAndScopes();

    this.state = {
      ...this._initialState,
      role,
      scope,
      noScope,
      noRole,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const {
      match: {
        params: { login, orgId },
      },
    } = this.props;

    this.loadUser(login, orgId);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      match: {
        params: { login: prevUserId },
      },
    } = prevProps;
    const {
      match: {
        params: { login: userId, orgId },
      },
      usersStore,
    } = this.props;
    if (userId !== prevUserId) {
      usersStore.resetUser();
      const { scope, role, noRole, noScope } = this.getInitialRolesAndScopes();
      this.setState(
        {
          ...this._initialState,
          scope,
          role,
          noRole,
          noScope,
        },
        () => {
          this.loadUser(userId, orgId);
        }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const result = {};
    const roles = get(nextProps, 'usersStore.singleUser.roles');
    const scopes = get(nextProps, 'usersStore.singleUser.scopes');
    const companies = get(nextProps, 'companiesStore.companies');

    if (!prevState.pickedRolesScopesFromProps) {
      if (roles && roles.length) {
        result.role = roles;
        result.pickedRolesScopesFromProps = true;
      }

      if (scopes && scopes.length) {
        result.scope = scopes;
        result.pickedRolesScopesFromProps = true;
      }
    }

    if (!prevState.pickedCompaniesFromScopes) {
      if (companies && companies.size > 1 && scopes && scopes.length) {
        result.selectedCompanies = userActions.getUsersCompaniesList();
        result.pickedCompaniesFromScopes = true;
      }
    }

    return result;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      pickedRolesScopesFromProps: false,
      isDataLoading: false,
    });
    userActions.resetUser();
  }

  loadUser = (userId: string, orgId: string) => {
    if (userId) {
      this.setState({
        isDataLoading: true,
      });
      this.props.usersStore
        .getUserByLogin(
          orgId || get(this.props.authStore, 'loggedUser.organisationId'),
          userId
        )
        .then(() => {
          this.setState({
            isDataLoading: false,
          });
        });
    }
  };

  getInitialRolesAndScopes = () => {
    const { storeId, login } = this.props.match.params;

    this._isEditPage = !!login;
    this._isCreatePage = !this._isEditPage;
    this._isCreateStoreUserPage = !!storeId;
    const role = [];
    const scope = [];
    let noRole = !this._isEditPage;
    const noScope = false;
    if (this._isCreateStoreUserPage) {
      noRole = false;
      role.push(Roles.STORE);
      scope.push(`${EMPTY_TEMPLATES[SCOPES.STORE_STAFF]}${storeId}`);
    }
    return { role, scope, noRole, noScope };
  };

  loadStoreOptions = async (companyId: string): Promise<void> => {
    this.toggleStoresLoading();
    try {
      const storeOptions = await this.props.doddleStores.getStoreOptionsByFilters(
        { host: companyId }
      );
      this.setState({
        storeOptions,
      });
    } finally {
      this.toggleStoresLoading();
    }
  };

  getRoleScopesConfig = (): TScopesConfig => {
    const getFieldValue = this._formRef.current
      ? this._formRef.current.getFieldValue
      : null;
    const storeId = getFieldValue ? getFieldValue('storeId') : '';
    const organisationId = getFieldValue ? getFieldValue('organisationId') : '';
    return [
      {
        name: SCOPES.ORGANISATION,
        vars: [organisationId ? organisationId : ''],
      },
      {
        name: SCOPES.STORE_STAFF,
        vars: [storeId ? storeId : ''],
      },
    ];
  };

  getRoleScopes(
    roles: Array<string>,
    config: TScopesConfig,
    existingScopes?: Array<string>
  ): Array<string> {
    let result = [];

    try {
      result = generateScopes(roles, config, existingScopes);
    } catch (e) {
      console.warn(e.message);
      return existingScopes || result;
    } finally {
      if (this._formRef.current) {
        this._formRef.current.validateFields(['storeId']);
      }
    }
    return result;
  }

  submit = async values => {
    const { scope, role, selectedCompanies } = this.state;
    const {
      match: {
        params: { storeId, login, orgId },
      },
      doddleStores,
      authStore,
      history,
      usersStore,
    } = this.props;

    const usersNextCompanyIds = selectedCompanies.map(option => option.value);
    const previousLogin = usersStore.singleUser.login;

    const config = this.getRoleScopesConfig();
    const finalScopes = this.getRoleScopes(role, config, scope).filter(
      scope => scope.length > 0
    );

    this.setState({
      noScope: false,
      noRole: false,
      formSubmitted: true,
      scope: uniq(finalScopes),
    });

    let store;
    if (storeId) {
      store = await doddleStores.getSingleStoreById(storeId);
    }
    const computedOrgId =
      get(store, 'companyId') ||
      orgId ||
      values.organisationId ||
      get(authStore, 'loggedUser.organisationId');

    const additionalData = {};

    if (compact(finalScopes).length) {
      additionalData.scopes = finalScopes;
    }

    if (compact(role).length) {
      const finalRoles = [];
      for (const roleName of role) {
        const companySpecificRole = Object.values(
          CUSTOM_ROLES
        ).find(customRole => startsWith(roleName, customRole));
        if (companySpecificRole) {
          finalRoles.push(
            composeCompanySpecificRole(companySpecificRole, computedOrgId)
          );
        } else {
          finalRoles.push(roleName);
        }
      }
      additionalData.roles = finalRoles;
    }

    if (!additionalData.roles && !values.storeId && !storeId) {
      this.setState({
        noRole: true,
      });
      return;
    }

    if (
      !additionalData.scopes &&
      !values.storeId &&
      !storeId &&
      isEmpty(selectedCompanies)
    ) {
      this.setState({
        noScope: true,
      });
      return;
    }

    const data = {
      login: values.username !== previousLogin ? values.username : undefined,
      password: values.password,
      ...additionalData,
    };

    // Need to track added and removed companies separately
    // to synchronise them with current users companies on successful update
    const addedCompanies = [];
    const removedCompanies = [];

    const isEditingUser = !!login;

    // In case of user editing
    if (isEditingUser) {
      const { scopes: initialScopes } = usersStore.singleUser;
      const { scopes, roles } = additionalData;
      const newScopes = {};
      // Make up lists of added and removed scopes by comparing them with initialScopes
      const addedScopes = scopes.filter(
        scope => !initialScopes.includes(scope)
      );
      const removedScopes = initialScopes.filter(
        scope => !isCompanyScope(scope) && !scopes.includes(scope)
      );

      // Compare selectedCompanyIds with current users company ids and add appropriate companies to Added or Removed scopes.
      const usersPreviousCompanyIds = userActions
        .getUsersCompaniesList()
        .map(option => option.value);
      usersNextCompanyIds.forEach(companyId => {
        if (!usersPreviousCompanyIds.includes(companyId)) {
          addedCompanies.push(companyId);
          addedScopes.push(getScopeFromCompany(companyId));
        }
      });
      usersPreviousCompanyIds.forEach(companyId => {
        if (!usersNextCompanyIds.includes(companyId)) {
          removedCompanies.push(companyId);
          removedScopes.push(getScopeFromCompany(companyId));
        }
      });

      if (!isEmpty(addedScopes)) {
        newScopes.add = addedScopes;
      }
      if (!isEmpty(removedScopes)) {
        newScopes.remove = removedScopes;
      }
      data.scopes = newScopes;
      data.roles = roles;
    }
    // In case of user creation
    else {
      data.scopes = [
        // $FlowExpectedError
        ...(data.scopes ? data.scopes : []),
        ...usersNextCompanyIds.map(companyId => getScopeFromCompany(companyId)),
      ];
    }

    const action = isEditingUser
      ? usersStore.updateUser
      : usersStore.createUser;

    this.setState({ isLoading: true });

    if (login && data.password) {
      await usersStore.resetPassword(computedOrgId, login, data.password);

      delete data.password;
    }

    const storestaff = values.storeId
      ? `${EMPTY_TEMPLATES[SCOPES.STORE_STAFF]}${values.storeId}`
      : storeId && `${EMPTY_TEMPLATES[SCOPES.STORE_STAFF]}${storeId}`;

    const adminAdditions = {
      orgID: values.organisationId,
      storestaff,
    };

    const args = isEditingUser
      ? // $FlowExpectedError
        [data, computedOrgId, login]
      : // $FlowExpectedError
        [data, computedOrgId, adminAdditions];

    action
      .apply(null, args)
      .then(() => {
        if (!isEmpty(addedCompanies) || !isEmpty(removedCompanies)) {
          usersStore.synchroniseCompanies(addedCompanies, removedCompanies);
        }
        if (!storeId && !login) {
          history.push(`/users`);
          return;
        }

        if (this._formRef.current) {
          this._formRef.current.resetFields(['password']);
        }

        if (storeId) {
          history.push({
            pathname: `/stores/${storeId}`,
            search: `?tab=USERS`,
          });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      })
      .finally(() => {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
          });
        }
      });
  };

  onRoleChange = (role: ?TOption) => {
    let roles = [];
    if (role && role.value) {
      roles = [role.value];
    }
    this.setState(
      {
        role: roles,
        noRole: !roles.length,
        forceParentCompany: roles.includes(Roles.DODDLE_ADMIN)
          ? MAIN_COMPANIES.DODDLE_ID
          : undefined,
      },
      this.triggerScopesChange
    );
  };

  suspendUser = () => {
    const {
      match: {
        params: { orgId, login },
      },
    } = this.props;

    if (window.confirm(`Are you sure to delete ${login}?`)) {
      userActions.suspendUser(orgId, login);
    }
  };

  onBackClicked = () => {
    const { history } = this.props;
    history.goBack();
  };

  canEditField = (fieldName: string): boolean => {
    return commonActions.canAccessFeature(
      FEATURES.USER_EDIT_PROFILE,
      fieldName
    );
  };

  generatePassword = () => {
    if (this._formRef.current) {
      this._formRef.current.setFieldsValue({
        password: getPassword(),
      });
    }
  };

  isSingleCompanyRole = (): boolean => {
    const { role } = this.state;
    return role.length ? SINGLE_COMPANY_ROLES.includes(role[0]) : false;
  };

  isStoreRole = (): boolean =>
    this.state.role.some(value => startsWith(value, Roles.STORE));

  onOrganisationChange = async (val: any) => {
    const { aclStore } = this.props;
    if (this.state.parentCompanyChangeInProgress) return;
    this.setState({
      parentCompanyChangeInProgress: true,
    });

    if (val === null) {
      this.toggleResetStoresSelect();
    }

    if (val && val.value) {
      this.setState({
        roleOptions: aclStore.filterHigherRoles(
          aclStore.getRolesList(val.value)
        ),
      });
      if (this._formRef.current) {
        this._formRef.current.setFieldsValue({
          organisationId: val.value,
        });
      }
      this.toggleResetStoresSelect();

      const loadSubCompanies = !this.isSingleCompanyRole();
      if (loadSubCompanies) {
        this.addSubCompanies(val.value, true);
      }

      this.triggerScopesChange();

      await this.loadStoreOptions(val.value);
    }
    this.setState({
      parentCompanyChangeInProgress: false,
    });
  };

  toggleResetStoresSelect = () => {
    this.setState(state => ({
      resetStoresSelect: !state.resetStoresSelect,
    }));
    this.onStoreChange(null);
  };

  toggleStoresLoading = () => {
    this.setState(state => ({
      isStoresLoading: !state.isStoresLoading,
    }));
  };

  onStoreChange = (val: ?any) => {
    if (this._formRef.current) {
      this._formRef.current.setFieldsValue({
        storeId: val ? val.value : val,
      });
    }
    this.triggerScopesChange();
  };

  getCompanyList = (companyIds: Array<string>) => {
    const { companiesStore } = this.props;

    return companiesStore.getCompaniesList(companyIds);
  };

  addCompany = (companyId: string) => {
    const { selectedCompanies } = this.state;
    if (!selectedCompanies.map(option => option.value).includes(companyId)) {
      this.setState({
        selectedCompanies: [
          ...selectedCompanies,
          ...this.getCompanyList([companyId]),
        ],
      });
    }
  };

  removeCompany = async (companyId: string) => {
    const subCompanies = await this.props.companiesStore.getSubCompanies(
      companyId
    );
    const companyIdsToRemove = [
      ...subCompanies.map(company => company.companyId),
      companyId,
    ];
    const companiesScopesToRemove = subCompanies.map(company =>
      getScopeFromCompany(company)
    );
    companiesScopesToRemove.push(getScopeFromCompany(companyId));
    this.onScopeChange([
      ...this.state.scope.filter(
        scope => !companiesScopesToRemove.includes(scope)
      ),
    ]);
    this.setState(state => ({
      selectedCompanies: state.selectedCompanies.filter(
        company => !companyIdsToRemove.includes(company.value)
      ),
    }));
  };

  onScopeChange = (values: Array<string>) => {
    const uniqScopes = uniq(values);
    this.setState({
      scope: uniqScopes,
      noScope: !values.length,
    });
  };

  triggerScopesChange = () => {
    const { scope, role } = this.state;
    const config = this.getRoleScopesConfig();
    const nextScopes = this.getRoleScopes(role, config, scope);
    this.onScopeChange(nextScopes);
  };

  addSubCompanies = async (
    companyId: string,
    clearCompaniesScopes: boolean = false
  ) => {
    let newSelectedCompanyIds = [
      ...this.state.selectedCompanies.map(option => option.value),
      companyId,
    ];
    const {
      companiesStore: { getSubCompanies, userCompanies },
    } = this.props;

    if (clearCompaniesScopes) {
      newSelectedCompanyIds = [companyId];
    }

    const subCompanies = await getSubCompanies(companyId);
    newSelectedCompanyIds = subCompanies.reduce(
      (acc, { companyId }) =>
        includes(userCompanies, companyId) ? [...acc, companyId] : acc,
      newSelectedCompanyIds
    );

    this.setState({
      selectedCompanies: this.getCompanyList(uniq(newSelectedCompanyIds)),
    });
  };

  render() {
    const {
      usersStore,
      match: {
        params: { storeId, login },
      },
      doddleStores,
      history,
      aclStore,
      companiesStore,
      t,
    } = this.props;
    const {
      role,
      scope,
      noScope,
      noRole,
      formSubmitted,
      isLoading,
      selectedCompanies,
      isDataLoading,
      resetStoresSelect,
      isStoresLoading,
      storeOptions,
      parentCompanyChangeInProgress,
      forceParentCompany,
      roleOptions,
    } = this.state;

    const getFieldValue = this._formRef.current
      ? this._formRef.current.getFieldValue
      : null;

    const { singleUser } = usersStore;

    const store = doddleStores.getStoreById(storeId);

    const isAllowedToSeeRole = commonActions.canAccessFeature(
      FEATURES.USER_SEE_ROLE
    );

    const isAllowedToDeleteUser = commonActions.canAccessFeature(
      FEATURES.USER_DELETE
    );
    const isAllowedToEditUser = aclStore.canAccessResource(
      RESOURCES.USERS.read
    );

    const switchObj = login
      ? {
          submitText: t('saveUser'),
          headerCaption: login,
        }
      : {
          submitText: t('create'),
          headerCaption: t('addUser'),
        };

    let preselectedRole;
    const rolesList = roleOptions.length
      ? roleOptions
      : aclStore.getRolesListField;
    let parentCompanyId = getFieldValue
      ? getFieldValue('organisationId')
      : undefined;
    if (this._isEditPage) {
      parentCompanyId = singleUser.organisationId;
      preselectedRole = get(usersStore, 'singleUser.roles[0]');
    }

    let companiesOptions = companiesStore.getCachedUserCompaniesList;
    if (!role.includes(Roles.DODDLE_ADMIN)) {
      companiesOptions = removeOptionIfExist(
        companiesOptions,
        MAIN_COMPANIES.DODDLE_ID
      );
    }

    const hasStoreRole = this.isStoreRole();

    const selectedParentCompany = getFieldValue
      ? getFieldValue('organisationId')
      : undefined;
    const isStoreSelectEnabled =
      !isStoresLoading &&
      hasStoreRole &&
      selectedParentCompany &&
      this.canEditField('storeId');

    const showStoresSelect =
      !(this._isCreateStoreUserPage || this._isEditPage) && hasStoreRole;
    const showOrganisationsSelect = !(
      this._isCreateStoreUserPage || this._isEditPage
    );
    const showRolesSelect = isAllowedToSeeRole && !this._isCreateStoreUserPage;

    const isCompaniesSelectDisabled =
      !parentCompanyId || this.isSingleCompanyRole();

    const hasPreselectedRole =
      preselectedRole &&
      !isEmpty(rolesList) &&
      rolesList.find(option => preselectedRole.includes(option.value));

    const canEditRole = aclStore.isStrictAccessGranted(FEATURES.USER_EDIT_ROLE);
    const canEditPassword = aclStore.isStrictAccessGranted(
      FEATURES.USER_EDIT_PASSWORD
    );

    return isDataLoading ? (
      <Loader />
    ) : (
      <Fragment>
        {isAllowedToEditUser && (
          <Fragment>
            <Header>
              <HeaderInnerHolder
                className={singleUser.status === 'suspended' ? 'holder' : ''}
              >
                <CaptionWrapper>
                  <HeaderCaption>{switchObj.headerCaption}</HeaderCaption>
                  <HeaderBackButton onClick={this.onBackClicked}>
                    {t('common:back')}
                  </HeaderBackButton>
                </CaptionWrapper>
                {isAllowedToDeleteUser &&
                  login &&
                  singleUser.status === 'active' && (
                    <SubmitButton onClick={this.suspendUser}>
                      {t('deleteUser')}
                    </SubmitButton>
                  )}
              </HeaderInnerHolder>
              {store ? (
                <Fragment>
                  <StoreName>
                    {get(store, 'storeName')}{' '}
                    <StoreId>({get(store, 'storeId')})</StoreId>
                  </StoreName>
                  <StoreAddress>
                    {[
                      get(store, 'place.address.line1'),
                      get(store, 'place.address.line2'),
                      get(store, 'place.address.line3'),
                      get(store, 'place.address.town'),
                    ]
                      .join(',')
                      .replace(/,{2,}/g, ',')}{' '}
                    {get(store, 'place.address.postcode')}
                  </StoreAddress>
                </Fragment>
              ) : null}
            </Header>

            <StyledPanelWrapper>
              <FormContainer>
                <Form ref={this._formRef} onFinish={this.submit}>
                  <Row type="flex">
                    <Col sm={{ span: 11 }} xxl={{ span: 11 }}>
                      <FormPartCaption>{t('accountDetails')}</FormPartCaption>
                      {showRolesSelect && (
                        <SelectBlock>
                          <UserLabel>{t('roleType')}</UserLabel>
                          <Form.Item>
                            <SelectWrapper>
                              <Select
                                initialValue={
                                  hasPreselectedRole ? preselectedRole : null
                                }
                                checkInitial
                                onSelectOption={this.onRoleChange}
                                isDisabled={!canEditRole}
                                options={localizeOptions(rolesList, t, 'users')}
                                inputPlaceholder={t('selectRole')}
                                selectPlaceholder={t('role')}
                              />
                            </SelectWrapper>
                          </Form.Item>
                        </SelectBlock>
                      )}
                      <UserInputWrapper>
                        <UserLabel htmlFor={'username'}>
                          {t('username')}
                        </UserLabel>
                        <Form.Item
                          name="username"
                          initialValue={get(singleUser, 'login')}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: t('inputUsername'),
                            },
                          ]}
                        >
                          <UserInput
                            id={'username'}
                            placeholder={t('enterUsername')}
                            disabled={!this.canEditField('username') || noRole}
                          />
                        </Form.Item>
                      </UserInputWrapper>
                      <UserInputWrapper>
                        <UserLabel htmlFor={'password'}>
                          {t('password')}
                          <GeneratePassword onClick={this.generatePassword}>
                            {t('generatePassword')}
                          </GeneratePassword>
                        </UserLabel>
                        <Form.Item
                          name="password"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: !get(singleUser, 'login'),
                              pattern: passwordRegExp,
                              message: t('inputPassword'),
                            },
                          ]}
                          help={t('passwordHelp')}
                        >
                          <UserInput
                            id={'password'}
                            placeholder={t('enterPassword')}
                            disabled={!canEditPassword || noRole}
                          />
                        </Form.Item>
                      </UserInputWrapper>
                    </Col>
                    {!this._isCreateStoreUserPage && (
                      <Col
                        sm={{ offset: 1, span: 12 }}
                        xxl={{ offset: 1, span: 12 }}
                      >
                        {!this._isCreateStoreUserPage &&
                          !aclStore.isCustomerSupportRole && (
                            <Fragment>
                              <CompaniesSelect
                                parentCompanyOptions={{
                                  showParentCompanySelect: showOrganisationsSelect,
                                  isDisabled:
                                    parentCompanyChangeInProgress || noRole,
                                  disabled: !this.canEditField(
                                    'organisationId'
                                  ),
                                  onParentCompanyChange: this
                                    .onOrganisationChange,
                                  initialValue: forceParentCompany,
                                }}
                                isDisabled={isCompaniesSelectDisabled}
                                parentCompanyId={parentCompanyId}
                                options={companiesOptions}
                                onCompanyAdd={this.addCompany}
                                onCompanyRemove={this.removeCompany}
                                selectedCompanies={selectedCompanies}
                              />
                            </Fragment>
                          )}
                        {showStoresSelect && (
                          <SelectSectionWrapper>
                            <UserLabel>{t('store')}</UserLabel>
                            <Form.Item
                              name="storeId"
                              rules={[
                                {
                                  required: hasStoreRole,
                                  message: t('selectStore'),
                                },
                              ]}
                            >
                              <Select
                                isAsync
                                isDisabled={!isStoreSelectEnabled}
                                resetSelect={resetStoresSelect}
                                onSelectOption={this.onStoreChange}
                                loadOptions={doddleStores.getStoreOptionsByFiltersCallback(
                                  { host: selectedParentCompany }
                                )}
                                inputPlaceholder={t('selectStore')}
                                selectPlaceholder={t('enterStoreNameOrId')}
                                options={storeOptions}
                                loading={isStoresLoading}
                              />
                            </Form.Item>
                          </SelectSectionWrapper>
                        )}
                        {aclStore.isDoddleAdminRole && (
                          <Fragment>
                            <Divider />
                            <AuthoritySelect
                              isAsync
                              editable={!noRole}
                              loadOptions={aclStore.getScopesByQuery}
                              pagination
                              fieldName="scope"
                              caption={t('scope')}
                              addButtonLabel={t('addScope')}
                              editLabel={t('editScopes')}
                              selectLabel={t('selectScope')}
                              options={aclStore.getScopesListField}
                              selectedItems={scope}
                              placeHolder={t('selectScope')}
                              onChange={this.onScopeChange}
                              validationMessage={
                                noScope &&
                                isEmpty(selectedCompanies) &&
                                formSubmitted
                                  ? t('chooseScope')
                                  : ''
                              }
                            />
                          </Fragment>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Divider />
                  <ButtonsHolder>
                    <SubmitButton
                      disabled={
                        isLoading ||
                        (noScope && isEmpty(selectedCompanies)) ||
                        noRole
                      }
                      htmlType="submit"
                    >
                      {switchObj.submitText}
                    </SubmitButton>
                    <TextLink
                      onClick={() => {
                        if (!isLoading) {
                          history.goBack();
                        }
                      }}
                    >
                      {t('orCancel')}
                    </TextLink>
                  </ButtonsHolder>
                </Form>
              </FormContainer>
            </StyledPanelWrapper>
          </Fragment>
        )}
        {!isAllowedToEditUser && (
          <Fragment>
            <Header>
              <HeaderBackButton onClick={this.onBackClicked}>
                {t('common:back')}
              </HeaderBackButton>
            </Header>
            <ForbiddenMessage permissions={RESOURCES.USERS.write} />
          </Fragment>
        )}
      </Fragment>
    );
  }

}

export default compose(
  withTranslation('users'),
  inject(
    'usersStore',
    'doddleStores',
    'authStore',
    'aclStore',
    'companiesStore'
  ),
  withRouter,
  observer
)(SingleUser);
