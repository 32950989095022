import links from '_common/routes/urls';
import { MAIN_COMPANIES } from '_common/constants/appConfig';

/**
 * Admin_Tool_Doddle_Admin -> dashboard
 * Admin_Tool_Host_Admin -> dashboard
 * Admin_Tool_Customer_Support -> search
 * Admin_Tool_Reporter -> dashboard
 * Admin_Tool_Store -> dashboard
 */
export const Roles = {
  DODDLE_ADMIN: 'Admin_Tool_Doddle_Admin',
  HOST_ADMIN: 'Admin_Tool_Host_Admin',
  MANAGER: 'Admin_Tool_Manager',
  NETWORK: 'Admin_Tool_Network',
  CUSTOMER_SUPPORT: 'Admin_Tool_Customer_Support',
  STORE: 'Admin_Tool_Store',
  REPORTER: 'Admin_Tool_Reporter',
};

export const CUSTOM_ROLES = {
  STORE: Roles.STORE,
};

export const RolesList = [
  { label: 'Doddle Admin', value: Roles.DODDLE_ADMIN },
  { label: 'Admin', value: Roles.HOST_ADMIN },
  { label: 'Manager', value: Roles.MANAGER },
  { label: 'Customer support', value: Roles.CUSTOMER_SUPPORT },
  { label: 'Reporter', value: Roles.REPORTER },
  { label: 'Store', value: Roles.STORE },
  { label: 'Network', value: Roles.NETWORK },
];

export const RESOURCES = {
  USERS: {
    read: {
      scopes: [],
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
    },
    write: {
      scopes: [],
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
    },
  },
  ORGANISATIONS: {
    read: {
      scopes: ['organisations_read'],
      roles: [Roles.DODDLE_ADMIN],
    },
    write: {
      scopes: ['organisations_write'],
      roles: [Roles.DODDLE_ADMIN],
    },
  },
  STORES: {
    MAIN: {
      scopes: ['stores:read', 'stores:write'],
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
    },
    RESTRICTED_LIST: {
      scopes: ['stores:read'],
      roles: [Roles.NETWORK, Roles.STORE],
    },
    DETAILS: {
      scopes: ['stores:read'],
      roles: [
        Roles.DODDLE_ADMIN,
        Roles.HOST_ADMIN,
        Roles.CUSTOMER_SUPPORT,
        Roles.STORE,
      ],
    },
    DASHBOARD: {
      scopes: ['stores:read'],
      roles: [Roles.STORE],
    },
  },
  COMPANIES: {
    write: {
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
      scopes: [],
    },
  },
  PROMOTIONS: {
    roles: [],
    scopes: ['ats-promotions:read'],
  },
  SEARCH: {
    scopes: [],
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
  },
  DASHBOARD: {
    scopes: ['dashboard:read'],
    roles: [
      Roles.DODDLE_ADMIN,
      Roles.HOST_ADMIN,
      Roles.REPORTER,
      Roles.STORE,
      Roles.MANAGER,
    ],
  },
};

export const START_PAGE = {
  [Roles.DODDLE_ADMIN]: links.dashboard,
  [Roles.HOST_ADMIN]: links.dashboard,
  [Roles.MANAGER]: links.dashboard,
  [Roles.REPORTER]: links.dashboard,
  [Roles.CUSTOMER_SUPPORT]: links.search.main,
  [Roles.NETWORK]: links.doddleStores.restrictedList,
  [Roles.STORE]: links.doddleStores.subroutes.dashboard,
};

export const SINGLE_COMPANY_ROLES = [Roles.STORE];

export const CONNECTIVITY_ACCESS_CONFIG = {
  IDS: [MAIN_COMPANIES.AUSTRALIA_POST_ID],
  NAMES: ['Australia Post'],
};
