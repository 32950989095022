// @flow

import React, { Component } from 'react';
import { Col, Radio, Row } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  ButtonNext,
  FixedWidthRow,
  RadioGroupWrapper,
  RadioWrapper,
  RowWrapper,
  SectionWrapper,
  SeparationLine,
  StyledRadio,
} from '_common/components/TabsForm/elements';
import { InputLabel, Select } from '_common/components';
import { BARCODE_FORMAT_OPTIONS } from '_common/constants/companies';
import { onSelectFromDropdown } from '_common/utils/utils';

type Props = {|
  form: Form,
  onNextTab: (?string) => void,
  companyCreate: Function,
  t: TTranslateFunc,
|};

class Config extends Component<Props> {

  onSubmit = e => {
    e.preventDefault();

    const { form, onNextTab } = this.props;

    form.validateFields(err => {
      if (!err) {
        onNextTab();
      }
    });
  };

  getFormattedData = () => {
    const { form } = this.props;
    let data = null;

    form.validateFields((err, values) => {
      if (err) return;

      data = { ...values };
    });

    return data;
  };

  render() {
    const { form, t } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col span={12}>
            <SectionWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('includeSubCompanies')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator('storesFeed.includeSubCompanies', {
                        initialValue: false,
                      })(
                        <Radio.Group>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:yes')}</StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('includeOnboardingStores')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    <RadioGroupWrapper>
                      {getFieldDecorator('onboardingStoresFeed', {
                        initialValue: false,
                      })(
                        <Radio.Group>
                          <RadioWrapper>
                            <StyledRadio value={false}>
                              {t('common:no')}
                            </StyledRadio>
                          </RadioWrapper>
                          <RadioWrapper>
                            <StyledRadio value>{t('common:yes')}</StyledRadio>
                          </RadioWrapper>
                        </Radio.Group>
                      )}
                    </RadioGroupWrapper>
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('barcodeFormat')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('label.barcodeFormat')(
                      <Select
                        onSelectOption={onSelectFromDropdown(
                          form,
                          'label.barcodeFormat'
                        )}
                        options={BARCODE_FORMAT_OPTIONS}
                        inputPlaceholder={t('common:select')}
                        selectPlaceholder=""
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
        </Row>
        <SeparationLine />
        <ButtonNext htmlType="submit">{t('create')}</ButtonNext>
      </Form>
    );
  }

}

export default compose(Form.create(), observer)(Config);
