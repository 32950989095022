//@ flow

import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Panel } from '_common/components';
import { Tabs } from 'antd';
import qs from 'qs';
import {
  SearchHeader,
  Text,
  TabsWrapper,
  TabTitle,
  Flex,
  CustomerName,
  Parcel,
  BackLink,
} from './elements';
import type { ElementType } from 'react';
import parcelDetailsActions from 'parcels/parcelDetails/actions';
import get from 'lodash/get';
import RawData from '_common/components/RawData';
import Loader from '_common/components/Loader';
import { withTranslation } from 'react-i18next';

type Props = {
  location: Location,
  history: RouterHistory,
  ParcelDetailComponent: ElementType,
  t: TTranslateFunc,
};
const ParcelDetails = (
  ParcelDetailComponent,
  { headerDataPath, logisticDataPath, rawDataPath, namePath } = {},
  itemType
) => {
  class ParcelDetail extends React.Component<Props> {

    componentDidMount() {
      const {
        match: {
          params: { id },
        },
      } = this.props;

      if (itemType === 'collection') {
        parcelDetailsActions.getCollectionById(id);
      } else if (itemType === 'return') {
        parcelDetailsActions.getReturnById(id);
      }
    }

    TABS_KEYS = {
      details: 'details',
      rawData: 'rawData',
      logisticsData: 'logisticsData',
    };

    getActiveTab = () => {
      const { location } = this.props;

      let { tab } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (!tab) {
        tab = this.TABS_KEYS.details;
      }

      return tab;
    };

    onTabClick = (key: string) => {
      this.props.history.push({
        search: `?tab=${key}`,
      });
    };

    onBackButton = () => {
      const { history, location } = this.props;

      if (get(location, 'state.storeId')) {
        history.push({
          pathname: `/stores/${get(location, 'state.storeId')}`,
          search:
            location.state &&
            `?tab=OPEN%20RETURNS%20CONTAINERS;${get(
              location,
              'state.containerId'
            )}`,
        });
      } else {
        history.push({
          pathname: `/search`,
          search: location.state && `?tab=${location.state.activeTab}`,
        });
      }
    };

    render() {
      const {
        parcelsStore,
        match: { params },
        location,
        t,
      } = this.props;

      const rawData = get(parcelsStore, rawDataPath);
      const logisticData = get(parcelsStore, logisticDataPath);

      let customerName = '';
      const namePathValue = get(parcelsStore, namePath);

      if (namePathValue) {
        customerName = `${namePathValue.firstName ||
          ''} ${namePathValue.lastName || ''}`;
      }

      return parcelsStore.isSearchingDetails ? (
        <Loader />
      ) : (
        <div>
          <SearchHeader>
            <Parcel>{t('parcel')}</Parcel>
            <Flex>
              <Text>{get(parcelsStore, headerDataPath) || t('noBarcode')}</Text>
              <CustomerName>{customerName}</CustomerName>
            </Flex>
            <BackLink onClick={this.onBackButton}>
              {get(location, 'state.storeId')
                ? t('common:back')
                : t('backToSearch')}
            </BackLink>
          </SearchHeader>
          <Panel>
            <TabsWrapper>
              <Tabs
                defaultActiveKey={this.getActiveTab()}
                animated={false}
                onChange={this.onTabClick}
              >
                <Tabs.TabPane
                  tab={<TabTitle>{t('parcelDetails')}</TabTitle>}
                  key={this.TABS_KEYS.details}
                >
                  <ParcelDetailComponent {...params} />
                </Tabs.TabPane>
                {rawData && rawData.length ? (
                  <Tabs.TabPane
                    tab={<TabTitle>{t('rawParcelData')}</TabTitle>}
                    key={this.TABS_KEYS.rawData}
                  >
                    <RawData header={t('rawParcelData')} data={rawData} />
                  </Tabs.TabPane>
                ) : null}
                {logisticData && logisticData.length ? (
                  <Tabs.TabPane
                    tab={<TabTitle>{t('logisticsData')}</TabTitle>}
                    key={this.TABS_KEYS.logisticsData}
                  >
                    <RawData
                      header={t('logisticsData')}
                      data={get(parcelsStore, logisticDataPath)}
                    />
                  </Tabs.TabPane>
                ) : null}
              </Tabs>
            </TabsWrapper>
          </Panel>
        </div>
      );
    }
  
}

  return compose(
    withTranslation('parcels'),
    inject('parcelsStore'),
    withRouter,
    Form.create(),
    observer
  )(ParcelDetail);
};

export default ParcelDetails;
