// @flow

import { apiClient } from '_common/api/clients/clients';

const getCountriesConfig = async () => {
  const response = await apiClient.get(
    '/v2/config/componentName/ADMIN_TOOL_SUBCOUNTRIES'
  );
  return response.data.resource.config;
};

export default {
  getCountriesConfig,
};
