// @flow strict

import React from 'react';
import { Redirect } from 'react-router';
import links from '_common/routes/urls';
import stores from 'stores';

const RootRedirect = () => {
  const getLink = (): string => {
    if (stores.authStore.isTokenValid()) {
      return links.dashboard;
    }

    return links.login;
  };

  return <Redirect to={getLink()} />;
};

export default RootRedirect;
