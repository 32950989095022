// @flow

import React, { Component, createRef } from 'react';
import { Col, Row, Form } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { PromotionsStore } from 'stores';
import {
  ButtonNext,
  FixedWidthRow,
  RowWrapper,
  SectionWrapper,
  SeparationLine,
} from '_common/components/TabsForm/elements';
import { cleanObject, formatPromotionDates } from '_common/utils/utils';
import { Input, InputLabel } from '_common/components';
import { StyledTextArea } from '_common/components/Input/elements';
import {
  FormItemSelect,
  RangePickerFullWidth,
} from '_common/components/PlainStyles';
import { PROMOTION_LENGTH_RESTRICTIONS } from '_common/constants/promotions';
import { LabelExtra } from '_common/components/PlainStyles';
import TermsAndConditionsDialog from './termsAndConditionsDialog';

type Props = {|
  form: Form,
  onNextTab: (?string) => void,
  promotionsStore: PromotionsStore,
  t: TTranslateFunc,
|};

type State = {
  startDate: string,
  endDate: string,
  isDateRangeError: boolean,
  isTnCApproved: boolean,
  isTnCDialogVisible: boolean,
};

class General extends Component<Props, State> {

  formRef = createRef();

  state = {
    startDate: '',
    endDate: '',
    isDateRangeError: false,
    isTnCApproved: false,
    isTnCDialogVisible: false,
  };

  onSubmit = () => {
    const { onNextTab } = this.props;
    const dateRangeValid = this.dateRangeValidator();
    const form = this.formRef.current;
    if (form && dateRangeValid) {
      form.validateFields().then(() => {
        this.state.isTnCApproved ? onNextTab() : this.getTnCApproval();
      });
    }
  };

  getTnCApproval = () => {
    this.setState({ isTnCDialogVisible: true });
  };

  getFormattedData = () => {
    if (!this.dateRangeValidator()) {
      return;
    }

    const form = this.formRef.current;
    const { startDate, endDate } = this.state;
    if (form) {
      const values = form.getFieldsValue();
      const data = { ...values, startDate, endDate };
      cleanObject(data, ['']);

      return data;
    }
  };

  changeDateRangeActive = (dates: Array<Object>) => {
    const { startDate, endDate } = formatPromotionDates(dates);
    this.setState({
      startDate,
      endDate,
      isDateRangeError: false,
    });
  };

  dateRangeValidator = () => {
    let result = true;
    if (!this.state.startDate || !this.state.endDate) {
      result = false;
      this.setState({
        isDateRangeError: true,
      });
    }
    return result;
  };

  handleTnCToggle = (isTnCApproved: boolean) => () => {
    this.setState({ isTnCApproved, isTnCDialogVisible: false });
    if (isTnCApproved) {
      this.props.onNextTab();
    }
  };

  render() {
    const { t } = this.props;
    const { isDateRangeError, isTnCDialogVisible } = this.state;

    const {
      TITLE: { min: titleMinLength, max: titleMaxLength },
      DESCRIPTION: { max: descriptionMaxLength },
    } = PROMOTION_LENGTH_RESTRICTIONS;

    return (
      <Form ref={this.formRef} onFinish={this.onSubmit}>
        <TermsAndConditionsDialog
          t={t}
          dialogProps={{
            isShown: isTnCDialogVisible,
            handleCancelClick: this.handleTnCToggle(false),
            handleConfirmClick: this.handleTnCToggle(true),
          }}
        />
        <Row>
          <Col span={16}>
            <SectionWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('title')}*</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item
                    name="title"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        min: titleMinLength,
                        message: t('titleLengthRule', {
                          min: titleMinLength,
                          max: titleMaxLength,
                        }),
                      },
                      {
                        max: titleMaxLength,
                        message: t('titleLengthRule', {
                          min: titleMinLength,
                          max: titleMaxLength,
                        }),
                      },
                      {
                        required: true,
                        whitespace: true,
                        message: t('inputTitle'),
                      },
                    ]}
                  >
                    <Input placeholder={t('enterTitle')} />
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('description')}</InputLabel>
                  <LabelExtra>{t('forInternalUse')}</LabelExtra>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item
                    name="description"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        max: descriptionMaxLength,
                        message: t('descriptionLengthRule', {
                          max: descriptionMaxLength,
                        }),
                      },
                    ]}
                  >
                    <StyledTextArea
                      rows={6}
                      placeholder={t('enterDescription')}
                    />
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('dateRangeActive')}*</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <FormItemSelect
                    validateStatus={isDateRangeError ? 'error' : 'success'}
                  >
                    <RangePickerFullWidth
                      placeholder={[t('common:from'), t('common:to')]}
                      onChange={this.changeDateRangeActive}
                    />
                    {isDateRangeError && (
                      <div className="ant-legacy-form-explain">
                        {t('inputDateRange')}
                      </div>
                    )}
                  </FormItemSelect>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
        </Row>
        <SeparationLine />
        <ButtonNext htmlType="submit">{t('common:next')}</ButtonNext>
      </Form>
    );
  }

}

export default compose(inject('promotionsStore'), observer)(General);
