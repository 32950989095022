import React, { Component } from 'react';
import { ActionButton } from '../elements';
import {
  EditableDataHolder,
  EditStateBlock,
  UpdateButton,
} from '_common/components/InfoBlock/elements';
import { DatePicker } from 'antd';

type Props = {
  canSetDate: boolean,
  t: TTranslateFunc,
  setOpeningDate: Function,
};

type State = {
  showDateFlag: boolean,
  value: string,
};

class OpeningDateSetter extends Component<Props, State> {

  state = {
    showDateFlag: false,
    value: '',
  };

  datePickerChange = value => this.setState({ value });

  toggleShowDate = () => {
    this.setState(state => ({
      showDateFlag: !state.showDateFlag,
    }));
  };

  update = () => {
    this.props.setOpeningDate(this.state.value);
  };

  render() {
    const { canSetDate, t } = this.props;
    const { showDateFlag } = this.state;
    const title = t('setOpenDate');

    return canSetDate ? (
      showDateFlag ? (
        <EditStateBlock width={100}>
          <EditableDataHolder>
            <DatePicker showTime={false} onChange={this.datePickerChange} />
          </EditableDataHolder>
          <UpdateButton onClick={this.update}>
            {t('common:update')}
          </UpdateButton>
        </EditStateBlock>
      ) : (
        <ActionButton onClick={this.toggleShowDate}>{title}</ActionButton>
      )
    ) : (
      title
    );
  }

}

export default OpeningDateSetter;
