import React from 'react';
import { Table } from '_common/components';
import { PromotionsStore } from 'stores';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { ViewLink } from '_common/components/Table/elements';
import { PaginationConfig } from 'antd/es/pagination';
import { STATUSES } from '_common/constants/stores';
import { withTranslation } from 'react-i18next';

type Props = {
  removeRelation: string => void,
  promotionsStore: PromotionsStore,
  serverPagination: PaginationConfig | false,
  isLoading: boolean,
  t: TTranslateFunc,
};

class StoresTable extends React.Component<Props> {

  onRemoveRelation = (store: TStore | TPromotionStoreRelation) => () =>
    this.props.removeRelation(store);

  renderColumns = () => {
    const { t } = this.props;

    return [
      {
        title: t('storeId'),
        dataIndex: 'storeId',
        key: 'storeId',
        // fixed: 'left',
        width: 200,
        sorter: (a, b) => {
          return (a.storeId || '').localeCompare(b.storeId || '');
        },
      },
      {
        title: t('storeName'),
        key: 'storeName',
        dataIndex: 'storeName',
        sorter: (a, b) => {
          return (a.storeName || '').localeCompare(b.storeName || '');
        },
      },
      {
        title: t('host'),
        dataIndex: 'companyId',
        key: 'companyId',
      },
      {
        title: t('status'),
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => {
          return (a.status || '').localeCompare(b.status || '');
        },
      },
      {
        title: t('common:action'),
        key: 'action',
        render: (store: TStore | TPromotionStoreRelation) => (
          <ViewLink onClick={this.onRemoveRelation(store)}>
            {t('common:remove')}
          </ViewLink>
        ),
      },
    ];
  };

  setRowClassName = (record: TStore | TPromotionStoreRelation) => {
    let customClassName = '';
    const nonClosedStatuses = [STATUSES.TRADING, STATUSES.CLOSING_SOON];
    if (!nonClosedStatuses.includes(record.status)) {
      customClassName = 'promo-store-relation-row__closed-store';
    }
    return customClassName;
  };

  render() {
    const { promotionsStore, serverPagination, isLoading, t } = this.props;

    const { getSinglePromotionStoresField } = promotionsStore;

    return (
      <Table
        emptyElement={t('noStoresSelected')}
        setRowClassName={this.setRowClassName}
        scroll={{ x: 1300 }}
        id="table"
        pagination={serverPagination}
        className="doddle-table"
        columns={this.renderColumns()}
        data={getSinglePromotionStoresField}
        rowKey="storeId"
        onCell={null}
        loading={isLoading}
        customActionColumn
      />
    );
  }

}

export default compose(
  withTranslation('promotions'),
  inject('promotionsStore'),
  observer
)(StoresTable);
