// @flow

import doddleStoresActions from 'doddleStores/actions';
import get from 'lodash/get';
import { toJS } from 'mobx';
import { updateSinglePrinter } from '_common/utils/utils';
import { Roles } from '_common/constants/acl';
import commonActions from '_common/actions';
import { FLAG_OPTIONS } from '../../../../../../_common/constants/appConfig';

export const printerRequiredBlock = (
  callback: (hideBlock?: boolean) => void,
  customValue: string,
  addTempPrinter: () => void,
  removeTempPrinters: (key: string, removeAll?: boolean) => void
) => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
  });

  return {
    infoRows: [
      {
        name: 'Printer required',
        translateKey: 'printerRequired',
        customValue,
        type: 'select',
        aclAccess: hasPermissions,
        localizeOptions: true,
        localizeValue: true,
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          if (
            updateObj.currentValue &&
            (
              get(
                updateObj,
                'data.singleStore.storeConfiguration.technicalConfiguration.printers'
              ) || []
            ).length
          ) {
            return;
          }
          if (updateObj.currentValue) {
            callback(true);
            addTempPrinter();
          } else {
            callback();
            removeTempPrinters('', true);
            doddleStoresActions.updateStoreById(
              updateObj.id,
              {
                storeConfiguration: {
                  technicalConfiguration: {
                    printers: [],
                  },
                },
              },
              true,
              get(updateObj, 'data.singleStore.revisionNumber')
            );
          }
        },
      },
    ],
    translateNS: 'stores',
    idPropName: 'singleStore.storeId',
    header: 'Printer required',
  };
};

export const printerDetailsBlock = (
  i: number,
  showError: (fieldName: string, value: boolean) => void,
  state: Object
) => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN, Roles.CUSTOMER_SUPPORT],
  });
  return {
    infoRows: [
      {
        name: 'Host',
        path: `singleStore.storeConfiguration.technicalConfiguration.printers[${i}].host`,
        aclAccess: hasPermissions,
        type: 'input',
        errorData: state,
        errorMessage: 'requiredField',
        showError,
        onUpdateClicked: (updateObj: Object) => {
          if (!String(updateObj.currentValue).length) {
            showError(
              `singleStore.storeConfiguration.technicalConfiguration.printers[${i}].host`,
              true
            );
            return true;
          }
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              technicalConfiguration: {
                printers: updateSinglePrinter(
                  toJS(
                    get(
                      updateObj.data,
                      `singleStore.storeConfiguration.technicalConfiguration.printers`
                    )
                  ),
                  i,
                  'host',
                  updateObj.currentValue
                ),
              },
            },
          });
        },
      },
      {
        name: 'Description',
        path: `singleStore.storeConfiguration.technicalConfiguration.printers[${i}].description`,
        aclAccess: hasPermissions,
        type: 'input',
        errorData: state,
        errorMessage: 'requiredField',
        showError,
        onUpdateClicked: (updateObj: Object) => {
          if (!String(updateObj.currentValue).length) {
            showError(
              `singleStore.storeConfiguration.technicalConfiguration.printers[${i}].description`,
              true
            );
            return true;
          }
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              technicalConfiguration: {
                printers: updateSinglePrinter(
                  toJS(
                    get(
                      updateObj.data,
                      `singleStore.storeConfiguration.technicalConfiguration.printers`
                    )
                  ),
                  i,
                  'description',
                  updateObj.currentValue
                ),
              },
            },
          });
        },
      },
      {
        name: 'Type',
        path: `singleStore.storeConfiguration.technicalConfiguration.printers[${i}].type`,
        aclAccess: hasPermissions,
        type: 'select',
        options: [
          {
            value: 'LABEL',
            label: 'LABEL',
          },
        ],
        errorData: state,
        errorMessage: 'requiredField',
        showError,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              technicalConfiguration: {
                printers: updateSinglePrinter(
                  toJS(
                    get(
                      updateObj.data,
                      `singleStore.storeConfiguration.technicalConfiguration.printers`
                    )
                  ),
                  i,
                  'type',
                  updateObj.currentValue
                ),
              },
            },
          });
        },
      },
    ],
    translateNS: 'stores',
    idPropName: 'singleStore.storeId',
    count: i + 1,
    header: 'Printer count',
  };
};
