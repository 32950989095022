import { apiClient, pureClient } from '_common/api/clients/clients';
import { HUMAN_READABLE_BUILD_NAME } from '_common/constants/appConfig';
import { includes } from 'lodash';

export default () =>
  Promise.all(
    [
      {
        client: apiClient,
        request: '/v3/stores/hc',
        displayName: 'API Gateway',
        testName: `api-gateway-${HUMAN_READABLE_BUILD_NAME}`,
      },
      {
        client: pureClient,
        request: 'https://api.amplitude.com',
        displayName: 'Amplitude Analytics',
        testName: 'amplitude',
      },
      {
        client: pureClient,
        request:
          'https://firebase.clients6.google.com/v1beta1/projects/store-application-7c4c0',
        displayName: 'Crash Reporting',
        testName: 'crash-reporting',
      },
    ].map(({ client, request, displayName, testName }) =>
      client
        .get(request)
        .then((d) => ({ ...d, displayName, testName }))
        .catch((e) => ({
          ...e,
          displayName,
          testName,
          message: e.message,
          isAxiosError:
            !e.response ||
            e.response.status >= 500 ||
            includes(e.response.data, 'ENOTFOUND'),
        }))
    )
  );
