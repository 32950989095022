import React, { Component } from 'react';
import { Select } from 'antd';
import { debounce } from 'lodash';
import { SelectAsyncWrapper } from './elements';
import { DropdownCover } from '../PlainStyles';
import { CloseOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';

type State = {
  isLoading: boolean,
  isOpened: boolean,
  searchValue: string,
  lastSearchValue: string,
};

type Props = {
  isDisabled?: boolean,
  options: Array<TOption>,
  onChange: string => void,
  placeholder: string,
  loadOptions: string => Promise<void | Array<TOption>>,
  isLoading: boolean,
  onSearchQueryChange?: string => void,
};

class SelectAsync extends Component<Props, State> {

  static defaultProps = {
    isDisabled: false,
  };

  selectRef: ?Object = null;

  initialState = {
    isLoading: false,
    isOpened: false,
    searchValue: '',
    lastSearchValue: '',
  };

  state = { ...this.initialState };

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { resetSelect, onSearchQueryChange } = this.props;
    const { lastSearchValue } = this.state;

    if (resetSelect !== prevProps.resetSelect) {
      this.setState({ ...this.initialState });
    }

    if (lastSearchValue !== prevState.lastSearchValue && onSearchQueryChange) {
      onSearchQueryChange(lastSearchValue);
    }
  }

  saveRefs = (ref: ?Object) => {
    this.selectRef = ref;
  };

  onBlur = (value?: string) => {
    const lastSearchValue =
      value !== undefined && typeof value === 'string' ? value : '';
    this.setState({
      lastSearchValue,
      searchValue: '',
      isOpened: false,
    });
    this.loadOptionsCallback.cancel();
  };

  onFocus = () => {
    const { lastSearchValue } = this.state;
    this.setState({
      searchValue: lastSearchValue,
      isOpened: true,
    });

    // Causes problems when input value overlaps placeholder
    /*const { options } = this.props;
    // Check if the search term is storeID
    const firstOptionValue = get(options, '[0].value');
    if (options.length > 1 || (options.length === 1 && firstOptionValue !== this.state.lastSearchValue)) {
      this.inputRef.value = this.state.lastSearchValue;
    }*/
  };

  onSearch = (value: string, setStateCallback?: Function) => {
    this.setState(
      {
        searchValue: value,
      },
      setStateCallback
    );

    this.loadOptionsCallback(value);
  };

  renderOptions = () =>
    this.props.options.map(option => (
      <Select.Option
        disabled={this.props.isLoading || this.state.isLoading}
        key={option.value}
      >
        {option.label}
      </Select.Option>
    ));

  loadOptionsCallback = debounce(value => {
    this.setState({
      isLoading: true,
    });

    this.props.loadOptions(value).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  }, 500);

  getPlaceholder = () => {
    const { isOpened, lastSearchValue, searchValue } = this.state;
    let { placeholder } = this.props;

    if (isOpened) {
      placeholder = searchValue ? searchValue : placeholder;
    } else if (lastSearchValue) {
      placeholder = `Filtered by ${lastSearchValue}`;
    }

    return placeholder;
  };

  focusSelect = () => {
    this.selectRef.focus();
  };

  blurSelect = () => {
    this.selectRef.blur();
  };

  clearSearch = () => {
    this.onSearch('', () => {
      this.selectRef.focus();
    });
  };

  forceBlur = () => {
    this.focusSelect();
    setTimeout(() => {
      this.blurSelect();
    }, 300);
  };

  onSelect = (selectedValue: any, option: Object) => {
    this.setState({
      selectedValue,
    });
    this.props.onChange(selectedValue, option);
    this.setState({
      isOpened: false,
    });
  };

  render() {
    const { isLoading, searchValue, isOpened } = this.state;
    const { isDisabled, isLoading: externalLoading } = this.props;

    return (
      <SelectAsyncWrapper>
        {isOpened && <DropdownCover onClick={this.forceBlur} />}
        <Select
          allowClear
          clearIcon={<CloseOutlined onClick={this.clearSearch} />}
          defaultActiveFirstOption={false}
          ref={this.saveRefs}
          onFocus={this.onFocus}
          filterOption={false}
          open={isOpened}
          onBlur={this.onBlur}
          showArrow={false}
          loading={isLoading || externalLoading}
          showSearch
          onSearch={this.onSearch}
          disabled={isDisabled}
          onSelect={this.onSelect}
          dropdownStyle={{ marginTop: '-3px' }}
          placeholder={this.getPlaceholder()}
          value={searchValue ? searchValue : undefined}
        >
          {this.renderOptions()}
        </Select>
        {isOpened ? (
          <UpOutlined onClick={this.blurSelect} />
        ) : (
          <DownOutlined onClick={this.focusSelect} />
        )}
      </SelectAsyncWrapper>
    );
  }

}

export default SelectAsync;
