import styled, { css } from 'styled-components';
import { Button } from '_common/components';
import { Radio } from 'antd';

export const SubText = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 13px;
  color: #6a6b6e;
`;

export const RowWrapper = styled.div`
  margin-top: 20px;
`;

export const FormItemWrapper = styled.div`
  margin-bottom: -15px;
`;

export const Line = styled.div`
  height: 2px;
  margin: 25px 0;
  background-color: #e6e7e8;
`;

export const SearchButton = styled(Button)`
  && {
    width: 160px;
    height: 46px;
  }
`;

export const SelectWrapper = styled.div`
  position: absolute; //todo add relative to parent
  width: 100%;
  z-index: 999;

  .doddle-react-select__dropdown-indicator,
  .doddle-react-select__indicator-separator {
    display: none;
  }

  .doddle-react-select__control {
    height: 40px;
    border: 2px solid #d8e1e8;
    box-shadow: none;

    &:hover {
      border-color: #d8e1e8;
      box-shadow: none;
    }
  }
`;

export const SelectTrigger = styled.div`
  width: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #d8e1e8;
  padding-left: 10px;
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  color: #8c8c8c;
  cursor: pointer;
  position: relative;

  i {
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 18px;
    color: #d1d2d2;
  }

  ${props =>
    props.selected &&
    css`
      font-family: ${props => props.theme.fonts.basic};
      font-size: 14px;
      font-weight: 500;
      color: #3e3f42;
      border-radius: 2px;
      background-color: #ffffff;
      border: solid 2px #542785;

      i {
        color: #542785;
      }
    `};
`;

export const StyledRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #542785;
  }

  .ant-radio-inner:after {
    background-color: #542785;
  }
`;

export const RadioWrapper = styled.div`
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #d8e1e8;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  margin-right: 10px;
  width: 160px;
  height: 40px;
  cursor: pointer;

  ${props =>
    props.selectedOption &&
    css`
      border: solid 1px #542785;
      span {
        color: #542785;
        font-weight: bold;
      }
    `};
`;

export const TableWrapper = styled.div`
  margin-top: 70px;
`;

export const FilterWrapper = styled.div`
  margin-bottom: 30px;
`;
