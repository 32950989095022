import React, { Component, Fragment } from 'react';
import { toJS } from 'mobx';
import { CompaniesStore, PromotionsStore } from 'stores';
import urls from '_common/routes/urls';
import GridWithFilters from '_common/components/GridWithFilters';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { EditOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import {
  Header,
  HeaderCaption,
  HeaderLeftPartHolder,
  HeaderUserCaptionHolder,
  EditLink,
  TitleLink,
  CreateButton,
} from '_common/components/ListView/elements';
import { ColumnProps } from 'antd/es/table';
import { RouterHistory } from 'react-router';
import EnablePromotionDialog from 'promotions/components/EnablePromotioDialog';
import DisablePromotionDialog from 'promotions/components/DisablePromotioDialog';
import stores from 'stores';
import { ActionButton } from '_common/components/PlainStyles';
import SendEmailDialog from 'promotions/components/SendEmailDialog';
import { get, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

type Props = {
  history: RouterHistory,
  promotionsStore: PromotionsStore,
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
};

type State = {
  isEnableDialogShown: boolean,
  isDisableDialogShown: boolean,
  isSendEmailDialogOpened: boolean,
  selectedPromotion: TPromotion | Object,
};

class PromotionsList extends Component<Props, State> {

  state = {
    isEnableDialogShown: false,
    isDisableDialogShown: false,
    isSendEmailDialogOpened: false,
    selectedPromotion: {},
  };

  componentDidUpdate(): void {
    const { promotionsEnabled } = this.props.companiesStore;
    if (!promotionsEnabled) {
      this.props.history.push(stores.aclStore.getDefaultRedirectUrl());
    }
  }

  componentWillUnmount() {
    this.props.promotionsStore.resetFilteredCollection();
  }

  renderFilters = () => [
    {
      label: this.props.t('title'),
      placeholder: this.props.t('searchByTitle'),
      type: 'input',
      key: 'title',
      path: 'title',
    },
  ];

  renderColumns = (): Array<ColumnProps> => {
    const { t } = this.props;

    return [
      {
        title: t('title'),
        key: 'title',
        fixed: 'left',
        width: 200,
        render: (promotion: TPromotion) => {
          return (
            promotion.title && (
              <TitleLink to={this.getActionRedirectLink(promotion)}>
                {promotion.title}
              </TitleLink>
            )
          );
        },
        sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
      },
      {
        title: t('offerType'),
        key: ['offer', 'offerText'],
        dataIndex: ['offer', 'offerText'],
      },
      {
        title: t('enable'),
        key: 'enabled',
        defaultSortOrder: 'ascend',
        align: 'center',
        render: (promotion: TPromotion) => {
          return (
            <Switch
              checked={promotion.enabled}
              onChange={this.onEnableChange(promotion)}
            />
          );
        },
        sorter: (a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1),
      },
      {
        title: t('dateRangeActive'),
        key: 'dateStart',
        render: ({ startDate, endDate }: TPromotion) => {
          const start = toJS(startDate);
          const end = toJS(endDate);
          if (start && end) {
            try {
              const formattedStart = moment.isMoment(start)
                ? start
                : moment(start);
              const formattedEnd = moment.isMoment(end) ? end : moment(end);
              return `${formattedStart.format(
                'YYYY-MM-DD'
              )} - ${formattedEnd.format('YYYY-MM-DD')}`;
            } catch (e) {
              return `${start} - ${end}`;
            }
          }
          return `${start} - ${end}`;
        },
      },
      {
        title: '',
        key: 'testEmail',
        render: (promotion: TPromotion) => {
          return (
            <ActionButton onClick={this.toggleSendEmailDialogOpened(promotion)}>
              {t('sendTestEmail')}
            </ActionButton>
          );
        },
      },
    ];
  };

  onCell = row => {
    return {
      onClick: () => {
        this.props.history.push(this.getActionRedirectLink(row));
      },
    };
  };

  getActionRedirectLink = row => {
    const { promotionId = '' } = row;
    return urls.promotions.singlePromotion.replace(':id', promotionId);
  };

  getTableData = () => {
    const {
      getFilteredPromotions,
      getPromotionsField,
      isFiltersEmpty,
    } = this.props.promotionsStore;

    return getFilteredPromotions.length || !isFiltersEmpty
      ? getFilteredPromotions
      : getPromotionsField;
  };

  goToCreatePromotion = () => {
    this.props.history.push(urls.promotions.createPromotion);
  };

  toggleSendEmailDialogOpened = (
    selectedPromotion: TPromotion | Object
  ) => () => {
    this.setState({
      selectedPromotion,
      isSendEmailDialogOpened: !isEmpty(selectedPromotion),
    });
  };

  onEnableChange = (selectedPromotion: TPromotion) => (enabled: boolean) => {
    this.setState({
      selectedPromotion,
      isDisableDialogShown: !enabled,
      isEnableDialogShown: enabled,
    });
  };

  closeEnableDisableDialogs = () => {
    this.setState({
      selectedPromotion: {},
      isDisableDialogShown: false,
      isEnableDialogShown: false,
    });
  };

  render() {
    const { companiesStore, promotionsStore, t } = this.props;

    const {
      isEnableDialogShown,
      selectedPromotion,
      isDisableDialogShown,
      isSendEmailDialogOpened,
    } = this.state;

    const tableData =
      promotionsStore.getFilteredPromotions.length ||
      !promotionsStore.isFiltersEmpty
        ? promotionsStore.getFilteredPromotions
        : promotionsStore.getPromotionsField;

    return (
      <Fragment key={companiesStore.promotionsEnabled}>
        <SendEmailDialog
          t={t}
          dialogProps={{
            isShown: isSendEmailDialogOpened,
            handleCancelClick: this.toggleSendEmailDialogOpened({}),
          }}
          sendTestEmail={promotionsStore.sendTestEmailById}
          promotionId={get(selectedPromotion, 'promotionId')}
        />
        <EnablePromotionDialog
          t={t}
          promotion={selectedPromotion}
          toggleEnablePromotion={promotionsStore.toggleEnablePromotion}
          dialogProps={{
            isShown: isEnableDialogShown,
            handleCancelClick: this.closeEnableDisableDialogs,
          }}
        />
        <DisablePromotionDialog
          t={t}
          promotion={selectedPromotion}
          toggleEnablePromotion={promotionsStore.toggleEnablePromotion}
          dialogProps={{
            isShown: isDisableDialogShown,
            handleCancelClick: this.closeEnableDisableDialogs,
          }}
        />
        <Header>
          <HeaderLeftPartHolder>
            <HeaderUserCaptionHolder>
              <HeaderCaption>{t('promotions')}</HeaderCaption>
            </HeaderUserCaptionHolder>
          </HeaderLeftPartHolder>
          <CreateButton onClick={this.goToCreatePromotion}>
            {t('createPromotion')}
          </CreateButton>
        </Header>
        <GridWithFilters
          loading={promotionsStore.isLoading}
          resetFilters={promotionsStore.resetFilters}
          uniqueDataId="promotionId"
          filterData={promotionsStore.getPromotionsField}
          onComplete={promotionsStore.setFilteredCollection}
          filters={this.renderFilters()}
          columns={this.renderColumns()}
          gridData={tableData}
          rowKey="promotionId"
          ignoreWrapper
          actionRedirectLink={this.getActionRedirectLink}
          onCell={this.onCell}
          emptyElement={t('noPromotions')}
          EditLinkComponent={
            <EditLink>
              <EditOutlined />
            </EditLink>
          }
        />
      </Fragment>
    );
  }

}

export default compose(
  withTranslation('promotions'),
  inject('promotionsStore', 'companiesStore'),
  observer
)(PromotionsList);
