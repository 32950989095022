import React, { Component } from 'react';
import { Progress } from 'antd';
import { Dialog } from '_common/components';

type Props = {
  promotion: TPromotion,
  toggleEnablePromotion: Function,
  dialogProps: TDialogProps,
  t: TTranslateFunc,
};

type State = {
  isProcessing: boolean,
};

class DisablePromotionDialog extends Component<Props, State> {

  initialState = {
    isProcessing: false,
  };

  state = { ...this.initialState };

  handleConfirm = () => {
    this.setState({
      isProcessing: true,
    });
    const { toggleEnablePromotion, promotion } = this.props;
    toggleEnablePromotion(promotion.promotionId, false).then(this.handleCancel);
  };

  renderContent = () =>
    this.state.isProcessing && (
      <Progress
        trailColor="#e6e7e8"
        strokeColor="#542785"
        percent={100}
        status="active"
        showInfo={false}
      />
    );

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
    this.props.dialogProps.handleCancelClick();
  };

  render() {
    const { promotion, dialogProps, t } = this.props;

    return (
      <Dialog
        {...dialogProps}
        justifyHeaderContent="center"
        headerText={`${t('disable')} ${promotion.title}`}
        displayCancelButton
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default DisablePromotionDialog;
