import styled, { css } from 'styled-components';
import { Button } from 'antd';

const primaryColor = props => props.theme.colors.primary;

const colors = css`
  background-color: ${primaryColor};
  color: ${props => props.theme.colors.white};
`;

const StyledBaseButton = styled(Button)`
  && {
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: ${props => props.theme.fonts.bold};
    font-size: 14px;

    &:hover {
      opacity: 0.7;
    }

    &:disabled,
    &:hover:disabled {
      opacity: 0.25;
    }
  }
`;

export const StyledButton = styled(StyledBaseButton)`
  && {
    ${colors};

    &:hover {
      ${colors};
    }
    

    &:focus,
    &:active {
      ${colors};
    }

    &:disabled,
    &:hover:disabled {
      ${colors};
    }
`;
