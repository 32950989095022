import styled from 'styled-components';

export const SearchResultsWrapper = styled.div`
  margin-top: -40px;
`;

export const GridFilterWrapper = styled.div`
  margin-bottom: 30px;
`;

export const RegularDiv = styled.div``;
