// @flow

import { apiClient, atsClient, pureClient } from '_common/api/clients/clients';
import { API_MAX_STORES_LIMIT } from '_common/constants/stores';
import {
  cleanObject,
  createStoreNormalizeData,
  updateStoreNormalizeData,
} from '_common/utils/utils';
import { ADMIN_TOOL_SERVICE_URL } from '_common/constants/appConfig';

const getSingleStoreById = async (
  storeId: string,
  sensitiveDataFlag: boolean = true
): Promise<TStore> => {
  const params = {};
  if (sensitiveDataFlag) {
    params.sensitiveDataFlag = sensitiveDataFlag;
  }
  const response = await apiClient.get(`/v3/stores/${storeId}`, { params });

  return response.data.resources;
};

const getStoresByFilters = async (
  filters: TStoresFilterParams,
  page: number = 1,
  limit: number = API_MAX_STORES_LIMIT
): Promise<{ pagination: TPaginationResponse, stores: Array<TStore> }> => {
  cleanObject(filters, ['', null, undefined]);

  // Workaround to get proper results - need to cut off country code
  if (filters.countrySubdivision) {
    const parts = filters.countrySubdivision.split('-');
    filters.countrySubdivision = parts.length >= 2 ? parts[1] : parts[0];
  }

  const response = await apiClient.get('/v3/stores/filter', {
    params: {
      ...filters,
      limit,
      offset: limit * (page - 1),
    },
  });

  return {
    pagination: response.data.pagination,
    stores: response.data.resources,
  };
};

const getStoresByCompany = async (
  companyId: string
): Promise<Array<TStore>> => {
  let page = 0;
  const config = {
    params: {
      limit: API_MAX_STORES_LIMIT,
      offset: API_MAX_STORES_LIMIT * page,
      includeSubCompanies: false,
      includeSensitiveData: false,
      includeOpeningHours: true,
    },
  };
  let response = await apiClient.get(`/v3/stores/company/${companyId}`, config);
  let resources = response.data.resources;

  while (response.data.pagination.hasNextPage) {
    config.params.offset = API_MAX_STORES_LIMIT * ++page;
    response = await apiClient.get(`/v3/stores/company/${companyId}`, config);
    resources = resources.concat(response.data.resources);
  }

  return resources;
};

const updateStoreById = async (
  storeId: string,
  data: TStore,
  companyId: string
): Promise<TStore> => {
  const body = updateStoreNormalizeData(data);
  const params = {
    method: 'stores.updateStore',
    params: JSON.stringify({ companyId, storeId }),
  };
  const response = await atsClient.patch(`/proxy-api`, body, { params });

  return response.data.resources;
};

const getStoreOpeningHoursById = async (storeId: string): Promise<TStore> => {
  const response = await atsClient.get(`/stores/${storeId}/availability`);
  return response.data.resource.openingHours;
};

const updateStoreOpeningHours = async (
  storeId: string,
  data: TStoreOpeningHours
): Promise<TStoreOpeningHours> => {
  const response = await atsClient.patch(
    `/stores/${storeId}/availability`,
    data
  );
  return response.data.openingHours;
};

const updateStorePhotos = async (
  storeId: string,
  companyId: string,
  data: TStorePhotos
): Promise<Object> => {
  const params = {
    method: 'stores.uploadPhotos',
    params: JSON.stringify({ companyId, storeId }),
  };
  const response = await atsClient.post(`/proxy-api`, data, { params });
  return response.data;
};

const approveStore = async (
  storeId: string,
  companyId: string
): Promise<boolean> => {
  let result = false;
  try {
    const params = {
      method: 'stores.approve',
      params: JSON.stringify({ companyId, storeId }),
    };
    await atsClient.post(`/proxy-api`, {}, { params });
    result = true;
  } catch (e) {
    console.error(e);
  }
  return result;
};

const setOpeningOrClosingDate = async ({
  storeId,
  companyId,
  setterMode,
  date,
  temporary,
}: {
  storeId: string,
  companyId: string,
  setterMode: string,
  date: string,
  temporary?: boolean,
}): Promise<TStore> => {
  const response = await atsClient.post(`/proxy-api`, null, {
    params: {
      method: `stores.${setterMode}`,
      params: JSON.stringify({
        companyId,
        storeId,
      }),
      date,
      temporary,
    },
  });
  return response.data.resources;
};

const createStore = async (companyId: string, data: any): Promise<any> => {
  const body = createStoreNormalizeData(data);
  const response = await apiClient.post(
    `/v3/stores/company/${companyId}`,
    body
  );
  return response.data.resources;
};

const getAddressByPostcode = async (postcode: string) => {
  const response = await apiClient.get(`/v1/geo/postcodes/gb/${postcode}`);

  return response.data;
};

const getLocationByPostcode = async (postcode: string) => {
  const response = await apiClient.get(
    `/v1/geo/postcodes/gb/${postcode}/location`
  );

  return response.data;
};

const getLoginQRCodePrivate = async (
  storeId: string,
  companyId: string,
  login?: string,
  token?: string
) => {
  const requestUrl = `/${companyId}/${storeId}/get-barcode-content/`;
  let response = {};
  const params = {};
  if (login) {
    params.login = login;
  }
  if (token) {
    response = await pureClient.get(`${ADMIN_TOOL_SERVICE_URL}${requestUrl}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
  } else {
    response = await atsClient.get(requestUrl, { params });
  }

  return response.data.resource.barcode;
};

export const sendLoginQRCodeAttached = async (
  companyId: string,
  storeId: string,
  emailAddress: string,
  login?: string
) => {
  const data = {
    storeId,
    emailAddress,
  };
  if (login) {
    // $FlowExpectedError
    data.login = login;
  }
  const response = await atsClient.post(
    `/${companyId}/send-barcode-email-attached/`,
    data
  );

  return response.data;
};

export const sendOnboardingEmail = (storeId: string) => {
  return atsClient.post(`stores/${storeId}/send-onboarding-email`);
};

export const uploadStoresFile = async (file: Object) => {
  const response = await atsClient.post('/stores/bulk-stores-upload', file);
  return response.data;
};

export default {
  approveStore,
  createStore,
  getSingleStoreById,
  updateStoreById,
  getStoreOpeningHoursById,
  updateStoreOpeningHours,
  updateStorePhotos,
  getStoresByCompany,
  getAddressByPostcode,
  getLocationByPostcode,
  setOpeningOrClosingDate,
  getStoresByFilters,
  getLoginQRCodePrivate,
  sendLoginQRCodeAttached,
  sendOnboardingEmail,
  uploadStoresFile,
};
