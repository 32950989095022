import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NotificationsWrapper = styled.div`
  z-index: 10001;
`;

export const NotificationItem = styled.div`
  height: 40px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  background-color: ${({ type }) =>
    type === 'success' ? '#79d85f' : '#ff3654'};
  .anticon {
    position: absolute;
    top: 12px;
    right: 14px;
    font-size: 14px;
  }
`;

export const NotificationLink = styled(Link)`
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  text-decoration: underline;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export const NotificationAction = styled.div`
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;
