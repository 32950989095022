import getUsersByOrgId from './getUsersByOrgId';
import getUserByLogin from './getUserByLogin';
import resetUser from './resetUser';
import suspendUser from './suspendUser';
import setFilteredCollection from './setFilteredCollection';
import getUsersByScope from './getUsersByScope';
import resetFilteredCollection from './resetFilteredCollection';
import getUsersCompaniesList from './getUsersCompaniesList';

export default {
  getUsersByOrgId,
  getUserByLogin,
  resetUser,
  suspendUser,
  setFilteredCollection,
  getUsersByScope,
  resetFilteredCollection,
  getUsersCompaniesList,
};
