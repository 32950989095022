// @flow

import React, { Component, Fragment } from 'react';
import { Input, InputLabel, GridWithFilters } from '_common/components';
import { Col, Row } from 'antd';
import { CustomerSectionTitle, FormWrapper, ErrorMessage } from './elements';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import {
  FilterWrapper,
  Line,
  SearchButton,
  TableWrapper,
} from '../Parcels/elements';
import parcelsActions from 'search/actions';
import { ParcelsStore } from 'stores';
import UIStore from '_common/stores/uiStore';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

type Props = {|
  parcelsStore: ParcelsStore,
  onSearchComplete?: () => {},
  onComplete: (Array<Object>, boolean) => void,
  filters: Array<TFilter>,
  columns: TColumns,
  onCell: Object => { onClick: Object => void },
  uiStore: UIStore,
  t: TTranslateFunc,
|};

type State = {|
  errorText: string,
|};

class CustomerSearch extends Component<Props, State> {

  state = {
    errorText: '',
  };

  getSearchHistory = () => {
    const {
      getSearchHistory,
      getFilteredSearchHistory,
    } = this.props.parcelsStore;

    const method = !this.getSearchFiler()
      ? getFilteredSearchHistory
      : getSearchHistory;

    return get(method['customers'], 'items', []);
  };

  getOriginalCollection = () => {
    const { getSearchHistory } = this.props.parcelsStore;

    return get(getSearchHistory['customers'], 'items', []);
  };

  getSearchFiler = () => {
    const { getFilteredSearchHistory } = this.props.parcelsStore;

    return get(getFilteredSearchHistory['customers'], 'isFiltersEmpty');
  };

  getIsSearchInit = () => {
    const { getSearchHistory } = this.props.parcelsStore;

    return get(getSearchHistory['customers'], 'isSearchInit', false);
  };

  handleSubmit = () => {
    const {
      uiStore: {
        customerSearchParams: { email, firstName, lastName },
      },
    } = this.props;

    const customerFirstName = firstName && firstName.trim();
    const customerLastName = lastName && lastName.trim();
    const customerEmail = email && email.trim();

    if (customerFirstName || customerLastName || customerEmail) {
      parcelsActions
        // $FlowExpectedError
        .getParcelsByUser(customerFirstName, customerLastName, customerEmail)
        .then(() => {
          this.props.onSearchComplete && this.props.onSearchComplete();
        });
    } else {
      this.setError();
    }
  };

  setError = () => {
    this.setState({
      errorText: this.props.t('enterSearchQuery'),
    });
  };

  resetError = () => {
    this.setState({
      errorText: '',
    });
  };

  onInputChange = () => {
    this.resetError();
  };

  render() {
    const {
      parcelsStore,
      onComplete,
      filters,
      columns,
      onCell,
      uiStore: { setCustomerSearchParams, customerSearchParams },
      t,
    } = this.props;
    const { errorText } = this.state;

    return (
      <Fragment>
        <CustomerSectionTitle>{t('customerDetails')}</CustomerSectionTitle>
        <Row>
          <FormWrapper>
            <Col span={24}>
              <Row align="middle" gutter={[16, 16]}>
                <Col span={7}>
                  <InputLabel htmlFor={'customerName'}>
                    {t('customerName')}
                  </InputLabel>
                </Col>
                <Col span={17}>
                  <Row gutter={5}>
                    <Col span={12}>
                      <Input
                        value={customerSearchParams.firstName}
                        id={'customerFirstName'}
                        type="text"
                        placeholder={t('enterFN')}
                        onChange={e => {
                          setCustomerSearchParams({
                            firstName: e.target.value,
                          });
                          this.onInputChange();
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        value={customerSearchParams.lastName}
                        id={'customerLastName'}
                        type="text"
                        placeholder={t('enterLN')}
                        onChange={e => {
                          setCustomerSearchParams({
                            lastName: e.target.value,
                          });
                          this.onInputChange();
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row align="middle" gutter={[16, 16]}>
                <Col span={7}>
                  <InputLabel htmlFor={'customerEmail'}>
                    {t('customerEmail')}
                  </InputLabel>
                </Col>
                <Col span={17}>
                  <Input
                    value={customerSearchParams.email}
                    id={'customerEmail'}
                    type="email"
                    placeholder={t('enterEmail')}
                    onChange={e => {
                      setCustomerSearchParams({
                        email: e.target.value,
                      });
                      this.onInputChange();
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </FormWrapper>
        </Row>
        {!!errorText.length && (
          <Fragment>
            <Line />
            <ErrorMessage>{errorText}</ErrorMessage>
          </Fragment>
        )}
        <Line />
        <SearchButton
          onClick={this.handleSubmit}
          loading={parcelsStore.isSearchInProgress}
          htmlType="submit"
        >
          {t('search')}
        </SearchButton>

        <TableWrapper>
          {(!!this.getSearchHistory().length || this.getIsSearchInit()) && (
            <GridWithFilters
              loading={parcelsStore.isSearchInProgress}
              resetFilters={false}
              uniqueDataId={'itemId'}
              filterData={this.getOriginalCollection()}
              onComplete={onComplete}
              filters={filters}
              columns={columns}
              gridData={this.getSearchHistory()}
              rowKey={'itemId'}
              onCell={onCell}
              FilterWrapperComponent={FilterWrapper}
              ignorePanel
            />
          )}
        </TableWrapper>
      </Fragment>
    );
  }

}

export default compose(
  inject('parcelsStore', 'uiStore'),
  withRouter,
  withTranslation('search'),
  observer
)(CustomerSearch);
