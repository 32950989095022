// @flow

import storage, { TOKEN_KEY } from 'storage';
import env from '@beam-australia/react-env';
import { Base64 } from 'js-base64';
import createClient from './clientFactory';
import apiEndpoints from '_common/api/endpoints';
import commonActions from '_common/actions';
import { get, endsWith } from 'lodash';
import { API_ERROR_CODES } from '_common/constants/apiErrorResponces';
import { ADMIN_TOOL_SERVICE_URL } from '_common/constants/appConfig';

const doddleApiKey = env('DODDLE_API_KEY') || '';
const doddleApiSecret = env('DODDLE_API_SECRET') || '';
const loqateApiKey = env('LOQATE_API_KEY') || '';

const pureClient = createClient({
  baseURL: apiEndpoints.LEGACY_DODDLE_API_URL,
});

const loqateClient = createClient({
  baseURL: apiEndpoints.LOQATE_API_URL,
  params: {
    Key: loqateApiKey,
  },
});

const authClient = createClient({
  baseURL: apiEndpoints.LEGACY_DODDLE_API_URL,
  headers: {
    Pragma: 'no-cache',
    Authorization: `Basic ${Base64.encode(
      `${doddleApiKey}:${doddleApiSecret}`
    )}`,
  },
});

const apiClient = createClient({
  baseURL: apiEndpoints.LEGACY_DODDLE_API_URL,
  headers: {
    Pragma: 'no-cache',
    'cache-control': 'no-cache',
  },
});

const lambdaApiClient = createClient({
  baseURL: apiEndpoints.DODDLE_API_URL,
  headers: {
    //Pragma: 'no-cache',
    'Content-Type': 'application/json',
  },
});

// Admin tool service API client
const atsClient = createClient({
  baseURL: `${apiEndpoints.LEGACY_DODDLE_API_URL}${ADMIN_TOOL_SERVICE_URL}`,
  headers: {
    Pragma: 'no-cache',
    'cache-control': 'no-cache',
  },
});

const interceptorsReq = (config: Object) => {
  return {
    ...config,
    headers: {
      ...config.headers,
    },
    params: {
      ...config.params,
    },
  };
};

const apiClientInterceptorsReq = (config: Object) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${storage.get(TOKEN_KEY)}`,
    },
  };
};

const interceptorsReqError = (error: any) => {
  Promise.reject(error);
};

const interceptorsRes = response => {
  return response;
};

const interceptorsResError = (error: Object) => {
  const errorStatus = get(error, 'response.status');
  if (errorStatus) {
    switch (errorStatus) {
      case 401: {
        if (
          error.response.data.errors.some(
            err => err.code === API_ERROR_CODES.InvalidUserCredentials
          ) ||
          endsWith(error.response.config.url, 'hc')
        ) {
          return Promise.reject(error);
        }
        commonActions.logout();
        break;
      }
      default: {
        console.error(error);
      }
    }
  } else {
    console.error(error);
  }

  return Promise.reject(error);
};

authClient.interceptors.request.use(interceptorsReq, interceptorsReqError);
authClient.interceptors.response.use(interceptorsRes, interceptorsResError);

apiClient.interceptors.request.use(
  apiClientInterceptorsReq,
  interceptorsReqError
);
apiClient.interceptors.response.use(interceptorsRes, interceptorsResError);

atsClient.interceptors.request.use(
  apiClientInterceptorsReq,
  interceptorsReqError
);
atsClient.interceptors.response.use(interceptorsRes, interceptorsResError);

lambdaApiClient.interceptors.request.use(
  apiClientInterceptorsReq,
  interceptorsReqError
);
lambdaApiClient.interceptors.response.use(
  interceptorsRes,
  interceptorsResError
);

loqateClient.interceptors.request.use(interceptorsReq);

export {
  authClient,
  apiClient,
  lambdaApiClient,
  loqateClient,
  pureClient,
  atsClient,
};
