import commonActions from '_common/actions';
import { Roles } from '_common/constants/acl';
import {
  allowEmptyValidator,
  boolToWord,
  normalizeUrl,
  requiredValidator,
  validatePhoneNumber,
  validateUrl,
} from '_common/utils/utils';
import { FLAG_OPTIONS } from '_common/constants/appConfig';

export const detailsBlock = (
  companiesList: Array<TOption>,
  errors: Object,
  setError: Function,
  currentState: Object
) => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
  });
  return {
    infoRows: [
      {
        name: 'Company ID',
        path: 'singleCompany.companyId',
        aclAccess: false,
      },
      {
        name: 'Company name',
        path: 'singleCompany.companyName',
        type: 'input',
        errorMessage: 'inputCompanyName',
        errorData: errors,
        showError: setError,
        aclAccess: hasPermissions,
        validator: requiredValidator,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany && updateCompany(id, { companyName: currentValue });
        },
      },
      {
        name: 'External company ID',
        path: 'singleCompany.externalCompanyId',
        type: 'input',
        aclAccess: hasPermissions,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, { externalCompanyId: currentValue });
        },
      },
      {
        name: 'Parent company',
        path: 'singleCompany.parent',
        type: 'select',
        aclAccess: hasPermissions,
        options: companiesList,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany, createHierarchyFromCompany },
        }: TDetailsUpdate) => {
          if (createHierarchyFromCompany) {
            createHierarchyFromCompany(currentValue).then(
              (hierarchy: Array<string> | false) => {
                if (hierarchy) {
                  updateCompany &&
                    updateCompany(id, { parent: currentValue, hierarchy });
                }
              }
            );
          }
        },
      },
      {
        name: 'Hierarchy',
        path: 'singleCompany.hierarchy',
        compute: (hierarchy: Array<string>) =>
          hierarchy ? hierarchy.join(', ') : '',
        type: 'input',
        aclAccess: false,
      },
      {
        name: 'Send onboarding email',
        path: 'singleCompany.storeOnboardingEmailEnabled',
        aclAccess: currentState.canEditOnbaordingEmailFlag && hasPermissions,
        type: 'select',
        compute: boolToWord,
        localizeOptions: true,
        localizeValue: true,
        options: FLAG_OPTIONS,
        onUpdateClicked: ({ id, currentValue, data }: TDetailsUpdate) => {
          data.toggleStoreOnboardingEmail &&
            data.toggleStoreOnboardingEmail(id);
        },
      },
    ],
    translateNS: 'company',
    idPropName: 'singleCompany.companyId',
    header: 'Company',
  };
};

export const contactBlock = (
  errors: Object,
  setError: Function
): TInfoBlock => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
  });
  return {
    infoRows: [
      {
        name: 'Website URL',
        path: 'singleCompany.website',
        type: 'input',
        errorData: errors,
        errorMessage: 'invalidUrl',
        showError: setError,
        validator: allowEmptyValidator(validateUrl),
        aclAccess: hasPermissions,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, { website: normalizeUrl(currentValue) });
        },
      },
      {
        name: 'Affiliate link URL',
        path: 'singleCompany.affiliateLink',
        type: 'input',
        errorData: errors,
        errorMessage: 'invalidUrl',
        showError: setError,
        validator: allowEmptyValidator(validateUrl),
        aclAccess: hasPermissions,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, { affiliateLink: normalizeUrl(currentValue) });
        },
      },
      {
        name: 'Terms and conditions URL',
        path: 'singleCompany.termsAndConditionsUrl',
        type: 'input',
        errorData: errors,
        errorMessage: 'invalidUrl',
        showError: setError,
        validator: allowEmptyValidator(validateUrl),
        aclAccess: hasPermissions,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany &&
            updateCompany(id, {
              termsAndConditionsUrl: normalizeUrl(currentValue),
            });
        },
      },
      {
        name: 'Contact number',
        path: 'singleCompany.phoneNumber',
        type: 'input',
        errorData: errors,
        errorMessage: 'invalidPhone',
        showError: setError,
        validator: validatePhoneNumber,
        aclAccess: hasPermissions,
        onUpdateClicked: ({
          id,
          currentValue,
          data: { updateCompany },
        }: TDetailsUpdate) => {
          updateCompany && updateCompany(id, { phoneNumber: currentValue });
        },
      },
    ],
    translateNS: 'company',
    idPropName: 'singleCompany.companyId',
    header: 'Contact',
  };
};
