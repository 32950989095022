// @flow

import { apiClient, atsClient } from '_common/api/clients/clients';
import { chunk } from 'lodash';
import { concurrentRequests } from '_common/utils/utils';

const createPromotion = async (body: Object): Promise<TPromotion> => {
  const response = await atsClient.post(`/create-promotion/`, body);
  return response.data;
};

const getPromotions = async (companyId: string): Promise<Array<TPromotion>> => {
  const params = {
    params: JSON.stringify({
      companyId,
    }),
    method: 'promotions.getPromotionsByCompany',
  };
  const response = await atsClient.get('/proxy-api/', { params });
  return response.data.resources;
};

const getPromotionsByStoreId = async (
  storeId: string
): Promise<Array<TPromotion>> => {
  const response = await atsClient.get(`/${storeId}/get-store-promotions/`);
  return response.data.resources;
};

const getPromotion = async (promotionId: string): Promise<TPromotion> => {
  const response = await atsClient.get(`/promotions/${promotionId}`);
  return response.data.resource;
};

const getPromotionStoreRelations = async (
  promotionId: string,
  page: number = 1,
  limit: number = 10
): Promise<{
  pagination: TPaginationResponse,
  relations: Array<TPromotionStoreRelation>,
}> => {
  const response = await apiClient.get(
    `/v1/promotions/${promotionId}/store/relations`,
    {
      params: {
        storeData: 'true',
        limit,
        offset: limit * (page - 1),
      },
    }
  );

  return {
    pagination: response.data.pagination,
    relations: response.data.resources,
  };
};

const updatePromotion = async (
  promotionId: string,
  body: Object
): Promise<TPromotion> => {
  const response = await atsClient.patch(
    `/${promotionId}/update-promotion/`,
    body
  );
  return response.data;
};

const uploadProductImage = async (promotionId: string, data: Object) => {
  const response = await atsClient.post(
    `/promotions/upload/${promotionId}`,
    data
  );
  return response.data.resource;
};

const addStoresByIds = async (promotionId: string, storeIds: Array<string>) => {
  const chunkSize = 500;
  let resources = [];
  const chunks = chunk(storeIds, chunkSize);
  const requestClosure = (chunk: Array<string>) =>
    atsClient
      .post(`/promotions/${promotionId}/relations`, {
        stores: chunk,
      })
      .then(response => {
        resources = resources.concat(response.data.resources);
      });
  await concurrentRequests(chunks, requestClosure, 1);
  return resources;
};

const removeSingleRelation = async (
  promotionId: string,
  relationId: string
) => {
  const response = await apiClient.delete(
    `/v1/promotions/store/relations/${relationId}`
  );
  return response.data;
};

const getOffersByCompanyId = async (
  companyId: string
): Promise<Array<TPromotionOffer>> => {
  const response = await atsClient.get(
    `/promotions/offers/company/${companyId}`
  );
  return response.data.resources;
};

const sendTestEmailById = async (
  promotionId: string,
  email: string
): Promise<Object> => {
  const response = await atsClient.post(
    `/promotions/${promotionId}/${email}/send-preview`
  );
  return response.data;
};

const sendTestEmailByData = async (
  data: Object,
  email: string
): Promise<Object> => {
  const response = await atsClient.post(
    `/promotions/${email}/send-preview`,
    data
  );
  return response.data;
};

const toggleEnableOffer = async (
  offerId: string,
  enabled: boolean
): Promise<TPromotionOffer> => {
  const response = await apiClient.post(
    `/v1/promotions/offers/${offerId}/${enabled ? 'enable' : 'disable'}`
  );
  return response.data.resource;
};

const createOffer = async (companyId: string, data: Object) => {
  const response = await apiClient.post(`/v1/promotions/offers`, data);
  return response.data.resource;
};

const deleteOffer = async (offerId: string) => {
  const response = await apiClient.delete(`/v1/promotions/offers/${offerId}`);
  return response.data.resource;
};

const getLibraryAssets = async (companyId: string) => {
  const response = await atsClient.get(
    `/promotions/get-images-library/${companyId}`
  );
  return response.data.resources;
};

export default {
  getLibraryAssets,
  getPromotionsByStoreId,
  getPromotionStoreRelations,
  getPromotion,
  getPromotions,
  createPromotion,
  updatePromotion,
  uploadProductImage,
  removeSingleRelation,
  addStoresByIds,
  getOffersByCompanyId,
  sendTestEmailById,
  sendTestEmailByData,
  toggleEnableOffer,
  createOffer,
  deleteOffer,
};
