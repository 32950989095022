import styled from 'styled-components';
import Button from '_common/components/Button/Button';
import { EditOutlined } from '@ant-design/icons';

export const Bordered = styled.div`
  border: 1px solid black;
`;

export const Header = styled.strong`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 18px;
  line-height: 18px;
  color: #494949;
  display: block;
  padding-bottom: 22px;
  border-bottom: 1px solid #e6e7e8;
  margin-bottom: ${props => (props.mb ? props.mb : 0)}px;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: ${({ justify = 'space-between' }) => justify};
`;

export const DataViewWrapper = styled.div`
  position: relative;
`;

export const CaptionPart = styled.div`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.book};
  color: ${props => props.theme.colors.shade2};
  font-weight: bold;
  line-height: 14px;
`;

export const DataPart = styled.div`
  width: 57.5%;
  font-family: ${props => props.theme.fonts.book};
  color: #494949;
  line-height: 14px;
  padding: 18px 18px 18px 0;
  font-size: 14px;
  overflow-x: auto;
`;

export const EditableValueHolder = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 44px;
  align-items: center;
  position: relative;
  width: 57.5%;
  word-break: break-all;
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  line-height: 14px;
  color: #494949;
`;

export const EditableTimePickerValueHolder = styled(EditableValueHolder)`
  justify-content: space-around;
`;

export const EditableDataHolder = styled.div`
  flex: 1;
  padding-right: 5px;
  width: 100%;

  .ant-checkbox-wrapper {
    margin-bottom: 3px;
  }
`;

export const UpdateButton = styled(Button)`
  && {
    font-family: ${props => props.theme.fonts.book};
    font-size: 13px;
    line-height: 13px;
    padding: 10px 8px;
    border-radius: 1px;
  }
`;

export const EditStateBlock = styled.div`
  padding: ${({ paddingVertical }) =>
      paddingVertical !== undefined ? paddingVertical : 7}px
    0;
  display: flex;
  width: ${({ width }) => (width ? width : 57.5)}%;
  align-items: center;
  .ant-legacy-form-extra {
    font-size: 12px;
  }
`;

export const EditIcon = styled(EditOutlined)`
  transition: 0.3s;
  position: absolute;
  right: 10px;
  top: 18px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const TimePickersHolder = styled.div`
  display: flex;
  font-size: 26px;
  line-height: 26px;

  .ant-picker,
  .ant-time-picker {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const InfoColumn = styled.div`
  flex-direction: column;
  border-bottom: 1px solid #e6e7e8;
  display: flex;
`;

export const ErrorMessage = styled.div`
  margin-bottom: 10px;
  color: #ff0000;
  text-align: right;
`;

export const ErrorHolder = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #f5222d;
  margin-bottom: 5px;
  text-align: center;
`;

export const CaptionHolder = styled.div`
  width: 42.5%;
  padding: ${props => (props.noPadding ? '0' : '18px 18px 18px 0')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
