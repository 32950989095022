import { useRef, useEffect } from 'react';

const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    let unMountFn = null;
    if (didMountRef.current) {
      // eslint-disable-next-line
      unMountFn = fn();
    } else {
      didMountRef.current = true;
    }
    if (typeof unMountFn === 'function') {
      return unMountFn;
    }
    // eslint-disable-next-line
  }, inputs);
};

export default useDidUpdateEffect;
