// @flow

import { apiClient, atsClient } from '_common/api/clients/clients';

const getAdminToolRoles = async (): Promise<Array<string>> => {
  const response = await apiClient.get(
    '/v1/auth-management/staff/roles?q=Admin_Tool'
  );

  return response.data.resources;
};

const getScopes = async (): Promise<Array<TOption>> => {
  const response = await apiClient.get(
    '/v1/auth-management/staff/scopes?limit=100000&mark=0'
  );

  return response.data.resources.map(scope => ({ value: scope, label: scope }));
};

const getScopesByQuery = async (
  query: string = ''
): Promise<Array<TOption>> => {
  const response = await apiClient.get(
    `/v1/auth-management/staff/scopes?q=${query}&limit=100`
  );

  return response.data.resources.map(scope => ({ value: scope, label: scope }));
};

const getCustomRole = async (baseRole: string, companyId: string) => {
  const response = await atsClient.get(
    `/staff/roles/${baseRole}/company/${companyId}`
  );

  return response.data.resource;
};

const updateRole = async (role: string, data: Object) => {
  const response = await atsClient.patch(`/staff/roles/${role}`, data);

  return response.data.resources;
};

export default {
  getAdminToolRoles,
  getScopes,
  getScopesByQuery,
  getCustomRole,
  updateRole,
};
