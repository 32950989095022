import React, { Component, Fragment } from 'react';
import { Input } from '_common/components';
import { CustomColourPicker, ColorPickerWrapper, Indicator } from './elements';
import { DropdownCover } from '_common/components/PlainStyles';
import { Form } from 'antd';
import { onColorPickerChange, parseRGBA } from '_common/utils/utils';

type Props = {
  placeholder?: string,
  onChange?: Function,
  initialRGBA?: Object,
  form?: Form,
  fieldName?: string,
  onColorChangeApplied?: Function,
};

type State = {
  isOpened: boolean,
  color: string,
};

class ColourPicker extends Component<Props, State> {

  static defaultProps = {
    placeholder: '',
    initialRGBA: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  };

  state = {
    isOpened: false,
    color: '',
  };

  componentDidMount(): void {
    this.changeColor({
      rgb: this.props.initialRGBA,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    if (!prevProps.form && this.props.form) {
      this.changeColor({
        rgb: this.props.initialRGBA,
      });
    }
  }

  toggleOpened = () => {
    if (this.state.isOpened && this.props.onColorChangeApplied) {
      this.props.onColorChangeApplied();
    }
    this.setState(state => ({
      isOpened: !state.isOpened,
    }));
  };

  changeColor = (color: Object): void => {
    const { form, fieldName, onChange } = this.props;
    const value = color.rgb;
    this.setState({
      color: value,
    });
    // Handle onChange if color picker is rendered in Form component
    if (form && fieldName) {
      onColorPickerChange(form, fieldName)(color);
    }
    // custom handler
    else if (onChange) {
      onChange(color);
    }
  };

  renderControl = () => {
    const { placeholder } = this.props;
    const { color } = this.state;
    const controlProps = {
      placeholder,
      readOnly: 'readonly',
      onFocus: this.toggleOpened,
    };
    controlProps.value = color ? parseRGBA(color) : '';
    return <Input {...controlProps} />;
  };

  render() {
    const { isOpened, color } = this.state;

    return (
      <ColorPickerWrapper>
        {this.renderControl()}
        <Indicator color={color} onClick={this.toggleOpened} />
        {isOpened && (
          <Fragment>
            <DropdownCover onClick={this.toggleOpened} />
            <CustomColourPicker
              color={color}
              onChangeComplete={this.changeColor}
            />
          </Fragment>
        )}
      </ColorPickerWrapper>
    );
  }

}

export default ColourPicker;
