// @flow

import React from 'react';
import {
  AddButton,
  PrinterHolder,
  Column,
  ColumnsHolder,
  LeftColumn,
  RemoveButton,
  AddPrinterToStoreButton,
  CaptionPart,
  InfoRow,
  DataPart,
  TemporaryPrinterHeader,
  TemporaryPrinterHolder,
} from '_common/components/DetailsPage/elements';
import { getInfoBlock } from '_common/utils/utils';
import { printerDetailsBlock, printerRequiredBlock } from './config';
import DoddleStoresStore from '_common/stores/doddleStoresStore';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { boolToWord } from '_common/utils/utils';
import get from 'lodash/get';
import doddleStoresActions from 'doddleStores/actions';
import { toJS } from 'mobx';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input } from '_common/components';
import { withTranslation } from 'react-i18next';

type Props = {|
  doddleStores: DoddleStoresStore,
  form: Form,
  t: TTranslateFunc,
|};

type State = {|
  showPrintersBlock: boolean,
  notSubmittedPrinters: Object,
|};

class DoddleStoreDetailsPrinters extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    const printers = get(
      this.props.doddleStores,
      'singleStore.storeConfiguration.technicalConfiguration.printers',
      []
    );

    this.state = {
      showPrintersBlock: Boolean(printers.length),
      notSubmittedPrinters: {},
    };
  }

  addPrinter = () => {
    this.setState(prevState => ({
      notSubmittedPrinters: {
        ...prevState.notSubmittedPrinters,
        [Date.now()]: {
          type: '',
          host: '',
          description: '',
        },
      },
    }));
  };

  removePrinter = (i: number) => {
    const { doddleStores } = this.props;
    const { notSubmittedPrinters } = this.state;
    const arr =
      toJS(
        get(
          doddleStores,
          'singleStore.storeConfiguration.technicalConfiguration.printers'
        )
      ) || [];
    arr.splice(i, 1);
    doddleStoresActions.updateStoreById(
      get(doddleStores, 'singleStore.storeId'),
      {
        storeConfiguration: {
          technicalConfiguration: {
            printers: [...arr],
          },
        },
      },
      true,
      get(doddleStores, 'singleStore.revisionNumber')
    );
    if (!arr.length && !notSubmittedPrinters.length) {
      this.setState({
        showPrintersBlock: false,
      });
    }
  };

  removeNotSubmittedPrinter = (key: string, removeAll?: boolean) => {
    this.setState(prevState => {
      if (removeAll) {
        return {
          notSubmittedPrinters: {},
        };
      }
      const notSubmittedPrinters = prevState.notSubmittedPrinters;
      delete notSubmittedPrinters[key];
      const conditionalObj = {};
      if (!Object.keys(notSubmittedPrinters).length) {
        const { doddleStores } = this.props;
        if (
          !(
            toJS(
              get(
                doddleStores,
                'singleStore.storeConfiguration.technicalConfiguration.printers'
              )
            ) || []
          ).length
        ) {
          conditionalObj.showPrintersBlock = false;
        }
      }
      return {
        notSubmittedPrinters,
        ...conditionalObj,
      };
    });
  };

  showHidePrintersBlock = showPrintersBlock => {
    this.setState({
      showPrintersBlock,
    });
  };

  addSelectedPrinterToStore = (key: string) => {
    const {
      form: { validateFields },
      doddleStores,
    } = this.props;

    validateFields(
      [`${key}.host`, `${key}.description`, `${key}.type`],
      (errors, values) => {
        if (!errors) {
          doddleStoresActions.updateStoreById(
            get(doddleStores, 'singleStore.storeId'),
            {
              storeConfiguration: {
                technicalConfiguration: {
                  printers: [
                    ...(get(
                      doddleStores,
                      'singleStore.storeConfiguration.technicalConfiguration.printers'
                    ) || []),
                    {
                      ...values[key],
                    },
                  ],
                },
              },
            },
            true,
            get(doddleStores, 'singleStore.revisionNumber'),
            () => this.removeNotSubmittedPrinter(key)
          );
        }
      }
    );
  };

  setFormFieldFromSelect = (key, val) => {
    this.props.form.setFields({
      [key]: {
        value: val ? val.value : undefined,
      },
    });
  };

  getPrinterBlock = (key: string, i: number) => {
    const {
      form: { getFieldDecorator },
      t,
    } = this.props;

    return (
      <TemporaryPrinterHolder key={key}>
        <TemporaryPrinterHeader>
          {t('notSubmittedPrinter')} #{i + 1}
        </TemporaryPrinterHeader>
        <InfoRow>
          <CaptionPart>{t('host')}</CaptionPart>
          <DataPart>
            <Form.Item>
              {getFieldDecorator(`${key}.host`, {
                rules: [
                  {
                    required: true,
                    message: t('inputHost'),
                  },
                ],
              })(<Input placeholder={t('host')} />)}
            </Form.Item>
          </DataPart>
        </InfoRow>
        <InfoRow>
          <CaptionPart>{t('common:description')}</CaptionPart>
          <DataPart>
            <Form.Item>
              {getFieldDecorator(`${key}.description`, {
                rules: [
                  {
                    required: true,
                    message: t('inputDescription'),
                  },
                ],
              })(<Input placeholder={t('common:description')} />)}
            </Form.Item>
          </DataPart>
        </InfoRow>
        <InfoRow>
          <CaptionPart>{t('common:type')}</CaptionPart>
          <DataPart>
            <Form.Item>
              {getFieldDecorator(`${key}.type`, {
                rules: [
                  {
                    required: true,
                    message: t('inputType'),
                  },
                ],
              })(
                <Select
                  selectPlaceholder={t('common:type')}
                  onSelectOption={val => {
                    this.setFormFieldFromSelect(`${key}.type`, val);
                  }}
                  options={[
                    {
                      value: 'LABEL',
                      label: 'LABEL',
                    },
                  ]}
                  inputPlaceholder={t('common:type')}
                />
              )}
            </Form.Item>
          </DataPart>
        </InfoRow>
        <AddPrinterToStoreButton
          onClick={() => this.addSelectedPrinterToStore(key)}
        >
          {t('addToStore')}
        </AddPrinterToStoreButton>
        <RemoveButton onClick={() => this.removeNotSubmittedPrinter(key)}>
          {t('common:remove')}
        </RemoveButton>
      </TemporaryPrinterHolder>
    );
  };

  render() {
    const { doddleStores, t } = this.props;
    const { showPrintersBlock, notSubmittedPrinters } = this.state;
    const printers = get(
      doddleStores,
      'singleStore.storeConfiguration.technicalConfiguration.printers',
      []
    );

    return (
      <Form>
        <ColumnsHolder>
          <LeftColumn>
            {getInfoBlock(
              printerRequiredBlock(
                this.showHidePrintersBlock,
                boolToWord(showPrintersBlock),
                this.addPrinter,
                this.removeNotSubmittedPrinter
              ),
              true,
              doddleStores,
              false,
              doddleStores.singleStore
            )}
          </LeftColumn>
          {showPrintersBlock ? (
            <Column>
              {Object.keys(notSubmittedPrinters).map(this.getPrinterBlock)}
              {printers.map((cur: TPrinter, i: number) => {
                return (
                  <PrinterHolder key={Object.keys(cur)[i]}>
                    {getInfoBlock(
                      printerDetailsBlock(
                        i,
                        (fieldName, value) =>
                          this.setState({
                            [fieldName]: value,
                          }),
                        this.state
                      ),
                      false,
                      doddleStores
                    )}
                    <RemoveButton onClick={() => this.removePrinter(i)}>
                      {t('common:remove')}
                    </RemoveButton>
                  </PrinterHolder>
                );
              })}
              <AddButton onClick={this.addPrinter}>{t('addPrinter')}</AddButton>
            </Column>
          ) : null}
        </ColumnsHolder>
      </Form>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  Form.create(),
  observer
)(DoddleStoreDetailsPrinters);
