// @flow

import React, { Component } from 'react';
import { Col } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  TabTitle,
  RowWrapper,
  SeparationLine,
  ButtonNext,
  FixedWidthRow,
} from '_common/components/TabsForm/elements';
import { InputLabel } from '_common/components';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import FileInput from '_common/components/FileInput';
import { setFileField } from '_common/utils/utils';

type Props = {
  form: Form,
  onNextTab: string => void,
  t: TTranslateFunc,
};

class Photos extends Component<Props> {

  onSubmit = e => {
    e.preventDefault();

    const { form, onNextTab } = this.props;

    form.validateFields(err => {
      if (!err) {
        onNextTab('printers');
      }
    });
  };

  getFormattedData = () => {
    const { form } = this.props;
    let data = null;

    form.validateFields((err, values) => {
      if (err) return;
      data = values;
    });

    return data;
  };

  render() {
    const { form, t } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.onSubmit}>
        <div>
          <TabTitle>{t('photos')}</TabTitle>
          <Col>
            <RowWrapper>
              <FixedWidthRow width={170}>
                <InputLabel>{t('common:defaultImage')}</InputLabel>
              </FixedWidthRow>
              <FixedWidthRow width={380}>
                <Form.Item>
                  {getFieldDecorator('default')(
                    <FileInput t={t} onFile={setFileField(form, 'default')} />
                  )}
                </Form.Item>
              </FixedWidthRow>
            </RowWrapper>
            <RowWrapper>
              <FixedWidthRow width={170}>
                <InputLabel>{t('common:thumbnailImage')}</InputLabel>
              </FixedWidthRow>
              <FixedWidthRow width={380}>
                <Form.Item>
                  {getFieldDecorator('thumbnail')(
                    <FileInput t={t} onFile={setFileField(form, 'thumbnail')} />
                  )}
                </Form.Item>
              </FixedWidthRow>
            </RowWrapper>
            <RowWrapper>
              <FixedWidthRow width={170}>
                <InputLabel>{t('common:wideImage')}</InputLabel>
              </FixedWidthRow>
              <FixedWidthRow width={380}>
                <Form.Item>
                  {getFieldDecorator('wide')(
                    <FileInput t={t} onFile={setFileField(form, 'wide')} />
                  )}
                </Form.Item>
              </FixedWidthRow>
            </RowWrapper>
          </Col>
          <SeparationLine />
          <ButtonNext htmlType="submit">{t('common:next')}</ButtonNext>
        </div>
      </Form>
    );
  }

}

export default compose(Form.create(), observer)(Photos);

// import React, { Component } from 'react';
//
// class Photos extends Component {
//
//   onFile = (e) => {
//     console.log(e.target.files);
//   };
//
//   render() {
//     return (
//       <div>
//         <label className="custom-file-upload">
//           <input type="file" onChange={this.onFile}/>
//           Custom Upload
//         </label>
//       </div>
//     );
//   }
//
// }
//
// export default Photos;
