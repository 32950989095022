// @flow

import React, { Component } from 'react';
import TabsForm, { COLLECT_DATA_SOURCES } from '_common/components/TabsForm';
import PromotionGeneral from './General';
import PromotionStoresAssignment from 'promotions/components/StoresAssignment';
import PromotionMarketingMessage from './MarketingMessage';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import stores, { ACLStore, PromotionsStore } from 'stores';
import type { RouterHistory } from 'react-router';
import { hasOwnKeys } from '_common/utils/utils';
import { isEmpty } from 'lodash';
import links from '_common/routes/urls';
import { withTranslation } from 'react-i18next';
import CustomHeader from 'promotions/components/CustomHeader';

type Props = {
  promotionsStore: PromotionsStore,
  aclStore: ACLStore,
  history: RouterHistory,
  t: TTranslateFunc,
};

class PromotionCreate extends Component<Props> {

  initialCompanyId: ?string = null;

  componentDidMount() {
    this.initialCompanyId = this.props.aclStore.selectedCompanyId;
  }

  componentDidUpdate() {
    const { aclStore, history } = this.props;
    const selectedCompanyId = aclStore.selectedCompanyId;
    if (
      this.initialCompanyId &&
      selectedCompanyId &&
      selectedCompanyId !== this.initialCompanyId
    ) {
      history.push(links.promotions.main);
    }
  }

  componentWillUnmount() {
    this.props.promotionsStore.resetPromotionStores();
  }

  getTabsConfig = (): Array<TTabConfig> => [
    {
      caption: this.props.t('details'),
      url: 'general',
      component: PromotionGeneral,
      props: {
        t: this.props.t,
      },
    },
    {
      caption: this.props.t('storesAssignment'),
      url: 'stores',
      component: PromotionStoresAssignment,
      props: {
        t: this.props.t,
      },
      access: !stores.aclStore.isStoreRole,
    },
    {
      caption: this.props.t('marketingMessageDesign'),
      url: 'marketingMessage',
      component: PromotionMarketingMessage,
      props: {
        t: this.props.t,
      },
    },
  ];

  createPromotion = async formData => {
    const { history, promotionsStore } = this.props;
    const expectedKeys = ['general', 'marketingMessage'];
    const allKeysPresent = hasOwnKeys(formData, expectedKeys);
    if (!isEmpty(formData) && allKeysPresent) {
      try {
        const result = await promotionsStore.createPromotion(formData);
        if (result) {
          history.push(links.promotions.main);
        }
      } catch (e) {
        throw new Error('Could not create promotion');
      }
    } else {
      console.error('Could not create promotion. Missed tabs data');
    }
  };

  render() {
    return (
      <TabsForm
        selectedCompanyId={this.props.aclStore.selectedCompanyId}
        customHeader={<CustomHeader title={this.props.t('createPromotion')} />}
        activeTab="general"
        disabledTabs={['stores', 'marketingMessage']}
        tabsConfig={this.getTabsConfig()}
        collectDataSource={COLLECT_DATA_SOURCES.TABS_REF}
        onSubmit={this.createPromotion}
      />
    );
  }

}

export default compose(
  withTranslation('promotions'),
  observer,
  inject('promotionsStore', 'aclStore')
)(PromotionCreate);
