import { FLAG_OPTIONS } from '_common/constants/appConfig';
import { boolToWord } from '_common/utils/utils';
import { BARCODE_FORMAT_OPTIONS } from '_common/constants/companies';
import { get } from 'lodash';

export const configBlock = (hasPermissions: boolean): TInfoBlock => ({
  infoRows: [
    {
      name: 'Include subcompany locations',
      path: 'singleCompany.storesFeed[0].includeSubCompanies',
      aclAccess: hasPermissions,
      type: 'select',
      compute: boolToWord,
      localizeOptions: true,
      localizeValue: true,
      options: FLAG_OPTIONS,
      onUpdateClicked: ({ id, currentValue, data }: TDetailsUpdate) => {
        data.updateCompany &&
          data.updateCompany(id, {
            storesFeed: [
              {
                ...get(data, 'singleCompany.storesFeed[0]', {}),
                includeSubCompanies: currentValue,
              },
            ],
          });
      },
    },
    {
      name: 'Include onboarding stores',
      translateKey: 'includeOnboardingStores',
      path: 'singleCompany.onboardingStoresFeed',
      aclAccess: hasPermissions,
      type: 'select',
      compute: boolToWord,
      localizeValue: true,
      localizeOptions: true,
      options: FLAG_OPTIONS,
      onUpdateClicked: ({ id, currentValue, data }: TDetailsUpdate) => {
        data.updateCompany &&
          data.updateCompany(id, {
            onboardingStoresFeed: currentValue,
          });
      },
    },
    {
      name: 'Barcode format',
      path: 'singleCompany.label.barcodeFormat',
      type: 'select',
      options: BARCODE_FORMAT_OPTIONS,
      aclAccess: hasPermissions,
      onUpdateClicked: ({
        id,
        currentValue,
        data: { updateCompany },
      }: TDetailsUpdate) => {
        updateCompany &&
          updateCompany(id, { label: { barcodeFormat: currentValue } });
      },
    },
  ],
  translateNS: 'company',
  idPropName: 'singleCompany.companyId',
  header: '',
});
