// @flow

import React, { Fragment, Component } from 'react';
import Select, { components } from 'react-select';
import Async from 'react-select/async';
import { Input } from '_common/components';
import { debounce, get, isEqual } from 'lodash';
import {
  SelectTrigger,
  SelectTriggerInfoBlock,
  SelectWrapper,
  SpinHolder,
} from './elements';
import { Spin } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { toCamelCase } from '_common/utils/utils';

const ControlComponent = props => {
  const controlStyles = {
    border: '1px solid #d8e1e8',
    borderRadius: '2px',
    borderBottom: 'none',
    padding: '10px',
    background: '#f7f7f7',
  };

  return (
    <div style={controlStyles}>
      <components.Control {...props} />
    </div>
  );
};

type Props = {
  t: TTranslateFunc,
  localizePlaceholder?: boolean,
  inputPlaceholder: string,
  selectPlaceholder: string,
  inInfoBlock?: boolean,
  options: Array<TOption>,
  onSelectOption: ?Function,
  checkOptions?: boolean,
  loading?: boolean,
  isDisabled?: boolean,
  initialValue?: ?string,
  forceDeepInitialized?: boolean,
  resetSelect?: boolean,
  checkInitial?: boolean,
  hasEmptyOption?: boolean,
  defaultToEmpty?: boolean,
  emptyOptionLabel?: string,
  loadOptions?: string => Promise<Array<TOption>>,
  isAsync?: boolean,
  disableSelection?: boolean,
  isClearable?: boolean,
  value?: ?string,
  whiteSpace?: string,
  // Prop to suppress double update when using with antd getFieldDecorator
  ignoreValue?: boolean,
};

type State = {
  selectedOption: ?TOption,
  isSelectOpen: boolean,
  selectWidth: number,
  initialized: boolean,
  deepInitialized: boolean,
  emptyOption: TOption,
};

class CustomSelect extends Component<Props, State> {

  static defaultProps = {
    whiteSpace: 'nowrap',
    emptyOptionLabel: 'empty',
    ignoreValue: false,
    isClearable: true,
  };

  _isMounted: boolean;
  inputRef: ?Object = null;
  triggerRef: ?Object = null;

  state = {
    selectedOption: null,
    isSelectOpen: false,
    selectWidth: 0,
    initialized: false,
    deepInitialized: false,
    emptyOption: {
      value: null,
      label: '',
    },
  };

  componentDidMount() {
    this._isMounted = true;
    // $FlowFixMe
    this.setState({
      emptyOption: {
        value: null,
        // $FlowExpectedError
        label: this.props.emptyOptionLabel.toLowerCase(),
      },
    });
    this.checkInitialValue();
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Props) {
    const {
      checkOptions,
      options,
      resetSelect,
      checkInitial,
      initialValue,
      value,
      forceDeepInitialized,
      ignoreValue,
    } = this.props;
    if (!ignoreValue && value !== undefined && value !== prevProps.value) {
      this.handleChange(this.getOptionFromValue(value));
    } else {
      const { deepInitialized } = this.state;
      if (checkOptions && !isEqual(prevProps.options, options)) {
        this.resetSelectedValue(this.checkInitialValue);
      }
      if (resetSelect !== prevProps.resetSelect) {
        this.resetSelectedValue();
      }
      if (
        checkInitial &&
        (forceDeepInitialized === false || !deepInitialized) &&
        initialValue !== prevProps.initialValue
      ) {
        this.checkInitialValue(true);
      }
    }
  }

  getOptionFromValue = (value: ?string): TOption | null => {
    const { options, hasEmptyOption } = this.props;
    const { emptyOption } = this.state;
    if (value === emptyOption.value) {
      if (hasEmptyOption) {
        return emptyOption;
      } else {
        return emptyOption.value;
      }
    }
    return {
      label: get(
        options.find(option => option.value === value),
        'label',
        value
      ),
      value,
    };
  };

  checkInitialValue = (deepInitialized: boolean = false) => {
    const { initialValue, hasEmptyOption, defaultToEmpty } = this.props;
    if (initialValue !== undefined && initialValue !== null) {
      this.handleChange(this.getOptionFromValue(initialValue));
      this.setState({
        initialized: true,
        deepInitialized,
      });
    } else if (hasEmptyOption && defaultToEmpty) {
      this.handleChange(this.state.emptyOption);
    } else if (initialValue === null) {
      this.handleChange(null);
    }
  };

  handleChange = (selectedOption: ?TOption) => {
    this.setState({
      isSelectOpen: false,
    });
    const { disableSelection, onSelectOption } = this.props;
    if (!disableSelection) {
      this.setState({
        selectedOption,
      });
      if (this.inputRef && this.inputRef.input) {
        this.inputRef.input.value = selectedOption ? selectedOption.value : '';
      }
    }
    onSelectOption && onSelectOption(selectedOption);
  };

  onSelectClick = () => {
    if (this.props.isDisabled || this.props.loading) return;

    this.setState({ isSelectOpen: true });
  };

  onSelectBlur = () => {
    this.setState({ isSelectOpen: false });
  };

  setInputRef = (ref: ?Object) => {
    this.inputRef = ref;
  };

  getTriggerRef = (ref: ?Object) => {
    this.triggerRef = ref;

    if (ref) {
      const self = this;
      setTimeout(() => {
        if (self._isMounted) {
          self.setState({
            //$FlowFixMe
            selectWidth: ref.getBoundingClientRect().width,
          });
        }
      }, 0);
    }
  };

  resetSelectedValue = (callback?: () => void) => {
    this.setState(
      {
        selectedOption: null,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  loadOptionsCallback = (query: string, callback: any => void) => {
    const { loadOptions } = this.props;
    if (loadOptions) {
      loadOptions(query).then(results => callback(results));
    }
    return;
  };

  renderInputPlaceholder = () => {
    const { selectedOption, emptyOption } = this.state;
    const { t, inputPlaceholder, localizePlaceholder } = this.props;
    let result = inputPlaceholder;
    if (selectedOption && selectedOption.label) {
      if (selectedOption.label === emptyOption.label) {
        result = t(emptyOption.label);
      } else {
        result = selectedOption.label;
      }
    } else if (localizePlaceholder) {
      result = t(toCamelCase(inputPlaceholder));
    }
    return result;
  };

  render() {
    const {
      isSelectOpen,
      selectedOption,
      selectWidth,
      emptyOption,
    } = this.state;
    const {
      isClearable,
      selectPlaceholder,
      options,
      isAsync,
      inInfoBlock,
      loading,
      isDisabled,
      hasEmptyOption,
      whiteSpace,
      t,
    } = this.props;
    const TriggerBlock = inInfoBlock ? SelectTriggerInfoBlock : SelectTrigger;

    let selectOptions = hasEmptyOption
      ? [
          {
            value: emptyOption.value,
            label: t(emptyOption.label),
          },
        ]
      : [];
    selectOptions = options
      ? [...selectOptions, ...options]
      : [...selectOptions];

    const selectProps = {
      isDisabled: isDisabled,
      autoFocus: true,
      menuIsOpen: true,
      placeholder: selectPlaceholder,
      autosize: false,
      components: { Control: ControlComponent },
      onBlur: this.onSelectBlur,
      classNamePrefix: 'doddle-react-select',
      isClearable,
      menuPortalTarget: document.body,
      isSearchable: true,
      menuPlacement: 'auto',
      value: selectedOption,
      onChange: this.handleChange,
    };

    return (
      <Fragment>
        <Input style={{ display: 'none' }} getRef={this.setInputRef} />

        <TriggerBlock
          onClick={this.onSelectClick}
          selected={selectedOption}
          isDisabled={isDisabled}
          ref={this.getTriggerRef}
          whiteSpace={whiteSpace}
        >
          {loading ? (
            <Fragment>
              <SpinHolder />
              <Spin />
            </Fragment>
          ) : null}
          {this.renderInputPlaceholder()}
          {isSelectOpen ? <UpOutlined /> : <DownOutlined />}
        </TriggerBlock>

        {isSelectOpen ? (
          <SelectWrapper selectWidth={selectWidth}>
            {isAsync ? (
              // $FlowFixMe
              <Async
                {...selectProps}
                loadOptions={debounce(this.loadOptionsCallback, 500)}
                defaultOptions={options}
              />
            ) : (
              <Select {...selectProps} options={selectOptions} />
            )}
          </SelectWrapper>
        ) : null}
      </Fragment>
    );
  }

}

export default withTranslation('common', { withRef: true })(CustomSelect);
