import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const MenuLink = styled(NavLink)`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #6a6b6e;
  padding: ${props => props.theme.padding.menuItem.vertical}px
    ${props => props.theme.padding.menuItem.horizontal}px;

  &:visited,
  &:focus {
    text-decoration: none;
  }

  &.active {
    font-family: ${props => props.theme.fonts.bold};
  }
`;
