// @flow

import stores from 'stores';

export default ({
  startDate,
  endDate,
  eventType,
  storeId,
  retailerId,
  type,
}: {
  startDate: string,
  endDate: string,
  eventType: string,
  storeId: string,
  retailerId: string,
  type: string,
}) => {
  return stores.parcelsStore.getParcelsByEvent({
    startDate,
    endDate,
    eventType,
    storeId,
    retailerId,
    type,
  });
};
