import styled from 'styled-components';

export const StoreInfo = styled.div`
  font-family: ${props => props.theme.fonts.book};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const StoreName = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  color: #542785;
`;
