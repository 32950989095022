// @flow

import React, { Component, Fragment } from 'react';
import { Select, InputLabel } from '_common/components';
import { Col, Row } from 'antd';
import {
  EventSectionTitle,
  DetailsWrapper,
  ErrorMessage,
  ResetHolder,
  TextLink,
  BackButton,
  ColumnDataHolder,
  EventSearchStaticPart,
  FilterName,
  FilterValue,
  FilterValueBold,
  StaticDataHolder,
} from './elements';
import { Line, SearchButton } from '../Parcels/elements';
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import parcelsActions from 'search/actions';
import { get, omit, isEmpty } from 'lodash';
import { ParcelsStore, DoddleStores, CompaniesStore, ACLStore } from 'stores';
import UIStore from '_common/stores/uiStore';
import moment from 'moment';
import { Trans, withTranslation } from 'react-i18next';
import { localizeOptions } from '_common/utils/utils';
import { RangePickerFullWidth } from '_common/components/PlainStyles';

const typeOptions = [
  { value: 'collections', label: 'Collections' },
  { value: 'returns', label: 'Returns' },
];

const milestoneOptionsCollection = [
  { value: 'PREADVICE_RECEIVED', label: 'PREADVICE_RECEIVED' },
  { value: 'PUT_ON_SHELF', label: 'PUT_ON_SHELF' },
  { value: 'COLLECTION_SMS_SENT', label: 'COLLECTION_SMS_SENT' },
  { value: 'ENRICHED_REFERENCE_ID', label: 'ENRICHED_REFERENCE_ID' },
  { value: 'ENRICHED_CUSTOMER_DETAILS', label: 'ENRICHED_CUSTOMER_DETAILS' },
  { value: 'ENRICHED_ID_REQUIREMENTS', label: 'ENRICHED_ID_REQUIREMENTS' },
  { value: 'FAILED_TO_FIND_ITEM', label: 'FAILED_TO_FIND_ITEM' },
  { value: 'RECOVERED_LOST_ITEM', label: 'RECOVERED_LOST_ITEM' },
  { value: 'CUSTOMER_ID_CHECK_FAILED', label: 'CUSTOMER_ID_CHECK_FAILED' },
  { value: 'CUSTOMER_ID_CHECK_PASSED', label: 'CUSTOMER_ID_CHECK_PASSED' },
  { value: 'REJECTED_BY_CUSTOMER', label: 'REJECTED_BY_CUSTOMER' },
  { value: 'COLLECTED_BY_CUSTOMER', label: 'COLLECTED_BY_CUSTOMER' },
  { value: 'EXPIRY_TIME_REACHED', label: 'EXPIRY_TIME_REACHED' },
  { value: 'RETURN_ROUTING_DETERMINED', label: 'RETURN_ROUTING_DETERMINED' },
  { value: 'RETRIEVED_FOR_RETURN', label: 'RETRIEVED_FOR_RETURN' },
  { value: 'ADDED_RETURN_LABEL', label: 'ADDED_RETURN_LABEL' },
  {
    value: 'SCANNED_TO_DESPATCH_CONTAINER',
    label: 'SCANNED_TO_DESPATCH_CONTAINER',
  },
  { value: 'DESPATCHED', label: 'DESPATCHED' },
  { value: 'DELIVERED_TO_STORE', label: 'DELIVERED_TO_STORE' },
];

const milestoneOptionsReturn = [
  { value: 'BOOKING_MADE', label: 'BOOKING_MADE' },
  { value: 'BOOKING_RETRIEVED', label: 'BOOKING_RETRIEVED' },
  { value: 'RETAILER_SELECTED', label: 'RETAILER_SELECTED' },
  { value: 'CHECKOUT_COMPLETED', label: 'CHECKOUT_COMPLETED' },
  {
    value: 'SCANNED_TO_DESPATCH_CONTAINER',
    label: 'SCANNED_TO_DESPATCH_CONTAINER',
  },
  { value: 'DESPATCHED', label: 'DESPATCHED' },
  { value: 'ADDED_RETURN_LABEL', label: 'ADDED_RETURN_LABEL' },
  { value: 'ADDED_RETAILER_REFS', label: 'ADDED_RETAILER_REFS' },
  { value: 'ADDED_RETURN_ITEMISATION', label: 'ADDED_RETURN_ITEMISATION' },
  { value: 'ADDED_CUSTOMER_DETAILS', label: 'ADDED_CUSTOMER_DETAILS' },
  { value: 'SKIPPED_CUSTOMER_DETAILS', label: 'SKIPPED_CUSTOMER_DETAILS' },
  { value: 'RETURN_ROUTING_DETERMINED', label: 'RETURN_ROUTING_DETERMINED' },
];

type State = {|
  startDate: string,
  endDate: string,
  eventType: string,
  storeId: string,
  storeName: string,
  retailerId: string,
  retailerName: string,
  type: string,
  showError: boolean,
  emptyFields: string,
  errorText: string,
  resetSelect: boolean,
  isStaticEventSearchPartVisible: boolean,
  storesUpdating: boolean,
|};

type Props = {|
  uiStore: UIStore,
  parcelsStore: ParcelsStore,
  doddleStores: DoddleStores,
  companiesStore: CompaniesStore,
  aclStore: ACLStore,
  onSearchComplete: Function,
  onClickSearch: (state: any) => void,
  t: TTranslateFunc,
|};

const initialState = {
  startDate: '',
  endDate: '',
  eventType: '',
  storeId: '',
  retailerId: '',
  type: '',
  showError: false,
  emptyFields: '',
  errorText: '',
  retailerName: '',
  storeName: '',
  resetSelect: false,
  isStaticEventSearchPartVisible: false,
  storesUpdating: false,
};

class EventSearch extends Component<Props, State> {

  state = initialState;

  componentDidMount() {
    const { aclStore } = this.props;
    const companyId = aclStore.getSelectedCompanyId;
    this.updateStoresOptions(companyId);
  }

  componentDidUpdate() {
    const { aclStore, doddleStores } = this.props;
    const { storesUpdating } = this.state;
    const companyId = aclStore.getSelectedCompanyId;
    if (
      !storesUpdating &&
      !isEmpty(companyId) &&
      doddleStores.storeOptionsConfig.lastLoadedFromCompany !== companyId
    ) {
      this.updateStoresOptions(companyId);
    }
  }

  updateStoresOptions = companyId => {
    if (companyId) {
      const { doddleStores } = this.props;
      this.setState({
        storesUpdating: true,
      });
      doddleStores
        .getStoreOptionsByFilters({ host: companyId }, true)
        .then(() => {
          this.setState({
            storesUpdating: false,
          });
        });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      startDate,
      endDate,
      eventType,
      storeId,
      retailerId,
      type,
    } = this.state;
    const { t } = this.props;

    this.setState({
      errorText: '',
      emptyFields: '',
      showError: false,
    });

    if (startDate && endDate && eventType && type) {
      parcelsActions
        .getParcelsByEvent({
          startDate,
          endDate,
          eventType,
          storeId,
          retailerId,
          type,
        })
        .then((e = {}) => {
          const err = get(e, 'response.data.errors[0].message');
          if (err) {
            this.setState({
              showError: true,
              errorText: err,
            });
            return;
          }
          this.props.onSearchComplete && this.props.onSearchComplete(type);
          this.props.onClickSearch && this.props.onClickSearch(this.state);
          this.setState({ isStaticEventSearchPartVisible: true });
        });
    } else {
      const emptyFields = [];

      if (!startDate || !endDate) {
        emptyFields.push(`'${t('dateRange')}'`);
      }
      if (!eventType) {
        emptyFields.push(`'${t('eventType')}'`);
      }
      if (!type) {
        emptyFields.push(`'${t('type')}'`);
      }

      this.setState({
        showError: true,
        emptyFields: emptyFields.join(', '),
      });
    }
  };

  setSelectOption = (name, resetType) => option => {
    let setObj = {};
    if (option) {
      setObj = {
        [name]: option.value,
      };
    } else {
      setObj = {
        [name]: '',
      };
    }
    if (resetType) {
      setObj = {
        ...setObj,
        eventType: '',
      };
    }
    this.setState(
      {
        ...setObj,
        showError: false,
      },
      this.setStateToUiStore
    );
  };

  setStateToUiStore = () => {
    const { uiStore } = this.props;

    const eventSearchParams = omit(this.state, [
      'showError',
      'emptyFields',
      'errorText',
      'retailerName',
      'storeName',
      'storesUpdating',
    ]);
    uiStore.setEventSearchParams(eventSearchParams);
    this.setState({
      resetSelect: false,
    });
  };

  setRetailer = async (option: ?TOption) => {
    this.setSelectOption('retailerId')(option);
    this.setState({
      retailerName: option && option.label ? option.label : '-',
    });
  };

  setStore = async (option: ?TOption) => {
    this.setSelectOption('storeId')(option);
    this.setState({
      storeName: option && option.label ? option.label : '-',
    });
  };

  changeDateRange = (dates: Array<Object>) => {
    this.setState(
      {
        showError: false,
        startDate: get(dates, '[0]')
          ? `${dates[0].format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
          : '',
        endDate: get(dates, '[1]')
          ? `${dates[1].format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
          : '',
      },
      this.setStateToUiStore
    );
  };

  renderEventDetailsForm = () => {
    const {
      uiStore,
      parcelsStore,
      companiesStore,
      doddleStores,
      aclStore,
      t,
    } = this.props;
    const { resetSelect } = this.state;
    const options = this.state.type
      ? this.state.type === 'collections'
        ? milestoneOptionsCollection
        : milestoneOptionsReturn
      : [];

    const {
      eventSearchParams: { startDate, endDate },
    } = uiStore;
    let dateRangeValue = [];
    if (startDate && endDate) {
      dateRangeValue = [moment(startDate), moment(endDate)];
    }
    return (
      <DetailsWrapper>
        <Row align="middle">
          <Col span={4}>
            <InputLabel>{t('type')}</InputLabel>
          </Col>
          <Col span={20}>
            <Select
              defaultValue="CollectionsAndReturns"
              resetSelect={resetSelect}
              onSelectOption={this.setSelectOption('type', true)}
              options={localizeOptions(typeOptions, t, ['search'])}
              inputPlaceholder={t('collRet')}
              selectPlaceholder={t('collRet')}
              initialValue={uiStore.eventSearchParams.type}
              loading={parcelsStore.isSearchInProgress}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={4}>
            <InputLabel>{t('narrowResults')}</InputLabel>
          </Col>
          <Col span={8}>
            <Select
              onSelectOption={this.setSelectOption('eventType')}
              checkOptions
              resetSelect={resetSelect}
              options={options}
              selectPlaceholder={t('eventType')}
              inputPlaceholder={t('eventType')}
              style={{ paddingRight: '10px' }}
              initialValue={uiStore.eventSearchParams.eventType}
              loading={parcelsStore.isSearchInProgress}
            />
          </Col>
          <Col span={11} offset={1}>
            <RangePickerFullWidth
              placeholder={[t('common:from'), t('common:to')]}
              showTime
              onChange={this.changeDateRange}
              disabled={parcelsStore.isSearchInProgress}
              value={[...dateRangeValue]}
              format="DD-MM-YYYY HH:mm:ss"
              ranges={{
                [`${t('pastHours', { hours: 24 })}`]: [
                  moment().subtract(24, 'hours'),
                  moment(),
                ],
                [`${t('pastHours', { hours: 48 })}`]: [
                  moment().subtract(48, 'hours'),
                  moment(),
                ],
                [`${t('pastDays', { days: 7 })}`]: [
                  moment().subtract(7, 'days'),
                  moment(),
                ],
                [`${t('pastDays', { days: 30 })}`]: [
                  moment().subtract(30, 'days'),
                  moment(),
                ],
                [`${t('pastDays', { days: 90 })}`]: [
                  moment().subtract(90, 'days'),
                  moment(),
                ],
              }}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={4}>
            <InputLabel>{t('storeNameOrId')}</InputLabel>
          </Col>
          <Col span={20}>
            <Select
              isAsync
              resetSelect={resetSelect}
              onSelectOption={this.setStore}
              loadOptions={doddleStores.getStoreOptionsByFiltersCallback({
                host: aclStore.getSelectedCompanyId,
              })}
              inputPlaceholder={t('allStores')}
              selectPlaceholder={t('enterStore')}
              options={doddleStores.getStoreOptionsField}
              initialValue={uiStore.eventSearchParams.storeId}
              loading={parcelsStore.isSearchInProgress}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={4}>
            <InputLabel>{t('companyNameOrId')}</InputLabel>
          </Col>
          <Col span={20}>
            <Select
              resetSelect={resetSelect}
              onSelectOption={this.setRetailer}
              inputPlaceholder={t('allCompanies')}
              selectPlaceholder={t('enterCompany')}
              options={companiesStore.getCachedUserCompaniesList}
              initialValue={uiStore.eventSearchParams.retailerId}
              loading={parcelsStore.isSearchInProgress}
            />
          </Col>
        </Row>
      </DetailsWrapper>
    );
  };

  render() {
    const { parcelsStore, t } = this.props;
    const {
      showError,
      emptyFields,
      errorText,
      isStaticEventSearchPartVisible,
      type,
      startDate,
      endDate,
      storeName,
      eventType,
      retailerName,
    } = this.state;

    const formattedStartDate = moment(startDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const formattedEndDate = moment(endDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    return (
      <Fragment>
        {isStaticEventSearchPartVisible ? (
          <Fragment>
            <EventSearchStaticPart>
              <StaticDataHolder>
                <ColumnDataHolder>
                  <FilterName>{t('type')}</FilterName>
                  <FilterName>{t('dateRange')}</FilterName>
                  <FilterName>{t('storeName')}</FilterName>
                  <FilterName>{t('companyName')}</FilterName>
                  <FilterName>{t('type')}</FilterName>
                </ColumnDataHolder>
                <ColumnDataHolder>
                  <FilterValueBold>{t(type)}</FilterValueBold>
                  <FilterValueBold>{`${formattedStartDate} - ${formattedEndDate}`}</FilterValueBold>
                  <FilterValue>{storeName ? storeName : '-'}</FilterValue>
                  <FilterValueBold>
                    {retailerName ? retailerName : '-'}
                  </FilterValueBold>
                  <FilterValue>{eventType}</FilterValue>
                </ColumnDataHolder>
              </StaticDataHolder>
              <BackButton
                onClick={() =>
                  this.setState({
                    isStaticEventSearchPartVisible: false,
                  })
                }
              >
                {t('refineSearch')}
              </BackButton>
            </EventSearchStaticPart>
          </Fragment>
        ) : (
          <Fragment>
            <EventSectionTitle>{t('eventDetails')}</EventSectionTitle>
            <Row>
              <Col span={20}>
                {this.renderEventDetailsForm()}
                {parcelsStore.isSearchInProgress ? null : (
                  <ResetHolder>
                    <TextLink
                      onClick={e => {
                        e.preventDefault();
                        this.setState(
                          {
                            ...initialState,
                            resetSelect: true,
                          },
                          this.setStateToUiStore
                        );
                      }}
                    >
                      {t('resetSearchFilters')}
                    </TextLink>
                  </ResetHolder>
                )}
              </Col>
            </Row>
            {showError ? (
              <Fragment>
                <Line />
                <ErrorMessage>
                  <Trans
                    ns="search"
                    i18nKey={errorText ? errorText : 'fieldsMustBeFilled'}
                    values={{ fields: emptyFields }}
                  />
                </ErrorMessage>
              </Fragment>
            ) : null}
            <Line />
            <SearchButton
              onClick={this.handleSubmit}
              loading={parcelsStore.isSearchInProgress}
            >
              {t('search')}
            </SearchButton>
          </Fragment>
        )}
      </Fragment>
    );
  }

}

export default compose(
  inject(
    'doddleStores',
    'parcelsStore',
    'uiStore',
    'companiesStore',
    'aclStore'
  ),
  withTranslation('search'),
  withRouter,
  observer
)(EventSearch);
