// @flow

import updateReturnStatus from '../../actions/updateReturnStatus';
import { boolToWord } from '_common/utils/utils';

const findOrderID = (data: Object = {}) => {
  let result = '-';
  Object.keys(data).forEach(cur => {
    if (data[cur].title.toLowerCase().includes('order id')) {
      result = data[cur].value;
    }
  });
  return result;
};

export const parcelDetailInfoBlock = (companyName: string): TInfoBlock => ({
  infoRows: [
    {
      name: 'Completed',
      path: 'singleReturn.completed',
      localizeValue: true,
      compute: boolToWord,
    },
    {
      name: 'Is Demo',
      path: 'singleReturn.isDemo',
      localizeValue: true,
      compute: boolToWord,
    },
    {
      name: 'Item ID',
      path: 'singleReturn.itemId',
    },
    {
      name: 'Label value',
      path: 'singleReturn.labelValue',
    },
    {
      name: 'Temp Label Value',
      path: 'singleReturn.tempLabelValue',
    },
    {
      name: 'Organisation ID',
      translationKey: 'returnOrganisationId',
      path: 'singleReturn.organisationId',
    },
    {
      name: 'Resource type',
      path: 'singleReturn.resourceType',
    },
    {
      name: 'Company',
      path: 'singleReturn.retailerId',
      customValue: companyName,
      aclAccess: false,
    },
    {
      name: 'Retailer name',
      path: 'singleReturn.retailerNameOverride',
      aclAccess: false,
    },
    {
      name: 'Status',
      path: 'singleReturn.status',
      type: 'select',
      options: [
        {
          value: 'SENT_TO_RETAILER',
          label: 'SENT_TO_RETAILER',
        },
        {
          value: 'VOID',
          label: 'VOID',
        },
      ],
      onUpdateClicked: (updateObj: Object) => {
        updateReturnStatus(updateObj);
      },
    },
    {
      name: 'Parcel Reference',
      path: 'singleReturn.referenceId',
    },
  ],
  translateNS: 'parcels',
  idPropName: 'singleReturn.itemId',
  header: 'Parcel details',
});

export const customerInfoBlock = {
  infoRows: [
    {
      name: 'Name',
      path: 'getSingleReturnCustomerName',
      computed: true,
    },
    {
      name: 'Customer ID',
      translateKey: 'returnCustomerId',
      path: 'singleReturn.customer.customerId',
    },
    {
      name: 'Email address',
      path: 'singleReturn.customer.email',
    },
  ],
  translateNS: 'parcels',
  header: 'Customer',
};

export const interactionInfoBlock = {
  infoRows: [
    {
      name: 'Store ID',
      translateKey: 'locationStoreId',
      path: 'singleReturn.location.storeId',
    },
  ],
  translateNS: 'parcels',
  header: 'Interaction',
};

export const returnItemInfoBlock = {
  infoRows: [
    {
      name: 'Order ID',
      path: 'singleReturn.retailerItemData.returnReferences',
      compute: findOrderID,
    },
  ],
  translateNS: 'parcels',
  header: 'Return item data',
};

export const returnRoutingInfoBlock = {
  infoRows: [
    {
      name: 'Is label required',
      path: 'singleReturn.returnRouting.isLabelRequired',
      localizeValue: true,
      compute: boolToWord,
    },
    {
      name: 'Routing ID',
      path: 'singleReturn.returnRouting.routingId',
    },
    {
      name: 'Store despatch carrier ID',
      path: 'singleReturn.returnRouting.storeDespatchCarrierId',
    },
    {
      name: 'Transfer strategy ID',
      path: 'singleReturn.returnRouting.transferStrategyId',
    },
  ],
  translateNS: 'parcels',
  header: 'Return routing',
};

export const returnAddressInfoBlock = {
  infoRows: [
    {
      name: 'Line 1',
      path: 'singleReturn.returnRouting.address.line1',
    },
    {
      name: 'Town',
      path: 'singleReturn.returnRouting.address.town',
    },
    {
      name: 'Postcode',
      path: 'singleReturn.returnRouting.address.postcode',
    },
  ],
  translateNS: 'parcels',
  header: 'Return address',
};
