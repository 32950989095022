import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import ja from 'i18next-icu/locale-data/ja';
import en from 'i18next-icu/locale-data/en';
import loginEN from 'assets/locales/en/login.json';
import menuEN from 'assets/locales/en/menu.json';
import searchEN from 'assets/locales/en/search.json';
import commonEN from 'assets/locales/en/common.json';
import notificationsEN from 'assets/locales/en/notifications.json';
import companyEN from 'assets/locales/en/company.json';
import storesEN from 'assets/locales/en/stores.json';
import usersEN from 'assets/locales/en/users.json';
import promotionsEN from 'assets/locales/en/promotions.json';
import parcelsEN from 'assets/locales/en/parcels.json';
import loginJA from 'assets/locales/ja/login.json';
import menuJA from 'assets/locales/ja/menu.json';
import searchJA from 'assets/locales/ja/search.json';
import commonJA from 'assets/locales/ja/common.json';
import notificationsJA from 'assets/locales/ja/notifications.json';
import companyJA from 'assets/locales/ja/company.json';
import storesJA from 'assets/locales/ja/stores.json';
import usersJA from 'assets/locales/ja/users.json';
import promotionsJA from 'assets/locales/ja/promotions.json';
import parcelsJA from 'assets/locales/ja/parcels.json';
import loginAR from 'assets/locales/ar/login.json';
import menuAR from 'assets/locales/ar/menu.json';
import searchAR from 'assets/locales/ar/search.json';
import commonAR from 'assets/locales/ar/common.json';
import notificationsAR from 'assets/locales/ar/notifications.json';
import companyAR from 'assets/locales/ar/company.json';
import storesAR from 'assets/locales/ar/stores.json';
import usersAR from 'assets/locales/ar/users.json';
import promotionsAR from 'assets/locales/ar/promotions.json';
import parcelsAR from 'assets/locales/ar/parcels.json';

import jaJP from 'antd/lib/locale/ja_JP';
import enGB from 'antd/lib/locale/en_GB';

import 'moment/locale/ja';
import 'moment/locale/en-gb';

export const ANTD_LOCALE_MAPPING = {
  ja: jaJP,
  en: enGB,
};

i18n
  .use(
    new ICU({
      localeData: [ja, en],
    })
  )
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: commonEN,
        login: loginEN,
        menu: menuEN,
        search: searchEN,
        notifications: notificationsEN,
        company: companyEN,
        stores: storesEN,
        users: usersEN,
        promotions: promotionsEN,
        parcels: parcelsEN,
      },
      ja: {
        common: commonJA,
        login: loginJA,
        menu: menuJA,
        search: searchJA,
        notifications: notificationsJA,
        company: companyJA,
        stores: storesJA,
        users: usersJA,
        promotions: promotionsJA,
        parcels: parcelsJA,
      },
      ar: {
        common: commonAR,
        login: loginAR,
        menu: menuAR,
        search: searchAR,
        notifications: notificationsAR,
        company: companyAR,
        stores: storesAR,
        users: usersAR,
        promotions: promotionsAR,
        parcels: parcelsAR,
      },
    },
    defaultNS: ['common'],
    fallbackLng: 'en',

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: process.env.NODE_ENV === 'development',

    // i18n language-detector options
    detection: {
      order: ['navigator'],
      caches: [],
      checkWhitelist: false,
    },
  });

export default i18n;
