import React, { Component } from 'react';
import { Dialog, Input } from '_common/components';
import { PromotionsStore } from 'stores';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { StyledRadio, OffersList } from './elements';
import { get } from 'lodash';

type Props = {
  dialogProps?: TDialogProps,
  t: TTranslateFunc,
  promotionsStore: PromotionsStore,
};

type State = {
  selectedOfferId: string,
  confirmedOfferId: ?string,
  isShown: boolean,
};

class OffersDialog extends Component<Props, State> {

  state = {
    selectedOfferId: void 0,
    confirmedOfferId: null,
    isShown: false,
  };

  onOfferSelection = ({ target: { value: selectedOfferId } }: string) => {
    this.setState({ selectedOfferId });
  };

  renderContent = () => {
    const { selectedOfferId } = this.state;
    const {
      promotionsStore: { getEnabledOffers, singlePromotion },
      t,
      isNewMode,
    } = this.props;
    if (getEnabledOffers.length <= 0) {
      return <div>{t('common:noDetails')}</div>;
    }
    const selectedId = isNewMode
      ? selectedOfferId
      : selectedOfferId || singlePromotion.offer.offerId;

    return (
      <OffersList onChange={this.onOfferSelection} value={selectedId}>
        {getEnabledOffers.map(({ offerId, labeledTemplate }) => (
          <StyledRadio value={offerId} key={offerId}>
            {labeledTemplate}
          </StyledRadio>
        ))}
      </OffersList>
    );
  };

  handleConfirm = () => {
    const { selectedOfferId } = this.state;
    this.setState({
      confirmedOfferId: selectedOfferId,
    });
    this.props.dialogProps.handleConfirmClick(selectedOfferId);
    this.setState({ isShown: false });
  };

  toggleOffersDialog = (isShown: boolean) => () => {
    this.setState({ isShown });
  };

  renderEditInput = () => {
    const {
      dialogProps: { inputValue },
      promotionsStore: { getOffers: offers, singlePromotion },
    } = this.props;
    const {
      offer: { offerId, offerText },
    } = singlePromotion;
    const targetOfferId = inputValue || offerId;
    const targetOffer = offers.find(offer => offer.offerId === targetOfferId);
    const displayText =
      targetOffer && targetOffer.labeledTemplate
        ? targetOffer.labeledTemplate
        : offerText;

    return (
      <Input
        value={displayText}
        onClick={this.toggleOffersDialog(true)}
        readOnly
      />
    );
  };

  renderNewInput = () => {
    const {
      placeholder,
      promotionsStore: { getEnabledOffers },
    } = this.props;
    const { confirmedOfferId } = this.state;

    return (
      <Input
        value={
          confirmedOfferId &&
          get(
            getEnabledOffers.find(offer => offer.offerId === confirmedOfferId),
            'labeledTemplate'
          )
        }
        placeholder={placeholder}
        onClick={this.toggleOffersDialog(true)}
        readOnly
      />
    );
  };

  render() {
    const { dialogProps, t, isNewMode } = this.props;
    const { isShown } = this.state;

    return (
      <>
        {isNewMode ? this.renderNewInput() : this.renderEditInput()}
        <Dialog
          isShown={isShown}
          {...dialogProps}
          minHeight={520}
          minWidth={800}
          maxHeight="720px"
          width="auto"
          headerText={t('promotions:selectOfferType')}
          confirmLabel={t('common:select')}
          displayCancelButton
          isConfirmDisabled={false}
          renderContent={this.renderContent}
          handleConfirmClick={this.handleConfirm}
          handleCancelClick={this.toggleOffersDialog(false)}
        />
      </>
    );
  }

}

export default compose(inject('promotionsStore'), observer)(OffersDialog);
