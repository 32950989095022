import styled from 'styled-components';
import { Button } from '_common/components';

export const CreateBlockWrapper = styled.div`
  margin-bottom: 40px;
`;

export const Variable = styled.div`
  margin-top: 10px;
  .ant-form-item {
    margin-bottom: 11px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AddButton = styled(Button)`
  height: auto !important;
  padding: 6px 17.5px;
  border-radius: unset !important;
  background-color: ${props => props.theme.colors.secondary} !important;
`;

export const SaveButton = styled(Button)`
  border-radius: unset !important;
  position: absolute;
  left: 8px;
  bottom: 8px;
`;

export const OfferItem = styled.div`
  border-top: 1px solid #e6e7e8;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    border-bottom: 1px solid #e6e7e8;
  }
`;

export const OfferTemplate = styled.div`
  line-height: 1;
`;
