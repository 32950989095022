// @flow

import React, { Fragment } from 'react';
import {
  InfoRow,
  Header,
  DataPart,
  CaptionPart,
  ErrorHolder,
  CaptionHolder,
  InfoColumn,
} from './elements';
import EditableBlock from './EditableBlock';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { LabelExtra } from '_common/components/PlainStyles';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  localizeOptions as localizeOptionsFunc,
  toCamelCase,
} from '_common/utils/utils';

type State = {
  status?: string,
};

type Props = {
  infoRows: Array<TInfoBlockItem>,
  data: Object,
  idPropName?: string,
  header: string,
  CustomHeader?: Object,
  translateNS?: string,
  count: number,
  t: TTranslateFunc,
};

class InfoBlock extends React.Component<Props, State> {

  static defaultProps = {
    translateNS: 'common',
  };

  state = {};

  getTranslateNameSpaces = (includeErrors: boolean = false) => {
    const finalNS = ['common'];
    if (this.props.translateNS !== 'common') {
      finalNS.push(this.props.translateNS);
    }
    if (includeErrors) {
      finalNS.push('notifications');
    }
    return finalNS;
  };

  getInfoRows = (): Array<InfoColumn> => {
    const { infoRows, data, idPropName, t } = this.props;
    const translateNamespaces = this.getTranslateNameSpaces();

    return infoRows.map((cur: TInfoBlockItem) => {
      const {
        name,
        labelExtra,
        type,
        path,
        translateKey,
        computed,
        compute,
        options,
        customView,
        customValue,
        errorData,
        errorMessage,
        showError,
        localizeOptions,
        localizeOptionsNS,
        localizeValue,
        translateReplace = {},
        aclAccess = true,
      } = cur;
      let rawData = get(data, path);
      if (!computed && !path && customValue !== undefined) {
        rawData = customValue;
      }
      let computedData = compute
        ? compute(rawData)
        : rawData === undefined || rawData === null || rawData === ''
        ? '-'
        : rawData;
      if (type === 'select' && options && !compute) {
        const computedOption = options.find(
          (option: TOption) => option.value === computedData
        );
        if (computedOption !== undefined) {
          computedData = computedOption.label;
        }
      }
      let dataView = customView ? customView(computedData) : computedData;
      if (!customView && customValue) {
        dataView = customValue;
      }
      const fieldKey = path && path.length > 0 && path.split('.').slice(-1)[0];
      const i18nKey = translateKey ? translateKey : fieldKey ? fieldKey : '';
      const translatedName = t(i18nKey, {
        ns: translateNamespaces,
        defaultValue: name,
      });
      dataView = localizeValue
        ? t(toCamelCase(dataView), { ns: translateNamespaces })
        : dataView;

      return (
        <Fragment key={name}>
          <InfoColumn key={name}>
            <InfoRow>
              <CaptionHolder>
                <CaptionPart>{translatedName}</CaptionPart>
                {labelExtra && (
                  <LabelExtra>
                    {t(labelExtra, { ns: translateNamespaces })}
                  </LabelExtra>
                )}
              </CaptionHolder>
              {path && !type && !customValue ? (
                <DataPart>{dataView}</DataPart>
              ) : aclAccess ? (
                <EditableBlock
                  showError={showError}
                  computedData={computedData}
                  dataView={dataView}
                  data={data}
                  id={get(data, idPropName)}
                  {...cur}
                  options={
                    localizeOptions
                      ? localizeOptionsFunc(options, t, localizeOptionsNS)
                      : options
                  }
                />
              ) : (
                <DataPart>{dataView}</DataPart>
              )}
            </InfoRow>
            {get(errorData, path) && errorMessage ? (
              <ErrorHolder>
                {t(errorMessage, {
                  ns: this.getTranslateNameSpaces(true),
                  ...translateReplace,
                })}
              </ErrorHolder>
            ) : null}
          </InfoColumn>
        </Fragment>
      );
    });
  };

  render() {
    const { t, count, CustomHeader } = this.props;
    let header = this.props.header;
    let headerFields = {};
    if (count !== undefined) {
      headerFields = { count };
    }
    if (header === undefined) {
      header = t('parcelDetails');
    }

    return (
      <Fragment>
        {header && !CustomHeader && (
          <Header>
            {t(toCamelCase(header), {
              ns: this.getTranslateNameSpaces(),
              ...headerFields,
            })}
          </Header>
        )}
        {CustomHeader}
        {this.getInfoRows()}
      </Fragment>
    );
  }

}

export default compose(withTranslation(), observer)(InfoBlock);
