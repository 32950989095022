import styled from 'styled-components';

export const StaticPromotionTitle = styled.div`
  margin: 20px 0 10px 0;
`;

export const StaticPromotionWrapper = styled.div`
  padding: 20px 10px;
  border: 1px solid #dbdbdb;
`;

export const StaticPromotionSection = styled.div`
  padding: ${props => (props.paddingTop ? props.paddingTop : 18)}px
    ${props => (props.paddingRight ? props.paddingRight : 15)}px
    ${props => (props.paddingBottom ? props.paddingBottom : 18)}px
    ${props => (props.paddingLeft ? props.paddingLeft : 15)}px;
  border: 1px dashed #d8d8d8;
  font-family: ${props => props.theme.fonts.basic};
  color: #696969;
  font-size: 16px;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;
