import styled from 'styled-components';
import Button from '_common/components/Button/Button';

export const ColumnsHolder = styled.div`
  display: flex;
`;

export const Column = styled.div`
  width: 40.1%;
`;

export const WideColumn = styled.div`
  width: 70%;
`;

export const LeftColumn = styled(Column)`
  margin-right: 10.7%;
`;

export const AddButton = styled(Button)`
  && {
    display: inline-block;
    background: #542785;
    padding: 10px 17px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    height: auto;
    width: auto;
    margin-right: 19px;
    font-family: ${props => props.theme.fonts.book};
    margin-bottom: 0;
    margin-top: 5px;
  }
`;

export const RemoveButton = styled(AddButton)`
  && {
    position: absolute;
    right: 10px;
    top: -9px;
    margin: 0;
  }
`;

export const PrinterHolder = styled.div`
  position: relative;
`;

export const AltNameHolder = styled.div`
  position: relative;
`;

export const TemporaryPrinterHolder = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const AltNameContent = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const AddPrinterToStoreButton = styled(RemoveButton)`
  && {
    background: #5ad03b;
    right: 110px;

    &:hover {
      background: #5ad03b;
    }

    &[ant-click-animating-without-extra-node] {
      background: #5ad03b;
    }
  }
`;

export const AddAltNameToStoreButton = styled(RemoveButton)`
  && {
    background: #5ad03b;
    right: 110px;

    &:hover {
      background: #5ad03b;
    }

    &[ant-click-animating-without-extra-node] {
      background: #5ad03b;
    }
  }
`;

export const InfoRow = styled.div`
  border-bottom: 1px solid #e6e7e8;
  display: flex;
  padding: 5px 0;
`;

export const CaptionPart = styled.div`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.book};
  color: ${props => props.theme.colors.shade2};
  font-weight: bold;
  line-height: 14px;
  padding: 13px 13px 13px 0;
  width: 30%;
`;

export const DataPart = styled.div`
  width: 70%;
  font-family: ${props => props.theme.fonts.book};
  color: #494949;
  line-height: 14px;
  padding: 0;
  font-size: 14px;

  .ant-row {
    margin-bottom: 0;
  }
`;

export const TemporaryPrinterHeader = styled.strong`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 18px;
  line-height: 18px;
  color: #494949;
  display: block;
  padding-bottom: 22px;
  border-bottom: 1px solid #e6e7e8;
`;

export const AltNameHeader = styled.strong`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 18px;
  line-height: 18px;
  color: #494949;
  display: block;
  padding-bottom: 22px;
  border-bottom: 1px solid #e6e7e8;
`;
