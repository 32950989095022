// @flow

import { observable, action, runInAction, computed } from 'mobx';
import moment from 'moment';
import storage, {
  USER_STORAGE_KEY,
  TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_LIFETIME,
  USER_SCOPES,
  USER_ROLES,
  USER_COMPANIES,
  LAST_LOGGED_IN_COMPANY,
  COMPANY_HAS_ACCESS_TO_PROMOTIONS,
} from 'storage';
import AuthService from '_common/services/authService';
import usersService from 'users/services/usersService';
import stores from 'stores';
import { getCompanyFromScope, isCompanyScope } from '_common/utils/utils';
import { REFRESH_TOKEN_INTERVAL } from '_common/constants/timeout';
import { Roles } from '_common/constants/acl';

class AuthStore {

  // Temporary entities used while user is forced to change password
  @observable
  tempUser: TUser = { userId: '' };
  @observable
  tempTokens: TTokens = { accessToken: '' };

  @observable
  loggedUser: TUser | Object = storage.get(USER_STORAGE_KEY);
  @observable
  accessToken: ?string = storage.get(TOKEN_KEY);
  @observable
  refreshToken: ?string = storage.get(REFRESH_TOKEN_KEY);
  @observable
  scopes: string = storage.get(USER_SCOPES);
  @observable
  isLoading: boolean = false;
  @observable
  newTokenHandler: IntervalID;

  constructor() {
    this.newTokenHandler = setInterval(
      () => this.getNewAccessToken(),
      REFRESH_TOKEN_INTERVAL
    );
  }

  createCompaniesListFromScopes = (
    scopes: string | Array<string>
  ): Array<string> => {
    const scopesList =
      typeof scopes === 'string' ? scopes.split(' ') : [...scopes];
    return scopesList.reduce((accumulator, currentScope) => {
      const tempAccum = [...accumulator];
      if (isCompanyScope(currentScope)) {
        tempAccum.push(getCompanyFromScope(currentScope));
      }
      return tempAccum;
    }, []);
  };

  @computed
  get getLoggedUserField(): TUser {
    return this.loggedUser;
  }

  handleRolesOnLogin = (roles: Array<string>) => {
    storage.set(USER_ROLES, roles);
  };

  @action
  handleScopesOnLogin = (scopes: string) => {
    this.scopes = scopes;
    storage.set(USER_SCOPES, scopes);
  };

  handleCompaniesOnLogin = (
    parentCompany: string,
    additionalCompanies: Array<string>
  ) => {
    storage.set(LAST_LOGGED_IN_COMPANY, parentCompany);
    const userCompanies = [
      ...new Set([
        ...this.createCompaniesListFromScopes(this.scopes),
        ...additionalCompanies,
      ]),
    ];
    if (userCompanies.length) {
      stores.companiesStore.setUserCompanies(userCompanies);
    } else {
      this.logout(false);
      console.error("User doesn't belong to any company");
      throw new Error("User doesn't belong to any company");
    }
  };

  @action
  authorizeStaff = async (
    login: string,
    password: string,
    companyId: string
  ): Promise<TLoginResponse> => {
    this.isLoading = true;

    const result = {
      forceChangePassword: false,
    };
    try {
      // $FlowFixMe
      const res = await AuthService.authorizeStaff(login, password, companyId);

      runInAction(() => {
        this.isLoading = false;
      });
      const resultUser = { ...res.user };

      this.setTokens({
        accessToken: res.access_token,
        refreshToken: res.refresh_token,
        expiresIn: res.expires_in,
      });

      const { userId, organisationId, roles } = resultUser;

      let userCompany = organisationId;
      // TODO When user has 2+ organisation_ scopes, organisationId in response maybe wrong
      if (userCompany !== companyId) {
        userCompany = companyId;
        console.error(
          `User organisations do not match: ${userCompany} - ${organisationId}`
        );
      }

      // Need to make this call to get user with companies list
      const staffUser = await usersService.getUserByLogin(companyId, userId);

      this.handleRolesOnLogin(roles);
      this.handleScopesOnLogin(res.scope);
      this.handleCompaniesOnLogin(companyId, staffUser.companies);

      // check for temporaryPassword
      if (roles.some(role => role.startsWith(Roles.STORE))) {
        const forceChangePassword = await usersService.checkTemporaryPassword(
          userCompany || organisationId,
          userId
        );
        if (forceChangePassword) {
          resultUser.temporaryPassword = true;
          result.forceChangePassword = true;
        }
      }

      if (resultUser.temporaryPassword) {
        runInAction(() => {
          this.resetTokens();
          this.tempUser = resultUser;
          this.tempTokens = {
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
            expiresIn: res.expires_in,
          };
        });
      } else {
        this.setUser(resultUser);
      }
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
      });

      return Promise.reject(e.response);
    }

    return result;
  };

  @action
  setTokens = (tokens: TTokens) => {
    this.accessToken = tokens.accessToken;

    if (tokens.refreshToken) {
      this.refreshToken = tokens.refreshToken;
      storage.set(REFRESH_TOKEN_KEY, tokens.refreshToken);
    }

    const tokenExpiredTime = tokens.expiresIn;
    storage.set(TOKEN_KEY, tokens.accessToken);

    if (tokenExpiredTime) {
      storage.set(
        TOKEN_LIFETIME,
        moment().add(tokenExpiredTime - 300, 'seconds')
      );
    }
  };

  @action
  resetTokens = () => {
    this.accessToken = null;
    this.refreshToken = null;
    storage.remove(TOKEN_LIFETIME);
    storage.remove(TOKEN_KEY);
    storage.remove(REFRESH_TOKEN_KEY);
  };

  isTokenValid = (extraSeconds: number = 300) => {
    const tokeLifetime = storage.get(TOKEN_LIFETIME);
    return (
      tokeLifetime &&
      moment(tokeLifetime).add(extraSeconds, 'seconds') > moment()
    );
  };

  @action
  setUser = (user: TUser) => {
    this.loggedUser = user;
    storage.set(USER_STORAGE_KEY, user);
  };

  @action
  logout = (redirect: boolean = true) => {
    this.loggedUser = {};
    this.accessToken = '';
    this.refreshToken = '';
    storage.remove(TOKEN_LIFETIME);
    storage.remove(TOKEN_KEY);
    storage.remove(REFRESH_TOKEN_KEY);
    storage.remove(USER_STORAGE_KEY);
    storage.remove(USER_ROLES);
    storage.remove(USER_SCOPES);
    storage.remove(USER_COMPANIES);
    storage.remove(COMPANY_HAS_ACCESS_TO_PROMOTIONS);

    clearInterval(this.newTokenHandler);

    // redirect to the login page
    if (redirect) {
      window.location.href = '/';
    }
  };

  @action
  getNewAccessToken = async () => {
    if (!this.isTokenValid(0) && storage.get(TOKEN_LIFETIME)) {
      const res = await AuthService.refreshToken(this.refreshToken);
      runInAction(() => {
        this.setTokens({
          accessToken: res.access_token,
          expiresIn: res.expires_in,
        });
      });
    }
  };

}

export default AuthStore;
