import React, { Component, Fragment } from 'react';
import { Col, Progress, Row } from 'antd';
import { Dialog, Input } from '_common/components';
import { validateEmail } from '_common/utils/utils';
import stores from 'stores';
import commonActions from '_common/actions';

type Props = {
  dialogProps?: TDialogProps,
  store: TStore,
  userLogin?: string,
  t: TTranslateFunc,
};

type State = {
  email: string,
  isProcessing: boolean,
  isOpened: boolean,
};

class EmailQRCodeDialog extends Component<Props, State> {

  static defaultProps = {
    dialogProps: {},
  };

  initialState = {
    email: '',
    isProcessing: false,
    isOpened: false,
  };

  state = { ...this.initialState };

  open = () => {
    this.setState({
      isOpened: true,
    });
  };

  handleConfirm = () => {
    const { email, isProcessing } = this.state;
    const { t, store, userLogin } = this.props;

    if (isProcessing) return;

    this.toggleIsProcessing();

    if (validateEmail(email) && store && store.companyId) {
      stores.doddleStores
        .sendLoginQRCodeAttached(
          store.companyId,
          store.storeId,
          email,
          userLogin
        )
        .then((result: boolean) => {
          if (result) {
            commonActions.showSuccessMessage(t('qrCodeSent', { email }));
            this.handleCancel();
          } else {
            this.toggleIsProcessing();
          }
        });
    }
  };

  toggleIsProcessing = () => {
    this.setState(state => ({
      isProcessing: !state.isProcessing,
    }));
  };

  changeEmail = ({ target: { value } }: Object): void => {
    this.setState({ email: value });
  };

  renderContent = () => {
    const { isProcessing, email } = this.state;

    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <Input
              value={email}
              placeholder={this.props.t('enterEmail')}
              onChange={this.changeEmail}
            />
          </Col>
        </Row>
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Fragment>
    );
  };

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
  };

  render() {
    const { dialogProps, t } = this.props;
    const { email, isOpened } = this.state;

    const isEmailValid = validateEmail(email);

    return (
      <Dialog
        {...dialogProps}
        isShown={isOpened}
        minHeight={320}
        headerText={t('enterEmail')}
        confirmLabel={t('common:send')}
        displayCancelButton
        isConfirmDisabled={!isEmailValid}
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default EmailQRCodeDialog;
