// @flow
import resetSingleStore from './resetSingleStore';
import updateStoreById from './updateStoreById';
import approveStore from './approveStore';
import updateStoreOpeningHours from './updateStoreOpeningHours';
import updateStorePhotos from './updateStorePhotos';
import getCurrentRole from './getCurrentRole';
import setOpeningOrClosingDate from './setOpeningOrClosingDate';

export default {
  resetSingleStore,
  updateStoreById,
  approveStore,
  updateStoreOpeningHours,
  updateStorePhotos,
  getCurrentRole,
  setOpeningOrClosingDate,
};
