// @flow

import React from 'react';
import { StyledLogo } from './elements';
import { NavLink } from 'react-router-dom';

type Props = {|
  url: string | Object,
  alt: string,
  path: string,
  maxHeight?: number,
  onError?: ?Function,
|};

const Logo = (props: Props) => {
  let logo = props.url;
  if (
    typeof logo === 'object' &&
    logo.hasOwnProperty('data') &&
    logo.hasOwnProperty('contentType')
  ) {
    logo = `data:${logo.contentType};base64,${logo.data}`;
  }
  return (
    <NavLink to={props.path}>
      <StyledLogo {...props} src={logo} alt={props.alt} />
    </NavLink>
  );
};

export default Logo;
