// @flow

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Col, Row, Form } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { PromotionsStore, UsersStore } from 'stores';
import { get, omit, isEmpty } from 'lodash';
import {
  ButtonNext,
  FixedWidthRow,
  RowWrapper,
  SectionWrapper,
  SeparationLine,
  TabTitle,
} from '_common/components/TabsForm/elements';
import {
  cleanObject,
  convertFileDataToBase64URL,
  formatOfferVariables,
  localizeOptions,
  normalizeUrl,
  onSelectFromDropdown,
  // setFileField,
} from '_common/utils/utils';
import { Input, InputLabel, Select } from '_common/components';
import {
  // COMPANY_LOGO_IMAGE_RESTRICTION,
  PROMO_DISPLAY_TYPE_OPTIONS,
} from '_common/constants/promotions';
import PromotionDisplayField from 'promotions/components/PromotionDisplayField';
import OfferVariables from 'promotions/components/OfferVariables';
import { toJS } from 'mobx';
import StaticPromotionRepresentation from 'promotions/components/StaticPromotionRepresentation';
import ColourPicker from '_common/components/ColourPicker';
import { ruleValidator, validateUrl } from '_common/utils/utils';
import {
  ActionButton,
  ColWithPaddingLeft,
} from '_common/components/PlainStyles';
import SendEmailDialog from 'promotions/components/SendEmailDialog';
import stores from 'stores';
import OffersDialog from 'promotions/components/OffersDialog';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import DynamicPromotionRepresentation from 'promotions/components/DynamicPromotionRepresentation';
import { PROMOTION_ACTION_BUTTONS_CONTAINER_ID } from '_common/constants/promotions';
// import FileInput from '_common/components/FileInput';

type Props = {|
  form: Form,
  onNextTab: (?string) => void,
  promotionsStore: PromotionsStore,
  usersStore: UsersStore,
  t: TTranslateFunc,
|};

type State = {
  isSendEmailDialogOpened: boolean,
  testEmailData: Object,
  formReady: boolean,
  isOffersDialogOpen: boolean,
  togglePromotionLayoutUpdates: boolean,
};

class MarketingMessage extends Component<Props, State> {

  formRef: any = null;

  state = {
    isSendEmailDialogOpened: false,
    testEmailData: {},
    formReady: false,
    isOffersDialogOpen: false,
    togglePromotionLayoutUpdates: false,
  };

  onSubmit = () => {
    this.props.onNextTab();
  };

  toggleSendEmailDialogOpened = () => {
    const isOpened = !this.state.isSendEmailDialogOpened;
    let data = this.getFormattedData();
    data = data ? data : {};
    const isOpenedAndHasData = isOpened && !isEmpty(data);
    if (isOpenedAndHasData) {
      if (typeof data.image === 'object') {
        data.image = convertFileDataToBase64URL(data.image);
      }
      data.companyId = stores.aclStore.selectedCompanyId;
      data.companyLogo = get(
        stores.companiesStore.selectedCompany,
        'logos.default',
        get(
          stores.companiesStore.selectedCompany,
          'logos.thumbnail',
          stores.companiesStore.companyLogo
        )
      );
    }
    this.setState({
      isSendEmailDialogOpened: isOpenedAndHasData,
      testEmailData: isOpenedAndHasData ? data : {},
    });
  };

  getFormattedData = () => {
    if (this.formRef) {
      const values = this.formRef.getFieldsValue();

      const { offerId, tcLink } = values;
      const offerVariables = formatOfferVariables(
        get(values, `[${offerId}]`, {})
      );

      const marketingMessageData = omit(values, [
        'offerId',
        offerId,
        'promoDisplayType',
        'tcLink',
      ]);

      const data = {
        ...marketingMessageData,
        tcLink: normalizeUrl(tcLink),
        offer: {
          offerId,
          variables: offerVariables,
        },
      };

      cleanObject(data, ['']);

      return data;
    }
  };

  renderOfferVariables = () => {
    const form = this.formRef;
    if (form) {
      const offerId = form.getFieldValue('offerId');
      let selectedOffer = null;
      if (offerId) {
        selectedOffer = this.props.promotionsStore.getOfferFromCache(offerId);
      }
      return (
        selectedOffer && (
          <OfferVariables
            t={this.props.t}
            offerId={selectedOffer.offerId}
            offerVariables={toJS(selectedOffer.variables)}
            form={form}
            blurHandler={this.onBlurHandler}
          />
        )
      );
    }
  };

  onBlurHandler = () => {
    this.setState(prev => ({
      togglePromotionLayoutUpdates: !prev.togglePromotionLayoutUpdates,
    }));
  };

  toggleOffersDialog = (isOffersDialogOpen: boolean) => () => {
    this.setState({ isOffersDialogOpen });
  };

  render() {
    const {
      promotionsStore,
      usersStore: { currentCompany },
      t,
    } = this.props;
    const {
      isSendEmailDialogOpened,
      testEmailData,
      formReady,
      togglePromotionLayoutUpdates,
    } = this.state;
    const form = this.formRef;

    const sendTestEmailContainer = document.getElementById(
      PROMOTION_ACTION_BUTTONS_CONTAINER_ID
    );

    return (
      <>
        {sendTestEmailContainer &&
          ReactDOM.createPortal(
            <ActionButton onClick={this.toggleSendEmailDialogOpened}>
              {t('sendTestEmail')}
            </ActionButton>,
            sendTestEmailContainer
          )}
        <Form
          ref={ref => {
            this.formRef = ref;
            if (ref && ref.current !== null && !formReady) {
              this.setState({ formReady: true });
            }
          }}
          onFinish={this.onSubmit}
        >
          <Row type="flex">
            <Col span={12}>
              <SectionWrapper>
                <TabTitle>{t('offerDetails')}</TabTitle>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('offerType')}*</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="offerId"
                      rules={[
                        {
                          required: true,
                          message: t('selectOfferType'),
                        },
                      ]}
                    >
                      <OffersDialog
                        isNewMode
                        t={t}
                        placeholder={t('selectOfferType')}
                        dialogProps={{
                          handleConfirmClick: offerId => {
                            form.setFieldsValue({ offerId });
                          },
                        }}
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <Form.Item
                  shouldUpdate={(prevValues, nextValues) =>
                    prevValues.offerId !== nextValues.offerId
                  }
                >
                  {this.renderOfferVariables}
                </Form.Item>
              </SectionWrapper>
              <SectionWrapper>
                <TabTitle>{t('design')}</TabTitle>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('displayType')}*</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="promoDisplayType"
                      rules={[
                        {
                          required: true,
                          message: t('selectDisplayType'),
                        },
                      ]}
                    >
                      <Select
                        ignoreValue
                        onSelectOption={
                          formReady
                            ? onSelectFromDropdown(form, 'promoDisplayType')
                            : null
                        }
                        options={localizeOptions(
                          PROMO_DISPLAY_TYPE_OPTIONS,
                          t,
                          'promotions'
                        )}
                        inputPlaceholder={t('selectDisplayType')}
                        selectPlaceholder={t('promoDisplayType')}
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <Form.Item
                  shouldUpdate={(prevValues, nextValues) =>
                    prevValues.promoDisplayType !== nextValues.promoDisplayType
                  }
                >
                  {() => (
                    <PromotionDisplayField
                      isEditLayout={false}
                      form={form}
                      onBlurHandler={this.onBlurHandler}
                      imageLibrary={promotionsStore.imageLibrary}
                      type={
                        formReady &&
                        form &&
                        form.getFieldValue('promoDisplayType')
                      }
                    />
                  )}
                </Form.Item>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('tcLink')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name="tcLink"
                      rules={[
                        {
                          validator: ruleValidator(
                            validateUrl,
                            t('notifications:invalidUrl')
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t('enterTcLink')}
                        onBlur={this.onBlurHandler}
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('tcLinkColour')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name={['colourScheme', 'tcLinkColour', 'rgba']}>
                      <ColourPicker
                        onColorChangeApplied={this.onBlurHandler}
                        initialRGBA={{ r: 0, g: 0, b: 0, a: 1 }}
                        placeholder={t('selectLinkColor')}
                        form={form}
                        fieldName="colourScheme.tcLinkColour.rgba"
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('offerTextColour')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item
                      name={['colourScheme', 'offerTextColour', 'rgba']}
                    >
                      <ColourPicker
                        onColorChangeApplied={this.onBlurHandler}
                        initialRGBA={{ r: 0, g: 0, b: 0, a: 1 }}
                        placeholder={t('selectOfferTextColor')}
                        form={form}
                        fieldName="colourScheme.offerTextColour.rgba"
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('bgColour')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name={['colourScheme', 'bgColour', 'rgba']}>
                      <ColourPicker
                        onColorChangeApplied={this.onBlurHandler}
                        placeholder={t('selectBgColour')}
                        form={form}
                        fieldName="colourScheme.bgColour.rgba"
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
                <RowWrapper>
                  <FixedWidthRow width={190}>
                    <InputLabel>{t('borderColour')}</InputLabel>
                  </FixedWidthRow>
                  <FixedWidthRow width={380}>
                    <Form.Item name={['colourScheme', 'borderColour', 'rgba']}>
                      <ColourPicker
                        onColorChangeApplied={this.onBlurHandler}
                        placeholder={t('selectBorderColour')}
                        form={form}
                        fieldName="colourScheme.borderColour.rgba"
                      />
                    </Form.Item>
                  </FixedWidthRow>
                </RowWrapper>
              </SectionWrapper>
            </Col>
            <ColWithPaddingLeft span={12}>
              {currentCompany === MAIN_COMPANIES.YAMATO_ID ? (
                form && (
                  <DynamicPromotionRepresentation
                    key={togglePromotionLayoutUpdates ? 'true' : 'false'}
                    formFields={form.getFieldsValue()}
                    isNewMode
                  />
                )
              ) : (
                <StaticPromotionRepresentation />
              )}
            </ColWithPaddingLeft>
          </Row>
          <SendEmailDialog
            t={t}
            dialogProps={{
              isShown: isSendEmailDialogOpened,
              handleCancelClick: this.toggleSendEmailDialogOpened,
            }}
            sendTestEmail={promotionsStore.sendTestEmailByData}
            data={testEmailData}
          />
          <SeparationLine verticalMargin={55} />
          <ButtonNext htmlType="submit">{t('savePromo')}</ButtonNext>
        </Form>
      </>
    );
  }

}

export default compose(
  inject('promotionsStore', 'aclStore', 'usersStore'),
  observer
)(MarketingMessage);
