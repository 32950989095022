// @flow

import React from 'react';
import { StyledInputLabel } from './elements';

type Props = {
  [key: any]: any,
};

export class InputLabel extends React.Component<Props> {

  render() {
    return <StyledInputLabel {...this.props} />;
  }

}

export default InputLabel;
