import styled from 'styled-components';

export const StyledPanel = styled.div`
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  border: solid 1px #e6e7e8;
`;

export const StyledPanelWrapper = styled.div`
  padding: 30px;
`;
