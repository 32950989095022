import React, { Component } from 'react';
import { Row, Col, Form, Typography } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { StyledTextArea } from '_common/components/Input/elements';
import {
  OFFER_VARIABLES_DEFAULT_VALIDATIONS,
  OFFER_RESTRICTIONS,
  OFFER_VARIABLE_TYPE_OPTIONS,
} from '_common/constants/promotions';
import { Header } from '_common/components/InfoBlock/elements';
import { Input, Select } from '_common/components';
import {
  CreateBlockWrapper,
  Variable,
  AddButton,
  SaveButton,
} from './elements';
import { localizeOptions, onSelectFromDropdown } from '_common/utils/utils';
import { last } from 'lodash';

type Props = {
  t: TTranslateFunc,
  createOffer: Function,
};

type State = {
  template: string,
  variables: Array<string>,
};

class CreateBlock extends Component<Props, State> {

  offerFormRef = React.createRef({});
  variableFormRef = React.createRef({});

  state = {
    variables: [],
    template: '',
  };

  onOfferSubmit = async ({ template }) => {
    const { createOffer } = this.props;
    const variables = this.state.variables.reduce((accum, variable) => {
      const tempAccum = accum;
      if (template.includes(`\${${variable.id}}`)) {
        tempAccum.push(variable);
      }
      return tempAccum;
    }, []);
    await createOffer({ template, variables });
    this.variableFormRef.current.resetFields();
    this.offerFormRef.current.resetFields();
  };

  onVariableSubmit = values => {
    const offerForm = this.offerFormRef.current;
    const idTemplate = 'var';
    const lastVar = last(this.state.variables);
    const id = lastVar
      ? `${idTemplate}${Number(lastVar.id.slice(-1)) + 1}`
      : `${idTemplate}1`;
    let template = offerForm.getFieldValue('template') || '';
    const variablePlaceholder = `\${${id}}`;
    if (!template.includes(variablePlaceholder)) {
      template +=
        !template || template.endsWith(' ')
          ? variablePlaceholder
          : ` ${variablePlaceholder}`;
      offerForm.setFieldsValue({ template });
      this.setState(state => ({
        variables: [
          ...state.variables,
          {
            ...values,
            id,
            ...OFFER_VARIABLES_DEFAULT_VALIDATIONS[values.type],
          },
        ],
      }));
    }
    this.variableFormRef.current.resetFields();
  };

  render() {
    const { t } = this.props;

    const {
      TEMPLATE: { min: templateMinLength, max: templateMaxLength },
      LABEL: { min: labelMinLength, max: labelMaxLength },
    } = OFFER_RESTRICTIONS;

    return (
      <CreateBlockWrapper>
        <Row>
          <Col xs={24}>
            <Header mb={20}>{t('createOffer')}</Header>
            <Row type="flex" gutter={[16, 16]}>
              <Col xs={12}>
                <Form
                  ref={this.offerFormRef}
                  initialValues={{
                    template: '',
                  }}
                  onFinish={this.onOfferSubmit}
                >
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name="template"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        min: templateMinLength,
                        message: t('templateLengthRule', {
                          min: templateMinLength,
                          max: templateMaxLength,
                        }),
                      },
                      {
                        max: templateMaxLength,
                        message: t('templateLengthRule', {
                          min: templateMinLength,
                          max: templateMaxLength,
                        }),
                      },
                      {
                        required: true,
                        whitespace: true,
                        message: t('inputTemplate'),
                      },
                    ]}
                  >
                    <StyledTextArea
                      style={{ marginBottom: 0 }}
                      rows={8}
                      placeholder={t('enterOfferText')}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={10}>
                <Form
                  ref={this.variableFormRef}
                  initialValues={{
                    type: '',
                    label: '',
                  }}
                  onFinish={this.onVariableSubmit}
                >
                  <Row justify="space-between">
                    <Typography.Text>{t('addVariable')}</Typography.Text>
                    <AddButton htmlType="submit">{t('common:add')}</AddButton>
                  </Row>
                  <Variable>
                    <Form.Item
                      name="label"
                      rules={[
                        {
                          min: 1,
                          message: t('labelLengthRule', {
                            min: labelMinLength,
                            max: labelMaxLength,
                          }),
                        },
                        {
                          max: 30,
                          message: t('labelLengthRule', {
                            min: labelMinLength,
                            max: labelMaxLength,
                          }),
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: t('addLabel'),
                        },
                      ]}
                    >
                      <Input placeholder={t('addLabel')} />
                    </Form.Item>
                    <Form.Item
                      name="type"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t('selectType'),
                        },
                      ]}
                    >
                      <Select
                        options={localizeOptions(
                          OFFER_VARIABLE_TYPE_OPTIONS,
                          t,
                          'common'
                        )}
                        onSelectOption={val => {
                          onSelectFromDropdown(
                            this.variableFormRef.current,
                            `type`
                          )(val);
                        }}
                        inputPlaceholder={t('selectType')}
                        selectPlaceholder={t('selectType')}
                      />
                    </Form.Item>
                  </Variable>
                </Form>
              </Col>
              <Col xs={2}>
                <SaveButton onClick={() => this.offerFormRef.current.submit()}>
                  {t('common:save')}
                </SaveButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </CreateBlockWrapper>
    );
  }

}

export default compose(inject('promotionsStore'), observer)(CreateBlock);
