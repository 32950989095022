import styled from 'styled-components';

export const Header = styled.p`
  font-family: ${props => props.theme.fonts.bold};
  fonst-size: 14px;
  line-height: 14px;
  margin-bottom: 37px;
`;

export const Divider = styled.div`
  height: 1px;
  background: #d1d2d2;
`;

export const ImageBlockHolder = styled.div`
  padding: 18px 0;
  display: flex;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ImageBlockCaption = styled.p`
  color: ${props => props.theme.colors.shade2};
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
  width: ${({ captionWidth = '226px' }) => captionWidth};
`;

export const ImagePart = styled.div`
  min-width: 304px;
  display: inline-block;
  vertical-align: top;
`;

export const ImageAreaRegular = styled.div`
  border: solid 1px #e6e7e8;
  height: 138px;
  background-color: #f1f1f1;
  margin-bottom: 18px;
`;

export const ImageAreaSized = styled(ImageAreaRegular)`
  width: 138px;
`;

export const TextLink = styled.a`
  color: #494949;
  display: inline-block;
  vertical-align: top;
  margin-right: 21px;
  font-size: 14px;
  line-height: 14px;
  transition: 0.3s opacity cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: #494949;
    opacity: 0.7;
  }
`;

export const Image = styled.img`
  display: block;
  max-height: 138px;
  width: auto;
  max-width: 100%;
  margin-bottom: 18px;
`;

export const PhotosHolder = styled.div`
  min-width: 530px;
  display: inline-block;
  vertical-align: top;
`;
