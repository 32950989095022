import { toPairs } from 'lodash';

export const PAGINATION = {
  PAGE_LIMIT: 10,
  HIDE_LAST_PAGE_STORES_THRESHOLD: 10000,
  // Need to reveal the link to last page if user tries to reach it manually
  REVEAL_WHEN_REACHED_LAST_PAGE: 10,
};

// 50 is a maximum limit of stores per request currently supported by API
export const API_MAX_STORES_LIMIT = 50;

// 300 is a maximum limit of stores per request if 'fieldsToReturn' parameter is specified
export const API_MAX_STORES_LIMIT_WITH_FEW_FIELDS = 300;

export const EXPORT_STORES_LIMIT = 1500;

export const AVAILABILITY_CONFIG = {
  DAYS: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ],
  // Closing hours can be set in the next day; value must be not bigger than specified;
  // 3000 means 06:00 am next day
  TIME_LIMIT: 3000,
  DISPLAY_FORMAT: 'HH:mm',
  SAVE_FORMAT: 'HHmm',
  DEFAULT_SAVE_START_VALUE: '0000',
  DEFAULT_SAVE_END_VALUE: '2400',
  DEFAULT_DISPLAY_VALUE: '00:00',
  FULL_DAY_HOURS: 2400,
};
export const STORE_EVENT_TYPES = {
  OPENING_DATE: 'OPENING_DATE_ADDED',
  CLOSING_DATE: 'CLOSING_DATE_ADDED',
};

export const OPEN_CLOSE_SETTERS = {
  SET_OPENING_DATE: 'setOpeningDate',
  SET_CLOSING_DATE: 'setClosingDate',
};

export const INVALID_TIME_RANGE_MESSAGE =
  'Issue with opening and closing times. Please take a look';

export const STATUSES = {
  PROPOSED: 'PROPOSED',
  PLANNED: 'PLANNED',
  TRADING: 'TRADING',
  OPENING_SOON: 'OPENING_SOON',
  CLOSING_SOON: 'CLOSING_SOON',
  PERMANENTLY_CLOSED: 'PERMANENTLY_CLOSED',
  TEMPORARILY_CLOSED: 'TEMPORARILY_CLOSED',
};

export const STATUS_OPTIONS = toPairs(STATUSES).map(([label, value]) => ({
  label,
  value,
}));

export const LOCATION_TYPE_OPTIONS = [
  {
    value: 'STORE',
    label: 'Store',
  },
  {
    value: 'HOST_LOCATION',
    label: 'Host Location',
  },
  {
    value: 'POSTBOX',
    label: 'Postbox',
  },
  {
    value: 'POSTOFFICE',
    label: 'Post office',
  },
  {
    value: 'RETURNS_KIOSK',
    label: 'Returns kiosk',
  },
  {
    value: 'LOCKER',
    label: 'Locker',
  },
  {
    value: 'SORTING_CENTER',
    label: 'Sorting center',
  },
];

export const STORE_USER_LOCATIONS = [
  'STORE',
  'SORTING_CENTER',
  'RETURNS_KIOSK',
];

export const MOVE_TO_CONTAINER_FLOW_OPTIONS = [
  {
    value: 'SINGLE_CONTAINER',
    label: 'SINGLE_CONTAINER',
  },
  {
    value: 'MULTIPLE_CONTAINERS',
    label: 'MULTIPLE_CONTAINERS',
  },
];

export const DISPATCH_FLOW_OPTIONS = [
  {
    value: 'DRIVER_BRINGS_LABEL',
    label: 'DRIVER_BRINGS_LABEL',
  },
];

export const RETURN_LABEL_FLOW_OPTIONS = [
  {
    value: 'PREPRINTED_LABELS',
    label: 'PREPRINTED_LABELS',
  },
];

export const BULK_ACTIONS_EXAMPLE_FILE_CONFIG = {
  UPLOAD_STORES: {
    fileName: 'uploadStoresExample.csv',
    separator: ';',
    columns: [
      {
        displayName: 'companyId',
        id: 'companyId',
      },
      {
        displayName: 'storeName',
        id: 'storeName',
      },
      {
        displayName: 'companyStoreId',
        id: 'companyStoreId',
      },
      {
        displayName: 'externalStoreId',
        id: 'externalStoreId',
      },
      {
        displayName: 'isDemo',
        id: 'isDemo',
      },
      {
        displayName: 'locationType',
        id: 'locationType',
      },
      {
        displayName: 'openingDate',
        id: 'openingDate',
      },
      {
        displayName: 'Validate?',
        id: 'Validate?',
      },
      {
        displayName: 'country',
        id: 'country',
      },
      {
        displayName: 'postcode',
        id: 'postcode',
      },
      {
        displayName: 'addressLine1',
        id: 'addressLine1',
      },
      {
        displayName: 'addressLine2',
        id: 'addressLine2',
      },
      {
        displayName: 'town',
        id: 'town',
      },
      {
        displayName: 'area',
        id: 'area',
      },
      {
        displayName: 'geo.lat',
        id: 'geo.lat',
      },
      {
        displayName: 'geo.lon',
        id: 'geo.lon',
      },
      {
        displayName: 'countrySubdivision',
        id: 'countrySubdivision',
      },
      {
        displayName: 'emailAddress',
        id: 'emailAddress',
      },
      {
        displayName: 'phoneNumber',
        id: 'phoneNumber',
      },
      {
        displayName: 'mapUrl',
        id: 'mapUrl',
      },
      {
        displayName: 'restrictedAccess',
        id: 'restrictedAccess',
      },
      {
        displayName: 'howToFindUs',
        id: 'howToFindUs',
      },
      {
        displayName: 'collections.enabled',
        id: 'collections.enabled',
      },
      {
        displayName: 'collections.testing',
        id: 'collections.testing',
      },
      {
        displayName: 'collections.integratedLockers',
        id: 'collections.integratedLockers',
      },
      {
        displayName: 'collections.integratedLockersSite',
        id: 'collections.integratedLockersSite',
      },
      {
        displayName: 'returns.enabled',
        id: 'returns.enabled',
      },
      {
        displayName: 'returns.testing',
        id: 'returns.testing',
      },
      {
        displayName: 'returns.integratedLockers',
        id: 'returns.integratedLockers',
      },
      {
        displayName: 'returns.integratedLockersSite',
        id: 'returns.integratedLockersSite',
      },
      {
        displayName: 'returns.acceptsPaperless',
        id: 'returns.acceptsPaperless',
      },
      {
        displayName: 'collectionDeadlineDays',
        id: 'collectionDeadlineDays',
      },
      {
        displayName: 'printers.description',
        id: 'printers.description',
      },
      {
        displayName: 'printers.host',
        id: 'printers.host',
      },
      {
        displayName: 'printers.type',
        id: 'printers.type',
      },
      {
        displayName: 'extendedHours',
        id: 'extendedHours',
      },
      {
        displayName: 'MondayOpenHours',
        id: 'MondayOpenHours',
      },
      {
        displayName: 'MondayClosingHours',
        id: 'MondayClosingHours',
      },
      {
        displayName: 'TuesdayOpenHours',
        id: 'TuesdayOpenHours',
      },
      {
        displayName: 'TuesdayClosingHours',
        id: 'TuesdayClosingHours',
      },
      {
        displayName: 'WednesdayOpenHours',
        id: 'WednesdayOpenHours',
      },
      {
        displayName: 'WednesdayClosingHours',
        id: 'WednesdayClosingHours',
      },
      {
        displayName: 'ThursdayOpenHours',
        id: 'ThursdayOpenHours',
      },
      {
        displayName: 'ThursdayClosingHours',
        id: 'ThursdayClosingHours',
      },
      {
        displayName: 'FridayOpenHours',
        id: 'FridayOpenHours',
      },
      {
        displayName: 'FridayClosingHours',
        id: 'FridayClosingHours',
      },
      {
        displayName: 'SaturdayOpenHours',
        id: 'SaturdayOpenHours',
      },
      {
        displayName: 'SaturdayClosingHours',
        id: 'SaturdayClosingHours',
      },
      {
        displayName: 'SundayOpenHours',
        id: 'SundayOpenHours',
      },
      {
        displayName: 'SundayClosingHours',
        id: 'SundayClosingHours',
      },
    ],
    data: [
      {
        companyId: 'AUSTRALIA_POST',
        storeName: 'WName1',
        companyStoreId: 'WN1',
        externalStoreId: '1233211',
        isDemo: 'Yes',
        locationType: 'STORE',
        openingDate: '2023-03-30',
        'Validate?': 'No',
        country: 'AU',
        postcode: '2825',
        addressLine1: '5 Derrybong St',
        addressLine2: 'Address Line 2',
        town: 'Nyngan',
        area: 'New South Wales',
        'geo.lat': '-1',
        'geo.lon': '2',
        countrySubdivision: 'AU-NSW',
        emailAddress: 'aaa@mail.com',
        phoneNumber: '1123456789',
        mapUrl: 'https://www.google.com/maps/place/-31.56098,147.20165',
        restrictedAccess: 'yes',
        howToFindUs: 'Check map',
        'collections.enabled': 'yes',
        'collections.testing': 'Yes',
        'collections.integratedLockers': 'Yes',
        'collections.integratedLockersSite': '123',
        'returns.enabled': 'No',
        'returns.testing': 'Yes',
        'returns.integratedLockers': 'Yes',
        'returns.integratedLockersSite': '123',
        'returns.acceptsPaperless': 'Yes',
        collectionDeadlineDays: '5',
        'printers.description': 'niceDesc',
        'printers.host': '123',
        'printers.type': 'LABEL',
        extendedHours: 'No',
        MondayOpenHours: '09:13',
        MondayClosingHours: '19:20',
        TuesdayOpenHours: '09:13',
        TuesdayClosingHours: '19:20',
        WednesdayOpenHours: '09:13',
        WednesdayClosingHours: '19:20',
        ThursdayOpenHours: '09:13',
        ThursdayClosingHours: '19:20',
        FridayOpenHours: '09:13',
        FridayClosingHours: '19:20',
        SaturdayOpenHours: '09:13',
        SaturdayClosingHours: '19:20',
        SundayOpenHours: '09:13',
        SundayClosingHours: '19:20',
      },
    ],
  },
  EDIT_STORES: {
    fileName: 'editStoresExample.csv',
    separator: ';',
    columns: [
      {
        displayName: 'storeId',
        id: 'storeId',
      },
      {
        displayName: 'companyId',
        id: 'companyId',
      },
      {
        displayName: 'locationType',
        id: 'locationType',
      },
      {
        displayName: 'returnLabelFlow',
        id: 'returnLabelFlow',
      },
      {
        displayName: 'extendedHours',
        id: 'extendedHours',
      },
    ],
    data: [
      {
        storeId: 'fdacbefc-5405-4ee7-a9b4-0e03208ad3c7',
        companyId: 'HM',
        locationType: 'LOCKER',
        returnLabelFlow: 'PREPRINTED_LABELS',
        extendedHours: 'Yes',
      },
    ],
  },
  PROPOSED_STORES: {
    fileName: 'proposedStoresExample.csv',
    separator: ',',
    columns: [
      {
        displayName: 'approved?',
        id: 'approved?',
      },
      {
        displayName: 'activationDate',
        id: 'activationDate',
      },
      {
        displayName: 'companyId',
        id: 'companyId',
      },
      {
        displayName: 'storeName',
        id: 'storeName',
      },
      {
        displayName: 'storeId',
        id: 'storeId',
      },
      {
        displayName: 'externalStoreId',
        id: 'externalStoreId',
      },
      {
        displayName: 'line1',
        id: 'line1',
      },
      {
        displayName: 'line2',
        id: 'line2',
      },
      {
        displayName: 'town',
        id: 'town',
      },
      {
        displayName: 'area',
        id: 'area',
      },
      {
        displayName: 'country',
        id: 'country',
      },
      {
        displayName: 'postcode',
        id: 'postcode',
      },
    ],
    data: [
      {
        'approved?': 'yes',
        activationDate: '="2020-03-20"',
        companyId: 'HM',
        storeName: 'Ex1',
        storeId: '1f6119db-f182-4382-872e-5e20d67db517',
        externalStoreId: '132',
        line1: '3 Derrybong St',
        line2: 'Address Line 2',
        town: 'Nyngan',
        area: 'New South Wales',
        country: 'AU',
        postcode: '2825',
      },
    ],
  },
};

// Store props mapping used in export stores process
export const STORE_PROPS_MAPPING = [
  { label: 'storeId', path: 'storeId' },
  { label: 'companyId', path: 'companyId' },
  { label: 'storeName', path: 'storeName' },
  { label: 'companyStoreId', path: 'companyStoreId' },
  { label: 'externalStoreId', path: 'externalStoreId' },
  { label: 'isDemo', path: 'isDemo' },
  { label: 'locationType', path: 'locationType' },
  { label: 'openingDate', path: 'openingDate' },
  { label: 'closingDate', path: 'closingDate' },
  { label: 'Validate?', path: 'Validate?' },
  { label: 'country', path: 'place.address.country' },
  { label: 'postcode', path: 'place.address.postcode' },
  { label: 'addressLine1', path: 'place.address.line1' },
  { label: 'addressLine2', path: 'place.address.line2' },
  { label: 'town', path: 'place.address.town' },
  { label: 'area', path: 'place.address.area' },
  { label: 'geo.lat', path: 'geo.lat' },
  { label: 'geo.lon', path: 'geo.lon' },
  { label: 'countrySubdivision', path: 'place.countrySubdivision' },
  { label: 'emailAddress', path: 'place.emailAddress' },
  { label: 'phoneNumber', path: 'place.phoneNumber' },
  { label: 'mapUrl', path: 'place.map' },
  { label: 'restrictedAccess', path: 'place.restrictedAccess' },
  { label: 'howToFindUs', path: 'place.howToFindUs' },
  { label: 'collections.enabled', path: 'services.COLLECTIONS.enabled' },
  { label: 'collections.testing', path: 'services.COLLECTIONS.testing' },
  {
    label: 'collections.integratedLockers',
    path: 'services.COLLECTIONS.integratedLockers',
  },
  {
    label: 'collections.integratedLockersSite',
    path: 'services.COLLECTIONS.integratedLockersSite',
  },
  { label: 'returns.enabled', path: 'services.RETURNS.enabled' },
  { label: 'returns.testing', path: 'services.RETURNS.testing' },
  {
    label: 'returns.integratedLockers',
    path: 'services.RETURNS.integratedLockers',
  },
  {
    label: 'returns.integratedLockersSite',
    path: 'services.RETURNS.integratedLockersSite',
  },
  {
    label: 'returns.acceptsPaperless',
    path: 'services.RETURNS.acceptsPaperless',
  },
  {
    label: 'collectionDeadlineDays',
    path: 'storeConfiguration.collectionDeadlineDays',
  },
  {
    label: 'printers.description',
    path: 'storeConfiguration.technicalConfiguration.printers[0].description',
  },
  {
    label: 'printers.host',
    path: 'storeConfiguration.technicalConfiguration.printers[0].host',
  },
  {
    label: 'printers.type',
    path: 'storeConfiguration.technicalConfiguration.printers[0].type',
  },
  { label: 'extendedHours', path: 'extendedHours' },
  { label: 'MondayOpenHours', path: 'openingHours.monday.hours[0][0]' },
  { label: 'MondayClosingHours', path: 'openingHours.monday.hours[0][1]' },
  { label: 'TuesdayOpenHours', path: 'openingHours.tuesday.hours[0][0]' },
  { label: 'TuesdayClosingHours', path: 'openingHours.tuesday.hours[0][1]' },
  { label: 'WednesdayOpenHours', path: 'openingHours.wednesday.hours[0][0]' },
  {
    label: 'WednesdayClosingHours',
    path: 'openingHours.wednesday.hours[0][1]',
  },
  { label: 'ThursdayOpenHours', path: 'openingHours.thursday.hours[0][0]' },
  { label: 'ThursdayClosingHours', path: 'openingHours.thursday.hours[0][1]' },
  { label: 'FridayOpenHours', path: 'openingHours.friday.hours[0][0]' },
  { label: 'FridayClosingHours', path: 'openingHours.friday.hours[0][1]' },
  { label: 'SaturdayOpenHours', path: 'openingHours.saturday.hours[0][0]' },
  { label: 'SaturdayClosingHours', path: 'openingHours.saturday.hours[0][1]' },
  { label: 'SundayOpenHours', path: 'openingHours.sunday.hours[0][0]' },
  { label: 'SundayClosingHours', path: 'openingHours.sunday.hours[0][1]' },
];
