import React, { Component, Fragment } from 'react';
import { Col, Row } from 'antd';
import { Dialog } from '_common/components';
import {
  TextMedium,
  CustomLink,
  CheckboxBig,
} from '_common/components/PlainStyles';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { CompaniesStore } from 'stores';

type Props = {
  companiesStore: CompaniesStore,
  dialogProps: TDialogProps,
  t: TTranslateFunc,
};

type State = {
  agreedWithAPRules: boolean,
};

class TermsAndConditionsDialog extends Component<Props, State> {

  initialState = {
    agreedWithAPRules: false,
  };

  state = { ...this.initialState };

  handleConfirm = () => {
    this.props.dialogProps.handleConfirmClick();
  };

  handleCancel = () => {
    this.setState({
      ...this.initialState,
    });
    this.props.dialogProps.handleCancelClick();
  };

  toggleAgreed = ({ target: { checked } }: Object) =>
    this.setState({ agreedWithAPRules: checked });

  renderContent = () => {
    const { agreedWithAPRules } = this.state;
    const {
      companiesStore: {
        selectedCompany: {
          companyName,
          topHierarchyCompanyName,
          termsAndConditionsUrl,
        },
      },
    } = this.props;
    const tsCompanyName = topHierarchyCompanyName || companyName;

    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col span={2}>
            <CheckboxBig
              onChange={this.toggleAgreed}
              checked={agreedWithAPRules}
            />
          </Col>
          <Col span={18}>
            <TextMedium>
              <Trans
                i18nKey="enableConsent"
                ns="promotions"
                values={{ companyName: tsCompanyName }}
              >
                I've read and understood {companyName}
                <CustomLink href={termsAndConditionsUrl} target="_blank">
                  terms&nbsp;and&nbsp;conditions
                </CustomLink>
                regarding promotions
              </Trans>
            </TextMedium>
          </Col>
        </Row>
      </Fragment>
    );
  };

  render() {
    const { dialogProps, t } = this.props;
    const { agreedWithAPRules } = this.state;

    return (
      <Dialog
        {...dialogProps}
        minHeight={320}
        justifyHeaderContent="center"
        headerText={`${t('common:confirm')} ${t(
          'promotions:termsAndConditions'
        )}`}
        confirmLabel={t('enable')}
        displayCancelButton
        isConfirmDisabled={!agreedWithAPRules}
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default compose(
  observer,
  inject('companiesStore')
)(TermsAndConditionsDialog);
