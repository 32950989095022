import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .text-right {
    text-align: right;
  }
  
  body > div:not(#root) {
    z-index: 9999;
  }

  .doddle-react-select__menu {    
    &:before, &:after {
      width: 10px;
      height: 100%;
      content: '';
      position: absolute;
      top: 0;
      background-color: #f7f7f7;
      border-radius: 2px;
    }
    
    &:before {
      left: -10px;
      border-left: solid 1px #d8e1e8;
    }
    
    &:after {
      right: -10px;
      border-right: solid 1px #d8e1e8;
    }
  }
  
  .doddle-react-select__menu-list {
    background-color: #f7f7f7;
  }
  
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    word-wrap: break-word;
  }
  
  .ant-table .ant-table-tbody > tr.promo-store-relation-row__closed-store {
    background-color: #ffebc4;
  }
`;

export default GlobalStyle;
