import styled from 'styled-components';

export const FileButton = styled.div`
  width: 110px;
  border-radius: 1px;
  background-color: #542785;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.fonts.basic};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  text-align: center;
  margin: 10px 0;
  img {
    max-width: 200px;
    max-height: 500px;
  }
`;

export const FileButtonsWrapper = styled.div`
  display: flex;
`;

export const FileInputButton = styled.input`
  display: none !important;
`;

export const FileInputBtnWrapper = styled.label`
  width: 110px;
  height: 40px;
  border-radius: 1px;
  background-color: #542785;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.fonts.basic};
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const FileInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FileName = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  color: #8c8c8c;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DeleteFile = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  text-align: right;
  color: #542785;
  cursor: pointer;
`;
