import styled, { css } from 'styled-components';
import { Button } from '_common/components/index';
import { Radio } from 'antd';

export const StyledHeader = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #e6e7e8;
  padding: 40px 30px;
  ${props =>
    props.type === 'flex' &&
    css`
      display: flex;
      justify-content: space-between;
    `};
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 26px;
  color: #494949;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const TabTitle = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 18px;
  color: #494949;
  margin-bottom: 23px;
`;

export const SectionWrapper = styled.div`
  margin-bottom: ${({ marginBottom = 23 }) => marginBottom}px;
  &:last-child {
    margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-control-input {
    min-height: 0;
  }
`;

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 0;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = 'unset' }) => justify};
  margin-bottom: ${({ marginBottom = 10 }) => marginBottom}px;

  &:last-child {
    margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
  }

  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 0;
  }
`;

export const SeparationLine = styled.div`
  width: 100%;
  margin: ${({ verticalMargin = 45 }) => verticalMargin}px 0;
  height: ${({ height = 2 }) => height}px;
  background-color: #e6e7e8;
`;

export const ButtonNext = styled(Button)`
  && {
    width: 175px;
    height: 46px;
  }
`;

export const FixedWidthRow = styled.div`
  width: ${props => props.width}px;
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `};
  .ant-legacy-form-extra {
    font-size: 12px;
  }
`;

export const StyledRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #8c8c8c;
  }

  .ant-radio-inner:after {
    background-color: #8c8c8c;
  }

  && {
    padding: 8px 85px 8px 15px;
  }
`;

export const RadioWrapper = styled.div`
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #d8e1e8;
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 10px;
  width: 160px;
  height: 40px;
  cursor: pointer;

  ${props =>
    props.selectedOption &&
    css`
      border: solid 1px #d8e1e8;
      span {
        color: #8c8c8c;
        font-weight: bold;
      }
    `};
`;

export const RadioGroupWrapper = styled.div`
  .ant-radio-group {
    display: flex;
  }
`;

export const PrinterCaption = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #6a6b6e;
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
`;

export const AddButton = styled(Button)`
  && {
    display: inline-block;
    background: #542785;
    padding: 10px 17px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    height: auto;
    width: auto;
    margin-right: 19px;
    font-family: ${props => props.theme.fonts.book};
    margin-bottom: 0;
    margin-top: 5px;
  }
`;

export const RemoveButton = styled(AddButton)`
  && {
    position: absolute;
    right: 10px;
    top: 0;
    margin: 0;
  }
`;

export const PrinterHolder = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 550px;
  padding-top: 10px;
`;

export const ErrorMessage = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
  color: #ff0000;
`;

export const ErrorBlock = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding: 4px 0;
  color: #f5222d;
`;
