// @flow strict

import React, { Component } from 'react';

class NotFound extends Component<void> {

  render() {
    return <div>Page Not Found</div>;
  }

}

export default NotFound;
