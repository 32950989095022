export const STATUSES = {
  LOADING: 'LOADING',
  NOT_CONFIGURED: 'NOT_CONFIGURED',
  ERROR: 'ERROR',
  LOADED: 'LOADED',
};

export const TYPES = {
  YAMATO: 'YAMATO',
  AMAZON: 'AMAZON',
  ASDA: 'ASDA',
  GXO_GB : 'GXO_GB',
};

export const APPLICATION_NAME = 'admintool';

export const NAMES = {
  OPS_SUMMARY: 'opsSummary',
  RETURNS_KIOSK: 'returnsKiosk',
  HANDHELD: 'handheld',
};

//Key is root companyId
export const SPACES = {
  YAMATO: 'yamato',
  AMAZON: 'amazon',
  ASDA: 'asda',
  GXO_GB: 'gxo_gb',
};

//Key is root companyId
export const DASHBOARDNAMES = {
  YAMATO: NAMES.OPS_SUMMARY,
  AMAZON: NAMES.RETURNS_KIOSK,
  ASDA: NAMES.HANDHELD,
  GXO_GB: NAMES.OPS_SUMMARY,
}
