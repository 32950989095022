import styled from 'styled-components';
import { PanelSectionTitle } from '_common/components';

export const EventSectionTitle = styled(PanelSectionTitle)`
  margin-bottom: 30px;
`;

export const DetailsWrapper = styled.div`
  .ant-row {
    margin-bottom: 10px;
  }

  .ant-col-17 {
    .ant-row {
      margin-bottom: 0;
    }
  }
`;

export const ErrorMessage = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
  color: #ff0000;
`;

export const ResetHolder = styled.div`
  text-align: right;
`;

export const TextLink = styled.a`
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  transition: 0.3s opacity cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: ${props => props.theme.colors.primary};
    opacity: 0.7;
  }
`;

export const StaticDataHolder = styled.div`
  margin-bottom: 25px;
`;

export const BackButton = styled.a`
  color: #542785;
  font-size: 16px;
  line-height: 14px;
  transition: 0.3s opacity cubic-bezier(0.645, 0.045, 0.355, 1);
  font-family: ${props => props.theme.fonts.basic};
  font-weight: 500;

  &:hover {
    color: #542785;
    opacity: 0.7;
  }
`;

export const FilterName = styled.span`
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 14px;
  font-family: ${props => props.theme.fonts.light};
  color: #8c8c8c;
`;

export const ColumnDataHolder = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 47px;
`;

export const FilterValue = styled.span`
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 14px;
  font-family: ${props => props.theme.fonts.book};
  color: #a5a6a8;
`;

export const FilterValueBold = styled(FilterValue)`
  font-weight: bold;
  color: #6a6b6e;
`;

export const EventSearchStaticPart = styled.div`
  padding: 30px 28px 32px;
  border: solid 1px #e6e7e8;
  background-color: #ffffff;
`;
