// @flow

import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router';
import { AuthStore } from 'stores';
import { get } from 'lodash';
import links from '_common/routes/urls';
import commonAction from '_common/actions';
import { Row, Col, Form } from 'antd';
import qs from 'qs';
import {
  LoginTitle,
  LoginInput,
  LoginInputLabel,
  LoginInputWrapper,
  LoginButton,
  LoginFormWrapper,
  LogoWrapper,
  SwitchCompany,
} from './elements/Login';
import storage, { LAST_LOGGED_IN_COMPANY } from 'storage';
import { withTranslation } from 'react-i18next';
import { toCamelCase } from '_common/utils/utils';
import CompanyLogo from './components/CompanyLogo';
import Notifications from '_common/components/Notifications';

type Props = {
  history: RouterHistory,
  authStore: AuthStore,
  form: Form,
  t: TTranslateFunc,
};

const Login = ({ history, authStore, t }: Props) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [lastLoggedInCompany, setLastLoggedInCompany] = useState(
    storage.get(LAST_LOGGED_IN_COMPANY)
  );
  const [queryCompanyId, setQueryCompanyId] = useState();

  useEffect(() => {
    if (get(authStore, 'getLoggedUserField.userId')) {
      history.push(commonAction.getDefaultRedirectUrl());
    }
    const { companyId } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    setQueryCompanyId(companyId);
    if (companyId && !lastLoggedInCompany) {
      form.setFieldsValue({
        companyId,
      });
    }
  }, [authStore, form, history, lastLoggedInCompany]);

  const login = ({ email, password, companyId }: Object) => {
    setIsLoading(true);

    authStore
      .authorizeStaff(email, password, companyId)
      .then((result: TLoginResponse) => {
        if (result.forceChangePassword) {
          let changePasswordLink = links.changePassword;
          if (queryCompanyId) {
            changePasswordLink = `${changePasswordLink}?companyId=${queryCompanyId}`;
          }
          history.push(changePasswordLink);
        } else {
          history.push(links.dashboard);
        }
      })
      .catch(err => {
        let message = get(err, 'data.errors[0].message');
        if (message) {
          message = toCamelCase(message);
        }
        form.setFields([
          {
            name: 'email',
            value: email,
            errors: [t(`notifications:${message ? message : 'unexpected'}`)],
          },
        ]);
        setIsLoading(false);
      });
  };

  const resetCompany = () => {
    setLastLoggedInCompany('');
    form.setFieldsValue({
      companyId: '',
    });
  };

  return (
    <>
      <Notifications />
      <Row justify="center">
        <Col xs={24} md={16} lg={12} xl={10} xxl={9}>
          <LogoWrapper>
            <CompanyLogo />
          </LogoWrapper>
          <LoginFormWrapper>
            <LoginTitle>{t('signInAdmin')}</LoginTitle>
            <Form form={form} onFinish={login}>
              <LoginInputWrapper>
                <LoginInputLabel htmlFor={'email'}>
                  {t('email')}
                </LoginInputLabel>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('inputEmail'),
                    },
                  ]}
                >
                  <LoginInput
                    id="email"
                    placeholder={t('enterEmail')}
                    autoComplete="username"
                  />
                </Form.Item>
              </LoginInputWrapper>
              <LoginInputWrapper>
                <LoginInputLabel htmlFor={'pwd'}>
                  {t('password')}
                </LoginInputLabel>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('inputPassword'),
                    },
                  ]}
                >
                  <LoginInput
                    autoComplete="current-password"
                    id="pwd"
                    type={'password'}
                    placeholder={t('enterPassword')}
                  />
                </Form.Item>
              </LoginInputWrapper>
              <LoginInputWrapper>
                <LoginInputLabel htmlFor={'companyId'}>
                  {t('companyId')}
                </LoginInputLabel>
                <Form.Item
                  name="companyId"
                  initialValue={lastLoggedInCompany}
                  rules={[
                    {
                      required: true,
                      message: t('inputCompany'),
                    },
                  ]}
                  extra={
                    !!lastLoggedInCompany && (
                      <SwitchCompany onClick={resetCompany}>
                        {t('switchCompany')}
                      </SwitchCompany>
                    )
                  }
                >
                  <LoginInput
                    disabled={!!lastLoggedInCompany}
                    id="companyId"
                    placeholder={t('enterCompany')}
                  />
                </Form.Item>
              </LoginInputWrapper>
              <LoginButton disabled={isLoading} htmlType="submit">
                {t('signIn')}
              </LoginButton>
            </Form>
          </LoginFormWrapper>
        </Col>
      </Row>
    </>
  );
};

export default compose(
  inject('authStore'),
  withRouter,
  withTranslation('login'),
  observer
)(Login);
