// @flow

import React, { Fragment } from 'react';
import {
  CustomUpload,
  UploadInnerText,
  Label,
  ProgressBar,
  HelperLink,
} from './elements';
import { Dialog } from '_common/components';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import CsvDownloader from 'react-csv-downloader';
import { Hidden } from '_common/components/PlainStyles';

type Props = {
  parseCSVAction: (content: string) => Promise<void>,
  uploadAction: Function,
  headerText: string,
  resetProgress: () => void,
  progress: number,
  dialogProps: TDialogProps,
  triggerError: boolean,
  triggerWarning: boolean,
  warning?: string,
  error?: string,
  exampleFileConfig?: Object,
  Footer?: Object,
  t: TTranslateFunc,
};

type State = {
  fileName: string,
  headerText: string,
  processingStatus: string,
};

const PROCESSING_STATUSES = {
  ERROR: 'error',
  STARTED: 'started',
  WARNING: 'warning',
  COMPLETED: 'completed',
};

class CSVDialog extends React.Component<Props, State> {

  uploadRef = React.createRef();
  exampleFileLinkRef = React.createRef();

  initialState: State = {
    fileName: '',
    headerText: this.props.t('uploadCsv'),
    processingStatus: '',
  };

  state = { ...this.initialState };

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      triggerError,
      triggerWarning,
      error,
      warning,
      headerText,
    } = this.props;
    if (prevProps.triggerError !== triggerError && error) {
      this.handleError(error);
    }
    if (prevProps.triggerWarning !== triggerWarning && warning) {
      this.handleWarning(warning);
    }
    if (prevProps.headerText !== headerText) {
      this.setState({
        headerText: headerText ? headerText : this.initialState.headerText,
      });
    }
  }

  handleWarning = (message: string) => {
    this.setState({
      processingStatus: PROCESSING_STATUSES.WARNING,
      headerText: message,
    });
  };

  handleError = (message: string) => {
    this.setState({
      processingStatus: PROCESSING_STATUSES.ERROR,
      fileName: '',
      headerText: message,
    });
  };

  beforeFileUpload = (file: Object) => {
    const { name: fileName } = file;
    const { t, parseCSVAction, uploadAction } = this.props;
    if (
      fileName &&
      fileName.includes('.') &&
      ['csv'].includes(fileName.split('.').pop())
    ) {
      this.setState({
        fileName,
        headerText: this.initialState.headerText,
      });
      if (parseCSVAction) {
        this.parseCSV(file, parseCSVAction);
      } else if (uploadAction) {
        this.uploadFile(file, uploadAction);
      }
    } else {
      this.handleError(t('invalidFileFormat'));
    }

    // Prevent upload
    return false;
  };

  parseCSV = (file, action) => {
    const { t, resetProgress } = this.props;
    const reader = new FileReader();
    reader.onload = e => {
      resetProgress();
      this.setState({
        processingStatus: PROCESSING_STATUSES.STARTED,
      });
      action(e.target.result)
        .catch(e => {
          console.error(e);
          this.handleError(t('unexpectedError'));
        })
        .finally(() => {
          if (this.state.processingStatus !== PROCESSING_STATUSES.ERROR) {
            this.setState({
              processingStatus: PROCESSING_STATUSES.COMPLETED,
            });
          }
        });
    };
    reader.readAsText(file);
  };

  uploadFile = (file, action) => {
    const { t, resetProgress } = this.props;

    const formData = new FormData();
    formData.append('stores', file);
    resetProgress();
    this.setState({
      processingStatus: PROCESSING_STATUSES.STARTED,
    });
    action(formData)
      .catch(e => {
        console.error(e);
        this.handleError(t('unexpectedError'));
      })
      .finally(() => {
        if (
          this.state.processingStatus !== PROCESSING_STATUSES.ERROR &&
          this.state.processingStatus !== PROCESSING_STATUSES.WARNING
        ) {
          this.setState({
            processingStatus: PROCESSING_STATUSES.COMPLETED,
          });
        }
      });
  };

  getStrokeColor = (): string => {
    const { processingStatus } = this.state;
    switch (processingStatus) {
      case PROCESSING_STATUSES.ERROR:
        return '#ff3654';
      case PROCESSING_STATUSES.WARNING:
        return '#c9651e';
      default:
        return '#542785';
    }
  };

  getProgress = (): number => {
    let progress = this.props.progress;
    const { fileName, processingStatus } = this.state;
    if (
      (!!fileName && processingStatus === PROCESSING_STATUSES.COMPLETED) ||
      processingStatus === PROCESSING_STATUSES.ERROR ||
      processingStatus === PROCESSING_STATUSES.WARNING
    ) {
      progress = 100;
    }
    return progress;
  };

  renderContent = () => {
    const { fileName, processingStatus } = this.state;
    const { Footer, t } = this.props;

    return (
      <Fragment>
        <Label>{t('csv')}</Label>
        <CustomUpload
          disabled={!!fileName}
          ref={this.uploadRef}
          accept=".csv"
          showUploadList={false}
          beforeUpload={this.beforeFileUpload}
        >
          <UploadInnerText>
            {fileName ? fileName : t('uploadCsv')}
          </UploadInnerText>
        </CustomUpload>
        <ProgressBar
          width={4}
          trailColor="#e6e7e8"
          strokeColor={this.getStrokeColor()}
          percent={this.getProgress()}
          status={
            processingStatus === PROCESSING_STATUSES.STARTED
              ? 'active'
              : 'normal'
          }
          showInfo={false}
        />
        {Footer}
      </Fragment>
    );
  };

  openFileBrowser = (): void => {
    const fileInput = get(this.uploadRef, 'current.upload.uploader.fileInput');
    if (fileInput && fileInput.click) {
      fileInput.click();
    }
  };

  downloadExampleFile = (): void => {
    // TODO this should be fixed if we upgrade react-scripts and migrate to typescript
    /* eslint-disable no-unused-expressions */
    // $FlowExpectedError
    this.exampleFileLinkRef.current?.handleClick?.();
  };

  handleDialogDismiss = () => {
    if (this.state.processingStatus === PROCESSING_STATUSES.STARTED) {
      return;
    }
    const { resetProgress } = this.props;
    this.setState({ ...this.initialState });
    if (resetProgress) {
      resetProgress();
    }
    const {
      dialogProps: { handleCancelClick },
    } = this.props;
    if (handleCancelClick) {
      handleCancelClick();
    }
  };

  render() {
    const { dialogProps, exampleFileConfig, t } = this.props;
    const { fileName, headerText, processingStatus } = this.state;
    const isProcessingCompleted =
      processingStatus === PROCESSING_STATUSES.COMPLETED ||
      processingStatus === PROCESSING_STATUSES.WARNING;
    return (
      <Dialog
        {...dialogProps}
        isDisabled={!!fileName && !isProcessingCompleted}
        renderContent={this.renderContent}
        headerText={headerText}
        confirmLabel={t(isProcessingCompleted ? 'ok' : 'upload')}
        handleConfirmClick={
          isProcessingCompleted
            ? this.handleDialogDismiss
            : this.openFileBrowser
        }
        handleCancelClick={this.handleDialogDismiss}
        displayCancelButton={!isProcessingCompleted}
        helper={
          exampleFileConfig ? (
            <>
              <HelperLink to="#" onClick={this.downloadExampleFile}>
                {t('seeExample')}
              </HelperLink>
              <Hidden>
                <CsvDownloader
                  separator={exampleFileConfig.separator}
                  columns={exampleFileConfig.columns}
                  datas={exampleFileConfig.data}
                  filename={exampleFileConfig.fileName}
                  ref={this.exampleFileLinkRef}
                />
              </Hidden>
            </>
          ) : null
        }
      />
    );
  }

}

export default withTranslation()(CSVDialog);
