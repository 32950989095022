// @flow

import React from 'react';
import {
  Image,
  ImageAreaRegular,
  ImageAreaSized,
  ImageBlockHolder,
  ImagePart,
  TextLink,
  ImageBlockCaption,
} from './elements';
import Lightbox from 'react-images';
import { getFileData } from '_common/utils/utils';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ImageUploadDialog from '../ImageUploadDialog';

type Props = {
  isSized?: boolean,
  src?: string,
  caption: string,
  captionWidth?: string,
  onDelete: Function,
  onReplace: ({
    data: string,
    contentType: string,
  }) => void,
  hasPermissions: boolean,
  useModal?: boolean,
  imageLibrary: Array<string>,
  maxSize?: number,
  t: TTranslateFunc,
};

type State = {
  isOpen: boolean,
};

class ImageUploadBlock extends React.Component<Props, State> {

  imageUploadDialogRef = React.createRef();

  static defaultProps = {
    hasPermissions: false,
  };

  input: any;

  state = {
    isOpen: false,
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  viewImage = () => {
    this.setState({
      isOpen: true,
    });
  };

  closePreview = () => {
    this.setState({
      isOpen: false,
    });
  };

  replaceImage = () => {
    if (this.props.useModal && this.imageUploadDialogRef.current) {
      this.imageUploadDialogRef.current.toggleDialog();
    } else if (this.input.current) {
      this.input.current.click();
    }
  };

  deleteImage = () => {
    const { onDelete } = this.props;
    if (window.confirm(this.props.t('deleteImageConfirm')) && onDelete) {
      onDelete();
    }
  };

  onChangeImage = async e => {
    const file = e.target.files[0];
    if (file) {
      const { data, contentType } = await getFileData(file);
      this.props.onReplace({ data, contentType });
    }
  };

  getLinks = () => {
    const { src, t, onDelete } = this.props;
    const imageExists = src && src.trim && src.trim();
    let links = [
      {
        label: t('view'),
        onClick: this.viewImage,
      },
      {
        label: t('replace'),
        onClick: this.replaceImage,
      },
    ];
    if (!imageExists) {
      links = [
        {
          label: t('upload'),
          onClick: this.replaceImage,
        },
      ];
    } else if (onDelete) {
      links.push({ label: t('delete'), onClick: this.deleteImage });
    }
    return links;
  };

  renderImage = () => {
    const { isSized, src } = this.props;

    const ImageArea = isSized ? ImageAreaSized : ImageAreaRegular;
    return src ? (
      <Image src={`${src}?param=${String(new Date())}`} />
    ) : (
      <ImageArea />
    );
  };

  render() {
    const {
      src,
      caption,
      hasPermissions,
      useModal,
      onReplace,
      imageLibrary,
      maxSize,
      captionWidth,
    } = this.props;

    return (
      <ImageBlockHolder>
        <ImageBlockCaption captionWidth={captionWidth}>
          {caption}
        </ImageBlockCaption>
        <ImagePart>
          {this.renderImage()}
          {hasPermissions &&
            this.getLinks().map(cur => (
              <TextLink key={cur.label} onClick={cur.onClick}>
                {cur.label}
              </TextLink>
            ))}
        </ImagePart>
        <Lightbox
          images={[{ src: src || '' }]}
          isOpen={this.state.isOpen}
          onClose={this.closePreview}
        />
        {useModal ? (
          <ImageUploadDialog
            maxSize={maxSize}
            imageLibrary={imageLibrary}
            onConfirm={onReplace}
            ref={this.imageUploadDialogRef}
            controlled
          />
        ) : (
          <input
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={this.onChangeImage}
            ref={this.input}
          />
        )}
      </ImageBlockHolder>
    );
  }

}

export default compose(withTranslation(), observer)(ImageUploadBlock);
