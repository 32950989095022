import styled from 'styled-components';

export const TimePickerWrapper = styled.div`
  .ant-picker,
  .ant-time-picker {
    width: 100%;
  }

  .ant-time-picker-input {
    height: 40px;
  }

  .ant-picker-input {
    height: 30px;
  }
`;
