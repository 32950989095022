// @flow

import React, { Component } from 'react';
import { LastInfoBlockContainer } from '_common/utils/utilsElements';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import CreateBlock from './CreateBlock';
import OffersTable from './OffersTable';
import { PromotionsStore } from 'stores';

type Props = {
  t: TTranslateFunc,
  promotionsStore: PromotionsStore,
};

type State = {};

class OfferTemplates extends Component<Props, State> {

  render() {
    const {
      t,
      promotionsStore: {
        getOffers,
        deleteOffer,
        createOffer,
        toggleEnableOffer,
      },
    } = this.props;

    return (
      <LastInfoBlockContainer>
        <CreateBlock t={t} createOffer={createOffer} />
        <OffersTable
          t={t}
          toggleEnableOffer={toggleEnableOffer}
          deleteOffer={deleteOffer}
          offers={getOffers}
        />
      </LastInfoBlockContainer>
    );
  }

}

export default compose(
  inject('promotionsStore'),
  withTranslation('company'),
  observer
)(OfferTemplates);
