// @flow

import React from 'react';
import { DatePicker, Switch } from 'antd';
import {
  EditableDataHolder,
  EditableTimePickerValueHolder,
  EditableValueHolder,
  EditIcon,
  EditStateBlock,
  UpdateButton,
} from './elements';
import { Input, Select } from '_common/components';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import get from 'lodash/get';
import AuthStore from '_common/stores/authStore';
import RangeTimePicker from './RangeTimePicker';
import { StyledTextArea } from '_common/components/Input/elements';
import ColourPicker from '../ColourPicker';
import { parseRGBA, toRGBAObject } from '_common/utils/utils';
import { Indicator } from '../ColourPicker/elements';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { RangePickerFullWidth } from '_common/components/PlainStyles';

type Props = {
  authStore: AuthStore,
  type: string,
  hideTime?: boolean,
  id: string,
  computedData?: any,
  name: string,
  placeholder?: string,
  options?: Array<TOption>,
  onUpdateClicked: ({
    id: string,
    currentValue?: string,
    previousValue: string,
    staffId: string,
  }) => void,
  dataView: any,
  data: any,
  showError: (fieldName: string, value: boolean) => void,
  path: string,
  customValue?: any,
  secondPath?: string,
  validator?: (?string) => boolean,
  hasEmptyOption?: boolean,
  emptyOptionLabel?: string,
  onEditStart?: (field: string, computedData: any) => void,
  onEditEnd?: (field: string, computedData: any) => void,
  onValueChange?: Function,
  initialValue?: any,
  skipUpdateCheck?: () => boolean,
  customUpdate: (value: any, editableBlockCallback: Function) => any,
  link?: boolean,
  t: TTranslateFunc,
  localizeValue?: boolean,
  localizePlaceholder?: boolean,
  updateIfCurrentUndefined?: boolean,
  modal: any,
  skipOnBlurValidation?: boolean,
  validatorWithMessages?: Array<any>,
  onColorChange?: Function,
  onBlur?: Function,
  setInitialFromComputed?: boolean,
};

type State = {
  edit: boolean,
  value: any,
};

class EditableBlock extends React.Component<Props, State> {

  static defaultProps = {
    localizePlaceholder: true,
  };

  state = {
    edit: false,
    value: undefined,
  };

  componentDidMount(): void {
    const { initialValue } = this.props;

    if (initialValue) {
      this.setState({
        value: initialValue,
      });
    }
  }

  switchChange = (checked: boolean) => this.setState({ value: checked });

  datePickerChange = value => this.setState({ value });

  selectChange = (option?: ?TOption) => {
    if (option) {
      this.setState({ value: option.value });
      if (this.props.onValueChange) {
        this.props.onValueChange(option.value);
      }
    }
  };

  rangeTimePickerChange = state => this.setState({ ...state });

  datePickerRangeChange = (dates: Array<Object>) => {
    this.setState({
      value: {
        from: get(dates, '[0]') ? dates[0].format('YYYY-MM-DD') : '',
        to: get(dates, '[1]') ? dates[1].format('YYYY-MM-DD') : '',
      },
    });
  };

  colorPickerChange = (color: Object) => {
    const value = parseRGBA(color.rgb);
    this.setState({
      value,
    });
    const { onColorChange } = this.props;
    if (onColorChange) {
      onColorChange(value);
    }
  };

  inputChange = ({ target: { value } }) => {
    const { showError, path, validatorWithMessages } = this.props;
    if (showError && value) {
      showError(path, false);
    }

    if (validatorWithMessages) {
      validatorWithMessages.some(({ validator, message }) => {
        if (!validator(value)) {
          showError(path, message);
          return true;
        }
        return false;
      });
    }

    this.setState({ value });
  };

  getInputValue = () => {
    const { computedData } = this.props;
    const { value } = this.state;

    const initialValue = computedData === '-' ? '' : computedData; //omit "-"
    return typeof value === 'undefined' ? initialValue : value;
  };

  getInputElem = ({
    type,
    computedData,
    placeholder = '',
    options,
    path,
    showError,
    hasEmptyOption,
    emptyOptionLabel,
    hideTime,
    data,
    customValue,
    secondPath,
    localizePlaceholder,
    skipOnBlurValidation,
    setInitialFromComputed,
  }) => {
    const initialInputValue = this.getInputValue();
    const { t, localizeValue, modal } = this.props;

    switch (type) {
      case 'modal': {
        return modal({
          inputValue: this.state.value,
          handleConfirmClick: id => {
            this.setState({ value: id });
            if (this.props.onValueChange) {
              this.props.onValueChange(id);
            }
          },
        });
      }
      case 'switch': {
        return (
          <Switch
            defaultChecked={
              customValue !== undefined ? !!customValue : !!get(data, path)
            }
            onChange={this.switchChange}
          />
        );
      }
      case 'textarea': {
        return (
          <StyledTextArea
            value={initialInputValue}
            autoSize
            placeholder={placeholder}
            onChange={this.inputChange}
          />
        );
      }
      case 'input':
        return (
          <Input
            value={initialInputValue}
            placeholder={placeholder}
            onBlur={e => {
              const { onBlur } = this.props;
              if (onBlur) {
                onBlur(e.target.value);
              }
              if (skipOnBlurValidation) {
                return;
              }
              if (showError) {
                showError(path, !e.target.value);
              }
            }}
            onChange={this.inputChange}
          />
        );
      case 'timePickerRange':
        return (
          <RangeTimePicker
            updateParentState={this.rangeTimePickerChange}
            computedData={computedData}
          />
        );
      case 'datePickerRange': {
        return (
          <RangePickerFullWidth
            placeholder={[t('from'), t('to')]}
            onChange={this.datePickerRangeChange}
            defaultValue={[
              moment(get(data, path)),
              moment(get(data, secondPath)),
            ]}
          />
        );
      }
      case 'select':
        return (
          <Select
            initialValue={setInitialFromComputed ? computedData : undefined}
            whiteSpace="break-spaces"
            value={computedData}
            hasEmptyOption={hasEmptyOption}
            emptyOptionLabel={emptyOptionLabel}
            inInfoBlock
            selectPlaceholder={localizeValue ? t(computedData) : computedData}
            onSelectOption={this.selectChange}
            options={options}
            inputPlaceholder={computedData}
            localizePlaceholder={localizePlaceholder}
          />
        );
      case 'datepicker':
        return (
          <DatePicker showTime={!hideTime} onChange={this.datePickerChange} />
        );
      case 'color': {
        const initialColor = toRGBAObject(computedData);
        return (
          <ColourPicker
            initialRGBA={initialColor}
            onChange={this.colorPickerChange}
          />
        );
      }
      default:
        return null;
    }
  };

  update = () => {
    const { value } = this.state;
    const {
      onUpdateClicked,
      customUpdate,
      computedData,
      id,
      authStore,
      data,
      showError,
      path,
      validator,
      skipUpdateCheck,
      updateIfCurrentUndefined,
      validatorWithMessages,
    } = this.props;
    const updateCallback = () => {
      this.setEdit(false)();
      if (showError) {
        showError(path, false);
      }
    };
    if (customUpdate && typeof customUpdate === 'function') {
      return customUpdate(value, updateCallback);
    }
    const skipUpdate =
      skipUpdateCheck !== undefined ? !skipUpdateCheck() : value === undefined;
    if (skipUpdate) {
      this.setEdit(false)();
      return;
    } else if (validatorWithMessages) {
      if (
        validatorWithMessages.some(({ validator, message }) => {
          if (!validator(value)) {
            showError(path, message);
            return true;
          }
          return false;
        })
      ) {
        return;
      }
    } else if (validator && !validator(value)) {
      showError(path, true);
      return;
    }

    if (
      (computedData !== undefined || updateIfCurrentUndefined) &&
      !skipUpdate
    ) {
      const updateResult = onUpdateClicked({
        id,
        currentValue: value,
        previousValue: computedData ? computedData : '',
        staffId: get(authStore, 'loggedUser.staffId'),
        data: data,
      });
      if (
        typeof updateResult === 'object' &&
        typeof updateResult.then === 'function'
      ) {
        updateResult.then(hasError => {
          if (!hasError) {
            updateCallback();
          }
        });
      } else if (!updateResult) {
        updateCallback();
      }
    }
  };

  setEdit = (edit: boolean) => () => {
    this.setState({ edit });
    const { computedData, onEditStart, onEditEnd, path } = this.props;
    const fieldName = path ? get(path.split('.').slice(-1), '[0]') : '';
    if (edit) {
      if (onEditStart) {
        onEditStart(fieldName, computedData);
      }
      this.setState({
        value: undefined,
      });
    } else if (onEditEnd) {
      onEditEnd(fieldName, computedData);
    }
  };

  render() {
    const { edit } = this.state;
    const { dataView, type, computedData, link, t } = this.props;
    const EditableValueHolderEl =
      type === 'timePickerRange'
        ? EditableTimePickerValueHolder
        : EditableValueHolder;
    return edit ? (
      <EditStateBlock>
        <EditableDataHolder>
          {this.getInputElem({ ...this.props })}
        </EditableDataHolder>
        <UpdateButton onClick={this.update}>{t('update')}</UpdateButton>
      </EditStateBlock>
    ) : (
      <EditableValueHolderEl>
        {link ? (
          <a href={dataView} target="_blank" rel="noopener noreferrer">
            {dataView}
          </a>
        ) : (
          dataView
        )}
        {type === 'color' && (
          <Indicator top={28} right={11} color={toRGBAObject(computedData)} />
        )}
        <EditIcon type="edit" onClick={this.setEdit(true)} />
      </EditableValueHolderEl>
    );
  }

}

export default compose(
  withTranslation(),
  inject('authStore'),
  observer
)(EditableBlock);
