import styled from 'styled-components';

export const StyledLogo = styled.img`
  max-width: 136px;
  max-height: ${props => (props.maxHeight ? props.maxHeight : 60)}px;
  margin: 0 auto;
  display: inline-block;
  @media only screen and (max-width: 1099px) {
    max-width: 90px;
  }
`;
