// @flow

import React from 'react';
import {
  WideColumn,
  AddButton,
  AltNameHolder,
  RemoveButton,
  AddAltNameToStoreButton,
  CaptionPart,
  InfoRow,
  DataPart,
  AltNameHeader,
  AltNameContent,
} from '_common/components/DetailsPage/elements';
import { getInfoBlock } from '_common/utils/utils';
import { alternativeNamesDetailsBlock } from './config';
import DoddleStoresStore from '_common/stores/doddleStoresStore';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import get from 'lodash/get';
import doddleStoresActions from 'doddleStores/actions';
import { toJS } from 'mobx';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from '_common/components';
import { withTranslation } from 'react-i18next';

type Props = {|
  doddleStores: DoddleStoresStore,
  form: Form,
  t: TTranslateFunc,
|};

type State = {|
  notSubmittedAlternativeNames: Object,
|};

class DoddleStoreDetailsAlternativeNames extends React.Component<Props, State> {

  state = {
    notSubmittedAlternativeNames: {},
  };

  addAltName = () => {
    this.setState(prevState => ({
      notSubmittedAlternativeNames: {
        ...prevState.notSubmittedAlternativeNames,
        [Date.now()]: {
          id: '',
          language: '',
          storeName: '',
        },
      },
    }));
  };

  removeAltName = (i: number) => () => {
    const { doddleStores } = this.props;
    const arr =
      toJS(get(doddleStores, 'singleStore.alternativeStoreNames')) || [];
    arr.splice(i, 1);
    doddleStoresActions.updateStoreById(
      get(doddleStores, 'singleStore.storeId'),
      {
        alternativeStoreNames: [...arr],
      },
      true,
      get(doddleStores, 'singleStore.revisionNumber')
    );
  };

  removeNotSubmittedAltName = (key: string, removeAll?: boolean) => () => {
    this.setState(prevState => {
      if (removeAll) {
        return {
          notSubmittedAlternativeNames: {},
        };
      }
      const notSubmittedAlternativeNames =
        prevState.notSubmittedAlternativeNames;
      delete notSubmittedAlternativeNames[key];
      const conditionalObj = {};

      return {
        notSubmittedAlternativeNames,
        ...conditionalObj,
      };
    });
  };

  addSelectedAltNameToStore = (key: string) => () => {
    const {
      form: { validateFields },
      doddleStores,
    } = this.props;

    validateFields(
      [`${key}.id`, `${key}.language`, `${key}.storeName`],
      (errors, values) => {
        if (errors) {
          return;
        }

        doddleStoresActions.updateStoreById(
          get(doddleStores, 'singleStore.storeId'),
          {
            alternativeStoreNames: [
              ...(get(doddleStores, 'singleStore.alternativeStoreNames') || []),
              { ...values[key] },
            ],
          },
          true,
          get(doddleStores, 'singleStore.revisionNumber'),
          () => {
            this.removeNotSubmittedAltName(key);
          }
        );
      }
    );
  };

  getAltNamesBlock = (key: string, i: number) => {
    const {
      form: { getFieldDecorator },
      t,
    } = this.props;

    return (
      <AltNameContent key={key}>
        <AltNameHeader>{t('notSubmittedName', { count: i + 1 })}</AltNameHeader>
        <InfoRow>
          <CaptionPart>{t('alternativeNameId')}</CaptionPart>
          <DataPart>
            <Form.Item>
              {getFieldDecorator(`${key}.id`, {
                rules: [
                  {
                    required: true,
                    message: t('inputId'),
                  },
                ],
              })(<Input placeholder={t('alternativeNameId')} />)}
            </Form.Item>
          </DataPart>
        </InfoRow>
        <InfoRow>
          <CaptionPart>{t('language')}</CaptionPart>
          <DataPart>
            <Form.Item>
              {getFieldDecorator(`${key}.language`, {
                rules: [
                  {
                    required: true,
                    message: t('inputLanguage'),
                  },
                ],
              })(<Input placeholder={t('language')} />)}
            </Form.Item>
          </DataPart>
        </InfoRow>
        <InfoRow>
          <CaptionPart>{t('storeName')}</CaptionPart>
          <DataPart>
            <Form.Item>
              {getFieldDecorator(`${key}.storeName`, {
                rules: [
                  {
                    required: true,
                    message: t('inputStoreName'),
                  },
                ],
              })(<Input placeholder={t('storeName')} />)}
            </Form.Item>
          </DataPart>
        </InfoRow>
        <AddAltNameToStoreButton onClick={this.addSelectedAltNameToStore(key)}>
          {t('addToStore')}
        </AddAltNameToStoreButton>
        <RemoveButton onClick={this.removeNotSubmittedAltName(key)}>
          {t('common:remove')}
        </RemoveButton>
      </AltNameContent>
    );
  };

  handleErrorWithDetailsBlock = (fieldName, value) =>
    this.setState({ [fieldName]: value });

  renderAltStoreName = (cur, i: number) => {
    const { doddleStores, t } = this.props;

    return (
      <AltNameHolder key={cur.id + i}>
        {getInfoBlock(
          alternativeNamesDetailsBlock(
            i,
            this.handleErrorWithDetailsBlock,
            this.state
          ),
          false,
          doddleStores
        )}
        <RemoveButton onClick={this.removeAltName(i)}>
          {t('common:remove')}
        </RemoveButton>
      </AltNameHolder>
    );
  };

  render() {
    const { doddleStores, t } = this.props;
    const { notSubmittedAlternativeNames } = this.state;
    const alternativeStoreNames =
      get(doddleStores, 'singleStore.alternativeStoreNames') || [];

    return (
      <Form>
        <WideColumn>
          {Object.keys(notSubmittedAlternativeNames).map(this.getAltNamesBlock)}
          {alternativeStoreNames.length > 0 &&
            alternativeStoreNames.map(this.renderAltStoreName)}
          <AddButton onClick={this.addAltName}>
            {t('addAlternativeStoreName')}
          </AddButton>
        </WideColumn>
      </Form>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  Form.create(),
  observer
)(DoddleStoreDetailsAlternativeNames);
