import React, { Component } from 'react';
import { compose } from 'recompose';
import EditableBlock from '_common/components/InfoBlock/EditableBlock';
import {
  PRODUCT_IMAGE_RESTRICTIONS,
  PROMO_DISPLAY_TYPE_OPTIONS,
  PROMO_DISPLAY_TYPES,
  PROMOTION_LENGTH_RESTRICTIONS,
} from '_common/constants/promotions';
import PromotionDisplayField from 'promotions/components/PromotionDisplayField';
import {
  CaptionHolder,
  CaptionPart,
  ErrorHolder,
  Header,
  InfoColumn,
  InfoRow,
} from '_common/components/InfoBlock/elements';
import ImageUploadBlock from '_common/components/ImageUploadBlock';
import { get } from 'lodash';
import {
  localizeOptions,
  maxLengthValidator,
  requiredValidator,
  toCamelCase,
} from '_common/utils/utils';
import { withTranslation } from 'react-i18next';
import { Form } from 'antd';

type State = {
  newPromoDisplayType: string,
  isPromoDisplayTypeInEdit: boolean,
};

type Props = {
  singlePromotion: TPromotion,
  updatePromotionDisplayType: (string, Object) => Promise<any>,
  uploadProductImage: (string, Object) => Promise<any>,
  updatePromotion: (string, Object) => Promise<any>,
  updateSinglePromotion: Function,
  errors: Object,
  setError: Function,
  addFieldInEdit: (field: string) => void,
  removeFieldInEdit: (field: string) => void,
  t: TTranslateFunc,
  imageLibrary: Array<string>,
  onChange: Function,
  onPromotionCodeChange: Function,
};

class PromotionDisplayTypeBlock extends Component<Props, State> {

  formRef = React.createRef();

  static defaultProps = {
    isEditLayout: false,
  };

  displayFieldName = null;

  state = {
    isPromoDisplayTypeInEdit: false,
    newPromoDisplayType: '',
  };

  togglePromoDisplayTypeInEdit = (field: string) => {
    const { addFieldInEdit, removeFieldInEdit } = this.props;
    const newValue = !this.state.isPromoDisplayTypeInEdit;
    this.setState({
      isPromoDisplayTypeInEdit: newValue,
    });
    newValue
      ? addFieldInEdit(field)
      : removeFieldInEdit([field, 'barcode', 'promotionCode']);
  };

  changePromoDisplayType = (value: string) => {
    this.setState({
      newPromoDisplayType: value,
    });
  };

  updatePromoDisplayType = async ({
    id,
    currentValue,
    data,
  }: TDetailsUpdate) => {
    const { updatePromotionDisplayType, updateSinglePromotion } = this.props;
    const { newPromoDisplayType } = this.state;

    const promoDisplayFieldName = this.displayFieldName;

    let error = false;

    const form = this.formRef.current;
    if (form) {
      try {
        await form.validateFields();
        updateSinglePromotion(updatePromotionDisplayType, [
          newPromoDisplayType,
          {
            [promoDisplayFieldName]: form.getFieldValue(promoDisplayFieldName),
          },
        ]);
      } catch (e) {
        error = true;
      }
    }

    return error;
  };

  changePromoTypeFieldName = (value: string) => {
    this.displayFieldName = value;
  };

  updateProductImage = (imageData: TFileDataType) => {
    const { uploadProductImage, updateSinglePromotion } = this.props;
    updateSinglePromotion(uploadProductImage, [imageData]);
  };

  updatePromotionField = (field: string) => ({
    id,
    currentValue,
    data,
  }: TDetailsUpdate) => {
    const { updateSinglePromotion, updatePromotion } = this.props;
    if (currentValue) {
      updateSinglePromotion(updatePromotion, [
        {
          [field]: currentValue,
        },
      ]);
    } else {
      return true;
    }
  };

  onPromotionCodeChange = (value: any) => {
    this.props.onPromotionCodeChange(value);
  };

  getEditablePromoDisplayField = (type: string) => {
    const {
      singlePromotion,
      errors,
      setError,
      addFieldInEdit,
      removeFieldInEdit,
      t,
      imageLibrary,
    } = this.props;
    let resultElem = null;
    let path = null;
    let errorMessage = null;

    switch (type) {
      case PROMO_DISPLAY_TYPES.BARCODE: {
        errorMessage = t('enterBarcode');
        path = 'barcode';
        resultElem = (
          <InfoRow>
            <CaptionHolder>
              <CaptionPart>{t('barcode')}*</CaptionPart>
            </CaptionHolder>
            <EditableBlock
              validator={requiredValidator}
              showError={setError}
              placeholder={t('enterBarcodePlaceholder')}
              type="input"
              computedData={singlePromotion.barcode}
              data={singlePromotion}
              dataView={singlePromotion.barcode}
              path={path}
              onEditStart={addFieldInEdit}
              onEditEnd={removeFieldInEdit}
              name={t('barcode')}
              onUpdateClicked={this.updatePromotionField('barcode')}
            />
          </InfoRow>
        );
        break;
      }
      case PROMO_DISPLAY_TYPES.PRODUCT_IMAGE: {
        path = 'image';
        resultElem = (
          <ImageUploadBlock
            useModal
            hasPermissions
            captionWidth="42.5%"
            maxSize={PRODUCT_IMAGE_RESTRICTIONS.size}
            imageLibrary={imageLibrary}
            src={get(singlePromotion, 'image')}
            caption={t('productImage')}
            onReplace={this.updateProductImage}
          />
        );
        break;
      }
      case PROMO_DISPLAY_TYPES.PROMOTION_CODE: {
        path = 'promotionCode';
        errorMessage = get(errors, path);
        resultElem = (
          <InfoRow>
            <CaptionHolder>
              <CaptionPart>{t('promotionCode')}</CaptionPart>
            </CaptionHolder>
            <EditableBlock
              onBlur={this.onPromotionCodeChange}
              validatorWithMessages={[
                {
                  validator: requiredValidator,
                  message: t('enterPromotionCode'),
                },
                {
                  validator: maxLengthValidator(
                    PROMOTION_LENGTH_RESTRICTIONS.PROMOTION_CODE.max
                  ),
                  message: t('promotionCodeLengthRule', {
                    max: PROMOTION_LENGTH_RESTRICTIONS.PROMOTION_CODE.max,
                  }),
                },
              ]}
              showError={setError}
              placeholder={t('enterPromoPlaceholder')}
              type="input"
              computedData={singlePromotion.promotionCode}
              data={singlePromotion}
              dataView={singlePromotion.promotionCode}
              path={path}
              onEditStart={addFieldInEdit}
              onEditEnd={removeFieldInEdit}
              name={t('promotionCode')}
              onUpdateClicked={this.updatePromotionField('promotionCode')}
            />
          </InfoRow>
        );
        break;
      }
      default: {
        resultElem = null;
      }
    }

    return (
      <InfoColumn>
        {resultElem}
        {get(errors, path) ? <ErrorHolder>{errorMessage}</ErrorHolder> : null}
      </InfoColumn>
    );
  };

  computePromotionDisplayType = () => {
    const { singlePromotion } = this.props;
    let result = '';
    if (singlePromotion) {
      const { barcode, promotionCode, image } = singlePromotion;
      if (barcode) {
        result = PROMO_DISPLAY_TYPES.BARCODE;
      } else if (promotionCode) {
        result = PROMO_DISPLAY_TYPES.PROMOTION_CODE;
      } else if (image) {
        result = PROMO_DISPLAY_TYPES.PRODUCT_IMAGE;
      }
    }
    return result;
  };

  onInputBlur = () => {
    const form = this.formRef.current;
    if (form) {
      this.props.onChange(form.getFieldsValue());
    }
  };

  render() {
    const { isPromoDisplayTypeInEdit, newPromoDisplayType } = this.state;
    const { singlePromotion, t, imageLibrary } = this.props;

    const currentDisplayType = this.computePromotionDisplayType();

    return (
      <div>
        <Form ref={this.formRef}>
          <Header>{t('design')}</Header>
          <InfoColumn>
            <InfoRow>
              <CaptionHolder>
                <CaptionPart>{t('displayType')}</CaptionPart>
              </CaptionHolder>
              <EditableBlock
                placeholder={t('selectDisplayType')}
                type="select"
                computedData={currentDisplayType}
                options={localizeOptions(
                  PROMO_DISPLAY_TYPE_OPTIONS,
                  t,
                  'promotions'
                )}
                data={singlePromotion}
                dataView={t(
                  toCamelCase(
                    get(
                      PROMO_DISPLAY_TYPE_OPTIONS.find(
                        item => item.value === currentDisplayType
                      ),
                      'label',
                      currentDisplayType
                    )
                  )
                )}
                path="promoDisplayType"
                name={t('promoDisplayType')}
                updateIfCurrentUndefined
                setInitialFromComputed
                onValueChange={this.changePromoDisplayType}
                onEditStart={this.togglePromoDisplayTypeInEdit}
                onEditEnd={this.togglePromoDisplayTypeInEdit}
                onUpdateClicked={this.updatePromoDisplayType}
              />
            </InfoRow>
          </InfoColumn>
          {isPromoDisplayTypeInEdit ? (
            <PromotionDisplayField
              onBlurHandler={this.onInputBlur}
              imageLibrary={imageLibrary}
              isEditLayout
              getFieldName={this.changePromoTypeFieldName}
              labelWidth={280}
              form={this.formRef.current}
              type={newPromoDisplayType || currentDisplayType}
            />
          ) : (
            this.getEditablePromoDisplayField(currentDisplayType)
          )}
        </Form>
      </div>
    );
  }

}

export default compose(withTranslation('promotions'))(
  PromotionDisplayTypeBlock
);
