// @flow

import React from 'react';
import type { Node } from 'react';
import { Table as AntdTable } from 'antd';
import { TableWrapper, ViewLink, ActionLink } from './elements';
import { PaginationConfig } from 'antd/es/pagination';
import { withTranslation } from 'react-i18next';

type Props = {
  t: TTranslateFunc,
  rowSelection: Object,
  rowKey: string | Function,
  scroll?: { x: number },
  id?: string,
  data: Array<{
    [key: any]: any,
  }>,
  columns: TColumns,
  disableActionColumn?: boolean,
  onCell?: Function,
  loading?: boolean,
  EditLinkComponent?: Node,
  ignoreAdditionalColumn?: boolean,
  customActionColumn?: boolean,
  className?: string,
  emptyElement?: any,
  actionRedirectLink?: Object => string | Object,
  pagination?: PaginationConfig | TStorePaginationConfig,
  setRowClassName?: (any, number) => string,
  onChange?: Function,
};

class Table extends React.Component<Props> {

  link = (<ViewLink>{this.props.t('view')}</ViewLink>);

  addActionColumn = (
    columns: TColumns,
    onCell?: Function,
    EditLinkComponent: Node = this.link,
    customActionColumn: ?boolean
  ): TColumns => {
    if (customActionColumn) return columns;
    const { actionRedirectLink } = this.props;

    const action = {
      title: '',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: row => {
        const { actionRedirectLink } = this.props;

        const link = (actionRedirectLink && actionRedirectLink(row)) || '#';

        return <ActionLink to={link}>{EditLinkComponent}</ActionLink>;
      },
      onCell: actionRedirectLink ? () => {} : onCell,
    };

    return columns.concat(action);
  };

  render() {
    const {
      columns,
      data,
      rowKey,
      id,
      className,
      scroll,
      onCell,
      loading,
      emptyElement,
      EditLinkComponent,
      customActionColumn,
      pagination,
      setRowClassName,
      onChange,
      rowSelection,
    } = this.props;

    const mergedColumns = this.addActionColumn(
      columns,
      onCell,
      EditLinkComponent,
      customActionColumn
    );

    const paginationConfig = pagination
      ? pagination
      : {
          total: data.length,
          hideOnSinglePage: true,
          showSizeChanger: false,
          hideLastPage: false,
        };

    return (
      <TableWrapper hideLastPage={paginationConfig.hideLastPage}>
        <AntdTable
          rowSelection={rowSelection ? { ...rowSelection } : null}
          onChange={onChange}
          rowClassName={setRowClassName}
          scroll={scroll}
          id={id}
          className={className}
          locale={{
            emptyText: emptyElement ? emptyElement : 'No Data were found',
          }}
          pagination={paginationConfig}
          rowKey={rowKey}
          columns={mergedColumns}
          dataSource={data}
          loading={loading}
        />
      </TableWrapper>
    );
  }

}

export default withTranslation()(Table);
