import { get, upperFirst, debounce, set, isEmpty, omit, unset } from 'lodash';
import { InfoBlockContainer, LastInfoBlockContainer } from './utilsElements';
import { message } from 'antd';
import { InfoBlock } from '_common/components';
import React from 'react';
import {
  COMPANY_SCOPE_TEMPLATE,
  MAIN_COMPANIES,
} from '_common/constants/appConfig';
import { AVAILABILITY_CONFIG } from '_common/constants/stores';
import moment from 'moment';
import commonActions from '_common/actions';
import { STATUSES } from '_common/constants/stores';
import {
  StoreStatus,
  StoreStatusDeactivated,
  StoreStatusPlanned,
} from 'doddleStores/doddleStoresDetails/pages/Main/elements';
import env from '@beam-australia/react-env';

export const createStoreNormalizeData = (store: TStore): TStore => {
  const normalizeFields = [
    'externalStoreId',
    'companyStoreId',
    'place.address.line2',
  ];
  for (const field of normalizeFields) {
    if (!get(store, field)?.trim()) {
      unset(store, field);
    }
  }

  return store;
};

export const updateStoreNormalizeData = (store: TStore): TStore => {
  const addressLine2 = get(store, 'place.address.line2');
  if (addressLine2?.trim() === '') {
    set(store, 'place.address.line2', null);
  }

  if (store.externalStoreId?.trim() === '') {
    store.externalStoreId = null;
  }

  if (store.companyStoreId?.trim() === '') {
    delete store.companyStoreId;
  }

  return store;
};

export const mergeParams = (
  initialParams: Object,
  newParams: Object
): Object => {
  let result = { ...initialParams };
  if (!isEmpty(newParams)) {
    Object.entries(newParams).forEach(([key, value]) => {
      if (value !== null) {
        result = { ...result, [key]: value };
      } else {
        result = { ...omit(result, [key]) };
      }
    });
  }
  return result;
};

export const composeCompanySpecificRole = (role: string, companyId: string) =>
  `${role}_${companyId}`;

export const generateGoogleMapsLink = ({ lat, lon }) =>
  `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;

export const detectEnvironment = () => {
  let result = 'localhost';
  const hostSplit = window.location.host.split('.');
  if (hostSplit.length > 2) {
    result = hostSplit[1];
  }
  return result;
};

export const isStoreApproved = (status) => status !== 'PROPOSED';

export const getStoreAddressFromLoqateAddress = (
  address: TLoqateAddress,
  subCountriesOptions: Array<TOption>
) => {
  const {
    Label,
    City,
    CountryIso2,
    PostalCode,
    AdminAreaCode,
    AdminAreaName,
    ProvinceCode,
    ProvinceName,
    Line2,
    Line1,
    Line3,
    Line4,
    Line5,
  } = address;
  let countrySubdivision = '';
  if (ProvinceCode) {
    const provinceCode = `${CountryIso2}-${ProvinceCode}`;
    if (
      subCountriesOptions[CountryIso2]?.subcountries?.find(
        (item) => item.value === provinceCode
      )
    ) {
      countrySubdivision = provinceCode;
    }
  } else {
    countrySubdivision = get(
      subCountriesOptions[CountryIso2]?.subcountries?.find(
        (row) => row.label === (AdminAreaName || ProvinceName)
      ),
      'value'
    );
  }
  let addressLine1 = Line1;
  if (!/[a-zA-Z]+/.test(addressLine1)) {
    const labelSplit = Label.split('\n');
    if (labelSplit.length) {
      addressLine1 = labelSplit[0];
    }
  }
  return {
    country: CountryIso2,
    countrySubdivision,
    area: AdminAreaCode ? AdminAreaCode : ProvinceCode,
    town: City,
    postcode: PostalCode,
    line1: addressLine1,
    line2: `${Line2}, ${Line3}, ${Line4}, ${Line5}`.replace(/(,+\s*)+$/, ''),
  };
};

export const toCamelCase = (text: string) =>
  text && typeof text === 'string'
    ? text
        .replace('_', ' ')
        .split(' ')
        .map((word, index) =>
          index === 0 ? word.toLowerCase() : upperFirst(word.toLowerCase())
        )
        .join('')
    : '';

export const localizeOptions = (
  options: Array<TOption>,
  t: TTranslateFunc,
  ns: string | Array<string> = ['common']
): Array<TOption> =>
  options.map(({ label, value }) => ({
    value,
    label: t(toCamelCase(label), { ns }),
  }));

export const localizeStoreStatus = (
  status: string,
  t: TTranslateFunc,
  options: Object
): string => {
  let result = status;
  if (status) {
    const statusTranslated = t(toCamelCase(status));
    switch (status) {
      case STATUSES.OPENING_SOON: {
        const date = get(options, 'openingDate');
        if (date) {
          result = t('openingSoonDate', { date });
        }
        break;
      }
      case STATUSES.CLOSING_SOON: {
        const date = get(options, 'closingDate');
        if (date) {
          result = t('closingSoonDate', { date });
        }
        break;
      }
      case STATUSES.PROPOSED:
      case STATUSES.PLANNED:
      case STATUSES.TEMPORARILY_CLOSED:
      case STATUSES.PERMANENTLY_CLOSED:
      case STATUSES.TRADING:
      default: {
        result = statusTranslated;
        break;
      }
    }
  }
  return result;
};

/**
 * Returns the last occurrence of specified event
 *
 * @param {Object} store
 * @param {String} eventType STORE_EVENT_TYPES
 * @returns {Object}
 */
export const getStoreLastEvent = (
  store: Object,
  eventType: string
): ?Object => {
  let result;
  if (store.eventHistory) {
    result = store.eventHistory
      .slice()
      .reverse()
      .find((event) => event.eventType === eventType);
  }
  return result;
};

export const getStoreStatusElement = (
  status: string,
  t: TTranslateFunc,
  options: {
    openingDate?: string,
    closingDate?: string,
    includeStatusString?: boolean,
  }
) => {
  let localizedStatus = localizeStoreStatus(status, t, {
    openingDate: moment(get(options, 'openingDate'), 'YYYY-MM-DDThh:mm:ss'),
    closingDate: moment(get(options, 'closingDate'), 'YYYY-MM-DDThh:mm:ss'),
  });
  if (options.includeStatusString) {
    localizedStatus = `${t('storeStatus')} : ${localizedStatus}`;
  }
  switch (status) {
    case STATUSES.PROPOSED:
    case STATUSES.OPENING_SOON:
    case STATUSES.PLANNED:
      return <StoreStatusPlanned>{localizedStatus}</StoreStatusPlanned>;
    case STATUSES.TEMPORARILY_CLOSED:
    case STATUSES.CLOSING_SOON:
    case STATUSES.PERMANENTLY_CLOSED:
      return <StoreStatusDeactivated>{localizedStatus}</StoreStatusDeactivated>;
    case STATUSES.TRADING:
    default:
      return <StoreStatus>{localizedStatus}</StoreStatus>;
  }
};

export const formatOfferVariables = (variables: Object): Array<Object> => {
  return Object.entries(variables).map(([id, value]) => ({
    id,
    value,
  }));
};

export const getSubCountriesOptions = (doddleStores, locationType, country) => {
  if (!country) {
    return [];
  }
  let subCountryOptions;
  if (locationType) {
    subCountryOptions = get(
      doddleStores.overrideSubCountries,
      `${locationType}.${country}.subcountries`
    );
  }
  return (
    subCountryOptions ||
    get(doddleStores.commonSubCountries, `${country}.subcountries`, [])
  );
};

export const getOrganisationFromUrl = () => {
  const doddleHostNames = [
    'ADMIN',
    'ADMINV3',
    'TEST-ADMIN-TOOL-V2',
    'STAGE-ADMIN-TOOL-V2',
    'LOCALHOST',
  ];

  const apacEnv = 'apac';

  const { hostname } = window.location;
  let org = hostname.split('.')[0].toUpperCase();

  if (hostname.includes(apacEnv)) {
    org = MAIN_COMPANIES.AUSTRALIA_POST_ID;
  } else if (doddleHostNames.includes(org)) {
    org = MAIN_COMPANIES.DODDLE_ID;
  }
  return org;
};

export const removeOptionIfExist = (
  options: Array<TOption>,
  optionValue: string
): Array<TOption> => {
  const result = [...options];
  const optionIndex = result.findIndex(
    (option) => option.value === optionValue
  );
  if (optionIndex !== -1) {
    result.splice(optionIndex, 1);
  }
  return result;
};

export const isCompanyScope = (scope) =>
  scope && scope.startsWith(COMPANY_SCOPE_TEMPLATE);

export const getCompanyFromScope = (scope) =>
  scope.replace(COMPANY_SCOPE_TEMPLATE, '');

export const getScopeFromCompany = (companyId) =>
  `${COMPANY_SCOPE_TEMPLATE}${companyId}`;

export const scrollToTable = debounce(() => {
  const table = document.getElementById('table');
  const tables = document.querySelectorAll('.doddle-table');

  const tablesArr = Array.from(tables);

  if (tablesArr.length) {
    tablesArr.forEach((table) => {
      table.scrollIntoView({
        behavior: 'smooth',
      });
    });
  } else {
    if (table) {
      table.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
}, 300);

export const getPassword = () =>
  ['0123456789', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz']
    .map((i) =>
      Array(4)
        .fill(i)
        .map(function (x) {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join('')
    )
    .join('');

export const passwordRegExp = new RegExp(/^(?=.*\d)(?=.*[A-Z]).{8,20}$/);

export const storeIdRegExp = new RegExp(/^([A-Z\d]{1,})(_[A-Z\d]{1,}){0,10}/);

export const optionsValidator = (
  value: ?string,
  options: Array<string>
): boolean => {
  if (!value) {
    return true;
  }
  return options.includes(value);
};

export const emptyValidator = (rule, value) => value !== undefined;

export const emptyValidatorAsync = async (rule, value) => {
  if (value === undefined) {
    throw new Error('Please input field');
  }
};

export const validateEmail = (email: string): boolean =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    email
  );

export const validateCoordinate = (value: string): boolean =>
  /^(-)?[0-9.]+$/.test(value);

export const validateDate =
  (format: string = 'YYYY-MM-DD') =>
  (value: string) =>
    moment(value, format).isValid();

export const minLengthValidator =
  (minLength: number, validator?: (value: any) => boolean) => (value: string) =>
    value && value.length >= minLength && (!validator || validator(value));

export const maxLengthValidator =
  (maxLength: number, validator?: (value: any) => boolean) => (value: string) =>
    value && value.length <= maxLength && (!validator || validator(value));

export const requiredValidator = (value?: ?string): boolean =>
  value && typeof value === 'string' && !!value.trim().length;

export const allowEmptyValidator =
  (validator: (value: any) => boolean) =>
  (value?: ?string): boolean =>
    !value || validator(value);

export const normalizeUrl = (value: string) =>
  !value || value.startsWith('http') ? value : `https://${value}`;

export const normalizeNumber = (value: string) =>
  !value ? undefined : Number(value);

// eslint-disable-next-line no-useless-escape
export const validateUrl = (url: string): boolean =>
  url &&
  new RegExp(
    /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
    'i'
  ).test(url);

export const ruleValidator =
  (
    validateFunc: (value: any) => boolean,
    errorMessage: string = 'Please enter a valid value'
  ) =>
  (rule: any, value: string, callback: (msg?: any) => void) => {
    const error = new Error(errorMessage);
    // In case field is not required
    if (!rule.required && !value) {
      callback();
    } else if (validateFunc(value)) {
      return callback();
    } else {
      callback(error);
    }
  };

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  let result = false;
  // contains invalid chars (0-9, whitespaces, '(', ')', '-', '+' are valid)
  if (/[^\d-\s()+]/.test(phoneNumber) || phoneNumber.includes('--')) {
    return result;
  }
  const formattedValue = phoneNumber.replace(/[-\s]/g, '');
  const digitsCount = (formattedValue.match(/\d/g) || []).length;
  const validLength = digitsCount >= 4 && digitsCount <= 15;

  // +XXXXXX or +XX(XXX)XXX
  if (
    validLength &&
    (/^\+?\d+$/.test(formattedValue) || /^\+?\d*(\d+)\d+$/.test(formattedValue))
  ) {
    result = true;
  }
  return result;
};

export const validateDateRange = ({
  from,
  to,
}: {
  from: string,
  to: string,
}): boolean => {
  return from && to;
};

export const hasOwnKeys = (object: Object, keys: Array<string>): boolean => {
  let hasAllKeys = true;
  for (const key of keys) {
    if (!object.hasOwnProperty(key)) {
      hasAllKeys = false;
      break;
    }
  }
  return hasAllKeys;
};

export const cleanObject = (obj?: Object, cleanValues: Array<any> = [null]) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object')
        cleanObject(obj[key], cleanValues);
      // recurse
      else if (cleanValues.includes(obj[key])) delete obj[key]; // delete
    });
  }
};

export const concurrentRequests = (
  argsList: Array<any>,
  asyncOperation: Promise,
  concurrencyLimit: number = 5
): Promise<any> => {
  if (concurrencyLimit > argsList.length) {
    concurrencyLimit = argsList.length;
  }
  const promises = new Array(concurrencyLimit).fill(Promise.resolve());

  function chainNext(p: Promise) {
    if (argsList.length) {
      const arg = argsList.shift();
      return p.then(() => {
        // Store the result into the array upon Promise completion
        const operationPromise = asyncOperation(arg);
        // recursion call
        return chainNext(operationPromise);
      });
    }
    return p;
  }

  return Promise.all(promises.map(chainNext));
};

export const setFileField = (form, field) => (fileData?: TFileDataType) => {
  form.setFieldsValue({
    [field]: fileData ? fileData : undefined,
  });
};

export const onSelectFromDropdown =
  (
    form: ?Object,
    field: string | Array<string>,
    allowEmpty?: boolean = false
  ) =>
  (val) => {
    form = form && form.current ? form.current : form;
    if (form && form.setFieldsValue) {
      const value = val && (val.value || allowEmpty) ? val.value : undefined;
      if (typeof field === 'string') {
        form.setFieldsValue({
          [field]: value,
        });
      } else if (Array.isArray(field)) {
        form.setFieldsValue(set({}, field.join('.'), value));
      }
    }
  };

export const convertRGBToHex = (colour: string) => {
  const rgb = colour
    .replace(/\s/g, '')
    .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  let hex = '';
  if (rgb && rgb.length >= 4) {
    hex =
      '#' +
      (rgb[1] | (1 << 8)).toString(16).slice(1) +
      (rgb[2] | (1 << 8)).toString(16).slice(1) +
      (rgb[3] | (1 << 8)).toString(16).slice(1);
  }
  return hex.toUpperCase();
};

export const onColorPickerChange =
  (form: Object, field: string) =>
  ({ rgb }: Object): void => {
    const data = {};
    set(data, field, parseRGBA(rgb));
    form.setFieldsValue(data);
  };

export const toRGBAObject = (rgba: string): Object => {
  const numbers = rgba.match(/(0\.\d+)|(\d+)/g);
  return {
    r: Number(get(numbers, '[0]', 0)),
    g: Number(get(numbers, '[1]', 0)),
    b: Number(get(numbers, '[2]', 0)),
    a: Number(get(numbers, '[3]', 1)),
  };
};

export const parseRGBA = (rgba: Object): string => {
  return 'rgba(' + rgba.r + ', ' + rgba.g + ', ' + rgba.b + ', ' + rgba.a + ')';
};

export const getInfoBlock = (
  config: {
    infoRows: Array<TInfoBlockItem>,
    idPropName?: string,
    header: string,
  },
  notContainer?: boolean,
  data?: any
) => {
  const Container = notContainer ? LastInfoBlockContainer : InfoBlockContainer;

  return (
    <Container>
      <InfoBlock data={data} {...config} />
    </Container>
  );
};

export const handleApiError = (
  error: Object,
  defaultMessage: string,
  options?: THandleAPIErrorOptions = {}
) => {
  const status = get(error, 'response.status');
  const {
    showNativeErrors,
    hide404Error,
    custom404Handler,
    messageSubData,
    messageDuration,
  } = options;
  const duration = messageDuration ? messageDuration : 5;
  const subData = messageSubData ? messageSubData : {};
  const handler40X = () => {
    const nativeErrors = get(error, 'response.data.errors', []).map(
      (error) => error.message
    );
    if (showNativeErrors) {
      nativeErrors.forEach((message: string) => {
        commonActions.showApiError(message, duration, subData);
      });
    } else {
      commonActions.showApiError(defaultMessage, duration, subData);
    }
    console.error(nativeErrors);
  };
  switch (status) {
    case 400: {
      handler40X();
      break;
    }
    case 404: {
      if (!hide404Error) {
        handler40X();
      }
      if (custom404Handler) {
        custom404Handler();
      }
      break;
    }
    case 500:
    default: {
      commonActions.showApiError(defaultMessage, duration, subData);
      console.error(error);
    }
  }
};

export const showMessage = (
  content: string,
  type: string = 'warning',
  duration: number = 5
): void => {
  if (typeof message[type] === 'function') {
    message[type](content, duration ? duration : 5);
  }
};

export const wordToBool = (data: string) =>
  ['yes', 'true'].includes(data.toLowerCase());
export const boolToWord = (data: boolean) =>
  data === true ? 'yes' : data === false ? 'no' : '-';
export const boolToWordReverse = (data: boolean) =>
  data === true ? 'no' : data === false ? 'yes' : '-';
export const wordOrDash = (data: string) => (data ? data : '-');

export const getImageNameAndFormatFromUrl = (
  url: string
): { name: ?string, format: string } => {
  let format = 'image/jpeg';
  const name = url.split('/').slice(-1)[0];
  if (name) {
    const splitName = name.split('.').slice(-1);
    if (splitName && splitName.length > 0 && splitName[0] === 'png') {
      format = 'image/png';
    }
  }
  return { name, format };
};

export const getBarcodeImageUrl = (barcodeData: string): string =>
  validateUrl(barcodeData)
    ? barcodeData
    : `data:image/png;base64,${barcodeData}`;

export const convertFileDataToBase64URL = (data: TFileDataType) =>
  `data:${data.contentType};base64,${data.data}`;

export const getFileData = (file?: Object): Promise<?TFileDataType> => {
  return new Promise((resolve) => {
    if (file) {
      const reader = new FileReader();
      let size = 0;
      reader.onloadend = () => {
        const result = reader.result;
        // Result is data url, e.g: data:image/png;base64,xxxxxxx
        const dataStart = result.indexOf(',');
        const contentType = result
          .slice(0, dataStart)
          .split(';')[0]
          .split(':')[1];
        resolve({ data: result.slice(dataStart + 1), contentType, size });
      };
      size = file.size;
      reader.readAsDataURL(file);
    } else {
      resolve(null);
    }
  });
};

export const convertImgToBase64URL = (
  url: string,
  outputFormat: string,
  { callback, errorCallback }: Object
) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    let canvas = document.createElement('CANVAS');
    const ctx = canvas.getContext('2d');
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL(
      outputFormat || getImageNameAndFormatFromUrl(url).format
    );
    callback(dataURL);
    canvas = null;
  };
  img.onerror = errorCallback;
  img.src = url;
};

export const getPromoImageLibraryUrl = (url) =>
  `${env('ASSET_MANAGEMENT_S3')}${url}`;

export const convertImgToFileData = (
  url: string,
  { callback, errorCallback }: Object
) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    let canvas = document.createElement('CANVAS');
    const ctx = canvas.getContext('2d');
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    const { format } = getImageNameAndFormatFromUrl(url);
    const dataURL = canvas.toDataURL(format);
    let result = null;
    if (dataURL) {
      const base64 = dataURL.split(',')[1];
      const y = base64.endsWith('==') ? 2 : 1;
      result = {
        contentType: format,
        data: base64,
        size: (base64.length * 3.0) / 4 - y,
      };
    }
    callback(result);
    canvas = null;
  };
  img.onerror = errorCallback;
  img.src = `${url}?t=${Date.now()}`;
};

export const getImageDimensions = (
  fileData: ?TFileDataType
): Promise<TImageDimension | boolean> =>
  new Promise((resolve, _) => {
    if (fileData) {
      const img = new Image();

      img.onload = () => resolve({ height: img.height, width: img.width });

      img.src = `data:${fileData.contentType};base64,${fileData.data}`;
    } else {
      resolve(false);
    }
  });

export const validateImageDimensions = (
  { width, height }: TImageDimension,
  { maxWidth, maxHeight, minHeight, minWidth }: TImageDimensionRestriction
): boolean => {
  return (
    (!minHeight || height >= minHeight) &&
    (!minWidth || width >= minWidth) &&
    (!maxHeight || height <= maxHeight) &&
    (!maxWidth || width < maxWidth)
  );
};

export const concatUrlParams = (params: Array<string>) => {
  let url = '';

  params.forEach((cur, i) => {
    url += `${params[i]}&`;
  });
  url = url.slice(0, -1);

  return url;
};

export const updateSinglePrinter = (
  arr: Array<TPrinter>,
  i: number,
  propName: string,
  value: string
) => {
  arr[i][propName] = value;
  return arr;
};

export const formatOpeningHours = (
  timeStart: Object | string,
  timeEnd: Object | string,
  fullDay: boolean = false
) => {
  const timeStartMoment = moment(
    timeStart || AVAILABILITY_CONFIG.DEFAULT_DISPLAY_VALUE,
    AVAILABILITY_CONFIG.DISPLAY_FORMAT
  );
  const timeEndMoment = moment(
    timeEnd || AVAILABILITY_CONFIG.DEFAULT_DISPLAY_VALUE,
    AVAILABILITY_CONFIG.DISPLAY_FORMAT
  );
  const closingNextDay = timeEndMoment.isSameOrBefore(timeStartMoment);

  const timeStartFormatted =
    !fullDay && timeStart
      ? timeStartMoment.format(AVAILABILITY_CONFIG.SAVE_FORMAT)
      : AVAILABILITY_CONFIG.DEFAULT_SAVE_START_VALUE;

  let timeEndFormatted =
    !fullDay && timeEnd
      ? timeEndMoment.format(AVAILABILITY_CONFIG.SAVE_FORMAT)
      : AVAILABILITY_CONFIG.DEFAULT_SAVE_END_VALUE;

  if (closingNextDay) {
    timeEndFormatted = (
      parseInt(timeEndFormatted, 10) + AVAILABILITY_CONFIG.FULL_DAY_HOURS
    ).toString();
  } else if (
    timeEndFormatted === AVAILABILITY_CONFIG.DEFAULT_SAVE_START_VALUE
  ) {
    timeEndFormatted = AVAILABILITY_CONFIG.DEFAULT_SAVE_END_VALUE;
  }

  return {
    timeStartMoment,
    timeEndMoment,
    timeStartFormatted,
    timeEndFormatted,
  };
};

export const computeOpeningHours = (
  value: {
    hours?: any,
  },
  t?: TTranslateFunc
): string | { startTime: string, endTime: string } => {
  if (!value || !value.hours) return t ? t('common:closed') : 'Closed';
  const timeStart = value.hours[0][0];
  const timeEnd = value.hours[0][1];
  let hoursEnd = parseInt(timeEnd.slice(0, 2), 10);
  if (hoursEnd > 24) {
    hoursEnd = hoursEnd - 24;
  }
  hoursEnd = hoursEnd.toString(10).padStart(2, '0');

  return {
    startTime: `${timeStart.slice(0, 2)}:${timeStart.slice(2, 4)}`,
    endTime: `${hoursEnd}:${timeEnd.slice(2, 4)}`,
  };
};

export const bindTrailingArgs =
  (fn, ...boundArgs) =>
  (...args) =>
    fn(...args, ...boundArgs);

export const isUKRegion = false; //process.env.AWS_REGION === 'eu-west-1';
// TODO: change logic for different location

/**
 * Since IE11 should be supported - can't use clipboard API.
 * @param text
 */
const copyTextToClipboardLegacy = (text: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.parentNode.removeChild(textField);
};

/**
 * Copies text to clipboard.
 */
export const copyPublicLink = async (text: string) => {
  if (!navigator.clipboard) {
    copyTextToClipboardLegacy(text);
    return Promise.resolve();
  } else {
    try {
      await navigator.clipboard.writeText(text);
      return Promise.resolve();
    } catch (e) {
      copyTextToClipboardLegacy(text);
      return Promise.resolve();
    }
  }
};

export const formatPromotionDates = (dates: ?Array<Object>) => {
  const result = { startDate: '', endDate: '' };
  if (dates && dates.length > 1) {
    const [start, end] = dates;
    if (moment.isMoment(start) && moment.isMoment(end)) {
      result.startDate = start.startOf('day').toISOString();
      result.endDate = end.startOf('day').toISOString();
    }
  }
  return result;
};

/**
 * Checks if HTTP request is failed.
 */
export const checkIsRequestFailed = (result) =>
  result instanceof Error || result.isAxiosError;
