// @flow

import React from 'react';
import {
  ColumnsHolder,
  LeftColumn,
} from '_common/components/DetailsPage/elements';
import { uiConfigBlock } from './config';
import { getInfoBlock } from '_common/utils/utils';
import DoddleStoresStore from '_common/stores/doddleStoresStore';

const DoddleStoreDetailsConfig = ({ data }: { data: DoddleStoresStore }) => {
  return (
    <ColumnsHolder>
      <LeftColumn>{getInfoBlock(uiConfigBlock(), true, data)}</LeftColumn>
    </ColumnsHolder>
  );
};

export default DoddleStoreDetailsConfig;
