// @flow

import stores from 'stores';

export default (
  collection: Array<Object>,
  isFiltersEmpty: boolean,
  historyKey: string
) => {
  return stores.parcelsStore.setFilteredCollection(
    collection,
    isFiltersEmpty,
    historyKey
  );
};
