import styled from 'styled-components';
import { Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const EditIcon = styled(EditOutlined)`
  vertical-align: center;
  & :hover {
    cursor: pointer;
  }
`;

export const UpdateButton = styled.button`
  width: 64px;
  height: 34px;
  border: none;
  background-color: #542785;
  font-family: ${props => props.theme.fonts.basic};
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

export const EditInput = styled(Input)`
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e6e7e8;
  height: 36px;
`;
