// @flow

import React from 'react';
import { Column, ColumnsHolder, LeftColumn } from './elements';
import {
  parcelReciepientInfoBlock,
  parcelDetailsInfoBlock,
  parcelStatusInfoBlock,
  parcelLogisticsInfoBlock,
} from './config';
import ParcelsStore from 'parcels/stores/parcelsStore';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { getInfoBlock } from '_common/utils/utils';
import { get } from 'lodash';
import stores from 'stores';

type Props = {
  parcelsStore: ParcelsStore,
};

type State = {
  storeName: string,
};

class ParcelReturn extends React.Component<Props, State> {

  state = {
    storeName: '',
  };

  componentDidMount() {
    const storeId = get(
      this.props,
      'parcelsStore.singleCollection.location.storeId'
    );
    if (storeId) {
      stores.doddleStores.getSingleStoreById(storeId).then(store => {
        this.setState({
          storeName: store.storeName,
        });
      });
    }
  }

  render() {
    const { parcelsStore } = this.props;
    const { storeName } = this.state;

    return (
      <ColumnsHolder>
        <LeftColumn>
          {getInfoBlock(parcelReciepientInfoBlock, false, parcelsStore)}
          {getInfoBlock(parcelDetailsInfoBlock, false, parcelsStore)}
        </LeftColumn>
        <Column>
          {getInfoBlock(parcelStatusInfoBlock(storeName), false, parcelsStore)}
          {getInfoBlock(parcelLogisticsInfoBlock, false, parcelsStore)}
        </Column>
      </ColumnsHolder>
    );
  }

}

export default compose(inject('parcelsStore'), observer)(ParcelReturn);
