import styled from 'styled-components';
import { Input, Button } from '_common/components';
import { Input as AntdInput } from 'antd';

export const LoginTitle = styled.h2`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 20px;
  color: ${props => props.theme.colors.shade2};
  margin-bottom: 50px;
`;

export const LoginInput = styled(Input)`
  && {
    &.ant-input-disabled {
      color: rgba(0, 0, 0, 0.4);
    }
    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-family: ${props => props.theme.fonts.light};
      color: ${props => props.theme.colors.shade2};
    }
  }
`;

export const LoginInputLabel = styled.label`
  font-size: 13px;
  font-family: ${props => props.theme.fonts.basic};
  color: ${props => props.theme.colors.shade2};
  text-transform: uppercase;
`;

export const LoginInputWrapper = styled.div``;

export const LoginButton = styled(Button)`
  && {
    width: 140px;
    height: 46px;
    margin-top: 25px;
  }
`;

export const LoginFormWrapper = styled.div`
  padding: 65px 50px;
  margin: 0 auto;
  background-color: #ffffff;
  border: solid 1px #e6e7e8;
  .ant-form-explain {
    font-size: 12px;
  }
  @media only screen and (max-width: 576px) {
    padding: 25px 35px;
  }
`;

export const LogoWrapper = styled.div`
  text-align: center;
  padding-bottom: 65px;
  margin-top: 15%;
  @media only screen and (max-width: 576px) {
    margin-top: 15%;
    padding-bottom: 35px;
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    margin-top: 5%;
    padding-bottom: 30px;
  }
`;

export const SwitchCompany = styled.div`
  cursor: pointer;
  color: #7b7b7b;
  display: inline-block;
`;

export const NewPasswordInput = styled(AntdInput.Password)`
  & {
    input {
      border: solid 1px #e6e7e8;
      border-radius: 2px;
      font-family: ${props => props.theme.fonts.book};
      font-size: 14px;
      color: #131314;
      width: 100%;
      min-height: 40px !important;
      &::placeholder {
        font-size: 14px;
        font-style: normal;
        font-family: ${props => props.theme.fonts.light};
        color: ${props => props.theme.colors.shade2};
      }
    }
  }
`;
