// @flow strict

import links from '_common/routes/urls';
import PromotionsList from 'promotions/pages/promotionsList';
import PromotionCreate from 'promotions/pages/promotionCreate';
import PromotionDetails from 'promotions/pages/promotionDetails';

export default [
  {
    path: links.promotions.main,
    component: PromotionsList,
  },
  {
    path: links.promotions.createPromotion,
    component: PromotionCreate,
  },
  {
    path: links.promotions.singlePromotion,
    component: PromotionDetails,
  },
];
