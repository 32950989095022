import React, { Component, Fragment } from 'react';
import { Dialog, InputLabel, Select } from '_common/components';
import { Col, Row, Typography } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { ACLStore, DoddleStores, PromotionsStore } from 'stores';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { get } from 'lodash';
import { cleanObject } from '_common/utils/utils';
import SelectAsync from '_common/components/SelectAsync';
import StoresTable from './StoresTable';
import { CheckboxBig } from '_common/components/PlainStyles';
import Button from '_common/components/Button/Button';
import {
  ButtonNext,
  SeparationLine,
} from '_common/components/TabsForm/elements';
import StoresConflictDialog from '../StoresConflictDialog';
import stores from '../../../stores';

type Props = {
  doddleStores: DoddleStores,
  aclStore: ACLStore,
  promotionsStore: PromotionsStore,
  isEditMode?: boolean,
  form: Form,
  onNextTab: (string) => void,
  t: TTranslateFunc,
};

type State = {
  allStoresAssigned: boolean,
  resetSelect: boolean,
  country?: string,
  countrySubdivision: string,
  storeNameQuery: string,
  isLoading: true,
  selectedCriteria: Array<TStoresFilterParams>,
  isStoresConflictDialogShown: boolean,
  isAssignAllStoresConfirmDialogShown: boolean,
  conflictStore: TStore,
};

/**
 * Stores tab for both create and edit promotion page.
 * goToNextTab and getFormattedData methods are used only during creation
 */
class Stores extends Component<Props, State> {

  COMPANY_COUNTRY_MAPPING = {
    [MAIN_COMPANIES.AUSTRALIA_POST_ID]: 'AU',
    [MAIN_COMPANIES.YAMATO_ID]: 'JP',
  };

  static defaultProps = {
    isEditMode: false,
  };

  state = {
    allStoresAssigned: false,
    country: get(
      this.COMPANY_COUNTRY_MAPPING,
      stores.aclStore.selectedCompanyId
    ),
    countrySubdivision: '',
    storeNameQuery: '',
    resetSelect: false,
    isLoading: false,
    selectedCriteria: [],
    isStoresConflictDialogShown: false,
    isAssignAllStoresConfirmDialogShown: false,
    conflictStore: {},
  };

  componentDidMount(): void {
    this.loadStoreOptions();
    this.props.doddleStores.loadCountriesConfig();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { aclStore, doddleStores } = this.props;
    const { countrySubdivision, country, allStoresAssigned } = this.state;
    const companyId = aclStore.getSelectedCompanyId;
    const companyChanged =
      doddleStores.storeOptionsConfig.lastLoadedFromCompany !== companyId;
    const filtersChanged =
      prevState.countrySubdivision !== countrySubdivision ||
      prevState.country !== country;
    if (!allStoresAssigned && (filtersChanged || companyChanged)) {
      this.loadStoreOptions();
    }
  }

  componentWillUnmount(): void {
    this.props.doddleStores.setStoreOptionsConfig({
      lastQuery: '',
      lastLoadedFromCompany: '',
      totalRecords: 0,
    });
  }

  goToNextTab = (e?) => {
    if (e) {
      e.preventDefault();
    }

    const { onNextTab } = this.props;

    onNextTab('marketingMessage');
  };

  getFormattedData = () => {
    const { allStoresAssigned } = this.state;

    const data = {
      allStoresAssigned,
    };

    cleanObject(data, ['']);

    return data;
  };

  toggleIsLoading = () => {
    this.setState((state) => ({
      isLoading: !state.isLoading,
    }));
  };

  changeAllStoresAssigned = async ({ target: { checked } }: Object) => {
    this.setState(
      (state) => ({
        allStoresAssigned: checked,
        countrySubdivision: '',
        resetSelect: !state.resetSelect,
      }),
      () => {
        if (checked) {
          this.getLoadOptionsCallback()('');
        }
      }
    );
  };

  getSaveSettings = (): TPromotionStoreRelationOptions => {
    return {
      saveInternal: !this.props.isEditMode,
      saveExternal: this.props.isEditMode,
    };
  };

  updateCallback = () => {
    this.reloadRelationsCurrentPage();
    this.toggleIsLoading();
  };

  assignAllStores = async () => {
    this.toggleIsLoading();
    this.toggleAssignAllStoresConfirmDialogShown();
    const {
      promotionsStore: { assignAllStores, updateSinglePromotion },
    } = this.props;
    updateSinglePromotion(assignAllStores, [], this.updateCallback);
  };

  addStoresBySelectedCriteria = async () => {
    const {
      promotionsStore: {
        addPromotionStoreRelationsByFilters,
        updateSinglePromotion,
      },
      aclStore,
    } = this.props;
    const { countrySubdivision, country, storeNameQuery } = this.state;

    this.toggleIsLoading();

    const filters = {
      host: aclStore.getSelectedCompanyId,
      storeName: storeNameQuery,
      countrySubdivision,
      country,
    };
    updateSinglePromotion(
      addPromotionStoreRelationsByFilters,
      [filters, this.getSaveSettings()],
      this.updateCallback
    );
  };

  addStore = async (storeId: string): void => {
    this.toggleIsLoading();
    const {
      doddleStores,
      promotionsStore: { updateSinglePromotion, addPromotionStoreRelations },
    } = this.props;
    const store = doddleStores.getRawStoreOption(storeId);
    if (store && store.storeId) {
      updateSinglePromotion(
        addPromotionStoreRelations,
        [[store], this.getSaveSettings()],
        this.updateCallback
      );
    } else {
      this.toggleIsLoading();
    }
  };

  removeRelation = async (store: TStore | TPromotionStoreRelation): void => {
    this.toggleIsLoading();
    const {
      promotionsStore: { updateSinglePromotion, removeSingleRelation },
    } = this.props;
    if (store) {
      updateSinglePromotion(
        removeSingleRelation,
        [store, this.getSaveSettings()],
        this.updateCallback
      );
    } else {
      this.toggleIsLoading();
    }
  };

  reloadRelationsCurrentPage = () => {
    const { promotionsStore, isEditMode } = this.props;
    if (isEditMode) {
      promotionsStore.reloadSinglePromotionStoreRelations();
    }
  };

  changeCriteria = (name: string) => (option: ?TOption) => {
    this.setState({
      [name]: option ? option.value : '',
    });
  };

  changeStoreName = (storeNameQuery: string) => {
    this.setState({
      storeNameQuery,
    });
  };

  loadStoreOptions = () => {
    const { aclStore } = this.props;
    if (this.state.isLoading || !aclStore.getSelectedCompanyId) return;
    this.setState({
      isLoading: true,
    });
    const loadOptionsCallback = this.getLoadOptionsCallback();
    if (loadOptionsCallback) {
      loadOptionsCallback(
        this.props.doddleStores.storeOptionsConfig.lastQuery,
        true
      ).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  getLoadOptionsCallback = () => {
    const { doddleStores, aclStore } = this.props;
    const { countrySubdivision, country } = this.state;
    const filters = {
      host: aclStore.getSelectedCompanyId,
      country,
      countrySubdivision,
    };
    cleanObject(filters, [undefined, '']);
    return doddleStores.getStoreOptionsByFiltersCallback(filters, true, true);
  };

  toggleStoresConflictDialogShown = () => {
    this.setState((state) => ({
      isStoresConflictDialogShown: !state.isStoresConflictDialogShown,
    }));
  };

  toggleAssignAllStoresConfirmDialogShown = () => {
    this.setState((state) => ({
      isAssignAllStoresConfirmDialogShown:
        !state.isAssignAllStoresConfirmDialogShown,
    }));
  };

  renderAllStoresAssignControl = () => {
    const { allStoresAssigned, isLoading } = this.state;
    const { isEditMode, t } = this.props;

    let controlElem = (
      <CheckboxBig
        onChange={this.changeAllStoresAssigned}
        checked={allStoresAssigned}
      >
        {t('assignAllStores')}
      </CheckboxBig>
    );

    if (isEditMode) {
      controlElem = (
        <Button
          onClick={this.toggleAssignAllStoresConfirmDialogShown}
          disabled={isLoading}
        >
          {t('assignAllStores')}
        </Button>
      );
    }
    return controlElem;
  };

  render() {
    const {
      doddleStores,
      isEditMode,
      promotionsStore: {
        getSinglePromotionStoreRelationsPaginationConfigField,
        isLoading: promotionsStoreIsLoading,
      },
      aclStore: { selectedCompanyId },
      t,
    } = this.props;
    const {
      resetSelect,
      isLoading,
      country,
      countrySubdivision,
      allStoresAssigned,
      isStoresConflictDialogShown,
      isAssignAllStoresConfirmDialogShown,
    } = this.state;
    const { lastQuery, totalRecords } = doddleStores.storeOptionsConfig;

    const showCountLoadedStores =
      !allStoresAssigned && (lastQuery || country || countrySubdivision);

    return (
      <div>
        <Dialog
          isShown={isAssignAllStoresConfirmDialogShown}
          justifyHeaderContent="center"
          confirmLabel={t('common:confirm')}
          displayCancelButton
          handleConfirmClick={this.assignAllStores}
          headerFontSize={19}
          headerText={t('assignAllStoresNotice', {
            selectedCompany: selectedCompanyId,
          })}
          handleCancelClick={this.toggleAssignAllStoresConfirmDialogShown}
        />
        <StoresConflictDialog
          dialogProps={{
            isShown: isStoresConflictDialogShown,
            confirmLabel: t('common:ok'),
            displayCancelButton: false,
            headerText: t('storesConflictHeader'),
            handleConfirmClick: this.toggleStoresConflictDialogShown,
          }}
        />
        <Row gutter={[16, 48]}>
          <Col span={24}>{this.renderAllStoresAssignControl()}</Col>
        </Row>
        <Row gutter={[16, 48]}>
          <Col span={14}>
            <Row gutter={[16, 48]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <InputLabel>{t('selectCountry')}</InputLabel>
                    <Select
                      initialValue={country}
                      onSelectOption={this.changeCriteria('country')}
                      inputPlaceholder={t('selectCountry')}
                      selectPlaceholder={t('enterCountry')}
                      options={doddleStores.getCountriesOptions}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <InputLabel>{t('selectCountrySub')}</InputLabel>
                    <Select
                      isDisabled={isLoading || !country || allStoresAssigned}
                      resetSelect={resetSelect}
                      onSelectOption={this.changeCriteria('countrySubdivision')}
                      inputPlaceholder={t('selectCountrySub')}
                      selectPlaceholder={t('enterCountrySub')}
                      options={
                        doddleStores.commonSubCountries[country]?.subcountries
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <InputLabel>{t('selectStore')}</InputLabel>
                    <SelectAsync
                      isLoading={isLoading}
                      isDisabled={isLoading || allStoresAssigned}
                      options={doddleStores.getStoreOptionsField}
                      placeholder={t('enterStoreNameOrId')}
                      onChange={this.addStore}
                      loadOptions={this.getLoadOptionsCallback()}
                      resetSelect={resetSelect}
                      onSearchQueryChange={this.changeStoreName}
                    />
                  </Col>
                </Row>
                {showCountLoadedStores && (
                  <Row gutter={[16, 64]}>
                    <Col span={24}>
                      <Button
                        disabled={isLoading}
                        onClick={this.addStoresBySelectedCriteria}
                      >
                        {t('addByCriteria')}
                      </Button>
                      <Typography.Text>
                        &nbsp;&nbsp;
                        {t('storesLoadedByCriteria', { count: totalRecords })}
                      </Typography.Text>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {!allStoresAssigned && (
          <Row gutter={[16, 48]}>
            <Col span={24}>
              <StoresTable
                isLoading={promotionsStoreIsLoading}
                serverPagination={
                  isEditMode
                    ? getSinglePromotionStoreRelationsPaginationConfigField
                    : false
                }
                removeRelation={this.removeRelation}
              />
            </Col>
          </Row>
        )}
        {!isEditMode && (
          <Fragment>
            <SeparationLine />
            <ButtonNext
              disabled={isLoading}
              htmlType="submit"
              onClick={this.goToNextTab}
            >
              {t('common:next')}
            </ButtonNext>
          </Fragment>
        )}
      </div>
    );
  }

}

export default compose(
  inject('doddleStores', 'aclStore', 'promotionsStore'),
  Form.create(),
  observer
)(Stores);
