// @flow

import React from 'react';
import TabsForm from '_common/components/TabsForm';
import CompaniesCollections from '../Collections';
import CompaniesSetup from '../Setup';
import CompaniesConfig from '../Config';
import { isEmpty } from 'lodash';
import { inject } from 'mobx-react';
import { compose } from 'recompose';
import { ACLStore, CompaniesStore } from 'stores';
import type { RouterHistory } from 'react-router';
import { hasOwnKeys } from '_common/utils/utils';
import { withTranslation } from 'react-i18next';

type Props = {
  companiesStore: CompaniesStore,
  aclStore: ACLStore,
  history: RouterHistory,
  t: TTranslateFunc,
};

const CompanyCreate = ({ companiesStore, aclStore, history, t }: Props) => {
  const TABS_CONFIG = [
    {
      caption: t('setup'),
      url: 'setup',
      component: CompaniesSetup,
    },
    {
      caption: t('collections'),
      url: 'collections',
      component: CompaniesCollections,
    },
    {
      caption: t('config'),
      url: 'config',
      component: CompaniesConfig,
      props: {
        t,
      },
    },
  ];

  const createCompany = async formData => {
    const expectedKeys = TABS_CONFIG.map((config: TTabConfig) => config.url);
    const allKeysPresent = hasOwnKeys(formData, expectedKeys);
    if (!isEmpty(formData) && allKeysPresent) {
      try {
        await companiesStore.createCompany(formData);
        history.push(aclStore.getDefaultRedirectUrl());
      } catch (e) {
        throw new Error('Could not create company');
      }
    } else {
      console.error('Could not create company. Missed tabs data');
    }
  };

  return (
    <TabsForm
      title={t('createCompany')}
      activeTab="setup"
      disabledTabs={['collections', 'config']}
      tabsConfig={TABS_CONFIG}
      onSubmit={createCompany}
    />
  );
};

export default compose(
  withTranslation('company'),
  inject('companiesStore', 'aclStore')
)(CompanyCreate);
