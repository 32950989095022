// @flow

import { loqateClient } from '_common/api/clients/clients';
import { get } from 'lodash';

const findAddresses = async (
  config: TFindAddressConfig
): Promise<Array<TLoqateFindResult>> => {
  const { postcode = '', address = '', country, container, depth } = config;
  const params: TLoqateFindParams = {
    Text: `${address} ${postcode}`.trim(),
    Limit: 50,
    Countries: country ? country : '',
    Container: container ? container : '',
  };
  let results = [];
  try {
    const response = await loqateClient.get(
      '/Capture/Interactive/Find/v1.10/json3.ws',
      { params }
    );
    const items = get(response.data, 'Items', []);
    if (get(items, '[0].Error') !== undefined) {
      return results;
    }
    const { containers, addresses } = items.reduce(
      (accumulator, item) => {
        const tempAccum = { ...accumulator };
        const { Id, Type } = item;
        if (Type === 'Address') {
          tempAccum.addresses = [...tempAccum.addresses, item];
        } else {
          tempAccum.containers = [...tempAccum.containers, Id];
        }
        return tempAccum;
      },
      { containers: [], addresses: [] }
    );
    results = addresses;
    if (
      addresses.length < params.Limit &&
      containers.length > 0 &&
      containers[0] !== params.Container &&
      (depth === undefined || depth <= 3)
    ) {
      const additionalAddresses = await findAddresses({
        ...config,
        container: containers[0],
        depth: depth ? depth + 1 : 1,
      });
      results = [...results, ...additionalAddresses];
    }
  } catch (e) {
    console.error(e);
  }
  return results;
};

const retrieveAddress = async (Id: string): Promise<TLoqateAddress> => {
  const response = await loqateClient.get(
    '/Capture/Interactive/Retrieve/v1.00/json3.ws',
    {
      params: { Id },
    }
  );
  const addresses = get(response, 'data.Items', []);
  const engAddress = addresses.find(
    (address: TLoqateAddress) => address.Language === 'ENG'
  );
  return engAddress || get(addresses, '[0]', {});
};

const retrieveGeo = async (
  Country: string,
  Location: string
): Promise<TLoqateGeocode> => {
  const response = await loqateClient.get(
    '/Geocoding/International/Geocode/v1.10/json3.ws',
    {
      params: { Country, Location },
    }
  );
  return get(response, 'data.Items[0]', {});
};

export default { findAddresses, retrieveAddress, retrieveGeo };
