// @flow
import env from '@beam-australia/react-env';

export const HUMAN_READABLE_BUILD_NAME =
  env('HUMAN_READABLE_BUILD_NAME') || 'Unknown';

export const ADMIN_TOOL_SERVICE_URL = '/v1/admin-tool-service';
// export const ASSET_MANAGEMENT_S3 = env('ASSET_MANAGEMENT_S3');

export const MESSAGE_DISPLAY_TIME = {
  WARNING: 5,
  SUCCESS: 5,
  API_ERROR: 5,
};

export const MESSAGE_TYPE = {
  WARNING: 'warning',
  SUCCESS: 'success',
  API_ERROR: 'warning',
};

export const STORE_ACTIVATION_DATE_FORMAT = 'YYYY-MM-DD';

export const COMPANY_SCOPE_TEMPLATE = 'organisation_';

export const STORE_STAFF_SCOPE_TEMPLATE = 'storestaff_';

export const MAIN_COMPANIES = {
  DODDLE_ID: 'DODDLE',
  AUSTRALIA_POST_ID: 'AUSTRALIA_POST',
  YAMATO_ID: 'YAMATO',
  AMAZON_ID: 'AMAZON',
  STARLINKS_ID: 'STARLINKS',
  USPS_ID: 'USPS',
};

export const QR_CODE_INSTRUCTIONS = {
  STOREAPP_LOGIN: 'STOREAPP_LOGIN',
};

export const FLAG_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const FLAG_REVERSE_OPTIONS = [
  { value: false, label: 'Yes' },
  { value: true, label: 'No' },
];
