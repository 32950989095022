import React, { Component } from 'react';
import QRCode from 'qrcode';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Location, RouterHistory } from 'react-router';

type Props = {
  location: Location,
  t: TTranslateFunc,
  history: RouterHistory,
};

type State = {
  qrCodeUrl: string,
  errorMessage: string,
};

class StoreQRCodeImage extends Component<Props, State> {

  state = {
    qrCodeUrl: '',
    errorMessage: '',
  };

  componentDidMount(): void {
    const { location, t } = this.props;
    const params = new URLSearchParams(location.search);
    const storeId = params.get('storeId');
    if (storeId) {
      QRCode.toDataURL(storeId)
        .then((qrCodeUrl) => {
          this.setState({
            qrCodeUrl,
          });
        })
        .catch((error) => {
          console.warn(error);
          const errorMessage = 'couldNotGetQRCode';
          this.setState({
            errorMessage: t(errorMessage),
          });
        });
    }
  }

  render() {
    const { errorMessage, qrCodeUrl } = this.state;

    return qrCodeUrl ? (
      <img
        src={qrCodeUrl}
        alt="qr code"
        style={{ margin: '10px', width: '400px' }}
      />
    ) : (
      errorMessage
    );
  }

}

export default compose(
  withTranslation(['notifications', 'stores']),
  withRouter
)(StoreQRCodeImage);
