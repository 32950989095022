// @flow

import React from 'react';
import { StyledBaseInput, StyledInput } from './elements';
type Props = {
  [key: any]: any,
  getRef?: (?Object) => void,
};

export const BaseInput = (props: Props) => {
  return <StyledBaseInput {...props} />;
};

export class Input extends React.Component<Props> {

  inputRef = (ref: ?Object) => {
    if (this.props.getRef) {
      this.props.getRef(ref);
    }
  };

  render() {
    const { getRef, ...rest } = this.props; // excluded getRef prop. DO NOT DELETE!!!

    return <StyledInput ref={this.inputRef} {...rest} />;
  }

}

export default {
  BaseInput,
  Input,
};
