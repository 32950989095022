// @flow
import { boolToWord } from '_common/utils/utils';
import doddleStoresActions from 'doddleStores/actions';
import { Roles } from '_common/constants/acl';
import commonActions from '_common/actions';
import { FLAG_OPTIONS } from '_common/constants/appConfig';
import {
  DISPATCH_FLOW_OPTIONS,
  MOVE_TO_CONTAINER_FLOW_OPTIONS,
  RETURN_LABEL_FLOW_OPTIONS,
} from '_common/constants/stores';
import { get } from 'lodash';

export const uiConfigBlock = () => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
  });

  return {
    infoRows: [
      {
        name: 'Simplified collection flow',
        path:
          'singleStore.storeConfiguration.storeSystemUIConfig.simplifiedCollectionFlow',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                simplifiedCollectionFlow: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        name: 'Return label flow',
        path:
          'singleStore.storeConfiguration.storeSystemUIConfig.returnLabelFlow',
        aclAccess: hasPermissions,
        type: 'select',
        hasEmptyOption: true,
        options: RETURN_LABEL_FLOW_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                returnLabelFlow: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        name: 'Disable prebooked',
        path:
          'singleStore.storeConfiguration.storeSystemUIConfig.returnsDisablePreBooked',
        aclAccess: hasPermissions,
        compute: boolToWord,
        localizeOptions: true,
        localizeValue: true,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                returnsDisablePreBooked: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        name: 'Fixed retailer ID',
        path:
          'singleStore.storeConfiguration.storeSystemUIConfig.returnsFixedRetailerId',
        aclAccess: hasPermissions,
        type: 'input',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                returnsFixedRetailerId: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        name: 'Fixed returns carrier ID',
        path:
          'singleStore.storeConfiguration.storeSystemUIConfig.returnsFixedCarrierId',
        aclAccess: hasPermissions,
        type: 'input',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                returnsFixedCarrierId: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        name: 'Dispatch flow',
        path: 'singleStore.storeConfiguration.storeSystemUIConfig.dispatchFlow',
        aclAccess: hasPermissions,
        type: 'select',
        hasEmptyOption: true,
        options: DISPATCH_FLOW_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                dispatchFlow: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        name: 'Move to container flow',
        path:
          'singleStore.storeConfiguration.storeSystemUIConfig.moveToContainerFlow',
        aclAccess: hasPermissions,
        type: 'select',
        hasEmptyOption: true,
        options: MOVE_TO_CONTAINER_FLOW_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                moveToContainerFlow: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        name: 'Fixed carrier ID',
        path:
          'singleStore.storeConfiguration.storeSystemUIConfig.fixedCarrierId',
        aclAccess: hasPermissions,
        type: 'input',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              storeSystemUIConfig: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.storeSystemUIConfig',
                  {}
                ),
                fixedCarrierId: updateObj.currentValue,
              },
            },
          });
        },
      },
      {
        aclAccess: hasPermissions,
        name: 'Request power whitelisting',
        path: 'singleStore.storeConfiguration.requestPowerWhitelisting',
        compute: boolToWord,
        type: 'select',
        localizeValue: true,
        localizeOptions: true,
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              ...get(updateObj.data, 'singleStore.storeConfiguration', {}),
              requestPowerWhitelisting: updateObj.currentValue,
            },
          });
        },
      },
      {
        name: 'Collection deadline days',
        path: 'singleStore.storeConfiguration.collectionDeadlineDays',
        aclAccess: hasPermissions,
        type: 'input',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              ...get(updateObj.data, 'singleStore.storeConfiguration', {}),
              collectionDeadlineDays: Number(updateObj.currentValue),
            },
          });
        },
      },
      {
        aclAccess: hasPermissions,
        name: 'Network forbids long polling',
        path:
          'singleStore.storeConfiguration.technicalConfiguration.networkForbidsLongPolling',
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            storeConfiguration: {
              technicalConfiguration: {
                ...get(
                  updateObj.data,
                  'singleStore.storeConfiguration.technicalConfiguration',
                  {}
                ),
                networkForbidsLongPolling: updateObj.currentValue,
              },
            },
          });
        },
      },
    ],
    translateNS: 'stores',
    idPropName: 'singleStore.storeId',
    header: 'Config',
  };
};
