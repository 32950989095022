// @flow

import React, { Fragment } from 'react';
import {
  FilterItem,
  FilterLabel,
  Wrapper,
} from '_common/components/GridFilter/elements';
import { getSubCountriesOptions } from '_common/utils/utils';
import { Input, Select } from '_common/components';
import {
  LOCATION_TYPE_OPTIONS,
  STATUS_OPTIONS,
} from '_common/constants/stores';
import { DoddleStores } from 'stores';
import { debounce, pick, toPairs } from 'lodash';
import { withTranslation } from 'react-i18next';
import { localizeOptions } from '_common/utils/utils';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

type Props = {
  doddleStores: DoddleStores,
  hiddenFilters: Array<string>,
  t: TTranslateFunc,
};

type State = {
  storeId: string,
  storeName: string,
  status: ?string,
  country: ?string,
  countrySubdivision: ?string,
  locationTypes: ?string,
  resetFilters: boolean,
  resetCountrySubdivision: boolean,
};

class StoreFilters extends React.Component<Props, State> {

  static defaultProps = {
    hiddenFilters: [],
  };

  initialState = {
    storeId: '',
    storeName: '',
    country: null,
    countrySubdivision: null,
    status: null,
    locationTypes: null,
    resetFilters: true,
    resetCountrySubdivision: true,
  };

  state = {
    ...this.initialState,
  };

  componentDidMount(): void {
    const resultState = {};
    const { doddleStores } = this.props;
    doddleStores.loadCountriesConfig();
    toPairs(
      pick(doddleStores.getFiltersField, [
        'storeName',
        'storeId',
        'status',
        'countrySubdivision',
        'country',
        'locationTypes',
      ])
    ).forEach(([key, value]) => {
      if (!!value) {
        resultState[key] = value;
      }
    });
    this.setState({
      ...this.state,
      ...resultState,
    });
  }

  componentWillUnmount(): void {
    this.props.doddleStores.setResetFiltersFlag(true);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      doddleStores: { resetFiltersFlag },
    } = this.props;

    if (resetFiltersFlag !== prevState.resetFilters) {
      this.setState({
        ...this.initialState,
        resetFilters: resetFiltersFlag,
      });
    }
  }

  onStatusChange = (option: TOption) => {
    const status = option ? option.value : null;
    if (status !== this.state.status) {
      this.setState({
        status,
      });
      this.props.doddleStores.setFilters({ status });
    }
  };

  onLocationTypeChange = (option: TOption) => {
    const locationTypes = option ? option.value : null;
    if (locationTypes !== this.state.locationTypes) {
      this.setState({
        locationTypes,
      });
      this.props.doddleStores.setFilters({ locationTypes });
    }
  };

  onCountryChange = (option: TOption) => {
    const country = option ? option.value : null;
    if (country !== this.state.country) {
      this.setState((state) => ({
        country,
        resetCountrySubdivision: !state.resetCountrySubdivision,
      }));
      this.props.doddleStores.setFilters({ country, countrySubdivision: null });
    }
  };

  onCountrySubdivisionChange = (option: TOption) => {
    const countrySubdivision = option ? option.value : null;
    if (countrySubdivision !== this.state.countrySubdivision) {
      this.setState({
        countrySubdivision,
      });
      this.props.doddleStores.setFilters({ countrySubdivision });
    }
  };

  setFiltersDebounced = debounce(this.props.doddleStores.setFilters, 700);

  onStoreNameChange = ({ target: { value: storeName } }: Object) => {
    this.setState({
      storeName,
    });
    this.setFiltersDebounced({ storeName });
  };

  onStoreIdChange = ({ target: { value: storeId } }: Object) => {
    this.setState({
      storeId,
    });
    this.setFiltersDebounced({ storeId });
  };

  render() {
    const { hiddenFilters, t, doddleStores } = this.props;
    const {
      storeName,
      storeId,
      status,
      country,
      countrySubdivision,
      resetCountrySubdivision,
      locationTypes,
    } = this.state;

    return (
      <Wrapper>
        <FilterItem>
          <FilterLabel>{t('storeId')}</FilterLabel>
          <Input
            value={storeId}
            onChange={this.onStoreIdChange}
            placeholder={t('enterStoreId')}
          />
        </FilterItem>
        <FilterItem>
          <FilterLabel>{t('storeName')}</FilterLabel>
          <Input
            value={storeName}
            onChange={this.onStoreNameChange}
            placeholder={t('enterStoreName')}
          />
        </FilterItem>
        {!hiddenFilters.includes('status') && (
          <FilterItem>
            <FilterLabel>{t('status')}</FilterLabel>
            <Select
              value={status}
              selectPlaceholder={t('selectStatus')}
              onSelectOption={this.onStatusChange}
              options={localizeOptions(STATUS_OPTIONS, t, ['stores'])}
              inputPlaceholder={t('selectStatus')}
            />
          </FilterItem>
        )}
        {!hiddenFilters.includes('countrySubdivision') && (
          <Fragment>
            <FilterItem>
              <FilterLabel>{t('country')}</FilterLabel>
              <Select
                value={country}
                selectPlaceholder={t('selectCountry')}
                onSelectOption={this.onCountryChange}
                options={doddleStores.getCountriesOptions}
                inputPlaceholder={t('selectCountry')}
              />
            </FilterItem>
            <FilterItem>
              <FilterLabel>{t('countrySubdivision')}</FilterLabel>
              <Select
                value={countrySubdivision}
                isDisabled={!country}
                resetSelect={resetCountrySubdivision}
                selectPlaceholder={t('selectCountrySubdivision')}
                onSelectOption={this.onCountrySubdivisionChange}
                options={getSubCountriesOptions(
                  doddleStores,
                  locationTypes,
                  country
                )}
                inputPlaceholder={t('selectCountrySubdivision')}
              />
            </FilterItem>
          </Fragment>
        )}
        {!hiddenFilters.includes('storeType') && (
          <FilterItem>
            <FilterLabel>{t('storeType')}</FilterLabel>
            <Select
              value={locationTypes}
              selectPlaceholder={t('selectStoreType')}
              onSelectOption={this.onLocationTypeChange}
              options={localizeOptions(LOCATION_TYPE_OPTIONS, t, 'stores')}
              inputPlaceholder={t('selectStoreType')}
            />
          </FilterItem>
        )}
      </Wrapper>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  observer
)(StoreFilters);
