// @flow

import { atsClient } from '_common/api/clients/clients';
import { cleanObject } from '_common/utils/utils';

const getParcelsByUser = async (
  firstName: string,
  lastName: string,
  email: string,
  orgId: string
): Promise<Array<TParcelItem>> => {
  const query = { firstName, lastName, email };
  cleanObject(query, [null, '']);
  const params = {
    method: 'items.getItemsByCustomerInfo',
    params: JSON.stringify({ orgId }),
    ...query,
  };

  const response = await atsClient.get('/proxy-api', { params });

  return response.data.resources;
};

const getParcelsByEvent = async ({
  startDate,
  endDate,
  eventType,
  storeId,
  retailerId,
  type,
  organisationId,
}: {
  startDate: string,
  endDate: string,
  eventType: string,
  storeId: string,
  retailerId: string,
  type: string,
  organisationId: string,
}): Promise<Array<TParcelItem>> => {
  let method = 'getCollectionsByEvents';
  if (type === 'returns') {
    method = 'getReturnsByEvents';
  }
  const query = { startDate, endDate, eventType, storeId, retailerId };
  cleanObject(query, [null, '']);
  const params = {
    method: `items.${method}`,
    params: JSON.stringify({ organisationId }),
    ...query,
  };

  const response = await atsClient.get('/proxy-api', { params });

  return response.data.resources;
};

const getParcelsByRetailerOrderId = async (
  retOrderId: string,
  orgId: string
): Promise<Array<TParcelItem>> => {
  const params = {
    method: 'items.getCollectionsByOrgAndRetailerOrderId',
    params: JSON.stringify({ orgId, retOrderId }),
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return response.data.resources;
};

const getParcelsByBarcode = async (
  value: string,
  orgId: string
): Promise<Array<TParcelItem>> => {
  const params = {
    method: 'items.getCollectionsByOrgAndLabelValue',
    params: JSON.stringify({ orgId, value }),
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return response.data.resources;
};

const getParcelsByReferenceId = async (
  referenceId: string,
  orgId: string
): Promise<Array<TParcelItem>> => {
  const params = {
    method: 'items.getCollectionsByOrgAndReferenceId',
    params: JSON.stringify({ orgId, referenceId }),
    allowMultipleResults: true,
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return Array.isArray(response.data) ? response.data : [response.data];
};

const getParcelsByItemId = async (
  itemId: string
): Promise<Array<TParcelItem>> => {
  const params = {
    params: JSON.stringify({ itemId }),
    method: 'items.getCollection',
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return [response.data];
};

const getReturnsByItemId = async (
  itemId: string
): Promise<Array<TParcelItem>> => {
  const params = {
    params: JSON.stringify({ itemId }),
    method: 'items.getReturn',
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return [response.data];
};

const getReturnsByBarcode = async (
  value: string,
  orgId: string
): Promise<Array<TParcelItem>> => {
  const params = {
    method: 'items.getReturnsByOrgAndLabelValue',
    params: JSON.stringify({ orgId, value }),
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return response.data.resources;
};

const getReturnsByReferenceId = async (
  referenceId: string,
  orgId: string
): Promise<Array<TParcelItem>> => {
  const params = {
    method: 'items.getReturnsByOrgAndReferenceId',
    params: JSON.stringify({ orgId, referenceId }),
    allowMultipleResults: true,
  };
  const response = await atsClient.get(`/proxy-api`, { params });

  return Array.isArray(response.data) ? response.data : [response.data];
};

export default {
  getParcelsByUser,
  getParcelsByRetailerOrderId,
  getParcelsByBarcode,
  getParcelsByReferenceId,
  getReturnsByBarcode,
  getReturnsByReferenceId,
  getParcelsByEvent,
  getParcelsByItemId,
  getReturnsByItemId,
};
