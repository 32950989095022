import React, { Component, Fragment } from 'react';
import { Col, Progress, Row } from 'antd';
import { Dialog, Input } from '_common/components';
import {
  validateUrl,
  convertImgToBase64URL,
  getImageNameAndFormatFromUrl,
} from '_common/utils/utils';
import { ImageWrapper } from './elements';

type Props = {
  dialogProps?: TDialogProps,
  t: TTranslateFunc,
  onChange: Function,
};

type State = {
  url: string,
  isProcessing: boolean,
  imageData: ?string,
  imageFormat: ?string,
  headerText: string,
  forceClose: boolean,
};

class UrlDialog extends Component<Props, State> {

  static defaultProps = {
    dialogProps: {},
  };

  initialState = {
    url: '',
    isProcessing: false,
    forceClose: false,
    imageData: null,
    imageFormat: null,
    headerText: 'inputUrl',
  };

  state = { ...this.initialState };

  imageErrorCallback = () => {
    this.setState({
      ...this.initialState,
      headerText: 'errorLoadImage',
    });
  };

  handleConfirm = () => {
    const { url, isProcessing, imageData, imageFormat } = this.state;
    const { onChange } = this.props;

    if (isProcessing) return;

    if (imageData) {
      const base64 = imageData.split(',')[1];
      const y = base64.endsWith('==') ? 2 : 1;
      onChange(getImageNameAndFormatFromUrl(url).name, {
        size: (base64.length * 3.0) / 4 - y,
        contentType: imageFormat,
        data: base64,
      });
      this.setState(
        {
          forceClose: true,
        },
        this.handleCancel
      );
    } else {
      this.toggleIsProcessing();

      if (validateUrl(url)) {
        // get image name
        const { format: imageFormat } = getImageNameAndFormatFromUrl(url);
        convertImgToBase64URL(url, imageFormat, {
          callback: (imageData: string) => {
            this.setState({
              imageData,
              isProcessing: false,
              imageFormat: imageFormat,
              headerText: 'inputUrl',
            });
          },
          errorCallback: this.imageErrorCallback,
        });
      }
    }
  };

  toggleIsProcessing = () => {
    this.setState(state => ({
      isProcessing: !state.isProcessing,
    }));
  };

  changeUrl = ({ target: { value } }: Object): void => {
    this.setState({ url: value });
  };

  renderContent = () => {
    const { isProcessing, url, imageData } = this.state;

    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          {imageData && (
            <Col span={24}>
              <ImageWrapper>
                <img src={imageData} alt="" />
              </ImageWrapper>
            </Col>
          )}
          <Col span={24}>
            <Input
              disabled={!!imageData}
              value={url}
              placeholder={this.props.t('common:inputUrl')}
              onChange={this.changeUrl}
            />
          </Col>
        </Row>
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Fragment>
    );
  };

  handleCancel = () => {
    const { imageData, forceClose } = this.state;
    this.setState({
      ...this.initialState,
    });
    if (!imageData || forceClose) {
      this.props.dialogProps.handleCancelClick();
    }
  };

  render() {
    const { dialogProps, t } = this.props;
    const { url, headerText, imageData } = this.state;

    const isUrlValid = validateUrl(url);

    return (
      <Dialog
        {...dialogProps}
        minHeight={320}
        maxHeight="600px"
        headerText={t(headerText, { ns: ['common', 'notifications'] })}
        confirmLabel={t(imageData ? 'confirm' : 'upload', { ns: 'common' })}
        displayCancelButton
        isConfirmDisabled={!isUrlValid}
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default UrlDialog;
