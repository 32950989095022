import styled from 'styled-components';

export const BlockHeader = styled.p`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 18px;
  line-height: 22px;
  color: #494949;
  margin-bottom: 22px;
`;

export const InfoPart = styled.pre`
  background-color: #f5f5f5;
  padding: 12px 19px 16px;
  margin-bottom: 2px;
  display: block;
`;
