// @flow

import React from 'react';
import { Tabs } from 'antd';
import { TabsWrapper, TabTitle, Panel } from '_common/components';
import { LoadingWrapper, StyledHeader, Text } from './elements';
import { get, isEqual } from 'lodash';
import commonActions from '_common/actions';

type Props = {
  activeTab: string,
  disabledTabs: Array<string>,
  tabsConfig: Array<TTabConfig>,
  title?: string,
  onSubmit?: (formData: Object) => Promise<void>,
  onTabChange?: (key: string) => void,
  PanelElement: Object,
  collectDataSource: string,
  customHeader?: Object,
};

type State = {
  isLoading: boolean,
  activeTab: string,
  disabledTabs: Array<string>,
  tabsConfig: Array<TTabConfig>,
};

export const COLLECT_DATA_SOURCES = {
  TABS_REF: 'TABS_REF',
  TABS_INNER_REF: 'TABS_INNER_REF',
};

class TabsForm extends React.Component<Props, State> {

  static defaultProps = {
    disabledTabs: [],
    PanelElement: Panel,
    collectDataSource: COLLECT_DATA_SOURCES.TABS_INNER_REF,
  };

  _isMounted = false;

  state = {
    isLoading: false,
    activeTab: '',
    disabledTabs: [],
    tabsConfig: [],
  };

  componentDidMount(): void {
    const { activeTab, disabledTabs } = this.props;

    const { finalTabsConfig, tabsToRemove } = this.computeFinalTabsConfig();

    this.setState({
      activeTab,
      disabledTabs: disabledTabs.filter((url) => !tabsToRemove.includes(url)),
      tabsConfig: finalTabsConfig,
    });

    this._isMounted = true;
  }

  componentDidUpdate(prevProps: Props) {
    const { tabsConfig } = this.props;
    const prevTabsConfig = prevProps.tabsConfig;
    const newTitles = tabsConfig.map((config) => config.caption);
    const prevTitles = prevTabsConfig.map((config) => config.caption);
    if (!isEqual(prevTitles, newTitles)) {
      const { finalTabsConfig } = this.computeFinalTabsConfig();
      this.setState({
        tabsConfig: finalTabsConfig,
      });
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  computeFinalTabsConfig = (): Object => {
    const { tabsConfig } = this.props;
    const finalTabsConfig = [];
    const tabsToRemove = [];
    tabsConfig.forEach((config: TTabConfig) => {
      if (config.access !== false) {
        finalTabsConfig.push(config);
      } else {
        tabsToRemove.push(config.url);
      }
    });
    return { finalTabsConfig, tabsToRemove };
  };

  TABS_REF = {};
  TABS_INNER_REF = {};

  setTabRef = (ref: null, tabName: string) => {
    if (!ref) return;

    this.TABS_REF[tabName] = ref;
  };

  setTabInnerRef = (ref: null, tabName: string) => {
    if (!ref) return;

    this.TABS_INNER_REF[tabName] = ref;
  };

  enableTab = (nextTabKey: string) => {
    return (overrideKey?: string) => {
      const key = overrideKey || nextTabKey;
      const { disabledTabs } = this.state;

      this.setState({
        disabledTabs: disabledTabs.filter((el) => el !== key),
        activeTab: key,
      });
    };
  };

  onTabClick = (key: string) => {
    const { onTabChange } = this.props;

    this.setState({
      activeTab: key,
    });

    if (onTabChange) {
      onTabChange(key);
    }
  };

  submit = async () => {
    const { onSubmit, collectDataSource } = this.props;
    let isError = false;
    if (onSubmit) {
      this.setState({
        isLoading: true,
      });
      const formData = {};

      for (const [key, component] of Object.entries(
        get(this, `${collectDataSource}`, {})
      )) {
        const anotherSource =
          collectDataSource === COLLECT_DATA_SOURCES.TABS_REF
            ? COLLECT_DATA_SOURCES.TABS_INNER_REF
            : COLLECT_DATA_SOURCES.TABS_REF;
        let getFormattedData = get(component, 'getFormattedData');
        if (!getFormattedData) {
          getFormattedData = get(
            this,
            `${anotherSource}.${key}.getFormattedData`
          );
        }
        if (getFormattedData) {
          let data = null;
          try {
            data = getFormattedData();
          } catch (e) {
            commonActions.showApiError(e.message);
            isError = true;
          }
          if (data) {
            formData[key] = data;
          }
        }
      }

      try {
        if (!isError) {
          await onSubmit(formData);
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
          });
        }
      }
    }
  };

  renderHeader = () => {
    const { title, customHeader } = this.props;
    const defaultHeader = title ? (
      <StyledHeader>
        <Text>{title}</Text>
      </StyledHeader>
    ) : null;
    return customHeader || defaultHeader;
  };

  render() {
    const { activeTab, disabledTabs, isLoading, tabsConfig } = this.state;
    const { PanelElement } = this.props;

    return (
      <div>
        {this.renderHeader()}
        <PanelElement>
          <TabsWrapper>
            {isLoading && <LoadingWrapper />}
            <Tabs
              activeKey={activeTab}
              animated={false}
              onChange={this.onTabClick}
              items={tabsConfig.map((config: TTabConfig, index) => ({
                key: config.url,
                label: <TabTitle>{config.caption}</TabTitle>,
                disabled: disabledTabs.includes(config.url),
                children: React.createElement(config.component, {
                  onNextTab:
                    index === tabsConfig.length - 1
                      ? this.submit
                      : get(
                          config,
                          'props.onNextTab',
                          this.enableTab(tabsConfig[index + 1]['url'])
                        ),
                  wrappedComponentRef: !config.skipRef
                    ? (ref) => this.setTabInnerRef(ref, config.url)
                    : null,
                  ref: !config.skipRef
                    ? (ref) => this.setTabRef(ref, config.url)
                    : null,
                  ...get(config, 'props', {}),
                }),
              }))}
            />
          </TabsWrapper>
        </PanelElement>
      </div>
    );
  }

}

export default TabsForm;
