// @flow

import React, { Component, createRef } from 'react';
import { Col, Row, Form, Checkbox } from 'antd';
import {
  TabTitle,
  RowWrapper,
  SeparationLine,
  ButtonNext,
  FixedWidthRow,
  ColWrapper,
} from '_common/components/TabsForm/elements';
import { TimePicker } from '_common/components';
import { get, set } from 'lodash';
import { DayLabel, SeparationDateLine } from './elements';
import { ErrorMessage } from '_common/components/InfoBlock/elements';
import {
  INVALID_TIME_RANGE_MESSAGE,
  AVAILABILITY_CONFIG,
} from '_common/constants/stores';
import ExtendedHoursControl from 'doddleStores/components/ExtendedHoursControl';

type Props = {
  onNextTab: string => void,
  t: TTranslateFunc,
};

type State = {
  errors: Object,
  fullDay: Object,
};

class Availability extends Component<Props, State> {

  formRef: Object = createRef();
  _isMounted = false;

  componentDidMount(): void {
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  state = {
    errors: {},
    fullDay: {},
  };

  validateAvailabilityTime = (availability: Object): boolean => {
    let result = true;
    const { openingHours } = this.formatAvailabilityTime(availability);
    const errors = {};
    if (openingHours) {
      for (const [day, data] of Object.entries(openingHours)) {
        if (data && data.isOpen) {
          const hours = get(data, 'hours[0]');
          if (
            !hours ||
            hours.length !== 2 ||
            parseInt(hours[1], 10) > AVAILABILITY_CONFIG.TIME_LIMIT
          ) {
            errors[day] = true;
            result = false;
          }
        }
      }
    }
    if (this._isMounted) {
      this.setState({
        errors,
      });
    }
    return result;
  };

  resetDayError = (day: string) => () => {
    const errors = { ...this.state.errors };
    set(errors, day, false);
    this.setState({
      errors,
    });
  };

  onFinish = (values: Object) => {
    const { onNextTab } = this.props;

    this.getFormattedData();

    if (this.validateAvailabilityTime(values)) {
      onNextTab('photos');
    }
  };

  set24Hours = (day: string) => ({ target: { checked } }: Object) => {
    this.setState(state => ({
      fullDay: {
        ...state.fullDay,
        [day]: !!checked,
      },
    }));
  };

  formatAvailabilityTime = (availability: Object) => {
    const getFormattedTime = (
      time?: Object,
      closingNextDay: boolean = false
    ) => {
      let result = time && time.format && time.format('HHmm');
      if (closingNextDay) {
        result = (parseInt(result, 10) + 2400).toString(10);
      }
      return result;
    };

    const getTimeObj = ({ open, close, fullDay }: Object) => {
      const closingNextDay = close && close.isBefore(open);
      const openTime = fullDay ? '0000' : getFormattedTime(open);
      let closeTime = getFormattedTime(close, closingNextDay);
      if (fullDay) {
        closeTime = '2400';
      }

      if (openTime && closeTime) {
        return {
          hours: [[openTime, closeTime]],
          isOpen: true,
        };
      } else {
        return {
          isOpen: false,
        };
      }
    };

    const openingHours = {};

    for (const day of AVAILABILITY_CONFIG.DAYS) {
      openingHours[day] = getTimeObj(availability[day]);
    }

    return { openingHours };
  };

  getFormattedData = () => {
    const form = this.formRef.current;
    let data = null;
    if (form) {
      const values = form.getFieldsValue();
      if (!this.validateAvailabilityTime(values)) {
        throw new Error(INVALID_TIME_RANGE_MESSAGE);
      }
      data = {
        ...this.formatAvailabilityTime(values),
        extendedHours: values.extendedHours,
      };
    }
    return data;
  };

  renderTimePicker = (day: string, placeholder: string, disabled: boolean) => (
    <TimePicker
      disabled={disabled}
      onChange={this.resetDayError(day)}
      placeholder={placeholder}
    />
  );

  renderError = (hasError: boolean) =>
    hasError && <ErrorMessage>{INVALID_TIME_RANGE_MESSAGE}</ErrorMessage>;

  setExtendedHours = (value: boolean) => {
    const form = this.formRef.current;
    if (form) {
      form.setFieldsValue({
        extendedHours: value,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { errors, fullDay } = this.state;

    return (
      <Form ref={this.formRef} onFinish={this.onFinish}>
        <div>
          <Row>
            <Col span={15}>
              <Row>
                <Col sm={24} md={12}>
                  <TabTitle>{t('storeAvailability')}</TabTitle>
                </Col>
                <Col sm={24} md={12} className="text-right">
                  <Form.Item name="extendedHours" initialValue={false}>
                    <ExtendedHoursControl
                      onChange={this.setExtendedHours}
                      editMode={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              {AVAILABILITY_CONFIG.DAYS.map(day => (
                <ColWrapper key={day}>
                  <RowWrapper>
                    <FixedWidthRow width={150}>
                      <DayLabel>{t(day, { ns: 'common' })}</DayLabel>
                    </FixedWidthRow>
                    <FixedWidthRow width={160}>
                      <Form.Item name={[day, 'open']}>
                        {this.renderTimePicker(
                          day,
                          t('openingTime'),
                          fullDay[day]
                        )}
                      </Form.Item>
                    </FixedWidthRow>

                    <FixedWidthRow width={60}>
                      <SeparationDateLine />
                    </FixedWidthRow>

                    <FixedWidthRow width={160}>
                      <Form.Item name={[day, 'close']}>
                        {this.renderTimePicker(
                          day,
                          t('closingTime'),
                          fullDay[day]
                        )}
                      </Form.Item>
                    </FixedWidthRow>
                    <FixedWidthRow style={{ marginLeft: '10px' }} width={150}>
                      <Form.Item
                        name={[day, 'fullDay']}
                        valuePropName="checked"
                      >
                        <Checkbox onChange={this.set24Hours(day)}>
                          {t('hoursCount', { hours: 24 })}
                        </Checkbox>
                      </Form.Item>
                    </FixedWidthRow>
                  </RowWrapper>
                  {this.renderError(errors[day])}
                </ColWrapper>
              ))}
            </Col>
          </Row>
          <SeparationLine />
          <ButtonNext htmlType="submit">{t('common:next')}</ButtonNext>
        </div>
      </Form>
    );
  }

}

export default Availability;
