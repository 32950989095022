import React, { Component, Fragment } from 'react';
import { Col, Progress, Row } from 'antd';
import { Dialog } from '_common/components';
import {
  TextMedium,
  CustomLink,
  CheckboxBig,
} from '_common/components/PlainStyles';
import { Trans } from 'react-i18next';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { CompaniesStore } from 'stores';

type Props = {
  companiesStore: CompaniesStore,
  dialogProps?: TDialogProps,
  t: TTranslateFunc,
};

type State = {
  agreedWithAPRules: boolean,
  isProcessing: boolean,
  isOpened: boolean,
  confirmAction: ?Function,
  callback: ?Function,
  runCallbackOnCancel?: boolean,
};

class ConsentDialog extends Component<Props, State> {

  static defaultProps = {
    dialogProps: {},
  };

  initialState = {
    agreedWithAPRules: false,
    isProcessing: false,
    isOpened: false,
    confirmAction: null,
    callback: null,
    runCallbackOnCancel: false,
  };

  state = { ...this.initialState };

  open = (
    confirmAction: Function,
    callback: Function,
    runCallbackOnCancel: boolean
  ) => {
    this.setState({
      isOpened: true,
      confirmAction,
      callback,
      runCallbackOnCancel,
    });
  };

  handleConfirm = () => {
    const { confirmAction, callback, runCallbackOnCancel } = this.state;
    if (confirmAction) {
      this.setState({
        isProcessing: true,
      });
      confirmAction().finally(() => {
        if (!runCallbackOnCancel) {
          callback();
        }
        this.handleCancel();
      });
    }
  };

  toggleAgreed = ({ target: { checked } }: Object) =>
    this.setState({ agreedWithAPRules: checked });

  renderContent = () => {
    const { isProcessing, agreedWithAPRules } = this.state;
    const {
      companiesStore: {
        selectedCompany: {
          companyName,
          topHierarchyCompanyName,
          termsAndConditionsUrl,
        },
      },
    } = this.props;
    const tsCompanyName = topHierarchyCompanyName || companyName;

    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col span={2}>
            <CheckboxBig
              onChange={this.toggleAgreed}
              checked={agreedWithAPRules}
            />
          </Col>
          <Col span={18}>
            <TextMedium>
              <Trans
                i18nKey="consentText"
                ns="promotions"
                values={{ companyName: tsCompanyName }}
              >
                My changes are in line with {tsCompanyName}
                <CustomLink href={termsAndConditionsUrl} target="_blank">
                  terms and conditions
                </CustomLink>
              </Trans>
            </TextMedium>
          </Col>
        </Row>
        <Progress
          trailColor="#e6e7e8"
          strokeColor="#542785"
          percent={isProcessing ? 100 : 0}
          status={isProcessing ? 'active' : 'normal'}
          showInfo={false}
        />
      </Fragment>
    );
  };

  handleCancel = () => {
    const { callback, runCallbackOnCancel } = this.state;
    if (callback && runCallbackOnCancel) {
      callback();
    }
    this.setState({
      ...this.initialState,
    });
  };

  render() {
    const { dialogProps, t } = this.props;
    const { agreedWithAPRules, isOpened, isProcessing } = this.state;

    return (
      <Dialog
        {...dialogProps}
        minHeight={320}
        isShown={isOpened}
        justifyHeaderContent="center"
        headerText={t('consentHeader')}
        headerFontSize={20}
        confirmLabel={t('updateActive')}
        displayCancelButton
        isConfirmDisabled={!agreedWithAPRules || isProcessing}
        renderContent={this.renderContent}
        handleConfirmClick={this.handleConfirm}
        handleCancelClick={this.handleCancel}
      />
    );
  }

}

export default compose(observer, inject('companiesStore'))(ConsentDialog);
