// @flow

import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { CompaniesStore } from 'stores';
import {
  ButtonNext,
  FixedWidthRow,
  RowWrapper,
  SectionWrapper,
  SeparationLine,
  TabTitle,
} from '_common/components/TabsForm/elements';
import { Input, InputLabel, Select } from '_common/components';
import FileInput from '_common/components/FileInput';
import {
  cleanObject,
  onSelectFromDropdown,
  setFileField,
  ruleValidator,
  validatePhoneNumber,
  validateUrl,
  normalizeUrl,
} from '_common/utils/utils';
import { withTranslation } from 'react-i18next';

type Props = {|
  form: Form,
  onNextTab: string => void,
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
|};

class Setup extends Component<Props> {

  onSubmit = e => {
    e.preventDefault();

    const { form, onNextTab } = this.props;

    form.validateFields(err => {
      if (!err) {
        onNextTab('collections');
      }
    });
  };

  getFormattedData = () => {
    const { form } = this.props;
    let data = null;

    form.validateFields((err, values) => {
      if (err) return;

      data = {
        ...values,
        website: normalizeUrl(values.website),
        affiliateLink: normalizeUrl(values.affiliateLink),
        termsAndConditionsUrl: normalizeUrl(values.termsAndConditionsUrl),
      };
    });

    cleanObject(data, ['']);

    return data;
  };

  render() {
    const { form, companiesStore, t } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col span={12}>
            <SectionWrapper>
              <TabTitle>{t('common:company')}</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('companyId')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('companyId', {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: t('companyIdError'),
                          pattern: /^[A-Z0-9_]+$/,
                        },
                      ],
                    })(<Input placeholder={t('enterCompanyId')} />)}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('companyName')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('companyName', {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: t('inputCompanyName'),
                        },
                      ],
                    })(<Input placeholder={t('enterCompanyName')} />)}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('externalCompanyId')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('externalCompanyId')(
                      <Input placeholder={t('enterExternalCompanyId')} />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('parent')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('parent', {
                      rules: [
                        {
                          required: true,
                          message: t('inputParentCompany'),
                        },
                      ],
                    })(
                      <Select
                        onSelectOption={onSelectFromDropdown(form, 'parent')}
                        options={companiesStore.getCachedUserCompaniesList}
                        inputPlaceholder="Select"
                        selectPlaceholder=""
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
            <SectionWrapper>
              <TabTitle>{t('branding')}</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={170}>
                  <InputLabel>{t('common:logo')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('logos.default')(
                      <FileInput
                        t={t}
                        onFile={setFileField(form, 'logos.default')}
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={170}>
                  <InputLabel>{t('thumbnail')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('logos.thumbnail')(
                      <FileInput
                        t={t}
                        onFile={setFileField(form, 'logos.thumbnail')}
                      />
                    )}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
          <Col span={12}>
            <SectionWrapper>
              <TabTitle>Contact</TabTitle>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('website')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('website', {
                      rules: [
                        {
                          validator: ruleValidator(
                            validateUrl,
                            t('notifications:invalidUrl')
                          ),
                        },
                      ],
                    })(<Input placeholder={t('enterWebsite')} />)}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('affiliateLink')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('affiliateLink', {
                      rules: [
                        {
                          validator: ruleValidator(
                            validateUrl,
                            t('notifications:invalidUrl')
                          ),
                        },
                      ],
                    })(<Input placeholder={t('enterAffiliateLink')} />)}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('termsAndConditionsUrl')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('termsAndConditionsUrl', {
                      rules: [
                        {
                          validator: ruleValidator(
                            validateUrl,
                            t('notifications:invalidUrl')
                          ),
                        },
                      ],
                    })(<Input placeholder={t('enterTCLink')} />)}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
              <RowWrapper>
                <FixedWidthRow width={190}>
                  <InputLabel>{t('phoneNumber')}</InputLabel>
                </FixedWidthRow>
                <FixedWidthRow width={380}>
                  <Form.Item>
                    {getFieldDecorator('phoneNumber', {
                      rules: [
                        {
                          validator: ruleValidator(
                            validatePhoneNumber,
                            t('notifications:invalidPhone')
                          ),
                        },
                      ],
                    })(<Input placeholder={t('enterContactNumber')} />)}
                  </Form.Item>
                </FixedWidthRow>
              </RowWrapper>
            </SectionWrapper>
          </Col>
        </Row>
        <SeparationLine />
        <ButtonNext htmlType="submit">{t('common:next')}</ButtonNext>
      </Form>
    );
  }

}

export default compose(
  inject('companiesStore'),
  withTranslation('company', { withRef: true }),
  Form.create(),
  observer
)(Setup);
