import styled from 'styled-components';
import { Input, Button } from '_common/components';

export const Header = styled.div`
  padding: 40px 30px;
  background-color: #fff;
  border-bottom: 1px solid #d1d2d2;
`;

export const HeaderCaption = styled.p`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 26px;
  line-height: 26px;
  color: #494949;
  margin-bottom: 14px;
  margin-right: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const HeaderInnerHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.holder {
    margin-bottom: 14px;
  }
`;

export const StyledPanelWrapper = styled.div`
  padding: 30px;
`;

export const FormContainer = styled.div`
  padding: 35px 30px 26px;
  border: solid 1px #e6e7e8;
  background-color: #ffffff;
`;

export const FormLeftPart = styled.div`
  border-right: 2px solid #e6e7e8;
  padding-right: 107px;
  width: 55%;
  box-sizing: border-box;
`;

export const FormRightPart = styled.div`
  padding-left: 107px;
  width: 50%;
  box-sizing: border-box;
`;

export const FormPartsHolder = styled.div`
  display: flex;
`;

export const FormPartCaption = styled.p`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 18px;
  line-height: 18px;
  color: #494949;
  margin-bottom: 37px;
  display: flex;
  align-items: center;

  & a {
    margin-left: 14px;
  }
`;

export const UserLabel = styled.p`
  font-family: ${props => props.theme.fonts.bold};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  color: #8c8c8c;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserInput = styled(Input)`
  width: 100%;
  && {
    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-family: ${props => props.theme.fonts.light};
      color: #8c8c8c;
    }
  }
`;

export const UserInputWrapper = styled.div`
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 33px;
  }
`;

export const Divider = styled.div`
  border-top: 2px solid #e6e7e8;
  margin-top: 24px;
  margin-bottom: 22px;
`;

export const SubmitButton = styled(Button)`
  && {
    display: inline-block;
    background: #542785;
    padding: 17px 47px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    height: auto;
    width: auto;
    margin-right: 19px;
  }
`;

export const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
`;

export const TextLink = styled.a`
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  transition: 0.3s opacity cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: ${props => props.theme.colors.primary};
    opacity: 0.7;
  }
`;

export const ValidationMessage = styled.p`
  color: #f5222d;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
`;

export const SelectBlock = styled.div``;

export const AddSelectButton = styled(SubmitButton)`
  && {
    font-family: ${props => props.theme.fonts.book};
    padding: 10px 17px;
    margin-bottom: 0;
    margin-top: 5px;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SelectCompaniesWrapper = styled.div`
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SelectSectionWrapper = styled.div`
  margin-top: 20px;
`;

export const SelectWithValue = styled.div`
  border-radius: 1px;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  padding: 14px 16px 14px 10px;
`;

export const SelectedItemsWrapper = styled.div`
  margin-top: 20px;
  max-height: 800px;
  overflow-y: auto;
`;

export const SelectedCompaniesWrapper = styled.div`
  margin-top: 20px;
  max-height: 800px;
  overflow-y: auto;
`;

export const PersonalDetailsWrapper = styled.div`
  margin-bottom: 50px;
`;

export const SelectedCompany = styled.div`
  margin-bottom: 0px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.shade3};
  padding: 8px 0;

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.shade3};
  }
`;

export const SelectedValue = styled.p`
  color: ${props => props.theme.colors.shade2};
  font-family: ${props => props.theme.fonts.bold};
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
`;

export const SelectedCompanyName = styled.p`
  color: ${props => props.theme.colors.shade2};
  font-family: ${props => props.theme.fonts.bold};
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
`;

export const SelectedCompanyId = styled.p`
  color: ${props => props.theme.colors.shade2};
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
`;

export const RemoveCompanyButton = styled(Button)`
  && {
    font-family: ${props => props.theme.fonts.basic};
    font-size: 13px;
    line-height: normal;
    width: 92px;
    border-radius: 1px;
    height: 34px;
  }
`;

RemoveCompanyButton.__ANT_BUTTON = true;

export const SelectRemoveLinkHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  & > :first-child {
    margin-right: 16px;
    margin-bottom: 0;
  }
`;

export const GeneratePassword = styled(TextLink)`
  font-size: 13px;
  line-height: 13px;
  font-family: ${props => props.theme.fonts.bold};
`;

export const StoreName = styled.p`
  font-family: ${props => props.theme.fonts.round};
  font-wight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #494949;
  margin-bottom: 5px;
`;

export const StoreId = styled.span`
  font-family: ${props => props.theme.fonts.light};
  color: #a5a6a8;
`;

export const StoreAddress = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: #6a6b6e;
  font-family: ${props => props.theme.fonts.light};
  margin-bottom: 0;
`;

export const CaptionWrapper = styled.div`
  display: flex;
`;

export const HeaderBackButton = styled(Button)`
  && {
    font-family: ${props => props.theme.fonts.basic};
    font-size: 13px;
    line-height: normal;
    width: 92px;
    border-radius: 1px;
    height: 34px;
  }
`;
