import styled from 'styled-components';
import { Input } from 'antd';

export const StyledBaseInput = styled(Input)`
  & {
    border: solid 1px #e6e7e8;
    border-radius: 2px;
    font-family: ${props => props.theme.fonts.book};
    font-size: 14px;
    color: #131314;
    width: 100%;

    &::placeholder {
      font-weight: 300;
      font-style: italic;
      font-family: ${props => props.theme.fonts.lightItalic};
      text-align: left;
      font-size: 14px;
      color: #6a6b6e;
    }
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  & {
    border: solid 1px #e6e7e8;
    border-radius: 2px;
    font-family: ${props => props.theme.fonts.book};
    font-size: 14px;
    color: #131314;
    width: 100%;

    &::placeholder {
      font-weight: 300;
      font-style: italic;
      font-family: ${props => props.theme.fonts.lightItalic};
      text-align: left;
      font-size: 14px;
      color: #6a6b6e;
    }
  }
`;

export const StyledInput = styled(StyledBaseInput)`
  && {
    min-height: 40px;
  }
`;
