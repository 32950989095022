// @flow

const defaultTheme = {
  padding: {
    header: {
      vertical: 0,
      horizontal: 30,
    },
    menuItem: {
      vertical: 20,
      horizontal: 20,
    },
  },
  colors: {
    primary: '#542785',
    secondary: '#75b7b6',
    darkBlack: '#131314',
    lightGrey: '#e6e7e8',
    mediumGrey: '#909090',
    shade1: '#fafafa',
    shade2: '#6a6b6e',
    shade3: '#d1d2d2',
    white: '#ffffff',
    link: '#1890ff',
    error: '#f5222d',
    warning: '#faad14',
  },
  fonts: {
    basic: 'GothamRounded',
    lightItalic: 'GothamRounded-LightItalic',
    bold: 'GothamRounded-Bold',
    light: 'GothamRounded-Light',
    book: 'GothamRounded-Book',
    bookItalic: 'GothamRounded-BookItalic',
  },
};

export default defaultTheme;
