// @flow

import store from 'store';

//Constants
export const TOKEN_KEY = 'admintoolv3/token';
export const REFRESH_TOKEN_KEY = 'admintoolv3/refreshtoken';
export const TOKEN_LIFETIME = 'admintoolv3/tokenlifetime';
export const USER_STORAGE_KEY = 'admintoolv3/user';
export const USER_SCOPES = 'admintoolv3/userscopes';
export const USER_ROLES = 'admintoolv3/usersroles';
export const USER_COMPANIES = 'admintoolv3/userscompanies';
export const USER_SELECTED_COMPANY = 'admintoolv3/usersselectedcompany.';
export const LAST_LOGGED_IN_COMPANY = 'admintoolv3/lastloggedincompany';
export const COMPANY_HAS_ACCESS_TO_PROMOTIONS =
  'admintoolv3/companyaccesspromotions';
export const COMPANY_LOGO = 'admintoolv3/companylogo';

export const VERSION_KEY = 'admintoolv3/version';

export default {
  get: (key: string): any => {
    return store.get(key);
  },
  set: (key: string, value: any): void => {
    try {
      return store.set(key, value);
    } catch (err) {
      // silent error.
    }
  },
  remove: (key: string): void => {
    return store.remove(key);
  },
  removeAll: (): void => {
    store.clearAll();
  },
};
