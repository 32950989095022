// @flow
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  LogoutDropdownTitle,
  Block,
  ActionsDropdownWrapper,
  ActionItem,
} from './elements';
import commonActions from '_common/actions';
import packageJson from '../../../../package.json';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { AuthStore } from 'stores';
import { get } from 'lodash';
import { HiddenOnBreakpoint } from '../PlainStyles';
import type { RouterHistory } from 'react-router';
import { withRouter } from 'react-router';
import stores from 'stores';
import { RESOURCES } from '_common/constants/acl';
import { withTranslation } from 'react-i18next';
import SelectLanguage from './SelectLanguage';

type Props = {|
  authStore: AuthStore,
  history: RouterHistory,
  t: TTranslateFunc,
  i18n: Object,
|};

const ActionsDropdown = ({ authStore, history, t, i18n }: Props) => {
  const languageSelectorRef = React.createRef();

  const [userIconLetters, setUserIconLetters] = useState('');
  const [userIdFull, setUserIdFull] = useState('');
  const [userIdShort, setUserIdShort] = useState('');
  const [orgId, setOrgId] = useState('');

  useEffect(() => {
    const loggedInUser = get(authStore, 'getLoggedUserField');
    const userIdFull = get(loggedInUser, 'userId', '');
    const userIdShort = userIdFull.split('@')[0];
    const orgId = get(loggedInUser, 'organisationId', '');
    setUserIdFull(userIdFull);
    setUserIdShort(userIdShort);
    setOrgId(orgId);
    if (userIdShort) {
      const fullName = userIdShort.split('.');
      setUserIconLetters(
        `${fullName[0][0]}${
          fullName.length > 1 ? fullName[1][0] : ''
        }`.toUpperCase()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogout = () => {
    commonActions.logout();
  };

  const goToProfile = () => {
    history.push(`/userDetails/${userIdFull}/${orgId}`);
  };

  const openLanguageSelector = () => {
    // TODO this should be fixed if we upgrade react-scripts and/or migrate to typescript
    /* eslint-disable no-unused-expressions */
    // $FlowExpectedError
    languageSelectorRef.current?.open?.();
  };

  const getMenuProps = () => {
    const defaultItems = [
      {
        key: 'item-language',
        label: (
          <ActionItem onClick={openLanguageSelector}>
            {t('language')}
          </ActionItem>
        ),
      },
      {
        key: 'item-logout',
        label: <ActionItem onClick={onLogout}>{t('logout')}</ActionItem>,
      },
      { type: 'divider' },
      {
        disabled: true,
        key: 'item-version',
        label: (
          <ActionItem>
            {t('version')} - {packageJson.version}
          </ActionItem>
        ),
      },
    ];
    const items = [];
    if (stores.aclStore.isStrictAccessGranted(RESOURCES.USERS.read)) {
      items.push({
        key: 'item-profile',
        label: (
          <ActionItem onClick={goToProfile}>{t('manageProfile')}</ActionItem>
        ),
      });
    }
    return {
      items: items.concat(defaultItems),
    };
  };

  return (
    <ActionsDropdownWrapper>
      <SelectLanguage t={t} i18n={i18n} ref={languageSelectorRef} />
      <Dropdown menu={getMenuProps()}>
        <LogoutDropdownTitle>
          <HiddenOnBreakpoint display="inline-block" breakpoint={1099}>
            <Block>{userIconLetters}</Block>
          </HiddenOnBreakpoint>
          {userIdShort} <DownOutlined />
        </LogoutDropdownTitle>
      </Dropdown>
    </ActionsDropdownWrapper>
  );
};

export default compose(
  inject('authStore'),
  withTranslation('menu'),
  withRouter,
  observer
)(ActionsDropdown);
