import { css } from 'styled-components';
import styled from 'styled-components';

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  transform: translateZ(0);
  z-index: 9999;

  & > div {
    position: absolute;
    width: ${props => props.selectWidth + 'px'};
    z-index: 9999;
  }

  .doddle-react-select__value-container > div:last-child {
    line-height: 1.5715 !important;
  }

  .doddle-react-select__dropdown-indicator,
  .doddle-react-select__indicator-separator {
    display: none;
  }

  .doddle-react-select__control {
    height: 40px;
    border: 2px solid #d8e1e8;
    box-shadow: none;

    &:hover {
      border-color: #d8e1e8;
      box-shadow: none;
    }
  }
`;

export const SpinHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.05;
  left: 0;
  top: 0;
  z-index: 2;
`;

export const SelectTrigger = styled.div`
  width: 100%;
  line-height: 38px;
  height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #d8e1e8;
  padding-left: 10px;
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  color: #8c8c8c;
  cursor: pointer;
  position: relative;

  padding-right: 30px;
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    transform: translateY(-45%);
  }

  .anticon {
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 18px;
    color: #d1d2d2;
  }

  ${props =>
    props.selected &&
    css`
      font-family: ${props => props.theme.fonts.basic};
      font-size: 14px;
      font-weight: 500;
      color: #3e3f42;
      border-radius: 2px;
      background-color: #ffffff;
      border: solid 2px #542785;

      .anticon {
        color: #542785;
      }
    `};

  ${props =>
    props.isDisabled &&
    css`
      background-color: #f2f2f2;
      cursor: not-allowed;
    `};
`;

export const SelectTriggerInfoBlock = styled(SelectTrigger)`
  line-height: 34px;
  font-size: 14px;
  ${props =>
    props.selected &&
    css`
      line-height: 32px;
      font-size: 14px;
    `};
`;
