// @flow

import React from 'react';
import { StoreInfo, StoreName } from './elements';
import moment from 'moment';
import { Dialog } from '_common/components';
import { Trans, withTranslation } from 'react-i18next';

type Props = {
  store: ?Object,
  dialogProps: TDialogProps,
  t: TTranslateFunc,
};

type State = {};

class ApproveDialog extends React.Component<Props, State> {

  renderContent = () => {
    const {
      dialogProps: { isError },
      store,
    } = this.props;
    if (store) {
      const { storeName, storeId, openingDate } = store;
      return isError ? (
        <StoreInfo>
          <Trans
            i18nKey="errorApproveSingleStore"
            ns="stores"
            values={{ storeName }}
          >
            Error while approving <StoreName>{storeName}</StoreName>
          </Trans>
        </StoreInfo>
      ) : (
        <StoreInfo>
          <StoreName>{storeName}</StoreName>
          <div>{storeId}</div>
          {openingDate && (
            <div>
              {moment.utc(openingDate).format('D MMM YYYY HH:mm', 'en')}
            </div>
          )}
        </StoreInfo>
      );
    }
  };

  render() {
    const { dialogProps, t } = this.props;

    return (
      <Dialog
        justifyHeaderContent="center"
        renderContent={this.renderContent}
        headerText={t('approveStoreConfirm')}
        displayCancelButton
        {...dialogProps}
      />
    );
  }

}

export default withTranslation('stores')(ApproveDialog);
