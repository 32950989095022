import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { ControlWrapper, Control } from './elements';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { DoddleStores } from 'stores';

type Props = {
  t: TTranslateFunc,
  editMode: boolean,
  doddleStoresStore?: DoddleStores,
  onChange?: (val: boolean) => void,
};

const ExtendedHoursControl = ({
  t,
  doddleStoresStore,
  editMode,
  onChange,
}: Props) => {
  const [extendedHoursChecked, setExtendedHours] = useState(
    editMode
      ? get(doddleStoresStore, 'singleStore.extendedHours', false)
      : false
  );

  const updateExtendedHours = ({ target: { checked: extendedHours } }) => {
    setExtendedHours(extendedHours);
    if (onChange) {
      onChange(extendedHours);
    } else if (editMode && doddleStoresStore) {
      const { storeId, companyId } = doddleStoresStore.singleStore;
      doddleStoresStore
        .updateStoreById(storeId, { extendedHours }, companyId)
        .then(result => {
          // Rollback checkbox state in case of error
          if (!result) {
            setExtendedHours(!extendedHours);
          }
        });
    }
  };

  return (
    <Tooltip title={t('extendedHoursDescription')}>
      <ControlWrapper checked={extendedHoursChecked}>
        <Control checked={extendedHoursChecked} onChange={updateExtendedHours}>
          {t('extendedHours')}
        </Control>
      </ControlWrapper>
    </Tooltip>
  );
};

export default withTranslation('stores')(ExtendedHoursControl);
