// @flow
import React from 'react';
import type { Node } from 'react';
import {
  GridFilterWrapper,
  SearchResultsWrapper,
  RegularDiv,
} from './elements';
import { Panel, Table, GridFilter } from '_common/components';
import { PaginationConfig } from 'antd/es/pagination';

type Props = {|
  rowSelection?: Object,
  resetFilters?: boolean,
  uniqueDataId: string | Function,
  filterData?: any,
  onComplete?: (Array<Object>, boolean) => void,
  filters?: Array<TFilter> | (() => Array<TFilter>),
  columns: TColumns,
  gridData: any,
  rowKey: string | Function,
  loading?: boolean,
  ignoreWrapper?: boolean,
  FilterWrapperComponent?: Object,
  SearchResultsWrapperComponent?: Object,
  onCell?: Object => { onClick: Object => void },
  EditLinkComponent?: Node,
  ignorePanel?: boolean,
  ignoreXScroll?: boolean,
  customActionColumn?: boolean,
  emptyElement?: any,
  actionRedirectLink?: Object => string | Object,
  pagination?: PaginationConfig,
  CustomFilterComponent?: Object,
  onTableChange?: Function,
|};

type State = {};

class GridWithFilters extends React.Component<Props, State> {

  render() {
    const {
      resetFilters,
      uniqueDataId,
      filterData,
      onComplete,
      filters,
      columns,
      gridData,
      rowKey,
      ignoreWrapper,
      onCell,
      loading,
      FilterWrapperComponent,
      SearchResultsWrapperComponent,
      EditLinkComponent,
      ignorePanel,
      ignoreXScroll,
      customActionColumn,
      actionRedirectLink,
      emptyElement,
      pagination,
      CustomFilterComponent,
      onTableChange,
      rowSelection,
    } = this.props;

    const OuterWrapper = ignoreWrapper
      ? RegularDiv
      : SearchResultsWrapperComponent || SearchResultsWrapper;
    const FilterWrapper = ignoreWrapper
      ? RegularDiv
      : FilterWrapperComponent || GridFilterWrapper;
    const PanelWrapper = ignorePanel ? RegularDiv : Panel;

    return (
      <OuterWrapper>
        <PanelWrapper>
          <FilterWrapper>
            {CustomFilterComponent ? (
              CustomFilterComponent
            ) : (
              // $FlowFixMe
              <GridFilter
                resetFilters={resetFilters}
                uniqueDataId={uniqueDataId}
                data={filterData}
                filterData={gridData}
                onComplete={onComplete}
                filters={filters}
              />
            )}
          </FilterWrapper>
          <Table
            rowSelection={rowSelection}
            onChange={onTableChange}
            emptyElement={emptyElement}
            loading={loading}
            scroll={ignoreXScroll ? undefined : { x: 1300 }}
            id={'table'}
            className={'doddle-table'}
            columns={columns}
            data={gridData}
            rowKey={rowKey}
            onCell={onCell}
            pagination={pagination}
            EditLinkComponent={EditLinkComponent}
            actionRedirectLink={actionRedirectLink}
            customActionColumn={customActionColumn}
          />
        </PanelWrapper>
      </OuterWrapper>
    );
  }

}

export default GridWithFilters;
