// @flow

import React, { Component, Fragment } from 'react';
import { Col, Radio } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  TabTitle,
  RowWrapper,
  SeparationLine,
  ButtonNext,
  FixedWidthRow,
  RadioGroupWrapper,
  RadioWrapper,
  StyledRadio,
  PrinterCaption,
  PrinterHolder,
  RemoveButton,
  AddButton,
  ErrorBlock,
} from '_common/components/TabsForm/elements';
import { Input, InputLabel, Select } from '_common/components';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

type Props = {
  form: Form,
  onNextTab: (?string) => void,
  t: TTranslateFunc,
};

type State = {|
  printers: Object,
  printerRequired: boolean,
|};

class Printers extends Component<Props, State> {

  state = {
    printers: {
      [Date.now()]: {
        description: '',
        host: '',
        type: 'LABEL',
      },
    },
    printerRequired: false,
  };

  getFormattedData = () => {
    let data = null;
    const { printers, printerRequired } = this.state;

    if (printerRequired) {
      // $FlowExpectedError
      data = Object.values(printers).map(printer => {
        const computedPrinter = {};
        Object.keys(printer).forEach(printerFieldName => {
          if (printerFieldName.indexOf('Error') === -1) {
            // $FlowExpectedError
            computedPrinter[printerFieldName] = printer[printerFieldName];
          }
        });
        return computedPrinter;
      });
    }

    return data;
  };

  validatePrinters = () => {
    const { printers } = this.state;
    let isValid = true;
    const updatedPrinters = {};
    Object.keys(printers).forEach(curPrinter => {
      updatedPrinters[curPrinter] = {};
      Object.keys(printers[curPrinter]).forEach(curPrinterPropName => {
        if (curPrinterPropName.indexOf('Error') > -1) return;
        updatedPrinters[curPrinter][curPrinterPropName] =
          printers[curPrinter][curPrinterPropName];
        const err = !String(printers[curPrinter][curPrinterPropName]).trim()
          .length;
        updatedPrinters[curPrinter][`${curPrinterPropName}Error`] = err;
        if (err && isValid) {
          isValid = false;
        }
      });
    });
    this.setState({
      printers: updatedPrinters,
    });
    return isValid;
  };

  onSubmit = e => {
    e.preventDefault();

    const { onNextTab } = this.props;
    const { printerRequired } = this.state;
    if (printerRequired && !this.validatePrinters()) {
      return;
    }
    onNextTab();
  };

  updatePrinter = (key: string, fieldName: string, value: string) => {
    this.setState(prevState => {
      return {
        printers: {
          ...prevState.printers,
          [key]: {
            ...prevState.printers[key],
            [fieldName]: value,
            [`${fieldName}Error`]: !String(value).trim().length,
          },
        },
      };
    });
  };

  getPrinterBlock = (
    printer: {
      description: string,
      host: string,
      type: string,
    },
    key: string,
    i: number
  ) => {
    const { printers } = this.state;
    const { t } = this.props;
    return (
      <PrinterHolder key={key}>
        <PrinterCaption>{t('printerCount', { count: i + 1 })}</PrinterCaption>
        <RowWrapper>
          <FixedWidthRow width={170}>
            <InputLabel>{t('type')}</InputLabel>
          </FixedWidthRow>
          <FixedWidthRow width={380}>
            <Form.Item>
              <Select
                initialValue={printer.type}
                onSelectOption={val => {
                  if (val) {
                    this.updatePrinter(key, 'type', val.value);
                  }
                }}
                options={[
                  {
                    label: 'LABEL',
                    value: 'LABEL',
                  },
                ]}
                inputPlaceholder={t('common:select')}
                selectPlaceholder={''}
              />
              <ErrorBlock>
                {printers[key]['typeError']
                  ? t('notifications:requiredField')
                  : null}
              </ErrorBlock>
            </Form.Item>
          </FixedWidthRow>
        </RowWrapper>
        <RowWrapper>
          <FixedWidthRow width={170}>
            <InputLabel>{t('host')}</InputLabel>
          </FixedWidthRow>
          <FixedWidthRow width={380}>
            <Form.Item>
              <Input
                placeholder={t('enterIp')}
                value={this.state.printers[key].host}
                onChange={e => this.updatePrinter(key, 'host', e.target.value)}
              />
            </Form.Item>
            <ErrorBlock>
              {printers[key]['hostError']
                ? t('notifications:requiredField')
                : null}
            </ErrorBlock>
          </FixedWidthRow>
        </RowWrapper>
        <RowWrapper>
          <FixedWidthRow width={170}>
            <InputLabel>{t('description')}</InputLabel>
          </FixedWidthRow>
          <FixedWidthRow width={380}>
            <Form.Item>
              <Input
                placeholder={t('enterDescription')}
                value={this.state.printers[key].description}
                onChange={e =>
                  this.updatePrinter(key, 'description', e.target.value)
                }
              />
            </Form.Item>
            <ErrorBlock>
              {printers[key]['descriptionError']
                ? t('notifications:requiredField')
                : null}
            </ErrorBlock>
          </FixedWidthRow>
        </RowWrapper>
        <RemoveButton
          onClick={() =>
            this.setState((prevState: State) => {
              const printers = prevState.printers;
              delete printers[key];
              return {
                printers,
              };
            })
          }
        >
          {t('removePrinter')}
        </RemoveButton>
      </PrinterHolder>
    );
  };

  render() {
    const { printers, printerRequired } = this.state;
    const { t } = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        <div>
          <TabTitle>{t('printers')}</TabTitle>
          <Col>
            <RowWrapper>
              <FixedWidthRow width={170}>
                <InputLabel>{t('printerRequired')}</InputLabel>
              </FixedWidthRow>
              <FixedWidthRow width={380}>
                <Form.Item>
                  <RadioGroupWrapper>
                    <Radio.Group
                      onChange={e => {
                        let updateObj = {};
                        if (
                          printerRequired === '0' &&
                          Object.keys(printers).length !== 1
                        ) {
                          updateObj = {
                            printers: {
                              [Date.now()]: {
                                description: ' ',
                                host: ' ',
                                type: 'LABEL',
                              },
                            },
                          };
                        }
                        this.setState({
                          printerRequired: e.target.value,
                          ...updateObj,
                        });
                      }}
                      value={printerRequired}
                    >
                      <RadioWrapper>
                        <StyledRadio value={0}>{t('common:no')}</StyledRadio>
                      </RadioWrapper>
                      <RadioWrapper>
                        <StyledRadio value={1}>{t('common:yes')}</StyledRadio>
                      </RadioWrapper>
                    </Radio.Group>
                  </RadioGroupWrapper>
                </Form.Item>
              </FixedWidthRow>
            </RowWrapper>
            {printerRequired === 1 ? (
              <Fragment>
                {Object.keys(printers).map((cur: string, i: number) =>
                  this.getPrinterBlock(printers[cur], cur, i)
                )}
                <AddButton
                  onClick={() => {
                    this.setState((prevState: State) => {
                      const printers = prevState.printers;
                      printers[Date.now()] = {
                        description: ' ',
                        host: ' ',
                        type: 'LABEL',
                      };
                      return {
                        printers,
                      };
                    });
                  }}
                >
                  {t('addPrinter')}
                </AddButton>
              </Fragment>
            ) : null}
          </Col>
          <SeparationLine />
          <ButtonNext htmlType="submit">{t('addStore')}</ButtonNext>
        </div>
      </Form>
    );
  }

}

export default compose(Form.create(), observer)(Printers);
