// @flow

import { observable, computed, runInAction, action, reaction } from 'mobx';
import parcelsService from '_common/services/parcelsService';
import parcelDetailsService from '_common/services/parcelDetailsService';
import moment from 'moment';
import _ from 'lodash';
import commonActions from '_common/actions';

type SearchHistory = {
  [key: string]: {
    items: Array<TParcelItem>,
    isSearchInit?: boolean,
    isFiltersEmpty?: boolean,
  },
};

class ParcelsStore {

  constructor() {
    reaction(
      () => this.currentOrganisation,
      () => {
        this.resetAllFilteredSearchHistory();
        this.clearSearchHistory();
      }
    );
  }

  HISTORY_KEYS = {
    parcels: 'parcels',
    customers: 'customers',
    eventSearch: 'eventSearch',
  };

  @observable
  searchHistory: SearchHistory = {};

  @observable
  filteredSearchHistory: SearchHistory = {};

  @observable
  singleReturn: TParcelItem;

  @observable
  singleCollection: TParcelItem;

  @observable
  isSearchingDetails: boolean;

  @observable
  isSearchInProgress: boolean = false;

  @observable
  currentOrganisation: string = '';

  getAdditionalData = (status: string) => {
    let additionalData = {};
    switch (status) {
      case 'VOID':
        additionalData = {
          eventType: 'ADMINSTRATIVELY_VOIDED',
          resource: {
            location: {
              shelfId: null,
            },
          },
        };
        return additionalData;
      case 'SENT_TO_RETAILER':
        additionalData = {
          eventType: 'DESPATCHED_TO_RETAILER',
          resource: {},
        };
        return additionalData;
      case 'WITH_CUSTOMER':
        additionalData = {
          eventType: 'COLLECTED_BY_CUSTOMER',
          resource: {
            location: {
              shelfId: null,
            },
          },
        };
        return additionalData;
      default:
        return additionalData;
    }
  };

  getStatusObj = (updateObj: Object, type: string) => {
    const additionalData = this.getAdditionalData(updateObj.currentValue);

    return {
      events: [
        {
          clientVersion: 'support.doddle.com',
          dateTime: new Date(),
          eventType: additionalData.eventType,
          newStatus: updateObj.currentValue,
          oldStatus: updateObj.previousValue,
          staffId: updateObj.staffId,
          eventData: {
            requester: updateObj.staffId,
            updateReason: `updated from amend parcel ${type} page`,
          },
        },
      ],
      resource: {
        status: updateObj.currentValue,
        ...additionalData.resource,
      },
    };
  };

  @computed
  get getSearchHistory(): SearchHistory {
    return this.searchHistory;
  }

  @computed
  get getFilteredSearchHistory(): SearchHistory {
    return this.filteredSearchHistory;
  }

  @computed
  get getSingleReturnCustomerName(): string {
    return _.get(this.singleReturn, 'customer')
      ? `${_.get(this.singleReturn, 'customer.name.firstName') || ''} ${_.get(
          this.singleReturn,
          'customer.name.lastName'
        ) || ''}`
      : '';
  }

  @computed
  get getSingleCollectionCustomerName(): string {
    return _.get(this.singleCollection, 'customer')
      ? `${_.get(this.singleCollection, 'customer.name.firstName') ||
          ''} ${_.get(this.singleCollection, 'customer.name.lastName') || ''}`
      : '';
  }

  @computed
  get getCollectionDeadlineString(): string {
    const d = moment(
      _.get(this.singleCollection, 'collectionDeadlineDateTime')
    );

    return `${d.format('DD MMM YYYY - HH:mm')} (${d.diff(
      moment(),
      'days'
    )} days)`;
  }

  @computed
  get getCollectionIsCollected(): string {
    return this.singleCollection &&
      _.find(this.singleCollection.eventHistory, ['eventType', 'WITH_CUSTOMER'])
      ? 'Yes'
      : 'No';
  }

  @computed
  get getArrivedInStore(): string {
    if (!this.singleCollection) {
      return 'N/A';
    }

    const ev = _.find(this.singleCollection.eventHistory, [
      'eventType',
      'DELIVERED_TO_STORE',
    ]);

    return ev ? moment(ev.dateTime).format('DD MMM YYYY') : 'N/A';
  }

  @action
  getParcelsByUser = async (
    firstName: string,
    lastName: string,
    email: string
  ) => {
    try {
      if (!this.currentOrganisation) return;

      this.isSearchInProgress = true;
      this.resetSearchHistory(this.HISTORY_KEYS.customers);

      const parcels = await parcelsService.getParcelsByUser(
        firstName,
        lastName,
        email,
        this.currentOrganisation
      );

      runInAction(() => {
        this.setSearchHistory(this.HISTORY_KEYS.customers, parcels);
        this.isSearchInProgress = false;
      });

      return Promise.resolve();
    } catch (e) {
      commonActions.showApiError('Could not get parcels by user');
      console.error(e);

      runInAction(() => {
        this.isSearchInProgress = false;
      });
    }
  };

  @action
  getParcelsByEvent = async ({
    startDate,
    endDate,
    eventType,
    storeId,
    retailerId,
    type,
  }: {
    startDate: string,
    endDate: string,
    eventType: string,
    storeId: string,
    retailerId: string,
    type: string,
  }) => {
    try {
      if (!this.currentOrganisation) return;

      this.isSearchInProgress = true;

      this.resetSearchHistory(this.HISTORY_KEYS.eventSearch);

      const parcels = await parcelsService.getParcelsByEvent({
        startDate,
        endDate,
        eventType,
        storeId,
        retailerId,
        type,
        organisationId: this.currentOrganisation,
      });

      runInAction(() => {
        this.setSearchHistory(this.HISTORY_KEYS.eventSearch, parcels);
        this.isSearchInProgress = false;
      });

      return Promise.resolve();
    } catch (e) {
      commonActions.showApiError('Could not get parcels by event');
      console.error(e);
      runInAction(() => {
        this.isSearchInProgress = false;
      });
      return e;
    }
  };

  @action
  searchForCollection = async (query: string) => {
    const searchTypes = [
      'barcode',
      'reference id',
      'retailer order id',
      'item id',
    ];

    try {
      if (!this.currentOrganisation) return;

      this.isSearchInProgress = true;
      this.resetSearchHistory(this.HISTORY_KEYS.parcels);

      const collection = await Promise.all(
        [
          parcelsService.getParcelsByBarcode(query, this.currentOrganisation),
          parcelsService.getParcelsByReferenceId(
            query,
            this.currentOrganisation
          ),
          parcelsService.getParcelsByRetailerOrderId(
            query,
            this.currentOrganisation
          ),
          parcelsService.getParcelsByItemId(query),
        ].map((request, index) =>
          request.catch(error => {
            const errorCode = _.get(error, 'response.status');
            if (errorCode !== 200 && errorCode !== 404) {
              commonActions.showApiError(
                'Could not search for collections by search type',
                5,
                { searchType: searchTypes[index] }
              );
            }
            return error;
          })
        )
      )
        .then(r => r)
        .then(n => n.filter(i => !(i instanceof Error)));

      runInAction(() => {
        collection.forEach(c => {
          this.setSearchHistory(this.HISTORY_KEYS.parcels, c);
        });

        this.isSearchInProgress = false;

        return Promise.resolve();
      });
    } catch (e) {
      console.error(e);
      commonActions.showApiError('Could not search for collections');
      runInAction(() => {
        this.isSearchInProgress = false;
      });
    }
  };

  @action
  setCurrentOrganisation = (currentOrganisation: string) => {
    this.currentOrganisation = currentOrganisation;
  };

  @action
  setSearchHistory = (key: string, items: Array<TParcelItem>) => {
    const isItemsExist =
      this.searchHistory[key] && this.searchHistory[key].items;
    this.searchHistory[key] = {
      items: [],
      isSearchInit: true,
      isFiltersEmpty: true,
    };
    this.searchHistory[key].items = isItemsExist
      ? [...isItemsExist, ...items]
      : [...items];
  };

  @action
  resetSearchHistory = (key: string) => {
    this.searchHistory[key] = {
      items: [],
      isSearchInit: false,
      isFiltersEmpty: true,
    };

    this.filteredSearchHistory[key] = {
      items: [],
      isFiltersEmpty: true,
    };
  };

  @action
  clearSearchHistory = () => {
    this.searchHistory = {};
  };

  @action
  resetAllFilteredSearchHistory = () => {
    this.filteredSearchHistory = {
      parcels: {
        items: [],
        isFiltersEmpty: true,
      },
      customers: {
        items: [],
        isFiltersEmpty: true,
      },
      eventSearch: {
        items: [],
        isFiltersEmpty: true,
      },
    };
  };

  @action
  searchForReturns = async (query: string) => {
    const searchTypes = ['barcode', 'reference id', 'item id'];

    try {
      if (!this.currentOrganisation) return;

      this.isSearchInProgress = true;
      this.resetSearchHistory(this.HISTORY_KEYS.parcels);

      const returns = await Promise.all(
        [
          parcelsService.getReturnsByBarcode(query, this.currentOrganisation),
          parcelsService.getReturnsByReferenceId(
            query,
            this.currentOrganisation
          ),
          parcelsService.getReturnsByItemId(query),
        ].map((request, index) =>
          request.catch(error => {
            const errorCode = _.get(error, 'response.status');
            if (errorCode !== 200 && errorCode !== 404) {
              commonActions.showApiError(
                'couldNotSearchForReturnsBySearchType',
                5,
                { searchType: searchTypes[index] }
              );
            }
            return error;
          })
        )
      )
        .then(r => r)
        .then(n => n.filter(i => !(i instanceof Error)));

      runInAction(() => {
        returns.forEach(c => {
          this.setSearchHistory(this.HISTORY_KEYS.parcels, c);
        });

        this.isSearchInProgress = false;
      });

      return Promise.resolve();
    } catch (e) {
      commonActions.showApiError('Could not search for returns');
      console.error(e);
      runInAction(() => {
        this.isSearchInProgress = false;
      });
    }
  };

  @action
  setFilteredCollection = (
    collection: Array<Object>,
    isFiltersEmpty: boolean,
    historyKey: string
  ) => {
    this.filteredSearchHistory[historyKey] = {
      items: collection,
      isFiltersEmpty,
    };
  };

  @action
  setParcelReturnStatus = async (updateObj: Object) => {
    try {
      const response = await parcelDetailsService.updateReturnParcelById(
        this.getStatusObj(updateObj, 'return'),
        updateObj.id
      );
      runInAction(() => {
        this.singleReturn.status = updateObj.currentValue;
        this.singleReturn.eventHistory = response.eventHistory;
      });
    } catch (e) {
      commonActions.showApiError('Could not set parcel return status');
      console.error(e);
    }
  };

  @action
  setParcelCollectionStatus = async (updateObj: Object) => {
    try {
      const response = await parcelDetailsService.updateCollectionParcelById(
        this.getStatusObj(updateObj, 'collection'),
        updateObj.id
      );
      runInAction(() => {
        this.singleCollection.status = updateObj.currentValue;
        this.singleCollection.eventHistory = response.eventHistory;
      });
    } catch (e) {
      commonActions.showApiError('Could not set parcel collection status');
      console.error(e);
    }
  };

  @action
  setParcelCollectionDeadline = async (updateObj: Object) => {
    try {
      const response = await parcelDetailsService.setParcelCollectionDeadline(
        updateObj
      );
      runInAction(() => {
        this.singleCollection.collectionDeadlineDateTime =
          updateObj.currentValue;
        this.singleCollection.eventHistory = response.eventHistory;
      });
    } catch (e) {
      commonActions.showApiError('Could not set parcel collection deadline');
      console.error(e);
    }
  };

  @action
  getLogisticsDataByDlPackageId = async (
    dlPackageId: string,
    itemType: string
  ) => {
    try {
      const dlPackage = await parcelDetailsService.getDlPackageById(
        dlPackageId
      );

      runInAction(() => {
        _.get(this, itemType).logisticsData = dlPackage;
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  searchReturnById = async (id: string) => {
    try {
      this.isSearchingDetails = true;
      const singleReturn = await parcelDetailsService
        .getReturnParcelById(id)
        .catch(e => e);

      runInAction(() => {
        this.singleReturn = singleReturn;
        const dlPackageId = _.get(singleReturn, 'dlPackageId');
        if (dlPackageId) {
          this.getLogisticsDataByDlPackageId(dlPackageId, 'singleReturn');
        }
        this.isSearchingDetails = false;
      });

      return Promise.resolve();
    } catch (e) {
      commonActions.showApiError('couldNotSearchForReturns');
      console.error(e);
      runInAction(() => {
        this.isSearchingDetails = false;
      });
    }
  };

  @action
  searchCollectionById = async (id: string) => {
    try {
      this.isSearchingDetails = true;
      const singleCollection = await parcelDetailsService
        .getCollectionParcelById(id)
        .catch(e => e);

      runInAction(() => {
        this.singleCollection = singleCollection;
        const dlPackageId = _.get(singleCollection, 'dlPackageId');
        if (dlPackageId) {
          this.getLogisticsDataByDlPackageId(dlPackageId, 'singleCollection');
        }
        this.isSearchingDetails = false;
      });

      return Promise.resolve();
    } catch (e) {
      commonActions.showApiError('couldNotSearchForCollections');
      console.error(e);
      runInAction(() => {
        this.isSearchingDetails = false;
      });
    }
  };

}

export default ParcelsStore;
