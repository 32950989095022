// @flow

import stores from 'stores';
import {
  MESSAGE_DISPLAY_TIME,
  MESSAGE_TYPE,
} from '_common/constants/appConfig';

export default (
  message: string,
  duration: number = MESSAGE_DISPLAY_TIME.SUCCESS,
  subData?: Object = {}
) => {
  return stores.uiStore.showMessage(message, {
    type: MESSAGE_TYPE.SUCCESS,
    duration,
    subData,
  });
};
