// @flow

import { MenuLink } from './elements';
import React from 'react';

const MenuItem = ({ to, children }: LinkItem) => (
  <MenuLink activeClassName="active" to={to}>
    {children}
  </MenuLink>
);

export default MenuItem;
