import React, { Component, Fragment, createRef } from 'react';
import {
  SearchHeader,
  SearchResultsWrapper,
  StoreNameLink,
  SubHeader,
  Text,
} from 'doddleStores/doddleStoresList/components/elements';
import { Button, GridWithFilters } from '_common/components';
import QRCodeDialog from 'doddleStores/doddleStoresList/components/QRCodeDialog';
import StoreFilters from 'doddleStores/doddleStoresList/components/Filters';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import stores, { DoddleStores } from 'stores';
import urls from '_common/routes/urls';

type Props = {
  t: TTranslateFunc,
  doddleStores: DoddleStores,
};

class RestrictedList extends Component<Props> {

  qrCodeDialogRef = createRef();

  getActionRedirectLink = row => {
    const { storeId = '' } = row;
    return `${urls.doddleStores.rootPath}/${storeId}`;
  };

  renderColumns = () => {
    const { t } = this.props;

    return [
      {
        title: t('storeId'),
        dataIndex: 'storeId',
        key: 'storeId',
        fixed: 'left',
        width: 200,
        sorter: (a, b) => {
          return (a.storeId || '').localeCompare(b.storeId || '');
        },
      },
      {
        title: t('storeName'),
        key: 'storeName',
        dataIndex: 'storeName',
        sorter: (a, b) => {
          return (a.storeName || '').localeCompare(b.storeName || '');
        },
        render: (storeName, store) => {
          const link = this.getActionRedirectLink(store);
          return (
            storeName && <StoreNameLink to={link}>{storeName}</StoreNameLink>
          );
        },
      },
      {
        title: t('host'),
        dataIndex: 'companyId',
        key: 'companyId',
        sorter: (a, b) => {
          return (a.companyId || '').localeCompare(b.companyId || '');
        },
      },
      {
        title: t('status'),
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => {
          return (a.status || '').localeCompare(b.status || '');
        },
      },
      {
        title: '',
        key: 'actions',
        render: (store: TStore) => (
          <Button onClick={() => this.qrCodeDialogRef?.current?.open?.(store)}>
            {t('viewQr')}
          </Button>
        ),
      },
    ];
  };

  render() {
    const { doddleStores, t } = this.props;

    const {
      getStoresField,
      getPaginationConfigField,
      isStoresLoading,
      getLoginQRCodePrivate,
    } = doddleStores;

    const isStoreRole = stores.aclStore.isStoreRole;

    return (
      <div>
        <QRCodeDialog
          t={t}
          getLoginQRCode={getLoginQRCodePrivate}
          ref={this.qrCodeDialogRef}
        />
        <SearchHeader>
          <SubHeader>
            <Text>{t(isStoreRole ? 'store' : 'plannedStores')}</Text>
          </SubHeader>
        </SearchHeader>
        <GridWithFilters
          customActionColumn
          loading={isStoresLoading}
          SearchResultsWrapperComponent={SearchResultsWrapper}
          uniqueDataId={'storeId'}
          CustomFilterComponent={
            !isStoreRole ? (
              <StoreFilters
                hiddenFilters={['countrySubdivision', 'storeType', 'status']}
                doddleStores={doddleStores}
              />
            ) : (
              <Fragment />
            )
          }
          columns={this.renderColumns()}
          gridData={getStoresField}
          pagination={getPaginationConfigField}
          rowKey={'storeId'}
        />
      </div>
    );
  }

}

export default compose(
  withTranslation('stores'),
  inject('doddleStores'),
  observer
)(RestrictedList);
