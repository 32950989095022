// @flow

import React, { Component } from 'react';

import { Title, Wrapper, SubTitle } from './elements';

type Props = {
  permissions: {
    roles: Array<string>,
    scopes: Array<string>,
  },
};

class ForbiddenMessage extends Component<Props> {

  getMessage = () => {
    const { scopes, roles } = this.props.permissions;

    let message = '';

    if (scopes.length && !roles.length) {
      message = `The scopes <span>[${scopes.toString()}]</span> are required to access this resource.`;
    }

    if (!scopes.length && roles.length) {
      message = `The roles <span>[${roles.toString()}]</span> are required to access this resource.`;
    }

    if (scopes.length && roles.length) {
      message = `The scopes <span>[${scopes.toString()}]</span> or the roles <span>[${roles.toString()}]</span> are required to access this resource.`;
    }

    return message;
  };

  render() {
    return (
      <Wrapper>
        <Title>Access forbidden</Title>
        <SubTitle dangerouslySetInnerHTML={{ __html: this.getMessage() }} />
      </Wrapper>
    );
  }

}

export default ForbiddenMessage;
