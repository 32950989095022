// @flow strict

import React, { Fragment } from 'react';
import {
  Panel,
  TabsWrapper,
  TabTitle,
  GridWithFilters,
} from '_common/components';
import { Tabs } from 'antd';
import {
  SearchHeader,
  Text,
  TableLink,
  Wrapper,
  ShelfIdHolder,
  ViewLink,
} from './elements';
import qs from 'qs';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import type { RouterHistory, Location } from 'react-router';
import { ParcelsStore, DoddleStores, CompaniesStore } from 'stores';
import moment from 'moment';
import Parcels from '../Parcels/index';
import EventSearch from '../EventSearch/index';
import CustomerSearch from '../CustomerSearch/index';
import searchActions from 'search/actions';
import { uniqBy, get, findLast } from 'lodash';
import { scrollToTable } from '_common/utils/utils';
import UIStore from '_common/stores/uiStore';
import { withTranslation } from 'react-i18next';
import urls from '_common/routes/urls';
import { MESSAGE_TYPE } from '_common/constants/appConfig';

type Props = {
  location: Location,
  history: RouterHistory,
  parcelsStore: ParcelsStore,
  doddleStores: DoddleStores,
  companiesStore: CompaniesStore,
  uiStore: UIStore,
  t: TTranslateFunc,
};

type State = {|
  hideDateFilter: boolean,
  activeTab: string,
  storeOptions: Array<TOption>,
|};

class Main extends React.Component<Props, State> {

  TABS_KEYS = {
    parcels: 'parcels',
    customers: 'customers',
    eventSearch: 'eventSearch',
  };

  state = {
    hideDateFilter: false,
    activeTab: '',
    storeOptions: [],
  };

  componentDidMount() {
    this.setActiveTab();
  }

  componentWillUnmount() {
    const { resetAllFilteredSearchHistory } = this.props.parcelsStore;

    resetAllFilteredSearchHistory();
  }

  getStoreOptions = () => {
    const options = [];

    const uniqueObjects = uniqBy(this.getSearchHistory(), (parcel) => {
      const storeId = get(parcel, 'location.storeId');

      if (!storeId) return false;

      return storeId;
    });

    uniqueObjects.forEach((parcel) => {
      const storeId = get(parcel, 'location.storeId');

      options.push({
        value: storeId,
        label: storeId,
      });
    });

    return options;
  };

  setActiveTab = () => {
    const { location } = this.props;

    let { tab } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (!tab || !this.TABS_KEYS[tab]) {
      tab = this.TABS_KEYS.parcels;
    }

    this.setState({
      activeTab: tab,
    });
  };

  onTabClick = (key: string) => {
    this.props.history.push({
      search: `?tab=${key}`,
    });

    this.setState({
      activeTab: key,
    });
  };

  onSearchComplete = (searchType: string) => {
    this.setState({
      hideDateFilter: searchType === 'returns',
      storeOptions: this.getStoreOptions(),
    });

    scrollToTable();
  };

  getSearchHistory = (tabName, fieldName) => {
    const { getSearchHistory, getFilteredSearchHistory } =
      this.props.parcelsStore;
    const { activeTab } = this.state;
    const computedTabName = tabName || activeTab;

    const method = !this.getSearchFiler(computedTabName)
      ? getFilteredSearchHistory
      : getSearchHistory;

    if (computedTabName) {
      return get(
        method[this.TABS_KEYS[computedTabName]],
        fieldName || 'items',
        []
      );
    }
  };

  getOriginalCollection = () => {
    const { getSearchHistory } = this.props.parcelsStore;

    return get(getSearchHistory[this.TABS_KEYS.eventSearch], 'items', []);
  };

  getSearchFiler = (tabName) => {
    const { getFilteredSearchHistory } = this.props.parcelsStore;
    if (tabName) {
      return get(
        getFilteredSearchHistory[this.TABS_KEYS[tabName]],
        'isFiltersEmpty'
      );
    }
  };

  getIsSearchInit = () => {
    const { getSearchHistory } = this.props.parcelsStore;

    return get(
      getSearchHistory[this.TABS_KEYS.eventSearch],
      'isSearchInit',
      false
    );
  };

  renderFilters = () => {
    const INSERT_IDX = 5;
    const { t } = this.props;

    const filters = [
      {
        label: t('parcelBarcode'),
        placeholder: t('enterParcelBarcode'),
        type: 'input',
        key: 'labelValue',
        path: 'labelValue',
      },
      {
        label: t('customerName'),
        placeholder: t('enterName'),
        type: 'input',
        key: 'filterByName',
        path: '',
      },
      {
        label: t('customerEmail'),
        placeholder: t('enterEmail'),
        type: 'input',
        key: 'email',
        path: 'customer.email',
      },
      {
        label: t('store'),
        placeholder: t('selectStore'),
        type: 'select',
        key: 'storeId',
        path: 'location.storeId',
        options: this.state.storeOptions,
      },
      {
        label: t('common:company'),
        placeholder: t('selectCompany'),
        type: 'select',
        key: 'organisationId',
        path: 'organisationId',
        options: this.props.companiesStore.getCachedUserCompaniesList,
      },
      {
        label: t('status'),
        placeholder: t('selectStatus'),
        type: 'select',
        key: 'status',
        path: 'status',
      },
    ];

    const additionalFilter = this.state.hideDateFilter
      ? []
      : [
          {
            label: t('latestStoredDate'),
            placeholder: t('common:selectDateRange'),
            type: 'date_range',
            key: 'filterByDate',
            path: '',
          },
          {
            label: t('arrivedInStore'),
            placeholder: t('common:selectDateRange'),
            type: 'date_range',
            key: 'filterByExtraDate',
            path: '',
          },
        ];

    return [
      ...filters.slice(0, INSERT_IDX),
      ...additionalFilter,
      ...filters.slice(INSERT_IDX),
    ];
  };

  renderColumns = () => {
    const INSERT_IDX = 5;
    const { t } = this.props;

    const columns = [
      {
        title: t('parcelBarcode'),
        key: 'barcode',
        width: 200,
        sorter: (a, b) => {
          return (a.labelValue || '').localeCompare(b.labelValue || '');
        },
        render: (row) => {
          const { labelValue, resourceType } = row;
          const resType =
            resourceType === 'ITEM_COLLECTION' ? 'collection' : 'return';

          return (
            labelValue && (
              <TableLink
                to={{
                  pathname: `parcelDetails/${resType}/${row.itemId}`,
                  state: {
                    activeTab: this.state.activeTab,
                  },
                }}
              >
                {labelValue}
              </TableLink>
            )
          );
        },
      },
      {
        title: t('common:name'),
        key: 'name',
        sorter: (a, b) => {
          const aName = `${get(a, 'customer.name.firstName')}${' '}${get(
            a,
            'customer.name.lastName'
          )}`;
          const bName = `${get(b, 'customer.name.firstName')}${' '}${get(
            b,
            'customer.name.lastName'
          )}`;

          return (aName || '').localeCompare(bName || '');
        },
        render: (record: TParcelItem) => {
          return (
            <span>
              {get(record, 'customer.name.firstName')}{' '}
              {get(record, 'customer.name.lastName')}
            </span>
          );
        },
      },
      {
        title: t('common:email'),
        dataIndex: ['customer', 'email'],
        key: ['customer', 'email'],
        sorter: (a, b) => {
          const aEmail = get(a, 'customer.email');
          const bEmail = get(b, 'customer.email');

          if (!aEmail || !bEmail) return 0;

          return (aEmail || '').localeCompare(bEmail || '');
        },
      },
      {
        title: t('store'),
        key: 'store',
        render: (record: TParcelItem) => {
          const { doddleStores } = this.props;

          const storeId = get(record, 'location.storeId');
          const store = doddleStores.getStoreById(storeId);

          return (
            <div>
              {store && (
                <span>
                  <TableLink to={'#'}>{store.storeName}</TableLink>
                  <br />
                </span>
              )}
              {storeId}
            </div>
          );
        },
      },
      {
        title: t('common:company'),
        key: 'retailer',
        render: (record: TParcelItem) => {
          const { companiesStore } = this.props;

          const retailerId = get(record, 'organisationId');
          const company = companiesStore.getCompanyFromCache(retailerId);

          return (
            <div>
              {company && (
                <span>
                  <TableLink to={'#'}>{company.companyName}</TableLink>
                  <br />
                </span>
              )}
              {retailerId}
            </div>
          );
        },
        sorter: (a, b) => {
          const aRetailerId = get(a, 'organisationId');
          const bRetailerId = get(b, 'organisationId');

          if (!aRetailerId || !bRetailerId) return 0;

          return (aRetailerId || '').localeCompare(bRetailerId || '');
        },
      },
      {
        title: t('status'),
        key: 'status',
        sorter: (a, b) => {
          const aStatus = get(a, 'status');
          const bStatus = get(b, 'status');

          return (aStatus || '').localeCompare(bStatus || '');
        },
        render: (record: TParcelItem) => {
          const status = get(record, 'status');

          if (status !== 'ON_SHELF') {
            return status;
          } else {
            const shelfId = get(record, 'location.shelfId');
            return (
              <Fragment>
                <div>{status}</div>
                <ShelfIdHolder>{shelfId}</ShelfIdHolder>
              </Fragment>
            );
          }
        },
      },
    ];
    const additionalColumn = this.state.hideDateFilter
      ? []
      : [
          {
            title: t('latestStoredDate'),
            key: 'stored',
            width: 185,
            sorter: (a, b) => {
              const aTime =
                a.eventHistory &&
                findLast(
                  a.eventHistory,
                  (rec) => rec.eventType === 'PUT_ON_SHELF'
                );

              const bTime =
                b.eventHistory &&
                findLast(
                  b.eventHistory,
                  (rec) => rec.eventType === 'PUT_ON_SHELF'
                );

              return (
                moment((aTime && aTime.dateTime) || 0).unix() -
                moment((bTime && bTime.dateTime) || 0).unix()
              );
            },
            render: (record: TParcelItem) => {
              const time =
                record.eventHistory &&
                findLast(
                  record.eventHistory,
                  (rec) => rec.eventType === 'PUT_ON_SHELF'
                );

              if (!time) return;

              return (
                <div>
                  {moment(time.dateTime).format('D MMMM YYYY')} <br />
                  {moment(time.dateTime).format('H:mm')}
                </div>
              );
            },
          },
          {
            title: t('arrivedInStore'),
            key: 'arrived',
            width: 185,
            sorter: (a, b) => {
              const aTime =
                a.eventHistory &&
                a.eventHistory.find(
                  (rec) => rec.eventType === 'DELIVERED_TO_STORE'
                );

              const bTime =
                b.eventHistory &&
                b.eventHistory.find(
                  (rec) => rec.eventType === 'DELIVERED_TO_STORE'
                );

              return (
                moment((aTime && aTime.dateTime) || 0).unix() -
                moment((bTime && bTime.dateTime) || 0).unix()
              );
            },
            render: (record: TParcelItem) => {
              const time =
                record.eventHistory &&
                record.eventHistory.find(
                  (rec) => rec.eventType === 'DELIVERED_TO_STORE'
                );

              if (!time) return;

              return (
                <div>
                  {moment(time.dateTime).format('D MMMM YYYY')} <br />
                  {moment(time.dateTime).format('H:mm')}
                </div>
              );
            },
          },
        ];

    return [
      ...columns.slice(0, INSERT_IDX),
      ...additionalColumn,
      ...columns.slice(INSERT_IDX),
    ];
  };

  onCell = (row) => {
    return {
      onClick: () => {
        const resType =
          row.resourceType === 'ITEM_COLLECTION' ? 'collection' : 'return';
        this.props.history.push({
          pathname: `parcelDetails/${resType}/${row.itemId}`,
          state: {
            activeTab: this.state.activeTab,
          },
        });
      },
    };
  };

  getActionRedirectLink = (row) => {
    const resType =
      row.resourceType === 'ITEM_COLLECTION' ? 'collection' : 'return';

    return {
      pathname: `parcelDetails/${resType}/${row.itemId}`,
      state: {
        activeTab: this.state.activeTab,
      },
    };
  };

  render() {
    const { parcelsStore, t } = this.props;
    const { activeTab } = this.state;

    return (
      <Wrapper>
        <SearchHeader>
          <Text
            onClick={() =>
              this.props.uiStore.showMessage(
                'Store has been successfully created.',
                {
                  duration: 100,
                  link: `${urls.doddleStores.rootPath}/${1232131313213}`,
                  linkText: 'View details',
                  type: MESSAGE_TYPE.SUCCESS,
                }
              )
            }
          >
            {t('search')}
          </Text>
        </SearchHeader>
        <Panel>
          <TabsWrapper>
            <Tabs
              defaultActiveKey={activeTab}
              animated={false}
              onChange={this.onTabClick}
              items={[
                {
                  key: this.TABS_KEYS.parcels,
                  label: <TabTitle>{t('parcelLookup')}</TabTitle>,
                  children: (
                    <Parcels
                      onComplete={(items, isFiltersEmpty) =>
                        searchActions.setFilteredCollection(
                          items,
                          isFiltersEmpty,
                          activeTab
                        )
                      }
                      filters={this.renderFilters}
                      columns={this.renderColumns()}
                      onCell={this.onCell}
                      onSearchComplete={this.onSearchComplete}
                      EditLinkComponent={<ViewLink>VIEW</ViewLink>}
                      actionRedirectLink={this.getActionRedirectLink}
                    />
                  ),
                },
                {
                  key: this.TABS_KEYS.customers,
                  label: <TabTitle>{t('customers')}</TabTitle>,
                  children: (
                    <CustomerSearch
                      onComplete={(items, isFiltersEmpty) =>
                        searchActions.setFilteredCollection(
                          items,
                          isFiltersEmpty,
                          activeTab
                        )
                      }
                      filters={this.renderFilters}
                      columns={this.renderColumns()}
                      onCell={this.onCell}
                      onSearchComplete={this.onSearchComplete}
                    />
                  ),
                },
                {
                  key: this.TABS_KEYS.eventSearch,
                  label: <TabTitle>{t('eventSearch')}</TabTitle>,
                  children: (
                    <EventSearch onSearchComplete={this.onSearchComplete} />
                  ),
                },
              ]}
            />
          </TabsWrapper>
        </Panel>

        {/* ONLY FOR EVENT SEARCH TABLE */}
        {(!!get(this.getSearchHistory(this.TABS_KEYS.eventSearch), 'length') ||
          this.getIsSearchInit()) &&
          activeTab === this.TABS_KEYS.eventSearch && (
            <GridWithFilters
              loading={parcelsStore.isSearchInProgress}
              resetFilters={false}
              uniqueDataId={'itemId'}
              filterData={this.getOriginalCollection()}
              onComplete={(items, isFiltersEmpty) =>
                searchActions.setFilteredCollection(
                  items,
                  isFiltersEmpty,
                  activeTab
                )
              }
              filters={this.renderFilters}
              columns={this.renderColumns()}
              gridData={this.getSearchHistory()}
              rowKey={'itemId'}
              onCell={this.onCell}
              EditLinkComponent={<ViewLink>{t('view')}</ViewLink>}
              actionRedirectLink={this.getActionRedirectLink}
            />
          )}
      </Wrapper>
    );
  }

}

export default compose(
  inject('parcelsStore', 'doddleStores', 'companiesStore', 'uiStore'),
  withTranslation('search'),
  observer
)(Main);
