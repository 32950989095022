import React, { Fragment } from 'react';
import {
  StaticPromotionSection,
  StaticPromotionWrapper,
  StaticPromotionTitle,
} from './elements';
import { Row } from 'antd';
import { withTranslation } from 'react-i18next';

type Props = {
  t: TTranslateFunc,
};

const StaticPromotionRepresentation = ({ t }: Props) => (
  <Fragment>
    <StaticPromotionTitle>{t('promotionLayout')}</StaticPromotionTitle>
    <StaticPromotionWrapper>
      <StaticPromotionSection width={55}>
        {t('companyLogo')}
      </StaticPromotionSection>
      <StaticPromotionSection paddingBottom={75}>
        {t('offerType')}
      </StaticPromotionSection>
      <StaticPromotionSection>{t('tc')}</StaticPromotionSection>
      <Row type="flex" justify="center">
        <StaticPromotionSection
          width={60}
          paddingTop={50}
          paddingLeft={25}
          paddingBottom={50}
          paddingRight={25}
        >
          {t('promoBcOrPi')}
        </StaticPromotionSection>
      </Row>
    </StaticPromotionWrapper>
  </Fragment>
);

export default withTranslation('promotions')(StaticPromotionRepresentation);
