// @flow
/*eslint-disable camelcase*/

import React, { Component } from 'react';
import type { RouterHistory } from 'react-router';
import { Wrapper } from './elements/DashboardElements';
import { EmbeddedIframe } from './EmbeddedIframe';
import { DashboardStore } from 'stores';
import type {
  TDashboardConfig,
} from 'dashboard/stores/dashboardStore';
import { Loader, Error } from '_common/components';
import {
  DashboardNameBlock,
} from 'dashboard/pages/elements/DashboardElements';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { STATUSES } from '_common/constants/dashboard';

type Props = {
  history: RouterHistory,
  dashboardStore: DashboardStore,
  t: TTranslateFunc,
};

type State = {
  iframeConfig: ?TDashboardConfig,
  isDrilledDown: boolean,
  initialPeriscopeConfig: ?TDashboardConfig,
};

class Dashboard extends Component<Props, State> {

  render() {
    const {
      t,
      dashboardStore: { iframeConfig, status },
    } = this.props;

    const config = iframeConfig;

    switch (status) {
      case STATUSES.LOADING: {
        return <Loader />;
      }
      case STATUSES.LOADED: {
        return (
          !!config && (
            <Wrapper>
              <DashboardNameBlock>
                <div>{config.name}</div>
              </DashboardNameBlock>
              <EmbeddedIframe src={config.src} />
            </Wrapper>
          )
        );
      }
      case STATUSES.NOT_CONFIGURED: {
        return (
          <Error
            message={t('dashboardNotConfiguredTitle')}
            description={t('dashboardNotConfiguredDescription')}
          />
        );
      }
      case STATUSES.ERROR:
      default: {
        return (
          <Error
            message={t('couldNotLoadDashboardTitle')}
            description={t('couldNotLoadDashboardDescription')}
          />
        );
      }
    }
  }

}

export default compose(
  inject('dashboardStore'),
  withTranslation('notifications'),
  observer
)(Dashboard);
