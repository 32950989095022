// @flow

import React from 'react';
import {
  FilterItem,
  FilterLabel,
  Wrapper,
} from '_common/components/GridFilter/elements';
import { Input, Select } from '_common/components';
import { ACLStore, UsersStore } from 'stores';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

type Props = {
  aclStore: ACLStore,
  usersStore: UsersStore,
  t: TTranslateFunc,
  hiddenFilters: Array<string>,
};

type State = {
  username: string,
  resetLocalFilters: boolean,
};

class UsersFilter extends React.Component<Props, State> {

  static defaultProps = {
    hiddenFilters: [],
  };

  initialState = {
    username: '',
    resetLocalFilters: true,
  };

  state = {
    ...this.initialState,
  };

  componentWillUnmount(): void {
    this.props.usersStore.setResetFiltersFlag(true);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      usersStore: { resetLocalFiltersFlag },
    } = this.props;

    if (resetLocalFiltersFlag !== prevState.resetLocalFilters) {
      this.setState({
        ...this.initialState,
        resetLocalFilters: resetLocalFiltersFlag,
      });
    }
  }

  onRoleChange = (option: TOption) => {
    const role = option ? option.value : null;
    if (role !== this.props.usersStore.filters.role) {
      this.props.usersStore.setFilters({ role });
    }
  };

  setUsernameDebounced = debounce((username: string) => {
    this.props.usersStore.setLocalFilters({ username });
  }, 700);

  onUsernameChange = ({ target: { value: username } }: Object) => {
    this.setState({
      username,
    });
    this.setUsernameDebounced(username);
  };

  render() {
    const { t, aclStore, usersStore, hiddenFilters } = this.props;
    const { username } = this.state;

    return (
      <Wrapper>
        {!hiddenFilters.includes('username') && (
          <FilterItem>
            <FilterLabel>{t('username')}</FilterLabel>
            <Input
              value={username}
              disabled={usersStore.isUsersLoading}
              onChange={this.onUsernameChange}
              placeholder={t('enterUsername')}
            />
          </FilterItem>
        )}
        {!hiddenFilters.includes('role') && (
          <FilterItem>
            <FilterLabel>{t('role')}</FilterLabel>
            <Select
              isDisabled={usersStore.isUsersLoading}
              isClearable={false}
              checkOptions
              checkInitial
              initialValue={usersStore.roleLoaded}
              selectPlaceholder={t('selectRole')}
              onSelectOption={this.onRoleChange}
              options={aclStore.getRolesListField}
              inputPlaceholder={t('selectRole')}
            />
          </FilterItem>
        )}
      </Wrapper>
    );
  }

}

export default compose(
  withTranslation('users'),
  inject('usersStore', 'aclStore'),
  observer
)(UsersFilter);
