// @flow strict

export default {
  root: '/',
  notFound: '/not-found',
  storeQRCodeImage: '/store-qr-code',

  //Login
  login: '/login',
  changePassword: '/change-password',

  //Dashboard
  dashboard: '/dashboard',

  //Search
  search: {
    main: '/search',
  },

  //Parcel DoddleStoreDetails
  parcelCollection: '/parcelDetails/collection/:id',

  //Parcel Return
  parcelReturn: '/parcelDetails/return/:id',

  //Create new user
  user: {
    createStoreUser: '/createStoreUser/:storeId',
    userList: '/users',
    create: '/users/create',
    editUser: '/userDetails/:login/:orgId',
    editUserStore: '/userDetails/:login/:orgId/:storeId',
  },

  //Stores
  doddleStores: {
    rootPath: '/stores',
    main: '/stores/list',
    restrictedList: '/stores/restricted-list',
    subroutes: {
      singleStore: '/stores/:id',
      dashboard: '/stores/dashboard',
      createStore: '/stores/create',
    },
  },

  //Companies
  companies: {
    rootPath: '/companies/create',
    main: '/companies/create',
    subroutes: {
      singleCompany: '/companies/settings',
      createCompany: '/companies/create',
    },
  },

  //Promotions
  promotions: {
    main: '/promotions/list',
    singlePromotion: '/promotions/:id',
    createPromotion: '/promotions/create',
  },
};
