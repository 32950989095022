// @flow

import {
  observable,
  computed,
  values,
  action,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import promotionsService from '_common/services/promotionsService';
import { convertImgToFileData, handleApiError } from '_common/utils/utils';
import commonActions from '_common/actions';
import { PROMO_DISPLAY_TYPES } from '_common/constants/promotions';
import stores from 'stores';
import { omit, get, isEmpty, pick } from 'lodash';
import { PaginationConfig } from 'antd/es/pagination';
import { RESOURCES } from '../constants/acl';
import { MESSAGE_TYPE } from '../constants/appConfig';
import urls from '_common/routes/urls';
import moment from 'moment';

const INITIAL_RELATIONS_PAGINATION_CONFIG: PaginationConfig = {
  current: 1,
  pageSize: 10,
  hideOnSinglePage: true,
  total: 0,
};

class PromotionsStore {

  constructor() {
    reaction(
      () => this.currentCompany,
      () => {
        const { aclStore, companiesStore } = stores;
        this.resetFilteredCollection();
        if (
          aclStore.isStrictAccessGranted(RESOURCES.PROMOTIONS) &&
          companiesStore.getCompanyPromotionsEnabled(this.currentCompany)
        ) {
          const shouldGetPromotionsByStore = aclStore.isStoreRole;
          this.loadOffers();
          this.loadPromotions(shouldGetPromotionsByStore);
          this.loadProductImageLibrary();
        }
      }
    );
  }

  @observable
  promotions: Map<string, TPromotion> = new Map();

  @observable
  singlePromotion: TPromotion | Object = {};

  @observable
  singlePromotionOffer: TPromotionOffer | Object = {};

  @observable
  singlePromotionStores: Map<
    string,
    TStore | TPromotionStoreRelation
  > = new Map();

  @observable
  singlePromotionStoreRelationsPaginationConfig: PaginationConfig = {
    ...INITIAL_RELATIONS_PAGINATION_CONFIG,
    onChange: this.loadSinglePromotionStoreRelations.bind(this),
  };

  @observable
  isPromotionsEmpty: boolean = false;

  @observable
  filteredPromotions: Array<TPromotion> = [];

  @observable
  isFiltersEmpty: boolean = true;

  @observable
  resetFilters: boolean = false;

  @observable
  isLoading: boolean = false;

  @observable
  currentCompany: TCompany | Object = {};

  @observable
  offers: Map<string, TPromotionOffer> = new Map();

  @observable
  imageLibrary: Array<string> = [];

  @computed
  get getSinglePromotionStoreRelationsPaginationConfigField(): Object {
    return this.singlePromotionStoreRelationsPaginationConfig;
  }

  @computed
  get getOffers(): Array<TPromotionOffer> {
    return values(this.offers).reduce((accum, offer) => {
      const tempAccum = [...accum];
      tempAccum.push({
        ...offer,
        labeledTemplate: this.getLabeledTemplate(offer),
      });
      return tempAccum;
    }, []);
  }

  @computed
  get getEnabledOffers(): Array<TPromotionOffer> {
    return this.getOffers.filter(offer => offer.enabled);
  }

  @computed
  get getSinglePromotionStoresField(): Array<TStore> {
    return values(this.singlePromotionStores);
  }

  @computed
  get getPromotionsField(): Array<TPromotion> {
    return values(this.promotions);
  }

  @computed
  get getFilteredPromotions(): Array<TPromotion> {
    return values(this.filteredPromotions);
  }

  getOfferFromCache = (offerId: string): ?TPromotionOffer => {
    return this.offers.get(offerId);
  };

  getOfferById = async (
    offerId: string,
    forceLoad?: boolean = false
  ): Promise<?TPromotionOffer> => {
    return this.offers.get(offerId);
  };

  @action
  resetPromotionStores = () => {
    this.singlePromotionStores.clear();
    this.singlePromotionStoreRelationsPaginationConfig = {
      ...this.singlePromotionStoreRelationsPaginationConfig,
      ...INITIAL_RELATIONS_PAGINATION_CONFIG,
    };
  };

  @action
  assignAllStores = async (promotionId: string): Promise<void> => {
    if (promotionId) {
      commonActions.showWarningMessage('storesAssigning', 7);
      const allStoresForCurrentCompany = await stores.doddleStores.getAllStoresByFilters(
        {
          host: get(this.currentCompany, 'companyId'),
          fieldsToReturn: 'storeId',
        }
      );
      // Flow wants redundant promotionId check
      await this.addPromotionStoreRelations(
        // $FlowFixMe
        promotionId,
        allStoresForCurrentCompany,
        {
          saveInternal: false,
          saveExternal: true,
          showMessages: false,
        }
      );
      commonActions.showSuccessMessage('storesAssigned', 7, { promotionId });
    }
  };

  @action
  resetSinglePromotion(): void {
    this.singlePromotion = {};
    this.resetPromotionStores();
  }

  @action
  addPromotionStoreRelations = async (
    promotionId?: string,
    stores: Array<Object>,
    options: TPromotionStoreRelationOptions = {}
  ): Promise<void> => {
    const {
      saveInternal = true,
      saveExternal = false,
      showMessages = true,
    } = options;
    if (stores && stores.length) {
      let saveExternalError = false;
      if (saveExternal && promotionId) {
        try {
          const storeIds = stores.map((store: TStore) => store.storeId);
          if (showMessages) {
            commonActions.showWarningMessage('storesAssigning', 7);
          }

          // $FlowFixMe
          await promotionsService.addStoresByIds(promotionId, storeIds);

          if (showMessages) {
            commonActions.showSuccessMessage('storesAssigned', 7, {
              promotionId,
            });
          }
        } catch (e) {
          saveExternalError = true;
          let message = 'couldNotCreatePromotionStoreRelations';
          const nativeErrors = get(e, 'response.data.errors');
          if (
            get(e, 'response.status') === 400 &&
            !isEmpty(nativeErrors) &&
            nativeErrors.find(e =>
              e.message.includes('is currently being assigned to stores')
            )
          ) {
            message = 'promoCurrentlyAssignedToStores';
          }
          handleApiError(e, message, { messageSubData: { promotionId } });
        }
      }
      if (saveInternal && !saveExternalError) {
        runInAction(() => {
          stores.forEach((store: Object) => {
            if (store && store.storeId) {
              this.singlePromotionStores.set(store.storeId, store);
            }
          });
        });
      }
    }
  };

  @action
  removeSingleRelation = async (
    promotionId?: string,
    store: TStore | TPromotionStoreRelation,
    options: TPromotionStoreRelationOptions = {}
  ): Promise<void> => {
    const { saveInternal = true, saveExternal = false } = options;
    if (store) {
      let saveExternalError = false;
      if (saveExternal && promotionId && store.relationId) {
        try {
          await promotionsService.removeSingleRelation(
            promotionId,
            // $FlowFixMe
            store.relationId
          );
        } catch (e) {
          saveExternalError = true;
          handleApiError(e, 'couldNotDeletePromotionStoreRelations');
        }
      }
      if (saveInternal && !saveExternalError && store.storeId) {
        runInAction(() => {
          // Flow wants a redundant check for storeId
          // $FlowFixMe
          this.singlePromotionStores.delete(store.storeId);
        });
      }
    }
  };

  @action
  addPromotionStoreRelationsByFilters = async (
    promotionId?: string,
    filters: TStoresFilterParams,
    options: TPromotionStoreRelationOptions = {}
  ): Promise<void> => {
    const { saveInternal = true, saveExternal = false } = options;
    let newStores = [];
    const singleStore = await stores.doddleStores.getSingleStoreByIdAndFilters(
      filters
    );
    if (singleStore) {
      newStores = [singleStore];
    } else {
      newStores = await stores.doddleStores.getAllStoresByFilters({
        ...filters,
        fieldsToReturn: 'storeId,storeName,companyId,status',
      });
    }
    return this.addPromotionStoreRelations(promotionId, newStores, {
      saveInternal,
      saveExternal,
      showMessages: !!saveExternal,
    });
  };

  @action
  setFilteredCollection = (
    collection: Array<TPromotion>,
    isFiltersEmpty: boolean
  ) => {
    this.filteredPromotions = collection;
    this.isFiltersEmpty = isFiltersEmpty;
  };

  @action
  resetFilteredCollection = () => {
    this.filteredPromotions = [];
    this.isFiltersEmpty = true;
    this.resetFilters = !this.resetFilters;
  };

  @action
  setCurrentCompany = (currentCompany: TCompany | Object) => {
    this.currentCompany = currentCompany;
  };

  @action
  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  cloneSinglePromotion = async (title: string): Promise<boolean> => {
    const { uiStore, aclStore } = stores;
    let result = false;
    if (this.singlePromotion) {
      const data = {
        title,
        startDate: moment()
          .subtract(7, 'days')
          .toISOString(),
        endDate: moment()
          .subtract(6, 'days')
          .toISOString(),
        ...pick(toJS(this.singlePromotion), [
          'colourScheme',
          'companyId',
          'companyLogo',
          'description',
          'barcode',
          'promotionCode',
          'tcLink',
        ]),
        offer: {
          offerId: get(this.singlePromotion, 'offer.offerId'),
          variables: get(toJS(this.singlePromotion), 'offer.variables'),
        },
      };
      try {
        // $FlowExpectedError
        const newPromotion = await promotionsService.createPromotion(data);
        if (newPromotion && newPromotion.promotionId) {
          if (aclStore.isStoreRole) {
            await this.addPromotionStoreRelations(
              newPromotion.promotionId,
              [{ storeId: aclStore.storeUsersStoreId }],
              {
                saveInternal: false,
                saveExternal: true,
                showMessages: true,
              }
            );
          }
          // eslint-disable-next-line
          uiStore.showMessage('Promotion has been successfully created', {
            duration: 5,
            link: urls.promotions.singlePromotion.replace(
              ':id',
              newPromotion.promotionId
            ),
            linkText: 'View details',
            type: MESSAGE_TYPE.SUCCESS,
          });
          runInAction(() => {
            this.promotions.set(newPromotion.promotionId, newPromotion);
          });
          const image = get(this.singlePromotion, 'image');
          if (image) {
            convertImgToFileData(image, {
              callback: data => {
                this.uploadProductImage(
                  newPromotion.promotionId,
                  omit(data, ['size'])
                );
              },
              errorCallback: () => {
                handleApiError({}, 'Could not load image');
              },
            });
          }
        }
        result = true;
      } catch (e) {
        handleApiError(e, 'Could not create promotion');
      }
    }
    return result;
  };

  createPromotion = async ({
    general,
    stores: storesTabData,
    marketingMessage,
  }: Object): Promise<boolean> => {
    const { companiesStore, uiStore, aclStore } = stores;
    const selectedCompany = companiesStore.selectedCompany;
    const companyLogo = get(
      selectedCompany,
      'logos.default',
      get(selectedCompany, 'logos.thumbnail', companiesStore.companyLogo)
    );
    let result = false;
    if (companyLogo) {
      const promotionObject = {
        companyId: selectedCompany.companyId || aclStore.selectedCompanyId,
        companyLogo,
        ...general,
        ...omit(marketingMessage, ['image']),
      };
      let imageToUpload = null;
      if (marketingMessage.image) {
        if (typeof marketingMessage.image === 'object') {
          imageToUpload = marketingMessage.image;
        } else {
          promotionObject.image = marketingMessage.image;
        }
      }

      try {
        // $FlowFixMe
        const promotion = await promotionsService.createPromotion(
          promotionObject
        );
        if (promotion && promotion.promotionId) {
          // eslint-disable-next-line
          uiStore.showMessage('Promotion has been successfully created', {
            duration: 5,
            link: urls.promotions.singlePromotion.replace(
              ':id',
              promotion.promotionId
            ),
            linkText: 'View details',
            type: MESSAGE_TYPE.SUCCESS,
          });
          runInAction(() => {
            this.promotions.set(promotion.promotionId, promotion);
          });

          // Assign stores
          let stores = this.getSinglePromotionStoresField;
          if (aclStore.isStoreRole) {
            stores = [{ storeId: aclStore.storeUsersStoreId }];
          }
          if (storesTabData && storesTabData.allStoresAssigned) {
            await this.assignAllStores(promotion.promotionId);
          } else if (stores && stores.length) {
            await this.addPromotionStoreRelations(
              promotion.promotionId,
              stores,
              {
                saveInternal: false,
                saveExternal: true,
                showMessages: true,
              }
            );
          }

          // Upload product image
          if (imageToUpload) {
            this.uploadProductImage(promotion.promotionId, imageToUpload);
          }
        }
        result = true;
      } catch (e) {
        handleApiError(e, 'Could not create promotion');
      }
    } else {
      commonActions.showApiError(
        'Could not create a promotion for a company without logo'
      );
    }
    return result;
  };

  @action
  updatePromotionDisplayType = async (
    promotionId: string,
    promoDisplayType: string,
    data: Object
  ) => {
    const updateObj = {
      image: null,
      barcode: null,
      promotionCode: null,
    };
    let error = false;
    switch (promoDisplayType) {
      case PROMO_DISPLAY_TYPES.BARCODE: {
        updateObj.barcode = data.barcode;
        break;
      }
      case PROMO_DISPLAY_TYPES.PRODUCT_IMAGE: {
        delete updateObj.image;
        const updateImageResult = await this.uploadProductImage(
          promotionId,
          data.image
        );
        error = !updateImageResult;
        break;
      }
      case PROMO_DISPLAY_TYPES.PROMOTION_CODE: {
        updateObj.promotionCode = data.promotionCode;
        break;
      }
      default: {
        error = true;
      }
    }

    if (!error) {
      const updateSuccess = await this.updatePromotion(promotionId, updateObj);
      if (updateSuccess && this.singlePromotion) {
        const omitKeys = [];
        Object.entries(updateObj).forEach(([key, value]) => {
          if (value === null) {
            omitKeys.push(key);
          }
        });
        const singlePromo = omit(this.singlePromotion, omitKeys);
        this.updateCachedSinglePromotion(promotionId, singlePromo);
      }
    } else {
      commonActions.showApiError('Could not update promotion display type');
    }
  };

  uploadProductImage = async (
    promotionId: string,
    data: TFileDataType | string
  ) => {
    let result = false;
    if (typeof data === 'string') {
      return this.updatePromotion(promotionId, { image: data });
    }
    try {
      const productImageBody = {
        ...data,
        imageType: 'PROMOTION_IMAGE',
      };
      const updatedData = await promotionsService.uploadProductImage(
        promotionId,
        productImageBody
      );
      if (updatedData && updatedData.image) {
        result = true;
        this.updateCachedSinglePromotion(promotionId, updatedData);
      }
    } catch (e) {
      let message = 'Could not upload product image';
      const nativeErrors = get(e, 'response.data.errors');
      if (
        get(e, 'response.status') === 400 &&
        !isEmpty(nativeErrors) &&
        nativeErrors.find(e => e.message.includes('Reached max images'))
      ) {
        message = 'promoMaxImages';
      }
      handleApiError(e, message);
    }
    return result;
  };

  @action
  updatePromotionOffer = async (
    promotionId: string,
    data: { offer: { offerId: string, variables: Array<Object> } },
    newOfferEntity: TPromotionOffer
  ): Promise<void> => {
    const updateSuccess = await this.updatePromotion(promotionId, data);
    if (updateSuccess) {
      runInAction(() => {
        this.singlePromotionOffer = newOfferEntity;
      });
    }
  };

  @action
  updatePromotion = async (
    promotionId: string,
    data: Object
  ): Promise<boolean> => {
    let result = false;
    try {
      // Exclude offer entity if updating offer
      const updatedPromo = await promotionsService.updatePromotion(
        promotionId,
        data
      );
      result = true;
      this.updateCachedSinglePromotion(promotionId, updatedPromo);
      runInAction(() => {
        // Flow requires promotion isNotUndefined check which is redundant here
        // $FlowFixMe
        this.promotions.set(promotionId, { ...updatedPromo });
      });
    } catch (e) {
      handleApiError(e, 'Could not update promotion');
    }
    return result;
  };

  @action
  updateSinglePromotion = (
    updateMethod: Function,
    args: Array<any>,
    callback?: Function = () => {},
    runCallbackOnCancel?: boolean = true
  ): void => {
    const {
      uiStore: { promotionEditConsentDialogRef: dialogRef },
    } = stores;

    const { promotionId, enabled } = this.singlePromotion;

    if (dialogRef && dialogRef.open && enabled) {
      dialogRef.open(
        updateMethod.bind(this, promotionId, ...args),
        callback,
        runCallbackOnCancel
      );
    } else {
      updateMethod(promotionId, ...args).finally(callback);
    }
  };

  @action
  updateCachedSinglePromotion = (promotionId: string, data: Object) => {
    if (this.singlePromotion.promotionId === promotionId) {
      if (data.image) {
        data.image = `${data.image}?t=${Date.now()}`;
      }
      if (this.singlePromotion.canEdit) {
        data.canEdit = this.singlePromotion.canEdit;
      }
      runInAction(() => {
        this.singlePromotion = { ...data };
      });
    }
  };

  @action
  toggleEnablePromotion = async (
    promotionId: string,
    enabled: boolean
  ): Promise<void> => {
    const success = await this.updatePromotion(promotionId, { enabled });
    if (success) {
      const updatedPromotionIndex = this.filteredPromotions.findIndex(
        (promotion: TPromotion) => promotion.promotionId === promotionId
      );
      if (updatedPromotionIndex !== -1) {
        const promo = this.filteredPromotions[updatedPromotionIndex];
        const updatedPromo = { ...promo, enabled };
        runInAction(() => {
          this.filteredPromotions[updatedPromotionIndex] = updatedPromo;
        });
      }
    }
  };

  @action
  toggleEnableOffer = async (
    offerId: string,
    enabled: boolean
  ): Promise<void> => {
    try {
      // $FlowExpectedError
      const offer = await promotionsService.toggleEnableOffer(offerId, enabled);
      if (offer && offer.offerId) {
        runInAction(() => {
          this.offers.set(offer.offerId, offer);
        });
      }
    } catch (e) {
      handleApiError(e, `Could not ${enabled ? 'enable' : 'disable'} offer`);
    }
  };

  @action
  async loadSinglePromotionById(
    promotionId: string,
    loadStoreRelations: boolean = true
  ): Promise<void> {
    const setSinglePromotionEntity = async (promotion: TPromotion) => {
      if (promotion.image) {
        promotion.image = `${promotion.image}?t=${Date.now()}`;
      }
      // Load offer entity
      let offer = null;
      if (promotion.offer && promotion.offer.offerId) {
        offer = await this.getOfferById(promotion.offer.offerId);
      }
      runInAction(() => {
        this.singlePromotion = promotion;
        if (offer) {
          this.singlePromotionOffer = offer;
        }
      });
    };

    let promotion = null;
    try {
      promotion = await promotionsService.getPromotion(promotionId);
      if (promotion) {
        setSinglePromotionEntity(promotion).then(() => {
          if (loadStoreRelations) {
            this.loadSinglePromotionStoreRelations();
          }
        });
      }
    } catch (e) {
      promotion = this.promotions.get(promotionId);
      if (promotion) {
        setSinglePromotionEntity(promotion);
      } else {
        handleApiError(e, 'Could not load promotion by id', {
          messageSubData: { promotionId },
        });
      }
    }
  }

  @action
  async loadSinglePromotionStoreRelations(
    page: number = 1,
    limit: number = 10
  ): Promise<void> {
    this.isLoading = true;
    const promotionId = this.singlePromotion.promotionId;

    if (!promotionId) {
      return;
    }

    try {
      const {
        pagination,
        relations,
      } = await promotionsService.getPromotionStoreRelations(
        promotionId,
        page,
        limit
      );
      runInAction(() => {
        this.singlePromotionStores.clear();
        this.singlePromotionStoreRelationsPaginationConfig.total =
          pagination.totalRecords;
        this.singlePromotionStoreRelationsPaginationConfig.current = page;
        if (relations && relations.length) {
          relations.forEach((relation: TPromotionStoreRelation) => {
            if (relation.storeId)
              this.singlePromotionStores.set(relation.storeId, relation);
          });
        }
      });
    } catch (e) {
      handleApiError(e, 'Could not load relations by id', {
        showNativeErrors: false,
        hide404Error: true,
        messageSubData: { promotionId },
        custom404Handler: () => {
          runInAction(() => {
            this.singlePromotionStores.clear();
          });
        },
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action
  reloadSinglePromotionStoreRelations = (): void => {
    this.loadSinglePromotionStoreRelations(
      this.singlePromotionStoreRelationsPaginationConfig.current,
      this.singlePromotionStoreRelationsPaginationConfig.pageSize
    );
  };

  /**
   * Load promotions by company id (default)
   * or by storestaff (store id) value if getByStore=true
   *
   * @param getByStore
   * @returns {Promise<void>}
   */
  @action
  loadPromotions = async (getByStore?: boolean = false) => {
    this.setLoading(true);

    try {
      let promotions = [];
      if (getByStore) {
        const storeId = get(stores.authStore, 'getLoggedUserField.storeStaff');
        if (storeId) {
          promotions = await promotionsService.getPromotionsByStoreId(storeId);
        } else {
          throw new Error('Unknown store id');
        }
      } else {
        promotions = await promotionsService.getPromotions(
          get(this.currentCompany, 'companyId')
        );
      }
      runInAction(() => {
        this.promotions.clear();
        if (promotions.length) {
          //$FlowFixMe
          promotions.forEach((promotion: TPromotion) => {
            if (promotion.promotionId)
              this.promotions.set(promotion.promotionId, promotion);
          });
        } else {
          this.isPromotionsEmpty = true;
        }
      });
    } catch (e) {
      handleApiError(e, 'Could not load promotions', {
        showNativeErrors: false,
        hide404Error: true,
      });
    } finally {
      this.setLoading(false);
    }
  };

  sendTestEmailById = async (
    promotionId: string,
    email: string
  ): Promise<boolean> => {
    let result = false;
    try {
      await promotionsService.sendTestEmailById(promotionId, email);
      result = true;
    } catch (e) {
      handleApiError(e, 'Could not send a test email');
    }
    return result;
  };

  sendTestEmailByData = async (
    data: Object,
    email: string
  ): Promise<boolean> => {
    let result = false;
    try {
      await promotionsService.sendTestEmailByData(data, email);
      result = true;
    } catch (e) {
      handleApiError(e, 'Could not send a test email');
    }
    return result;
  };

  loadOffers = async () => {
    this.offers.clear();
    try {
      // $FlowFixMe
      const offers = await promotionsService.getOffersByCompanyId(
        get(this.currentCompany, 'companyId')
      );
      runInAction(() => {
        offers.forEach(offer => {
          this.offers.set(offer.offerId, offer);
        });
      });
    } catch (e) {
      handleApiError(e, 'Could not load offers');
    }
  };

  getLabeledTemplate = (offer: TPromotionOffer): string => {
    let labeledTemplate = offer.template;
    offer.variables.forEach(({ id, label }) => {
      labeledTemplate = labeledTemplate.replace(`\${${id}}`, `[${label}]`);
    });
    return labeledTemplate;
  };

  @action
  createOffer = async (data: {
    template: string,
    variables: Array<TPromotionOfferVariable>,
  }) => {
    try {
      const offer = await promotionsService.createOffer(
        stores.aclStore.selectedCompanyId,
        { ...data, companyId: stores.aclStore.selectedCompanyId }
      );
      runInAction(() => {
        this.offers.set(offer.offerId, offer);
      });
    } catch (e) {
      handleApiError(e, 'Could not create offer');
    }
  };

  @action
  deleteOffer = async (offerId: string) => {
    try {
      await promotionsService.deleteOffer(offerId);
      runInAction(() => {
        this.offers.delete(offerId);
      });
    } catch (e) {
      handleApiError(e, 'Could not delete offer');
    }
  };

  @action
  loadProductImageLibrary = async () => {
    const { selectedCompanyId } = stores.aclStore;
    if (selectedCompanyId) {
      try {
        const libraryAssets = await promotionsService.getLibraryAssets(
          selectedCompanyId
        );
        runInAction(() => {
          this.imageLibrary = libraryAssets;
        });
      } catch (e) {
        console.warn(`Could not get library for ${selectedCompanyId}`);
      }
    }
  };

  @action
  removeCorruptedImage = (imageUrl: string) => {
    this.imageLibrary = this.imageLibrary.filter(url => url !== imageUrl);
  };

}

export default PromotionsStore;
