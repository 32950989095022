// @flow
import doddleStoresActions from 'doddleStores/actions';
import { Roles } from '_common/constants/acl';
import { boolToWord } from '_common/utils/utils';
import commonActions from '_common/actions';
import { FLAG_OPTIONS } from '_common/constants/appConfig';

export const returnsBlock = () => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
  });
  return {
    infoRows: [
      {
        name: 'Enabled',
        path: 'singleStore.services.RETURNS.enabled',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: { RETURNS: { enabled: updateObj.currentValue } },
          });
        },
      },
      {
        name: 'Testing',
        path: 'singleStore.services.RETURNS.testing',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: { RETURNS: { testing: updateObj.currentValue } },
          });
        },
      },
      {
        name: 'Integrated Lockers',
        path: 'singleStore.services.RETURNS.integratedLockers',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: {
              RETURNS: { integratedLockers: updateObj.currentValue },
            },
          });
        },
      },
      {
        aclAccess: hasPermissions,
        name: 'Integrated Lockers Config',
        path: 'singleStore.services.RETURNS.integratedLockersConfig.siteId',
        type: 'input',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: {
              RETURNS: {
                integratedLockersConfig: { siteId: updateObj.currentValue },
              },
            },
          });
        },
      },
      {
        name: 'Accepts paperless',
        path: 'singleStore.services.RETURNS.acceptsPaperless',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: { RETURNS: { acceptsPaperless: updateObj.currentValue } },
          });
        },
      },
    ],
    translateNS: 'stores',
    idPropName: 'singleStore.storeId',
    header: 'Returns',
  };
};

export const collectionsBlock = () => {
  const hasPermissions = commonActions.canAccessFeature({
    roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
  });
  return {
    infoRows: [
      {
        name: 'Enabled',
        path: 'singleStore.services.COLLECTIONS.enabled',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: { COLLECTIONS: { enabled: updateObj.currentValue } },
          });
        },
      },
      {
        name: 'Testing',
        path: 'singleStore.services.COLLECTIONS.testing',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: { COLLECTIONS: { testing: updateObj.currentValue } },
          });
        },
      },
      {
        name: 'Integrated Lockers',
        path: 'singleStore.services.COLLECTIONS.integratedLockers',
        aclAccess: hasPermissions,
        localizeValue: true,
        localizeOptions: true,
        compute: boolToWord,
        type: 'select',
        options: FLAG_OPTIONS,
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: {
              COLLECTIONS: { integratedLockers: updateObj.currentValue },
            },
          });
        },
      },
      {
        aclAccess: hasPermissions,
        name: 'Integrated Lockers Config',
        path: 'singleStore.services.COLLECTIONS.integratedLockersConfig.siteId',
        type: 'input',
        onUpdateClicked: (updateObj: Object) => {
          doddleStoresActions.updateStoreById(updateObj.id, {
            services: {
              COLLECTIONS: {
                integratedLockersConfig: { siteId: updateObj.currentValue },
              },
            },
          });
        },
      },
    ],
    translateNS: 'stores',
    idPropName: 'singleStore.storeId',
    header: 'Orders',
  };
};
