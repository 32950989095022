import styled from 'styled-components';

export const SelectAsyncWrapper = styled.div`
  width: 100%;
  position: relative;

  .anticon {
    z-index: 170;
  }

  > .anticon-up,
  .anticon-down {
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 16px;
    color: #d1d2d2;
  }

  .ant-select {
    display: block;
    z-index: 160;
  }

  .ant-select-selection {
    height: 40px;
    border: 1px solid #d8e1e8;
    border-radius: unset;
  }

  .ant-select-selection__rendered {
    line-height: 38px;
  }

  .ant-select-selection__placeholder {
    font-family: ${props => props.theme.fonts.book};
    font-size: 14px;
    color: #8c8c8c;
  }

  .ant-select-open,
  .ant-select-enabled,
  .ant-select-focused {
    .ant-select-selection {
      border: 1px solid #d8e1e8;
      border-radius: unset;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .ant-select-clear {
    top: 43%;
    right: 40px;
    font-size: 15px;
  }

  .ant-select-arrow {
    right: 63px;
    margin-top: -9px;
    font-size: 15px;
  }
`;
