import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SearchHeader = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #e6e7e8;
  padding: 40px 30px;
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 26px;
  color: #494949;
`;

export const TableLink = styled(Link)`
  font-family: ${props => props.theme.fonts.basic};
  color: #542785;
  font-size: 13px;
`;

export const Wrapper = styled.div`
  .ant-tabs {
    overflow: visible;
  }
`;

export const ShelfIdHolder = styled.div`
  font-size: 12px;
  line-height: 21px;
`;

export const OrganisationSection = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
`;

export const HeaderCaption = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 26px;
  line-height: 26px;
  color: #494949;
  margin-bottom: 0;
`;

export const OrgIdCaption = styled(HeaderCaption)`
  font-size: 18px;
  line-height: 18px;
  margin-right: 20px;
`;

export const SelectHolder = styled.div`
  flex-basis: 100%;
`;

export const OrganisationWarning = styled.div`
  color: #ff0012;
  font-size: 18px;
`;

export const ViewLink = styled.div`
  font-family: ${props => props.theme.fonts.bold};
  font-size: 14px;
  color: #542785;
  cursor: pointer;
`;
