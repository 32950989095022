import React, { Component } from 'react';
import { BackButton, LanguageItem, SelectLanguageWrapper } from './elements';
import { LeftOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import en from 'assets/images/gb.png';
import ja from 'assets/images/jp.png';
import ar from 'assets/images/ar.svg';
import moment from 'moment';
import stores from 'stores';

type State = {
  isOpened: boolean,
};

type Props = {
  t: TTranslateFunc,
  i18n: Object,
};

class SelectLanguage extends Component<Props, State> {

  state = {
    isOpened: false,
  };

  open = () => {
    this.setState({
      isOpened: true,
    });
  };

  close = () => {
    this.setState({
      isOpened: false,
    });
  };

  changeLanguage = (lng: string) => () => {
    moment.locale(lng);
    this.props.i18n.changeLanguage(lng);
    stores.uiStore.setLocale(lng);
    this.close();
  };

  getItems = () => [
    {
      key: 'language-back',
      label: (
        <BackButton onClick={this.close}>
          <LeftOutlined />
          &nbsp;{this.props.t('selectLanguage')}
        </BackButton>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'language-en',
      label: (
        <LanguageItem onClick={this.changeLanguage('en')}>
          <img src={en} alt="english" />
          {this.props.t('english')}
        </LanguageItem>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'language-ja',
      label: (
        <LanguageItem onClick={this.changeLanguage('ja')}>
          <img src={ja} alt="japanese" />
          {this.props.t('japanese')}
        </LanguageItem>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'language-ar',
      label: (
        <LanguageItem onClick={this.changeLanguage('ar')}>
          <img src={ar} alt="arabic" />
          {this.props.t('arabic')}
        </LanguageItem>
      ),
    },
    {
      type: 'divider',
    },
  ];

  render() {
    const { isOpened } = this.state;

    return (
      isOpened && (
        <SelectLanguageWrapper>
          <Menu items={this.getItems()} />
        </SelectLanguageWrapper>
      )
    );
  }

}

export default SelectLanguage;
