import React, { useRef } from 'react';
import { StyledHeader, Text } from '_common/components/TabsForm/elements';
import { PROMOTION_ACTION_BUTTONS_CONTAINER_ID } from '_common/constants/promotions';
import { withTranslation } from 'react-i18next';
import { ActionButton } from '_common/components/PlainStyles';
import { Row, Col } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { PromotionsStore } from 'stores';
import ClonePromotionDialog from '../ClonePromotionDialog';
import { RouterHistory, withRouter } from 'react-router';

type Props = {
  title: string,
  showCloneButton?: boolean,
  t: TTranslateFunc,
  history?: RouterHistory,
  promotionsStore: PromotionsStore,
};

const CustomHeader = React.forwardRef(
  ({ title, showCloneButton, t, promotionsStore, history }: Props, _) => {
    const cloneDialogRef = useRef(null);

    return (
      <>
        <ClonePromotionDialog
          ref={cloneDialogRef}
          t={t}
          history={history}
          cloneSinglePromotion={promotionsStore.cloneSinglePromotion}
        />
        <StyledHeader type="flex">
          <Col xs={10}>
            <Text>{title}</Text>
          </Col>
          <Col md={13} lg={11} xl={9} xxl={7}>
            <Row justify="end" id={PROMOTION_ACTION_BUTTONS_CONTAINER_ID}>
              {showCloneButton && (
                <ActionButton
                  onClick={() =>
                    cloneDialogRef.current
                      ? cloneDialogRef.current.open()
                      : null
                  }
                >
                  {t('common:clone')}
                </ActionButton>
              )}
            </Row>
          </Col>
        </StyledHeader>
      </>
    );
  }
);

export default compose(
  withRouter,
  withTranslation('promotions', { withRef: true }),
  inject('promotionsStore'),
  observer
)(CustomHeader);
