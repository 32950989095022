// @flow

import React, { Component, Fragment } from 'react';
import { CompaniesStore } from 'stores';
import {
  Column,
  ColumnsHolder,
  LeftColumn,
} from '_common/components/DetailsPage/elements';
import { getInfoBlock } from '_common/utils/utils';
import { daysBlock, checksBlock, emailBlock, smsBlock } from './config';
import commonActions from '_common/actions';
import { Roles } from '_common/constants/acl';
import { Header } from '_common/components/InfoBlock/elements';
import { InfoBlockContainer } from '_common/utils/utilsElements';
import { get } from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

type Props = {
  companiesStore: CompaniesStore,
  t: TTranslateFunc,
};

type State = {
  errors: Object,
};

class CompanyDetailsCollections extends Component<Props, State> {

  state = {
    errors: {},
  };

  setError = (fieldName: string, value: any): void => {
    this.setState({
      errors: {
        [fieldName]: value,
      },
    });
  };

  render() {
    const isEditable = commonActions.canAccessFeature({
      roles: [Roles.DODDLE_ADMIN, Roles.HOST_ADMIN],
    });
    const { companiesStore, t } = this.props;
    const { errors } = this.state;

    return (
      <Fragment>
        <ColumnsHolder>
          <LeftColumn>
            {getInfoBlock(checksBlock(isEditable), false, companiesStore)}
          </LeftColumn>
          <Column>
            {getInfoBlock(daysBlock(isEditable), false, companiesStore)}
          </Column>
        </ColumnsHolder>
        <InfoBlockContainer>
          <Header>{t('communications')}</Header>
        </InfoBlockContainer>
        <ColumnsHolder>
          <LeftColumn>
            {getInfoBlock(
              emailBlock(
                isEditable,
                !!get(companiesStore, 'singleCompany.sendEmailViaCompany'),
                companiesStore.getCachedUserCompaniesList
              ),
              false,
              companiesStore
            )}
          </LeftColumn>
          <Column>
            {getInfoBlock(
              smsBlock(
                isEditable,
                !!get(
                  companiesStore,
                  'singleCompany.collections.smsConfig.sendSMS'
                ),
                errors,
                this.setError
              ),
              false,
              companiesStore
            )}
          </Column>
        </ColumnsHolder>
      </Fragment>
    );
  }

}

export default compose(
  inject('companiesStore'),
  withTranslation('company'),
  observer
)(CompanyDetailsCollections);
