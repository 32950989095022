import styled, { css } from 'styled-components';
import Button from '_common/components/Button/Button';

export const CompaniesDialogRoot = styled.div`
  width: 95%;
  min-width: 1045px;
  height: 85%;
  border: 1px solid #e6e7e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
  position: absolute;
  margin: 0 auto;
  background: #fff;
  top: 7.5%;
  left: 2.5%;
  overflow: auto;
  z-index: 10000;
  display: block;
  .DialogWrapperInner {
    justify-content: flex-start;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  span[role='img'] {
    position: initial;
    font-size: 21px;
  }
`;

export const CompanyNameContainer = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompaniesMenuTitle = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #6a6b6e;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  transition: color 0.3s;
  &:hover {
    color: #1890ff;
  }
`;

export const ParentCompanyItem = styled.div`
  padding: 15px;
  font-size: 13px;
  border: 1px solid ${props => props.theme.colors.shade3};
  border-top-width: 0;
  font-family: ${props => props.theme.fonts.basic};
  position: relative;
  color: #6a6b6e;
  .anticon {
    position: absolute;
    right: 5%;
    top: 23%;
    font-size: 26px;
    color: ${props => props.theme.colors.secondary};
  }
  &:last-child {
    .anticon {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const CompanyItem = styled(ParentCompanyItem)`
  border-top-width: 1px;
  font-size: 15px;
  font-family: ${props => props.theme.fonts.bold};
  color: ${props => (props.selected ? '#fff' : '#000')};
  background-color: ${props =>
    props.selected ? props.theme.colors.primary : '#fff'};
  cursor: pointer;
  transition: background 0.3s;
  ${props =>
    !props.selected &&
    css`
      &:hover {
        background-color: ${props => props.theme.colors.shade1};
      }
    `}
`;

export const CompaniesGridWrapper = styled.div`
  margin-top: 15px;
  .ant-row,
  .ant-row-flex {
    margin-bottom: 65px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CompanyGridItem = styled.div`
  padding: 0 8px;
  display: block;
  box-sizing: border-box;
  width: 19.8%;
  @media (max-width: 1400px) {
    width: 24.8%;
  }
  &:first-child {
    padding-left: 0;
  }
`;

export const TopRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
`;

export const SearchCompaniesWrapper = styled.div`
  position: relative;
  width: 30%;
  .anticon {
    position: absolute;
    right: 5%;
    top: 25%;
    font-size: 20px;
  }
`;

export const CreateCompanyButton = styled(Button)`
  height: auto !important;
`;
